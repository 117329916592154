<p-toast position="bottom-right">{{msgs}}</p-toast>
	<h1 *ngIf="formMode === 'create'" class="m-0 ml-2 my-4">{{ 'Operational Policies' | translate}}</h1>
	<h1 *ngIf="formMode === 'update'" class="m-0 ml-2 my-4">{{ 'Edit Operational Policy' | translate}}</h1>
	<p-panel [toggleable]="true">
		<p-header class="d-flex flex-grow-1 align-items-center justify-content-between">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{formMode == 'create'? ('Add Operational Policy' | translate) : 'Edit Operational Policy' | translate}}</span>
			<span class="ci-history c-pointer cursor-pointer mr-4" *ngIf="this.formMode === 'update'" (click)="getHistory()"><i class="fa-solid fa-clock-rotate-left text-primary"></i> {{'history.title' | translate}}</span>
		</p-header>
		<div class="ui-g">	
			<!--<div [formGroup]="holidayForm" class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin && !hide">-->
				<!--<label>{{'Company' | translate}}<span class="required-indicator">*</span></label>-->
				<!--<div class="input-group w-100">-->
					<!--<div class="input-group-prepend p-0">-->
						<!--<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">-->
							<!--<i class="fa-solid fa-building"></i>-->
						<!--</button>-->
					<!--</div>-->
					<!--<p-dropdown [options]="companyList" id="company" placeholder="{{'pleaseSelect' | translate}}" [filter]="true" formControlName="companyId" [(ngModel)]="edittingHoliday.companyId" [disabled]="formMode === 'update'">-->
						<!--<ng-template pTemplate="item" let-item>-->
							<!--<span class="text-truncate">{{item.label | translate}}</span>-->
						<!--</ng-template>-->
						<!--<ng-template let-item pTemplate="selectedItem">-->
							<!--<span class="one-liner">{{item.label | translate}}</span>-->
						<!--</ng-template>-->
					<!--</p-dropdown>-->
				<!--</div>-->
				<!--<div class="text-danger error" *ngIf="holidayForm.get('companyId').invalid && holidayForm.get('companyId').touched">-->
					<!--<span>{{'This field is required' | translate}}</span>-->
				<!--</div>-->
			<!--</div>	-->
			<div [formGroup]="holidayForm" #f="ngForm" class="ui-g-6 ui-sm-12">
				<label for="name">{{'Policy Name' | translate}}:<span class="required-indicator">*</span></label>
				<div class="input-group w-100" [ngClass]="{'form-edit': formMode === 'update'}">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-quote-left"></i>
						</button>
					</div>
					<input maxlength="50" type="text" class="form-control" pInputText id="name" [(ngModel)]="edittingHoliday.name" formControlName="name" placeholder="{{'Enter Text' | translate}}" (keyup)="validateName()"/>
				</div>
				<div class="text-danger error" *ngIf="holidayForm.get('name').invalid && holidayForm.get('name').touched">
					<span>{{'This field is required' | translate}}</span>
				</div>
				<div class="text-danger error" *ngIf="checkValidateName">
					<span>{{'Policy Name already exists' | translate}}</span>
				</div>
			</div>
			<div [formGroup]="holidayForm" #f="ngForm" class="ui-g-6 ui-sm-12">
				<label for="status">{{'form.status' | translate}}<span class="required-indicator">*</span></label>
				<div class="input-group w-100" [ngClass]="{'form-edit': formMode === 'update'}">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-info"></i>
						</button>
					</div>
					<p-dropdown id="status" [options]="searchStatus" formControlName="status" placeholder="{{'placeholder.selectaStatus' | translate}}" [(ngModel)]="edittingHoliday.status" [autoDisplayFirst]="false">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
				<div class="text-danger error" *ngIf="holidayForm.get('status').invalid && holidayForm.get('status').touched">
					<span>{{'This field is required' | translate}}</span>
				</div>
			</div>
			<div class="ui-g-12 text-center py-4">
				<button *ngIf="formMode === 'create'" class="btn btn-danger mx-1" (click)="onCancel($event)" ><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
				<button *ngIf="formMode === 'create'"class="btn btn-primary mx-1" (click)="onSave($event)" [disabled]="holidayForm.invalid || checkValidateName || submitting" ><i class="fa-solid fa-check mr-2"></i>{{'button.submit' | translate}}</button>
				<button *ngIf="formMode === 'update'" class="btn btn-danger mx-1" (click)="onCancel($event)" ><i class="fa-solid fa-xmark mr-2"></i>{{'button.cancel' | translate}}</button>
				<button *ngIf="formMode === 'update'" class="btn btn-primary mx-1" (click)="onSave($event)" [disabled]="holidayForm.invalid || checkValidateName || submitting" ><i class="fa-solid fa-check mr-2"></i>{{'Update' | translate}}</button>
			</div>
		</div>
	</p-panel>
	<div class="d-flex justify-content-between align-items-center flex-wrap py-4">
		<h1 class="m-0 ml-2">{{'Browse Operational Policies' | translate}}</h1>
		<!--<button class="btn btn-primary" pTooltip="{{'Add' | translate}}" (click)="addHoliday()" [disabled]="formMode === 'update'" tooltipPosition="left"><i class="fa-solid fa-plus"></i></button>-->
	</div>
	<p-panel id="table-ppanel" [toggleable]="true">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Operational Policies' | translate}} ({{(dataSource$|async)?.totalElements || 0 | number}})</span>
		</p-header>
		<p-table #table [value]="holidayList" [paginator]="true" [rowsPerPageOptions]="[10, 25, 50]" [totalRecords]="(dataSource$|async)?.totalElements" (onLazyLoad)="loadAllHolidayList($event)" [lazy]="false" [responsive]="true" [rows]="10" dataKey="id"
				 [globalFilterFields]="['createdAtStr', 'id', 'name', 'createdByUsr']">
			<ng-template pTemplate="caption">
				<div class="d-flex align-items-center justify-content-end">
					<!--<div class="d-flex align-items-center">-->
						<!--<button type="button" (click)="exportPdf();" class="btn btn-primary mx-1">-->
							<!--<i class="fa-solid fa-print mr-2"></i> {{'Export PDF' | translate}}-->
						<!--</button>-->
					<!--</div>-->
					<div class="input-group" style="max-width: 400px">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-filter"></i>
							</button>
						</div>
						<input type="text" [(ngModel)]="searchText" pInputText size="50" placeholder="{{'Filter' | translate}}" (input)="table.filterGlobal($event?.target?.value, 'contains')">
					</div>
				</div>
			</ng-template>
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th class="adjust-padding" pResizableColumn *ngFor="let col of cols">
						<ng-container [ngSwitch]="col.field">
							<span *ngSwitchDefault>
								<span class="d-flex align-items-center justify-content-between w-100"> 
									<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
									<span *ngIf="col.sort">
										<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field;">
											<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
										</span>
									</span>
								</span>
							</span>
							<span *ngSwitchCase="'createdAt'">
								<span class="d-flex align-items-center justify-content-between w-100"> 
									<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
									<span *ngIf="col.sort">
										<span (click)="col.sort == 'number'? op3.toggle($event) : op1.toggle($event); selectedField = col.field;">
											<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
										</span>
									</span>
								</span>
							</span>
							<span *ngSwitchCase="'status'">
								<i class="fa-solid fa-info text-muted m-auto" pTooltip="{{'Status' | translate}}" tooltipPosition="left"></i>
							</span>
							<span *ngSwitchCase="'actions'">
								<i class="fa-solid fa-bars text-muted m-auto" pTooltip="{{'Actions' | translate}}" tooltipPosition="left"></i>
							</span>
						</ng-container>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData>
				<tr [pSelectableRow]="rowData" >
					<td *ngFor="let col of cols">
						<ng-container [ngSwitch]="col.field">
							<span *ngSwitchDefault>
								<span class="one-liner">
									<span class="mobile-table-label">{{'ID' | translate}}: </span>
									<span *ngIf="this.rowData[col.field]" pTooltip="{{ rowData[col.field] }}">{{ rowData[col.field] }}</span>
									<span *ngIf="!this.rowData[col.field]">{{ 'no data' | translate }}</span>
								</span>
							</span>
							<span *ngSwitchCase="'name'">
								<span class="one-liner">
									<span class="mobile-table-label">{{'Policy Name' | translate}}: </span>
									<span *ngIf="this.rowData[col.field]" pTooltip="{{ rowData[col.field] }}">{{ rowData[col.field] }}</span>
									<span *ngIf="!this.rowData[col.field]">{{ 'no data' | translate }}</span>
								</span>
							</span>
							<span *ngSwitchCase="'createdAt'">
								<span class="one-liner">
									<span class="mobile-table-label">{{'Added Date' | translate}}: </span>
									<span *ngIf="rowData.createdAt" pTooltip="{{rowData.createdAt | date: 'MM/dd/yyyy hh:mm:ss a' }}">{{rowData.createdAt | date: 'MM/dd/yyyy hh:mm:ss a' }}</span>
									<span *ngIf="!rowData.createdAt">{{ 'no data' | translate }}</span>
								</span>
							</span>
							<span *ngSwitchCase="'createdByUsr'">
								<span class="one-liner">
									<span class="mobile-table-label">{{'Creator' | translate}}: </span>
									<span *ngIf="this.rowData[col.field]" pTooltip="{{ rowData[col.field] }}">{{ rowData[col.field] }}</span>
									<span *ngIf="!this.rowData[col.field]">{{ 'no data' | translate }}</span>
								</span>
							</span>
							<span *ngSwitchCase="'status'">
								<span class="one-liner">
									<span class="mobile-table-label">{{'Status' | translate}}: </span>
									<i *ngIf="rowData[col.field] == 'Active'" class="fa-solid fa-circle-check text-success" pTooltip="{{'Active' | translate}}"></i>
									<i *ngIf="rowData[col.field] != 'Active'" class="fa-solid fa-circle-xmark text-danger" pTooltip="{{'Inactive' | translate}}"></i>
								</span>
							</span>
							<span *ngSwitchCase="'actions'">
								<span class="one-liner">
									<span class="mobile-table-label">{{'Actions' | translate}}: </span>
									<i class="fa-solid text-primary fa-pen-to-square c-pointer mx-1" (click)="onEditHoliday($event, rowData)"></i>
									<i class="fa-solid fa-xmark text-danger c-pointer mx-1" (click)="onDeleteHoliday($event, rowData)"></i>
								</span>
							</span>
						</ng-container>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage" let-columns>
				<tr *ngIf="!loading">
					<td [attr.colspan]="6" class="text-center py-5">
						<div class="text-muted my-5">{{'no data' | translate}}</div>
					</td>
				</tr>
				<tr *ngIf="loading">
					<td [attr.colspan]="6" class="text-center py-5">
						<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</p-panel>
	<p-overlayPanel #op1 appendTo="body">
		<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
			<ng-template let-col pTemplate="item">
				<div class="d-flex align-items-center">
					<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
					<span>{{col.name | translate}}</span>
				</div>
			</ng-template>
		</p-listbox>
	</p-overlayPanel>
	<p-overlayPanel #op2 appendTo="body">
		<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
			<ng-template let-col pTemplate="item">
				<div class="d-flex align-items-center">
					<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
					<span>{{col.name | translate}}</span>
				</div>
			</ng-template>
		</p-listbox>
	</p-overlayPanel>
	<p-overlayPanel #op3 appendTo="body">
		<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
			<ng-template let-col pTemplate="item">
				<div class="d-flex align-items-center">
					<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
					<span>{{col.name | translate}}</span>
				</div>
			</ng-template>
		</p-listbox>
	</p-overlayPanel>

<p-dialog *ngIf="historyShow" [modal]="true" [(visible)]="historyShow" [dismissableMask]="true" showEffect="fade" styleClass="w-75" appendTo="body">
	<p-header>
		<span><i class="fa-solid fa-clock-rotate-left mr-2"></i>{{'Edit History' | translate}}</span>
	</p-header>
	<app-operational-policy-history [holidayId]="edittingHoliday.id"></app-operational-policy-history>
	<p-footer>
		<button class="btn btn-primary" (click)="historyShow = false"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="displayDialog" [modal]="true" [style]="{'width':'90%', 'max-width':'500px'}">
	<p-header>
		<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Delete Item' | translate}}</span>
	</p-header>
   <div class="ui-g">
		<div class="ui-g-12">
			<span style="font-size: 16px;">{{'Are you sure you want to delete this item?' | translate}}</span>
		</div>
	</div>
    <p-footer class="d-flex align-items-center justify-content-between">
		<button type="button" (click)="onReject()" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button type="button" (click)="onConfirm()" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
    </p-footer>
</p-dialog>
