export class CustomTabMenu {
  breadcrumb: string | string[];
  items: CustomTabMenuItem[] = [];
  disabledRouterOutlet?: boolean;
}

export class CustomTabMenuItem {
  id: string;
  label: string;
  routerLink: any;
  routerLinkUrl: string | string[];
  visible: boolean;
  breadcrumb?: string[];
  hideAsDefault?: boolean;
  tooltip?: string;
  iconClass?: string;
  iconBeforeClass?: string;
  onClick?: () => void;
}