import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LazyLoadEvent, Message, MenuItem, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'primeng/table';
import { Employee } from '../../../employee/model/employee.model';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { EmployeeClockingService } from '../../service/employee-clocking.service';
import { EmployeeClocking } from '../../model/employee-clocking.model';
import { WindowRefService } from '../../../shared/service/window-ref/window-ref.service';
import * as MOMENT from 'moment';
import { PayrollSettingService } from '../../../payroll-setting/service/payroll-setting.service';
import { CompanyService } from '../../../company/service/company.service';
import { AgencyService } from '../../../agency/service';
import { Company } from '../../../company/model/company.model';
import { Agency } from '../../../agency/model';
import { first } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Chart } from 'chart.js';
import {TranslatePipe} from '@ngx-translate/core';
declare let $: any;

@Component({
    selector: 'app-ontime-late',
    templateUrl: './ontime-late.component.html',
    styleUrls: ['./ontime-late.component.css'],
    providers: [EmployeeClockingService, WindowRefService, PayrollSettingService, MessageService]
})
export class OntimeLateComponent implements OnInit {
    @ViewChild('dt', { static: true }) table: Table;

    punchHistoryList: Array<any>;
    filteredPunchHistoryList: Array<any>;
    selectedPunchHistory: EmployeeClocking = new EmployeeClocking();
    employeeClocking: EmployeeClocking = new EmployeeClocking();
    totalRecords: Number = 0;
    items: MenuItem[];
    msgs: Message[] = [];
    loading = true;
    types: Array<any> = [];
    employeeList: Array<Employee> = [];
    filteredEmployeeList: Array<Employee> = [];
    historyFilter: any;
    rowData: any;
    selectedCompany: any;
    selectedAgency: any;
    missingOrInvalidDate = false;
    showNoteDetailsDialog = false;
    missingCheckInCount = 0;
    missingCheckOutCount = 0;
    selected: any;
    companies = [];
    allAgencies = [];
    onLateData: any;
    agencies = [];
    isMissing = false;
    role: number;
    chartData: any;
    lineOptions: any;
    dataList = [];
    lineLabels = [];
    timeClock = [];
    timeclockVal = null;
    onlateDataChart: any;
    ranges: any = {
        'Today': [MOMENT(), MOMENT()],
        'Yesterday': [MOMENT().subtract(1, 'days'), MOMENT().subtract(1, 'days')],
        'Last 7 Days': [MOMENT().subtract(6, 'days'), MOMENT()],
        'Last 30 Days': [MOMENT().subtract(29, 'days'), MOMENT()],
        'This Month': [MOMENT().startOf('month'), MOMENT().endOf('month')],
        'Last Month': [MOMENT().subtract(1, 'month').startOf('month'), MOMENT().subtract(1, 'month').endOf('month')]
    };
    invalidDates: MOMENT.Moment[] = [MOMENT().add(2, 'days'), MOMENT().add(3, 'days'), MOMENT().add(5, 'days')];
    isInvalidDate = (m: MOMENT.Moment) => {
        return this.invalidDates.some(d => d.isSame(m, 'day'));
    }


    constructor(private router: Router, private route: ActivatedRoute,
        private employeeClockingService: EmployeeClockingService,
        private payrollSettingService: PayrollSettingService,
        private windowRef: WindowRefService,
        private companyService: CompanyService,
        private messageService: MessageService,
        private agencyService: AgencyService,
        private authService: AuthService,
        private translatePipe: TranslatePipe) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe(param => {
            if (param.term === 'missing') {
                this.isMissing = true;
                this.getMissingPunched();
            }
        });
        this.items = [
            { label: 'View', icon: 'fa-solid fa-magnifying-glass', command: (event) => this.viewPunchHistory(this.selectedPunchHistory) },
        ];
        this.punchHistoryList = [];
        this.filteredPunchHistoryList = [];
        this.types = [
            { label: 'Part Time', value: 'part_time' },
            { label: 'Full Time', value: 'full_time' },
            { label: 'Consultant', value: 'consultant' },
            { label: 'Volunteer', value: 'volunteer' }];

        this.timeClock = [{ label: 'all', value: 'all' }, { label: 'onTime', value: 'onTime' }, { label: 'late', value: 'late' }];

        this.historyFilter = {
            employees: [],
            employeeIds: [],
            fromDate: new Date(),
            toDate: new Date(),
            page: 0,
            size: 10,
            searchKey: '',
            date: [new Date(), new Date()]
        };

        this.selectedCompany = this.authService.getCurrentCompany();
        this.selectedAgency = this.authService.getCurrentAgency();
        this.role = this.authService.getRoleLevel();

        if (this.authService.getCurrentEmployee()) {
            console.log(this.authService.getCurrentEmployee());
            this.historyFilter.employeeIds.push(this.authService.getCurrentEmployee());
        }

        // this.getAllEmployees();
        this.companyService.getCompaniesDropdown({}).subscribe(resC => {
            const resObjC: any = resC;
            this.companies = [];
            resObjC.data.forEach(dt => {
                this.companies.push({ label: dt.value, value: dt.key });
            });
        });
        /*this.agencyService.getAll().pipe(first()).subscribe((response: any) => {
            this.allAgencies = response.data.content;
            if (this.selectedCompany) {
                this.loadAgencies(this.selectedCompany);
            }
        });*/

        this.getAllEmployeesByCompanyAndAgency();
        const employeeIdsStr = localStorage.getItem('overtime_alert');
        if (employeeIdsStr && employeeIdsStr.length > 0) {
            this.historyFilter.employeeIds = employeeIdsStr.split(',');
            if (!this.isMissing) {
                //this.loadPage(this.historyFilter);
            }
            localStorage.removeItem('overtime_alert');
        }
        const onlateData = this.getOnlateData();
        this.onLateData = Object.assign({}, onlateData);
        this.onlateDataChart = new Chart('onlateCanvas', this.onLateData);
        //this.filterAllClockings();
    }

    viewPunchHistory(d: EmployeeClocking) {
        // this.router.navigate(['app/employee/clocking','view',d.id]);
    }

    editPunchHistory(d: EmployeeClocking) {
        // this.router.navigate(['app/employee/clocking', 'edit', d.id]);
    }

    loadPunchHistoryLazy(event: LazyLoadEvent) {
        const sortOrder = <number>event['sortOrder'];
        this.loading = true;
        const page = (event.first) / event.rows;
        this.historyFilter.page = page;
        this.historyFilter.size = event.rows;
        if (event.sortField != null && event.sortOrder != null) {
            const sortField = event.sortField;
            this.historyFilter.sortField = sortField;
            this.historyFilter.sortOrder = sortOrder;
        }
        if (!this.isMissing) {
            //this.loadPage(this.historyFilter);
        }
    }

    loadPage(options) {
        const empTypes = this.types;
        this.employeeClockingService.punchHistoryFilter(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.loading = false;
                resObj.data.content.forEach(obj => {
                    if (obj.checkInTime !== null && obj.checkOutTime !== null) {
                        if ((obj.checkOutTime - obj.checkInTime) >= 86400000) {
                            obj.checkOutTime = null;
                        }
                        if (obj.checkInTime >= obj.checkOutTime) {
                            obj.checkOutTime = null;
                        }
                    }
                    this.setList(resObj.data.content, obj);
                });
                resObj.data.content.forEach(function (obj) {
                    empTypes.forEach(function (obj2) {
                        if (obj.employee.type === obj2.value) {
                            obj.employee.type = obj2.label;
                        }
                    });

                });
                this.punchHistoryList = resObj.data.content;
                this.totalRecords = resObj.data.totalElements;
                this.filterPunchHistory();
            }
        });
    }

    filterAllClockings() {
        this.employeeClockingService.punchHistoryFilterAll(this.historyFilter).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                console.log(resObj.data);
                const phList = resObj.data.content;
                const filteredList = phList.filter((punchHistory: EmployeeClocking) => {
                    return (!this.selectedCompany) || (this.selectedCompany && punchHistory.employee && punchHistory.employee.companyId === this.selectedCompany);
                }).filter((punchHistory: EmployeeClocking) => {
                    return (!this.selectedAgency) || (this.selectedAgency && punchHistory.employee && punchHistory.employee.agency && punchHistory.employee.agency.id === this.selectedAgency);
                });
                this.prepareLinechartData(filteredList);
            }
        });
    }

    showTotal(rowData: EmployeeClocking) {
        if (rowData.checkInTime === null || rowData.checkOutTime === null) {
            return 'N/A';
        }
        if (rowData.checkInTime > rowData.checkOutTime) {
            return 'N/A';
        }
        const startTime = rowData.checkInTime;
        const endTime = rowData.checkOutTime;
        const second = (endTime - startTime) / 1000;
        const min = parseInt((second / 60).toString(), 10);
        const hour = (min - (min % 60)) / 60;
        const minuter = min % 60;
        const hourStr = hour < 10 ? '0' + hour.toString() : hour.toString();
        const minStr = minuter < 10 ? '0' + minuter.toString() : minuter.toString();
        return hourStr + ':' + minStr;
    }

    getTotalDay(punchHistoryList: Array<any>) {
        return punchHistoryList.length.toString() + ' Day(s)';
    }

    getTotalHour(punchHistoryList: Array<any>) {
        let total = 0;
        this.missingOrInvalidDate = false;
        this.missingCheckInCount = 0;
        this.missingCheckOutCount = 0;
        for (let i = 0; i < punchHistoryList.length; i++) {
            const row = punchHistoryList[i];
            if ((row.checkInTime === null || row.checkOutTime === null) || (row.checkInTime > row.checkOutTime)) {
                if (row.checkInTime === null) {
                    this.missingCheckInCount = this.missingCheckInCount + 1;
                }
                if (row.checkOutTime === null) {
                    this.missingCheckOutCount = this.missingCheckOutCount + 1;
                }
                this.missingOrInvalidDate = true;
                continue;
            }
            const startTime = row.checkInTime;
            const endTime = row.checkOutTime;
            const duration = endTime - startTime;
            total = total + duration;
        }

        const totalSecond = total / 1000;

        const min = (totalSecond - (totalSecond % 60)) / 60;
        const hour = (min - (min % 60)) / 60;
        const minStr = min % 60 < 10 ? '0' + (min % 60).toString() : (min % 60).toString();
        const hourStr = hour < 10 ? '0' + hour.toString() : hour.toString();
        return hourStr + ':' + minStr;
    }

    getTotalOverrtime(punchHistoryList: Array<any>) {
        let total = 0;
        for (let i = 0; i < punchHistoryList.length; i++) {
            const row = punchHistoryList[i];
            if (row.checkOutTime === null || row.checkInTime === null) {
                continue;
            }
            const duration = row.checkOutTime - row.checkInTime;
            if (row.overTime) {
                total = total + row.overTime;
            }
        }

        if (total === 0) {
            return '00:00';
        }
        const min = (total - total % 60) / 60;
        const hour = (min - min % 60) / 60;
        const hourStr = hour < 10 ? '0' + hour.toString() : hour.toString();
        const minStr = min % 60 < 10 ? '0' + (min % 60).toString() : (min % 60).toString();
        return hourStr + ':' + minStr;
    }

    getOverrtime(rowData: EmployeeClocking) {
        if (rowData.checkInTime === null || rowData.checkOutTime) {
            return '00:00';
        }
        let total = 0;
        if (rowData.list != null && rowData.list.length > 0) {
            for (let i = 0; i < rowData.list.length; i++) {
                const row = rowData.list[i];

                if (row.id === rowData.id) {
                    break;
                } else {
                    const duration = row.checkOutTime - row.checkInTime;
                    const second1 = duration / 1000;
                    total = total + second1;
                }
            }

            const duration1 = rowData.checkOutTime - rowData.checkInTime;
            const second = total + duration1 / 1000;
            let overrtimeAfter = 0;
            if (rowData.payrollSetting) {
                overrtimeAfter = MOMENT.duration(rowData.payrollSetting.overtimeAfter).asHours();
            }
            const overtime = overrtimeAfter * 60 * 60;
            if (second > overtime) {
                const time = second - overtime;
                console.log(time < duration1 / 1000);
                if (time < duration1 / 1000) {
                    rowData.overTime = time;
                    return MOMENT.utc(time * 1000).format('HH:mm');

                } else {
                    rowData.overTime = duration1 / 1000;
                    return MOMENT.utc(duration1).format('HH:mm');
                }

            } else {
                rowData.overTime = 0;
            }
        }

        return '00:00';
    }

    setList(punchHistoryList: Array<any>, rowData: EmployeeClocking) {
        if (rowData.employee.agency && rowData.employee.agency.id) {
            this.payrollSettingService.getPayrollSetting(rowData.employee.agency.id).subscribe(res => {

                const resObj: any = res;
                rowData.payrollSetting = resObj.data;
                // this.getOverrtime(rowData);
            });
        }

        const list: EmployeeClocking[] = [];
        this.punchHistoryList.forEach(function (obj) {
            if (obj.employee.id === rowData.employee.id) {
                list.push(obj);
            }
        });
        rowData.list = list;
    }

    search(event) {
        this.loading = true;
        //this.table.first = 0;
        this.historyFilter.searchKey = event.target.value;
        this.historyFilter.page = 0;
        this.historyFilter.size = 10;
        if (this.historyFilter.searchKey) {
            if (!this.isMissing) {
                //this.loadPage(this.historyFilter);
            }
        } else {
            this.historyFilter.searchKey = '';
            if (!this.isMissing) {
                //this.loadPage(this.historyFilter);
            }
        }
    }

    searchBySelectedEmployees(event) {
        this.loading = true;
        //this.table.first = 0;
        this.historyFilter.page = 0;
        this.historyFilter.size = 10;
        this.historyFilter.employeeIds = [];
        const pchc = this;
        this.historyFilter.employees.forEach(function (obj) {
            pchc.historyFilter.employeeIds.push(obj.id);
        });
        if (this.historyFilter.employeeIds && this.historyFilter.employeeIds.length > -1) {
            if (!this.isMissing) {
                //this.loadPage(this.historyFilter);
                //this.filterAllClockings();
            }
        }
        if (event.value.length === this.filteredEmployeeList.length) {
            let x = document.getElementById('id_filteredEmployeeList_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = 'All';
        } else {
            let x = document.getElementById('id_filteredEmployeeList_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
            x.textContent = `${event.value.length} items selected`;
        }
    }

    getAllEmployees() {
        this.employeeClockingService.getEmployeeList().subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.employeeList = resObj.data;
            }
        });
    }

    isSuperAdmin() {
        return this.role === 1 || this.role === 2;
    }

    isCompanyAdmin() {
        return this.role === 3 || this.role === 4;
    }

    isAgencyAdmin() {
        return this.role === 5 || this.role === 6;
    }

    onResetClick(event) {
        if (this.isAgencyAdmin()) {
            this.historyFilter.lateEarlier = undefined;
            this.historyFilter.date = undefined;
            this.historyFilter.fromDate = '';
            this.historyFilter.toDate = '';
            this.timeclockVal = null;

        } else if (this.isCompanyAdmin()) {
            this.selectedAgency = undefined;
            this.historyFilter.lateEarlier = undefined;
            this.historyFilter.fromDate = '';
            this.historyFilter.toDate = '';
            this.timeclockVal = null;
        } else if (this.isSuperAdmin()) {
            this.selectedCompany = undefined;
            this.selectedAgency = undefined;
            this.historyFilter.lateEarlier = undefined;
            this.historyFilter.date = undefined;
            this.historyFilter.fromDate = '';
            this.historyFilter.toDate = '';
            this.timeclockVal = null;
        }
    }

    onSubmitClick(event) {
        this.filterAllClockings();
    }

    searchBySelectedDate(date) {
        if (date[0] && date[1]) {
            this.loading = true;
            //this.table.first = 0;
            this.historyFilter.page = 0;
            this.historyFilter.size = 10;
            this.historyFilter.fromDate = date[0];
            this.historyFilter.toDate = date[1];
            if (this.historyFilter.fromDate && this.historyFilter.toDate) {
                //this.loadPage(this.historyFilter);
                //this.filterAllClockings();
            }
        }
    }

    showHour(date: Date) {
        // return MOMENT.utc(date).format('HH:mm');
        if (date === null) {
            return 'Missing';
        }
        return MOMENT(date).format('HH:mm');
    }

    showDate(date: Date) {
        return MOMENT.utc(date).format('MM/dd/YYYY ddd');
    }

    getReportFile() {
        this.employeeClockingService.getReportByAgency(this.historyFilter).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                const fileDate = new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDate() + '-' + new Date().getHours() + '-' + new Date().getMinutes() + '-' + new Date().getSeconds();
                const filename = 'employee_punch_in_out_history_' + fileDate;
                const binary = atob(resObj.data.replace(/\s/g, ''));
                const len = binary.length;
                const buffer = new ArrayBuffer(len);
                const view = new Uint8Array(buffer);
                for (let i = 0; i < len; i++) {
                    view[i] = binary.charCodeAt(i);
                }
                const blob = new Blob([view], { type: 'application/pdf' });
                const linkElement = document.createElement('a');
                const fileObjUrl = URL.createObjectURL(blob);
                linkElement.setAttribute('href', fileObjUrl);
                linkElement.setAttribute('download', filename);
                const clickEvent = new MouseEvent('click', {
                    'view': this.windowRef.nativeWindow,
                    'bubbles': true,
                    'cancelable': false
                });
                linkElement.dispatchEvent(clickEvent);
                (URL).revokeObjectURL(linkElement.href);
            }
        });
    }

    mapToDropdown(label, val) {
        return {
            label: label,
            value: val
        };
    }

    loadAgencies(companyId) {
        this.agencies = this.allAgencies.filter((agency: Agency) => {
            return agency.companyId === companyId;
        }).map((agency: Agency) => this.mapToDropdown(agency.name, agency.id));

        this.getAllEmployeesByCompanyAndAgency();
    }

    loadEmployeeList(agencyId) {
        this.filteredEmployeeList = this.employeeList.filter((employee) => {
            return employee.agency.id === agencyId;
        });
        // console.log(this.filteredEmployeeList.length, this.employeeList.length);
        this.filterPunchHistory();
    }

    getAllEmployeesByCompanyAndAgency() {
        if (this.selectedCompany && this.selectedAgency) {
            this.employeeClockingService.getByCompanyAndAgency({
                companyId: this.selectedCompany, page: 0, size: 9999
            }).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    this.employeeList = resObj.data.content;
                    this.historyFilter.employees = this.employeeList.filter((emp: Employee) => {
                        return emp.id === this.authService.getCurrentEmployee();
                    });
                    this.filteredEmployeeList = this.employeeList;
                    this.filterPunchHistory();
                }
            });
        } else if (this.selectedCompany && !this.selectedAgency) {
            this.employeeClockingService.getByCompanyAndAgency({ companyId: this.selectedCompany, page: 0, size: 9999 }).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    this.employeeList = resObj.data.content;
                    this.historyFilter.employees = this.employeeList.filter((emp: Employee) => {
                        return emp.id === this.authService.getCurrentEmployee();
                    });
                    this.filteredEmployeeList = this.employeeList;
                    this.filterPunchHistory();
                }
            });
        } else if (!this.selectedCompany && this.selectedAgency) {
            this.employeeClockingService.getByCompanyAndAgency({ agencyId: this.selectedAgency, page: 0, size: 9999 }).subscribe(res => {
                const resObj: any = res;
                if (resObj.status === 'SUCCESS') {
                    this.employeeList = resObj.data.content;
                    this.historyFilter.employees = this.employeeList.filter((emp: Employee) => {
                        return emp.id === this.authService.getCurrentEmployee();
                    });
                    this.filteredEmployeeList = this.employeeList;
                    this.filterPunchHistory();
                }
            });
        }
    }

    filterPunchHistory() {
        this.filteredPunchHistoryList = this.punchHistoryList.filter((punchHistory: EmployeeClocking) => {
            return (!this.selectedCompany) || (this.selectedCompany && punchHistory.employee.company.id === this.selectedCompany);
        }).filter((punchHistory: EmployeeClocking) => {
            return (!this.selectedAgency) || (this.selectedAgency && punchHistory.employee.agency.id === this.selectedAgency);
        });
    }

    allowEditCheckIn(rowData) {
        if (!rowData.editAllowedCheckIn) {
            rowData.editAllowedCheckIn = true;
            if (rowData.checkInTime) {
                rowData.tempCheckInTime = this.showHour(rowData.checkInTime);
            }
        }
    }

    cancelEditCheckIn(rowData) {
        setTimeout(() => {
            rowData.editAllowedCheckIn = false;
        }, 0);
    }

    updateCheckInTime(rowData) {
        if (rowData.tempCheckInTime.length < 4) {
            return;
        }
        try {
            const tempTimeArr = rowData.tempCheckInTime.split(':');
            let tempDate = null;
            if (rowData.checkInTime) {
                tempDate = new Date(rowData.checkInTime);
            } else {
                tempDate = new Date();
            }
            tempDate.setHours(parseInt(tempTimeArr[0], 10));
            tempDate.setMinutes(parseInt(tempTimeArr[1], 10));
            if (rowData.checkOutTime && tempDate > new Date(rowData.checkOutTime)) {
                alert(this.translatePipe.transform('Invalid time. Check-in time can not be greater than check-out time'));
                return;
            }
            rowData.checkInTime = tempDate;
            this.updateTime(rowData);
        } catch (e) {
            console.log('Exception at update checkout time: ', e);
            alert(this.translatePipe.transform('Something went wrong please try again'));
        }
    }

    allowEditCheckOut(rowData) {
        if (!rowData.editAllowedCheckOut) {
            rowData.editAllowedCheckOut = true;
            if (rowData.checkOutTime) {
                rowData.tempCheckOutTime = this.showHour(rowData.checkOutTime);
            }
        }
    }

    cancelEditCheckOut(rowData) {
        setTimeout(() => {
            rowData.editAllowedCheckOut = false;
        }, 0);
    }

    updateCheckOutTime(rowData) {
        if (rowData.tempCheckOutTime.length < 4) {
            return;
        }
        try {
            const tempTimeArr = rowData.tempCheckOutTime.split(':');
            let tempDate = null;
            if (rowData.checkOutTime) {
                tempDate = new Date(rowData.checkOutTime);
            } else {
                tempDate = new Date();
            }
            tempDate.setHours(parseInt(tempTimeArr[0], 10));
            tempDate.setMinutes(parseInt(tempTimeArr[1], 10));
            if (rowData.checkInTime && tempDate < new Date(rowData.checkInTime)) {
                alert('Invalid time. Check-out time can not be smaller than check-in time');
                return;
            }
            rowData.checkOutTime = tempDate;
            this.updateTime(rowData);
        } catch (e) {
            console.log('Exception at update checkout time: ', e);
            alert(this.translatePipe.transform('Something went wrong please try again'));
        }
    }

    updateTime(rowData) {
        this.employeeClocking = new EmployeeClocking();
        this.employeeClocking.id = rowData.id;
        this.employeeClocking.checkInSignature = rowData.checkInSignature;
        this.employeeClocking.checkInTime = rowData.checkInTime;
        this.employeeClocking.checkOutSignature = rowData.checkOutSignature;
        this.employeeClocking.checkOutTime = rowData.checkOutTime;
        this.employeeClocking.createdAt = rowData.createdAt;
        this.employeeClocking.employee = rowData.employee;
        this.employeeClocking.updatedAt = rowData.updatedAt;
        this.employeeClockingService.updateEntity(this.employeeClocking).subscribe(
            (data: any) => {
                if (data.status === 'SUCCESS') {
                    this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('Service Message'), detail: this.translatePipe.transform('Updated Successfully') });
                    setTimeout(() => {
                        rowData.editAllowedCheckIn = false;
                        rowData.editAllowedCheckOut = false;
                    }, 0);
                }
            }
        );
    }

    noteDetailsMouseEnter(event) {
        if (this.showNoteDetailsDialog) {
            return;
        } else {
            this.showNoteDetailsDialog = true;
            setTimeout(() => {
                $('#id_note_detail_dialog > .ui-dialog').css('cssText', `top: ${event.clientY + 10}px !important; left: ${event.pageX + 10}px !important;`);
            }, 0);
        }
    }

    noteDetailsMouseLeave(event) {
        this.showNoteDetailsDialog = false;
    }

    disableCompany(): boolean {
        return !(this.authService.isSuper() || this.authService.isSubSuper());
    }

    disableAgency(): boolean {
        return this.disableCompany() && !(this.authService.isCompanyAd() || this.authService.isSubCompanyAd());
    }

    disableEmployee(): boolean {
        return this.authService.isEmployeeRole();
    }

    getMissingPunched() {
        this.employeeClockingService.getPunchMissingEmployess({ isMissing: this.isMissing }).subscribe(res => {
            const resObj: any = res;
            this.punchHistoryList = resObj.data.content;
            this.loading = false;
        });
    }

    calculateAfterBeforeTime(empData) {
        let employeeTime = 0;
        if (empData.employeeLateAfterHours) {
            employeeTime = empData.employeeLateAfterHours * 60 * 1000;
        }
        employeeTime = empData.checkInTime + employeeTime;
        const d = new Date(empData.checkInTime);
        const n = d.getDay();
        let timeToReturn = '';
        empData.employee.workHours.forEach(element => {
            if (element.dayOfWeek === n) {
                const startTime = new Date(element.startTime);
                const startTimeStr = moment(startTime).format('HH:MM');
                const arivalTimeTemp = startTimeStr.split(':');
                const arivalTimeHours = arivalTimeTemp[0]; // emp should come on this time
                const arivalTimeMins = arivalTimeTemp[1];
                const date = new Date(employeeTime);
                const hh = date.getHours(); // emp come on this time
                const mm = date.getMinutes();
                if ((!hh && hh !== 0) || (!mm && mm !== 0)) {
                    return '';
                }
                if (hh > parseInt(arivalTimeHours, 10)) {
                    if (empData.lateEarlier === '1') {
                        return '';
                    }
                    timeToReturn = `Late: ${Math.abs(hh - parseInt(arivalTimeHours, 10))}.${Math.abs(mm - parseInt(arivalTimeMins, 10))} hrs`;
                } else {
                    if (empData.lateEarlier === '2') {
                        return '';
                    }
                    timeToReturn = `Early: ${Math.abs(hh - parseInt(arivalTimeHours, 10))}.${Math.abs(mm - parseInt(arivalTimeMins, 10))} hrs`;
                }
            }
        });
        return timeToReturn;
    }

    timeClockChange(event) {
        if (event.value === 'all') {
            this.historyFilter.lateEarlier = null;
            //this.loadPage(this.historyFilter);
        } else {
            this.historyFilter.lateEarlier = event.value === 'onTime' ? '1' : '2';
            //this.loadPage(this.historyFilter);
        }
        //this.filterAllClockings();
        console.log(this.timeclockVal)
    }

    changeLineData() {
        const changedData = this.getOnTimeAndLateData();
        this.chartData = Object.assign({}, changedData);
        // this.productivityChart = new Chart('productivityCanvas', this.productivityData);
    }

    private prepareLinechartData(filteredList) {
        this.lineLabels = [];
        this.dataList = [];
        filteredList.forEach((item) => {
            this.lineLabels.push(this.showDate(item.clockInTime));
            this.dataList.push({ day: this.showDate(item.clockInTime), phItem: item, checkInTime: item.clockInTime, timeFloat: this.hoursToNumber(item.clockInTime) });
        });
        this.removeDuplicateLabels();
        this.changeLineData();
        this.lineOptions = this.getLinechartOptions();
        this.changeLineData();

        const onlateData = this.getOnlateData();
        this.onLateData = Object.assign({}, onlateData);
        this.onlateDataChart = new Chart('onlateCanvas', this.onLateData);
    }

    getOnlateData() {
        return {
            type: 'line',
            data: this.getOnTimeAndLateData(),
            options: this.getLinechartOptions
        };
    }

    private removeDuplicateLabels() {
        this.lineLabels = this.lineLabels.filter((v, i) => this.lineLabels.indexOf(v) === i);
    }

    private hoursToNumber(time: number) {
        return parseFloat(this.showHour(new Date(time)).replace(':', '.').replace(/[^\d.-]/g, ''));
    }

    private getOnTimeAndLateData() {
        return {
            labels: this.lineLabels,
            datasets: [
                {
                    label: 'Check ins',
                    data: this.dataList.map(data => {
                        return { x: data.day, y: data.timeFloat };
                    }),
                    fill: false,
                    borderColor: '#03A9F4'
                },
            ]
        };
    }

    private getLinechartOptions() {
        const self = this;
        return {
            tooltips: {
                enabled: true,
                callbacks: {
                    title: function (tooltipItems, data) {
                        return data.datasets[0].data[tooltipItems[0].index].x + ' ' + self.convertDoubleToHHMM(data.datasets[0].data[tooltipItems[0].index].y);
                    },
                    label: function (tooltipItem, data) {
                        const phItem = self.dataList[tooltipItem.index].phItem;
                        const empName = phItem.employee.firstName + ' ' + phItem.employee.lastName;
                        return empName + ' ' + self.calculateAfterBeforeTime(phItem);
                    }
                }
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            userCallback: function (v) {
                                if (self.dataList.length === 0) {
                                    return '';
                                }
                                if (v >= 24) {
                                    return '23:59';
                                }
                                if (v === 0) {
                                    return '00:00';
                                }
                                return v < 10 ? '0' + v + ':00' : v + ':00';
                            },
                        }
                    }
                ]
            },
        };
    }

    convertDoubleToHHMM(value) {
        const temp = value.toString().split('.');
        const hours = +temp[0] < 10 ? ('0' + temp[0]) : temp[0];
        if (temp.length === 1) {
            return hours + ':00';
        }
        const minutes = +temp[1] < 10 ? ('0' + temp[1]) : temp[1];
        return hours + ':' + minutes;
    }
}
