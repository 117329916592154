import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../authenticate/auth.guard';
import { ArReportLayoutComponent } from './component/ar-report-layout/ar-report-layout.component';
import { RevenuePaymentComponent } from './component/revenue-payment/revenue-payment.component';
import { ServiceRevenueComponent } from './component/service-revenue/service-revenue.component';
import { GrossIncomeComponent } from './component/gross-income/gross-income.component';
import { ReceivableAgingDetailsComponent } from './component/receivable-aging-details/receivable-aging-details.component';
import { AgingSummaryComponent } from './component/aging-summary/aging-summary.component';
import { PermissionGuard } from 'app/authenticate/permission.guard';
import { MENU_ID } from 'app/shared/data/menu-id';


export const ArReportRoutes: Routes = [
  {
    path: 'app/revenue-payment',
    component: ArReportLayoutComponent,
    children: [
      { path: '', redirectTo: 'app/revenue-payment/list', pathMatch: 'full' },
      {
        path: 'list',
        component: RevenuePaymentComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { canView: [MENU_ID.REVENUE_PAYMENTS], canEdit: [] }
      },
    ]
  },
  {
    path: 'app/service-revenue',
    component: ArReportLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'app/service-revenue/list', pathMatch: 'full' },
      {
        path: 'list',
        component: ServiceRevenueComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { canView: [MENU_ID.SERVICE_REVENUE], canEdit: [] }
      }
    ]
  },
  {
    path: 'app/gross-income',
    component: ArReportLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'app/gross-income/list', pathMatch: 'full' },
      {
        path: 'list',
        component: GrossIncomeComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { canView: [MENU_ID.GROSS_INCOME], canEdit: [] }
      }
    ]
  },
  {
    path: 'app/receivable-aging-details',
    component: ArReportLayoutComponent,
    children: [
      { path: '', redirectTo: 'app/receivable-aging-details/list', pathMatch: 'full' },
      {
        path: 'list',
        component: ReceivableAgingDetailsComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { canView: [MENU_ID.AGING_DETAILS], canEdit: [] }
      },
    ]
  },
  {
    path: 'app/aging-summary',
    component: ArReportLayoutComponent,
    children: [
      { path: '', redirectTo: 'app/aging-summary/list', pathMatch: 'full' },
      {
        path: 'list',
        component: AgingSummaryComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { canView: [MENU_ID.AGING_SUMMARY], canEdit: [] }
      },
    ]
  }
];

export const ArReportRoutingModule = RouterModule.forChild(ArReportRoutes);
