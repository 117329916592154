<div class="spinner-overlay" *ngIf="loadingSpinner">
    <p-progressSpinner class="center-spin"></p-progressSpinner>
</div>
<p-toast position="bottom-right">{{msgs}}</p-toast>
<div class="card add-shadow p-4">
	<h1 class="m-0 ml-2 my-4">{{'Follow-Ups' | translate}}</h1>
	<p-panel [toggleable]="true">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Follow-Ups' | translate}}</span>
		</p-header>
        <div class="ui-g">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Method of Contact' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-folder-tree"></i>
						</button>
					</div>
					<p-dropdown [options]="contacts" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedMethodContact">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Agent' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" type="button" style="cursor:default !important">
							<i class="fa-solid fa-user-tie"></i>
						</button>
					</div>
					<p-multiSelect defaultLabel="{{'Select Items' | translate}}" [options]="agents" [(ngModel)]="agentList" [ngModelOptions]="{standalone: true}" [filter]="true" [panelStyle]="{'width': '100%'}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Client' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-briefcase"></i>
						</button>
					</div>
					<p-dropdown [options]="allClientList" [filter]="true" [(ngModel)]="selectedClient" [showClear]="true" (onChange)="getAllAddedProductsByClientId($event)" [disabled]="disableFields" placeholder="{{'pleaseSelect' | translate}}" >
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Date Range' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-week"></i>
						</button>
					</div>
					<mat-form-field class="w-100">
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate matInput placeholder="{{'Start date' | translate}}" [(ngModel)]="fromDate" (focus)="picker.open()">
							<input matEndDate matInput placeholder="{{'End date' | translate}}" [(ngModel)]="toDate" (focus)="picker.open()">
						</mat-date-range-input>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>
				</div>
			</div>
			<div class="ui-g-12 py-4 text-center">
				<button type="button" class="btn btn-danger mx-1" (click)="resetFilters()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
				<button type="button" class="btn btn-primary mx-1" (click)="search()"><i class="fa-solid fa-check mr-2"></i>{{'button.search' | translate}}</button>
			</div>
        </div>
    </p-panel>
	<div class="d-flex justify-content-start align-items-center py-4">
		<h1 class="m-0 ml-2">{{'Browse Follow-Ups' | translate}}</h1>
	</div>
    <p-panel id="table-ppanel" [toggleable]="true">
        <p-header class="flex-grow-1">
            <span><i class="fa-solid fa-table text-primary mr-2"></i> {{'Follow-Ups' | translate}} ({{totalRecords | number}})</span>
        </p-header>
        <p-contextMenu #cm [model]="items"></p-contextMenu>
        <p-table #dt (onFilter)="onFilter($event, dt)" [value]="contentList" dataKey="id" selectionMode="single" [lazy]="false" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10, 25, 50]" [totalRecords]="totalRecords" [contextMenu]="cm" [responsive]="true" (sortFunction)="customSort($event)" [customSort]="true"
                 [globalFilterFields]="['invoiceNumber', 'clientName', 'methodContact', 'note', 'collectionRepresentative', 'createdByUsr']">
            
			<ng-template pTemplate="caption">
				<div class="d-flex align-items-center justify-content-between" style="row-gap: 10px">
					<div class="d-flex">
						<button type="button" (click)="exportPdf()" class="btn btn-primary mx-1">
							<span class="text-nowrap"><i class="fa-solid fa-print mr-2"></i> {{'Export PDF' | translate}}</span>
						</button>
					</div>
					<div class="input-group w-100 mr-2" style="max-width: 450px">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-filter"></i>
							</button>
						</div>
						<input [(ngModel)]="searchText" (input)="dt.filterGlobal($event?.target?.value, 'contains')" placeholder="{{'Filter' | translate}}" type="text" pInputText>
					</div>
				</div>
			</ng-template>

            <ng-template pTemplate="header" let-columns>
				<tr>
					<ng-container *ngFor="let col of cols">
						<th [ngStyle]="{'width': col.width}" [attr.id]="col.label === 'followUpDate' ? 'followUpDate' : ''">		
							<ng-container [ngSwitch]="col.field">
								<span *ngSwitchDefault>
									<span class="d-flex justify-content-between align-items-center w-100">
										<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
										<span *ngIf="col.sort">
											<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field; setSortOption(col.field,col.sortOptions)">
												<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
											</span>
										</span>
									</span>
								</span>
								<span *ngSwitchCase="'createdAt'">
									<span class="d-flex justify-content-between align-items-center w-100">
										<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
										<span *ngIf="col.sort">
											<span (click)="col.sort == 'number'? op3.toggle($event) : op1.toggle($event); selectedField = col.field; setSortOption(col.field,col.sortOptions)">
												<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
											</span>
										</span>
									</span>
								</span>
								<span *ngSwitchCase="'followUpDate'">
									<span class="d-flex justify-content-between align-items-center w-100">
										<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
										<span *ngIf="col.sort">
											<span (click)="col.sort == 'number'? op3.toggle($event) : op1.toggle($event); selectedField = col.field; setSortOption(col.field,col.sortOptions)">
												<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
											</span>
										</span>
									</span>
								</span>
								<span *ngSwitchCase="'document'">
									<span class="d-flex justify-content-center w-100">
										<i class="fa-solid fa-upload text-muted m-auto" pTooltip="{{'Uploads' | translate}}" tooltipPosition="left"></i>
									</span>
								</span>
							</ng-container>
						</th>
					</ng-container>
					<th>
						<span class="d-flex justify-content-center w-100">
							<i class="fa-solid fa-bars text-muted m-auto" pTooltip="{{'Actions' | translate}}" tooltipPosition="left"></i>
						</span>
					</th>
				</tr>
			</ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Invoice' | translate}}: </span>
							<span *ngIf="rowData.invoiceNumber" class="text-primary c-pointer" (click)="exportPdfRow(rowData)" pTooltip="{{rowData.invoiceNumber}}">{{rowData.invoiceNumber}}</span>
							<span *ngIf="!rowData.invoiceNumber" class="text-muted">{{'no data' | translate}}</span>
						</span>
                    </td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Client' | translate}}: </span>
							<span *ngIf="rowData.clientName" pTooltip="{{rowData.clientName}}">{{rowData.clientName}}</span>
							<span *ngIf="!rowData.clientName" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Method' | translate}}: </span>
							<span *ngIf="rowData.methodContact" pTooltip="{{rowData.methodContact}}">{{rowData.methodContact}}</span>
							<span *ngIf="!rowData.methodContact" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Agent' | translate}}: </span>
							<span *ngIf="rowData.collectionRepresentative" pTooltip="{{rowData.collectionRepresentative}}">{{rowData.collectionRepresentative}}</span>
							<span *ngIf="!rowData.collectionRepresentative" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td id="uploads">
						<div class="d-flex align-items-center justify-content-start">
							<span class="mobile-table-label">{{'Attachment' | translate}}: </span>
							<p-dropdown *ngIf="!rowData.documentId" [(ngModel)]="rowData.uploadOption" [dropdownIcon]="this.attachment" [options]="this.noFileOptions" [style]="{'display':'table-cell','color' : '#5d5d5d','background-image' : 'none','font-size':'23px', 'min-width':'auto', 'border':'0','background': 'transparent','height':'20px'}" (onChange)="onChangeUploadOption($event, rowData)" optionLabel="label" placeholder="" [autoWidth]="false" appendTo="body" [editable]="true" styleClass="custom-input" pTooltip="{{'Attach' | translate}}"></p-dropdown>
							<p-dropdown *ngIf="rowData.documentId" [(ngModel)]="rowData.uploadOption" [dropdownIcon]="this.attachment1" [options]="this.hasFileOptions" class="green-color" [style]="{'display':'table-cell','color' : 'green','font-size':'23px','background-image':'none','min-width':'auto', 'border':'0','background': 'transparent','height':'20px'}" optionLabel="label" placeholder="" (onChange)="onChangeUploadOption($event, rowData)" [autoWidth]="false" appendTo="body" [editable]="true" styleClass="custom-input"  pTooltip="{{'Attach' | translate}}"></p-dropdown>
							<input type="file" id="documentId" style="display: none;" (change)="onSelectDocument($event)">
						</div>
					</td>
                    <td>
						<span class="d-flex text-truncate" style="height: 20px;">
							<span class="mobile-table-label">{{'Note' | translate}}: </span>
							<span *ngIf="rowData.note" [innerHTML]="rowData.note" style="display: contents"></span>
							<span *ngIf="!rowData.note" class="text-muted">{{'no data' | translate}}</span>
						</span>
                    </td>
                    <td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Creator' | translate}}: </span>
							<span *ngIf="rowData.createdByUsr" pTooltip="{{rowData.createdByUsr}}">{{rowData.createdByUsr}}</span>
							<span *ngIf="!rowData.createdByUsr" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Created' | translate}}: </span>
							<span *ngIf="rowData.createdAt" pTooltip="{{rowData.createdAt | date: 'MM/dd/yyyy HH:mm a'}}">{{rowData.createdAt | date: 'MM/dd/yyyy HH:mm a'}}</span>
							<span *ngIf="!rowData.createdAt" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td *ngIf="isPlatformAdmin">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Follow Up' | translate}}: </span>
							<span *ngIf="rowData.invoiceDueDate" pTooltip="{{rowData.invoiceDueDate | date: 'MM/dd/yyyy HH:mm a'}}">{{rowData.invoiceDueDate | date: 'MM/dd/yyyy HH:mm a'}}</span>
							<span *ngIf="!rowData.invoiceDueDate" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
                    <td>
						<span class="d-flex align-items-center">
							<span class="mobile-table-label">{{'Actions' | translate}}: </span>
							<i class="fa-solid fa-pen-to-square text-primary mx-1" (click)="openViewDialog(rowData)" pTooltip="{{'View/Edit' | translate}}" tooltipPosition="left" style="cursor: pointer;"></i>
							<i class="fa-solid fa-print text-purple c-pointer mx-1" (click)="exportPdfRow(rowData)" pTooltip="{{'Export PDF' | translate}}" tooltipPosition="left" style="cursor: pointer;"></i>
							<i class="fa-solid fa-xmark text-danger c-pointer mx-1" *ngIf="showDeleteButton(rowData)" (click)="openConfirmDeleteDialog(rowData)" pTooltip="{{'Delete' | translate}}" tooltipPosition="left" style="cursor: pointer;"></i>
							<i class="fa-solid fa-xmark text-muted c-pointer mx-1" *ngIf="!showDeleteButton(rowData)" pTooltip="{{'Disallowed' | translate}}" tooltipPosition="left"></i>
						</span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr *ngIf="loading">
                    <td [attr.colspan]="isPlatformAdmin ? 10 : 9" class="text-center py-5">
                        <span class="text-muted">{{'Loading...' | translate}}</span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr *ngIf="!loading" class="text-center">
                    <td [attr.colspan]="isPlatformAdmin ? 10 : 9" class="text-center py-5">
                        <span class="text-muted">{{'no data' | translate}}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <p-dialog header="Invoice Details" styleClass="w-50" [modal]="true" [responsive]="true" [autoZIndex]="true"
            [(visible)]="detailEnable" [contentStyle]="{'max-height':'400px'}">
            <div class="ui-g invoice-details">
                <div id="contentToConvert" style="padding:50px;">
                    <div style="text-align: center;">
                        <img src="../../../../assets/punch-images/operr_logo.jpg">
                    </div>
                </div>
                <div class="ui-g-12">
                    <p style="text-align: center;">
                        <b>{{'INVOICE' | translate}}</b>
                    </p>
                </div>
                <div class="ui-g-12">
                    <div class="ui-g-6">
                        {{'Invoice Number' | translate}}: {{invoiceDetail.invoiceNumber}}
                    </div>
                </div>
                <div style="text-align: right">
                    {{'Date' | translate}}: {{invoiceDetail.invoiceDate | date: 'dd/MM/yyyy HH:mm'}}
                </div>
                <div class="ui-g-12">
                    <div class="ui-g-8 pb-0 ui-g-offset-2">
                        <p-table [value]="details">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th><b>{{'Charge Item' | translate}}</b></th>
                                    <th><b>{{'Amount' | translate}}</b></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-detail>
                                <tr>
                                    <td>{{detail?.name}}</td>
                                    <td>
                                        <div style="text-align: right">
                                            {{detail?.value?.toFixed(2)}}
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer" let-columns>
                                <tr>
                                    <td>
                                        <b>{{'Total' | translate}}:</b>
                                    </td>
                                    <td>
                                        <div style="text-align: right">
                                            ${{invoiceDetail?.totalAmount?.toFixed(2)}}
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
                <div class="ui-g-12">
                    <b>{{'Please make to payment' | translate}}</b>
                </div>
                <div class="ui-g-12" style="text-align: center;">
                    <button pButton type="button" label="{{'Ok' | translate}}" (click)="cancelDialog()"></button>
                </div>
            </div>
        </p-dialog>
    </p-panel>
</div>


<p-overlayPanel #op1 appendTo="body">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption, $event)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption, $event)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op3 appendTo="body">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption, $event)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>



<p-dialog [(visible)]="showConfirmDialog" class="modal_in" [modal]="true" [style]="{'width':'90%', 'max-width':'500px'}">
	<p-header>
		<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Confirm Action' | translate}}</span>
	</p-header>
    <div class="ui-g">
       <div class="ui-g-12">
			<span style="font-size: 16px">{{message}}</span>
	   </div>
    </div>
    <p-footer class="d-flex justify-content-between">
		<button type="button" class="btn btn-danger" (click)="closeConfirmDeleteDialog()"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button type="button" class="btn btn-primary" (click)="deleteFollowUpNote()"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="showViewFileDialog" [responsive]="true" [modal]="true" showEffect="fade"  [style]="{'width':'90%', 'max-width':'600px', 'max-height':'85vh'}">
	<p-header>
		<span><i class="fa-solid fa-image mr-2"></i>{{'View Attached' | translate}}</span>
	</p-header>
    <img [src]="fileUrl" *ngIf="fileUrl" class="imageShown w-100" />
    <p-footer class="d-flex justify-content-end">
		<button type="button" (click)="closeViewFileDialog()" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
    </p-footer>
</p-dialog>

<p-dialog header="History" [(visible)]="showHistoryDialog" [responsive]="true" showEffect="fade"
    [style]="{width: '30vw'}" [closable]="true" [modal]="true">
    <span> Coming Soon ...</span>
    <p-footer>
        <button type="button" pButton (click)="showHistoryDialog = false" label="Close"></button>
    </p-footer>
</p-dialog>


    <!--Collection Note-->
    <p-dialog [(visible)]="isOpenCollectionNote" [responsive]="true" baseZIndex="99999" [modal]="true" [dismissableMask]="true" [style]="{'width':'90%', 'max-width':'900px'}">
		<p-header>
			<span><i class="fa-solid fa-circle-plus mr-2"></i>{{headerNote}}: {{followUpDate | date: 'MM/dd/yyyy hh:mm'}}</span>
		</p-header>
        <div class="ui-g">
            <div class="ui-g-6 ui-sm-12">
                <label>{{'Client' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-briefcase"></i>
						</button>
					</div>
					<input *ngIf="paymentInvoiceData.clientName" pInputText [(ngModel)]="paymentInvoiceData.clientName" type="text" disabled>
					<div *ngIf="!paymentInvoiceData.clientName" class="form-control"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'no data' | translate}}</span></div>
				</div>
            </div>
			<div class="ui-g-6 ui-sm-12">
                <label>{{'Invoice #' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-hashtag"></i>
						</button>
					</div>
					<input *ngIf="paymentInvoiceData.invoiceNumber" pInputText [(ngModel)]="paymentInvoiceData.invoiceNumber" type="text" disabled>
					<div *ngIf="!paymentInvoiceData.invoiceNumber" class="form-control"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'no data' | translate}}</span></div>
				</div>
            </div>
			<div class="ui-g-6 ui-sm-12">
                <label>{{'Invoice Dated' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-days"></i>
						</button>
					</div>
					<input *ngIf="paymentInvoiceData.invoiceDate"  pInputText type="text" [value]="paymentInvoiceData.invoiceDate | date: 'MM/dd/yyyy HH:mm'" disabled>
					<div *ngIf="!paymentInvoiceData.invoiceDate" class="form-control"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'no data' | translate}}</span></div>
				</div>
            </div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Due Date' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-days"></i>
						</button>
					</div>
					<p-calendar [style]="{'flex-grow': '1'}" [inputStyle]="{'width':'100%'}" [showIcon]="false" placeholder="Date" [(ngModel)]="followUpInvoiceDueDate"
								[disabled]="collectionTypeSelected !== 'Delay Payment Requested' || followUpCount > 2 || disabledExtendDueDate || isOverTime"></p-calendar>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
                <label>{{'Balance' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i *ngIf="paymentInvoiceData.balanceTxt > 0" class="fa-solid fa-dollar-sign"></i>
							<i *ngIf="paymentInvoiceData.balanceTxt <= 0" class="fa-solid fa-circle-check text-success"></i>
						</button>
					</div>
					<input *ngIf="paymentInvoiceData.balanceTxt" pInputText [(ngModel)]="paymentInvoiceData.balanceTxt" type="text" disabled>
					<div *ngIf="!paymentInvoiceData.balanceTxt" class="form-control"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'no data' | translate}}</span></div>
				</div>
            </div>
			<div class="ui-g-6 ui-sm-12">
                <label>{{'Invoice Status' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-info"></i>
						</button>
					</div>
					<input *ngIf="paymentInvoiceData.status" pInputText [(ngModel)]="paymentInvoiceData.status" type="text" disabled>
					<div *ngIf="!paymentInvoiceData.status" class="form-control"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'no data' | translate}}</span></div>
				</div>
            </div>

			<div class="ui-g-6 ui-sm-12">
                <label>{{'Collection Agent' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-user-tie"></i>
						</button>
					</div>
					<p-dropdown class="w-100" [options]="assignedToEmployeeList" [filter]="true" [(ngModel)]="selectedCollectionPresentative"
								[showClear]="true" [style]="{'flex-grow': '1'}" placeholder="{{'pleaseSelect' | translate}}" [disabled]="isOverTime"></p-dropdown>
				</div>
            </div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Collection Type' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-folder-tree"></i>
						</button>
					</div>
					<p-dropdown [options]="collectionTypeOptions" [(ngModel)]="collectionTypeSelected" [style]="{'flex-grow': '1'}"  placeholder="Choose method" [disabled]="isOverTime"></p-dropdown>
				</div>
			</div>

			<div class="ui-g-6 ui-sm-12">
                <label>{{'Contact Method' | translate}}:<span class="text-danger">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-message"></i>
						</button>
					</div>
					<p-dropdown [options]="contacts" [(ngModel)]="selectedContact" [style]="{'flex-grow': '1'}" placeholder="Choose method" [disabled]="isOverTime"></p-dropdown>
				</div>
            </div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Count' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-hashtag"></i>
						</button>
					</div>
					<input type="text" pInputText [(ngModel)]="followUpCount" disabled/>
				</div>
			</div>
		
		<!-- FIELDS NOT YET FUNCTIONAL 
		
			<div class="ui-g-6 ui-sm-12">
                <label>{{'Amount Due' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-dollar-sign"></i>
						</button>
					</div>
					<div class="form-control nodata"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'not functional' | translate}}</span></div>
				</div>
            </div>
			<div class="ui-g-6 ui-sm-12">
                <label>{{'Contact Phone' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-phone"></i>
						</button>
					</div>
					<div class="form-control nodata"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'not functional' | translate}}</span></div>
				</div>
            </div>
			<div class="ui-g-6 ui-sm-12">
                <label>{{'Support' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-user-tie"></i>
						</button>
					</div>
					<div class="form-control nodata"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'not functional' | translate}}</span></div>
				</div>
            </div>
			<div class="ui-g-6 ui-sm-12">
                <label>{{'Assignee' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-user"></i>
						</button>
					</div>
					<div class="form-control nodata"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'not functional' | translate}}</span></div>
				</div>
            </div>
-->
			
			<div class="ui-g-12 ui-sm-12">
                <label>{{'Note' | translate}}:<span class="text-danger">*</span> </label>
				<p-editor #notesEditor (onTextChange)="textChanged1($event)" (onInit)="setEditor($event, 'concern')" (closed)="boldMention()" [readonly]="isOverTime"
						  [mentionConfig]="{triggerChar:'@',maxItems:10,labelKey:'value'}" [mention]="assignedToEmployeeList" [(ngModel)]="followUpNote" [style]="{'height':'170px'}" >
					<p-header>
						<span class="ql-formats">
							<button class="ql-bold" aria-label="Bold"></button>
							<button class="ql-italic" aria-label="Italic"></button>
							<button class="ql-underline" aria-label="Underline"></button>
							<button class="ql-strike" aria-label="Strike"></button>
						</span>
						<span class="ql-formats">
							<select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)">
								<option value="rgb(0, 0, 0)" label="rgb(0, 0, 0)"></option>
								<option value="rgb(104 104 104)" label="rgb(104 104 104)"></option>
								<option value="rgb(222 58 58)" label="rgb(222 58 58)"></option>
								<option value="rgb(255 99 0)" label="rgb(255 99 0)"></option>
								<option value="rgb(90 171 29)" label="rgb(90 171 29)"></option>
								<option value="rgb(0 123 255)" label="rgb(0 123 255)"></option>
							</select>
							<span class="ql-format-separator"></span>
						</span>
						<span class="ql-formats">
							<button class="ql-list" title="List"></button>
							<button class="ql-bullet" title="Bullet"></button>         
						</span> 
					</p-header>
				</p-editor>
				<span *ngIf="500 - charactersLength > 0" class="text-muted pull-right" style="font-size: 12px">{{500 - charactersLength}} {{'characters left' | translate}}</span>
				<span *ngIf="500 - charactersLength <= 0" class="text-danger pull-right" style="font-size: 12px">{{500 - charactersLength}} {{'characters left' | translate}}</span>
            </div>
        </div>
		<p-footer class="d-flex align-items-center justify-content-between">
			<button class="btn btn-danger" (click)="closeDialog()"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
			<button class="btn btn-primary" [disabled]="followUpNote === '' || (followUpNote && followUpNote.length < 25)  || selectedCollectionPresentative === '' || selectedContact === '' || isOverTime"
					(click)="saveCollectionNote()"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
		</p-footer>
    </p-dialog>
<p-dialog *ngIf="showHistoryDialog" [(visible)]="showHistoryDialog" [responsive]="true" showEffect="fade" [style]="{'width': '100%', 'max-width':'900px'}" [closable]="true" [modal]="true">
	<p-header>
		<span><i class="fa-solid fa-clock-rotate-left mr-2"></i>{{'Upload History' | translate}}</span>
	</p-header>
    <app-payment-follow-up-history appendTo="body" [jobId]="selectedFollowUpNote.id" [historyType]="historyType"></app-payment-follow-up-history>
    <p-footer class="d-flex justify-content-end">
		<button type="button" (click)="showHistoryDialog = false" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
    </p-footer>
</p-dialog>
