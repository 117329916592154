import { EmployeeClockingServiceV2 } from 'app/employee-clocking/service/v2/employee.clocking.v2.service';
import { EmployeeJobReportNoteService } from 'app/employee/service/employee-job-report-note.service';
import { EmployeeJobReportService } from 'app/employee/service/employee-job-report.service';
import { CompanyService } from 'app/company/service/company.service';
import { AuthService } from 'app/shared/service/auth/auth.service';
import { Message, MessageService } from 'primeng/api';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { EmployeeService } from 'app/employee/service/employee.service';
import { PlatformAdminService } from 'app/admin/platform-admin/service/platform-admin.service';
import { EmployeeJobReport } from 'app/employee/model/employee-job-report.model';
import { DocumentsServicev2 } from 'app/shared/service/documents-upload/document.v2.service';
import { TranslatePipe } from '@ngx-translate/core';
import { Editor, OverlayPanel } from 'primeng';
import { DocumentsService } from 'app/shared/service/documents-upload/documents.service';
import { FormValidatorService } from 'app/shared/service/form-validator/form-validator.service';
import { RESPONSE_STATUS } from 'app/shared/model/constants';
import * as moment from 'moment-timezone';
import { EmployeeJobReportNote } from 'app/employee/model/employee-job-report-note.model';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from 'app/shared/data/CustomNgxDatetimeAdapter';
import { DATE_FORMATS } from 'app/shared/data/config-common';
import { EmployeeClockingService } from 'app/employee-clocking/service/employee-clocking.service';
import { CompanySettingServiceV2 } from 'app/company/service/company-setting.v2.service';
import Quill from 'quill';
import AutoLinks from 'quill-auto-links';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { OperatorService } from 'app/employee/service/v2/operator.v2.service';
import { DatePipe } from '@angular/common';

declare let $: any;

@Component({
  selector: 'app-employee-job-report-form',
  templateUrl: './employee-job-report-form.component.html',
  styleUrls: ['./employee-job-report-form.component.scss'],
  providers: [FormValidatorService, AuthService, CompanyService, EmployeeService, EmployeeJobReportService, EmployeeJobReportNoteService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }
  ]
})
export class EmployeeJobReportFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() mode: String;
  @Input() readOnly: Boolean = false;
  @Input() employeeJobReportId: number | null;
  disableInput = false;
  loadingTimeSpent: Boolean = false;
  msgs: Message[] = [];
  employeeJobReportForm: UntypedFormGroup;
  @Input() employeeJobReportDataForm: ElementRef;
  employeeJobReport: EmployeeJobReport;
  isPlatformAdmin = false;
  companies: any = [];
  companySelected: any;
  companyId: any;
  company: any;
  maxDate: any;
  noteNotFix: any = "";
  indexNotFix: any = -1;
  employees: any = [];
  employeeSelected: any;
  @ViewChildren('upload') uploadFileRef: QueryList<ElementRef>;
  showViewFileDialog = false;
  fileUrl: string;
  historyHeader: string;
  showHistoryDialog: boolean;
  historyType: string;
  isClicked = false;
  timeSpent = new Date();
  allEmployeeJobReportNote: any;
  allEmployeeJobReportNoteLength: number;
  employeeJobReportNote: EmployeeJobReportNote = new EmployeeJobReportNote();
  editor: any;
  editorReason: any;
  @ViewChild('detailsEditor', { static: true }) detailsEditor: Editor;
  @ViewChild('detailsEditorReason', { static: true }) detailsEditorReason: Editor;
  previewImage = '';
  mentionEmployees: any[];
  exceededMaxLength = false;
  exceededMaxLengthReason = false;
  exceededMinLength = false;
  exceededMinLengthReason = false;
  charactersNumber = 0;
  charactersNumberReason = 0;
  onlyWhitespaceError = false;
  onlyWhitespaceErrorReason = false;
  MAX_LENGTH = 5000;
  descriptionReport: boolean = true;
  descriptionReportReason: boolean = true;
  showNoteReason: boolean = false;
  hourCols: any[] = [
    { field: 'code', label: 'Time Slots', sortOptions: '', sort: 'text' },
    { field: 'status', label: 'Status', sortOptions: '', sort: 'text' },
  ];
  reportDateNgModel: Date;
  currentIndex = -1;
  listHours = [
    { name: '12:00 AM - 12:59 AM', code: '12:00 AM - 12:59 AM', checked: false, disabled: true, status: '' },
    { name: '01:00 AM - 01:59 AM', code: '01:00 AM - 01:59 AM', checked: false, disabled: true, status: '' },
    { name: '02:00 AM - 02:59 AM', code: '02:00 AM - 02:59 AM', checked: false, disabled: true, status: '' },
    { name: '03:00 AM - 03:59 AM', code: '03:00 AM - 03:59 AM', checked: false, disabled: true, status: '' },
    { name: '04:00 AM - 04:59 AM', code: '04:00 AM - 04:59 AM', checked: false, disabled: true, status: '' },
    { name: '05:00 AM - 05:59 AM', code: '05:00 AM - 05:59 AM', checked: false, disabled: true, status: '' },
    { name: '06:00 AM - 06:59 AM', code: '06:00 AM - 06:59 AM', checked: false, disabled: true, status: '' },
    { name: '07:00 AM - 07:59 AM', code: '07:00 AM - 07:59 AM', checked: false, disabled: true, status: '' },
    { name: '08:00 AM - 08:59 AM', code: '08:00 AM - 08:59 AM', checked: false, disabled: true, status: '' },
    { name: '09:00 AM - 09:59 AM', code: '09:00 AM - 09:59 AM', checked: false, disabled: true, status: '' },
    { name: '10:00 AM - 10:59 AM', code: '10:00 AM - 10:59 AM', checked: false, disabled: true, status: '' },
    { name: '11:00 AM - 11:59 AM', code: '11:00 AM - 11:59 AM', checked: false, disabled: true, status: '' },
    { name: '12:00 PM - 12:59 PM', code: '12:00 PM - 12:59 PM', checked: false, disabled: true, status: '' },
    { name: '01:00 PM - 01:59 PM', code: '01:00 PM - 01:59 PM', checked: false, disabled: true, status: '' },
    { name: '02:00 PM - 02:59 PM', code: '02:00 PM - 02:59 PM', checked: false, disabled: true, status: '' },
    { name: '03:00 PM - 03:59 PM', code: '03:00 PM - 03:59 PM', checked: false, disabled: true, status: '' },
    { name: '04:00 PM - 04:59 PM', code: '04:00 PM - 04:59 PM', checked: false, disabled: true, status: '' },
    { name: '05:00 PM - 05:59 PM', code: '05:00 PM - 05:59 PM', checked: false, disabled: true, status: '' },
    { name: '06:00 PM - 06:59 PM', code: '06:00 PM - 06:59 PM', checked: false, disabled: true, status: '' },
    { name: '07:00 PM - 07:59 PM', code: '07:00 PM - 07:59 PM', checked: false, disabled: true, status: '' },
    { name: '08:00 PM - 08:59 PM', code: '08:00 PM - 08:59 PM', checked: false, disabled: true, status: '' },
    { name: '09:00 PM - 09:59 PM', code: '09:00 PM - 09:59 PM', checked: false, disabled: true, status: '' },
    { name: '10:00 PM - 10:59 PM', code: '10:00 PM - 10:59 PM', checked: false, disabled: true, status: '' },
    { name: '11:00 PM - 11:59 PM', code: '11:00 PM - 11:59 PM', checked: false, disabled: true, status: '' },
  ];
  listUpdateHours = [
    { name: '12:00 AM - 12:59 AM', code: '12:00 AM - 12:59 AM', checked: false },
    { name: '01:00 AM - 01:59 AM', code: '01:00 AM - 01:59 AM', checked: false },
    { name: '02:00 AM - 02:59 AM', code: '02:00 AM - 02:59 AM', checked: false },
    { name: '03:00 AM - 03:59 AM', code: '03:00 AM - 03:59 AM', checked: false },
    { name: '04:00 AM - 04:59 AM', code: '04:00 AM - 04:59 AM', checked: false },
    { name: '05:00 AM - 05:59 AM', code: '05:00 AM - 05:59 AM', checked: false },
    { name: '06:00 AM - 06:59 AM', code: '06:00 AM - 06:59 AM', checked: false },
    { name: '07:00 AM - 07:59 AM', code: '07:00 AM - 07:59 AM', checked: false },
    { name: '08:00 AM - 08:59 AM', code: '08:00 AM - 08:59 AM', checked: false },
    { name: '09:00 AM - 09:59 AM', code: '09:00 AM - 09:59 AM', checked: false },
    { name: '10:00 AM - 10:59 AM', code: '10:00 AM - 10:59 AM', checked: false },
    { name: '11:00 AM - 11:59 AM', code: '11:00 AM - 11:59 AM', checked: false },
    { name: '12:00 PM - 12:59 PM', code: '12:00 PM - 12:59 PM', checked: false },
    { name: '01:00 PM - 01:59 PM', code: '01:00 PM - 01:59 PM', checked: false },
    { name: '02:00 PM - 02:59 PM', code: '02:00 PM - 02:59 PM', checked: false },
    { name: '03:00 PM - 03:59 PM', code: '03:00 PM - 03:59 PM', checked: false },
    { name: '04:00 PM - 04:59 PM', code: '04:00 PM - 04:59 PM', checked: false },
    { name: '05:00 PM - 05:59 PM', code: '05:00 PM - 05:59 PM', checked: false },
    { name: '06:00 PM - 06:59 PM', code: '06:00 PM - 06:59 PM', checked: false },
    { name: '07:00 PM - 07:59 PM', code: '07:00 PM - 07:59 PM', checked: false },
    { name: '08:00 PM - 08:59 PM', code: '08:00 PM - 08:59 PM', checked: false },
    { name: '09:00 PM - 09:59 PM', code: '09:00 PM - 09:59 PM', checked: false },
    { name: '10:00 PM - 10:59 PM', code: '10:00 PM - 10:59 PM', checked: false },
    { name: '11:00 PM - 11:59 PM', code: '11:00 PM - 11:59 PM', checked: false },
  ];
  isEmployeeRole = false;
  loadingUpload = false;
  isExpand = false;
  jrAllowExpand = false;
  jrSendNotifyEvery: string;
  listHourTemp: any = [];
  listHourNews: any = [];
  noteByHour: any;
  titleNoteByHour: any;
  indexNoteByHour: number = 0;
  selectedHourItem: any;
  createdAtNoteByHour: any;
  companySetting: any;
  quillConfig = {
    autoLinks: true
  };
  showCreateWorkOrderDialog: boolean = false;

  reportDateTemp: any;
  constructor(
    private companyService: CompanyService,
    private operatorService: OperatorService,
    private datePipe: DatePipe,
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router,
    private monitoringDetailsService: MonitoringDetailsService,
    private employeeService: EmployeeService,
    private platformAdminService: PlatformAdminService,
    private documentServicev2: DocumentsServicev2,
    private translatePipe: TranslatePipe,
    private documentsService: DocumentsService,
    private formValidator: FormValidatorService,
    private employeeJobReportService: EmployeeJobReportService,
    private employeeJobReportNoteService: EmployeeJobReportNoteService,
    private employeeClockingService: EmployeeClockingService,
    private companySettingService: CompanySettingServiceV2,
    private employeeClockingServiceV2: EmployeeClockingServiceV2,
    private route: ActivatedRoute,
  ) {
    Quill.register('modules/autoLinks', AutoLinks);

    this.route.queryParams.subscribe(params => {
      this.reportDateTemp = params['reportDate'];
    });
  }

  ngOnInit() {
    this.messageService.clear();
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    this.employeeJobReport = new EmployeeJobReport();
    this.isEmployeeRole = this.authService.isEmployeeRole();
    this.init();
    if (this.employeeJobReportId) {
      this.mode = 'edit';
      this.monitoringDetailsService.monitorAction(
        `Navigated to Edit Job Report`,
        this.timeSpent,
        {
          navigated_to_employee_job_report_edit_page_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
        },
        'complete',
        `Navigated to Edit Job Report`,
        0
      );
    } else {
      this.mode = 'create';
      this.monitoringDetailsService.monitorAction(
        `Navigated to Add Job Report`,
        this.timeSpent,
        {
          navigated_to_employee_job_report_add_page_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
        },
        'complete',
        `Navigated to Job Add Job Report`,
        0
      );
    }

    this.maxDate = new Date();
    this.checkClockingIn();

    if (!this.isExpand && this.listHourTemp.length === 0) {
      this.listHourTemp = [...this.listHourNews];
    }
    //     document.addEventListener('click', function(e) {
    //         var href = (e.target as HTMLElement).closest('a').href || '';
    //         if (href.indexOf("app/workorder/view") > -1) {
    //           window.open(href, '_blank');
    //         }
    //     }, false);
  }
  showListHour() {
    if (this.jrSendNotifyEvery == '1') {
      this.listHours = [
        { name: '12:00 AM - 12:59 AM', code: '12:00 AM - 12:59 AM', checked: false, disabled: true, status: '' },
        { name: '01:00 AM - 01:59 AM', code: '01:00 AM - 01:59 AM', checked: false, disabled: true, status: '' },
        { name: '02:00 AM - 02:59 AM', code: '02:00 AM - 02:59 AM', checked: false, disabled: true, status: '' },
        { name: '03:00 AM - 03:59 AM', code: '03:00 AM - 03:59 AM', checked: false, disabled: true, status: '' },
        { name: '04:00 AM - 04:59 AM', code: '04:00 AM - 04:59 AM', checked: false, disabled: true, status: '' },
        { name: '05:00 AM - 05:59 AM', code: '05:00 AM - 05:59 AM', checked: false, disabled: true, status: '' },
        { name: '06:00 AM - 06:59 AM', code: '06:00 AM - 06:59 AM', checked: false, disabled: true, status: '' },
        { name: '07:00 AM - 07:59 AM', code: '07:00 AM - 07:59 AM', checked: false, disabled: true, status: '' },
        { name: '08:00 AM - 08:59 AM', code: '08:00 AM - 08:59 AM', checked: false, disabled: true, status: '' },
        { name: '09:00 AM - 09:59 AM', code: '09:00 AM - 09:59 AM', checked: false, disabled: true, status: '' },
        { name: '10:00 AM - 10:59 AM', code: '10:00 AM - 10:59 AM', checked: false, disabled: true, status: '' },
        { name: '11:00 AM - 11:59 AM', code: '11:00 AM - 11:59 AM', checked: false, disabled: true, status: '' },
        { name: '12:00 PM - 12:59 PM', code: '12:00 PM - 12:59 PM', checked: false, disabled: true, status: '' },
        { name: '01:00 PM - 01:59 PM', code: '01:00 PM - 01:59 PM', checked: false, disabled: true, status: '' },
        { name: '02:00 PM - 02:59 PM', code: '02:00 PM - 02:59 PM', checked: false, disabled: true, status: '' },
        { name: '03:00 PM - 03:59 PM', code: '03:00 PM - 03:59 PM', checked: false, disabled: true, status: '' },
        { name: '04:00 PM - 04:59 PM', code: '04:00 PM - 04:59 PM', checked: false, disabled: true, status: '' },
        { name: '05:00 PM - 05:59 PM', code: '05:00 PM - 05:59 PM', checked: false, disabled: true, status: '' },
        { name: '06:00 PM - 06:59 PM', code: '06:00 PM - 06:59 PM', checked: false, disabled: true, status: '' },
        { name: '07:00 PM - 07:59 PM', code: '07:00 PM - 07:59 PM', checked: false, disabled: true, status: '' },
        { name: '08:00 PM - 08:59 PM', code: '08:00 PM - 08:59 PM', checked: false, disabled: true, status: '' },
        { name: '09:00 PM - 09:59 PM', code: '09:00 PM - 09:59 PM', checked: false, disabled: true, status: '' },
        { name: '10:00 PM - 10:59 PM', code: '10:00 PM - 10:59 PM', checked: false, disabled: true, status: '' },
        { name: '11:00 PM - 11:59 PM', code: '11:00 PM - 11:59 PM', checked: false, disabled: true, status: '' },
      ];
    } else if (this.jrSendNotifyEvery == '2') {
      this.listHours = [
        { name: '12:00 AM - 01:59 AM', code: '12:00 AM - 01:59 AM', checked: false, disabled: true, status: '' },
        { name: '02:00 AM - 03:59 AM', code: '02:00 AM - 03:59 AM', checked: false, disabled: true, status: '' },
        { name: '04:00 AM - 05:59 AM', code: '04:00 AM - 05:59 AM', checked: false, disabled: true, status: '' },
        { name: '06:00 AM - 07:59 AM', code: '06:00 AM - 07:59 AM', checked: false, disabled: true, status: '' },
        { name: '08:00 AM - 09:59 AM', code: '08:00 AM - 09:59 AM', checked: false, disabled: true, status: '' },
        { name: '10:00 AM - 11:59 AM', code: '10:00 AM - 11:59 AM', checked: false, disabled: true, status: '' },
        { name: '12:00 PM - 01:59 PM', code: '12:00 PM - 01:59 PM', checked: false, disabled: true, status: '' },
        { name: '02:00 PM - 03:59 PM', code: '02:00 PM - 03:59 PM', checked: false, disabled: true, status: '' },
        { name: '04:00 PM - 05:59 PM', code: '04:00 PM - 05:59 PM', checked: false, disabled: true, status: '' },
        { name: '06:00 PM - 07:59 PM', code: '06:00 PM - 07:59 PM', checked: false, disabled: true, status: '' },
        { name: '08:00 PM - 09:59 PM', code: '08:00 PM - 09:59 PM', checked: false, disabled: true, status: '' },
        { name: '10:00 PM - 11:59 PM', code: '10:00 PM - 11:59 PM', checked: false, disabled: true, status: '' }
      ];
    } else if (this.jrSendNotifyEvery == '3') {
      this.listHours = [
        { name: '12:00 AM - 02:59 AM', code: '12:00 AM - 02:59 AM', checked: false, disabled: true, status: '' },
        { name: '03:00 AM - 05:59 AM', code: '03:00 AM - 05:59 AM', checked: false, disabled: true, status: '' },
        { name: '06:00 AM - 08:59 AM', code: '06:00 AM - 08:59 AM', checked: false, disabled: true, status: '' },
        { name: '09:00 AM - 11:59 AM', code: '09:00 AM - 11:59 AM', checked: false, disabled: true, status: '' },
        { name: '12:00 PM - 02:59 PM', code: '12:00 PM - 02:59 PM', checked: false, disabled: true, status: '' },
        { name: '03:00 PM - 05:59 PM', code: '03:00 PM - 05:59 PM', checked: false, disabled: true, status: '' },
        { name: '06:00 PM - 08:59 PM', code: '06:00 PM - 08:59 PM', checked: false, disabled: true, status: '' },
        { name: '09:00 PM - 11:59 PM', code: '09:00 PM - 11:59 PM', checked: false, disabled: true, status: '' }
      ];
    } else if (this.jrSendNotifyEvery == '4') {
      this.listHours = [
        { name: '12:00 AM - 03:59 AM', code: '12:00 AM - 03:59 AM', checked: false, disabled: true, status: '' },
        { name: '04:00 AM - 07:59 AM', code: '04:00 AM - 07:59 AM', checked: false, disabled: true, status: '' },
        { name: '08:00 AM - 11:59 AM', code: '08:00 AM - 11:59 AM', checked: false, disabled: true, status: '' },
        { name: '12:00 PM - 03:59 PM', code: '12:00 PM - 03:59 PM', checked: false, disabled: true, status: '' },
        { name: '04:00 PM - 07:59 PM', code: '04:00 PM - 07:59 PM', checked: false, disabled: true, status: '' },
        { name: '08:00 PM - 11:59 PM', code: '08:00 PM - 11:59 PM', checked: false, disabled: true, status: '' }
      ];
    } else if (this.jrSendNotifyEvery == '6') {
      this.listHours = [
        { name: '12:00 AM - 05:59 AM', code: '12:00 AM - 05:59 AM', checked: false, disabled: true, status: '' },
        { name: '06:00 AM - 11:59 AM', code: '06:00 AM - 11:59 AM', checked: false, disabled: true, status: '' },
        { name: '12:00 PM - 05:59 PM', code: '12:00 PM - 05:59 PM', checked: false, disabled: true, status: '' },
        { name: '06:00 PM - 11:59 PM', code: '06:00 PM - 11:59 PM', checked: false, disabled: true, status: '' }
      ];
    } else if (this.jrSendNotifyEvery == '8') {
      this.listHours = [
        { name: '12:00 AM - 06:59 AM', code: '12:00 AM - 06:59 AM', checked: false, disabled: true, status: '' },
        { name: '07:00 AM - 02:59 PM', code: '07:00 AM - 02:59 PM', checked: false, disabled: true, status: '' },
        { name: '03:00 PM - 11:59 PM', code: '03:00 PM - 11:59 PM', checked: false, disabled: true, status: '' }
      ];
    }
  }

  init() {
    this.disableInput = false;
    this.readOnly = false;
    this.companyId = this.authService.getCurrentCompany() ? this.authService.getCurrentCompany() : 0;
    this.employeeJobReportForm = new UntypedFormGroup({
      company: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
      userName: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
      reportDate: new UntypedFormControl({ value: '', disabled: this.readOnly }, Validators.required),
      concern: new UntypedFormControl({ value: '', disabled: this.readOnly }),
      details: new UntypedFormControl({ value: '' }),
      detailsReason: new UntypedFormControl({ value: '' }),
      selectedHours: new UntypedFormControl({ value: '' }),
    });

    this.companySettingService.getCompanySettingByCompanyId(this.companyId).subscribe(com => {
      if (com.status === 'SUCCESS') {
        this.companySetting = com.data;
        this.jrAllowExpand = this.companySetting.jrAllowExpand;
        this.jrSendNotifyEvery = this.companySetting.jrSendNotifyEvery;
        this.showListHour()
        if (this.employeeJobReportId) {
          // this.disableInput = true;
          // this.readOnly = true;
          this.employeeJobReportService.getEmployeeJobReportById(this.employeeJobReportId).subscribe(res => {
            const resObj: any = res;
            this.employeeJobReport = resObj.data;
            this.companyId = this.employeeJobReport.companyId;
            this.loadCompanyDropDown();
            this.loadPageEmployee({ companyId: this.companyId });
            // this.getEmployeeJobReportNote();
            //Set UTC
            this.employeeJobReportForm.patchValue({ reportDate: moment(this.employeeJobReport.reportDate).utc(false) });

            if (this.readOnly) {
              const args: any = {
                my_contact_viewed_by: this.authService.getCurrentLoggedInName(),
                my_contact_id: this.employeeJobReportId ? this.employeeJobReportId.toString() : null
              }
              this.monitoringDetailsService.monitorAction(
                'Job Report Viewed',
                this.timeSpent,
                args,
                'complete',
                'Job Report Viewed',
                0
              );
            }
          });
        } else {
          this.loadCompanyDropDown();
          this.loadPageEmployee({ companyId: this.companyId });
          if (this.reportDateTemp) {
            this.reportDateNgModel = moment(this.reportDateTemp, 'YYYY-MM-DD').toDate();
          } else {
            this.reportDateNgModel = moment(new Date()).toDate();
          }

          this.employeeJobReport.reportDate = this.reportDateNgModel;

          if (!this.authService.isEmployeeRole()) {
            this.getListHour();
          }
        }
      }
    });
    this.getLocation();
  }

  ngAfterViewInit(): void {
    //this.checkboxJob.nativeElement.scrollTop += 285;
    $('li:first-child').removeClass('ui-state-active');
  }
  ngOnDestroy() {
    this.messageService.clear();
  }

  loadCompanyDropDown() {
    this.companyService.getCompaniesDropdown({}).subscribe(res => {
      const resObj: any = res;
      this.companies = resObj.data;
      this.companySelected = this.companies.find(company => company.key === this.companyId);
      if (!this.isPlatformAdmin) {
        this.employeeJobReportForm.patchValue({ company: this.companySelected });
      }
    });
  }

  loadPageEmployee(options) {
    this.employees = [];
    // options.status = 1;
    this.employeeService.getAllsDropdown(options).subscribe(resEmp => {
      const empObj: any = resEmp;
      const tempList: any[] = [];
      if (empObj.status === 'SUCCESS') {
        empObj.data.forEach(element => {
          tempList.push({ label: element.fullName, value: element.value, id: element.key, type: element.type });
        });
      }
      this.employees = tempList;

      if (this.mode === 'add' || this.mode === 'create') {
        if (this.authService.isEmployeeRole()) {
          this.employeeSelected = this.employees.find(project => project.value?.toLowerCase() === this.authService.getCurrentUsername().toLowerCase());
          this.employeeJobReportForm.patchValue({ userName: this.employeeSelected });
          this.employeeJobReport.fullName = this.employeeSelected.label;
          this.getListHourByDate();
          // this.checkClockingIn(this.employeeSelected.id);
        }
      } else {
        if (this.employeeJobReport.userName) {
          this.employeeSelected = this.employees.find(project => project.value?.toLowerCase() === this.employeeJobReport.userName?.toLowerCase());
          this.employeeJobReportForm.patchValue({ userName: this.employeeSelected });
          this.employeeJobReport.fullName = this.employeeSelected.label;
          // this.checkClockingIn(this.employeeSelected.id);
        }
        this.getListHourByDate();
      }
    });
  }

  changeCompany(event: any) {
    this.companyService.get(event.value.key).subscribe(res => {
      const resObj: any = res;
      this.company = resObj.data;
    });
    this.loadPageEmployee({ companyId: event.value.key });
  }

  onDateChange(event: any) {
    if (this.employeeSelected && this.companySelected) {
      this.getListHourByDate();

      if (this.isEmployeeRole) {
        const checkRequest: any = {
          companyId: this.authService.getCurrentCompanyId(),
          reportDate: this.reportDateNgModel,
          userName: this.authService.getUserInfo().username
        };
        this.employeeJobReportService.getJobReportExistedToDay(checkRequest).subscribe(res => {
          const resObj: any = res;
          if (resObj.status === 'SUCCESS' && (!resObj.data || resObj.data.length === 0)) {
            this.mode = 'create';
            const reportDate: any = moment(this.reportDateNgModel).format('YYYY-MM-DD');
            this.router.navigate(['app/employee/employee-job-report/add'], { queryParams: { reportDate: reportDate } });
          }
        });
      }
    }
  }

  getListHourByDate() {
    this.selectedHourItem = undefined;
    this.loadingTimeSpent = true;
    const checkRequest: any = {};

    if (this.companySelected) {
      checkRequest.companyId = this.companySelected.key;
    } else {
      checkRequest.companyId = this.authService.getCurrentCompanyId();
    }

    if (this.employeeSelected) {
      checkRequest.userName = this.employeeSelected.value;
    }

    checkRequest.reportDate = moment(this.reportDateNgModel).format('YYYY-MM-DD');
    // checkRequest.reportDate = moment(this.reportDateNgModel).toDate();

    this.employeeJobReportId = null;
    this.employeeJobReportService.getJobReportNoteByDate(checkRequest).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.allEmployeeJobReportNote = [];
        let allEmployeeJobReportNoteLst: any = {};
        allEmployeeJobReportNoteLst = resObj?.data;
        allEmployeeJobReportNoteLst?.forEach(ele => {
          if (ele?.jobReportNotes) {
            ele?.jobReportNotes?.forEach(e => {
              this.allEmployeeJobReportNote.push(e);
              this.employeeJobReportId = e?.employeeJobReportId;
            });
          }
        });

        if (this.employeeJobReportId != null) {
          this.mode = 'edit';
          this.router.navigate(['app/employee/employee-job-report/edit/' + this.employeeJobReportId]);
        } else {
          this.mode = 'create';
          this.listHourNews = [];
        }
        this.allEmployeeJobReportNote?.forEach(ele => {
          if (ele.note) {
            ele.note = ele.note.replace(/<img src=/g, `<img style="width:150px" src=`);
          }
          ele.showHideActions = (moment(moment().toDate()).diff(ele.createdAt, 'minutes') >= 5);
        });
        this.loadingTimeSpent = false;
      } else {
        this.loadingTimeSpent = false;
      }
      this.getListHour();
    }, () => {
      this.loadingTimeSpent = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong while fetching data from the Employee Job Report service, try again later. If this error persists, contact support.' })
    });
  }

  changeUser(event: any) {
    if (event) {
      this.employeeJobReport.fullName = event?.originalEvent?.srcElement?.textContent;
      this.getListHour();
    }
  }

  disableCompany(): boolean {
    return !(this.authService.adminRole());
  }

  noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  isUploadingFile = false;
  uploadFile(event) {
    this.monitoringDetailsService.monitorAction(
      'Job Report Uploaded button clicked',
      this.timeSpent,
      {
        job_report_uploaded_button_clicked_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
      },
      'complete',
      'Job Report Uploaded button clicked',
      0
    );

    if (event.target.files.length === 0) {
      return;
    }
    this.isUploadingFile = true;
    this.documentServicev2.uploadMultiFile(event.target.files, 'email_template_file', this.companySelected ? this.companySelected.key : 1, 'Company')
      .subscribe(res => {
        this.isUploadingFile = false;
        if (res.status === 'SUCCESS' && (res.data || []).length) {
          const resObj: any = res;
          this.employeeJobReport.documentId = resObj.data[0].id;
          resObj.data.forEach(item => {
            this.employeeJobReport.documentUrls = this.employeeJobReport.documentUrls || [];
            if (item.fileUrl) {
              this.employeeJobReport.documentUrls.push(item.fileUrl);
            }
          });
          this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('Created'), detail: this.translatePipe.transform('SuccessfullyUploaded') });
        } else {
          this.messageService.add({ severity: 'error', summary: 'ERROR', detail: 'Upload files failed' });
        }
      }, err => {
        this.isUploadingFile = false;
        this.messageService.add({ severity: 'error', summary: 'ERROR', detail: 'Upload files failed' });
      });
  }

  onUploadDocument(uploadFilePanel: OverlayPanel) {
    this.monitoringDetailsService.monitorAction(
      'Job Report Uploaded button clicked',
      this.timeSpent,
      {
        job_report_uploaded_button_clicked_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
      },
      'complete',
      'Job Report Uploaded button clicked',
      0
    );
    this.uploadFileRef.toArray()[0].nativeElement.click();
    uploadFilePanel.hide();
  }

  onViewDocument(uploadFilePanel: OverlayPanel) {
    this.monitoringDetailsService.monitorAction(
      'Job Report Uploaded button clicked',
      this.timeSpent,
      {
        job_report_uploaded_button_clicked_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
      },
      'complete',
      'Job Report Uploaded button clicked',
      0
    );
    this.documentsService.getUploadedFile(this.employeeJobReport.documentId).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        if (resObj.data.fileType === 'pdf') {
          window.open(resObj.data.fileUrl);
        } else {
          this.fileUrl = resObj.data.fileUrl;
          this.showViewFileDialog = true;
        }
        uploadFilePanel.hide();
      }
    }, error => {
      // error
    });
  }

  onChangeDocument(uploadFilePanel: OverlayPanel) {
    this.monitoringDetailsService.monitorAction(
      'Job Report Uploaded button clicked',
      this.timeSpent,
      {
        job_report_uploaded_button_clicked_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
      },
      'complete',
      'Job Report Uploaded button clicked',
      0
    );
    this.uploadFileRef.toArray()[0].nativeElement.click();
    uploadFilePanel.hide();
  }

  viewHistoryUpload(uploadFilePanel: OverlayPanel) {
    this.historyHeader = 'Upload History';
    this.historyType = 'upload';
    this.showHistoryDialog = true;
    uploadFilePanel.hide();
  }

  showHistoryForDetail() {
    this.historyHeader = 'Edit History';
    this.historyType = 'detail';
    this.showHistoryDialog = true;
  }

  onRemoveDocument(uploadFilePanel: OverlayPanel) {
    if (this.employeeJobReport.documentId) {
      this.employeeJobReport.documentId = null;
      uploadFilePanel.hide();
    }
  }

  onCancel() {
    this.router.navigate(['app/employee/employee-job-report/list']);
  }

  update(dataForm) {
    this.isClicked = true;
    this.descriptionReport = true;
    this.messageService.clear();
    if (!this.formValidator.validateForm(this.employeeJobReportForm, dataForm)) {
      const invalid: any[] = [];
      for (const name in this.employeeJobReportForm.controls) {
        if (this.employeeJobReportForm.controls[name].invalid) {
          invalid.push(name);
        }
      }
      this.messageService.add({
        severity: 'error',
        summary: this.translatePipe.transform('InvalidData'),
        detail: this.translatePipe.transform('PleaseEnterRequiredFields')
      });
      this.isClicked = false;
      return;
    }
    if (!this.authService.isEmployeeRole() && !this.authService.isSubSuper() && !this.authService.isSuper() && !this.authService.isCompanyAd()) {
      let detail = 'You do not have permission to add/update new reports to this employee: ' + this.employeeSelected.label;
      this.messageService.add({
        severity: 'error',
        summary: this.translatePipe.transform('Error'),
        detail: detail
      });
      this.isClicked = false;
      return;
    }
    if (this.authService.isEmployeeRole() && this.employeeSelected.id !== this.authService.getUserInfo()?.adminId) {
      let detail = 'You do not have permission to add/update new reports to this employee: ' + this.employeeSelected.label;
      this.messageService.add({
        severity: 'error',
        summary: this.translatePipe.transform('Error'),
        detail: detail
      });
      this.isClicked = false;
      return;
    }
    if (!this.listHourNews.some(item => item.note)) {
      this.messageService.add({
        severity: 'error',
        summary: this.translatePipe.transform('Error'),
        detail: 'Job Report Updates are required.'
      });
      this.isClicked = false;
      return;
    }
    const hasNote = this.listHourNews.some(item => item.note && item?.note?.length > 0 && item?.note?.length < 25);
    if (hasNote) {
      this.messageService.add({
        severity: 'error',
        summary: this.translatePipe.transform('Error'),
        detail: 'Job report details must have minimum 25 characters.'
      });
      this.isClicked = false;
      return;
    }
    /*const noteEmpty = this.onlyWhitespaceError || !this.selectedHourItem.note;
    if (!noteEmpty && this.charactersNumber > 0 && this.charactersNumber < 25) {
      if (this.descriptionReport) {
        this.descriptionReport = false;
        this.messageService.add({
          severity: 'error',
          sticky: false,
          summary: this.translatePipe.transform('InvalidData'),
          detail: this.translatePipe.transform('Job report details must have minimum 25 characters')
        });
      }
      this.isClicked = false;
      return;
    } else */
    if (this.charactersNumber > 5000) {
      if (this.descriptionReport) {
        this.descriptionReport = false;
        this.messageService.add({
          severity: 'error',
          sticky: false,
          summary: this.translatePipe.transform('InvalidData'),
          detail: this.translatePipe.transform('Job report details must have maximum 5000 characters')
        });
      }
      this.isClicked = false;
      return;
    }

    if (this.charactersNumberReason > 0 && this.charactersNumberReason < 25) {
      if (this.descriptionReportReason) {
        this.descriptionReportReason = false;
        this.messageService.add({
          severity: 'error',
          sticky: false,
          summary: this.translatePipe.transform('InvalidData'),
          detail: this.translatePipe.transform('Reason Of missed this job report must have minimum 25 characters')

        });
      }
      this.isClicked = false;
      return;
    } else if (this.charactersNumberReason > 5000) {
      if (this.descriptionReportReason) {
        this.descriptionReportReason = false;
        this.messageService.add({
          severity: 'error',
          sticky: false,
          summary: this.translatePipe.transform('InvalidData'),
          detail: this.translatePipe.transform('Reason Of missed this job report must have maximum 5000 characters')
        });
      }
      this.isClicked = false;
      return;
    }
    this.descriptionReportReason = true;

    this.employeeJobReport.lastModifiedBy = this.authService.getCurrentUsername();
    this.employeeJobReport.companyId = this.companyId;
    this.employeeJobReport.updatedAt = new Date();

    if (this.employeeSelected) {
      this.employeeJobReport.userName = this.employeeSelected.value;
    }

    if (this.indexNotFix != -1) {
      //this.listHourNews[this.indexNotFix].note = this.noteNotFix + "<hr>" + this.listHourNews[this.indexNotFix]?.note;
      this.selectedHourItem = undefined;
    }
    // Set UTC
    this.employeeJobReport.reportDate = this.reportDateNgModel;

    // this.employeeJobReport.reportDate = this.reportDateNgModel;
    this.employeeJobReport.listNoteByHours = this.listHourNews;
    if (this.employeeJobReport.listNoteByHours) {
      // this.listHourNews.forEach(item => {
      //   const a = item?.note?.slice(this.selectedHourItem?.note?.indexOf('<p>') + 3, this.selectedHourItem?.note?.indexOf('</p>'));
      //   console.log(item.name + " : " + a)
      // });
      this.employeeJobReport.listNoteByHours = this.employeeJobReport.listNoteByHours.filter(item =>
        item.note && item?.note?.length >= 25);

      if (this.selectedItemNoteTmp) {
        this.employeeJobReport.listNoteByHours[this.currentIndex].note = `${this.selectedItemNoteTmp} <br/> ${this.employeeJobReport.listNoteByHours[this.currentIndex].note}`;
      }
    }

    console.log('updateJobReport employeeJobReport: ', this.employeeJobReport)
    this.employeeJobReportService.updateJobReport(this.employeeJobReport).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        // this.isClicked = false;
        this.monitoringDetailsService.monitorAction(
          'Job Report updated',
          this.timeSpent,
          {
            job_report_updated_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
          },
          'complete',
          'Job Report updated',
          0
        );
        // this.addEmployeeJobReportNote(resObj.data.id, this.employeeJobReport.details, this.selectedHours)
        this.employeeJobReportService.getEmployeeJobReportById(this.employeeJobReportId).subscribe(res => {
          const resObj: any = res;
          this.employeeJobReport = resObj.data;
          try {
            this.exportPDF(this.employeeJobReport);
          } catch (error) {
            console.error('Có lỗi xảy ra:', error);
          }
          //this.employeeJobReportForm.patchValue({reportDate: moment(this.employeeJobReport.reportDate).tz('UTC')});
        });
        this.messageService.add({
          severity: 'success',
          summary: this.translatePipe.transform('Created'),
          detail: this.translatePipe.transform('Job report update successfully')
        });
        if (this.updatedWorkOrders && this.updatedWorkOrders.length) {
          let updatedWO = this.updatedWorkOrders.map(item => {
            return {
              id: item.id,
              jobReportId: this.employeeJobReportId,
              jobReportName: this.employeeJobReport.userName + '-' + this.datePipe.transform(this.employeeJobReport.reportDate, 'MM/dd/yyyy') + ' ' + item.name
            };
          });
          this.operatorService.updateJobReportWorkOrder(updatedWO).subscribe(res => {
            this.updatedWorkOrders = [];
          });
        }
        setTimeout(() => this.getListHourByDate(), 1500);

      } else if (resObj.status === 'EXIST') {
        this.isClicked = false;
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Failed'), detail: this.translatePipe.transform('Job Report Already Existed In That Report Date') });
      } else {
        this.isClicked = false;
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Failed'), detail: res.message ? res.message : this.translatePipe.transform('cannot save') });
      }
    }, err => {
      this.isClicked = false;
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Failed'), detail: err.error.message });
    });
  }

  add(dataForm) {
    this.isClicked = true;
    this.descriptionReport = true;
    this.messageService.clear();
    if (!this.formValidator.validateForm(this.employeeJobReportForm, dataForm)) {
      const invalid: any[] = [];
      for (const name in this.employeeJobReportForm.controls) {
        if (this.employeeJobReportForm.controls[name].invalid) {
          invalid.push(name);
        }
      }
      this.messageService.add({
        severity: 'error',
        summary: this.translatePipe.transform('InvalidData'),
        detail: this.translatePipe.transform('PleaseEnterRequiredFields')
      });
      this.isClicked = false;
      return;
    }

    if (!this.authService.isEmployeeRole() && !this.authService.isSubSuper() && !this.authService.isSuper() && !this.authService.isCompanyAd()) {
      let detail = 'You do not have permission to add/update new reports to this employee: ' + this.employeeSelected.label;
      this.messageService.add({
        severity: 'error',
        summary: this.translatePipe.transform('Error'),
        detail: detail
      });
      this.isClicked = false;
      return;
    }
    if (this.authService.isEmployeeRole() && this.employeeSelected.id !== this.authService.getUserInfo()?.adminId) {
      let detail = 'You do not have permission to add/update new reports to this employee: ' + this.employeeSelected.label;
      this.messageService.add({
        severity: 'error',
        summary: this.translatePipe.transform('Error'),
        detail: detail
      });
      this.isClicked = false;
      return;
    }

    // this.isClicked = true;
    if (dataForm?.value?.concern == null) {
      if (!this.listHourNews.some(item => item.note)) {
        this.messageService.add({
          severity: 'error',
          summary: this.translatePipe.transform('Error'),
          detail: 'Job Report Updates are required.'
        });
        this.isClicked = false;
        return;
      }
      const hasNote = this.listHourNews.some(item => item.note && item?.note?.length > 0 && item?.note?.length < 25);
      if (hasNote) {
        this.messageService.add({
          severity: 'error',
          summary: this.translatePipe.transform('Error'),
          detail: 'Job report details must have minimum 25 characters.'
        });
        this.isClicked = false;
        return;
      }
      /*const noteEmpty = this.onlyWhitespaceError || !this.selectedHourItem.note;
      if (!noteEmpty && this.charactersNumber < 25) {
        if (this.descriptionReport) {
          this.descriptionReport = false;
          this.messageService.add({
            severity: 'error',
            summary: this.translatePipe.transform('InvalidData'),
            detail: this.translatePipe.transform('Job report details must have minimum 25 characters')
          });
        }
        this.isClicked = false;
        return;
      } else */
      if (this.charactersNumber > 5000) {
        if (this.descriptionReport) {
          this.descriptionReport = false;
          this.messageService.add({
            severity: 'error',
            summary: this.translatePipe.transform('InvalidData'),
            detail: this.translatePipe.transform('Job report details must have maximum 5000 characters')
          });
        }
        this.isClicked = false;
        return;
      }
      this.descriptionReport = true;

      if (this.charactersNumberReason > 0 && this.charactersNumberReason < 25) {
        if (this.descriptionReportReason) {
          this.descriptionReportReason = false;
          this.messageService.add({
            severity: 'error',
            sticky: false,
            summary: this.translatePipe.transform('InvalidData'),
            detail: this.translatePipe.transform('Reason Of missed this job report must have minimum 25 characters')

          });
        }
        this.isClicked = false;
        return;
      } else if (this.charactersNumberReason > 5000) {
        if (this.descriptionReportReason) {
          this.descriptionReportReason = false;
          this.messageService.add({
            severity: 'error',
            sticky: false,
            summary: this.translatePipe.transform('InvalidData'),
            detail: this.translatePipe.transform('Reason Of missed this job report must have maximum 5000 characters')
          });
        }
        this.isClicked = false;
        return;
      }
    }
    this.descriptionReportReason = true;

    this.employeeJobReport.createdByUsr = this.authService.getCurrentUsername();
    this.employeeJobReport.companyId = this.companyId;
    this.employeeJobReport.lat = this.latitude;
    this.employeeJobReport.lng = this.longitude;
    if (this.employeeSelected) {
      this.employeeJobReport.userName = this.employeeSelected.value;
      this.employeeJobReport.employeeId = this.employeeSelected.id;
    }

    //Set UTC
    // this.employeeJobReport.reportDate = moment(this.employeeJobReport.reportDate).utc(true).toDate();
    this.employeeJobReport.reportDate = this.reportDateNgModel;
    this.employeeJobReport.listNoteByHours = this.listHourNews;
    this.employeeJobReportService.createJobReport(this.employeeJobReport).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {

        try {
          const employeeJobReport: any = res.data;
          employeeJobReport.lastModifiedBy = this.authService.getCurrentUsername();
          this.exportPDF(employeeJobReport);
        } catch (error) {
          console.error('Có lỗi xảy ra:', error);
        }

        // this.isClicked = false;
        this.monitoringDetailsService.monitorAction(
          'Job Report Saved',
          this.timeSpent,
          {
            job_report_saved_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
          },
          'complete',
          'Job Report Saved',
          0
        );
        if (this.updatedWorkOrders && this.updatedWorkOrders.length) {
          let updatedWO = this.updatedWorkOrders.map(item => {
            return {
              id: item.id,
              jobReportId: resObj.data.id,
              jobReportName: this.employeeJobReport.userName + '-' + this.datePipe.transform(this.employeeJobReport.reportDate, 'MM/dd/yyyy hh:mm a') + '-' + item.name
            };
          });
          this.operatorService.updateJobReportWorkOrder(updatedWO).subscribe(res => {
            this.updatedWorkOrders = [];
          });
        }
        // this.addEmployeeJobReportNote(resObj.data.id, this.employeeJobReport.details, this.selectedHours);
        this.messageService.add({
          severity: 'success',
          summary: this.translatePipe.transform('Created'),
          detail: this.translatePipe.transform('Job report added successfully')
        });
        this.isClicked = false;

        setTimeout(() => this.router.navigate(['app/employee/employee-job-report/list']), 1500);
      } else if (resObj.status === 'EXIST') {
        this.isClicked = false;
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Failed'), detail: this.translatePipe.transform('Job Report Already Existed In That Report Date') });
      } else {
        this.isClicked = false;
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Failed'), detail: res.message ? res.message : this.translatePipe.transform('cannot save') });
      }
    }, err => {
      this.isClicked = false;
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Failed'), detail: err.error.message });
    });
  }

  getEmployeeJobReportNote() {
    if (!this.employeeJobReportId) {
      return;
    }
    this.employeeJobReportNoteService.getAllEmployeeJobReportNote(this.employeeJobReportId).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === RESPONSE_STATUS.SUCCESS) {
        if (resObj.data) {
          this.allEmployeeJobReportNote = resObj.data;
        }
        if (this.allEmployeeJobReportNote) {
          this.allEmployeeJobReportNoteLength = this.allEmployeeJobReportNote.length;
          this.allEmployeeJobReportNote = this.allEmployeeJobReportNote.slice().reverse();
        }

        this.allEmployeeJobReportNote.forEach(ele => {
          if (ele.note) {
            ele.note = ele.note.replace(/<img src=/g, `<img style="width:150px" src=`);
          }
          ele.showHideActions = (moment(moment().toDate()).diff(ele.createdAt, 'minutes') >= 5);
        });
      }
    });
  }

  onEditorClick(event) {
    if (event.target.localName === 'img') {
      this.previewImage = event.target.currentSrc;
    }
  }

  textEditChanged(event) {
    event.delta.map((item) => {
      if (item?.insert?.image) {
        if (item.insert.image.includes('https://livestore')) {
          return;
        }
        const temp = new Date().getTime();
        const valueAdded = event.htmlValue.replace(/<img[^>]*src="data:image\/png;base64,[^"]*"[^>]*>/g, temp);
        const file = this.dataURIToBlob(item.insert.image);
        if (file && this.fileSizeCheck(file)) {
          this.loadingUpload = true;
          this.documentsService.uploadAnResize(item.insert.image).then((res) => {
            const thumbnail = this.dataURIToBlob(res);
            this.documentsService.uploadFileWithThumbnail(file, thumbnail, 'employee-job-report', this.employeeJobReportId || 0, 'employee-job-report', file.name).subscribe((fileRes: any) => {
              this.nodeToEdit = valueAdded.replace(temp, `<img src="${fileRes.data.fileUrl}">`);
              this.loadingUpload = false;
            }, () => this.loadingUpload = false);
          }, () => this.loadingUpload = false);
        } else {
          this.nodeToEdit = valueAdded.replace(temp, '');
        }
      }
    });
  }

  setEditor($event: any) {
    this.editor = $event.editor;
    if (!this.detailsEditor) {
      this.detailsEditor = new Editor($event.ElementRef);
    }
  }

  setEditorReason($event: any) {
    this.editorReason = $event.editor;
    if (!this.detailsEditorReason) {
      this.detailsEditorReason = new Editor($event.ElementRef);
    }
  }

  boldMention() {
    setTimeout(() => {
      const regexTag = /(@)([@.a-zA-Z0-9_-]*)(<\/p>)/g;
      const regexTag2 = /(@)([@.a-zA-Z0-9_-]*)( +)/g;
      this.employeeJobReport.details = this.employeeJobReport.details.replace(regexTag, '<strong>$1$2</strong>&nbsp;$3');
      this.employeeJobReport.details = this.employeeJobReport.details.replace(regexTag2, '<strong>$1$2</strong>$3');
      this.detailsEditor.writeValue(this.employeeJobReport.details.replace(regexTag, '<strong>$1$2</strong>&nbsp;$3'));
      this.detailsEditor.writeValue(this.employeeJobReport.details.replace(regexTag2, '<strong>$1$2</strong>$3'));
      setTimeout(() => {
        this.detailsEditor.getQuill().setSelection(this.detailsEditor.value.length);
      }, 100);

    }, 500);
  }

  boldMentionReason() {
    setTimeout(() => {
      const regexTag = /(@)([@.a-zA-Z0-9_-]*)(<\/p>)/g;
      const regexTag2 = /(@)([@.a-zA-Z0-9_-]*)( +)/g;
      this.employeeJobReport.detailsReason = this.employeeJobReport.detailsReason.replace(regexTag, '<strong>$1$2</strong>&nbsp;$3');
      this.employeeJobReport.detailsReason = this.employeeJobReport.detailsReason.replace(regexTag2, '<strong>$1$2</strong>$3');
      this.detailsEditorReason.writeValue(this.employeeJobReport.detailsReason.replace(regexTag, '<strong>$1$2</strong>&nbsp;$3'));
      this.detailsEditorReason.writeValue(this.employeeJobReport.detailsReason.replace(regexTag2, '<strong>$1$2</strong>$3'));
      setTimeout(() => {
        this.detailsEditorReason.getQuill().setSelection(this.detailsEditorReason.value.length);
      }, 100);

    }, 500);
  }

  textChanged(event) {
    if (this.loadingUpload) {
      return;
    }
    event.delta.map((item) => {
      if (item?.insert?.image) {
        if (item.insert.image.includes('https://livestore')) {
          return;
        }
        this.loadingUpload = true;
        const currentTime = new Date().getTime();
        const valueAdded = event.htmlValue.replace(/<img[^>]*src="data:image\/png;base64,[^"]*"[^>]*>/g, currentTime);
        const file = this.dataURIToBlob(item.insert.image);
        if (file && this.fileSizeCheck(file)) {
          this.documentsService.uploadAnResize(item.insert.image).then((res) => {
            const thumbnail = this.dataURIToBlob(res);
            this.documentsService.uploadFileWithThumbnail(file, thumbnail, 'employee-job-report', this.employeeJobReportId || 0, 'employee-job-report', file.name).subscribe((fileRes: any) => {
              this.employeeJobReport.details = valueAdded.replace(currentTime, `<img src="${fileRes.data.fileUrl}">`);
              this.detailsEditor.writeValue(this.employeeJobReport.details);
              this.loadingUpload = false;
            }, () => this.loadingUpload = false);
          }, () => this.loadingUpload = false);
        } else {
          this.employeeJobReport.details = valueAdded.replace(currentTime, '');
          this.detailsEditor.writeValue(this.employeeJobReport.details);
          this.loadingUpload = false;
        }
      }
    });
    this.exceededMaxLength = false;
    this.exceededMinLength = false;
    if (!!event.htmlValue) {
      const value = event.htmlValue.slice(event.htmlValue.indexOf('<p>') + 3, event.htmlValue.indexOf('</p>'));
      if (!!value && !value.replace(/\s/g, '')) {
        this.onlyWhitespaceError = true;
      } else {
        this.onlyWhitespaceError = false;
      }
    }
    this.msgs = [];
    if (this.editor.getLength() <= 25) {
      this.exceededMinLength = true;
      this.charactersNumber = this.editor.getLength() - 1;
      return;
    } else {
      this.charactersNumber = this.editor.getLength() - 1;
    }
    if (this.editor.getLength() > this.MAX_LENGTH) {
      this.exceededMaxLength = true;
      this.editor.deleteText(this.MAX_LENGTH - 1, this.editor.getLength());
      this.msgs.push({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('You have exceeded the maximum text limit') });
    }
    this.charactersNumber = this.editor.getLength() - 1;
    this.isClicked = false;
  }

  textChangedReason(event) {
    if (this.loadingUpload) {
      return;
    }
    event.delta.map((item) => {
      if (item?.insert?.image) {
        if (item.insert.image.includes('https://livestore')) {
          return;
        }
        this.loadingUpload = true;
        const currentTime = new Date().getTime();
        const valueAdded = event.htmlValue.replace(/<img[^>]*src="data:image\/png;base64,[^"]*"[^>]*>/g, currentTime);
        const file = this.dataURIToBlob(item.insert.image);
        if (file && this.fileSizeCheck(file)) {
          this.documentsService.uploadAnResize(item.insert.image).then((res) => {
            const thumbnail = this.dataURIToBlob(res);
            this.documentsService.uploadFileWithThumbnail(file, thumbnail, 'employee-job-report', this.employeeJobReportId || 0, 'employee-job-report', file.name).subscribe((fileRes: any) => {
              this.employeeJobReport.detailsReason = valueAdded.replace(currentTime, `<img src="${fileRes.data.fileUrl}">`);
              this.detailsEditorReason.writeValue(this.employeeJobReport.detailsReason);
              this.loadingUpload = false;
            }, () => this.loadingUpload = false);
          }, () => this.loadingUpload = false);
        } else {
          this.employeeJobReport.detailsReason = valueAdded.replace(currentTime, '');
          this.detailsEditorReason.writeValue(this.employeeJobReport.detailsReason);
          this.loadingUpload = false;
        }
      }
    });
    this.exceededMaxLengthReason = false;
    this.exceededMinLengthReason = false;
    if (!!event.htmlValue) {
      const value = event.htmlValue.slice(event.htmlValue.indexOf('<p>') + 3, event.htmlValue.indexOf('</p>'));
      if (!!value && !value.replace(/\s/g, '')) {
        this.onlyWhitespaceErrorReason = true;
      } else {
        this.onlyWhitespaceErrorReason = false;
      }
    }
    this.msgs = [];
    if (this.editorReason.getLength() <= 25) {
      this.exceededMinLengthReason = true;
      this.charactersNumberReason = this.editorReason.getLength() - 1;
      return;
    } else {
      this.charactersNumberReason = this.editorReason.getLength() - 1;
    }
    if (this.editorReason.getLength() > this.MAX_LENGTH) {
      this.exceededMaxLengthReason = true;
      this.editorReason.deleteText(this.MAX_LENGTH - 1, this.editorReason.getLength());
      this.msgs.push({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('You have exceeded the maximum text limit') });
    }
    this.charactersNumberReason = this.editorReason.getLength() - 1;
  }

  dataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const file: any = new Blob([ia], { type: mimeString });
    file.lastModifiedDate = new Date();
    file.name = 'image_' + new Date().getTime() + '.jpg';
    return file;

  }

  fileSizeCheck = (file): boolean => {
    if (file.size > 15758640) { // 15 mb = 15758640 bytes
      this.messageService.add({
        severity: 'error',
        summary: this.translatePipe.transform('Error'),
        detail: this.translatePipe.transform('File should not be greater than 15MB'),
      });
      return false;
    }
    return true;
  }

  selectedHours: any[] = [];
  rowCheckedChange() {
    this.listHours.forEach(v => this.updateCheckedRows(v));
  }
  updateCheckedRows(voter) {
    const row = this.selectedHours.find(c => c.code === voter.code);
    if (row) {
      row.checked = voter.checked;
    } else {
      this.selectedHours.push(voter);
    }
  }

  addEmployeeJobReportNote(employeeJobReportId: number, employeeJobReportNote: string, notesTimes: any[]) {
    if (notesTimes) {
      let listNoteTime: any[] = [];
      let notesTime = '';
      listNoteTime = notesTimes;
      listNoteTime.forEach((time: any) => {
        if (time.checked) {
          notesTime = notesTime + time.code + '; '
        }
      });
      if (notesTime) {
        notesTime = notesTime.replace(/;\s*$/, '');
      }

      this.employeeJobReportNote.employeeJobReportId = employeeJobReportId;
      this.employeeJobReportNote.createdByUsr = this.authService.getUserInfo().username;
      if (employeeJobReportNote) this.employeeJobReportNote.note = this.urlify(employeeJobReportNote);
      this.employeeJobReportNote.notesTime = notesTime;
      this.employeeJobReportNoteService.saveEmployeeJobReportNote(this.employeeJobReportNote).subscribe(res => {
      });
    } else {
      this.employeeJobReportNote.employeeJobReportId = employeeJobReportId;
      this.employeeJobReportNote.createdByUsr = this.authService.getUserInfo().username;
      if (employeeJobReportNote) this.employeeJobReportNote.note = this.urlify(employeeJobReportNote);
      this.employeeJobReportNoteService.saveEmployeeJobReportNote(this.employeeJobReportNote).subscribe(res => {
      });
    }
  }

  urlify(text) {
    const exp = /((href|src)=["']|)(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(exp, function () {
      return arguments[1] ?
        arguments[0] :
        '<a target=\'_blank\' href="' + arguments[3] + '">' + arguments[3] + '</a>';
    });
  }

  updateToDoModal = false;
  nodeToEdit: any = {};
  notesTimeEdit: any = '';
  // selectedUpdateHours: any[] = [];
  selectedNote: any;
  openUpdateToModal(noteData, event) {
    if (noteData.notesTime) {
      this.notesTimeEdit = noteData.notesTime;
    } else {
      this.messageService.add({
        severity: 'error',
        summary: this.translatePipe.transform('Error'),
        detail: this.translatePipe.transform('Invalid Note Time'),
      });
      return;
    }

    this.selectedNote = noteData;
    this.updateToDoModal = true;
    if (noteData && !noteData.showHideActions) {
      this.nodeToEdit = noteData.note;
    } else {
      this.nodeToEdit = '';
      // this.messageService.add({
      //   severity: 'info', summary: this.translatePipe.transform('Info'),
      //   detail: this.translatePipe.transform('Sorry, Job Report updates can not be edited more than 5 mins after their creation.')
      // });
    }
  }

  cancelUpdateModal() {
    this.updateToDoModal = false;
  }

  updateEditNote() {
    if (this.selectedNote) {

      // let notesTime = '';
      // this.selectedUpdateHours.forEach((time: any) => {
      //   if (time.checked) {
      //       notesTime = notesTime + time.code + '; '
      //   }
      // });
      // if (notesTime) {
      //   notesTime = notesTime.replace(/;\s*$/, '');
      // }

      const option: any = this.selectedNote;
      option.note = this.urlify(`${option.note}<br/>${this.nodeToEdit}`);
      option.notesTime = this.notesTimeEdit;
      option.lastModifiedBy = this.authService.getUserInfo().username;

      this.employeeJobReportNoteService.saveEmployeeJobReportNote(option).subscribe(res => {
        const resObj: any = res;
        if (resObj.status === 'SUCCESS') {
          this.messageService.add({
            severity: 'info', summary: this.translatePipe.transform('Updated'),
            detail: this.translatePipe.transform('Update note success')
          });

          this.getListHourByDate();
        } else {
          this.messageService.add({
            severity: 'info', summary: this.translatePipe.transform('Updated'),
            detail: this.translatePipe.transform('Update note fail')
          });
        }
        this.updateToDoModal = false;
      }, err => {
        this.messageService.add({
          severity: 'error', summary: this.translatePipe.transform('Updated'),
          detail: this.translatePipe.transform('Update note fail')
        });
      });
    }
  }

  rowCheckedChangeEdit() {
    // this.listUpdateHours.forEach(v => this.updateCheckedRowsEdit(v));
    console.log('rowCheckedChangeEdit listUpdateHours: ', this.listUpdateHours)
  }

  // updateCheckedRowsEdit(voter) {
  //   const row = this.selectedUpdateHours.find(c => c.code === voter.code);
  //   if (row) {
  //       row.checked = voter.checked;
  //   } else {
  //       this.selectedUpdateHours.push(voter);
  //   }
  // }

  checkClockingIn(employeeId?) {
    const currentTime = moment(new Date()).format('YYYY-MM-DD');
    if (!employeeId) {
      this.listHours.map(h => {
        const hhmm = h.code.split(' - ');
        const startTime = moment(currentTime + ' ' + hhmm[0].replace(' ', ':00 ')).unix() * 1000;
        const endTime = moment(currentTime + ' ' + hhmm[1].replace(' ', ':59 ')).unix() * 1000;
        const currTime = moment().unix() * 1000;

        if (currTime - startTime < 0 || (currTime - startTime >= 0 && currTime - endTime < 0)) {
          h.disabled = false;
        }
      });
    }
  }


  latitude;
  longitude;
  getLocation() {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
        },
        (error) => {

        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  }

  getListHour() {
    const request: any = {};
    // request.reportDate = this.employeeJobReport.reportDate;
    request.reportDate = moment(this.reportDateNgModel).toDate();
    if (this.companySelected) {
      request.companyId = this.companySelected.key;
    } else {
      request.companyId = this.authService.getCurrentCompanyId();
    }

    if (this.employeeSelected) {
      request.employeeId = this.employeeSelected.id;
    }

    if (this.employeeJobReportId) {
      request.employeeJobReportId = this.employeeJobReportId;
    }
    request.timezone = new Date().getTimezoneOffset();

    this.listHourNews = [];
    this.listHourTemp = [];

    this.employeeJobReportService.getListHour(request).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === RESPONSE_STATUS.SUCCESS) {
        let tempListHour = resObj.data;
        // const currentTime = moment(moment(this.employeeJobReport.reportDate).utc(true).toDate()).format('YYYY-MM-DD');
        const currentTime = moment(this.reportDateNgModel).format('YYYY-MM-DD');
        tempListHour.forEach(product => {
          const hhmm = product.code.split(' - ');
          const startTime = moment(currentTime + ' ' + hhmm[0].replace(' ', ':00 ')).unix() * 1000;
          const endTime = moment(currentTime + ' ' + hhmm[1].replace(' ', ':59 ')).unix() * 1000;
          const currTime = moment().unix() * 1000;

          if (currTime - startTime >= 0 && currTime - endTime < 0 && product.status != "Done") {
            product.status = "In Progress";
          }

          if (currTime - endTime > 0 && product.status != "Done") {
            product.status = "Missed";
          }

          if (startTime - currTime > 0 && product.status != "Done") {
            product.status = "Upcoming";
          }
          this.listHourNews.push(product);
          this.listHourTemp.push(product);
        });

        this.collapseTime();
      }
    });
  }

  selectedItemNoteTmp: any;
  clickHour(data: any, index: number) {
    this.isClicked = data.note && data.note.length > 25 ? false : true;
    this.exceededMinLength = false;
    data.note = data.note || null;
    this.showNoteReason = false;
    this.indexNotFix = -1;
    this.noteNotFix = "";
    this.indexNoteByHour = index;
    this.titleNoteByHour = data.code;
    this.createdAtNoteByHour = data.createdAt;
    this.selectedHourItem = data;
    this.currentIndex = index;
    this.showNoteReason = false;
    this.selectedItemNoteTmp = null;

    if (this.currentIndex > 0) {
      this.listHourNews[this.currentIndex].note = this.selectedHourItem.note;
      this.listHourNews[this.currentIndex].noteReason = this.selectedHourItem.noteReason;
    }
    if (this.listHourNews[index] && this.listHourNews[index]?.status != "Missed") {
      if ((moment(moment().toDate()).diff(this.listHourNews[index]?.createdAt, 'minutes') >= 5)) {
        this.selectedHourItem.editNotDisabled = false;
        this.selectedItemNoteTmp = this.selectedHourItem.note;
        this.selectedHourItem.note = '';
      } else {
        this.selectedHourItem.editNotDisabled = false;
      }
      if (this.listHourNews[index]?.noteReason != null) {
        this.showNoteReason = true;
      }

      if (this.listHourNews[index]?.status == "Done") {
        this.noteNotFix = this.selectedHourItem.note;
        this.indexNotFix = index;
      }
      this.selectedHourItem.note = this.listHourNews[index].note;
      this.selectedHourItem.noteReason = this.listHourNews[index].noteReason;
    } else if (this.listHourNews[index] && this.listHourNews[index]?.status == "Missed") {
      this.showNoteReason = true;
      this.selectedHourItem.editNotDisabled = false;
      this.selectedHourItem.note = this.selectedHourItem.note ? this.selectedHourItem.note : "";
      this.selectedHourItem.noteReason = this.selectedHourItem.noteReason ? this.selectedHourItem.noteReason : "";
    }

    const value = this.selectedHourItem?.note?.slice(this.selectedHourItem?.note?.indexOf('<p>') + 3, this.selectedHourItem?.note.indexOf('</p>'));
    setTimeout(() => {
      if (this.editor.getLength() <= 25) {
        this.exceededMinLength = true;
        this.charactersNumber = this.editor.getLength() - 1;
        return;
      } else {
        this.charactersNumber = this.editor.getLength() - 1;
      }
    }, 500);
    /*if (value.length <= 25) {
      this.charactersNumber = value.length;
    } else {
      this.exceededMinLength = false;
      this.charactersNumber = value.length;
    }*/

    const valueReason = this.selectedHourItem?.noteReason?.slice(this.selectedHourItem.noteReason.indexOf('<p>') + 3, this.selectedHourItem.noteReason.indexOf('</p>'));
    if ((valueReason || '').length <= 25) {
      this.charactersNumberReason = valueReason.length;
    } else {
      this.exceededMinLengthReason = false;
      this.charactersNumberReason = valueReason.length;
    }
  }

  getRowCount(): number {
    let count = 0;
    this.listHourNews.forEach(product => {
      if (['Missed', 'In Progress', 'Done', 'Upcoming', ''].includes(product.status)) {
        count += !Number.isNaN(parseInt(this.jrSendNotifyEvery)) ? parseInt(this.jrSendNotifyEvery) : 1;
      }
    });
    return count;
  }
  calculateEditorHeight(): string {
    const baseHeight = 40;
    // const rowCount = this.getRowCount();
    const rowCount = 10;
    const totalHeight = rowCount * baseHeight;
    return `${totalHeight - 36}px`;
  }

  expandTime() {
    this.isExpand = true;
    this.showListHour();
    const expandList = this.listHours.map(item => {
      const matchingItem = this.listHourTemp.find(item1 => item1.code === item.code);
      if (matchingItem) {
        return matchingItem;
      } else {
        return item;
      }
    });

    this.listHourNews = expandList;

    const currentTime = moment(this.reportDateNgModel).format('YYYY-MM-DD');
    this.listHourNews.forEach(product => {
      const hhmm = product.code.split(' - ');
      const startTime = moment(currentTime + ' ' + hhmm[0].replace(' ', ':00 ')).unix() * 1000;
      const endTime = moment(currentTime + ' ' + hhmm[1].replace(' ', ':59 ')).unix() * 1000;
      const currTime = moment().unix() * 1000;

      if (currTime - startTime >= 0 && currTime - endTime < 0 && product.status != "Done") {
        product.status = "In Progress";
      }

      if (currTime - endTime > 0 && product.status != "Done") {
        product.status = "Missed";
      }

      if (startTime - currTime > 0 && product.status != "Done") {
        product.status = "Upcoming";
      }
    });
  }

  collapseTime() {
    if (this.listHourTemp.length > 0) {
      this.listHourNews = [...this.listHourTemp];
      this.isExpand = false;
    }
  }

  handleDrop(event: DragEvent, type: string) {
    this.loadingUpload = true;
    event.preventDefault();

    const dataTransfer: any = event.dataTransfer;
    if (dataTransfer.items) {
      const file = dataTransfer.items[0].getAsFile();
      this.convertImageToBase64(file, type);
    }
  }

  convertImageToBase64(file: File, type: any) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const base64Data = e.target.result as string;
      const file = this.dataURIToBlob(base64Data);
      if (file && this.fileSizeCheck(file)) {
        this.documentsService.uploadAnResize(base64Data).then((res) => {
          const thumbnail = this.dataURIToBlob(res);
          // tslint:disable-next-line:max-line-length
          this.documentsService.uploadFileWithThumbnail(file, thumbnail, 'employee-job-report', this.employeeJobReportId || 0, 'employee-job-report', file.name).subscribe((fileRes: any) => {
            this.employeeJobReport.details = `<img src="${fileRes.data.fileUrl}">`;
            if (type === 'create') {
              this.selectedHourItem.note = (this.selectedHourItem.note == null ? '' : this.selectedHourItem.note) + this.employeeJobReport.details;
              this.selectedHourItem.noteReason = (this.selectedHourItem.noteReason == null ? '' : this.selectedHourItem.noteReason) + this.employeeJobReport.detailsReason;
            } else {
              this.nodeToEdit = (this.nodeToEdit == null ? "" : this.nodeToEdit) + this.employeeJobReport.details;
            }
            this.loadingUpload = false;
          }, () => this.loadingUpload = false);
        }, () => this.loadingUpload = false);
      } else {
        this.detailsEditor.writeValue(this.employeeJobReport.details);
        this.loadingUpload = false;
      }
    }
    reader.readAsDataURL(file);
  };

  allowDrop(event: DragEvent) {
    event.preventDefault();
  }



  collapseDefault() {
    if (this.jrSendNotifyEvery == '1') {
      this.listHours = [
        { name: '09:00 AM - 09:59 AM', code: '09:00 AM - 09:59 AM', checked: false, disabled: true, status: '' },
        { name: '10:00 AM - 10:59 AM', code: '10:00 AM - 10:59 AM', checked: false, disabled: true, status: '' },
        { name: '11:00 AM - 11:59 AM', code: '11:00 AM - 11:59 AM', checked: false, disabled: true, status: '' },
        { name: '12:00 PM - 12:59 PM', code: '12:00 PM - 12:59 PM', checked: false, disabled: true, status: '' },
        { name: '01:00 PM - 01:59 PM', code: '01:00 PM - 01:59 PM', checked: false, disabled: true, status: '' },
        { name: '02:00 PM - 02:59 PM', code: '02:00 PM - 02:59 PM', checked: false, disabled: true, status: '' },
        { name: '03:00 PM - 03:59 PM', code: '03:00 PM - 03:59 PM', checked: false, disabled: true, status: '' },
        { name: '04:00 PM - 04:59 PM', code: '04:00 PM - 04:59 PM', checked: false, disabled: true, status: '' },
        { name: '05:00 PM - 05:59 PM', code: '05:00 PM - 05:59 PM', checked: false, disabled: true, status: '' }
      ];
    } else if (this.jrSendNotifyEvery == '2') {
      this.listHours = [
        { name: '08:00 AM - 09:59 AM', code: '08:00 AM - 09:59 AM', checked: false, disabled: true, status: '' },
        { name: '10:00 AM - 11:59 AM', code: '10:00 AM - 11:59 AM', checked: false, disabled: true, status: '' },
        { name: '12:00 PM - 01:59 PM', code: '12:00 PM - 01:59 PM', checked: false, disabled: true, status: '' },
        { name: '02:00 PM - 03:59 PM', code: '02:00 PM - 03:59 PM', checked: false, disabled: true, status: '' },
        { name: '04:00 PM - 05:59 PM', code: '04:00 PM - 05:59 PM', checked: false, disabled: true, status: '' },
      ];
    } else if (this.jrSendNotifyEvery == '3') {
      this.listHours = [
        { name: '09:00 AM - 11:59 AM', code: '09:00 AM - 11:59 AM', checked: false, disabled: true, status: '' },
        { name: '12:00 PM - 02:59 PM', code: '12:00 PM - 02:59 PM', checked: false, disabled: true, status: '' },
        { name: '03:00 PM - 05:59 PM', code: '03:00 PM - 05:59 PM', checked: false, disabled: true, status: '' }
      ];
    } else if (this.jrSendNotifyEvery == '4') {
      this.listHours = [
        { name: '08:00 AM - 11:59 AM', code: '08:00 AM - 11:59 AM', checked: false, disabled: true, status: '' },
        { name: '12:00 PM - 03:59 PM', code: '12:00 PM - 03:59 PM', checked: false, disabled: true, status: '' },
        { name: '04:00 PM - 07:59 PM', code: '04:00 PM - 07:59 PM', checked: false, disabled: true, status: '' }
      ];
    } else if (this.jrSendNotifyEvery == '6') {
      this.listHours = [
        { name: '06:00 AM - 11:59 AM', code: '06:00 AM - 11:59 AM', checked: false, disabled: true, status: '' },
        { name: '12:00 PM - 05:59 PM', code: '12:00 PM - 05:59 PM', checked: false, disabled: true, status: '' }
      ];
    } else if (this.jrSendNotifyEvery == '8') {
      this.listHours = [
        { name: '07:00 AM - 02:59 PM', code: '07:00 AM - 02:59 PM', checked: false, disabled: true, status: '' },
        { name: '03:00 PM - 11:59 PM', code: '03:00 PM - 11:59 PM', checked: false, disabled: true, status: '' }
      ];
    }
  }

  // collapseAfterProccess() {
  //   console.log(this.listHours);
  //   console.log(this.listHourTemp);
  //   console.log(this.listHourNews);
  //   // const collapseList = this.listHourTemp.map(item => {
  //   //   const matchingItem = this.listHours.find(item1 => item1.code === item.code);

  //   //   if (matchingItem) {
  //   //     return matchingItem;
  //   //   } else {
  //   //     return item;
  //   //   }
  //   // });
  //   // this.listHourNews = collapseList;
  //   const currentTime = moment(moment(this.reportDateNgModel).utc(true).toDate()).format('YYYY-MM-DD');
  //   console.log("8. Collapse listHours New")
  //   this.listHourNews.forEach(product => {
  //     const hhmm = product.code.split(' - ');
  //     const startTime = moment(currentTime + ' ' + hhmm[0].replace(' ', ':00 ')).unix() * 1000;
  //     const endTime =  moment(currentTime + ' ' + hhmm[1].replace(' ', ':59 ')).unix() * 1000;
  //     const currTime = moment().unix() * 1000;

  //     if (currTime - startTime >= 0 && currTime - endTime < 0 && product.status != "Done") {
  //       product.status = "In Progress";
  //     }

  //     if (currTime - endTime > 0 && product.status != "Done") {
  //       product.status = "Missed";
  //     }

  //     if (startTime - currTime > 0 && product.status != "Done") {
  //       product.status = "Upcoming";
  //     }
  //   });

  //   console.log(this.listHourNews);
  // }

  showViewImage = false;
  angle = 90;
  rotation = 0;
  viewFileUrl
  isImage(msg) {
    return ['.jpg', '.jpeg', '.png', '.webp', '.gif'].some(item => (msg || '').toLowerCase().endsWith(item));
  }

  isDoc(msg) {
    return ['.doc', '.docx'].some(item => (msg || '').toLowerCase().endsWith(item));
  }

  isPdf(msg) {
    return ['.pdf'].some(item => (msg || '').toLowerCase().endsWith(item));
  }

  isExcel(msg) {
    return ['.xlsx', '.xls'].some(item => (msg || '').toLowerCase().endsWith(item));
  }

  isFile(msg) {
    return msg && !this.isImage(msg) && !this.isDoc(msg) && !this.isPdf(msg) && !this.isExcel(msg);
  }

  onViewImage(fileUrl) {
    this.viewFileUrl = fileUrl;
    this.showViewImage = true;
  }
  rotateImage() {
    const rotated: any = document.getElementById('rotated');
    this.rotation = (this.rotation + this.angle) % 360;
    rotated.style.transform = `rotate(${this.rotation}deg)`;
  }

  zoomin() {
    const myImg: any = document.getElementById('rotated');
    const currWidth = myImg.clientWidth;
    if (currWidth >= 2500) { return false; } else {
      myImg.style.width = (currWidth + 100) + 'px';
    }
  }

  zoomout() {
    const myImg: any = document.getElementById('rotated');
    const currWidth = myImg.clientWidth;
    if (currWidth <= 100) { return false; } else {
      myImg.style.width = (currWidth - 100) + 'px';
    }
  }

  closeViewFileDialog() {
    this.showViewImage = false;
  }

  openInNewTab(url) {
    window.open(url, '_blank');
  }

  removeCommentFile(index) {
    this.employeeJobReport.documentId = null;
    this.employeeJobReport.documentUrls.splice(index, 1);
  }

  openCreateWorkOrderDialog() {
    this.showCreateWorkOrderDialog = true;
  }

  closeCreateWorkOrderPopup(event) {
    this.showCreateWorkOrderDialog = !event;
  }

  updatedWorkOrders = <any>[];
  createWorkOrderPopupData(val) {
    if (val) {
      console.log(this.selectedHourItem);
      this.updatedWorkOrders.push({ id: val.id, name: this.selectedHourItem.name });
      const currentDomain = window.location.origin;
      const workOrderTitle = `<p><a href=\"${currentDomain}/#/app/workorder/view/${val.id}\" target=\"_blank\">${val.ticketNumber} Plan/Work Order - ${val.todo}</a></p>`;
      this.selectedHourItem.note = this.selectedHourItem.note ? this.selectedHourItem.note + workOrderTitle : workOrderTitle;
    }
  }

  openNewTab(url) {
    window.open(url, '_blank');
  };

  historyFilter: any = {
    employees: [],
    employeeIds: [],
    fromDate: null,
    toDate: null,
    ids: [],
    page: 0,
    size: 10,
    key: '',
    lateEarlier: null,
    isMissing: false,
    withNoOvertime: false,
    type: 1
  };

  async exportPDF(rowData) {
    const reportDate = new Date(rowData.reportDate);
    const employee = this.employeeSelected;
    if (!employee) {
      return;
    }
    let options: any = this.historyFilter;
    options.employeeIds.push(employee.id);
    options.fromDate = reportDate;
    options.toDate = reportDate;

    const clockingDataResponse: any = await this.employeeClockingServiceV2.search(options).toPromise();
    if (clockingDataResponse.status !== 'SUCCESS') {
      return;
    }

    const pdfBodyContent: any = [];
    const clockingData = clockingDataResponse.data.content;

    if (clockingData.length === 0) {
      pdfBodyContent.push({ type: 'START_WORK', time: null, description: 'Start time: Missing', notes: [] });
      pdfBodyContent.push({ type: 'TAKE_BREAK', time: null, description: 'Take break: Missing', notes: [] });
      pdfBodyContent.push({ type: 'BACK_FROM_BREAK', time: null, description: 'Back from break: Missing', notes: [] });
      pdfBodyContent.push({ type: 'END_WORK', time: null, description: 'End Work: Missing', notes: [] });
    } else {
      clockingData.forEach((data: any) => {
        // start time
        pdfBodyContent.push({ type: 'START_WORK', time: data.clockInTime, description: 'Start time ' + moment(new Date(data.clockInTime)).format('hh:mm A'), notes: [] });

        if (data.employeeClockingLunchHourHistory && data.employeeClockingLunchHourHistory.length > 0) {
          data.employeeClockingLunchHourHistory.forEach(lunch => {
            if (lunch.lunchOutTime) {
              pdfBodyContent.push({ type: 'TAKE_BREAK', time: lunch.lunchOutTime, description: 'Take break at ' + moment(new Date(lunch.lunchOutTime)).format('hh:mm A'), notes: [] });
            } else {
              pdfBodyContent.push({ type: 'TAKE_BREAK', time: null, description: 'Take break: Missing', notes: [] });
            }
            if (lunch.lunchInTime) {
              pdfBodyContent.push({ type: 'BACK_FROM_BREAK', time: lunch.lunchInTime, description: 'Back from break at ' + moment(new Date(lunch.lunchInTime)).format('hh:mm A'), notes: [] });
            } else {
              pdfBodyContent.push({ type: 'BACK_FROM_BREAK', time: null, description: 'Back from break: Missing', notes: [] });
            }
          });
        } else {
          pdfBodyContent.push({ type: 'TAKE_BREAK', time: null, description: 'Take break: Missing', notes: [] });
          pdfBodyContent.push({ type: 'BACK_FROM_BREAK', time: null, description: 'Back from break: Missing', notes: [] });
        }

        if (data.missedEndWork) {
          pdfBodyContent.push({ type: 'END_WORK', time: null, description: 'End Work: Missing', notes: [] });
        } else {
          pdfBodyContent.push({ type: 'TAKE_BREAK', time: data.clockOutTime, description: 'End Work ' + moment(new Date(data.clockOutTime)).format('hh:mm A'), notes: [] });
        }
      });
    }

    const jobNoteResponse: any = await this.employeeJobReportNoteService.getAllEmployeeJobReportNote(rowData.id).toPromise();
    if (jobNoteResponse.data && jobNoteResponse.data.length > 0) {
      jobNoteResponse.data.forEach(note => {
        for (let i = pdfBodyContent.length - 1; i >= 0; i--) {
          const content = pdfBodyContent[i];
          if (content) {
            content.notes.push({
              time: moment(new Date(note.createdAt)),
              note: note.note,
              noteReason: note.noteReason,
              timeFormat: moment(new Date(note.createdAt)).format('MM-DD-YYYY hh:mm A'),
              title: note.createdByUsr + ' : ' + note.notesTime,
            });
            break;
          }
        }
      });
    }

    for (let i = 0; i < pdfBodyContent.length; i++) {
      const currentTime = pdfBodyContent[i].time;
      if (!currentTime) {
        continue;
      }
      const nextTime = this.getNextReportTime(pdfBodyContent, i);
      if (nextTime) {
        this.fillMissingTimeFrame(pdfBodyContent[i].notes, currentTime, nextTime);
      }
    }

    const doc = this.buildPdf(reportDate, pdfBodyContent, rowData.fullName, rowData.id);
    const pdfBlob = doc.output('blob');
    const pdfFile = new File([pdfBlob], 'sample.pdf', { type: 'application/pdf' });

    const uploadFileResObj = await this.documentServicev2.uploadFile(pdfFile, 'employeee_job_report', rowData.id, 'EmployeeeJobReport').toPromise();
    console.log('exportPDF uploadFileResObj: ', uploadFileResObj);

    if (uploadFileResObj && uploadFileResObj.data && uploadFileResObj.data.fileUrl) {
      rowData.reportUrl = uploadFileResObj.data.fileUrl;
      const dataUpdate: any = {
        id: rowData.id,
        reportUrl: uploadFileResObj.data.fileUrl,
        lastModifiedBy: this.authService.getCurrentUsername(),
      }
      this.employeeJobReportService.updateJobReportNew(dataUpdate).subscribe(res => {
      });
    }
  }

  getNextReportTime(listReport: any[], currentIdx: number) {
    const currentReport = listReport[currentIdx];
    if (currentReport.type === 'END_WORK' || !currentReport.time) {
      return null;
    }
    for (let i = currentIdx + 1; i < listReport.length; i++) {
      const next = listReport[i].time;
      if (next) {
        return next;
      }
    }
    return null;
  }

  fillMissingTimeFrame(notes: any[], startTime, endTime) {
    let start = moment(new Date(startTime));
    start = start.set('minute', 0);
    start = start.set('second', 0);
    start = start.set('millisecond', 0);

    let current = start.clone();
    while (current.isBefore(moment(new Date(endTime)))) {
      const next = current.clone().add(59 * 60 * 1000, 'milliseconds');
      const isMissing = !notes.some(note => {
        const t = moment(note.time, 'HH:mm');
        return t.isSameOrAfter(current) && t.isBefore(next);
      });
      current.add(1, 'hour');
    }
  }

  buildPdf(reportDate, pdfBodyContent, fullName, reportId): jsPDF {
    const doc = new jsPDF();
    let baseUrl;
    if (window.location.href.includes('?')) {
      baseUrl = window.location.href.substring(0, window.location.href.indexOf('?'));
    } else {
      baseUrl = window.location.href;
    }

    let jobReportUrl = baseUrl;
    if (this.mode === 'create') {
      jobReportUrl = baseUrl.replace('add', 'edit') + '/' + reportId;
    }
    doc.setFillColor(25, 145, 245);
    doc.roundedRect(168, 5, 32, 10, 2, 2, 'FD');
    doc.textWithLink('Job Report', 170, 12, { url: jobReportUrl, target: '_blank' });
    doc.addImage('assets/images/logo-raw.png', 'PNG', 2, 0, 20, 20);
    doc.addImage('assets/images/operrtextback.webp', 'WEBP', 26, 6, 50, 6);
    doc.addFont('../../../../assets/fonts/Arial.ttf', 'Arial', 'normal');
    doc.setFont('Arial');
    autoTable(doc, { body: [[{ content: 'Job Report', styles: { halign: 'center', fontSize: 20, fontStyle: 'bold' } }]], theme: 'plain' });
    autoTable(doc, { margin: 0, body: [[{ content: 'Report Date: ' + moment(new Date()).format('MM/DD/YYYY hh:mm a'), styles: { halign: 'right', fontSize: 11 } }]], theme: 'plain' });
    doc.roundedRect(10, 42, 190, 25, 0, 0, 'S');
    autoTable(doc, {
      margin: 0,
      tableLineColor: 122,
      body: [
        [
          { content: 'Company Name: ' + this.companySelected?.value, styles: { halign: 'left', fontSize: 11, fontStyle: 'bold' } },
          { content: '', styles: { halign: 'right', fontSize: 11, fontStyle: 'bold' } }
        ],
        [
          { content: 'Date: ' + moment(reportDate).format('MM/DD/YYYY'), styles: { halign: 'left', fontSize: 11, fontStyle: 'bold' } },
          { content: 'Action Done by: ' + fullName, styles: { halign: 'right', fontSize: 11, fontStyle: 'bold', font: 'Arial' } }
        ],
      ],
      theme: 'plain'
    });

    pdfBodyContent.forEach(row => {
      autoTable(doc, { margin: 0, body: [[{ content: row.description, styles: { halign: 'left', fontSize: 12, fontStyle: 'bold', cellPadding: 0 } }]], theme: 'plain' });
      if (row.notes && row.notes.length > 0) {
        row.notes.forEach(note => {
          const body: any = [];
          const temporaryElement = document.createElement('div');
          const temporaryElementReason = document.createElement('div');
          temporaryElement.innerHTML = note.note;
          temporaryElementReason.innerHTML = note?.noteReason ? 'Reason Of missed this job report: ' + note?.noteReason : '';

          const regex = /<\w+[^>]*>/g;
          const regex1 = /<\/\w+>/g;
          let text = temporaryElement.innerHTML;
          let textReason = temporaryElementReason.innerHTML;

          text = text.split(regex).join('');
          text = text.split(regex1).join('\n');

          textReason = textReason.split(regex).join('');
          textReason = textReason.split(regex1).join('\n');

          const halign = note.isMissing ? 'center' : 'left';
          body.push(
            [
              { content: note.title, styles: { halign: 'left' } },
              { content: 'Added: ' + note.timeFormat, styles: { halign: 'right' } }
            ],
            [{ content: text, styles: { halign: halign }, colSpan: 2 }],
            [{ content: textReason, styles: { halign: halign }, colSpan: 2 }]
          );

          autoTable(doc, {
            margin: 0,
            includeHiddenHtml: true,
            styles: {
              lineWidth: 0.5,
            },
            bodyStyles: { fillColor: 255 },
            alternateRowStyles: { fillColor: [245, 245, 245] },
            body: body,
            theme: 'striped'
          });
        });
      }
    });

    return doc;
  }
}
