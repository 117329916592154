
<p-toast position="bottom-right">{{msgs}}</p-toast>
	<h1 class="m-0 ml-2 my-4">{{'Punch History' | translate}}</h1>
	<p-panel [toggleable]="true">
		<p-header class="flex-grow-1 d-flex align-items-center justify-content-between">
			<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i>{{'punchHistory' | translate}}</span>
<!--			<span *ngIf="!disableEmployee()" class="mr-2 c-pointer" (click)="showHistory()" style="cursor: pointer !important"><i class="fa-solid fa-clock-rotate-left text-primary c-pointer mr-2"></i><span class="text-dark">{{'history.title' | translate}}</span></span>-->
		</p-header>
		<div class="ui-g">
			<div class="ui-g-6 ui-sm-12" *ngIf="!isSuperAdmin()">
				<label>{{'timeClock' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-building"></i>
						</button>
					</div>
					<p-dropdown [options]="timeClock" [(ngModel)]="selectedTimeClock" placeholder="{{'Select One' | translate}}" (onChange)="timeClockChange($event)">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12"  *ngIf="!isSuperAdmin() && !disableEmployee()">
				<label> {{'employee.name' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-user"></i>
						</button>
					</div>
					<p-multiSelect defaultLabel="{{'Select Items' | translate}}" emptyFilterMessage="{{'No results found' | translate}}" [options]="employeeList" (onChange)="checkSelectedEmployee($event)" [(ngModel)]="historyFilter.employeeIds" [disabled]="disableEmployee()" id="id_filteredEmployeeList_to_selected_label">
						<ng-template pTemplate="item" let-item>
							<span class="one-liner" style="width: 90% !important">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12" *ngIf="!isSuperAdmin()">
				<label>{{'form.dateRange' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-week"></i>
						</button>
					</div>
					<mat-form-field class="w-100">
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate matInput defaultLabel="{{'StartDate' | translate}}" [(ngModel)]="fromDate" name="Startdate" (focus)="picker.open()">
							<input matEndDate matInput defaultLabel="{{'EndDate' | translate}}" [(ngModel)]="toDate" name="Enddate" (focus)="picker.open()" >
						</mat-date-range-input>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12" *ngIf="!isSuperAdmin() && !disableEmployee()">
				<label>{{'department.name' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-people-roof"></i>
						</button>
					</div>
					<p-dropdown *ngIf="!isEmployee" [options]="departments" [(ngModel)]="selectedDepartment" placeholder="{{'Select One' | translate}}" (onChange)="searchByDepartment($event)" >
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
					<input type="text" disabled *ngIf="isEmployee && employeeDepartment.length > 0" class="ui-inputtext w-100" placeholder="{{employeeDepartmentShadow | translate}}" >
				</div>
			</div>

			<div *ngIf="isSuperAdmin()" class="ui-g-6 ui-sm-12">
				<label>{{'company.name' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-building"></i>
						</button>
					</div>
					<p-dropdown [filter]="true" emptyFilterMessage="{{'No results found' | translate}}" [options]="companies" [(ngModel)]="selectedCompany" (ngModelChange)="changeCompany($event)" [showClear]="true" optionLabel="value" placeholder="{{'Select One' | translate}}" [disabled]="companyDisabled" >
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>

			<div *ngIf="isSuperAdmin()" class="ui-g-6 ui-sm-12">
				<label>{{'employee.name' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-user"></i>
						</button>
					</div>
					<p-multiSelect defaultLabel="{{'Select Items' | translate}}" emptyFilterMessage="{{'No results found' | translate}}"  class="w-100" [options]="employeeList" (onChange)="checkSelectedEmployee($event)" [(ngModel)]="historyFilter.employeeIds" [disabled]="disableEmployee()" id="id_filteredEmployeeList_to_selected_label" >
						<ng-template pTemplate="item" let-item>
							<span class="one-liner" style="width: 90% !important">{{item.label | translate}}</span>
						</ng-template>e>
					</p-multiSelect>
				</div>
			</div>

			<div *ngIf="isSuperAdmin()" class="ui-g-6 ui-sm-12">
				<label>{{'timeClock' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-dropdown [options]="timeClock" [(ngModel)]="selectedTimeClock" placeholder="{{'placeholder.pleaseSelect' | translate}}" (onChange)="timeClockChange($event)" >
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>

			<div *ngIf="isSuperAdmin()" class="ui-g-6 ui-sm-12">
				<label>{{'form.dateRange' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-week"></i>
						</button>
					</div>
					<mat-form-field class="w-100">
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate matInput defaultLabel="{{'StartDate' | translate}}"
								[(ngModel)]="fromDate" name="Startdate" (focus)="picker.open()">
							<input matEndDate matInput defaultLabel="{{'EndDate' | translate}}" [(ngModel)]="toDate"
								name="Enddate" (focus)="picker.open()" >
						</mat-date-range-input>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>
				</div>
			</div>

			<div *ngIf="isSuperAdmin()" class="ui-g-6 ui-sm-12">
				<label>{{'department.name' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-people-roof"></i>
						</button>
					</div>
					<p-dropdown [filter]="true" emptyFilterMessage="{{'No results found' | translate}}" [options]="departments" [(ngModel)]="selectedDepartment" placeholder="{{'placeholder.pleaseSelect' | translate}}" (onChange)="searchByDepartment($event)">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>

			<div class="ui-g-6 ui-sm-12">
				<label>{{'Location' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-location-dot"></i>
						</button>
					</div>
					<p-dropdown [options]="allLocationList" [(ngModel)]="selectedLocation" placeholder="{{'placeholder.pleaseSelect' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>

			<div *ngIf="isSuperAdmin()" class="ui-g-6 ui-sm-12">
				<label>{{'Presence' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-street-view"></i>
						</button>
					</div>
					<p-dropdown [options]="workStatus" [(ngModel)]="selectedWorkStatus" placeholder="{{'placeholder.pleaseSelect' | translate}}" (onChange)="workStatusChange($event)">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>

			<div *ngIf="!isSuperAdmin()" class="ui-g-6 ui-sm-12">
				<label>{{'workStatus' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-info"></i>
						</button>
					</div>
					<p-dropdown [options]="workStatus" [(ngModel)]="selectedWorkStatus" placeholder="{{'Select One' | translate}}" 
						(onChange)="workStatusChange($event)" >
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			
			<div class="ui-g-12 pb-4 pt-3 text-center">
				<button type="button" class="btn btn-danger mx-1" (click)="selectedEmployeeId = null; reset()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
				<button type="button" class="btn btn-primary mx-1" (click)="search2()"><i class="fa-solid fa-check mr-2"></i>{{'button.search' | translate}}</button>
			</div>
		</div>
	</p-panel>
	<div class="d-flex flex-wrap align-items-center my-4" id="area-two">   
		<h1 class="m-0 ml-2">{{'Browse Punches' | translate}}</h1>
		<div class="d-flex align-items-center justify-content-between py-2" style="column-gap: 15px;font-size: 16px;row-gap: 10px" id="stats">

			<button *ngIf="roleLevel != 7" class="btn btn-white" (click)="openPopupEmployeeAbsent()" >
				<span class="text-nowrap"><span  class="badge mr-2" [ngClass]="emplAbsent > 0 ? 'badge-danger' : 'badge-muted'">{{emplAbsent}}</span>{{'Absent' | translate}} <i class="fa-solid fa-up-right-from-square text-primary ml-2"></i></span>
			</button>
			<button *ngIf="roleLevel != 7" class="btn btn-white" (click)="openPopupClockedIn()">
				<span class="text-nowrap"><span class="badge mr-2" [ngClass]="totalClockedIn > 0 ? 'badge-primary' : 'badge-muted'">{{totalClockedIn}}</span>{{'Started Work' | translate}} <i class="fa-solid fa-up-right-from-square text-primary ml-2"></i></span>
			</button>
			<button *ngIf="roleLevel != 7" class="btn btn-white" (click)="openPopupOutForLunch()" >
				<span class="text-nowrap"><span class="badge mr-2" [ngClass]="emplOutForLunch > 0 ? 'badge-orange' : 'badge-muted'">{{emplOutForLunch}}</span>{{'Started Break' | translate}} <i class="fa-solid fa-up-right-from-square text-primary ml-2"></i></span>
			</button>
			<button *ngIf="roleLevel != 7" class="btn btn-white" (click)="openNotLunchDialog()" >
				<span class="text-nowrap"><span class="badge mr-2" [ngClass]="totalNotLunch > 0 ? 'badge-danger' : 'badge-muted'">{{totalNotLunch}}</span>{{'Missed Break' | translate}}<i class="fa-solid fa-up-right-from-square text-primary ml-2"></i></span>
			</button>
			<button *ngIf="roleLevel != 7" class="btn btn-white" (click)="openPopupEndedWork()">
				<span class="text-nowrap"><span class="badge mr-2" [ngClass]="emplEndWork > 0 ? 'badge-success' : 'badge-muted'">{{emplEndWork}}</span>{{'Ended Work' | translate}} <i class="fa-solid fa-up-right-from-square text-primary ml-2"></i></span>
			</button>
			<button *ngIf="roleLevel != 7" (click)="loadStat()" class="btn btn-white" pTooltip="{{'Refresh Stats' | translate}}" tooltipPosition="left">
				<i class="fa-solid fa-rotate text-primary"></i>
			</button>
		</div>
	</div>
	<p-panel id="punch-data-table" [toggleable]="true">
		<p-toast position="bottom-right">{{msgs}}</p-toast>
		<p-contextMenu #cm [model]="items"></p-contextMenu>
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'MatchingPunchData' | translate}} ({{totalRecords || 0 | number}})</span>
		</p-header>
		<p-table #dt [value]="filteredPunchHistoryList" [responsive]="true" dataKey="id" [customSort]="true" [lazy]="true" styleClass="punch-table" (onLazyLoad)="loadPunchHistoryLazy($event)"  (onRowClick)="rowClicked($event)" [loading]="loading" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50]" [totalRecords]="totalRecords" class="pioh-data" [(contextMenuSelection)]="selectedPunchHistory" [contextMenu]="cm" [(first)]="first">
			<ng-template pTemplate="caption">
				<div class="d-flex align-items-center justify-content-between flex-wrap" style="row-gap: 10px">
					<span class="d-flex">
						<div class="dropdown">
							<button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
								<i *ngIf="!showProgressBarOnInitialLoad && !exportingPDF2" class="fa-solid fa-file-export mr-2"></i>
								<i *ngIf="showProgressBarOnInitialLoad || exportingPDF2" class="fa-solid fa-spinner mr-2 loadingHourglass"></i> {{'Export' | translate}}
							</button>
							<div class="dropdown-menu">
								<h6 class="dropdown-header">{{'Time Card Export' | translate}}</h6>
								<div class="dropdown-item" (click)="getReportFile()" ><i class="fa-solid fa-file-pdf text-primary mr-2" style="width: 25px;cursor: pointer"></i>{{'Export PDF' | translate}}</div>
								<div *ngIf="!disableSendEmployee" class="dropdown-item" (click)="sendReportFileToEmployee()"><i class="fa-solid fa-paper-plane text-primary mr-2" style="width: 25px;cursor: pointer"></i>{{'Send to Employee' | translate}}</div>
								<div class="dropdown-item" (click)="sendReportFileToFinance()"><i class="fa-solid fa-paper-plane text-primary mr-2" style="width: 25px;cursor: pointer"></i>{{'Send to Finance' | translate}}</div>
								<div class="dropdown-divider"></div>
								<h6 class="dropdown-header">{{'Payroll Export' | translate}}</h6>
								<div class="dropdown-item" (click)="exportPayrollConfirmModal = true"><i class="fa-solid fa-file-export text-primary mr-2" style="width: 25px;cursor:pointer"></i>{{'Export Payroll' | translate}}</div>
							</div>
							<button type="button" class="btn btn-info" (click)="viewTimeRecollection()">
								<span class="d-flex align-items-center" style="white-space: nowrap"><i class="fa-solid fa-clock-rotate-left mr-2"></i>{{'Time Recollection' | translate}}<i class="fa-solid fa-chevron-right ml-2"></i></span>
							</button>
							<button type="button" class="btn btn-primary mx-1" (click)="openTimesheetGenerate()" [disabled]="generatingTimesheet || !fromDate || !toDate || !historyFilter || historyFilter.employeeIds.length !== 1">
								<i *ngIf="!generatingTimesheet" class="fa-solid fa-file-export mr-2"></i>
								<i *ngIf="generatingTimesheet" class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Generate Timesheet' | translate}}
							</button>

						</div>
					</span>
					<div class="input-group w-100 mr-2" style="max-width: 450px">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-filter"></i>
							</button>
						</div>
						<input type="text" (keyup)="search($event)" placeholder="{{'Filter' | translate}}" [(ngModel)]="textSearch" pInputText>
						<div class="input-group-append">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important" (click)="opDisplayColumn.toggle($event);">
								<i class="fa-solid fa-gear text-primary" pTooltip="{{'Options' | translate}}" tooltipPosition="left"></i>
							</button>
						</div>
					</div>
				</div>
			</ng-template>
			<ng-template pTemplate="header" let-columns let-i="index">
				<tr id="header-row">
					<th>
						<div class="d-flex align-items-center justify-content-between w-100">
							<span class="one-liner" pTooltip="{{'Name' | translate}}">{{'Name' | translate}}</span>
							<span appNoDblClick (click)="op1.toggle($event); selectedField = 'employee.fullName';">
								<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
							</span>
						</div>
					</th>
					<th>
						<div class="d-flex align-items-center justify-content-between w-100">
							<span class="one-liner" pTooltip="{{'Dept' | translate}}">{{'Dept' | translate}}</span>
							<span appNoDblClick (click)="op1.toggle($event); selectedField = 'employee.department';">
								<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
							</span>
						</div>
					</th>
					<th>
						<div class="d-flex align-items-center justify-content-between w-100">
							<span class="one-liner" pTooltip="{{'Location' | translate}}">{{'Location' | translate}}</span>
							<span (click)="op1.toggle($event); selectedField = 'locationName';">
								<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
							</span>
						</div>
					</th>
					<th>
						<div class="d-flex align-items-center justify-content-between w-100">
							<span class="one-liner" pTooltip="{{'Date' | translate}}">{{'form.date' | translate}}</span>
							<span appNoDblClick (click)="op4.toggle($event); selectedField = 'punchDate';">
								<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
							</span>
						</div>
					</th>
					<th *ngIf="displayColumnDay">
						<div class="d-flex align-items-center justify-content-between w-100">
							<span class="one-liner" pTooltip="{{'Day' | translate}}">{{'Day' | translate}}</span>
							<span appNoDblClick (click)="op4.toggle($event); selectedField = 'punchDateDay';">
								<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
							</span>
						</div>
					</th>
					<th *ngIf="displayColumnTimeConfirmation">
						<div class="d-flex align-items-center justify-content-between w-100">
							<span class="one-liner" pTooltip="{{'Time Confirmation' | translate}}">{{'Time Confirmation' | translate}}</span>
							<span appNoDblClick (click)="op4.toggle($event); selectedField = 'timeConfirmation';">
								<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
							</span>
						</div>
					</th>
					<th>
						<div class="d-flex align-items-center justify-content-between w-100">
							<span class="one-liner" pTooltip="{{'WorkIn' | translate}}">{{'Start Work' | translate}}</span>
							<span appNoDblClick (click)="op2.toggle($event); selectedField = 'clockInTime';">
								<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
							</span>
						</div>
					</th>
					<th>
						<div class="d-flex align-items-center justify-content-start w-100">
							<span class="one-liner" pTooltip="{{'Start Break' | translate}}">{{'Start Break' | translate}}</span>
						</div>
					</th>
					<th>
						<div class="d-flex align-items-center justify-content-start w-100">
							<span class="one-liner" pTooltip="{{'End Break' | translate}}">{{'End Break' | translate}}</span>
						</div>
					 </th>
					<th>
						<div class="d-flex align-items-center justify-content-start w-100">
							<span class="one-liner" pTooltip="{{'Break Duration' | translate}}">{{'Break Duration' | translate}}</span>
						</div>
					</th>
					<th>
						<div class="d-flex align-items-center justify-content-between w-100">
							<span class="one-liner" pTooltip="{{'End Work' | translate}}">{{'End Work' | translate}}</span>
							<span appNoDblClick (click)="op2.toggle($event); selectedField = 'clockOutTime';">
								<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
							</span>
						</div>
					</th>
					<th [pSortableColumn]="'totalTime'">
						<div class="d-flex align-items-center justify-content-start w-100">
							<span class="one-liner">
								{{'Regular Hour(s)'  | translate}}	
							</span>
						</div>
					</th>
					<th [pSortableColumn]="'overTime'">
						<div class="d-flex align-items-center justify-content-between w-100">
							<span class="one-liner" pTooltip="{{'O.T.' | translate}}">{{'O.T.' | translate}}</span>
							<span appNoDblClick (click)="op2.toggle($event); selectedField = 'overTime';">
								<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
							</span>
						</div>
					</th>
					<th [pSortableColumn]="'totalTime'">
						<div class="d-flex align-items-center justify-content-start w-100">
							<span class="one-liner">
								{{'total'  | translate}}	
							</span>
						</div>
					</th>
					<th>
						<span class="one-liner">
							<i class="fa-solid fa-location-dot text-muted" pTooltip="{{'Track' | translate}}" tooltipPosition="left"></i>
						</span>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData>
				<tr [pSelectableRow]="rowData" id="id_punch_history_table_body">
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'FullName' | translate}}: </span>
							<span style="cursor: pointer;" class="text-primary" *ngIf="rowData.employee.fullName" (click)="gotoEmployeePage(rowData?.employee.employeeId)" pTooltip="{{rowData.employee.fullName}}">{{rowData.employee.fullName}}</span>
							<span *ngIf="!rowData.employee.fullName" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<div class="one-liner">
							<span class="mobile-table-label">{{'Department' | translate}}: </span>
							<span *ngIf="rowData.departmentShadow" pTooltip="{{rowData.departmentShadow}}">{{rowData.departmentShadow | translate}}</span>
							<span *ngIf="!rowData.departmentShadow" class="text-muted">{{'no data' | translate}}</span>
						</div>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Location' | translate}}: </span>
							<span *ngIf="rowData.locationName" pTooltip="{{rowData.locationName}}">{{rowData.locationName | translate}}</span>
							<span *ngIf="!rowData.locationName" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'form.date' | translate}}: </span> 
							<span *ngIf="rowData.punchDate" pTooltip="{{rowData.punchDate | date: 'MM/dd/yyyy'}}">{{rowData.punchDate | date: 'MM/dd/yyyy'}}</span>
							<span *ngIf="!rowData.punchDate" class="text-muted">{{'no data' | translate}}</span>
							
						</span>
					</td> 
					<td *ngIf="displayColumnDay">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Day' | translate}}: </span> 
							<span *ngIf="rowData.punchDate" pTooltip="{{rowData.punchDate | date: 'EEE'}}">{{rowData.punchDate | date: 'EEE'}}</span>
							<span *ngIf="!rowData.punchDate" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td *ngIf="displayColumnTimeConfirmation">
						<span class="one-liner">
							<span class="mobile-table-label">{{'Time Confirmation' | translate}}: </span>
							<span *ngIf="rowData.timeConfirmation" pTooltip="{{rowData.timeConfirmation | date: 'MM/dd/yyyy'}}">{{rowData.timeConfirmation | date: 'MM/dd/yyyy'}}</span>
							<span *ngIf="!rowData.timeConfirmation" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td class="p-0">
						<div class="d-flex align-items-center" (click)="allowEditCheckIn(rowData)">
							<div *ngIf="!rowData.editAllowedCheckIn && !isExceptionPunchInOut(rowData.locationName)" class=" text-left pl-lg-2 pl-sm-0"  [style.cursor]="userInput === 'isEmployee' ? 'default' : 'cell'">
								<span class="one-liner d-flex">
									<span class="mobile-table-label">{{'Start Work' | translate}}: </span>
									<span [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left" [ngClass]="{'bold-text': !rowData.clockInTime}" [class]="rowData.lunchOutTimeOriginal? 'text-danger' : ''">{{ !rowData.missedStartWork && rowData.clockInTime == null ? '--:--' : ''}}</span>
									<span [ngClass]="{'bold-text': rowData.clockInTime==null}" [class]="rowData.clockInTimeOriginal? 'one-liner text-danger' : 'one-liner justify-content-between'">
										<span *ngIf="rowData.clockInTimeOriginal" pTooltip="{{'Changed From' | translate}} {{dateToShowInTwelveHoursFormat(rowData.clockInTimeOriginal)}} {{getToolTip(rowData.locationName, rowData.lastModifiedBy)}}">{{ rowData.missedStartWork? ('Missing' | translate) : rowData.clockInTime | date: 'h:mm a'}}</span>
										<span *ngIf="!rowData.clockInTimeOriginal" pTooltip="{{ rowData.missedStartWork? ('Missing' | translate) : ((rowData.clockInTime | date: 'hh:mm a') + ' (' + rowData.locationName + ')')}}">{{ rowData.missedStartWork? ('Missing' | translate) : rowData.clockInTime | date: 'h:mm a'}}</span>
										<i class="cursor-pointer" [hidden]="rowData.clockInReason === '' || rowData.clockInReason === null" (mouseenter)="showPunchInOutReason($event, rowData.clockInReason)" (mouseleave)="hidePunchInOutReason()" class="fa-solid fa-circle-info text-primary ml-1" style="font-size: 12px"></i>
										<img [src]="rowData?.clockInPhoto" *ngIf="rowData.clockInPhoto" class="align-self-center profile-picture m-auto cursor-pointer" style="max-height:30px; max-width: 40px; margin-left: 5px !important;" (click)="showFaceImage($event, rowData?.id, 'CLOCK_IN', rowData.clockInTime, rowData?.employee?.fullName, rowData?.clockInPhoto, rowData?.clockInPhotoStatus)"/>
										<i *ngIf="rowData.clockInPhoto && rowData.clockInPhotoStatus === 'Pending'" class="fa-solid fa-exclamation-triangle text-danger ml-2" style="font-size: 12px" pTooltip="{{'Please review and confirm the submitted time' | translate}}" tooltipPosition="right"></i>
									</span>
								</span>
							</div>
							<div *ngIf="!rowData.editAllowedCheckIn && isExceptionPunchInOut(rowData.locationName)" [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy) : ''" tooltipPosition="left" class="pl-sm-0 pl-lg-2" [ngClass]="{'bold-text': rowData.clockInTime==null}" [class]="rowData.clockInTimeOriginal? 'text-danger text-center' : 'text-center'">
								<span class="one-liner">
									<span class="mobile-table-label">{{'Start Work' | translate}}: </span>
									{{ rowData.locationName | translate}}
								</span>
							</div>
							<div *ngIf="rowData.editAllowedCheckIn" [ngClass]="{'d-flex w-100': rowData.editAllowedCheckIn==true}" >
								<input type="time" [(ngModel)]="rowData.tempCheckInTime" class="editTime" />
								<div class="showUpdateCancelbtn">
									<i class="fa-solid fa-square-check text-success"  (click)="updateCheckInTime(rowData)"></i>
									<i class="fa-solid fa-square-xmark text-danger"  (click)="cancelEditCheckIn(rowData)"></i>
								</div>
							</div>
							<i *ngIf="!rowData.editAllowedCheckIn && !isEmployee" (click)="allowEditCheckIn(rowData)" class="c-pointer fa-solid fa-pen-to-square ml-auto text-muted mr-2 text" style="cursor: pointer !important"></i>
						</div>
					</td>

					<td class="p-0">
						<div class="d-flex align-items-center justify-content-between">
							<div *ngIf="rowData.employeeClockingLunchHourHistory.length > 1 && (rowData.lunchOutTime !=null && (rowData.employeeClockingLunchHourHistory != null))" class="pl-sm-0 pl-lg-2">
								<span class="one-liner" (click)="expand(rowData)">
									<span class="mobile-table-label">{{'Start Break' | translate}}: </span> 
									<span class="text-primary c-pointer">{{'Details' | translate}}</span>
								</span>
							</div>
							<div *ngIf="!rowData.editAllowedOutForLunch && !isExceptionPunchInOut(rowData.locationName)" (click)="allowEditOutForLunch(rowData)" class="pl-lg-2 pl-sm-0 text-left" [style.cursor]="userInput === 'isEmployee' ? 'default' : 'cell'">
								<span *ngIf="!(rowData.lunchOutTime !=null && (rowData.employeeClockingLunchHourHistory != null && rowData.employeeClockingLunchHourHistory.length > 1))"  class="one-liner d-flex">
									<span class="mobile-table-label">{{'Break' | translate}}:</span> 
									<span [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left" [ngClass]="{'bold-text': !rowData.lunchOutTime}" [class]="rowData.lunchOutTimeOriginal? 'text-danger' : ''">{{ !rowData.missedLunchOutTime && rowData.lunchOutTime == null ? '--:--' : ''}}</span>
									<span class="d-flex justify-content-between align-items-center" [ngClass]="{'bold-text': !rowData.lunchOutTime}" [class]="rowData.lunchOutTimeOriginal? 'one-liner text-danger' : 'one-liner justify-content-between'" >
										<span *ngIf="rowData.lunchOutTimeOriginal" pTooltip="{{ rowData.missedLunchOutTime ? ('Missing' | translate) : (rowData.lunchOutTime | date: 'hh:mm a')}} {{'Changed From' | translate}} {{dateToShowInTwelveHoursFormat(rowData.lunchOutTimeOriginal)}} {{getToolTip(rowData.locationName, rowData.lastModifiedBy)}}">{{ rowData.missedLunchOutTime ? ('Missing' | translate) : (rowData.lunchOutTime | date: 'h:mm a')}}</span>
										<span *ngIf="!rowData.lunchOutTimeOriginal" pTooltip="{{ rowData.missedLunchOutTime ? ('Missing' | translate) : ((rowData.lunchOutTime | date: 'hh:mm a') + ' (' + rowData.locationName + ')')}}">{{ rowData.missedLunchOutTime ? ('Missing' | translate) : (rowData.lunchOutTime | date: 'h:mm a')}}</span>
										<i data-html="true" [hidden]="rowData.lunchOutReason === '' || rowData.lunchOutReason === null "  (mouseenter)="showPunchInOutReason($event, rowData.lunchOutReason)" (mouseleave)="hidePunchInOutReason()" class="cursor-pointer" class="fa-solid fa-circle-info text-primary ml-1" style="font-size: 12px"></i>
										<img [src]="rowData?.lunchOutPhoto" *ngIf="rowData.lunchOutPhoto" class="align-self-center profile-picture m-auto cursor-pointer" style="max-height:30px; max-width: 40px; margin-left: 5px !important;" (click)="showFaceImage($event, rowData?.id, 'LUNCH_OUT', rowData.lunchOutTime, rowData?.employee?.fullName, rowData?.lunchOutPhoto, rowData?.lunchOutPhotoStatus)"/>
										<i *ngIf="rowData.lunchOutPhoto && rowData.lunchOutPhotoStatus === 'Pending'" class="fa-solid fa-exclamation-triangle text-danger ml-2" style="font-size: 12px" pTooltip="{{'Please review and confirm the submitted time' | translate}}" tooltipPosition="right"></i>
									</span>
								</span>
							</div>
							<div *ngIf="!rowData.editAllowedOutForLunch && isExceptionPunchInOut(rowData.locationName)" [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left" class="pl-sm-0 pl-lg-2" [ngClass]="{'bold-text': !rowData.lunchOutTime}" [class]="rowData.lunchOutTimeOriginal? 'text-danger text-center' : 'text-center'" >
								<span class="one-liner">
									<span class="mobile-table-label">{{'Start Break' | translate}}: </span> 
									{{ rowData.locationName | translate}} 
								</span>
							</div>
							<div *ngIf="rowData.editAllowedOutForLunch" [ngClass]="{'d-flex w-100': rowData.editAllowedOutForLunch==true}">
								<input type="time" [(ngModel)]="rowData.tempOutForLunch" class="editTime" />
								<div class="showUpdateCancelbtn">
									<i class="fa-solid fa-square-check text-success"  (click)="updateCheckOutForLunchTime(rowData)"></i>
									<i class="fa-solid fa-square-xmark text-danger"  (click)="cancelEditOutForLunch(rowData)"></i>
								</div>
							</div>
							<span *ngIf="!rowData.editAllowedOutForLunch" class="d-flex">
								<i *ngIf="rowData.lunchOutTime !=null && (rowData.employeeClockingLunchHourHistory != null && rowData.employeeClockingLunchHourHistory.length > 1)" (click)="expand(rowData)" pTooltip="{{'Expand' | translate}}" [class]="this.add" class="fa-solid mr-1"></i>
								<i *ngIf="!isEmployee && (rowData.employeeClockingLunchHourHistory.length <= 1)" (click)="allowEditOutForLunch(rowData)" class="c-pointer fa-solid fa-pen-to-square ml-auto text-muted mr-2"></i>
							</span>
						</div>
		
					</td>

					<td class="p-0">
						<div class="d-flex align-items-center justify-content-between">
							<div *ngIf="rowData.employeeClockingLunchHourHistory.length > 1 && (rowData.lunchOutTime !=null && (rowData.employeeClockingLunchHourHistory != null))" class="pl-sm-0 pl-lg-2" >
								<span class="one-liner" (click)="expand(rowData)">
									<span class="mobile-table-label">{{'End Break' | translate}}: </span> 
									<span class="text-primary c-pointer">{{'Details' | translate}}</span>
								</span>
							</div>
							<div *ngIf="!rowData.editAllowedBackFromLunch && !isExceptionPunchInOut(rowData.locationName)" (click)="allowEditBackFromLunch(rowData)" class="pl-lg-2 pl-sm-0 text-left" [style.cursor]="userInput === 'isEmployee' ? 'default' : 'cell'">
								<span *ngIf="!(rowData.lunchOutTime !=null && (rowData.employeeClockingLunchHourHistory !=null && rowData.employeeClockingLunchHourHistory.length > 1))" class="one-liner d-flex">
									<span class="mobile-table-label">{{'End Break' | translate}}: </span>
									<span [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left" [ngClass]="{'bold-text': !rowData.lunchInTime}" [class]="rowData.lunchInTimeOriginal? 'text-danger' : ''">{{! rowData.missedLunchInTime && rowData.lunchInTime == null ? '--:--' : ''}}</span>
									<span  [ngClass]="{'bold-text': !rowData.lunchInTime}" [class]="rowData.lunchInTimeOriginal? 'one-liner text-danger' : 'one-liner justify-content-between'">
										<span *ngIf="rowData.lunchInTimeOriginal" pTooltip="{{rowData.missedLunchInTime ? ('Missing' | translate) : (rowData.lunchInTime | date: 'hh:mm a')}} {{'Changed From' | translate}} {{dateToShowInTwelveHoursFormat(rowData.lunchInTimeOriginal)}} {{getToolTip(rowData.locationName, rowData.lastModifiedBy)}}" tooltipPosition="left">{{rowData.missedLunchInTime ? ('Missing' | translate) : (rowData.lunchInTime | date: 'h:mm a')}}</span>
										<span *ngIf="!rowData.lunchInTimeOriginal" pTooltip="{{rowData.missedLunchInTime ? ('Missing' | translate) : ((rowData.lunchInTime | date: 'hh:mm a') + ' (' + rowData.locationName + ')')}}" tooltipPosition="left">{{rowData.missedLunchInTime ? ('Missing' | translate) : (rowData.lunchInTime | date: 'h:mm a')}}</span>
										<i [hidden]="(rowData.lunchInReason === '' || rowData.lunchInReason === null)" (mouseenter)="showPunchInOutReason($event, rowData.lunchInReason)" (mouseleave)="hidePunchInOutReason()" class="fa-solid fa-circle-info text-primary ml-1" style="font-size: 12px"></i>
										<img [src]="rowData?.lunchInPhoto" *ngIf="rowData.lunchInPhoto" class="align-self-center profile-picture m-auto cursor-pointer" style="max-height:30px; max-width: 40px; margin-left: 5px !important;" (click)="showFaceImage($event, rowData?.id, 'LUNCH_IN', rowData.lunchInTime, rowData?.employee?.fullName, rowData?.lunchInPhoto, rowData?.lunchInPhotoStatus)"/>
										<i *ngIf="rowData.lunchInPhoto && rowData.lunchInPhotoStatus === 'Pending'" class="fa-solid fa-exclamation-triangle text-danger ml-2" style="font-size: 12px" pTooltip="{{'Please review and confirm the submitted time' | translate}}" tooltipPosition="right"></i>
									</span>
								</span>
							</div>
							<div *ngIf="!rowData.editAllowedBackFromLunch && isExceptionPunchInOut(rowData.locationName)" [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left" class="pl-sm-0 pl-lg-2" [ngClass]="{'bold-text': !rowData.lunchInTime}" [class]="rowData.lunchInTimeOriginal? 'text-danger text-center' : 'text-center'">
								<span class="one-liner">
									<span class="mobile-table-label">{{'End Break' | translate}}: </span>
									{{ rowData.locationName | translate}}
								</span>
							</div>
							
							<div [ngClass]="{'d-flex w-100': rowData.editAllowedBackFromLunch==true}" *ngIf="rowData.editAllowedBackFromLunch">
								<input *ngIf="rowData.editAllowedBackFromLunch" type="time" [(ngModel)]="rowData.tempBackFromLunch" class="editTime" />
								<div class="showUpdateCancelbtn" *ngIf="rowData.editAllowedBackFromLunch">
									<i class="fa-solid fa-square-check text-success"  (click)="updateBackFromLunchTime(rowData)"></i>
									<i class="fa-solid fa-square-xmark text-danger"  (click)="cancelEditBackFromLunch(rowData)"></i>
								</div>
							</div>
							<span *ngIf="!rowData.editAllowedBackFromLunch" class="d-flex">
								<i *ngIf="rowData.lunchOutTime !=null && (rowData.employeeClockingLunchHourHistory !=null && rowData.employeeClockingLunchHourHistory.length > 1)" (click)="expand(rowData)" [class]="this.add" class="mr-1 fa-solid" pTooltip="{{'Expand' | translate}}"></i>
								<i *ngIf="!isEmployee && (rowData.employeeClockingLunchHourHistory.length <= 1)" (click)="allowEditBackFromLunch(rowData)" class="c-pointer fa-solid fa-pen-to-square ml-auto text-muted mr-2"></i>
							</span>
						</div>
					</td>

					<td class="p-0">
						<div *ngIf="isExceptionPunchInOut(rowData.locationName)" class="d-flex align-items-center pl-lg-2">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Break Total' | translate}}: </span>{{rowData.locationName | translate}}
							</span>
						</div>
						<div *ngIf="!isExceptionPunchInOut(rowData.locationName)" class="d-flex align-items-center justify-content-between pl-lg-2 w-100">					
							<span class="one-liner">
								<span class="mobile-table-label">{{'LunchTot' | translate}}: </span> 
								<!-- {{hhmm(rowData.lunchHours)}} -->
								{{calCulateTotalLunchTothhmm(rowData)}}
							</span>
							<i [class]="this.add" *ngIf="rowData.lunchOutTime !=null && (rowData.employeeClockingLunchHourHistory !=null && rowData.employeeClockingLunchHourHistory.length > 1)" (click)="expand(rowData)" class="fa-solid mr-2"  pTooltip="{{'Expand' | translate}}"></i>
						</div>  	
					</td>

					<td class="p-0">
						<div class="d-flex align-items-center justify-content-between">
							<span class="mobile-table-label mobile-left text-nowrap">{{'WorkEnd' | translate}}: </span>
							<span *ngIf="(!rowData.clockOutTime || !rowData.editAllowedCheckOut) && isExceptionPunchInOut(rowData.locationName)" class="text-nowrap text-truncate text-muted pl-3">{{'no data' | translate}}</span>
							<div *ngIf="!rowData.editAllowedCheckOut &&  !isExceptionPunchInOut(rowData.locationName)" (click)="allowEditCheckOut(rowData)" class="pl-lg-3 pl-sm-0 text-left" [style.cursor]="userInput === 'isEmployee' ? 'default' : 'cell'">
								<span class="one-liner d-flex">
									<span [pTooltip]="rowData.lastModifiedBy? getToolTip(rowData.locationName, rowData.lastModifiedBy): ''" tooltipPosition="left"  [ngClass]="{'bold-text': !rowData.clockOutTime}" [class]="rowData.clockOutTimeOriginal? 'text-danger' : ''">{{ !rowData.missedEndWork && rowData.clockOutTime == null ? '--:--' : ''}}</span>
									<span [ngClass]="{'bold-text': !rowData.clockOutTime}" [class]="rowData.clockOutTimeOriginal? 'one-liner text-danger' : 'one-liner justify-content-between'">
										<span *ngIf="rowData.clockOutTimeOriginal" pTooltip="{{rowData.missedEndWork ? getEndWorkLabel(rowData) : rowData.clockOutTime | date: 'hh:mm a'}} {{'Changed From' | translate}} {{dateToShowInTwelveHoursFormat(rowData.clockOutTimeOriginal)}} {{getToolTip(rowData.locationName, rowData.lastModifiedBy)}}" tooltipPosition="bottom" >{{rowData.missedEndWork ? getEndWorkLabel(rowData) : rowData.clockOutTime | date: 'h:mm a'}}</span>
										<span *ngIf="!rowData.clockOutTimeOriginal" pTooltip="{{rowData.missedEndWork ? getEndWorkLabel(rowData) : ((rowData.clockOutTime | date: 'hh:mm a') + ' (' + rowData.locationName + ')')}}">{{rowData.missedEndWork ? getEndWorkLabel(rowData) : rowData.clockOutTime | date: 'h:mm a'}}</span>
										<i [hidden]="rowData.clockOutReason === '' || rowData.clockOutReason === null " class="cursor-pointer"  (mouseenter)="showPunchInOutReason($event, rowData.clockOutReason)" (mouseleave)="hidePunchInOutReason()" class="fa-solid fa-circle-info text-primary ml-1" style="font-size: 12px"></i>
										<img [src]="rowData?.clockOutPhoto" *ngIf="rowData.clockOutPhoto" class="align-self-center profile-picture m-auto cursor-pointer" style="max-height:30px; max-width: 40px; margin-left: 5px !important;" (click)="showFaceImage($event, rowData?.id, 'CLOCK_OUT', rowData.clockOutTime, rowData?.employee?.fullName, rowData?.clockOutPhoto, rowData?.clockOutPhotoStatus)"/>
										<i *ngIf="rowData.clockOutPhoto && rowData.clockOutPhotoStatus === 'Pending'" class="fa-solid fa-exclamation-triangle text-danger ml-2" style="font-size: 12px" pTooltip="{{'Please review and confirm the submitted time' | translate}}" tooltipPosition="right"></i>	
									</span>
								</span>			
							</div>						
							<div [ngClass]="{'d-flex w-100': rowData.editAllowedCheckOut==true}" *ngIf="rowData.editAllowedCheckOut">
								<div class="d-flex align-items-center">
									<input *ngIf="rowData.editAllowedCheckOut" type="time" [(ngModel)]="rowData.tempCheckOutTime" class="editTime" />
									<div class="showUpdateCancelbtn" *ngIf="rowData.editAllowedCheckOut">
										<i class="fa-solid fa-square-check text-success" (click)="updateCheckOutTime(rowData)"></i>
										<i class="fa-solid fa-square-xmark text-danger" (click)="cancelEditCheckOut(rowData)"></i>
									</div>
								</div>
							</div>
							<!-- <span *ngIf="!rowData.clockOutTime && !rowData.editAllowedCheckOu" class="text-muted">{{'no data' | translate}}</span> -->
							<i *ngIf="!rowData.editAllowedCheckOut && !isEmployee"  (click)="allowEditCheckOut(rowData)" class="c-pointer fa-solid fa-pen-to-square ml-auto text-muted mr-2"></i>
						</div>
					</td>
					<td *ngIf="rowData.spreadHour && rowData.spreadHour > 0" [ngClass]="{'bold-text': (rowData.clockInTime==null||rowData.clockOutTime==null)}">
						<span class="one-liner">
							<span class="mobile-table-label">{{'total'  | translate}}: </span> <span class="mobile-big">{{rowData.workHourShadow}}  <strong style="color: orangered;" [pTooltip]="rowData.spreadHourText" tooltipPosition="top"> + 01:00 </strong></span>
						</span>
					</td>
					<td *ngIf="rowData.spreadHour == null || rowData.spreadHour == 0" [ngClass]="{'bold-text': (rowData.clockInTime==null||rowData.clockOutTime==null)}">
						<span class="one-liner">
							<span class="mobile-table-label">{{'total'  | translate}}: </span> <span class="mobile-big">{{rowData.workHourShadow}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Overtime' | translate}}: </span>
							<span *ngIf="rowData.overTimeStr" pTooltip="{{rowData.overTimeStr}}">{{rowData.overTimeStr}}</span>
							<span *ngIf="!rowData.overTimeStr" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td *ngIf="rowData.spreadHour && rowData.spreadHour > 0" [ngClass]="{'bold-text': (rowData.clockInTime==null||rowData.clockOutTime==null)}">
						<span class="one-liner">
							<span class="mobile-table-label">{{'total'  | translate}}: </span> <span class="mobile-big">{{rowData.workHoursNew}}  <strong style="color: orangered;" [pTooltip]="rowData.spreadHourText" tooltipPosition="top"> + 01:00 </strong></span>
						</span>
					</td>
					<td *ngIf="rowData.spreadHour == null || rowData.spreadHour == 0" [ngClass]="{'bold-text': (rowData.clockInTime==null||rowData.clockOutTime==null)}">
						<span class="one-liner">
							<span class="mobile-table-label">{{'total'  | translate}}: </span> <span class="mobile-big">{{rowData.workHoursNew}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Location' | translate}}: </span>
							<i class="fa-solid fa-map-location-dot text-muted c-pointer" [ngClass]="{'text-primary' : (rowData.lat && rowData.lng && !rowData.missedStartWork)}"
							   (click)="viewMap(rowData)" pTooltip="{{'View Map' | translate}}" tooltipPosition="left"></i>
						</span>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="footer" let-columns>
				<tr id="footer-row">
					<td colspan="9"><b class="desktop-table-label pull-right">{{'total' | translate}}:</b></td>
					<td></td>
					<td class="bold-text">
						<span class="d-flex align-items-center">
							<span class="mobile-table-label">{{'Total Regular Hour(s)' | translate}}: </span>
							<i *ngIf="missingOrInvalidDate" (click)="noteDetailsMouseEnter($event)" class="fa-solid fa-triangle-exclamation cursor-pointer text-warning mr-1" pTooltip="{{'Total is missing hours' | translate}}" tooltipPosition="left"></i><span pTooltip="{{getTotalHour(filteredPunchHistoryList)}}" tooltipPosition="left">{{getTotalHour(filteredPunchHistoryList)}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Total Overtime' | translate}}: </span>
							{{getTotalOverrtime(filteredPunchHistoryList)}}
						</span>
					</td>
					<td class="bold-text">
						<span class="d-flex align-items-center">
							<span class="mobile-table-label">{{'Total Hours' | translate}}: </span>
							<i *ngIf="missingOrInvalidDate" class="fa-solid fa-triangle-exclamation cursor-pointer text-warning mr-1" 
								pTooltip="{{'Total is missing hours' | translate}}" tooltipPosition="left"></i>
							<span pTooltip="{{getTotalHourNew(filteredPunchHistoryList)}}" tooltipPosition="left">{{getTotalHourNew(filteredPunchHistoryList)}}</span>
						</span>
					</td>
					<td></td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage" let-columns>
				<tr *ngIf="!loading">
					<td [attr.colspan]="13" class="text-center py-5">
						<div class="text-muted my-5">{{'no data' | translate}}</div>
					</td>
				</tr>
				<tr *ngIf="loading">
					<td [attr.colspan]="13" class="text-center py-5">
						<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
					</td>
				</tr>
			</ng-template>

			<p-overlayPanel #op1 appendTo="body">
				<p-listbox [options]="textSortOptionsClient" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
					<ng-template let-col pTemplate="item">
						<div class="d-flex align-items-center">
							<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
							<span>{{col.name | translate}}</span>
						</div>
					</ng-template>
				</p-listbox>
			</p-overlayPanel>
			<p-overlayPanel #op2 appendTo="body">
				<p-listbox [options]="numberSortOptionsClient" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
					<ng-template let-col pTemplate="item">
						<div class="d-flex align-items-center">
							<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
							<span>{{col.name | translate}}</span>
						</div>
					</ng-template>
				</p-listbox>
			</p-overlayPanel>
			<p-overlayPanel #op4 appendTo="body">
				<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
					<ng-template let-col pTemplate="item">
						<div class="d-flex align-items-center">
							<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
							<span>{{col.name | translate}}</span>
						</div>
					</ng-template>
				</p-listbox>
			</p-overlayPanel>
			
		</p-table>
	</p-panel>
<p-dialog [(visible)]="showDialog" [modal]="true" [style]="{'width': '90%', 'max-width':'750px'}" [closable]="true" [id]="'multipleLunches'">
	<p-header>
		<span><i class="fa-solid fa-plus mr-2"></i>{{'Multiple Breaks' | translate}}: {{selectedRowData?.employee.fullName}} [{{punchDate}}]</span>
	</p-header>
	<p-table #table [value]="selectedRowData?.employeeClockingLunchHourHistory" [rows]="(selectedRowData?.employeeClockingLunchHourHistory)?.size" [paginator]="false" [lazy]="false" [responsive]="true" [loading]="loading">
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngFor="let col of displayLunchColumns"  [ngClass]="col?.field==='action' ? 'tdwh-action' : ''" [style.width]="col.width">
					<span class="d-flex align-items-center justify-content-between">
						<span class="one-liner">{{col?.label | translate}}</span>
					</span>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData let-i="rowIndex">
			<tr *ngIf="loading && (i+1 === 1)">
				<td [attr.colspan]="5"  class="text-center py-5">
					<span class="text-muted my-5">{{'Loading...' | translate}}</span>
				</td>
			</tr>
			<!-- <tr *ngIf="i+1 === 2" [pSelectableRow]="rowData"> -->
			<tr [pSelectableRow]="rowData">
				<td *ngFor="let col of displayLunchColumns;">
					<ng-container [ngSwitch]="col.field">
						<span *ngSwitchCase="'id'">
							{{i + 1}}
						</span>
						<span *ngSwitchCase="'lunchStart'">
							<div class="d-flex align-items-center justify-content-between">
								<span *ngIf="!rowData.editAllowedLunchStart" class="one-liner" pTooltip="{{rowData.isAdminUpdateLunchOut ? ('Change from (' + (rowData.prevLunchOutTime ? (rowData.prevLunchOutTime | date:'hh:mm a') : 'None') + ') by ' + rowData.lastModifiedBy) : ''}}">
									<span class="mobile-table-label">{{'Lunch #2 Start' | translate}}: </span>
									<span  [class]="rowData.isAdminUpdateLunchOut ? 'text-danger' : ''">{{rowData.lunchOutTime | date: 'h:mm a'}}</span>
									<img [src]="rowData?.lunchOutPhoto" *ngIf="rowData.lunchOutPhoto" class="align-self-center profile-picture m-auto cursor-pointer" style="max-height:30px; max-width: 40px; margin-left: 5px !important;" (click)="showFaceImage($event, rowData?.employeeClockingId, 'LUNCH_OUT', rowData.lunchOutTime, rowData?.employee?.fullName, rowData?.lunchOutPhoto, rowData?.lunchOutPhotoStatus, rowData?.id)"/>
									<i *ngIf="rowData.lunchOutPhoto && rowData.lunchOutPhotoStatus === 'Pending'" class="fa-solid fa-exclamation-triangle text-danger ml-2" style="font-size: 12px" pTooltip="{{'Please review and confirm the submitted time' | translate}}" tooltipPosition="right"></i>
								</span>
								<div *ngIf="rowData.editAllowedLunchStart" [ngClass]="{'d-flex w-100': rowData.editAllowedLunchStart==true}" >
									<input type="time" [(ngModel)]="rowData.tempLunchStartTimeDetail" class="editTime" />
									<div class="showUpdateCancelbtn">
										<i class="fa-solid fa-square-check text-success"  (click)="updateLunchStartDetail(rowData); rowData.editAllowedLunchStart = false;"></i>
										<i class="fa-solid fa-square-xmark text-danger"  (click)="cancelEditLunchStartDetail(rowData)"></i>
									</div>
								</div>
								<i *ngIf="!rowData.editAllowedLunchStart && !isEmployee" (click)="allowEditLunchStartDetail(rowData)" class="c-pointer fa-solid fa-pen-to-square ml-auto text-muted mr-2 text" style="cursor: pointer !important"></i>
							</div>
						</span>
						<span *ngSwitchCase="'lunchEnd'">
							<div class="d-flex align-items-center justify-content-between">
								<span *ngIf="!rowData.editAllowedLunchEnd" class="one-liner" pTooltip="{{rowData.isAdminUpdateLunchIn ? ('Change from (' + (rowData.prevLunchInTime ? (rowData.prevLunchInTime | date:'hh:mm a') : 'None') + ') by ' + rowData.lastModifiedBy) : ''}}">
									<span class="mobile-table-label">{{'Lunch #2 End' | translate}}: </span>
									<span [class]="rowData.isAdminUpdateLunchIn ? 'text-danger' : ''">{{rowData.lunchInTime != null?  (rowData.lunchInTime| date: 'h:mm a') : 'Missing' | translate}}</span>
									<img [src]="rowData?.lunchInPhoto" *ngIf="rowData.lunchInPhoto" class="align-self-center profile-picture m-auto cursor-pointer" style="max-height:30px; max-width: 40px; margin-left: 5px !important;" (click)="showFaceImage($event, rowData?.employeeClockingId, 'LUNCH_IN', rowData.lunchInPhoto, rowData?.employee?.fullName, rowData?.lunchInPhoto, rowData?.lunchInPhotoStatus, rowData?.id)"/>
									<i *ngIf="rowData.lunchInPhoto && rowData.lunchInPhotoStatus === 'Pending'" class="fa-solid fa-exclamation-triangle text-danger ml-2" style="font-size: 12px" pTooltip="{{'Please review and confirm the submitted time' | translate}}" tooltipPosition="right"></i>
								</span>
								<div *ngIf="rowData.editAllowedLunchEnd" [ngClass]="{'d-flex w-100': rowData.editAllowedLunchEnd == true}" >
									<input type="time" [(ngModel)]="rowData.tempEndLunchTimeDetail" class="editTime" />
									<div class="showUpdateCancelbtn">
										<i class="fa-solid fa-square-check text-success"  (click)="updateLunchEndDetail(rowData); rowData.editAllowedLunchEnd = false;"></i>
										<i class="fa-solid fa-square-xmark text-danger"  (click)="cancelEditLunchEndDetail(rowData)"></i>
									</div>
								</div>
								<i *ngIf="!rowData.editAllowedLunchEnd && !isEmployee" (click)="allowEditLunchEndDetail(rowData)" class="c-pointer fa-solid fa-pen-to-square ml-auto text-muted mr-2 text" style="cursor: pointer !important"></i>
							</div>
						</span>
						<span *ngSwitchCase="'lunchTot'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Total' | translate}}: </span>
								<span>{{calculateTotalhhmmForSecondLunchNew(rowData)}}
                                    <i [hidden]="checkShowIconTooltip(rowData)" class="cursor-pointer" (mouseenter)="showBreakDurationLess($event)"
                                       (mouseleave)="hideBreakDurationLess()"
                                       class="fa-solid fa-circle-info text-primary ml-1" style="font-size: 12px">
                                    </i>
                                </span>
							</span>
						</span>
					</ng-container>
				</td>
		</ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading">
				<td [attr.colspan]="5"  class="text-center py-5">
					<span class="text-muted my-5">{{'no data' | translate}}</span>
				</td>
			</tr>
		</ng-template>
	</p-table>
	<div class="p-3 w-100 text-center">
		<span style="font-size: 16px">
			<span style="font-weight: 500">{{'Combined Total' | translate}}: </span>{{calCulateTotalhhmm()}}
		</span>
	</div>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="showDialog = false">
			<span class="text-nowrap"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
		</button>
	</p-footer>
</p-dialog>
	
<p-dialog [closable]="true" [(visible)]="showHistoryInfoDialog" [modal]="true" [style]="{'width':'90%', 'max-width':'900px', 'max-height':'85vh'}" id="historyModal">
	<p-header>
		<span><i class="fa-solid fa-clock-rotate-left mr-2"></i>{{'Edit History' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-5 ui-sm-12">
			<label>{{'Field' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-mouse-pointer"></i>
					</button>
				</div>
				<p-dropdown [options]="fieldsName" placeholder="{{'Select One' | translate}}" [(ngModel)]="selectedField">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-7 ui-sm-12">
			<label>{{'Date Range' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-week"></i>
					</button>
				</div>
				<mat-form-field class="w-100">
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate matInput  [(ngModel)]="fromDate" name="Startdate" (focus)="picker.open()" [ngModelOptions]="{standalone: true}">
						<input matEndDate matInput [(ngModel)]="toDate" name="Enddate" (focus)="picker.open()" [ngModelOptions]="{standalone: true}">
					</mat-date-range-input>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
				<div class="input-group-append pl-3">
					<button class="btn btn-white" type="button" (click)="resetHistoryModal()" pTooltip="{{'Reset' | translate}}"><i class="fa-solid fa-xmark text-danger"></i></button>
					<button class="btn btn-white" (click)="loadDataSource()"><span class="text-nowrap"><i *ngIf="!loading" class="fa-solid fa-check text-primary mr-2"></i><i *ngIf="loading" class="fa-solid fa-spinner mr-2 text-primary loadingHourglass"></i>{{'Apply' | translate}}</span></button>
				</div>
			</div>
		</div>
	</div>
	<br>
	<p-panel id="table-ppanel" [toggleable]="true" class="mb-4">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'History' | translate}}:  {{ getSelectedFieldLabel()}} ({{totalRecords2 || 0 | number}})</span>
		</p-header>
		<p-table [value]="historyData" [rows]="10" [paginator]="true" [columns]="displayColumns" [totalRecords]="totalRecords2">
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th *ngFor="let col of displayColumns">
						<span class="d-flex align-items-center justify-content-start">
							<span class="one-liner" pTooltip="{{col?.label | translate}}" tooltipPosition="left">{{col?.label | translate}}</span>
						</span>
					</th>
				</tr>
			</ng-template>

			<ng-template pTemplate="body" let-rowData>
				<tr [pSelectableRow]="rowData">
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Updated' | translate}}: </span>
							<span *ngIf="rowData.updateDate" pTooltip="{{rowData.updateDate | date: 'M-dd-yyyy h:mm:ss a'}}">{{rowData.updateDate | date: 'M-dd-yyyy h:mm:ss a'}}</span>
							<span *ngIf="!rowData.updateDate" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Username' | translate}}: </span>
							<span *ngIf="rowData.user" pTooltip="{{rowData.user}}">{{rowData.user}}</span>
							<span *ngIf="!rowData.user" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Employee' | translate}}: </span>
							<span *ngIf="rowData.employee" pTooltip="{{rowData.employee}}">{{rowData.employee}}</span>
							<span *ngIf="!rowData.employee" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Date' | translate}}: </span>
							<span *ngIf="rowData.Date" pTooltip="{{ rowData.Date | date : 'M/dd/yyyy'}}">{{ rowData.Date | date : 'M/dd/yyyy'}}</span>
							<span *ngIf="!rowData.Date || this.rowData.Date === '' || rowData.Date === null" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Old Time' | translate}}: </span>
							<span *ngIf="rowData.newValue && rowData.oldValue !== 'null' && isValidDate(rowData.oldValue)" pTooltip="{{ rowData.oldValue | date: 'h:mm a' }}">{{ rowData.oldValue | date: 'h:mm a' }}</span>
							<span *ngIf="!rowData.oldValue || rowData.oldValue === 'null' || !isValidDate(rowData.oldValue)" class="text-muted">{{ 'no data' | translate }}</span>
						</span>
					</td>
					<td> 	
						<span class="one-liner">
							<span class="mobile-table-label">{{'New Value' | translate}}: </span>
							<span *ngIf="rowData.newValue && rowData.newValue !== 'null' && isValidDate(rowData.newValue)" pTooltip="{{ rowData.newValue | date: 'h:mm a' }}">{{ rowData.newValue | date: 'h:mm a' }}</span>
							<span *ngIf="!rowData.newValue || !isValidDate(rowData.newValue)" class="text-muted">{{ 'no data' | translate }}</span>
						</span>
					</td>
				</tr>
			</ng-template>

			<ng-template pTemplate="emptymessage" let-columns>
				<tr *ngIf="!loading">
					<td [attr.colspan]="6" class="text-center py-5">
						<div class="text-muted my-5">{{'no data' | translate}}</div>
					</td>
				</tr>
				<tr *ngIf="loading">
					<td [attr.colspan]="6" class="text-center py-5">
						<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
					</td>
				</tr>
			</ng-template>

		</p-table>
	</p-panel>
	<br>
	<p-footer class="d-flex justify-content-end">
		<button (click)="showHistoryInfoDialog = false" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
	</p-footer>
</p-dialog>

<p-overlayPanel #op3 appendTo="body">
	<div class="export">
		<div (click)="getReportFile()" class="py-1" style="cursor: pointer !important">
			<i *ngIf="!showProgressBarOnInitialLoad" class="fa-solid fa-print text-primary mr-2"></i>
			<i *ngIf="showProgressBarOnInitialLoad" class="fa-solid fa-spinner text-muted mr-2 loadingHourglass"></i>
			{{'Export PDF' | translate}}
		</div>
		<div *ngIf="!disableSendEmployee" (click)="sendReportFileToEmployee()" class="py-1 c-pointer" style="cursor: pointer !important"><i class="fa-solid fa-print text-primary mr-2"></i>{{'Send to Employee' | translate}}</div>
		<div (click)="sendReportFileToFinance()" class="py-1 c-pointer" style="cursor: pointer !important"><i class="fa-solid fa-at text-primary mr-2"></i>{{'sendEmail' | translate}}</div>
	</div>
</p-overlayPanel>

<p-overlayPanel #opDisplayColumn appendTo="body">
	<div style=" max-width: 300px;">
		<div class="px-2 py-3">
			<p-checkbox [binary]="true" [(ngModel)]="displayColumnDay" label="{{'Show Day of Week' | translate}}"></p-checkbox>
		</div>
		<div class="px-2">
			<p-checkbox [binary]="true" [(ngModel)]="displayColumnTimeConfirmation" label="{{'Show Time Confirmation' | translate}}"></p-checkbox>
		</div>
	</div>
</p-overlayPanel>

<p-overlayPanel #op99 appendTo="body" styleClass="custom">
	<div style=" max-width: 300px;">
		<div style="background-color: #5b93ce; color: #fff;" class="d-flex align-items-center px-2 py-1">
			<i style="color: #fff;" class="mr-2 fa-solid fa-info-circle"></i>
			{{reasonHeader}}
		</div>
		<div class="px-2 py-3">
			{{reasonNote}}
		</div>
	</div>
		
</p-overlayPanel>

<p-dialog [(visible)]="isPopupEmployeeAbsent" [responsive]="true" [modal]="true" [style]="{'width':'90%', 'max-width':'600px', 'max-height':'85vh'}" id="absentModal">
	<p-header>
		<span><i class="fa-solid fa-head-side-mask mr-2"></i>{{'Absent Employees' | translate}} [{{popupEmployeeAbsentHeader}}] ({{emplAbsent || 0}})</span>
	</p-header>


	<div class="p-3 border-bottom">
		<div class="input-group w-100">
		  <div class="input-group-prepend p-0">
			<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
			  <i class="fa-solid fa-magnifying-glass"></i>
			</button>
		  </div>
		  <input
			type="text"
			size="35"
			pInputText
			[(ngModel)]="textSearchModal"
			(keyup)="onSearchChange($event?.target?.value)"
			placeholder="{{ 'Search by Name' | translate }}"
			style="height: 40px !important"
		  />
		</div>
	  </div>
	  
	  <div *ngFor="let rowData of filteredEmployeeDisplayData; let i = index"
		(click)="gotoEmployeePage(rowData?.employeeId)"
		class="d-flex align-items-center justify-content-start p-2 border-bottom bg-white"
		id="concernsItem" style="cursor:pointer" >
		<i class="fa-solid fa-circle-user text-muted fa-3x mr-3"></i>
		<div style="line-height: 1" class="py-1 text-truncate" style="cursor:pointer">
			<h5 class="m-0 text-nowrap">{{ rowData?.fullName || 'Confirm Weekly Work Hours' }}</h5>
		</div>
		<span class="align-items-center flex-grow-1 text-right">
		  <i class="fa-solid fa-chevron-right text-muted c-pointer" style="cursor: pointer;"></i>
		</span>
	  </div>
	  
	<div *ngIf="filteredEmployeeDisplayData.length === 0" class="text-center py-5 bg-light">
		<i *ngIf="!loading" class="fa-solid fa-circle-exclamation text-muted fa-3x mt-5"></i>
		<div class="text-muted my-5">{{loading ? ('Loading...' | translate) : ('no data' | translate)}}</div>
	</div>



	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="closePopupEmployeeAbsent()">
			<span><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
		</button> 
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="isPopupOutForLunch" [responsive]="true" [modal]="true" [style]="{'width':'90%', 'max-width':'600px', 'max-height':'85vh'}" id="lunchOutModal">
	<p-header>
		<span><i class="fa-solid fa-utensils mr-2"></i>{{'On Break' | translate}} [{{popupOutForLunchHeader}}] ({{emplOutForLunch || 0}})</span>
	</p-header>
	<div class="p-3 border-bottom">
		<div class="input-group w-100">
			<div class="input-group-prepend p-0">
				<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
					<i class="fa-solid fa-magnifying-glass"></i>
				</button>
			</div>
			<input
					type="text"
					size="35"
					pInputText
					[(ngModel)]="textSearchModal"
					(keyup)="onSearchChange($event?.target?.value)"
					placeholder="{{ 'Search by Name' | translate }}"
					style="height: 40px !important"
			/>
		</div>
	</div>

	<div *ngFor="let rowData of filteredEmployeeDisplayData; let i = index"
		 (click)="gotoEmployeePage(rowData?.employeeId)"
		 class="d-flex align-items-center justify-content-start p-2 border-bottom bg-white"
		 id="concernsItem" style="cursor:pointer" >
		<i class="fa-solid fa-circle-user text-muted fa-3x mr-3"></i>
		<div style="line-height: 1" class="py-1 text-truncate" style="cursor:pointer">
			<h5 class="m-0 text-nowrap">{{ rowData?.fullName || 'Confirm Weekly Work Hours' }}</h5>
		</div>
		<span class="align-items-center flex-grow-1 text-right">
		  <i class="fa-solid fa-chevron-right text-muted c-pointer" style="cursor: pointer;"></i>
		</span>
	</div>

	<div *ngIf="filteredEmployeeDisplayData.length === 0" class="text-center py-5 bg-light">
		<i *ngIf="!loading" class="fa-solid fa-circle-exclamation text-muted fa-3x mt-5"></i>
		<div class="text-muted my-5">{{loading ? ('Loading...' | translate) : ('no data' | translate)}}</div>
	</div>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="closePopupOutForLunchs()">
			<span><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
		</button> 
	</p-footer>
</p-dialog>

<p-dialog id="questionModal" [style]="{'width': '90%', 'max-width':'900px', 'max-height':'85vh'}" [(visible)]="showTrackingDialog" class="modal_in" [modal]="true" (onHide)="closeTrackingDialog()" [dismissableMask]="true">
	<p-header>
		<span><i class="fa-solid fa-location-dot mr-2"></i>{{selectedName}}</span>
	</p-header>
	<div class="ui-g row m-0 d-flex">
		<div class="ui-g confirm ui-g-5 ui-sm-12 p-0">
			<p-table #dt [value]="milestone_groups" [scrollable]="true" [rows]="10" [totalRecords]="100" [paginator]="true" [style]="{'height': '100%'}" selectionMode="single" dataKey="groupId">
				<ng-template pTemplate="body" let-rowData let-columns="columns">
					<tr style="height:30px" [pSelectableRow]="rowData" [pContextMenuRow]="rowData" (click)="toggleMarkers(rowData)">
						<td style="position: relative;">
							<p-panel id="table-ppanel" [toggleable]="true" [collapsed]="true">
								<p-header>
									<span *ngIf="rowData.data.length === 1">
										{{rowData.data[0].labelText}} {{rowData.data[0].createdAt | date:'MM/dd/yyyy hh:mm:ss a'}}
									</span>
									<span *ngIf="rowData.data.length > 1">
										{{rowData.data[0].labelText}} {{rowData.data[0].createdAt | date:'MM/dd/yyyy hh:mm:ss a'}} - {{rowData.data[rowData.data.length - 1].labelText}} {{rowData.data[rowData.data.length - 1].createdAt | date:'MM/dd/yyyy hh:mm:ss a'}}
									</span>
									<span *ngIf="rowData.inprogress" class="badge badge-primary ml-4">{{'Pending' | translate}}</span>
								</p-header>
								<p-table [value]="rowData.data" >
									<ng-template pTemplate="body" let-rowData1>
										<tr [pSelectableRow]="rowData1">
											<td>
												{{rowData1.labelText}}
											</td>
											<td>
												{{rowData1.createdAt | date:'MM/dd/yyyy hh:mm:ss a'}}
											</td>
										</tr>
									</ng-template>
								</p-table>
							</p-panel>
						</td>
					</tr>
				</ng-template>

				<ng-template pTemplate="emptymessage" let-columns>
					<tr *ngIf="!loadingTrackData">
						<td [attr.colspan]="2" class="text-center py-5">
							<div class="text-muted my-5" style="
							margin: 184px 0px !important;
						">{{'no data' | translate}}</div>
						</td>
					</tr>
					<tr *ngIf="loadingTrackData">
						<td [attr.colspan]="2" class="text-center py-5">
							<div class="text-muted my-5" style="
							margin: 184px 0px !important;
						"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
						</td>
					</tr>
				</ng-template>

			</p-table>
		</div>
		<div class="ui-g-7 ui-sm-12 p-0" *ngIf="trackingList">
			<agm-map style="width: 98%; height: 520px;" [latitude]="firstLat" [longitude]="firstLong" [zoom]="options.zoom">
				<agm-marker *ngFor="let marker of milestone_markers" [latitude]="marker.lat" [longitude]="marker.lng" [label]="marker.label"></agm-marker>
				<agm-polyline [geodesic]="true" [strokeColor]="'#FF0000'" [strokeOpacity]="1.0" [strokeWeight]="2">		
					<agm-polyline-point *ngFor="let point of routePathMilestone" [latitude]="point.lat" [longitude]="point.lng"></agm-polyline-point>
					<agm-icon-sequence [fixedRotation]="false" [repeat]= "'100px'" [offset]="100" [path]="'FORWARD_OPEN_ARROW'"></agm-icon-sequence>
				</agm-polyline>
				<agm-marker *ngFor="let point of routePathMilestone" [latitude]="point.lat" [longitude]="point.lng" [iconUrl]="'path/to/arrow-icon.png'"></agm-marker>
				<agm-marker *ngFor="let marker of milestone_timestamp_markers" [latitude]="marker.lat" [longitude]="marker.lng" [label]="marker.createdAtLabel" [iconUrl]="redDotIcon"></agm-marker>
			</agm-map>
		</div>
	</div>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="closeTrackingDialog()">
			<span><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
		</button> 
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="showPopupClockedIn" [responsive]="true" [modal]="true" [style]="{'width':'90%', 'max-width':'600px', 'max-height':'85vh'}" id="absentModal">
	<p-header>
		<span><i class="fa-solid fa-house-circle-check mr-2"></i>{{'Working' | translate}}: {{fromDate | date: 'MM/dd/yyyy'}} - {{toDate | date: 'MM/dd/yyyy'}} ({{emplClockedIn || 0}})</span>
	</p-header>
	<div class="p-3 border-bottom">
		<div class="input-group w-100">
			<div class="input-group-prepend p-0">
				<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
					<i class="fa-solid fa-magnifying-glass"></i>
				</button>
			</div>
			<input
					type="text"
					size="35"
					pInputText
					[(ngModel)]="textSearchModal"
					(keyup)="onSearchChange($event?.target?.value)"
					placeholder="{{ 'Search by Name' | translate }}"
					style="height: 40px !important"
			/>
		</div>
	</div>

	<div *ngFor="let rowData of filteredEmployeeDisplayData; let i = index"
		 (click)="gotoEmployeePage(rowData?.employeeId)"
		 class="d-flex align-items-center justify-content-start p-2 border-bottom bg-white"
		 id="concernsItem" style="cursor:pointer" >
		<i class="fa-solid fa-circle-user text-muted fa-3x mr-3"></i>
		<div style="line-height: 1" class="py-1 text-truncate" style="cursor:pointer">
			<h5 class="m-0 text-nowrap">{{ rowData?.fullName || 'Confirm Weekly Work Hours' }}</h5>
		</div>
		<span class="align-items-center flex-grow-1 text-right">
		  <i class="fa-solid fa-chevron-right text-muted c-pointer" style="cursor: pointer;"></i>
		</span>
	</div>

	<div *ngIf="filteredEmployeeDisplayData.length === 0" class="text-center py-5 bg-light">
		<i *ngIf="!loading" class="fa-solid fa-circle-exclamation text-muted fa-3x mt-5"></i>
		<div class="text-muted my-5">{{loading ? ('Loading...' | translate) : ('no data' | translate)}}</div>
	</div>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="closePopupClockedIn()">
			<span><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
		</button> 
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="showPopupEndedWork" [responsive]="true" [modal]="true" [style]="{'width':'90%', 'max-width':'600px', 'max-height':'85vh'}" id="absentModal">
	<p-header>
		<span><i class="fa-solid fa-calendar-check mr-2"></i>{{'Ended Work' | translate}}: {{fromDate | date: 'MM/dd/yyyy'}} - {{toDate | date: 'MM/dd/yyyy'}} ({{emplEndWork || 0}})</span>
	</p-header>
	<div class="p-3 border-bottom">
		<div class="input-group w-100">
			<div class="input-group-prepend p-0">
				<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
					<i class="fa-solid fa-magnifying-glass"></i>
				</button>
			</div>
			<input
					type="text"
					size="35"
					pInputText
					[(ngModel)]="textSearchModal"
					(keyup)="onSearchChange($event?.target?.value)"
					placeholder="{{ 'Search by Name' | translate }}"
					style="height: 40px !important"
			/>
		</div>
	</div>

	<div *ngFor="let rowData of filteredEmployeeDisplayData; let i = index"
		 (click)="gotoEmployeePage(rowData?.employeeId)"
		 class="d-flex align-items-center justify-content-start p-2 border-bottom bg-white"
		 id="concernsItem" style="cursor:pointer" >
		<i class="fa-solid fa-circle-user text-muted fa-3x mr-3"></i>
		<div style="line-height: 1" class="py-1 text-truncate" style="cursor:pointer">
			<h5 class="m-0 text-nowrap">{{ rowData?.fullName || 'Confirm Weekly Work Hours' }}</h5>
		</div>
		<span class="align-items-center flex-grow-1 text-right">
		  <i class="fa-solid fa-chevron-right text-muted c-pointer" style="cursor: pointer;"></i>
		</span>
	</div>

	<div *ngIf="filteredEmployeeDisplayData.length === 0" class="text-center py-5 bg-light">
		<i *ngIf="!loading" class="fa-solid fa-circle-exclamation text-muted fa-3x mt-5"></i>
		<div class="text-muted my-5">{{loading ? ('Loading...' | translate) : ('no data' | translate)}}</div>
	</div>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="closePopupEndedWork()">
			<span><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
		</button> 
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="exportPayrollConfirmModal" class="modal_in" [modal]="true" [style]="{'width': '90%', 'max-width': '500px'}">
    <p-header>
        <span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Export Payroll' | translate}}</span>
    </p-header>
    <div class="ui-g">
		<div class="ui-g-12">
			<span style="font-size: 16px;"> {{'Are you sure you want to export last week\'s payroll data?' | translate}}</span>
		</div>
    </div>
    <p-footer class="d-flex justify-content-between">
        <button type="button" (click)="exportPayrollConfirmModal = false" class="btn btn-danger mx-1"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
        <button type="button" (click)="exportLastWeekData();exportPayrollConfirmModal = false" class="btn btn-primary mx-1"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
    </p-footer>
</p-dialog>

<p-dialog header="{{'ClockINOutIncomplete' | translate}}" [(visible)]="showNoteDetailsDialog" [modal]="true"
	[responsive]="true" styleClass="w-50">
	<div *ngIf="missingCheckOutCount>0" class="ui-g ui-fluid pr-2 pb-2 pt-2 pl-1">
		<p>
			{{missingCheckOutCount}} {{'missingCheckOutCount' | translate}}
		</p>
		<p>
			{{'CurrentlySystem' | translate}}
		</p>

		<p>
			{{'ToUpdateMissing' | translate}} <a style="text-decoration: underline" class="click-here" href="javascript:void(0);"
				(click)="filterMissing()"> {{'here' | translate}}</a>
		</p>
	</div>
	<div class="ui-g-12 ui-md-12 text-center pb-3">
		<button pButton class="w-auto" label="{{'OK' | translate}}" style="margin-right: 5px" (click)="noteDetailsMouseLeave($event)"></button>
		<button pButton class="w-auto" label="{{'button.cancel' | translate}}" (click)="closePopupOutForLunchs()"></button>
	</div>
</p-dialog>

<p-dialog [(visible)]="showPopupNotLunch" [responsive]="true" [modal]="true" [style]="{'width':'90%', 'max-width':'600px', 'max-height':'85vh'}" id="notLunchModal">
	<p-header>
		<span><i class="fa-solid fa-house-circle-check mr-2"></i>{{'Missed Break' | translate}}: {{fromDate | date: 'MM/dd/yyyy'}} - {{toDate | date: 'MM/dd/yyyy'}} ({{totalNotLunch || 0}})</span>
	</p-header>
	<div class="p-3 border-bottom">
		<div class="input-group w-100">
			<div class="input-group-prepend p-0">
				<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
					<i class="fa-solid fa-magnifying-glass"></i>
				</button>
			</div>
			<input
					type="text"
					size="35"
					pInputText
					[(ngModel)]="textSearchModal"
					(keyup)="onSearchChange($event?.target?.value)"
					placeholder="{{ 'Search by Name' | translate }}"
					style="height: 40px !important"
			/>
		</div>
	</div>

	<div *ngFor="let rowData of filteredEmployeeDisplayData; let i = index"
		 (click)="gotoEmployeePage(rowData?.employeeId)"
		 class="d-flex align-items-center justify-content-start p-2 border-bottom bg-white"
		 id="concernsItem" style="cursor:pointer" >
		<i class="fa-solid fa-circle-user text-muted fa-3x mr-3"></i>
		<div style="line-height: 1" class="py-1 text-truncate" style="cursor:pointer">
			<h5 class="m-0 text-nowrap">{{ rowData?.fullName || 'Confirm Weekly Work Hours' }}</h5>
		</div>
		<span class="align-items-center flex-grow-1 text-right">
		  <i class="fa-solid fa-chevron-right text-muted c-pointer" style="cursor: pointer;"></i>
		</span>
	</div>

	<div *ngIf="filteredEmployeeDisplayData.length === 0" class="text-center py-5 bg-light">
		<i *ngIf="!loading" class="fa-solid fa-circle-exclamation text-muted fa-3x mt-5"></i>
		<div class="text-muted my-5">{{loading ? ('Loading...' | translate) : ('no data' | translate)}}</div>
	</div>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="closePopupNotLunch()">
			<span><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
		</button>
	</p-footer>
</p-dialog>

<p-dialog [modal]="true" [(visible)]="showViewFaceImg" [style]="{'width': '90%', 'max-width':'600px'}" [dismissableMask]="true">
	<p-header>
		<span>{{'Punch Confirmation' | translate}} ({{faceData.name}})</span>
	</p-header>
		<div class="d-flex">
			<div style="width: 400px; padding: 5px" [style.width]="(!isEmployee && (!faceData.status || faceData.status === 'Pending')) ? '400px' : '560px'">
				<img [src]="faceData.img" alt="" [style.width]="(!isEmployee && (!faceData.status || faceData.status === 'Pending')) ? '400px' : '560px'">
				<div class="text-center" style="padding: 5px" [style.width]="(!isEmployee && (!faceData.status || faceData.status === 'Pending')) ? '400px' : '560px'">
					<span style="font-size: large;">{{faceData.date | date: 'MM/dd/yyyy HH:mm a'}}</span>
					<span *ngIf="faceData.status && faceData.status !== 'Pending'" class="pl-2" style="font-size: large; font-weight: bold;" [ngClass]="faceData.status == 'Accepted' ? 'text-success' : 'text-danger'">{{faceData.status}}</span>
				</div>
			</div>
			<div *ngIf="!isEmployee && (!faceData.status || faceData.status === 'Pending')" class="d-flex justify-content-center" style="flex-direction: row; align-items: center; flex-wrap: wrap;">
				<div class="d-flex justify-content-center" style="height: 160px; flex-direction: row; align-items: center; flex-wrap: wrap;">
					<button class="btn btn-success" (click)="acceptFaceImg()" [disabled]="confirmingFaceImg">
						<span><i class="fa-solid fa-check mr-2"></i>{{'Accept' | translate}}</span>
					</button>
					<button class="btn btn-danger" (click)="rejectFaceImg()" [disabled]="confirmingFaceImg">
						<span><i class="fa-solid fa-xmark mr-2"></i>{{'Reject' | translate}}</span>
					</button>
				</div>
			</div>
		</div>
</p-dialog>


<p-dialog [(visible)]="timesheetGeneratePopup" [responsive]="true" [modal]="true" [style]="{'width':'90%', 'max-width':'1200px', 'max-height':'85vh'}">
	<p-header>
		<span>{{'Generate Timesheet' | translate}}</span>
	</p-header>
	<ng-container *ngIf="timesheetGeneratePopup">
    <app-timesheet-generate [dataForm]="timesheetDataForm"></app-timesheet-generate>
  </ng-container>
</p-dialog>


<p-overlayPanel #op100 appendTo="body" styleClass="custom">
    <div style=" max-width: 300px;">
        <div style="background-color: #5b93ce; color: #fff;" class="d-flex align-items-center px-2 py-1">
            <i style="color: #fff;" class="mr-2 fa-solid fa-info-circle"></i>
            Short break
        </div>
        <div class="px-2 py-3">
            Break duration less than {{convertTimeFormat(this.payrollSetting?.breakDuration)}} will be considered as working time
        </div>
    </div>
</p-overlayPanel>
