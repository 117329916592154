import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { DropDownsService } from '../../../shared/service/drop-downs/drop-downs.service';
import { Table } from 'primeng/table';
import { LazyLoadEvent, MenuItem, Message, MessageService, SelectItem } from 'primeng/api';
import { Company } from '../../../company/model/company.model';
import { Agency } from '../../../payroll-setting/model/agency.model';
import { RoleLevel } from '../../../layout/component/app-menu/role-level';
import { User } from '../../../shared/model/user/user.model';
import { AgencyService } from '../../../agency/service';
import { CrmCommService } from '../../../crm/service/crm-comm.service';
import { PaymentInvoiceService } from '../../service/payment-invoice.service';
import { PaymentInvoice } from '../../model/payment-invoice.model';
import { PhonePackageService } from '../../../phonesystem/service/phone-package.service';
import { PhonePackage } from '../../../phonesystem/model/phone-package.model';
import { Subject } from 'rxjs/Subject';
import { interval } from 'rxjs/observable/interval';
import { takeUntil } from 'rxjs/operators';
import { FileUtility } from '../../../shared/utility/file.utility';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DATE_FORMATS } from 'app/shared/data/config-common';
import { ClientService } from '../../../crm/service/client.service';
import { ProductService } from '../../../crm/service/product.service';
import { DatePipe } from '@angular/common';
import { TransactionDetailsService } from 'app/transaction/service/transaction-details.service';
import { TransactionDetail } from 'app/transaction/model/transaction-detail.model';
import moment from 'moment/moment';
import {MonitoringDetailsService} from "../../../admin/process-monitor/service/monitoring-details.service";

@Component({
  selector: '',
  templateUrl: './parking-invoice-list.component.html',
  styleUrls: ['./parking-invoice-list.component.scss'],
  providers: [PaymentInvoiceService, DropDownsService,
    PhonePackageService, TransactionDetailsService,
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }]
})
export class ParkingInvoiceListComponent implements OnInit, OnDestroy {
  detailEnable = false;
  contentList: any[] = [];
  contentTransactionList: TransactionDetail[] = [];
  selectedContent: PaymentInvoice = new PaymentInvoice();
  invoiceDetail: PaymentInvoice = new PaymentInvoice();
  details = [];
  totalRecords: Number = 0;
  items: MenuItem[];
  msgs: Message[] = [];

  private destroyed$ = new Subject<void>();
  loading = false;
  tableRows = 10;
  sumFee = 0;
  balance = 0;
  balanceTxt = '';
  sumMinFee = 0;
  sumTax = 0;
  sumTotalAmount = 0;
  sumFeeTxt: string;
  sumMinFeeTxt: string;
  sumTaxTxt: string;
  sumTotalAmountTxt: string;
  sumOutStandingBalanceTxt: string;
  sumOutStandingBalance = 0;
  sumPaidFee = 0;
  sumPaidFeeTxt: string;
  @ViewChild('dt', { static: true }) table: Table;
  userPermission: Boolean;
  rowData: any;
  allCompanyList: any = [];
  allAgencyList: any = [];
  phonePackages: PhonePackage[];
  invoiceNumber: string;
  searchText: string;
  selectedCompany: Company;
  selectedAgency: Agency;
  selectedPhonePackage: PhonePackage;
  selectedStatus: string;
  user: User;
  loggedInRole: number;
  disableCompany = true;
  disableAgency = false;
  fromDate: Date;
  toDate: Date;
  rangeDates: any;
  statusTypes: any[] = [];
  invoiceFilter: any;
  enabledGenerate = false;
  rowsPerPageOptions: any[];
  rowsPerPageItems: SelectItem[];
  loadingSpinner = false;
  page = 0;
  rows = 10;
  sortF: any = '';
  paymentMethod = '';

  cols: any[] = [
    { field: 'invoiceNumber', label: 'Invoice', sortOptions: '', sort: 'text' },
    { field: 'invoiceDate', label: 'Date', sortOptions: '', sort: 'number' },
    { field: 'clientName', label: 'Client', sortOptions: '', sort: 'text' },
    { field: 'dueDate', label: 'Date Due', sortOptions: '', sort: 'text' },
    { field: 'totalHrs', label: 'Hours', sortOptions: '', sort: 'number' },
    { field: 'amountDue', label: 'Amount Due', sortOptions: '', sort: 'number' },
    { field: 'totalAmount', label: 'Paid', sortOptions: '', sort: 'number' },
    { field: 'balance', label: 'Balance', sortOptions: '', sort: 'number' },
    { field: 'paymentMethod', label: 'Method', sortOptions: '', sort: 'text' }
  ];
  sortField: string;
  sortOrder: number;
  textSortOptionsClient = [
    { name: 'Sort A to Z', value: 'ASC', img: 'a-z' },
    { name: 'Sort Z to A', value: 'DESC', img: 'z-a' }
  ];
  numberSortOptionsClient = [
    { name: 'Sort 0 to 9', value: 'ASC', img: 'a-z' },
    { name: 'Sort 9 to 0', value: 'DESC', img: 'z-a' }
  ];
  paymentMethods = [
    {label: 'Cash', value: 'cash'},
    {label: 'Credit/Debit', value: 'credit/Debit'},
    {label: 'Zelle', value: 'zelle'},
    {label: 'Check', value: 'check'},
    {label: 'Other', value: 'other'}
];
  selectedSortOption = '';
  selectedField = '';
  allClientList: any = [];
  clientList: any = [];
  selectedClient;
  productList = [];
  isPlatformAdmin = false;
  disableFields = false;
  contentListTmp: any[] = []
  totalRecordsTmp: Number = 0

  constructor(
    private phonePackageService: PhonePackageService,
    private crmCommService: CrmCommService,
    private contentService: PaymentInvoiceService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private agencyService: AgencyService,
    private dropDownsService: DropDownsService,
    private transactionDetailsService: TransactionDetailsService,
    private messageService: MessageService,
    private clientService: ClientService,
    private datePipe: DatePipe,
    private monitoringDetailsService: MonitoringDetailsService,
    private productService: ProductService) {
    this.user = this.authService.getUserInfo();
    this.monitoringDetailsService.monitorAction(
        'Navigated to Parking Invoice list',
        new Date(),
        {
            navigated_to_parking_invoice_list_by: this.authService.getCurrentLoggedInName(),
        },
        'complete',
        'Parking Invoice',
        0
    )
  }

  ngOnInit() {
    this.fromDate = new Date();
    this.fromDate.setDate(this.fromDate.getDate() - 7);
    this.toDate = new Date();
    this.loggedInRole = this.authService.getRoleLevel();
    if(this.loggedInRole === RoleLevel.ROLE_CLIENT) { this.disableFields = true; }
    this.rowsPerPageOptions = [5, 10, 20];
    this.rowsPerPageItems = [];
    this.rowsPerPageOptions.forEach(rowPerPage => {
      this.rowsPerPageItems.push({ label: String(rowPerPage), value: rowPerPage });
    });
    this.statusTypes = [{ label: 'All', value: '' },
    { label: 'Unpaid', value: 'Unpaid' },
    { label: 'Pending', value: 'Pending' },
    { label: 'Paid', value: 'Paid' }
    ];
    this.items = [
      { label: 'View', icon: 'fa-solid fa-pen-to-square text-primary', command: (event) => this.viewContent(this.selectedContent) },
      { label: 'Delete', icon: 'fa-solid fa-xmark text-danger', command: (event) => this.deleteContent(this.selectedContent) }
    ];
    this.contentList = [];
    this.userPermission = this.authService.adminRole();
    this.searchText = '';
    this.loadAllCompanyList();
    this.loadAllClient();
    this.enabledGenerate = false;
    this.disableAgency = true;
    this.disableCompany = true;

    if (this.loggedInRole === RoleLevel.ROLE_COMPANY_ADMIN || this.loggedInRole === RoleLevel.ROLE_SUB_COMPANY_ADMIN) {
      this.disableCompany = true;
      this.disableAgency = false;
    }

    if (this.loggedInRole === RoleLevel.ROLE_AGENCY_ADMIN || this.loggedInRole === RoleLevel.ROLE_SUB_AGENCY_ADMIN) {
      this.disableAgency = true;
      this.disableCompany = true;
    }
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    if (this.isPlatformAdmin) {
      this.cols = [
		{ field: 'invoiceNumber', label: 'Invoice', sortOptions: '', sort: 'text' },
		{ field: 'invoiceDate', label: 'Date', sortOptions: '', sort: 'number' },
		{ field: 'clientName', label: 'Client', sortOptions: '', sort: 'text' },
		{ field: 'dueDate', label: 'Date Due', sortOptions: '', sort: 'text' },
		{ field: 'totalHrs', label: 'Hours', sortOptions: '', sort: 'number' },
		{ field: 'amountDue', label: 'Amount Due', sortOptions: '', sort: 'number' },
		{ field: 'totalAmount', label: 'Paid', sortOptions: '', sort: 'number' },
		{ field: 'balance', label: 'Balance', sortOptions: '', sort: 'number' },
		{ field: 'paymentMethod', label: 'Method', sortOptions: '', sort: 'text' },
		{ field: 'companyName', label: 'companyLabel', sortOptions: '', sort: 'text' }
      ];
    }
    if (this.loggedInRole === RoleLevel.ROLE_ADMIN || this.loggedInRole === RoleLevel.ROLE_SUB_ADMIN) {
      this.disableAgency = false;
      this.disableCompany = false;
    }
    this.invoiceFilter = {
      fromDate: null,
      toDate: null,
      page: 0,
      size: 10,
      search: '',
      agencyId: 0,
      companyId: 0,
      packageId: 0,
      status: '',
      invoiceNumber: '',
      sort: null,
      isParkingInvoice: true
    };
    this.detailEnable = false;
    // this.subscribeMessage();
    this.search();
  }
  getAllAddedProductsByClientId(clientId: any) {
    const options: any = {
      page: 0, size: 99999, sort: 'id,desc',
    };
    options.clientId = this.selectedClient;
    options.crmClientIds = [this.selectedClient];
    this.productService.getAllProductsByClient(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.productList = resObj.data.content;
      }
    });
  }
  loadAllClient() {
    const options: any = { size: 9999, page: 0, moduleName: 'billing' };
    if (this.selectedCompany) {
      options.companyId = this.selectedCompany;
    } else {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    if(this.disableFields) {
      options.userId = this.authService.getCurrentLoggedInId();
      this.selectedClient = options.userId;
    }
    this.dropDownsService.getAllClientList(options).subscribe((res) => {
      const resObj: any = res;
      this.allClientList = [];
      this.clientList = [];
      if (resObj.status === 'SUCCESS') {
        this.allClientList = resObj.data.map(c => ({ value: c.key, label: c.value }));
        this.clientList = resObj.data;
      }
    });
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
  // private subscribeMessage() {
  //   this.contentService.message$
  //     .pipe(
  //       takeUntil(this.destroyed$)
  //     )
  //     .subscribe((message: Message) => {
  //       this.messages.push(message);
  //     });

  //   interval(10000)
  //     .pipe(takeUntil(this.destroyed$))
  //     .subscribe(() => {
  //       if (this.messages.length) {
  //         this.messages.shift();
  //       }
  //     });
  // }
  loadPackageBasedOnCompanyId() {
    this.phonePackageService.getAllList({ companyId: this.selectedCompany }).subscribe((res) => {
      const resObj: any = res;
      console.log(resObj);
      if (resObj.status === 'SUCCESS') {
        this.phonePackages = resObj.data;
      }
    });
    this.detailEnable = false;
  }
  changeStatus(event) {
    this.selectedStatus = event.status;
  }
  viewContent(c: PaymentInvoice) {
    this.router.navigate(['app/phonesystem', 'view', c.id]);
  }

  deleteContent(c: PaymentInvoice) {
    this.contentService.delete(c.id).subscribe((_response) => {
      this.search();
    }, (error) => {
      console.log(error);
    });
  }

  getDetail(detail: PaymentInvoice) {
    this.invoiceDetail = new PaymentInvoice();
    this.invoiceDetail = detail;
    this.details = [];
    this.details = [{ name: 'Package Fee', value: detail.fee },
    { name: 'Minutes Fee', value: detail.minuteFee },
    { name: 'Taxes', value: detail.taxes }];
    this.detailEnable = true;
  }

  editContent(c: PaymentInvoice) {
    this.router.navigate(['app/phonesystem', 'edit', c.id]);
    // this.router.navigate(['app/crm/freelancer', 'edit', c.id]);
  }

  paginate(event?) {
    const first = (event?.first || 1);
    const rows = (event?.rows || 10);
    this.sumFee = 0;
    this.sumMinFee = 0;
    this.sumTax = 0;
    this.sumTotalAmount = 0;
    this.sumOutStandingBalance = 0;
    this.sumPaidFee = 0;
    const newList = this.contentList.slice(first , first + rows);
    newList.forEach(invoice => {
      const paidFee = invoice.paidFee ? invoice.paidFee : 0;
      invoice.paidFeeTxt = paidFee.toFixed(2);
      invoice.feeTxt = invoice.fee.toFixed(2);
      invoice.totalAmountTxt = invoice.totalAmount.toFixed(2);
      this.sumFee = this.sumFee + invoice.fee;
      this.sumTotalAmount = this.sumTotalAmount + invoice.totalAmount;
      this.balance = invoice.fee > paidFee ? invoice.fee - paidFee : paidFee - invoice.fee;
      invoice.balanceTxt = this.balance.toFixed(2);
      this.sumOutStandingBalance +=  this.balance;
      this.sumPaidFee += paidFee;
    });
    this.sumFeeTxt = this.sumFee.toFixed(2);
    this.sumPaidFeeTxt = this.sumPaidFee.toFixed(2);
    this.sumOutStandingBalanceTxt =  this.sumOutStandingBalance.toFixed(2);
    this.sumTotalAmountTxt = this.sumTotalAmount.toFixed(2);
  }

  public loadContentsLazy(event?: any) {
    // this.loading = false;
    this.detailEnable = false;
    if (event.originalEvent !== 'MouseEvent') {
      this.page = (event.first) / event.rows;

      this.sortField = this.selectedField;
      this.rows = event.rows;
    }
    // this.sortOrder = event && event.sortOrder ? event.sortOrder : 1;
    const order = this.selectedSortOption;
    // this.sortOrder === 1 ? 'ASC' : 'DESC';
    this.invoiceFilter.page = 0;
    this.invoiceFilter.size = 9999;
    // if (this.sortField && order) {
    //   this.invoiceFilter.sort = this.sortField + ',' + order;
    // }

    this.loadPage(this.invoiceFilter);
  }
  cancelDialog() {
    this.detailEnable = false;
  }
  resetFilters() {
    this.invoiceNumber = null;
    this.selectedStatus = null;
    if (this.isPlatformAdmin) { this.selectedCompany = null; }
    this.fromDate = null;
    this.toDate = null;
    this.searchText = '';
    this.paymentMethod = '';
    this.selectedClient = undefined;
    this.table.reset();
    this.search();
  }
  search(event?: any) {
    this.table.first = 0;
    const options = <any>{
      page: 0,
      size: 9999
    };
    this.invoiceFilter.page = 0;
    this.invoiceFilter.size = this.table.rows;
    // console.log(event)
    if (event === this.searchText) {
      console.log('Search function run');
      options.search = this.searchText;
    }
    // if (event && event.target.value) {
    //   options.search = event.target.value;
    // }

    if (this.paymentMethod) {
      options.paymentMethod = this.paymentMethod;
    }

    if (this.invoiceNumber) {
      options.invoiceNumber = this.invoiceNumber;
    }
    if (this.selectedStatus) {
      options.status = this.selectedStatus;
    }

    if (this.selectedCompany != null) {
      options.companyId = this.selectedCompany;
    } else {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    if (this.user.companyId) { options.companyId = this.user.companyId; }

    if (this.selectedClient != null) {
      options.clientId = this.selectedClient;
    }
    options.isParkingInvoice = true;
    if (this.fromDate) { options.fromDate = this.fromDate; }
    if (this.toDate) { options.toDate = this.toDate; }

    options.sortField = event?.sortField || 'invoiceDate';
    options.sortOrder = event?.sortOrder || 'DESC';

    this.detailEnable = false;

    this.loadPage(options);

  }

  generateInvoice() {
    this.loadingSpinner = true;

    const startDate = new Date(this.fromDate);
    startDate.setDate(startDate.getDate() - 30);
    const endDate = new Date(this.toDate);
    endDate.setDate(endDate.getDate() - 30);
    this.contentService.generateGenerate({ fromDate: startDate, toDate: endDate }).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.loading = false;
        setTimeout(() => {
          this.loadingSpinner = false;
        }, 1000);
        this.contentService.notifyMessage(
          { severity: 'info', summary: 'Generate', detail: 'Generate Invoice successfully!' }
        );
        this.loadPage(this.invoiceFilter);
      }
    });
  }

  searchBySelectedDate(date) {
    if (date.startDate) {
      this.fromDate = date.startDate;
    }
    if (date.endDate) {
      this.toDate = date.endDate;
    }
  }

  loadPage(options) {
    this.loading = true;
    options.oldInvoiceOnly = false;
    options.isParkingInvoice = true;
    if (this.paymentMethod) {
      options.paymentMethod = this.paymentMethod;
    }
    this.contentService.invoiceSummarySearch(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.contentList = resObj.data.content;
        this.totalRecords = resObj.data.totalElements;
        this.contentList = this.contentList.sort((a, b) => new Date(b.invoiceDate).getTime() - new Date(a.invoiceDate).getTime());
        this.sumFee = 0;
        this.sumMinFee = 0;
        this.sumTax = 0;
        this.sumTotalAmount = 0;
        this.sumOutStandingBalance = 0;
        this.sumPaidFee = 0;
        this.contentList.forEach(invoice => {
          const company = this.allCompanyList.find(com => com.value === invoice.companyId);
          const client = this.allClientList.find(com => com.value === invoice.clientId);
          if (company) {
            invoice.companyName = company.label;
          }
          if (client) {
            invoice.clientName = client.label;
          }
          let totalHrs = 0;
          let amountDue = 0;
          invoice.invoiceDetails.forEach(detail => {
            totalHrs += (detail.parkingBilling?.totalHrs || 0);
            amountDue += (detail.parkingBilling?.totalAmount || 0);
          });
          invoice.totalHrs = totalHrs;
          invoice.amountDue = amountDue;
          const paidFee = invoice.paidFee ? invoice.paidFee : 0;
          invoice.paidFeeTxt = paidFee.toFixed(2);
          invoice.feeTxt = invoice.fee.toFixed(2);
          invoice.totalAmountTxt = invoice.totalAmount.toFixed(2);
          this.sumFee = this.sumFee + invoice.fee;
          this.sumTotalAmount = this.sumTotalAmount + invoice.totalAmount;
          this.balance = invoice.fee > paidFee ? invoice.fee - paidFee : paidFee - invoice.fee;
          invoice.balance = this.balance;
          invoice.balanceTxt = this.balance.toFixed(2);
          this.sumOutStandingBalance +=  this.balance;
          this.sumPaidFee += paidFee;
          if (invoice.paymentMethod === 'cash') {
            invoice.paymentMethod = 'Cash';
          }

          if (invoice.paymentMethod === 'credit/Debit') {
            invoice.paymentMethod = 'Credit/Debit';
          }

          if (invoice.paymentMethod === 'zelle') {
            // invoice.paymentMethod = 'Zelle';
          }

          if (invoice.paymentMethod === 'check') {
            invoice.paymentMethod = 'Check';
          }

          if (invoice.paymentMethod === 'other') {
            invoice.paymentMethod = 'Other';
          }

        });

        this.contentListTmp = this.contentList.map(i => Object.assign({}, i))
        this.totalRecordsTmp = this.totalRecords

        const option = {
          first: this.invoiceFilter.page,
          rows: this.invoiceFilter.size
        };
        this.paginate(option);

        this.loading = false;
      }
    });
  }

  exportReportPdf() {
      const data: any = {
        productPrices: this.productList,
        clientId: this.selectedClient
      };
      if (this.selectedCompany != null) {
        data.companyId = this.selectedCompany;
      }
      if (!(this.authService.isSuper() || this.authService.isSubSuper())) {
        data.companyId = this.authService.getCurrentCompanyId();
      }
      this.contentService.exportReportPdf(data).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
        }
      });
  }

  exportPdf(invoice) {
    if (invoice.pdfLink) {
      window.open(invoice.pdfLink, '_blank');
    } else {
      this.contentService.exportPdf(invoice.id).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
        }
      });
    }
  }

  displayType(freelancer: PaymentInvoice) {
  }



  loadAllCompanyList() {
    const pafc = this;
    this.dropDownsService.getAllCompanyList().subscribe((res) => {
      const resObj: any = res;
      this.allCompanyList = [];
      if (resObj.status === 'SUCCESS') {
        // this.allCompanyList = resObj.data;
        resObj.data.forEach(rs => {
          this.allCompanyList.push({value: rs.key, label: rs.value});
        });
        if (this.user.companyId) {
          const company = this.allCompanyList.find(company => company.value === this.user.companyId);
          this.selectedCompany = company.value;
        }
      }
    });
  }

  sortDataSelected(selectedSortOption, event: LazyLoadEvent) {
    this.sortF = this.selectedField;
    const options = {
      field: this.selectedField,
      order: selectedSortOption === 'ASC' ? 1 : -1,
      mode: 'single',
      data: this.contentList
    };
    this.customSort(options);
     this.loadContentsLazy(event);
  }

  customSort(event) {
    if (this.sortF.length > 0) {
      if (event.data) {
        event.data.sort((data1, data2) => {
          const value1 = data1[event.field];
          const value2 = data2[event.field];
          let result = null;

          if (value1 == null && value2 != null) {
            result = -1;
          } else if (value1 != null && value2 == null) {
            result = 1;
 } else if (value1 == null && value2 == null) {
            result = 0;
 } else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
 } else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
 }

          return (event.order * result);
        });
      }
      this.sortF = '';
    }
  }
  setSortOption(field, selectedSortOption?) {
    this.cols.forEach(item => {
      if (field === item.field) {
        item.sortOptions = selectedSortOption;
        this.selectedSortOption = selectedSortOption;
      }
    });
  }

  exportTablePdf() {
    if (this.contentList && this.contentList.length) {
        let serviceFee = 0;
        let serviceFeePaid = 0;

        const data: any = {
            contentList: this.contentList.map(value => {
                return {
                  invoiceNumber: value.invoiceNumber,
                  invoiceDate: this.datePipe.transform(value.invoiceDate, 'MM/dd/yyyy'),
                  dueDate: this.datePipe.transform(value.dueDate, 'MM/dd/yyyy'),
                  clientName: value.clientName,
                  companyName: value.companyName,
                  totalAmount: value.totalAmount,
                  feeTxt: value.feeTxt,
                  paidFeeTxt: value.paidFeeTxt,
                  balance: '$' + value.feeTxt,
                };
            })
        };

        this.contentList.forEach(item => {
          serviceFee += parseFloat(item.feeTxt);
          serviceFeePaid += parseFloat(item.paidFeeTxt);
        });
        data.totalServiceFee = '$' + serviceFee.toFixed(2);
        data.totalServiceFeePaid = '$' + serviceFeePaid.toFixed(2);
        data.totalOutstandingBalance = '$' + serviceFee.toFixed(2);
        if (!this.isPlatformAdmin) {
            data.companyId = this.authService.getCurrentCompanyId();
            data.includeCompany = false;
        } else {
          data.companyId = this.selectedCompany;
          data.includeCompany = true;
        }
        data.username = this.authService.getCurrentUsername();
        data.reportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy EEE hh:mm a');
        this.contentService.exportTablePdf(data).subscribe(res => {
          if (res.data) {
            const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
            this.monitoringDetailsService.monitorAction(
                'Exported Parking Invoice list',
                new Date(),
                {
                    exported_parking_invoice_list_by: this.authService.getCurrentLoggedInName(),
                },
                'complete',
                'Parking Invoice',
                0
            )
          } else {
            this.messageService.add({ severity: 'error', summary: 'Problem exporting', detail: 'Problem exporting client pdf list' });
          }
        });
    }
  }

  customFilter(e) {
    this.table?.reset()
    const value = e?.target?.value;
    if (value) {
      this.contentList = this.contentListTmp.filter(t => {
        const invoiceDate = this.formatDate(t.invoiceDate);
        const dueDate = this.formatDate(t.dueDate);
        return ((t.invoiceNumber && t.invoiceNumber.toLowerCase().search(value.toLowerCase()) > -1) || (t.clientName && t.clientName.toLowerCase().search(value.toLowerCase()) > -1)
            || (t.totalHrs && String(t.totalHrs).toLowerCase().search(value.toLowerCase()) > -1) || (dueDate && dueDate.toLowerCase().search(value.toLowerCase()) > -1)
            || (invoiceDate && invoiceDate.toLowerCase().search(value.toLowerCase()) > -1) || (t.amountDue && String(t.amountDue).toLowerCase().search(value.toLowerCase()) > -1)
            || (t.balanceTxt && t.balanceTxt.toLowerCase().search(value.toLowerCase()) > -1) || (t.totalAmountTxt && t.totalAmountTxt.toLowerCase().search(value.toLowerCase()) > -1)
            || (t.paymentMethod && t.paymentMethod.toLowerCase().search(value.toLowerCase()) > -1) || (t.companyName && t.companyName.toLowerCase().search(value.toLowerCase()) > -1));
      });
      this.totalRecords = (this.contentList || []).length

      this.paginate()
    } else {
      this.contentList = this.contentListTmp.map(i => Object.assign({}, i));
      this.totalRecords = this.totalRecordsTmp

      this.paginate()
    }
  }

  formatDate(date) {
    return date ? moment(date).format('MM/DD/YYYY hh:mm a') : '';
  }

  formatMoney(amount) {
    return amount ? amount.toFixed(2) : '';
  }
}
