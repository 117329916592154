import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { FormValidatorService } from '../../../shared/service/form-validator/form-validator.service';
import { PayrollSettingService } from '../../service/payroll-setting.service';
import { PayrollHolidayService } from '../../service/payroll-holiday.service';
import { DepartmentService } from '../../../department/service/department.service';
import { PositionService } from '../../../position/service/position.service';
import { Agency } from '../../model/agency.model';

@Component({
    selector: 'app-payroll-setting',
    templateUrl: './payroll-setting.component.html',
    styleUrls: ['./payroll-setting.component.css'],
    providers: [AuthService, FormValidatorService, PayrollSettingService, PayrollHolidayService,
        DepartmentService, PositionService]
})
export class PayrollSettingComponent implements OnInit {
    agency: Agency;
    agencyId: Number;
    constructor(
        private route: ActivatedRoute) {
        this.route.params.subscribe(params => {
            this.agencyId = +params.id;
        });
    }

    ngOnInit() {
    }



}
