export const MENU_ID = {
  ANONYMOUS: 0,
  DASHBOARD: 1,
  PLATFORM_ADMIN: 2,
  COMPANY_ADMIN: 3,
  ADMINISTRATORS: 4,
  COMPANY_PROFILE: 5,
  DEPARTMENT: 7,
  POSITION: 8,
  COMPANY_CONTENT: 9,
  EMPLOYEES: 21,
  PUNCH_IN_OUT: 22,
  PUNCH_HISTORY: 23,
  EMPLOYEE_PAYMENT: 24,
  EMPLOYEE_TIME_OFF: 25,
  FOLLOW_UP_BILLS: 26,
  CRM: 30,
  MULTIMEDIA: 36,
  GUESTS: 37,
  MEMBERSHIP: 38,
  CONTRIBUTION: 39,
  GROUPS: 41,
  // PAYROLL_SETTING: 42,
  EMAIL_ACTIVITY: 43,
  SMS_VALIDATION: 44,
  EMPLOYEE_TIME_CORRECTION: 45,
  EVENT_SCHEDULE: 46,
  TIME_SHEET: 47,
  WORK_ORDER: 48,
  PROCESS_MONITOR: 49,
  DESK_ACTIVITY: 50,
  CLIENT_TRANSFERRED: 51,
  EVV_REGISTERED: 52,
  OVERTIME_REQUEST: 55,
  IND_CONTRACTORS: 56,
  OPERRTEL: 57,
  PHONE_PACKAGE: 58,
  PROCESS_PAYMENT: 59,
  EXCURSION: 60,
  PHONE_PAYMENT: 61,
  OVERTIME_DASHBOARD: 62,
  PHONE_INVOICE: 63,
  CHAT_BOX: 64,
  INVOICE: 65,
  CRM_PRODUCT: 66,
  BILLING: 67,
  PHONE_SYSTEM_CONTRACT: 68,
  PERFORMANCE_REPORTS: 69,
  COLORS: 70,
  EXPENSE: 71,
  PAYMENT_DETAILS_ADD_EMPLOYEE: 72,
  SUMMARY_VIEW: 73,
  EMPLOYEE_OT: 74,
  OPERRTEL_REPORTS: 75,
  COMPLIANCE_REPORTS: 76,
  OT_PREVENTION: 77,
  TASK_MANAGEMENT: 78,
  CONTRACTOR_CONTRACT: 79,
  TRANSFER_RESHUFFLE: 80,
  HIDE_PAY_RATE_OT_RATE: 81,
  TRANSACTION_TYPES: 82,
  // TRANSACTION_ADD: 84,
  API_USAGE_REPORT: 85,
  API_SETTING: 86,
  ACCOUNTING: 94,
  TRANSACTIONS: 95,
  RECEIVED_PAYMENTS: 96,
  REPORT_TEMPLATES: 97,
  PREFERENCES: 98,
  ADD_EMPLOYEE: 99,
  PROJECTS: 100,
  CREDIT_DEBIT_ACCOUNTS: 101,
  CONTRACTS_LICENSES: 102,
  PAYMENT_TRACKING: 103,
  LOANS: 104,
  EMPLOYEE_CALENDAR: 106,
  EMPLOYEE_EVALUATION: 107,
  EMPLOYEE_MENU_INACTIVITY: 108,
  IMPORT_CLIENTS: 109,
  INVOICE_TEMPLATE: 110,
  ADD_CLIENT_BILLING_DETAILS: 112,
  DISPATCH_BILLING: 113,
  MONTHLY_FEE: 114,
  TAXS: 115,
  SCREEN_RECORDER: 125,
  VIDEO_CONFERENCES: 126,
  JOIN_CONFERENCE: 127,
  PAYSTUB: 500,
  LAYOUT_PALETTE: 701,
  THEMES: 702,
  CONTRACTOR_INVOICE: 703,
  PAYMENT_METHOD: 704,
  SSN_VIEW: 705,
  ADD_ABSENCE: 707,
  ASSIGNED_TICKET: 710,
  COMPLETED_TICKETS: 711,
  MAIL_DOCUMENT: 997,
  DEADLINE_REMINDER: 998,
  MAIL: 999,
  CLIENT_DASHBOARD: 1000,
  TRAINING_SCHEDULE: 1001,
  TRAINING_TOPIC: 1002,
  CRM_EVENTS: 1003,
  CRM_EVENTS_TYPE: 1004,
  CRM_CALENDAR: 1005,
  MANAGE_PIPELINE: 1006,
  SALES_PIPELINE: 1008,
  CRM_LABEL_FLAGS: 1009,
  CONFERENCE_ROOM: 1011,
  BILLING_CLIENT_LOG: 1012,
  OLD_INVOICE: 1013,
  FOLLOW_UPS: 1014,
  ADMIN_BOARD_ACCESS: 1015,
  CLIENT_CREDITS: 1016,
  MEMBERS: 1017,
  TRACKING: 1018,
  TOTAL_JOB: 1019,
  GENERAL_REPORT: 1020,
  DETAIL_REPORT: 1021,
  BILLING_CORRECTION: 1022,
  FIDELIS_REPORT: 1023,
  BILLING_UPLOAD_HISTORY: 1024,
  LOG_TOTAL_JOB: 1025,
  BILLING_LOG_DETAIL_REPORT: 1026,
  PHONE_SYSTEM_SEND_SMS: 1027,
  EMPLOYEE_JOB_REPORT: 1028,
  TICKET_SUMMARY: 1029,
  INTERN: 1030,
  VOLUNTEER: 1031,
  MONITORING: 1032,
  SUSPICIOUS_ACTIVITIES: 1033,
  BILLING_CLIENT_STATUS: 1034,
  EMPLOYEE_TIME_HISTORY: 1035,
  SMS_USAGE: 1036,
  PHONE_SYSTEM_TOP_UP: 1037,
  NOTEBOOK: 1038,
  EMAIL_SUBSCRIPTION: 1039,
  SHARE_HOLDER: 1055,
  NOTIFICATION: 1056,
  PWD_MANAGEMENT: 1059,
  CARDS: 1080,
  SUSPENSION_RECORDS: 1081,
  VIEW_DETAILS_REPORT: 1100,
  CAMPAIGN: 1101,
  MY_CONTACT_LIST: 1111,
  VIEW_PAYMENT_METHOD: 1120,
  MAP_CLIENT_BASE: 1121,
  DISPATCH_TOTAL_JOB: 1122,
  // LEAD_LIST: 1132,
  CRM_LEAD: 1133,
  CAMPAIGN_MARKETING: 1134,
  CAMPAIGN_OPPORTUNITY: 1135,
  ACTIVITY_LOG: 1136,
  CLIENT_USER_ACCOUNT: 1137,
  EMPLOYEE_ON_DUTY: 1138,
  EMAIL_TEMPLATE: 1139,
  RECOLLECTION_DETAILS: 1140,
  INSURANCE_POLICIES: 1151,
  VEHICLES: 1152,
  LINKED_ACCOUNTS: 1153,
  CONTRACTOR_NEW_PAYROLL: 1154,
  CONTRACTOR_PAYROLL: 1155,
  SOFTPHONE: 1156,
  CLIENT_CONTRACT: 1157,
  PHONE_DIRECTORY: 1158,
  SERVICE_REVENUE: 1159,
  AR_REPORT: 1160,
  GROSS_INCOME: 1161,
  SPREAD_SHEET: 1162,
  VOTER: 1163,
  WALKER: 1164,
  ZONE_MANAGEMENT: 1165,
  CAMPAGN_CANDIDATE: 1166,
  AGING_DETAILS: 1167,
  SCHEDULE_BATCH: 1168,
  ELECTORAL_CAMPAIGN: 1169,
  FLYER: 1170,
  PUNCH_TRACKING: 1171,
  CUSTOMIZE_QUESTIONS: 1172,
  FUNDRAISERS: 1173,
  DOCUMENT_SETTING: 1176,
  OPERRSIGN: 1177,
  OPERRTEL_POOL: 1178,
  AGING_SUMMARY: 1179,
  EMPLOYEE_SCHEDULER: 1180,
  PACKAGE_SERVICE_PLAN: 1181,
  BULK_SMS: 1182,
  PHONE_SYSTEM_SCHEDULE: 1183,
  AUTO_DIAL: 1184,
  MY_MESSAGE: 1185,
  BILLING_LOCATION: 1186,
  LOCATION_MANAGEMENT: 1187,
  EXPORT_MONITOR: 1188,
  PLAN_PREVIEW: 1189,
  PLANS: 1190,
  MAINTENANCE: 1191,
  EMPLOYEE_DOCUMENT_HISTORY: 1192,
  PERFORMANCE: 1193,
  INVENTORY_ITEMS: 1200,
  EQUIPMENT_CHECK_OUT: 1201,
  INVENTORY: 1202,
  PURCHASE_REQUESTS: 1203,
  INVENTORY_REPORT: 1204,
  MANAGE_ATTRIBUTES: 1205,
  INCIDENT_REPORT: 1206,
  CURFEW_SCHEDULE: 1207,
  SERVICE_MANAGEMENT: 1208,
  REMINDER_ALERT: 1209,
  RESIDENTS: 1272,
  SIGN_INS_OUTS_LIST: 1273,
  RESIDENT_FLAG: 1274,
  ALERT_HISTORY: 1275,
  SIGN_INS_OUTS: 1276,
  GLOBAL_DASHBOARD: 1277,
  WAIVED_SINGLE_MISSING_SIN_IN_OUT: 1278,
  WAIVED_IN_BULK_MISSING_SIN_IN_OUT: 1279,
  HOURS_48_OVER_AUTHORIZATION: 1280,
  NEGLIGENCE: 1281,
  WALLET: 1666,
  WALLET_TRANSACTION: 1667,
  CLIENT_SELF_PAYMENT: 1668,
  SERVICE_RATE: 1669,
  QUOTE: 1670,
  LAND_PROJECT: 1671,
  STATEMENT_BALANCE: 1672,
  PAYROLL_JOURNAL_REPORT: 1673,
  PAYROLL_JOURNAL_PASSWORD: 1674,
  PAYSTUB_PIN_NOT_REQUIRED: 1675,
  INVOICE_DISCOUNT: 1676,
  SERVICE_PROVIDER: 1677,
  INVOICE_VERIFICATION: 1678,
  INVOICE_SUMMARY: 1679,
  COMPLIANCE_DASHBOARD: 1680,
  COMPLIANCE_DOCUMENT: 1681,
  STUFF_COMPLIANCE: 1682,
  EMPLOYEE_DOCUMENT: 1683,
  STUFF_VOUCHER: 1684,
  TIME_RECOLLECTION: 1685,
  WAGE_SCHEDULE: 1686,
  COMPANY_SETTING: 1687,
  PAYROLL_SETTING: 1688,
  OTHER_SETTING: 1689,
  PUNCTUALITY: 1690,
  EMPLOYEE_VOUCHER: 1691,
  ZONE_PREFERENCES: 1692,
  TIME_RECOLLECTION_UPLOAD_HISTORY: 1693,
  TIP_CREDIT: 1694,
  CRM_DOCUMENTS: 1777,
  WORKING_TIME_CALCULATOR: 1778,
  PAYSTUB_PDF_PROCESSER: 1779,
  FILE_EXAMINATION: 2003,
  GENERAL_INVOICES: 2004,
  INVOICE_TRACKING: 2005,
  PUNCH_BILLING: 2006,
  VISIT_HISTORY: 2341,
  OPERRTEL_GROUP: 2342,
  DRIP_CAMPAIGN: 2343,
  CRM_CONTACTS: 2344,
  VOTER_IMPORT_HISTORY: 3158,
  SMS_MMS_BILLING: 4422,
  LAW_FIRM: 4461,
  MONITORING_COMPANY_DOCUMENTS: 4871,
  ONBOARDING_REQUIREMENTS: 5038,
  MEMBER: 5039,

  DISCOUNTS_WAIVERS: 6000,
  TRANSACTION_GROUP: 6001,
  TAX_TYPES: 6002,
  INVOICE_TYPE: 6003,
  REVENUE_PAYMENTS: 6004,
  MEMBER_PROFILE: 6005,
  MEMBER_SCHEDULE: 6006,
  MONITOR_DETAILS: 6007,
  LOGIN_ACTIVITY: 6008,
  COMPANY_TRACKING: 6009,
  EMPLOYEE_SURVEY: 6010,
  WORK_TIME_CONFIRMATION: 6011,
  CALL_NOTES: 6013,
  CALL_RECORDS: 6014,
  CRM_OPERRTEL_CONTACT_LISTS: 6015,
  TIMER_RECORDS: 6016,
  VRM_DASHBOARD: 6017,
  PARKING_BILLING: 6019,
  PARKING_INVOICES: 6020,
  CLIENTS_BASES: 6021,
  DISPATCH_API_USAGE: 6022,
  PAYMENT_AVAILABILITY: 6023,
  PAYMENTS: 6024,
  CLIENT_PACKAGE: 6025,
  SHORTEN_URL: 6026,
  OPERRTEL_CONTACT_LISTS: 6027,
  COMPANY_HOLIDAY: 6028,
  COMPANY_DOCUMENT_SETTING: 6029,
  COMPANY_PAYMENT: 6030,
  COMPANY_PAYMENT_METHOD: 6031,
  OPERATIONAL_POLICY: 6032,
  COMPANY_CONTRACT: 6033,
  OPERR_DRIVE: 6038,
  SURVEY_DASHBOARD: 6039,
  CRM_DASHBOARD: 6040,
  CEO_DASHBOARD: 6041,
  OPERRTEL_DASHBOARD: 6042,
  EMPLOYEE_DASHBOARD: 6043,
  MANAGER_CALENDAR: 6044,
  EMPLOYEE_NOTE: 6045,
};
