<div [ngSwitch]="menuTab">
    <ng-container *ngSwitchCase="'wallet'">
        <app-custom-tab-menu [customTabMenu]="customTabMenuWallet"></app-custom-tab-menu>
    </ng-container>
    <ng-container *ngSwitchCase="'invoice'">
        <app-custom-tab-menu [customTabMenu]="customTabMenuInvoice"></app-custom-tab-menu>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <app-custom-tab-menu [customTabMenu]="customTabMenuPayment"></app-custom-tab-menu>
    </ng-container>
</div>