import {Component, OnInit, ViewChild} from '@angular/core';
import {TransactionTypeService} from 'app/transaction/service/transaction-type.service';
import {LazyLoadEvent, Table, MessageService} from 'primeng';
import * as _moment from 'moment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DATE_FORMATS} from 'app/shared/data/config-common';
import {AuthService} from 'app/shared/service/auth/auth.service';
import {DropDownsService} from 'app/shared/service/drop-downs/drop-downs.service';
import {CompanyService} from 'app/company/service/company.service';
import * as _ from 'lodash';
import {RevenueService} from '../../services/revenue.service';
import {FileUtility} from 'app/shared/utility/file.utility';
import {DatePipe, formatNumber} from '@angular/common';
import {of} from 'rxjs';
import moment from 'moment/moment';
import { ExportMonitorService } from 'app/admin/process-monitor/service/export-monitor.service';


@Component({
  selector: 'app-service-revenue',
  templateUrl: './service-revenue.component.html',
  styleUrls: ['./service-revenue.component.scss'],
  providers: [TransactionTypeService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS}]
})
export class ServiceRevenueComponent implements OnInit {
  allTransactionTypes: any[];
  fromDate: Date;
  toDate: Date;
  cols = [
    {field: '#', label: '#'},
    {field: 'invoiceDate', label: 'Invoiced', sortOptions: '', sort: 'number'},
    {field: 'transactionType', label: 'Type', sortOptions: '', sort: 'text'},
    {field: 'clientName', label: 'Client', sortOptions: '', sort: 'text'},
    {field: 'account', label: 'Account', sortOptions: '', sort: 'text'},
    {field: 'debitAmount', label: 'Debit', sortOptions: '', sort: 'number'},
    {field: 'creditAmount', label: 'Credit', sortOptions: '', sort: 'number'},
    {field: 'memo', label: 'Memo', sortOptions: '', sort: 'text'},
    {field: 'confirmedByUsr', label: 'Confirm', sortOptions: '', sort: 'text'},
    {field: 'approvedByUsr', label: 'Approve', sortOptions: '', sort: 'text'},
  ];

  textSortOptions = [
    { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
    { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
  ];
  numberSortOptions = [
    { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  dateSortOptions = [
    { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  confirmSortOptions = [
    { name: 'Confirmed First', value: 'ASC', img: 'circle-check text-success' },
    { name: 'First', value: 'DESC', img: 'circle-check text-primary' }
  ];
  approveSortOptions = [
    { name: 'Approved First', value: 'ASC', img: 'circle-check text-success' },
    { name: 'First', value: 'DESC', img: 'circle-check text-primary' }
  ];

  insuranceList: any;
  totalRecords: number;
  selectedSortOption = '';
  selectedField = '';
  loading: boolean;
  @ViewChild('dt', {static: true}) table: Table;
  isPlatformAdmin: any;
  selectedClient: any;
  allClientList: any[];
  allCompanyList: any;
  selectedCompany: any;
  totalInvoice: number;
  totalServiceFee: number;
  totalPayment: number;

  accounts = [
    {label: 'Invoice A/R', value: 'Invoice A/R'},
    {label: 'Service Fee', value: 'Service Fee'},
    {label: 'Payment', value: 'Payment'}
  ];
  selectedAccount: any;
  page: number;
  size: number;
  filterText: any;
  insuranceListTmp: any = [];

  constructor(private companyService: CompanyService,
              private authService: AuthService,
              private dropDownsService: DropDownsService,
              private datePipe: DatePipe,
              private exportMonitorService: ExportMonitorService,
              private messageService: MessageService,
              private revenueService: RevenueService) {
  }

  ngOnInit(): void {
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    this.loadAllCompanies();
    this.loadAllClient();
    this.search();
  }

  loadAllClient() {
    const options: any = {};
    if (this.selectedCompany) {
      options.companyId = this.selectedCompany.key;
    }
    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompanyId();
    }

    this.dropDownsService.getAllClientList(options).subscribe((res) => {
      const resObj: any = res;
      this.allClientList = [];
      if (resObj.status === 'SUCCESS') {
        this.allClientList = resObj.data;
      }
    });
  }

  loadAllCompanies() {
    this.companyService.getCompaniesDropdown({}).subscribe(res => {
      const resObj: any = res;
      console.log(resObj);
      if (resObj.status === 'SUCCESS') {
        this.allCompanyList = _.sortBy(resObj.data, 'value');
      }
    });
  }

  changeCompany() {
    this.loadAllClient();
  }

  loadRevenueLazy(event: LazyLoadEvent) {
    this.loading = true;
    const page = (event.first) / event.rows;
    const option = {
      page: page, size: event.rows
    };
    this.loadPage(option);
  }

  search(event?: any) {
    this.table.first = 0;
    this.loading = true;
    this.size = event && event.rows ? event.rows : (this.size ? this.size : 99999);
    this.page = event && typeof event.first !== 'undefined' && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);

    if (event && (event.first / event.rows) === 0) {
      this.page = 0;
    }
    let options = {
      size: this.size,
      page: this.page,
      companyId: null
    };
    this.loadPage(options);

  }

  loadPage(options: any) {
    this.totalServiceFee = 0;
    this.totalPayment = 0;
    this.totalInvoice = 0;
    if (this.selectedCompany) {
      options.companyId = this.selectedCompany.key;
    }
    if (!this.isPlatformAdmin) {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    if (this.fromDate && this.toDate) {
      options.fromDate = _moment(this.fromDate);
      options.toDate = _moment(this.toDate);
    }
    if (this.selectedClient) {
      options.clientId = this.selectedClient.key;
    }
    if (this.selectedAccount) {
      options.account = this.selectedAccount;
    }
    options.sortField = 'invoiceDate';
    options.sortOrder = 'DESC';
    this.insuranceListTmp = [];
    this.revenueService.getRevenueList(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.loading = false;
        this.insuranceList = resObj.data.content;
        this.totalRecords = resObj.data.totalElements;
        this.insuranceList.forEach(value => {
          if (value.account === 'Service Fee') {
            this.totalServiceFee += Number(value.creditAmount);
          }
          if (value.account === 'Payment') {
            this.totalPayment += Number(value.debitAmount);
          }
          this.totalInvoice = this.totalServiceFee - this.totalPayment;
          value.clientName = this.getClient(value?.clientId);

          this.insuranceListTmp.push(value);
        });
      }
    });
  }

  reset() {
    this.selectedCompany = null;
    this.selectedClient = null;
    this.selectedAccount = null;
    this.filterText = null;
    this.toDate = null;
    this.fromDate = null;
    this.table.reset();
    this.search();
  }

  sortingClick(selectedSortOption) {
    const sortForStatus = selectedSortOption;
    if (this.selectedField === 'status') {
      selectedSortOption = (selectedSortOption === 'ASC') ? 'DESC' : (selectedSortOption === 'DESC' ? 'ASC' : selectedSortOption);
    }
    selectedSortOption = selectedSortOption === 'ASC' ? 1 : -1;
    this.sort(this.selectedField, selectedSortOption);
  }

  sort(fieldName: string, order: number) {
    this.insuranceList.sort((row1, row2) => {
      let val1 = row1[fieldName];
      let val2 = row2[fieldName];
      if (val1?.toLowerCase) {
        val1 = val1.toLowerCase();
      }
      if (val2?.toLowerCase) {
        val2 = val2.toLowerCase();
      }
      if (val1 === val2) {
        return 0;
      }
      let result = -1;
      if (val1 > val2) {
        result = 1;
      }
      if (order < 0) {
        result = -result;
      }
      return result;
    });
  }

  setSortOption(field, selectedSortOption?) {
    this.cols.forEach((item) => {
      if (field === item.field) {
        item.sort = selectedSortOption;
        this.selectedSortOption = selectedSortOption;
      }
    });
  }

  getClient(id) {
    const client = this.allClientList.find(cl => cl.key === id);
    if (client) {
      return client.value;
    }
  }

  approveByUsr(rowData: any, mode: any) {
    if (mode === 1) {
      rowData.confirmedByUsr = this.authService.getCurrentUsername();
    }
    if (mode === 2) {
      rowData.approvedByUsr = this.authService.getCurrentUsername();
    }

    this.revenueService.update(rowData).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        console.log('update success');
      }
    });
  }

  updateMemo(rowData: any) {
    this.revenueService.update(rowData).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        console.log('update success');
      }
    });
  }

  exportPdf() {
    if (this.insuranceList && this.insuranceList.length) {
      const data: any = {
        contentList: this.insuranceList.map(value => {
          return {
            invoiceDate: this.datePipe.transform(value.invoiceDate, 'MM/dd/yyyy'),
            transactionType: value.transactionType,
            clientId: this.getClient(value.clientId),
            account: value.account,
            debitAmount: formatNumber(value.debitAmount, 'en-US', '1.2-2'),
            creditAmount: formatNumber(value.creditAmount, 'en-US', '1.2-2')
          };
        })
      };
      if (!this.isPlatformAdmin) {
        data.companyId = this.authService.getCurrentCompany();
      } else {
        if (this.selectedCompany) {
          data.companyId = this.selectedCompany.key;
        }
      }
      data.username = this.authService.getCurrentUsername();
      data.reportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy EEE hh:mm');
      this.revenueService.exportPdf(data).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
            let data = <any>{
                featureName: 'Service Revenue',
                fileName: blobUrl,
                fileSize: blob.size,
                action: 'Export',
                createdByUsr: this.authService.getCurrentUsername(),
                companyId: this.authService.getCurrentCompanyId()
            };
            this.exportMonitorService.create(data).subscribe(()=>{
            });
        } else {
          this.messageService.add({severity: 'error', summary: 'Problem exporting', detail: 'Problem exporting client pdf list'});
        }
      });
    }
  }

  customFilter(e: any) {
    const value = e?.target?.value;
    if (value) {
      this.insuranceList = this.insuranceListTmp.filter(t => {
        const invoiceDate = this.formatDate(t.invoiceDate);
        const debitAmount = this.formatMoney(t.debitAmount);
        const creditAmount = this.formatMoney(t.creditAmount);
        return ((t.transactionType && t.transactionType.toLowerCase().search(value.toLowerCase()) > -1) || (t.clientName && t.clientName.toLowerCase().search(value.toLowerCase()) > -1)
            || (t.account && t.account.toLowerCase().search(value.toLowerCase()) > -1) || (t.memo && t.memo.toLowerCase().search(value.toLowerCase()) > -1)
            || (invoiceDate && invoiceDate.toLowerCase().search(value.toLowerCase()) > -1) || (debitAmount && debitAmount.toLowerCase().search(value.toLowerCase()) > -1)
            || (creditAmount && creditAmount.toLowerCase().search(value.toLowerCase()) > -1) || (t.confirmedByUsr && t.confirmedByUsr.toLowerCase().search(value.toLowerCase()) > -1)
            || (t.approvedByUsr && t.approvedByUsr.toLowerCase().search(value.toLowerCase()) > -1));
      });
    } else {
      this.insuranceList = this.insuranceListTmp.map(i => Object.assign({}, i));
    }
  }

  formatDate(date) {
    return date ? moment(date).format('MM/DD/YYYY hh:mm a') : '';
  }

  formatMoney(amount) {
    return amount ? amount.toFixed(2) : '';
  }
}
