import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from 'app/shared/model/constants';
import { AbstractService } from 'app/shared/service/abstract.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AccountModel } from '../model/account.model';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends AbstractService<AccountModel> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.OPERATOR + '/billing-account', '');
  }

  getAllAccountList(options: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}search`, options).pipe(map(resp => resp));
  }

  getCreatedByWithCompanyId(companyId: any): Observable<any> {
    return this._http.get<any>(`${this.baseUrlV2}createdByList?companyId=${companyId}`).pipe(map(resp => resp));
  }

  getCreatedBy(): Observable<any> {
    return this._http.get<any>(`${this.baseUrlV2}createdByList`).pipe(map(resp => resp));
  }

  getAccoundDropdown(options: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}dropdown`, options).pipe(map(resp => resp));
  }

  exportPdf(data?: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}exportPdf`, data).pipe(map(resp => resp));
  }

  getBankList(params: any): Observable<any> {
    return this._http.get<any>(`${this.baseUrlV2}get-bank-list`, { params: params }).pipe(map(resp => resp));
  }

  createBillingAccount(entity: any): Observable<any> {
    // return this._http.post<any>('http://localhost:8007/api/v2/operation/billing-account', entity).pipe(map(res => res));
    return this._http.post<any>(this.baseUrlV2, entity).pipe(map(res => res));
  }

  updateBillingAccount(entity: any, id: Number): Observable<any> {
    // return this._http.put<any>(`http://localhost:8007/api/v2/operation/billing-account`, entity).pipe(map(res => res));
    return this._http.put<any>(`${this.baseUrlV2}`, entity).pipe(map(res => res));
  }
}
