import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BreadcrumbService } from 'app/layout/service/breadcrumb.service';
import { MenuItem } from 'primeng';
import { CustomTabMenu, CustomTabMenuItem } from 'app/shared/model/custom-tab-menu';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-custom-tab-menu',
  templateUrl: './custom-tab-menu.component.html',
  styleUrls: ['./custom-tab-menu.component.scss']
})
export class CustomTabMenuComponent implements OnInit {

  @Input() customTabMenu: CustomTabMenu;

  sortedCustomTabMenu: CustomTabMenuItem[] = [];

  tabMenuItems: MenuItem[] = [];
  activeItem: MenuItem;
  breadCrumbs: MenuItem[] = [];

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private router: Router,
    private breadcrumbService: BreadcrumbService
  ) {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      if (event instanceof NavigationEnd && this.customTabMenu) {
        this.onRouteChanged(event.url);
      }
    });
  }

  ngOnInit(): void {
    this.onRouteChanged(this.router.url);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onRouteChanged(url) {
    console.log('url', url);

    this.initBreadCrumbs();
    this.initTabMenu();
    if (this.customTabMenu && this.customTabMenu.items) {
      const menuItem = this.sortedCustomTabMenu.find(item => {
        if (Array.isArray(item.routerLinkUrl)) {
          return item.routerLinkUrl.some(link => url.indexOf(link) >= 0);
        }
        return url.indexOf(item.routerLinkUrl) >= 0;
      });
      if (menuItem) {
        if (menuItem.breadcrumb) {
          menuItem.breadcrumb.forEach(label => this.breadCrumbs.push({ label: label }));
        }
        if (!this.tabMenuItems.find(t => t.id === menuItem.id)) {
          this.tabMenuItems.push({
            ...menuItem,
            label: menuItem.label,
            id: menuItem.id,
            routerLink: menuItem.routerLink,
            visible: menuItem.visible
          });
        }
        this.getActiveMenuItem(menuItem.id);
      }
    }
    setTimeout(() => {
      this.breadcrumbService.setItems(this.breadCrumbs);
    }, 200);
  }

  initBreadCrumbs() {
    this.breadCrumbs = [];
    if (this.customTabMenu && this.customTabMenu.breadcrumb) {
      if (Array.isArray(this.customTabMenu.breadcrumb)) {
        this.customTabMenu.breadcrumb.forEach(item => this.breadCrumbs.push({ label: item }));
      } else {
        this.breadCrumbs = [{ label: this.customTabMenu.breadcrumb }];
      }
    }
  }

  initTabMenu() {
    this.tabMenuItems = [];
    if (this.customTabMenu && this.customTabMenu.items) {
      this.customTabMenu.items.forEach(item => {
        if (item.hideAsDefault) {
          return;
        }
        this.tabMenuItems.push({
          ...item,
          label: item.label,
          id: item.id,
          routerLink: item.routerLink,
          visible: item.visible
        });
      });
      this.sortedCustomTabMenu = this.customTabMenu.items.slice().sort((a, b) => b.routerLinkUrl.length - a.routerLinkUrl.length);
    }
  }

  getActiveMenuItem(id: string) {
    this.activeItem = this.tabMenuItems.find(emp => emp.id === id);
  }

  onItemClick(event: Event, item: CustomTabMenuItem) {
    if (item.onClick) {
      event.preventDefault();
      event.stopPropagation();
      item.onClick();
    }
  }
}
