import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { MENU_ID } from 'app/shared/data/menu-id';
import { CustomTabMenu } from 'app/shared/model/custom-tab-menu';

@Component({
    selector: 'app-employee-clocking-layout',
    templateUrl: './employee-clocking-layout.component.html',
    styleUrls: ['./employee-clocking-layout.component.scss']
})
export class EmployeeClockingLayoutComponent implements OnInit {

    customTabMenu: CustomTabMenu = {
        breadcrumb: 'Employee',
        items: [
            {
                label: 'Employee Punch',
                id: 'employeePunch',
                routerLink: ['punch'],
                routerLinkUrl: '/app/employee-clocking/punch',
                visible: this.authService.hasViewPermission(MENU_ID.PUNCH_IN_OUT),
                breadcrumb: ['Employee Punch']
            },
            {
                label: 'Punch History',
                id: 'punchHistory',
                routerLink: ['punch-history'],
                routerLinkUrl: '/app/employee-clocking/punch-history',
                visible: this.authService.hasViewPermission(MENU_ID.PUNCH_HISTORY),
                breadcrumb: ['Employee Punch', 'Punch History']
            },
            {
                label: 'Punch Tracking',
                id: 'punchTracking',
                routerLink: ['punch-tracking'],
                routerLinkUrl: '/app/employee-clocking/punch-tracking',
                visible: this.authService.hasViewPermission(MENU_ID.PUNCH_TRACKING),
                breadcrumb: ['Employee Punch', 'Punch Tracking']
            },
            {
                label: 'Summary View',
                id: 'summaryView',
                routerLink: ['summary-view'],
                routerLinkUrl: '/app/employee-clocking/summary-view',
                visible: this.authService.hasViewPermission(MENU_ID.SUMMARY_VIEW),
                breadcrumb: ['Summary View']
            },
            {
                label: 'Employee OT',
                id: 'employeeOT',
                routerLink: ['employee-ot'],
                routerLinkUrl: '/app/employee-clocking/employee-ot',
                visible: this.authService.hasViewPermission(MENU_ID.EMPLOYEE_OT),
                breadcrumb: ['Employee OT']
            },
            {
                label: 'Time Corrections',
                id: 'timeCorrection',
                routerLink: ['protest-hour'],
                routerLinkUrl: '/app/employee-clocking/protest-hour',
                visible: this.authService.hasViewPermission(MENU_ID.EMPLOYEE_TIME_CORRECTION),
                breadcrumb: ['Time Corrections']
            },
            {
                label: 'Work Time Confirmation',
                id: 'workConfirmation',
                routerLink: ['work-confirmation'],
                routerLinkUrl: '/app/employee-clocking/work-confirmation',
                visible: this.authService.hasViewPermission(MENU_ID.WORK_TIME_CONFIRMATION),
                breadcrumb: ['Work Time Confirmation']
            },
            {
                label: 'Job Reports',
                id: 'jobReport',
                routerLink: ['job-report'],
                routerLinkUrl: '/app/employee-clocking/job-report',
                visible: this.authService.hasViewPermission(MENU_ID.EMPLOYEE_JOB_REPORT),
                breadcrumb: ['Job Reports']
            },
            {
                label: 'Add Job Report',
                id: 'jobReport',
                routerLink: ['job-report', 'add'],
                routerLinkUrl: '/app/employee-clocking/job-report/add',
                visible: this.authService.hasEditPermission(MENU_ID.EMPLOYEE_JOB_REPORT),
                breadcrumb: ['Add Job Report'],
                hideAsDefault: true
            },
            {
                label: 'Edit Job Report',
                id: 'jobReport',
                routerLink: ['job-report', 'edit'],
                routerLinkUrl: '/app/employee-clocking/job-report/edit',
                visible: this.authService.hasEditPermission(MENU_ID.EMPLOYEE_JOB_REPORT),
                breadcrumb: ['Edit Job Report'],
                hideAsDefault: true
            },
            {
                label: 'Job Report History',
                id: 'jobReport',
                routerLink: ['job-report', 'history'],
                routerLinkUrl: '/app/employee-clocking/job-report/history',
                visible: this.authService.hasViewPermission(MENU_ID.EMPLOYEE_JOB_REPORT),
                breadcrumb: ['Job Report History'],
                hideAsDefault: true
            },
            
        ]
    }
    constructor(private authService: AuthService) {
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }
}
