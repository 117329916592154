import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { MENU_ID } from 'app/shared/data/menu-id';
import { CustomTabMenu } from 'app/shared/model/custom-tab-menu';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-payment-layout',
    templateUrl: './payment-layout.component.html',
    styleUrls: ['./payment-layout.component.scss']
})
export class PaymentLayoutComponent implements OnInit {

    customTabMenuPayment: CustomTabMenu = {
        breadcrumb: 'Payments',
        items: [
            {
                label: 'Process Payments',
                id: 'payment_process',
                routerLink: ['detail'],
                routerLinkUrl: '/app/payments/detail',
                visible: this.authService.hasViewPermission(MENU_ID.PROCESS_PAYMENT),
                breadcrumb: ['Process Payments']
            },
            {
                label: 'Settings',
                id: 'payment_method',
                routerLink: ['method'],
                routerLinkUrl: '/app/payments/method',
                visible: this.authService.hasViewPermission(MENU_ID.PROCESS_PAYMENT),
                breadcrumb: ['Settings']
            },
            {
                label: 'Payment Availability',
                id: 'payment_availability',
                routerLink: ['payment-availability'],
                routerLinkUrl: '/app/payments/payment-availability',
                visible: this.authService.hasViewPermission(MENU_ID.PAYMENT_AVAILABILITY),
                breadcrumb: ['Payment Availability']
            },
            {
                label: 'Payments',
                id: 'payment_list',
                routerLink: ['detail/list'],
                routerLinkUrl: '/app/payments/detail/list',
                visible: this.authService.hasViewPermission([MENU_ID.PAYMENTS, MENU_ID.PHONE_PAYMENT]),
                breadcrumb: ['Payments']
            },
            {
                label: 'Payment Methods',
                id: 'view_payment_method',
                routerLink: ['view-payment-method', 'list'],
                routerLinkUrl: '/app/payments/view-payment-method/list',
                visible: this.authService.hasViewPermission(MENU_ID.VIEW_PAYMENT_METHOD),
                breadcrumb: ['Payment Methods']
            },
            {
                label: 'Follow-Ups',
                id: 'follow_up',
                routerLink: ['follow-up-note'],
                routerLinkUrl: '/app/payments/follow-up-note',
                visible: this.authService.hasViewPermission(MENU_ID.FOLLOW_UPS),
                breadcrumb: ['Follow-Ups']
            },
            {
                label: 'Client Self-Pay',
                id: 'client_self_payment',
                routerLink: ['client-self-payment'],
                routerLinkUrl: '/app/payments/client-self-payment',
                visible: this.authService.hasViewPermission(MENU_ID.CLIENT_SELF_PAYMENT),
                breadcrumb: ['Client Self-Pay']
            },
            {
                label: 'Credits',
                id: 'credits',
                routerLink: ['available-credit'],
                routerLinkUrl: '/app/payments/available-credit',
                visible: this.authService.hasViewPermission(MENU_ID.CLIENT_CREDITS),
                breadcrumb: ['Credits']
            }
        ]
    }

    customTabMenuInvoice: CustomTabMenu = {
        breadcrumb: 'Invoices',
        items: [
            {
                label: 'Invoices',
                id: 'invoice_search',
                routerLink: ['invoice'],
                routerLinkUrl: '/app/payments/invoice',
                visible: this.authService.hasViewPermission(MENU_ID.INVOICE),
                breadcrumb: ['Invoices']
            },
            {
                label: 'Old Invoices',
                id: 'old_invoice_search',
                routerLink: ['old-invoice'],
                routerLinkUrl: '/app/payments/old-invoice',
                visible: this.authService.hasViewPermission(MENU_ID.OLD_INVOICE),
                breadcrumb: ['Old Invoices']
            },
            {
                label: 'Invoice Verification',
                id: 'invoice_verification',
                routerLink: ['invoice-verification'],
                routerLinkUrl: '/app/payments/invoice-verification',
                visible: this.authService.hasViewPermission(MENU_ID.INVOICE_VERIFICATION),
                breadcrumb: ['Invoice Verification']
            },
            {
                label: 'Invoice Summary',
                id: 'invoice_summary',
                routerLink: ['invoice-summary'],
                routerLinkUrl: '/app/payments/invoice-summary',
                visible: this.authService.hasViewPermission(MENU_ID.INVOICE_SUMMARY),
                breadcrumb: ['Invoice Summary']
            }
        ]
    }

    customTabMenuWallet: CustomTabMenu = {
        breadcrumb: 'Wallet',
        items: [
            {
                label: 'Wallet',
                id: 'wallet',
                routerLink: ['wallet'],
                routerLinkUrl: '/app/payments/wallet',
                visible: this.authService.hasViewPermission(MENU_ID.WALLET),
                breadcrumb: ['Wallet']
            },
            {
                label: 'Wallet Transactions',
                id: 'wallet_transaction',
                routerLink: ['wallet-transaction'],
                routerLinkUrl: '/app/payments/wallet-transaction',
                visible: this.authService.hasViewPermission(MENU_ID.WALLET_TRANSACTION),
                breadcrumb: ['Wallet Transactions']
            }
        ]
    }

    menuTab;

    routerEventsSubscribe;

    constructor(private authService: AuthService, private router: Router) {
        this.routerEventsSubscribe = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (this.customTabMenuWallet.items.find(item => {
                    if (Array.isArray(item.routerLinkUrl)) {
                      return item.routerLinkUrl.some(link => event.url.indexOf(link) >= 0);
                    }
                    return event.url.indexOf(item.routerLinkUrl) >= 0;
                  })) {
                    this.menuTab = 'wallet';
                } else if (this.customTabMenuInvoice.items.find(item => {
                    if (Array.isArray(item.routerLinkUrl)) {
                      return item.routerLinkUrl.some(link => event.url.indexOf(link) >= 0);
                    }
                    return event.url.indexOf(item.routerLinkUrl) >= 0;
                  })) {
                    this.menuTab = 'invoice';
                } else {
                    this.menuTab = 'payment';
                }
            }
        });
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        if (this.routerEventsSubscribe) {
            this.routerEventsSubscribe.unsubscribe();
        }
    }
}
