<div class="row py-2">
    <div class="col-md-3" *ngIf="imageUrl.type=='IMAGE_URL'">
        <img style="width: 100px;cursor: pointer;max-height: 125px;" [src]="imageUrl.content" (click)="viewImage()"/>
    </div>
    <div class="col-md-3" *ngIf="imageUrl.type=='DOCUMENT'">
        <div *ngIf="imageUrl.content.includes('.pdf') " class="filetype">
            <a href="{{imageUrl.content}}" target="_blank">
                Pdf
            </a>
        </div>
        <div *ngIf="imageUrl.content.includes('.csv') || imageUrl.content.includes('.xlsx') || imageUrl.content.includes('.xlsm') || imageUrl.content.includes('.xltx') || imageUrl.content.includes('.xltm') || imageUrl.content.includes('.xlsb') || imageUrl.content.includes('.xlam')" class="filetype">
            <a href="{{imageUrl.content}}" target="_blank">
                Excel
            </a>
        </div>
        <div *ngIf="imageUrl.content.includes('.txt') " class="filetype">
            <a href="{{imageUrl.content}}" target="_blank">
                Text file
            </a>
        </div>
        <div *ngIf="imageUrl.content.includes('.html') " class="filetype">
            <a href="{{imageUrl.content}}" target="_blank">
                Html
            </a>
        </div>
        <div *ngIf="imageUrl.content.includes('.zip') " class="filetype">
            <a href="{{imageUrl.content}}" target="_blank">
                Zip
            </a>
        </div>
        <div *ngIf="imageUrl.content.includes('.docx') || imageUrl.content.includes('.docm') || imageUrl.content.includes('.dotx') || imageUrl.content.includes('.dotm')" class="filetype">
            <a href="{{imageUrl.content}}" target="_blank">
                Doc
            </a>
        </div>
        <div *ngIf="imageUrl.content.includes('.pptx') || imageUrl.content.includes('.pptm') || imageUrl.content.includes('.potx') || imageUrl.content.includes('.potm') || imageUrl.content.includes('.ppam') || imageUrl.content.includes('.ppsx') || imageUrl.content.includes('.ppsm') || imageUrl.content.includes('.sldx') || imageUrl.content.includes('.sldm') || imageUrl.content.includes('.thmx')" class="filetype">
            <a href="{{imageUrl.content}}" target="_blank">
                PowerPoint
            </a>
        </div>
    </div>
    <div class="col-md-9">
        <h6 *ngIf="imageUrl.type === 'IMAGE_URL' && !editLabel" style="word-break: break-word"
            (click)="editImageLabelClick()">{{imageUrl.label ? imageUrl.label : getFileName(imageUrl.content)}}</h6>
        <h6 *ngIf="imageUrl.type === 'DOCUMENT' && !editLabel" style="word-break: break-word"
            (click)="editImageLabelClick()">{{imageUrl.label ? imageUrl.label : getFileName(imageUrl.content)}}</h6>
        <input *ngIf="editLabel" type="text" pInputText [ngModelOptions] ="{standalone: true}" (keydown.enter)="editImageLabel(); editLabel=false" (blur)="editImageLabel(); editLabel=false"  [(ngModel)]="imageUrl.label" id="editAttachInputDirectly">
        <u [ngClass]="{'disabledIcon': isSuperAdmin}" (click)="makeCover()" *ngIf="taskBackground != imageUrl.content && imageUrl.type != 'DOCUMENT'">Make Cover</u>
        <u [ngClass]="{'disabledIcon': isSuperAdmin}" (click)="removeCover()" *ngIf="taskBackground == imageUrl.content && imageUrl.type != 'DOCUMENT'">Remove Cover</u>
        <u [ngClass]="{'disabledIcon': isSuperAdmin}" (click)="download(imageUrl)" *ngIf="imageUrl.content">Download</u>
        <u [ngClass]="{'disabledIcon': isSuperAdmin}" (click)="comment()" >Comment</u>
        <u [ngClass]="{'disabledIcon': isSuperAdmin}" (click)="op1.show($event); setFocus('editAttachInput')">Edit</u>
        <u [ngClass]="{'disabledIcon': isSuperAdmin}" (click)="delete()" >Delete</u>
    </div>

    <p-overlayPanel #op1 appendTo="body" (onHide)="editImageLabel()">
       <div class="p-1 text-center">
           Edit Attachement
       </div>
       <div>
           <input type="text" pInputText [ngModelOptions] ="{standalone: true}" (keydown.enter)="editImageLabel();op1.toggle($event)"  [(ngModel)]="imageUrl.label" id="editAttachInput">
           <button style="margin-top:10px" pButton label="update" (click)="op1.hide($event)"
            ></button>
       </div>
    </p-overlayPanel>

    <!-- <div class="modal-image-preview" *ngIf="previewImage" id="close-image">
        <div class="preview-content">
            <img [src]="previewImage" />
            <div class="close" (click)="closeModalImage()">X</div>
            <div class="action"> -->
                <!-- <span [ngClass]="{'disabledIcon': isSuperAdmin}" (click)="delete()">Delete</span> -->
            <!-- </div>
        </div>
    </div> -->
    
    <p-dialog class="show-image show-image-custom" [showHeader]="false" [(visible)]="previewImage" [modal]="true" [dismissableMask]="true">
        <div><img [src]="previewImage"/></div>
        <div class="close" (click)="closeModalImage()">X</div>
    </p-dialog>
</div>
