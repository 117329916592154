import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../authenticate/auth.guard';
import { CompanyLayoutComponent } from './component/company-layout/company-layout.component';
import { CompanyListComponent } from './component/company-list/company-list.component';
import { CompanyAddComponent } from './component/company-add/company-add.component';
import { CompanyViewComponent } from './component/company-view/company-view.component';
import { CompanyUpdateComponent } from './component/company-update/company-update.component';
import { CompanyContractComponent } from './component/company-contract/company-contract.component';
import { CompanySettingViewComponent } from './component/company-setting-view/company-setting-view.component';
import { CompanySettingEditComponent } from './component/company-setting-edit/company-setting-edit.component';
import { PayrollSettingComponent } from '../payroll-setting/component/payroll-setting/payroll-setting.component';
import { CompanyHolidayManageComponent } from './component/company-holiday-manage/company-holiday-manage.component';
import { PayrollSettingViewComponent } from '../payroll-setting/component/payroll-setting-view/payroll-setting-view.component';
import { CompanyOtherViewComponent } from './component/company-other-view/company-other-view.component';
import { DocumentSettingsComponent } from './component/document-settings/document-settings.component';
import { CompanyPaymentDetailsComponent } from './component/comany-payment-details/company-payment-details.component';
import { CompanyPaymentMethodComponent } from './component/company-payment-method/company-payment-method.component';
import { NgModule } from '@angular/core';
import { PayrollHolidayAddComponent } from 'app/payroll-setting/component/payroll-holiday-add/payroll-holiday-add.component';
import { PayrollHolidayEditComponent } from 'app/payroll-setting/component/payroll-holiday-edit/payroll-holiday-edit.component';
import { OperationalPolicyComponent } from "./component/operational-policy/operational-policy.component";
import { PermissionGuard } from 'app/authenticate/permission.guard';
import { MENU_ID } from 'app/shared/data/menu-id';
import { RoleLevel } from 'app/layout/component/app-menu/role-level';

export const routes: Routes = [
    {
        path: '',
        component: CompanyLayoutComponent,
        children: [
            { path: '', redirectTo: 'list', pathMatch: 'full' },
            {
                path: 'list',
                component: CompanyListComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.COMPANY_PROFILE], canEdit: [] }
            },
            {
                path: 'add',
                component: CompanyAddComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [], canEdit: [MENU_ID.COMPANY_PROFILE], role: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN] }
            },
            {
                path: 'edit/:id',
                component: CompanyUpdateComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [], canEdit: [MENU_ID.COMPANY_PROFILE], role: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN] }
            },
            {
                path: 'view/:id',
                component: CompanyViewComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.COMPANY_PROFILE], canEdit: [] }
            },
            {
                path: 'setting/view/:id',
                component: CompanySettingViewComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.COMPANY_SETTING], canEdit: [] }
            },
            {
                path: 'setting/edit/:id',
                component: CompanySettingEditComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [], canEdit: [MENU_ID.COMPANY_SETTING] }
            },
            {
                path: 'contract/:id',
                component: CompanyContractComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.COMPANY_PROFILE], canEdit: [] }
            },
            {
                path: 'payroll/:id',
                component: PayrollSettingComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.PAYROLL_SETTING], canEdit: [] }
            },
            {
                path: 'payroll/view/:id',
                component: PayrollSettingViewComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.PAYROLL_SETTING], canEdit: [] }
            },
            {
                path: 'payroll/:id/holiday/add',
                component: PayrollHolidayAddComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [], canEdit: [MENU_ID.PAYROLL_SETTING] }
            },
            {
                path: 'payroll/:id/holiday/edit/:holidayId',
                component: PayrollHolidayEditComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [], canEdit: [MENU_ID.PAYROLL_SETTING] }
            },
            {
                path: 'other/view/:id',
                component: CompanyOtherViewComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.OTHER_SETTING], canEdit: [] }
            },
            {
                path: 'other/edit/:id',
                component: CompanySettingEditComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [], canEdit: [MENU_ID.OTHER_SETTING] }
            },
            {
                path: 'holiday',
                component: CompanyHolidayManageComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.COMPANY_HOLIDAY], canEdit: [] }
            },
            {
                path: 'company-payment',
                component: CompanyPaymentDetailsComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.COMPANY_PROFILE], canEdit: [] }
            },
            {
                path: 'company-payment-method',
                component: CompanyPaymentMethodComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.COMPANY_PAYMENT_METHOD], canEdit: [] }
            },
            {
                path: 'document-settings/list',
                component: DocumentSettingsComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.DOCUMENT_SETTING], canEdit: [] }
            },
            {
                path: 'operational-policy',
                component: OperationalPolicyComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.OPERATIONAL_POLICY], canEdit: [] }
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CompanyRoutingModule { }
