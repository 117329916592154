<div class="container">
  <div class="row justify-content-md-center align-self-center" style="text-align: center; display: inline;">
      <div class="col-md-auto" style="margin-top: 100px">
          <img src="../../../../assets/images/warning-icon-svg-11.jpg" style="width: 100px;height: auto;"><br>
          <h3 translate>Sorry, you do not have permission to access this page.</h3>
          <!-- <div class="ui-g-12">
              <button type="button" class="btn btn-primary  mr-2" (click)="goBack()"><i class="fa-solid fa-arrow-left  mr-2"></i>{{'Go Back' | translate}}</button>
          </div> -->
      </div>
  </div>
</div>