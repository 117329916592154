<form [formGroup]="companyForm" #companyDataForm>
	<div class="d-flex align-items-center justify-content-between py-4">
		<div class="d-flex">
			<h1 *ngIf="mode == 'create'" class="m-0 ml-2">{{'Add Company' | translate}}</h1>
			<h1 *ngIf="mode == 'edit'" class="m-0 ml-2">{{'Edit Company' | translate}}</h1>
			<h1 *ngIf="mode == 'view'" class="m-0 ml-2">{{'Company Details' | translate}}</h1>
			<h1 *ngIf="isPlatformAdmin && company && company.name" class="font-weight-bold m-0 ml-1">({{company.name}} company)</h1>
		</div>
		<button *ngIf="mode == 'view' && isPlatformAdmin" class="btn btn-info round" (click)="editCompany()" pTooltip="{{'button.edit' | translate}}" tooltipPosition="left"><i class="fa-solid fa-pen-to-square"></i></button>
		<button *ngIf="mode == 'edit'" class="btn btn-danger round" (click)="viewCompany()" pTooltip="{{'Cancel' | translate}}" tooltipPosition="left"><i class="fa-solid fa-xmark"></i></button>
	</div>
	<p-panel [toggleable]="true" toggler="header">
		<p-header class="w-100 d-flex justify-content-between align-items-center flex-grow-1">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'header.companyDetails' | translate}}</span>
			<span *ngIf="mode != 'create'" class="mr-3" (click)="trackChangesCompany('companyDetails');$event.stopPropagation();" style="cursor: pointer !important"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i>{{'History' | translate}}</span>
		</p-header>
		<div class="ui-g">
			<div class="ui-g-6 ui-sm-12">
			<label>{{'company.companyName' | translate}}:<span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-quote-left"></i>
						</button>
					</div>
					<input class="form-control" pInputText type="text" maxlength="60" formControlName="companyName" autocomplete="off" name="companyName" placeholder="{{'Enter a name' | translate}}" [(ngModel)]="company.name">
				</div>
			</div>
			<!--<div class="ui-g-6 ui-sm-12">-->
				<!--<label>{{'form.licenseNumber' | translate}}:<span class="required-indicator">*</span></label>-->
				<!--<div class="input-group w-100">-->
					<!--<div class="input-group-prepend p-0">-->
						<!--<button  [disabled]="mode == 'view'"  class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">-->
							<!--<i class="fa-solid fa-certificate"></i>-->
						<!--</button>-->
					<!--</div>-->
					<!--<input class="form-control" pInputText type="text" maxlength="200" formControlName="licenseNumber" name="licenseNumber" placeholder="{{'Enter number' | translate}}" [(ngModel)]="company.licenseNumber">-->
				<!--</div>-->
			<!--</div>-->
			<div class="ui-g-6 ui-sm-12">
				<label>{{'form.addressOne' | translate}}:<span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button  [disabled]="mode == 'view'"  class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-signs-post"></i>
						</button>
					</div>
					<input class="form-control" pInputText type="text" maxlength=200 id="addressOne" formControlName="addressOne" name="addressOne" autocomplete="off" [(ngModel)]="company.addressOne" placeholder="{{'Street Address' | translate}}">
				</div>
				<span class="ui-message ui-messages-error" *ngIf="(!companyForm.controls['addressOne'].valid && companyForm.controls['addressOne'].dirty) || (companyForm.controls['addressOne'].hasError('whitespace') && companyForm.controls['addressOne'].dirty)">{{'validate.notBlank' | translate:{value:'form.addressOne' | translate} }}</span>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'form.addressTwo' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button  [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-door-open"></i>
						</button>
					</div>
					<input class="form-control" pInputText type="text" maxlength=200 id="addressTwo" formControlName="addressTwo" name="addressTwo" [(ngModel)]="company.addressTwo" placeholder="{{'form.addressTwo' | translate}}">
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'form.city' | translate}}:<span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button [disabled]="mode == 'view'"  class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-map-location-dot"></i>
						</button>
					</div>
					<input class="form-control" pInputText type="text" maxlength=200 formControlName="city" name="city" placeholder="{{'form.city' | translate}}" [(ngModel)]="company.city">
				</div>
				<span class="ui-message ui-messages-error" *ngIf="(!companyForm.controls['city'].valid && companyForm.controls['city'].dirty) || (companyForm.controls['city'].hasError('whitespace') && companyForm.controls['city'].dirty)">
					{{'validate.notBlank' | translate:{value:'form.city' | translate} }}
				</span>
			</div>
			<div class="ui-g-3 ui-sm-12">
				<label>{{'form.state' | translate}}:<span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button  [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-map"></i>
						</button>
					</div>
					<input class="form-control" pInputText type="text" maxlength=200 formControlName="state" name="state" placeholder="NY" [(ngModel)]="company.state">
				</div>
				<span class="ui-message ui-messages-error" *ngIf="(!companyForm.controls['state'].valid && companyForm.controls['state'].dirty) || (companyForm.controls['state'].hasError('whitespace') && companyForm.controls['state'].dirty)">
					{{'validate.notBlank' | translate:{value:'form.state' | translate} }}
				</span>
			</div>
			<div class="ui-g-3 ui-sm-12">
				<label>{{'form.zipCode' | translate}}:<span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button  [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-location-dot"></i>
						</button>
					</div>
					<input class="form-control" pInputText type="text" maxlength="5" formControlName="zipcode" name="zipcode" placeholder="12345" [(ngModel)]="company.zipcode" (blur)="onZipCodeBlur()">
				</div>
				<span class="ui-message ui-messages-error" *ngIf="(companyForm.controls['zipcode'].hasError('whitespace') && companyForm.controls['zipcode'].dirty && !companyForm.controls['zipcode'].errors?.pattern)">
					{{'validate.notBlank' | translate:{value:'form.zipCode' | translate} }}
				</span>
				<span class="ui-message ui-messages-error" *ngIf="!companyForm.controls['zipcode'].valid && !companyForm.controls['zipcode'].hasError('whitespace') && !companyForm.get('phone').hasError('required') && companyForm.controls['zipcode'].errors?.pattern && companyForm.controls['zipcode'].dirty">
					{{'validate.onlyNumber' | translate}}
				</span>
			</div>
			<div class="ui-g-3 ui-md-6 ui-sm-12">
				<label>{{'form.phone' | translate}}<span class="required-indicator">*</span></label>
				<div class="input-group w-100 {{readOnly ? 'disbled-field' : ''}}">
					<div class="input-group-prepend p-0">
						<button  [disabled]="mode == 'view'"  class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-phone"></i>
						</button>
					</div>
					<p-inputMask mask="(999)999-9999" formControlName="phone" style="height: 40px !important;width:-webkit-fill-available;" placeholder="{{'form.phone' | translate}}" [(ngModel)]="company.phone"></p-inputMask>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="companyForm.controls['phone'].touched && companyForm.get('phone').hasError('required')">{{'validate.thisField' | translate}}</span>
				<span class="ui-message ui-messages-error" *ngIf="companyForm.controls['phone'].touched && !companyForm.get('phone').hasError('required') && companyForm.get('phone').hasError('whitespace')">{{'validate.whitespace' | translate}}</span>
				<span class="ui-message ui-messages-error" *ngIf="!companyForm.get('phone').hasError('required') && companyForm.controls['phone'].errors?.custom && (companyForm.controls['phone'].dirty || companyForm.controls['phone'].touched)" >{{'validate.invalid' | translate:{value:'phoneNumber' | translate} }}</span>
			</div>
			<div class="ui-g-3 ui-md-6 ui-sm-12">
				<label>{{'form.fax' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-fax"></i>
						</button>
					</div>
					<input type="text" pInputText formControlName="fax" placeholder="{{'form.fax' | translate}}" name="fax" maxlength=50 [(ngModel)]="company.fax">
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'form.email' | translate}}<span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-at"></i>
						</button>
					</div>
					<input class="form-control" pInputText type="text" maxlength="200" pInputText formControlName="email" name="email" placeholder="{{'Select Items' | translate}}" [(ngModel)]="company.email">
				</div>
				<span class="ui-message ui-messages-error" *ngIf="!companyForm.controls['email'].valid && companyForm.controls['email'].dirty">
					{{'validate.incorrect' | translate:{value:'form.email' | translate} }}
				</span>
			</div>

			<div class="ui-g-6 ui-sm-12" *ngIf="company.type === '6'">
				<label>{{'Website' | translate}} :</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-keyboard"></i>
						</button>
					</div>
					<input class="form-control" pInputText type="text" maxlength="200" pInputText formControlName="website" name="email" placeholder="{{'Select Items' | translate}}" [(ngModel)]="company.website">
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12" *ngIf="company.type === '6'" >
				<label>{{'Specialty' | translate}} :</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-star"></i>
						</button>
					</div>
					<input class="form-control" pInputText type="text" maxlength="200" pInputText formControlName="specialty" name="email" placeholder="{{'Select Items' | translate}}" [(ngModel)]="company.specialty">
				</div>
			</div>

			<div class="ui-g-12 ui-sm-12" *ngIf="company.type === '6'">
				<label>{{'Company Description' | translate}} :</label>
				<div class="input-group w-100">
					<textarea pInputTextarea style="width:100%" [rows]="4" class="acceptance-inputs" autoResize="true"
							  maxlength="250" placeholder="{{'Maximum length of notes is 250' | translate}}"
							  formControlName="description" [(ngModel)]="company.description"></textarea>
				</div>
			</div>

			<div class="ui-g-12">
				<h3 class="m-0 my-3">{{'Other Details' | translate}}</h3>
			</div>
				
			<div class="ui-g-6 ui-sm-12">
				<label>{{'form.timeZone' | translate}}:<span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button [disabled]="mode == 'view'"  class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-earth-america"></i>
						</button>
					</div>
					<p-dropdown formControlName="timezone" class="timezone" [filter]="true" [showClear]="true" name="timezone" [(ngModel)]="company.timezone"  id="timezone" [options]="timezones" placeholder="{{'Select One' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="!companyForm.controls['timezone'].valid && companyForm.controls['timezone'].dirty">
					{{'validate.required' | translate:{value:'form.timeZone' | translate} }}
				</span>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'form.companyType' | translate}}:<span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button [disabled]="mode == 'view'"  class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-landmark"></i>
						</button>
					</div>
					<p-dropdown class="flex-grow-1" [options]="companyType" name="type" formControlName="type" placeholder="{{'placeholder.pleaseSelect' | translate}}" [(ngModel)]="company.type">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="!companyForm.controls['type'].valid && companyForm.controls['type'].touched">{{'validate.required' | translate:{value:'form.companyType' | translate} }}</span>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Attachment' | translate}} ({{'Drag & Drop' | translate}})</label>
				<div class="input-group w-100" appDragToUploadFile (fileDropped)="onDrop($event)">
					<div class="input-group-prepend p-0">
						<button [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i *ngIf="!processing" class="fa-solid fa-paperclip"></i>
							<div class="upload-process" *ngIf="processing">
								<svg xmlns="http://www.w3.org/2000/svg" style="zoom: 26%;width: 51px;height: 50px;rotate: 180deg;" pTooltip="{{'Uploading...' | translate}}">
									<style>
										.line {
											fill: none;
											stroke: #aaa;
											stroke-width: 8;
											stroke-dasharray: 40
										}
			
										.animated {
											animation: draw .8s ease-in-out;
											animation-iteration-count: infinite;
											stroke-dashoffset: 0
										}
			
										.line2 {
											animation-delay: .08s
										}
			
										.line3 {
											animation-delay: .16s
										}
			
										@keyframes draw {
											0% {
												stroke-dashoffset: 0
											}
			
											50% {
												stroke-dashoffset: 30
											}
			
											100% {
												stroke-dashoffset: 0
											}
										}
									</style>
									<path class="line animated" d="M14 10V50z" />
									<path class="line animated line2" d="M30 10V50z" />
									<path class="line animated line3" d="M46 10V50z" />
								</svg>
							</div>
						</button>
					</div>
					<input *ngIf="mode == 'view'" pInputText disabled placeholder="{{fileUploaded.length}} {{'Uploaded' | translate}}" pInputText>
					<div *ngIf="mode == 'edit' || mode == 'create'" class="form-control d-flex justify-content-between align-items-start" style="height: 40px;line-height: 2;overflow: hidden;">
						<div *ngIf="fileUploaded" class="d-flex align-items-center flex-wrap flex-grow-1">
							<div *ngFor="let file of fileUploaded; let i = index" class="flex-grow-1">
								<div *ngIf="fileUploaded.length >= 2" class="d-flex align-items-center justify-content-between w-100">
									<span class="one-liner" style="max-height: 2rem;line-height: 2;" data-bs-toggle="collapse" data-bs-target="#uploadCollapse" aria-expanded="false" aria-controls="uploadCollapse">{{ i + 1 }}&#41; {{file.fileName}}</span>
									<span class="d-flex">
										<i class="fa-solid fa-arrow-up-right-from-square text-muted text-primary mx-1" (click)="viewDocument(file)" pTooltip="{{'Open' | translate}}" tooltipPosition="left"></i>
										<i class="fa-solid fa-xmark text-danger mx-1" (click)="removeFile(file.id)" pTooltip="{{'Delete' | translate}}" tooltipPosition="left"></i>
										<i data-bs-toggle="collapse" data-bs-target="#uploadCollapse" aria-expanded="false" aria-controls="uploadCollapse" class="fa-solid fa-chevron-down mx-1 text-secondary float-right"></i>
									</span>
								</div>
								<div *ngIf="fileUploaded.length <= 1"  class="d-flex align-items-center justify-content-between w-100">
									<span class="one-liner" style="max-height: 2rem;line-height: 2;">{{ i + 1 }}&#41; {{file.fileName}}</span>
									<span  class="d-flex">
										<i class="fa-solid fa-arrow-up-right-from-square text-muted text-primary mx-1" (click)="viewDocument(file)" pTooltip="{{'Open' | translate}}" tooltipPosition="left"></i>
										<i class="fa-solid fa-xmark text-danger mx-1" (click)="removeFile(file.id)" pTooltip="{{'Delete' | translate}}" tooltipPosition="left"></i>
									</span>
								</div>
							</div>
						</div>
					</div>
					<input hidden type="file" accept="/*" (change)="uploadFile($event)" #upload multiple>
					<div class="input-group-append">
						<button [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i (click)="fileUpload.toggle($event)" class="fa-solid fa-upload" [ngClass]="{'text-success': fileUploaded.length >= 1}"></i>
						</button>
					</div>
				</div>
				<div *ngIf="fileUploaded" class="collapse" id="uploadCollapse">
					<div class="card add-shadow card-body">
						<span class="d-flex align-items-center flex-wrap w-100" style="row-gap: 7px;">
							<span *ngFor="let file of fileUploaded;let i=index; let firstItem = first" class="d-flex align-items-center justify-content-between c-pointer w-100">
								<span *ngIf="!firstItem" class="one-liner" (click)="viewDocument(file)">{{ i + 1 }}&#41; {{file.fileName}}</span><span *ngIf="!firstItem" class="d-flex"><i class="fa-solid fa-arrow-up-right-from-square text-muted text-primary mx-1" (click)="viewDocument(file)" pTooltip="{{'Open' | translate}}" tooltipPosition="left"></i><i class="fa-solid fa-xmark text-danger mx-1" data-bs-toggle="collapse" data-bs-target="#uploadCollapse" aria-expanded="false" aria-controls="uploadCollapse" (click)="removeFile(file.id)" pTooltip="{{'Delete' | translate}}" tooltipPosition="left"></i></span>
							</span>
						</span>
					</div>
				</div>
				<p-overlayPanel #fileUpload appendTo="body">
					<ng-template pTemplate>
						<ul class="m-0 px-1" style="list-style: none; min-width: 150px; line-height: 2">
							<li (click)="onUploadDocument(uploadFilePanel)" style="cursor: pointer;">
								<span class="text-nowrap"><i class="fa-solid fa-file-upload text-primary mr-2"></i> {{'Upload' | translate}}</span>
							</li>
						</ul>
					</ng-template>
				</p-overlayPanel>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'form.serviceType' | translate}} <span class="required-indicator">*</span></label>
				<app-company-service-type formControlName="serviceTypeIds" name="serviceTypeIds" [(ngModel)]="company.serviceTypeIds"></app-company-service-type>
				<span class="ui-message ui-messages-error" *ngIf="!companyForm.controls['serviceTypeIds'].valid && companyForm.controls['serviceTypeIds'].dirty">{{'validate.required' | translate:{value:'form.serviceType' | translate} }}</span>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'form.status' | translate}}<span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button  [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-info"></i>
						</button>
					</div>
					<p-dropdown class="flex-grow-1" [options]="statusTypes" formControlName="status" name="status" placeholder="{{'placeholder.pleaseSelect' | translate}}" [(ngModel)]="company.status">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="!companyForm.controls['status'].valid && companyForm.controls['status'].dirty">
					{{'validate.required' | translate:{value:'form.status' | translate} }}
				</span>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Operational Policy' | translate}}<span class="required-indicator">*</span>
					<i style="cursor: pointer;" class="ml-2 fa-solid fa-info-circle" (click)="operationalPolicyPanel.show($event)"></i>
				</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button  [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-info"></i>
						</button>
					</div>
					<p-dropdown class="flex-grow-1" [options]="operationalPolicies" [filter]="true" formControlName="operationalPolicy" name="operationalPolicy" placeholder="{{'placeholder.pleaseSelect' | translate}}" [(ngModel)]="company.operationalPolicy">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="!companyForm.controls['operationalPolicy'].valid && companyForm.controls['operationalPolicy'].dirty">
					{{'validate.required' | translate:{value:'Operational Policy' | translate} }}
				</span>
			</div>
		</div>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Feature' | translate}}</label>
				<p-panel [toggleable]="true" [collapsed]="true" toggler="header">
					<p-header class="w-100 d-flex justify-content-between align-items-center flex-grow-1">
						<span><i class="fa-solid fa-clock text-primary mr-2"></i>{{'Punch Clock' | translate}}</span>
						<span *ngIf="mode != 'create'" class="mr-3" (click)="trackChangesCompany('punchInOutDetail');$event.stopPropagation();" style="cursor: pointer !important"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i>{{'History' | translate}}</span>
					</p-header>
					<div class="ui-g">
						<div class="ui-g-8 ui-sm-12">
							<label>{{'Username' | translate}}</label>
							<div class="input-group w-100">
								<div class="input-group-prepend p-0">
									<button [disabled]="mode == 'view' || modePunch == 'viewPunch'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
										<i class="fa-solid fa-user-shield"></i>
									</button>
								</div>
								<input type="text" maxlength="40" pInputText name="punchInOutAdminUserName" formControlName="punchInOutAdminUserName" [(ngModel)]="punchInOutAdminUserName" [attr.dsiabled]="mode === 'view' || mode === 'edit'" >
							</div>
							<span *ngIf="companyForm.get('punchInOutAdminUserName').hasError('minlength')" class="ui-message ui-messages-error text-danger">{{'Must be 8 characters minumum' | translate}}</span>
							<span *ngIf="companyForm.get('punchInOutAdminUserName').hasError('userTaken')" class="ui-message ui-messages-error text-danger">{{'Not Available' | translate}}</span>
						</div>
						<div class="ui-g-1 ui-sm-12">
							<label style="width: 100%">&nbsp;</label>
							<button type="button" class="btn btn-orange flex-grow-1" [disabled]="(mode == 'view' && modePunch == 'viewPunch') || !punchInOutAdminStatus || punchInOutAdminStatus == 1" (click)="unlockPunchInOutAdmin()" pInputText>
								<span class="text-nowrap"><i class="fa-solid fa-unlock mr-2"></i></span>
							</button>
						</div>
						<div class="ui-g-3 ui-sm-12">
							<label>{{'form.password' | translate}}: </label>
							<button type="button" class="btn btn-orange flex-grow-1" [disabled]="mode == 'view' && modePunch == 'viewPunch'" (click)="onUpdatePassword()" pInputText>
								<span class="text-nowrap"><i class="fa-solid fa-rotate mr-2"></i>{{'Reset' | translate}}</span>
							</button>
						</div>
						<div class="ui-g-4 ui-sm-6">
							<label>{{'Facial Recognition' | translate}}</label>
							<div class="form-check form-switch my-3">
								<input *ngIf="(mode == 'view' || modePunch == 'viewPunch') && !isSwitchedOn" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" disabled [(ngModel)]="isSwitchedOn" formControlName="isSwitchedOn" name="isSwitchedOn">
								<input *ngIf="(mode == 'view' || modePunch == 'viewPunch') && isSwitchedOn" checked class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" disabled [(ngModel)]="isSwitchedOn" formControlName="isSwitchedOn" name="isSwitchedOn">
								<input *ngIf="(mode != 'view' || modePunch != 'viewPunch') && isSwitchedOn" checked class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" [(ngModel)]="isSwitchedOn" (click)="isSwitchedOn = !isSwitchedOn" formControlName="isSwitchedOn" name="isSwitchedOn">
								<input *ngIf="(mode != 'view' || modePunch != 'viewPunch') && !isSwitchedOn"  class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" [(ngModel)]="isSwitchedOn" (click)="isSwitchedOn = !isSwitchedOn" formControlName="isSwitchedOn" name="isSwitchedOn">
							</div>
						</div>
						<div class="ui-g-4 ui-sm-6">
							<label>{{'PIN Method' | translate}}</label>
							<div class="form-check form-switch my-3">
								<input *ngIf="(mode == 'view' || modePunch == 'viewPunch') && !isPinSwitchedOn" class="form-check-input" type="checkbox" role="switch" id="flexPinSwitchCheckChecked" disabled [(ngModel)]="isPinSwitchedOn" formControlName="isPinSwitchedOn" name="isPinSwitchedOn">
								<input *ngIf="(mode == 'view' || modePunch == 'viewPunch') && isPinSwitchedOn" checked class="form-check-input" type="checkbox" role="switch" id="flexPinSwitchCheckChecked" disabled [(ngModel)]="isPinSwitchedOn" formControlName="isPinSwitchedOn" name="isPinSwitchedOn">
								<input *ngIf="(mode != 'view' || modePunch != 'viewPunch') && isPinSwitchedOn" checked class="form-check-input" type="checkbox" role="switch" id="flexPinSwitchCheckChecked" [(ngModel)]="isPinSwitchedOn" (click)="isPinSwitchedOn = !isPinSwitchedOn" formControlName="isPinSwitchedOn" name="isPinSwitchedOn">
								<input *ngIf="(mode != 'view' || modePunch != 'viewPunch') && !isPinSwitchedOn"  class="form-check-input" type="checkbox" role="switch" id="flexPinSwitchCheckChecked" [(ngModel)]="isPinSwitchedOn" (click)="isPinSwitchedOn = !isPinSwitchedOn" formControlName="isPinSwitchedOn" name="isPinSwitchedOn">
							</div>
						</div>
						<div class="ui-g-4 ui-sm-6">
							<label>{{'Display Duration' | translate}}</label>
							<div class="form-check form-switch my-3">
								<input *ngIf="(mode == 'view' || modePunch == 'viewPunch') && !isDisplayDuration" class="form-check-input" type="checkbox" role="switch" id="flexDisplayDurationSwitchCheckChecked" disabled [(ngModel)]="isDisplayDuration" formControlName="isDisplayDuration" name="isDisplayDuration">
								<input *ngIf="(mode == 'view' || modePunch == 'viewPunch') && isDisplayDuration" checked class="form-check-input" type="checkbox" role="switch" id="flexDisplayDurationSwitchCheckChecked" disabled [(ngModel)]="isDisplayDuration" formControlName="isDisplayDuration" name="isDisplayDuration">
								<input *ngIf="(mode != 'view' || modePunch != 'viewPunch') && isDisplayDuration" checked class="form-check-input" type="checkbox" role="switch" id="flexDisplayDurationSwitchCheckChecked" [(ngModel)]="isDisplayDuration" (click)="isDisplayDuration = !isDisplayDuration" formControlName="isDisplayDuration" name="isDisplayDuration">
								<input *ngIf="(mode != 'view' || modePunch != 'viewPunch') && !isDisplayDuration"  class="form-check-input" type="checkbox" role="switch" id="flexDisplayDurationSwitchCheckChecked" [(ngModel)]="isDisplayDuration" (click)="isDisplayDuration = !isDisplayDuration" formControlName="isDisplayDuration" name="isDisplayDuration">
							</div>
						</div>
						<div class="ui-g-12 ui-sm-12">
							<button *ngIf="modePunch != 'editPunch' && isCompanyAdmin" class="btn btn-info round" (click)="editPunchClock()" pTooltip="{{'button.edit' | translate}}" tooltipPosition="left"><i class="fa-solid fa-pen-to-square"></i></button>
							<button *ngIf="modePunch == 'editPunch' && isCompanyAdmin" class="btn btn-danger round" (click)="viewPunchClock()" pTooltip="{{'Cancel' | translate}}" tooltipPosition="left"><i class="fa-solid fa-xmark"></i></button>
							<button *ngIf="modePunch == 'editPunch' && isCompanyAdmin" type="submit" class="btn btn-primary mx-1" (click)="updatePunchClock(companyDataForm)"><i class="fa-solid fa-check mr-2"></i>{{'Update' | translate}}</button>
						</div>
					</div>
				</p-panel>
			</div>
            <div class="ui-g-6 ui-sm-12">
                <label>{{'Total Employee' | translate}}<span class="required-indicator">*</span>
                    <i *ngIf="company.totalEmployee" style="cursor: pointer;" class="ml-2 fa-solid fa-info-circle" (click)="totalEmployeePanel.show($event)"></i>
                </label>
                <div class="input-group w-100">
                    <div class="input-group-prepend p-0">
                        <button  [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                            <i class="fa-solid fa-info"></i>
                        </button>
                    </div>
                    <p-dropdown class="flex-grow-1" [options]="totalEmployees" formControlName="totalEmployee" name="totalEmployee" placeholder="{{'placeholder.pleaseSelect' | translate}}" [(ngModel)]="company.totalEmployee">
                        <ng-template pTemplate="item" let-item>
                            <span class="text-truncate">{{item.label | translate}}</span>
                        </ng-template>
                        <ng-template let-item pTemplate="selectedItem">
                            <span class="one-liner">{{item.label | translate}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
                <span class="ui-message ui-messages-error" *ngIf="!companyForm.controls['totalEmployee'].valid && companyForm.controls['totalEmployee'].dirty">
					{{'validate.required' | translate:{value:'Operational Policy' | translate} }}
				</span>
            </div>
		</div>
	</p-panel>
	<br>

		<!-- OPERRTEL SETTING -->
	<p-panel [toggleable]="true" *ngIf="isPlatformAdmin" toggler="header">
		<p-header class="d-flex flex-grow-1 justify-content-between align-items-center">
			<span><i class="fa-solid fa-fax text-primary mr-2"></i>{{'Phone Configuration' | translate}}</span>
			<span *ngIf="mode != 'create'" class="mr-3 text-dark" style="cursor: pointer;" (click)="trackChangesCompany('phoneConfiguration');$event.stopPropagation();"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i>{{'History' | translate}}</span>
		</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'OperrTel' | translate}}# 1</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button [disabled]="mode == 'view'"  class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-phone"></i>
						</button>
					</div>
<!--					<p-inputMask mask="(999)999-9999" formControlName="operrTel" class="w-100" placeholder="{{'form.phone' | translate}}" [(ngModel)]="company.operrTelId" [attr.disabled]="!isPlatformAdmin || !company.serviceTypeIds || company.serviceTypeIds.indexOf(3) === -1"></p-inputMask>-->
					<p-dropdown class="flex-grow-1" (onChange)="onChangeOperrTel(company.operrTelId)" [options]="listOperrTelPhone" formControlName="operrTel" name="operrTel" placeholder="{{'form.phone' | translate}}" [(ngModel)]="company.operrTelId" [disabled]="!isPlatformAdmin || (mode !== 'edit' && mode !== 'create')">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
					<div class="input-group-append">
                        <button *ngIf="mode !== 'view'" class="btn btn-white" id="button-addon2" type="button" pTooltip="{{'Delete' | translate}}" tooltipPosition="left" (click)="removeOperrtelKey(company, true)" style="cursor:default !important">
                            <i  class="fa-solid fa-xmark text-danger mx-1 cursor-pointer"></i>
                        </button>
						<button [disabled]="mode == 'view'" class="btn btn-primary text-nowrap" (click)="openPhoneSetupDialog(company, true)"><i class="fa-solid fa-gear mr-2"></i>{{'API Setup' | translate}}</button>
					</div>
				</div>
			</div>

			<ng-container *ngIf="company.extraOperrTelKeys">
				<div class="ui-g-6 ui-sm-12" *ngFor="let extraKey of company.extraOperrTelKeys; let i = index; trackBy: trackByIdentify">
					<label>{{'OperrTel' | translate}}# {{i + 2}}
						<i style="cursor: pointer;" class="ml-2 fa-solid fa-info-circle" (click)="operrTelInfo.show($event)"></i>
					</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button [disabled]="mode == 'view'"  class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-phone"></i>
							</button>
						</div>
<!--						<p-inputMask mask="(999)999-9999" formControlName="operrTel-{{extraKey.idx}}" class="flex-grow-1" placeholder="{{'form.phone' | translate}}" [(ngModel)]="extraKey.phone" [disabled]="!isPlatformAdmin || !company.serviceTypeIds || company.serviceTypeIds.indexOf(3) === -1 || (mode !== 'edit' && mode !== 'create')"></p-inputMask>-->
						<p-dropdown class="flex-grow-1" (onChange)="onChangeOperrTelPhone(i, extraKey.operrtelId)" [options]="listOperrTelPhone" formControlName="operrTel-{{extraKey.idx}}" name="operrtelId" placeholder="{{'form.phone' | translate}}" [(ngModel)]="extraKey.operrtelId" [disabled]="!isPlatformAdmin || !company.serviceTypeIds || company.serviceTypeIds.indexOf(3) === -1 || (mode !== 'edit' && mode !== 'create')"
									pTooltip="{{'Please select the Operrtel option from the Service Type field to use the options in this section' | translate}}" tooltipPosition="left">
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>
						</p-dropdown>
						<div class="input-group-append">
							<button *ngIf="mode !== 'view'" class="btn btn-white" id="button-addon2" type="button" pTooltip="{{'Delete' | translate}}" tooltipPosition="left" (click)="removeOperrtelKey(extraKey, false)" style="cursor:default !important">
								<i  class="fa-solid fa-xmark text-danger mx-1 cursor-pointer"></i>
							</button>
							<button [disabled]="mode == 'view'" class="btn btn-primary text-nowrap" (click)="openPhoneSetupDialog(extraKey, false)"><i class="fa-solid fa-gear mr-2"></i>{{'API Setup' | translate}}</button>
						</div>
					</div>
				</div>
			</ng-container>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Process Method' | translate}}:<span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button [disabled]="mode == 'view'"  class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-circle-info"></i>
						</button>
					</div>
					<p-dropdown formControlName="operrTelProcessMethod" name="operrTelProcessMethod" [(ngModel)]="company.operrTelProcessMethod" [options]="operrTelProcessMethodOptions" placeholder="{{'Select One' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
		</div>
		<p-footer *ngIf="mode != 'view'" class="d-flex align-items-center justify-content-center">
			<button class="btn btn-white" style="cursor: pointer;" (click)="addOperTelKey()">
				<i class="fa-solid fa-plus text-primary mr-2"></i>{{'Add Another' | translate}}
			</button>
		</p-footer>
	</p-panel>
	<br *ngIf="isPlatformAdmin">
	<!-- Save cards -->
	<!--<p-panel [toggleable]="true" [collapsed]="true" id="table-ppanel">-->
		<!--<p-header class="flex-grow-1">-->
			<!--<span><i class="fa-solid fa-credit-card text-primary mr-2"></i>{{'Saved Cards' | translate}} <span>({{userCardList?.length || 0}})</span></span>-->
		<!--</p-header>-->
		<!--<div class="ui-g" *ngIf="!userCardList">-->
			<!--<div class="ui-g-12 text-center py-5">-->
				<!--<div class="text-muted">{{'No cards have been added' | translate}}</div>-->
				<!--<button *ngIf="mdoe == 'edit'" class="btn btn-light mt-4" (click)="addCard('CREDIT')">-->
					<!--<span class="text-nowrap"><i class="fa-solid fa-plus mr-2"></i> {{'Add Now' | translate}}</span>-->
				<!--</button>-->
			<!--</div>-->
		<!--</div>-->
		<!--<div *ngFor="let card of userCardList" class="card add-shadow mb-0 p-2">-->
			<!--<div class="d-flex align-items-center" *ngIf="card">-->
				<!--<img src="/assets/payment/visa-straight-128px.png" *ngIf="card.paymentCardType === 'VISA'"  class="card-image"/>-->
				<!--<img src="/assets/payment/mastercard-straight-128px.png" *ngIf="card.paymentCardType === 'MASTER_CARD'"  class="card-image"/>-->
				<!--<img src="/assets/payment/discovery-straight-128px.png" *ngIf="card.paymentCardType === 'DISCOVER'"  class="card-image"/>-->
				<!--<img src="/assets/payment/american-express-straight-128px.png" *ngIf="card.paymentCardType === 'AMERICAN_EXPRESS'"  class="card-image" />-->
				<!--<img src="/assets/payment/debit-card-straight-128px.png" *ngIf="card.paymentCardType === 'DEBIT'"  class="card-image"/>-->
				<!--<div class="d-flex align-items-center justify-content-between flex-grow-1 pl-3">-->
					<!--<span class=""><span style="font-weight: 500">{{'Ends With' | translate}}</span>: {{card.lastFourNumbers}}</span>-->
					<!--<span><span style="font-weight: 500">{{'Exp' | translate}}</span> {{card.expire}}</span>-->
					<!--<div class="d-flex">-->
						<!--<button class="btn btn-white mx-1" (click)="confirmRemoveCard(card)" id="button-addon2" [disabled]="mode == 'view'" type="button" pTooltip="{{'Delete' | translate}}" style="cursor:default !important">-->
							<!--<i class="fa-solid fa-xmark text-danger"></i>-->
						<!--</button>-->
					<!--</div>-->
				<!--</div>-->
			<!--</div>-->
		<!--</div>-->

		<!--<div *ngIf="!isPlatformAdmin && (userCardList?.length > 0)" class="d-flex flex-wrap align-items-center justify-content-center py-4">-->
			<!--<p-checkbox [(ngModel)]="companyAutoPay" formControlName="autoPay" -->
				<!--label="{{'I Authorize the processing of Auto-Pay payments' | translate}}" value="true" binary="true" -->
				<!--[disabled]="((!userCardList || userCardList.length === 0)) || mode == 'view'" (onChange)="changeAutoPay($event)" ></p-checkbox>-->
			<!--<a href="javascript:void(0)" (click)="openDialogCondition()" class="text-primary">{{'Terms of Service' | translate}}</a>-->
		<!--</div>-->
	<!--</p-panel>-->
	<br>
	<!-- TAX DETAILS -->
	<p-panel [toggleable]="true" toggler="header">
		<p-header class="d-flex flex-grow-1 justify-content-between align-items-center">
			<span><i class="fa-solid fa-scale-unbalanced text-primary mr-2"></i>{{'header.taxDetail' | translate}}</span>
			<span *ngIf="mode != 'create'" class="mr-3 text-dark" style="cursor: pointer;" (click)="trackChangesCompany('taxDetail');$event.stopPropagation();"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i>{{'History' | translate}}</span>
		</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'form.federalTaxNumber' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-tree-city"></i>
						</button>
					</div>
					<input class="form-control" pInputText type="text" maxlength="200" name="federalTax" formControlName="federalTax" placeholder="{{'form.federalTaxNumber' | translate}}" [(ngModel)]="company.federalTax">
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'form.federalTaxStatus' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-circle-info"></i>
						</button>
					</div>
					<p-dropdown [options]="statusTypes"  class="flex-grow-1" formControlName="federalTaxStatus" name="federalTaxStatus" placeholder="{{'placeholder.pleaseSelect' | translate}}" [(ngModel)]="company.federalTaxStatus">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-3 ui-sm-12">
				<label>{{'form.federalTaxStart' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-check"></i>
						</button>
					</div>
					<mat-form-field class="d-flex flex-grow-1">
						<input matInput (focus)="picker1.open()" [matDatepicker]="picker1" name="federalTaxStart" formControlName="federalTaxStart" [(ngModel)]="company.federalTaxStart" style="flex-grow: 1; display: flex;">
						<mat-datepicker #picker1></mat-datepicker>
					</mat-form-field>
				</div>
			</div>
			<div class="ui-g-3 ui-sm-12">
				<label>{{'form.federalTaxExpire' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-xmark"></i>
						</button>
					</div>
					<mat-form-field class="d-flex flex-grow-1">
						<input matInput (focus)="picker2.open()" [matDatepicker]="picker2" name="federalTaxExpire" formControlName="federalTaxExpire" [(ngModel)]="company.federalTaxExpire" style="flex-grow: 1; display: flex;">
						<mat-datepicker #picker2></mat-datepicker>
					</mat-form-field>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'form.stateTaxNumber' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar"></i>
						</button>
					</div>
					<input type="text" class="form-control" pInputText  maxlength="200" name="stateTax" formControlName="stateTax" placeholder="{{'form.stateTaxNumber' | translate}}" [(ngModel)]="company.stateTax">
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'form.stateTaxStatus' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-info"></i>
						</button>
					</div>
					<p-dropdown class="flex-grow-1" [options]="statusTypes" formControlName="stateTaxStatus" placeholder="{{'placeholder.pleaseSelect' | translate}}" name="stateTaxStatus" [(ngModel)]="company.stateTaxStatus">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-3 ui-sm-12">
				<label>{{'form.stateTaxStart' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-check"></i>
						</button>
					</div>
					<mat-form-field class="d-flex flex-grow-1">	
						<input matInput (focus)="picker3.open()" formControlName="stateTaxStart" [matDatepicker]="picker3" name="stateTaxStart" [(ngModel)]="company.stateTaxStart" style="flex-grow: 1; display: flex;">
						<mat-datepicker #picker3></mat-datepicker>
					</mat-form-field>
				</div>
			</div>
			<div class="ui-g-3 ui-sm-12">
				<label>{{'form.stateTaxExpire' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-xmark"></i>
						</button>
					</div>
					<mat-form-field class="d-flex flex-grow-1">
						<input matInput (focus)="picker4.open()" formControlName="stateTaxExpire" [matDatepicker]="picker4" name="stateTaxExpire" [(ngModel)]="company.stateTaxExpire" style="flex-grow: 1; display: flex;">
						<mat-datepicker #picker4></mat-datepicker>
					</mat-form-field>
				</div>
			</div>
		</div>
	</p-panel>
	<br>
	<p-panel [toggleable]="true" id="table-ppanel" toggler="header">
		<p-header class="d-flex flex-grow-1 justify-content-between align-items-center">
			<span><i class="fa-solid fa-sliders text-primary mr-2"></i>{{'Plan' | translate}}</span>
			<span *ngIf="mode == 'edit' || mode == 'view'" class="text-dark mr-3"  (click)="trackChangesCompany('plan');$event.stopPropagation();" style="cursor: pointer !important;"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i>{{'History' | translate}}</span>
		</p-header>
		<app-company-plan [planId]="company.planId" [isDisable]="isDisable" (newItemEvent)="addPlanId($event)" (groupsList)="groups($event)"></app-company-plan>
	</p-panel>
	<div class="ui-g">
		<div class="ui-g-12 text-center pt-4">
			<button *ngIf="mode == 'edit'" class="btn btn-danger mx-1" (click)="viewCompany()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.cancel' | translate}}</button>
			<button *ngIf="mode == 'create'" class="btn btn-danger mx-1" (click)="onCancel()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.cancel' | translate}}</button>
			<button *ngIf="mode == 'view'" class="btn btn-orange mx-1" (click)="onCancel()"><i class="fa-solid fa-arrow-left mr-2"></i>{{'Back' | translate}}</button>
			<button *ngIf="mode == 'edit'" type="submit" class="btn btn-primary mx-1" (click)="updateCompany(companyDataForm)" [disabled]="companyForm.invalid || disableAfterSave "><i class="fa-solid fa-check mr-2"></i>{{'Update' | translate}}</button>
			<button *ngIf="mode == 'view' && isPlatformAdmin" class="btn btn-info mx-1" (click)="editCompany()"><i class="fa-solid fa-pen-to-square mr-2"></i>{{'button.edit' | translate}}</button>
			<button *ngIf="mode == 'create'" type="submit" class="btn btn-primary mx-1" (click)="addCompany(companyDataForm)" [disabled]="companyForm.invalid || disableAfterSave"><i class="fa-solid fa-check mr-2"></i>{{'button.submit' | translate}}</button>
		</div>
	</div>
</form>
<p-dialog [(visible)]="isUpdatePassword" modal="modal" showEffect="fade" width="600">
    <p-header styleClass="p-0">
        <span style="font-size: 14px" translate>{{'header.updatePassword'}}</span>
    </p-header>
    <form [formGroup]="updatePasswordForm" (ngSubmit)="onSavePassword(updatePasswordForm, updatePw)" #updatePw="ngForm">
        <div class="ui-g pr-5 mt-3">
            <div class="ui-g-12 p-0">
                <div class="ui-g">
                    <div class="ui-g-4 py-1 text-right">
                        <label>{{'form.newPassword' | translate}}:</label>
                    </div>
                    <div class="ui-g-8 px-0 py-1">
                        <input [type]="!hideNewPassword ? 'text' : 'password'" pInputText class="w-90" formControlName="newPassword" [(ngModel)]="newPassword" name="newPassword"/>
                        <i class="fa-solid fa-eye" *ngIf="hideNewPassword" (click)="showPassword('new')"></i>
                        <i class="fa-solid fa-eye-slash " *ngIf="!hideNewPassword" (click)="hidePassword('new')"></i>
                    </div>
                </div>
                <div class="ui-g">
                    <div class="ui-g-4 py-1 text-right">
                        <label>{{'form.confirmNewPassword' | translate}}:</label>
                    </div>
                    <div class="ui-g-8 px-0 py-1">
                        <input [type]="!hideConfirmPassword ? 'text' : 'password'" pInputText class="w-90" formControlName="confirmNewPassword" [(ngModel)]="confirmPassword" name="confirmNewPassword"/>
                        <i class="fa-solid fa-eye" *ngIf="hideConfirmPassword" (click)="showPassword('confirm')"></i>
                        <i class="fa-solid fa-eye-slash " *ngIf="!hideConfirmPassword" (click)="hidePassword('confirm')"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="ui-g mt-3">
            <div class="ui-g-12 text-center">
                <button pButton type="submit" label="{{'button.save' | translate}}"
                    [disabled]="(newPassword !== confirmPassword) || (newPassword && newPassword.length === 0) || (oldPassword && oldPassword.length === 0)"></button>
                <button pButton (click)="onCancelSavePassword(updatePw)" type="button" label="{{'button.cancel' | translate}}"></button>
            </div>
        </div>
    </form>
</p-dialog>
<p-dialog *ngIf="historyShow" [modal]="true" [(visible)]="historyShow" [dismissableMask]="true" [responsive]="true" [style]="{'width':'90%', 'max-width':'900px', 'max-height':'80vh'}">
	<p-header>
		<span><i class="fa-solid fa-clock-rotate-left mr-2"></i>{{title}}</span>
	</p-header>
    <app-company-history [companyId]="companyId" [field]="planId" [plans]="plans" [type]="historyType"></app-company-history>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="historyShow = false">
			<i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}
		</button>
	</p-footer>
</p-dialog>
<p-dialog [(visible)]="displayDialog" [modal]="true" [style]="{'width': '90%', 'max-width':'500px'}" [closable]="true">
	<p-header>
		<span><i class="fa-solid fa-image mr-2"></i>{{'Attachment Preview' | translate}}</span>
	</p-header>
    <img [src]="fileUrl" style="width:100%; height:auto;">
	<p-footer>
		<button class="btn btn-primary" (click)="displayDialog = false">
			<i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}
		</button>
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="phoneKeySetupDialog" [modal]="true" showEffect="fade" [style]="{'width': '90%', 'max-width':'500px'}" (onHide)="closePhoneSetupDialog()">
	<p-header>
		<span><i class="fa-solid fa-key mr-2"></i>{{'Phone API Keys' | translate}}</span>
	</p-header>
	<form [formGroup]="phoneKeySetupForm">
		<div class="ui-g pb-3">
			<div class="ui-g-12">
				<label>{{'API Key' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-quote-left"></i>
						</button>
					</div>
					<input type="text" pInputText class="w-90" autocomplete="off" formControlName="apiKey" [(ngModel)]="selectedPhoneKeySetup.apiKey" name="apiKey"/>
				</div>
			</div>
			<div class="ui-g-12">
				<label>{{'API Secret' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button [disabled]="mode == 'view'" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-lock"></i>
						</button>
					</div>
					<input class="form-control" [type]="!hideApiSecret ? 'text' : 'password'" autocomplete="new-password" pInputText class="w-90" formControlName="apiSecret" [(ngModel)]="selectedPhoneKeySetup.apiSecret" name="apiSecret"/>
					<div class="input-group-append">
						<button *ngIf="hideApiSecret" (click)="showPassword('apiSecret')" class="btn btn-white" id="button-addon2" type="button" style="cursor:cursor !important">
							<i class="fa-solid fa-eye text-primary"></i>
						</button>
						<button *ngIf="!hideApiSecret" (click)="hidePassword('apiSecret')" class="btn btn-white" id="button-addon2" type="button" style="cursor:cursor !important">
							<i class="fa-solid fa-eye-slash text-danger"></i>
						</button>
					</div>
				</div>
			</div>
			<div class="ui-g-6 ui-md-6 ui-sm-12">
				<label>{{'Start Date' | translate}} <span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-plus"></i>
						</button>
					</div>
					<mat-form-field class="w-100" (click)="picker6.open()">
						<input matInput (focus)="picker6.open()" readonly
							   [matDatepicker]="picker6" name="startDate"
							   [disabled]="readOnly" formControlName="startDate"
							   [(ngModel)]="selectedPhoneKeySetup.startDate"
							   [max]="selectedPhoneKeySetup.endDate">
						<mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
						<mat-datepicker #picker6></mat-datepicker>
					</mat-form-field>
				</div>
			</div>
			<div class="ui-g-6 ui-md-6 ui-sm-12">
				<label>{{'End Date' | translate}} : </label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-xmark"></i>
						</button>
					</div>
					<mat-form-field class="w-100" (click)="picker5.open()">
						<input matInput (focus)="picker5.open()"
							   [matDatepicker]="picker5" name="endDate"
							   [disabled]="readOnly" formControlName="endDate"
							   [(ngModel)]="selectedPhoneKeySetup.endDate"
							   [min]="selectedPhoneKeySetup.startDate">
						<mat-datepicker-toggle matSuffix [for]="picker5">
						</mat-datepicker-toggle>
						<mat-datepicker #picker5></mat-datepicker>
					</mat-form-field>
				</div>
			</div>
		</div>
	</form>
	<p-footer class="d-flex justify-content-between">
		<button class="btn btn-danger mx-1" (click)="closePhoneSetupDialog()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.cancel' | translate}}</button>
		<button type="submit" (click)="changePhoneKeySetup()" class="btn btn-primary mx-1" [disabled]="!selectedPhoneKeySetup.apiKey || !selectedPhoneKeySetup.apiSecret"><i class="fa-solid fa-check mr-2"></i>{{'button.submit' | translate}}</button>
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="showRemoveOperrTelConfirmDialog" class="modal_in" [modal]="true">
	<p-header>
		<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Remove Item' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-12">
			<span style="font-size: 16px">{{'Are you sure you want to remove this item?' | translate}}</span>
		</div>
	</div>
	<p-footer class="d-flex justify-content-between">
		<button type="button" class="btn btn-danger" (click)="showRemoveOperrTelConfirmDialog = false;"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button type="button" class="btn btn-primary" (click)="confirmRemoveOperrtelKey()"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
	</p-footer>
</p-dialog>

<p-dialog [style]="{'width':'90%', 'max-width':'500px'}" [(visible)]="showConfirmCardDialog" [modal]='true'>
	<p-header>
		<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Delete Card' | translate}}</span>
	</p-header>
    <div class="ui-g">
		<div class="ui-g-12">
			<span style="font-size: 16px">{{'Are you sure you want to delete card ending in' | translate}} {{lastFourNumbers}}?</span>
		</div>
    </div>
	<p-footer class="d-flex justify-content-between">
		<button type="button" (click)="rejectCard()" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button type="button" (click)="acceptCard()" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
    </p-footer>
</p-dialog>

<p-dialog [style]="{'width':'70vw'}" [(visible)]="showConditionDialog" modal='modal' [modal]='true'>
	<p-header>
		<span><i class="fa-solid fa-shield mr-2"></i>{{'Payment Authorization' | translate}}</span>
	</p-header>
    <div>
		<h3 class="m-0 my-3">{{'Card Payment & Refund Authorization' | translate}}</h3>
        <p>
            {{'this_checkbox' | translate}}
        </p>
        <p>
            {{'by_selecting_this_option' | translate}}
        </p>
        <p>
            {{'i_authorize_operr' | translate}}
        </p>
    </div>
    <p-footer>
        <div class="ui-g-12" align="right">
            <button type="button" class="btn btn-primary" (click)="closeDialogCondition()"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
        </div>
    </p-footer>
</p-dialog>


<p-overlayPanel #operationalPolicyPanel appendTo="body">
	<div style=" max-width: 500px;">
		<div style="background-color: #5b93ce; color: #fff;" class="d-flex align-items-center px-2 py-1">
			<i style="color: #fff;" class="mr-2 fa-solid fa-info-circle"></i>
			Information
		</div>
		<div class="px-2 py-3" style="white-space: pre-line;">
			Other Businesses that share common operational elements such as customer service, reservations or bookings, cleanliness,
			and logistical management, making their policies and procedures similar to those of restaurants:
		</div>
		<div class="ui-g">
			<div class="ui-g-6 p-0">
				<ul>
					<li>Brewpubs</li>
					<li>Gastropubs</li>
					<li>Nightclubs</li>
					<li>Cafés with Alcohol</li>
					<li>Lounges</li>
					<li>Wine Bars</li>
					<li>Tapas Bars</li>
					<li>Bistro</li>
					<li>Beer Gardens</li>
					<li>Speakeasies</li>
					<li>Cider Houses</li>
					<li>Food Trucks with Alcohol</li>
				</ul>
			</div>
			<div class="ui-g-6 p-0">
				<ul>
					<li>Hotels</li>
					<li>Parking Garages</li>
					<li>Event Venues</li>
					<li>Cinemas</li>
					<li>Retail Stores</li>
					<li>Gyms and Fitness Centers</li>
					<li>Spas and Wellness Centers</li>
					<li>Theaters and Performance Venues</li>
					<li>Theme Parks and Amusement Parks</li>
					<li>Casinos</li>
					<li>Conference Centers</li>
				</ul>
			</div>
		</div>
	</div>
</p-overlayPanel>


<p-overlayPanel #totalEmployeePanel appendTo="body">
	<div style=" max-width: 500px;">
		<div style="background-color: #5b93ce; color: #fff;" class="d-flex align-items-center px-2 py-1">
			<i style="color: #fff;" class="mr-2 fa-solid fa-info-circle"></i>
			Information
		</div>
		<div class="px-2 py-3" style="white-space: pre-line;" *ngIf="company && company.totalEmployee == '100 or more employees'">
			Employers with 100 or more employees must provide up to 56 hours of paid sick leave per calendar year.
		</div>
		<div class="px-2 py-3" style="white-space: pre-line;" *ngIf="company && company.totalEmployee == '5 to 99 employees'">
			Employers with 5 to 99 employees must provide up to 40 hours of paid sick leave per calendar year.
		</div>
		<div class="px-2 py-3" style="white-space: pre-line;" *ngIf="company && company.totalEmployee == '4 or fewer employees'">
			Employers with 4 or fewer employees and net income of greater than $1 million in the previous tax year are required to provide up to 40 hours of paid sick leave per calendar year.
		</div>
	</div>
</p-overlayPanel>
<p-dialog [(visible)]="showUnlockAdmin" class="modal_in" [modal]="true" (onHide)="showUnlockAdmin = false">
	<p-header>
		<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Unlock Account' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-12">
			<span style="font-size: 16px">{{'Are you sure you want to unlock punch in out account?' | translate}}</span>
		</div>
	</div>
	<p-footer class="d-flex justify-content-between">
		<button type="button" class="btn btn-danger" (click)="showUnlockAdmin = false;"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button type="button" class="btn btn-primary" (click)="confirmUnlockPunchInOutAdmin()"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
	</p-footer>
</p-dialog>

<p-overlayPanel #operrTelInfo appendTo="body">
	<div style=" max-width: 500px;">
		<div style="background-color: #5b93ce; color: #fff;" class="d-flex align-items-center px-2 py-1">
			<i style="color: #fff;" class="mr-2 fa-solid fa-info-circle"></i>
			Information
		</div>
		<div class="px-2 py-3" style="white-space: pre-line;">
			Please select the Operrtel option from the Service Type field to use the options in this section
		</div>
	</div>
</p-overlayPanel>
