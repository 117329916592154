import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { FreelancerType } from '../model/freelancerType.model';
import { Observable } from 'rxjs';
import { Freelancer } from '../model/freelancer.model';
import { Page } from '../../shared/model/page.model';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { map } from 'rxjs/operators';
import {ValidatePin} from '../../employee/model/punch-in-out';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({ providedIn: 'root' })
export class FreelancerService extends AbstractService<Freelancer> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.FREELANCER, '');
  }
  getDepartmentListByPage(params: any): Observable<Page<Freelancer>> {
    return this._http.get<Page<Freelancer>>(this.baseUrl, { params: params }).pipe(map(resp => resp));
  }

  saveHistoryData(data) {
    return this._http.post(`${this.baseUrlV2}history`, data).pipe(map(resp => resp));
  }

  filter(options: any): Observable<Page<Freelancer>> {
    return this._http.post<Page<Freelancer>>(`${this.baseUrlV2}search`, options).pipe(map(resp => resp));
  }
  validateCurrentPassword(entity: any): Observable<any> {
    return this._http.post<any>(this.baseUrlV2 + 'validate_password', entity).pipe(map(resp => resp));
  }

  changePassword(entity: any): Observable<any> {
      return this._http.put<any>(this.baseUrlV2 + 'change-password', entity).pipe(map(res => res));
  }

  isEmailAvailable(email: any) {
    return this._http.get(this.baseUrl + 'check_email/' + email);
  }

  generatePdfFile(options: any) {
    return this._http.post<any>(`${this.baseUrlV2}generatePdf`, options).pipe(map(resp => resp));
  }

  exportAsExcelFile(json: any[], excelFileName: string, fileExtension?: any): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    const excelBuffer: any = XLSX.write(workbook, { bookType: fileExtension, type: 'array' });
    if (fileExtension) {
      this.saveAsExcelFile(excelBuffer, excelFileName, fileExtension);
    } else {
      this.saveAsExcelFile(excelBuffer, excelFileName, '.xlsx');
    }
  }

  saveAsExcelFile(buffer: any, fileName: string, fileExtension?: string): void {
    let downloadFileExtension: string = EXCEL_EXTENSION;
    if (fileExtension) {
      downloadFileExtension = fileExtension;
    }
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    // FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + '.' + fileExtension);
  }

  dropdown(data: any): Observable<any> {
      return this.http.post(`${this.baseUrlV2}drop_down`, data).pipe(map((resp) => resp));
  }

  getAllAddedByCompany(options?: any): Observable<any> {
    const params = this.createParams(options);
    return this._http.get(this.baseUrlV2 + 'getAllAddedByCompany', { params: params }).pipe(map(res => res));
  }

  updateAgreement(id: any) {
    const url  = this.baseUrlV2 + id + '/agreement';
    return this._http.post<any>(url, null).pipe(map(resp => resp));
  }

  dropdownAll(data: any): Observable<any> {
    return this.http.post(this.baseUrlV2 + 'all', data).pipe(map((resp) => resp));
    // return this.http.post('http://localhost:8013/api/v2/freelancer/all', data).pipe(map((resp) => resp));
  }

  isEmailAvail(email: string) {
    const URL = this.baseUrlV2 + '/email-exist';
    const option = {email: email};
    return this._http.post(URL, option);
  }

  updateStatus(id: any, status: any) {
    const url  = this.baseUrlV2 + id + '/status/'  + status;
    return this._http.get(url);
  }

  updateOnlineStatus(id: any, status: number) {
    const url  = this.baseUrlV2 + id + '/online-status/' + status;
    return this._http.post<any>(url, null).pipe(map(resp => resp));
  }
  
  unblockUser(data) {
    return this._http.get(`${this.baseUrlV2}${data.id}/status/${data.status}`);
  }

  getAllFreelancer(options: any): Observable<Page<Freelancer>> {
    return this._http.post<Page<Freelancer>>(`${this.baseUrlV2}all-freelancer`, options).pipe(map(resp => resp));
  }

  saveDocumentCount(body) {
      return this._http.put<any>(this.baseUrlV2 + 'update-document-count/' , body ).pipe(map(res => res));
  }

  validatePin(employeeId, pin): Observable<ValidatePin> {
    const url = `${this.baseUrlV2}/${employeeId}/validate_pin`;
    // const url = `http://localhost:8013/api/v2/freelancer/${employeeId}/validate_pin`;
    return this._http.post<ValidatePin>(url, {
      pin: pin
    });
  }

  unlockPin(employeeId): Observable<ValidatePin> {
    const url = `${this.baseUrlV2}/${employeeId}/unlock`;
    // const url = `http://localhost:8013/api/v2/freelancer/${employeeId}/unlock`;
    return this._http.post<ValidatePin>(url, {});
  }

  addPreferredZone(freelancerId: number, zoneId: number): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}preferred-zone/${freelancerId}/${zoneId}`, {}).pipe(map(resp => resp));
  }

  removePreferredZone(freelancerId: number, zoneId: number): Observable<any> {
    return this._http.delete<any>(`${this.baseUrlV2}preferred-zone/${freelancerId}/${zoneId}`).pipe(map(resp => resp));
  }

    getContractHistoryData(data) {
        return this._http.post(`${this.baseUrlV2}contract/history`, data).pipe(map(resp => resp));
    }

  getByGithubId(githubId): Observable<any> {
      return this._http.get<any>(`${this.baseUrlV2}find-username/${githubId}`).map(resp => resp);
  }
}
