import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ReloadService  {
    // Subject dùng để phát tín hiệu reload
    private reloadTopbarSubject = new Subject<void>();

    // Observable để lắng nghe tín hiệu reload
    reloadTopbar$ = this.reloadTopbarSubject.asObservable();

    // Phát tín hiệu reload khi được gọi
    triggerReloadTopbar() {
        this.reloadTopbarSubject.next(); // Phát tín hiệu reload cho TopBar
    }
}
