import {Component, OnInit, ViewChild} from '@angular/core';
import {MessageService, OverlayPanel} from 'primeng';
import {Message} from 'aws-sdk/clients/cloudwatch';
import {AuthService} from '../../../shared/service/auth/auth.service';
import {CompanyService} from '../../../company/service/company.service';
import {DropDownsService} from '../../../shared/service/drop-downs/drop-downs.service';
import {PaymentInvoiceService} from '../../../payment/service/payment-invoice.service';
import {NavigationEnd, Router} from '@angular/router';
import {MonitoringDetailsService} from '../../../admin/process-monitor/service/monitoring-details.service';
import moment from 'moment';
import {FileUtility} from '../../../shared/utility/file.utility';
import * as _ from 'lodash';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ExportMonitorService } from 'app/admin/process-monitor/service/export-monitor.service';


@Component({
  selector: 'app-receivable-aging-details',
  templateUrl: './receivable-aging-details.component.html',
  styleUrls: ['./receivable-aging-details.component.scss']
})
export class ReceivableAgingDetailsComponent implements OnInit {

  @ViewChild('op3', { static: true }) op3: OverlayPanel;

  isPlatformAdmin = false;
  isClientRole = false;
  allCompanyList: any[] = [];
  selectedCompany: any;
  allClientList: any[] = [];
  clientList: any[] = [];
  selectedClient: any;
  fromDate: Date;
  toDate: Date;
  loading = false;
  statementBalanceList = [];
  totalBalanceStatistics = 0;
  size: number;
  page: number;
  rows = 10;
  sortField: string;
  sortOrder: number = 0;
  totalRecords = 0;
  cols = [
    { field: 'invoiceDate', label: 'Date', sortOptions: '', sort: 'number' },
    { field: 'paymentMethod', label: 'Transaction', sortOptions: '', sort: 'text' },
    { field: 'invoiceNumber', label: 'Invoice', sortOptions: '', sort: 'text' },
    { field: 'clientName', label: 'Customer', sortOptions: '', sort: 'text' },
    { field: 'dueDate', label: 'Due', sortOptions: '', sort: 'number' },
    { field: 'amount', label: 'Amount', sortOptions: '', sort: 'number' },
    { field: 'balance', label: 'Open Balance', sortOptions: '', sort: 'number' }
  ];
  statisticsCols = [
    { field: 'first30', label: '0 - 30' },
    { field: 'first60', label: '31 - 60' },
    { field: 'first90', label: '61 - 90' },
    { field: 'first120', label: '91 - 120' },
  ];
  exportOptions = [
    { name: 'View Details', value: 'view' },
    { name: 'Download Invoice', value: 'download' },
  ];
  invoiceRef: any = {};
  exportAs: any;
  timespent = new Date();
  employeeList: any = [];
  msgs: Message[] = [];
  companyInfo;
  selectedStatuses;
  statusTypes: any[] = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Unpaid', value: 'Unpaid' },
    { label: 'Partially Paid', value: 'Partial Paid' },
    { label: 'Paid', value: 'Paid' }
  ];
  textSortOptions = [
    { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
    { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
  ];
  numberSortOptions = [
    { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  dateSortOptions = [
    { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];

  selectedSortOption = '';
  selectedField = '';
  rowGroupMetadata: any;
  selectedToShow: String = 'ALL';
  storeExpandedStatus: any = [];
  showSelectedList: any[] = [
    { label: 'All', value: 'ALL' },
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Non-zero', value: 'NONZERO' }
  ];

  routerEventsSubscribe;

  constructor(private authService: AuthService,
              private companyService: CompanyService,
              private dropDownsService: DropDownsService,
              private paymentInvoiceService: PaymentInvoiceService,
              private router: Router,
              private exportMonitorService: ExportMonitorService,
              private messageService: MessageService,
              private monitoringDetailsService: MonitoringDetailsService) {
    this.routerEventsSubscribe = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('app/receivable-aging-details/list')) {
          this.monitoringDetailsService.monitorAction(
              `Navigated to Accounts Receivable Aging Details`,
              this.timespent,
              {
                navigated_to_accounts_receivable_aging_details_by: this.authService.getCurrentLoggedInName()
              },
              'complete',
              `Navigated to Accounts Receivable Aging Details`,
              0
          );
        }
      }
    });
  }

  ngOnInit(): void {
    this.isClientRole = this.authService.isClientRole();
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();

    if (this.isPlatformAdmin) {
      this.loadAllCompanies();
    } else {
      this.selectedCompany = this.authService.getCurrentCompanyId();
      this.companyService.getCompanyByCompanyId(this.selectedCompany).subscribe(res => {
        this.companyInfo = res.data;
      });
    }
    this.loadAllClient();
    this.search();
  }

  ngOnDestroy() {
    if (this.routerEventsSubscribe) {
      this.routerEventsSubscribe.unsubscribe();
    }
  }

  loadAllCompanies() {
    this.dropDownsService.getAllCompanyList().subscribe((res) => {
      const resObj: any = res;
      this.allCompanyList = [];
      if (resObj.status === 'SUCCESS') {
        this.allCompanyList = _.sortBy(resObj.data, 'value');
      }
    });
  }

  loadAllClient() {
    let companyId;
    if (this.isPlatformAdmin)
      companyId = this.selectedCompany;
    else
      companyId = this.authService.getCurrentCompanyId();

    const options: any = {
      size: 99999,
      page: 0,
      moduleName: 'billing',
      companyId: companyId || 0,
      // status: 1,
    };

    this.dropDownsService.getAllClientList(options).subscribe((res) => {
      this.clientList = [];
      this.allClientList = [];
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        const data = resObj.data.sort((a, b) => a.value.localeCompare(b.value));
        this.allClientList = data.map(c => ({ value: c.key, label: c.value, email: c.email }));
        this.clientList = data;
      }
    });
  }

  changeCompany() {
    if (this.selectedCompany) {
      this.loadAllClient();
    }
  }

  changeClient() {
    this.search();
  }

  search(event?: any) {
    this.monitoringDetailsService.monitorAction(
        `Searched Accounts Receivable Aging Details`,
        this.timespent,
        {
          search_to_accounts_receivable_aging_details_by: this.authService.getCurrentLoggedInName()
        },
        'complete',
        `Searched Accounts Receivable Aging Details`,
        0
    );
    const options = this.buildOptions(event, 0, 99999);
    this.loadPage(options);
  }

  buildOptions(event?: any, page?, size?) {
    this.size = event ? event.rows : this.size ? this.size : 10;
    this.page = event && event.first >= 0 && event.rows > 0 ? event.first / event.rows : 0;

    const options = <any>{
      page: page ? page : this.page,
      size: size ? size : this.size
    };

    this.sortField = this.sortField ? this.sortField : event && event.sortField ? event.sortField : 'id';
    this.sortOrder = this.sortOrder ? this.sortOrder : event && event.sortOrder ? event.sortOrder : 0;

    if (this.sortField === 'id') {
      this.sortOrder = 0;
    }

    options.sortField = this.sortField;
    options.sortOrder = this.sortOrder === 1 ? 'ASC' : 'DESC';

    if (this.isPlatformAdmin) {
      options.companyId = this.selectedCompany;
    } else {
      options.companyId = this.authService.getCurrentCompany();
    }

    if (this.selectedClient != null) {
      options.clientId = this.selectedClient.key;
    }

    if (this.fromDate) {
      options.fromDate = moment(this.fromDate).utc(true).startOf('day').toDate();
      options.startDate = options.fromDate;
    }

    if (this.toDate) {
      options.toDate = moment(this.toDate).utc(true).endOf('day').toDate();
      options.endDate = options.toDate;
    }

    if (this.selectedStatuses && this.selectedStatuses.length > 0 && this.selectedStatuses.length !== this.statusTypes.length) {
      options.listStatus = this.selectedStatuses;
    } else {
      options.listStatus = null;
    }

    options.oldInvoiceOnly = false;
    options.ignoreInvoiceDetails = true;

    return options;
  }

  loadPage(options) {
    this.loading = true;
    this.paymentInvoiceService.filter(options).subscribe(res => {
      const statistics: any = {
        first30: {
          amount: 0,
          balance: 0,
          section: '2'
        },
        first60: {
          amount: 0,
          balance: 0,
          section: '4'
        },
        first90: {
          amount: 0,
          balance: 0,
          section: '6'
        },
        first120: {
          amount: 0,
          balance: 0,
          section: '8'
        },
        last120: {
          amount: 0,
          balance: 0,
          section: '10'
        },
        total: {
          amount: 0,
          balance: 0,
          section: '11'
        }
      };

      const section1List: any[] = [];
      const section2List: any[] = [];
      const section3List: any[] = [];
      const section4List: any[] = [];
      const section5List: any[] = [];

      this.loading = false;
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {

        const data = resObj.data.content;

        for (const invoice of data) {

          if (invoice.status === 'Paid') {
            continue;
          }

          const company = this.allCompanyList.find(com => com.value === invoice.companyId);
          if (company) {
            invoice.companyName = company.label;
          }

          const client = this.allClientList.find(com => com.value === invoice.clientId);
          if (client) {
            invoice.clientName = client.label;
          }

          const invoiceDate = new Date(invoice.invoiceDate);
          invoiceDate.setMinutes(invoiceDate.getMinutes() + invoiceDate.getTimezoneOffset());
          invoice.invoiceDate = invoiceDate;

          if (invoice.paymentMethod === 'cash') {
            invoice.paymentMethod = 'Cash';
          }

          if (invoice.paymentMethod === 'credit/Debit') {
            invoice.paymentMethod = 'Credit/Debit';
          }

          if (invoice.paymentMethod === 'zelle') {
            invoice.paymentMethod = 'Zelle';
          }

          if (invoice.paymentMethod === 'check') {
            invoice.paymentMethod = 'Check';
          }

          if (invoice.paymentMethod === 'other') {
            invoice.paymentMethod = 'Other';
          }

          const diff = moment().diff(moment(invoice.invoiceDate), 'd');

          if (invoice.totalFee) {
            invoice.feeTxt = invoice.totalFee.toFixed(2);
          } else {
            invoice.feeTxt = '0.00';
          }

          if (invoice.paidFee) {
            invoice.paidFeeTxt = invoice.paidFee.toFixed(2);
          } else {
            invoice.paidFeeTxt = '0.00';
          }

          const length = invoice.followUpBillingInvoices ? invoice.followUpBillingInvoices.length : 0;
          if (length > 0) {
            invoice.followUpNoteDate = invoice.followUpBillingInvoices[length - 1].createdAt;
            invoice.numOfFollowUpNote = length;
          }

          if (diff >= 0) {
            invoice.balanceTxt = (invoice.balance || 0).toFixed(2);
            invoice.totalAmountTxt = (invoice.totalFee || 0).toFixed(2);
            if (diff <= 30) {
              invoice.section = '1';
              if (this.checkInvoice(invoice)) {
                statistics.first30.amount += (invoice.totalFee || 0);
                statistics.first30.balance += (invoice.balance || 0);
                section1List.push(invoice);
              }
            } else if (diff <= 60) {
              invoice.section = '3';
              if (this.checkInvoice(invoice)) {
                statistics.first60.amount += (invoice.totalFee || 0);
                statistics.first60.balance += (invoice.balance || 0);
                section2List.push(invoice);
              }
            } else if (diff <= 90) {
              invoice.section = '5';
              if (this.checkInvoice(invoice)) {
                statistics.first90.amount += (invoice.totalFee || 0);
                statistics.first90.balance += (invoice.balance || 0);
                section3List.push(invoice);
              }
            } else if (diff <= 120) {
              invoice.section = '7';
              if (this.checkInvoice(invoice)) {
                statistics.first120.amount += (invoice.totalFee || 0);
                statistics.first120.balance += (invoice.balance || 0);
                section4List.push(invoice);
              }
            } else {
              invoice.section = '9';
              if (this.checkInvoice(invoice)) {
                statistics.last120.amount += (invoice.totalFee || 0);
                statistics.last120.balance += (invoice.balance || 0);
                section5List.push(invoice);
              }
            }
          }
        }

        statistics.total.amount = statistics.first30.amount + statistics.first60.amount + statistics.first90.amount + statistics.first120.amount + statistics.last120.amount;
        statistics.total.balance = statistics.first30.balance + statistics.first60.balance + statistics.first90.balance + statistics.first120.balance + statistics.last120.balance;

        if (section1List.length > 0 && (statistics.first30.amount > 0 || statistics.first30.balance > 0)) {
          section1List.push(statistics.first30);
        }
        if (section2List.length > 0 && (statistics.first60.amount > 0 || statistics.first60.balance > 0)) {
          section2List.push(statistics.first60);
        }
        if (section3List.length > 0 && (statistics.first90.amount > 0 || statistics.first90.balance > 0)) {
          section3List.push(statistics.first90);
        }
        if (section4List.length > 0 && (statistics.first120.amount > 0 || statistics.first120.balance > 0)) {
          section4List.push(statistics.first120);
        }
        if (section5List.length > 0 && (statistics.last120.amount > 0 || statistics.last120.balance > 0)) {
          section5List.push(statistics.last120);
        }
        if (section1List.length > 0 || section2List.length > 0 || section3List.length > 0 || section4List.length > 0 || section5List.length > 0) {
          section5List.push(statistics.total);
        }
        this.statementBalanceList = section1List.concat(section2List).concat(section3List).concat(section4List).concat(section5List);
        this.totalRecords = this.selectedToShow === 'ALL' ? resObj.data.totalElements : this.statementBalanceList.filter(i => !i.amount).length;

        this.updateRowGroupMetaData();
      } else {
        this.statementBalanceList = [];
        this.totalRecords = 0;
      }
    }, () => {
      this.loading = false;
      this.statementBalanceList = [];
      this.totalRecords = 0;
    });
  }

  reset() {
    if (this.isPlatformAdmin) {
      this.selectedCompany = null;
    }
    this.selectedClient = null;
    this.selectedStatuses = null;
    this.fromDate = null;
    this.toDate = null;
    this.sortField = null;
    this.sortOrder = null;
    this.page = 0;
    this.size = 99999;
    this.selectedToShow = 'ALL';
    this.search();
  }

  formatDate(date: any) {
    if (date) {
      return moment.utc(moment(date).format('MM/DD/YYYY')).local().format('MM/DD/YYYY');
    }
    return null;
  }

  clickInvoiceRef(event: any, rowData) {
    this.op3.toggle(event);
    this.invoiceRef = rowData;
  }

  invoiceRefAction(type: string) {
    switch (type) {
      case 'view': this.exportPdf(this.invoiceRef);
        break;
      case 'download': this.downloadInvoiceRefPdf();
        break;
    }
  }

  exportPdf(invoice) {
    this.op3.hide();
    if (invoice.pdfLink) {
      window.open(invoice.pdfLink, '_blank');
    } else {
      this.paymentInvoiceService.exportPdf(invoice.id).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
            let data = <any>{
                featureName: 'Receivable Aging Detail',
                fileName: blobUrl,
                fileSize: blob.size,
                action: 'Export',
                createdByUsr: this.authService.getCurrentUsername(),
                companyId: this.authService.getCurrentCompanyId()
            };
            this.exportMonitorService.create(data).subscribe(()=>{
            });
        }
      });
    }
  }

  downloadInvoiceRefPdf() {
    this.op3.hide();
    this.paymentInvoiceService.exportPdfWithName(this.invoiceRef.id).subscribe(res => {
      if (res.data) {
        const blob = FileUtility.b64toBlob(res.data.blob.body, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.target = '_blank';
        let fileName = 'Dummy.pdf';
        if (res.data.fileName && res.data.fileName.length > 0) {
          fileName = res.data.fileName;
        }
        a.download = fileName;
        a.click();
        const args = {
          ticket_invoice_download_by: this.authService.getCurrentLoggedInName(),
          Ticket_id: this.invoiceRef.invoiceId,
        };
          let data = <any>{
              featureName: 'Receivable Aging Invoice',
              fileName: blobUrl,
              fileSize: blob.size,
              action: 'Download',
              createdByUsr: this.authService.getCurrentUsername(),
              companyId: this.authService.getCurrentCompanyId()
          };
          this.exportMonitorService.create(data).subscribe(()=>{
          });
      }
    });
  }

  sortDataSelected(selectedSortOption) {
    this.sortField = this.selectedField;
    this.sortOrder = selectedSortOption === 'ASC' ? 1 : 0;
    this.search();
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    if (this.statementBalanceList) {
      for (let i = 0; i < this.statementBalanceList.length; i++) {
        let rowData = this.statementBalanceList[i];
        let section = rowData.section;
        if (i == 0) {
          this.rowGroupMetadata[section] = { index: 0, size: 1 };
        }
        else {
          let previousRowData = this.statementBalanceList[i - 1];
          let previousRowGroup = previousRowData.section;
          if (section === previousRowGroup)
            this.rowGroupMetadata[section].size++;
          else
            this.rowGroupMetadata[section] = { index: i, size: 1 };
        }
      }
    }
  }

  exportTable() {
    const doc = this.buildPdf();
    let output = doc.output('bloburl');
    window.open(output.toString(), '_blank');
  }

  buildPdf(): jsPDF {
    const pdfBody: any = this.getPdfBody();
    const doc = new jsPDF();
    autoTable(doc, {
      margin: 0,
      head: [
        [
          {
            content: 'Date',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 25
            }
          },
          {
            content: 'Transaction Type',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 30
            }
          },
          {
            content: 'Num',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 25
            }
          },
          {
            content: 'Customer',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 40
            }
          },
          {
            content: 'Due Date',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 25
            }
          },
          {
            content: 'Amount',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 20
            }
          },
          {
            content: 'Open Balance',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 20
            }
          }
        ],
      ],
      theme: 'striped',
      columnStyles: {
        0: {cellWidth: 25},
        1: {cellWidth: 30},
        2: {cellWidth: 25},
        3: {cellWidth: 40},
        4: {cellWidth: 25},
        5: {cellWidth: 20},
        6: {cellWidth: 20},
      },
      styles: {
        lineWidth: 0.5,
      },
      bodyStyles: {
        fillColor: 255
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245]
      },
      body: pdfBody,
    });
    return doc;
  }

  getPdfBody() {
    const cloneList = this.statementBalanceList.map(x => Object.assign({}, x));

    const indexSection1 = cloneList.findIndex(t => t.section === '1');
    if (indexSection1 >= 0) {
      cloneList.splice(indexSection1, 0, {subheader: '1 - 30 days past due'});
    }

    const indexSection3 = cloneList.findIndex(t => t.section === '3');
    if (indexSection3 >= 0) {
      cloneList.splice(indexSection3, 0, {subheader: '31 - 60 days past due'});
    }

    const indexSection5 = cloneList.findIndex(t => t.section === '5');
    if (indexSection5 >= 0) {
      cloneList.splice(indexSection5, 0, {subheader: '61 - 90 days past due'});
    }

    const indexSection7 = cloneList.findIndex(t => t.section === '7');
    if (indexSection7 >= 0) {
      cloneList.splice(indexSection7, 0, {subheader: '91 - 120 days past due'});
    }

    const indexSection9 = cloneList.findIndex(t => t.section === '9');
    if (indexSection9 >= 0) {
      cloneList.splice(indexSection9, 0, {subheader: '120+ days past due'});
    }

    return cloneList.filter(c => {
      if (c.amount >= 0 || c.subheader) {
        return true
      }
        return  this.statementBalanceList.find(e => e.section === c.section);
      //return rowExpanded
      //return !!(rowExpanded && rowExpanded.expanded);
    }).map(row => {
      if (row.subheader) {
        return [
          {
            content: row.subheader,
            colSpan: 7,
            styles: {
              halign: 'left',
              fontSize: 11,
              cellPadding: 1,
              textColor: '#0d69fc'
            }
          }
        ]
      }

      if (row.amount >= 0) {
        let footerContent;
        if (row.section === '2') {
          footerContent = 'Total 1 - 30 days past due';
        } else if (row.section === '4') {
          footerContent = 'Total 31 - 60 days past due';
        } else if (row.section === '6') {
          footerContent = 'Total 61 - 90 days past due';
        } else if (row.section === '8') {
          footerContent = 'Total 91 - 120 days past due';
        } else if (row.section === '10') {
          footerContent = 'Total 120+ days past due';
        } else {
          footerContent = 'Total';
        }

        return [
          {
            content: footerContent,
            colSpan: 5,
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellPadding: 1
            }
          },
          {
            content: '$' + (row.amount || 0).toFixed(2),
            colSpan: 1,
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellPadding: 1
            }
          },
          {
            content: '$' + (row.balance || 0).toFixed(2),
            colSpan: 1,
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellPadding: 1
            }
          }
        ]
      }

      return [
        {
          content: this.formatDate(row.invoiceDate),
          styles: {
            halign: 'left'
          }
        },
        {
          content: (row.paymentMethod || ''),
          styles: {
            halign: 'left'
          }
        },
        {
          content: (row.invoiceNumber || ''),
          styles: {
            halign: 'left'
          }
        },
        {
          content: (row.clientName || ''),
          styles: {
            halign: 'left'
          }
        },
        {
          content: moment(row.dueDate).format('MM/DD/YYYY'),
          styles: {
            halign: 'left'
          }
        },
        {
          content: '$' + (row.totalFee || 0).toFixed(2),
          styles: {
            halign: 'left'
          }
        },
        {
          content: '$' + (row.balance || 0).toFixed(2),
          styles: {
            halign: 'left'
          }
        }
      ]
    });
  }

  expandedList(expanded, section) {
    const item = this.storeExpandedStatus.find(e => e.section === section);
    if (item) {
      item.expanded = !expanded;
    } else {
      this.storeExpandedStatus.push({section, expanded: !expanded});
    }
  }

  private checkInvoice(invoice) {
    if (this.selectedToShow !== 'ALL') {
      if (this.selectedToShow === 'NONZERO') {
        return (invoice.totalFee > 0 || invoice.balance > 0);
      } else {
        return (invoice.totalFee > 0 || invoice.balance > 0) && invoice.status !== 'Paid';
      }
    }
    return true;
  }
}
