import { RouterModule, Routes } from '@angular/router';
import { PaymentMethodComponent } from './component/payment-method/payment-method.component';
import { AuthGuard } from '../authenticate/auth.guard';
import { PaymentLayoutComponent } from './component/payment-layout/payment-layout.component';
import { PaymentDetailsComponent } from './component/payment-details/payment-details.component';
import { PaymentInvoiceListComponent } from './component/payment-invoice-list/payment-invoice-list.component';
import { PaymentOldInvoiceListComponent } from './component/payment-old-invoice-list/payment-old-invoice-list.component';
import { PaymentFollowUpNoteListComponent } from './component/payment-follow-up-note-list/payment-follow-up-note.component';
import { PaymentAvailableCreditListComponent } from './component/payment-available-credit-list/payment-available-credit.component';
import { PaymentDetailsListComponent } from "./component/payment-details-list/payment-details-list.component";
import { ViewPaymentMethodListComponent } from './component/view-payment-method-list/view-payment-method-list.component';
import { WalletComponent } from './component/wallet/wallet.component';
import { WalletTransactionComponent } from './component/wallet-transaction/wallet-transaction.component'
import { PaymentAvailabilityComponent } from './component/payment-availability/payment-availability.component';
import { ClientSelfPaymentComponent } from './component/client-self-payment/client-self-payment.component';
import { InvoiceVerificationComponent } from './component/invoice-verification/invoice-verification.component';
import { InvoiceSummaryComponent } from './component/invoice-summary/invoice-summary.component';
import { NgModule } from '@angular/core';
import { PermissionGuard } from 'app/authenticate/permission.guard';
import { MENU_ID } from 'app/shared/data/menu-id';

export const routes: Routes = [
    {
        path: '',
        component: PaymentLayoutComponent,
        children: [
            { path: '', redirectTo: 'method', pathMatch: 'full' },
            {
                path: 'method',
                component: PaymentMethodComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.PROCESS_PAYMENT], canEdit: [] }
            },
            {
                path: 'detail/list',
                component: PaymentDetailsListComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.PAYMENTS, MENU_ID.PHONE_PAYMENT], canEdit: [] }
            },
            {
                path: 'view-payment-method/list',
                component: ViewPaymentMethodListComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.VIEW_PAYMENT_METHOD], canEdit: [] }
            },
            {
                path: 'detail',
                component: PaymentDetailsComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.PROCESS_PAYMENT, MENU_ID.PHONE_PAYMENT], canEdit: [] }
            },
            {
                path: 'invoice',
                component: PaymentInvoiceListComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.INVOICE], canEdit: [] }
            },
            {
                path: 'old-invoice',
                component: PaymentOldInvoiceListComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.OLD_INVOICE], canEdit: [] }
            },
            {
                path: 'follow-up-note',
                component: PaymentFollowUpNoteListComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.FOLLOW_UPS], canEdit: [] }
            },
            {
                path: 'follow-up-note/:id',
                component: PaymentFollowUpNoteListComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.FOLLOW_UPS], canEdit: [] }
            },
            {
                path: 'available-credit',
                component: PaymentAvailableCreditListComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.CLIENT_CREDITS], canEdit: [] }
            },
            {
                path: 'wallet',
                component: WalletComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.WALLET], canEdit: [] }
            },
            {
                path: 'wallet-transaction',
                component: WalletTransactionComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.WALLET_TRANSACTION], canEdit: [] }
            },
            {
                path: 'payment-availability',
                component: PaymentAvailabilityComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.PAYMENT_AVAILABILITY], canEdit: [] }
            },
            {
                path: 'client-self-payment',
                component: ClientSelfPaymentComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.CLIENT_SELF_PAYMENT], canEdit: [] }
            },
            {
                path: 'invoice-verification',
                component: InvoiceVerificationComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.INVOICE_VERIFICATION], canEdit: [] }
            },
            {
                path: 'invoice-summary',
                component: InvoiceSummaryComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.INVOICE_SUMMARY], canEdit: [] }
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PaymentRoutingModule { }
