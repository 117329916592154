import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PlatformAdminService } from 'app/admin/platform-admin/service/platform-admin.service';
import { EmployeeService } from 'app/employee/service/employee.service';
import { AuthService } from 'app/shared/service/auth/auth.service';
import { Message, MessageService } from 'primeng/api';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { EmployeeJobReportService } from 'app/employee/service/employee-job-report.service';
import { DropDownsService } from 'app/shared/service/drop-downs/drop-downs.service';
import { EmployeeJobReport } from 'app/employee/model/employee-job-report.model';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { DocumentsService } from 'app/shared/service/documents-upload/documents.service';
import { Editor, OverlayPanel } from 'primeng';
import { EmployeeClockingServiceV2 } from 'app/employee-clocking/service/v2/employee.clocking.v2.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CompanyService } from 'app/company/service/company.service';
import { EmployeeJobReportNoteService } from 'app/employee/service/employee-job-report-note.service';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import { Table } from 'primeng/table';
import { ExportMonitorService } from 'app/admin/process-monitor/service/export-monitor.service';
import { map } from 'rxjs/operators';
import { TranslatePipe } from '@ngx-translate/core';
import { notoSansTC } from 'app/employee/noto-sans-tc';
import * as MOMENT from 'moment';

interface UserInfo {
    userId: number;
    profileImageId?: string;
    profileImageUrl?: string;
    avatarUrl?: string;  // Add this line
}

@Component({
    selector: 'app-employee-job-report',
    templateUrl: './employee-job-report.component.html',
    styleUrls: ['./employee-job-report.component.scss'],
    providers: [AuthService, EmployeeService, EmployeeJobReportService, EmployeeClockingServiceV2, CompanyService, EmployeeJobReportNoteService]
})
export class EmployeeJobReportComponent implements OnInit, AfterViewInit {
    msgs: Message[] = [];
    isPlatformAdmin: Boolean = false;
    allCompanyList = [];
    companyId: number;
    employees = [];
    employeeFullName: any = '';
    selectedEmployee: any;
    dataSourceSubject = new BehaviorSubject(null);
    dataSource$ = this.dataSourceSubject.asObservable();
    sortField: string;
    sortOrder: number;
    fromDate: Date;
    toDate: Date;
    processCols: any[] = [
        { field: 'fullName', label: 'Worker', sortOptions: '', sort: 'text' },
        { field: 'reportDate', label: 'Date', sortOptions: '', sort: 'number' },
        { field: 'documentId', label: 'Attachment', sortOptions: '150', sort: 'text' },
        { field: 'createdAt', label: 'Added', sortOptions: '', sort: 'number' },
        { field: 'updatedAt', label: 'Updated', sortOptions: '', sort: 'number' },
        { field: 'reportSynthetic', label: 'Finished #/Total #', sortOptions: '', sort: 'text' },
        { field: 'location', label: 'Location', sortOptions: '', sort: '' },
        { field: 'jobReportNotes', label: 'Progress Updates', sortOptions: '', sort: 'text', width: '45px !important' },
        { field: 'actions', label: '' },
    ];
    loading = true;
    size: number;
    page: number;
    textSortOptionsClient = [
        { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
        { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
    ];
    numberSortOptionsClient = [
        { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
        { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
    ];

    selectedSortOption = 'DESC';
    selectedFileType = '';
    selectedField = 'createdAt';
    selectedRecord: EmployeeJobReport;
    showViewFileDialog = false;
    @ViewChild('upload') uploadFileRef: ElementRef;
    fileUrl: string;
    isEmployeeRole = false;
    historyFilter: any = {
        employees: [],
        employeeIds: [],
        fromDate: null,
        toDate: null,
        ids: [],
        page: 0,
        size: 10,
        key: '',
        lateEarlier: null,
        isMissing: false,
        withNoOvertime: false,
        type: 1
    };

    companyInfo;
    private _routerSub = Subscription.EMPTY;
    timeSpent = new Date();
    viewMapDialog = false;
    jobLocation = null;
    textSearch: any;
    showProgressBarForNote = false;
    notesDetailArray: any[] = [];
    @ViewChild('detailsEditor', { static: true })
    detailsEditor: Editor;
    disableAdd = false;
    @ViewChild('dt') table: Table;

    userIdMap: { [username: string]: UserInfo } = {};
    companyFileUrl: any;
    companyLogo: any;

    constructor(
        private authService: AuthService,
        private employeeService: EmployeeService,
        private platformAdminService: PlatformAdminService,
        private exportMonitorService: ExportMonitorService,
        private employeeJobReportService: EmployeeJobReportService,
        private dropDownsService: DropDownsService,
        private router: Router,
        private documentsService: DocumentsService,
        private employeeClockingService: EmployeeClockingServiceV2,
        private companyService: CompanyService,
        private employeeJobReportNoteService: EmployeeJobReportNoteService,
        private monitoringDetailsService: MonitoringDetailsService,
        private documentService: DocumentsService,
        private messageService: MessageService,
        private translatePipe: TranslatePipe,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute
    ) {
        this.monitoringDetailsService.monitorAction(
            `Navigated to Job Report List`,
            this.timeSpent,
            {
                navigated_to_employee_job_report_list_page_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
            },
            'complete',
            `Navigated to Job Report List`,
            0
        );
        this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        console.log(this.isPlatformAdmin);
        this.isEmployeeRole = this.authService.isEmployeeRole();
        if (this.isEmployeeRole) {
            this.employeeFullName = this.authService.getCurrentUsername();
        }
    }

    ngOnInit() {
        this.loadAllCompanyList();
        this.companyId = !this.isPlatformAdmin ? this.authService.getCurrentCompanyId() : null;
        if (!this.isPlatformAdmin) {
            this.companyService.getCompanyByCompanyId(this.companyId).subscribe((res: any) => {
                this.companyInfo = res.data;
            });
        }
        if (this.isEmployeeRole) {
            let options = { companyId: this.companyId };
            this.employeeFullName = this.authService.getCurrentUsername();
            this.employeeService.getAllsDropdown(options).subscribe(resEmp => {
                const empObj: any = resEmp;
                const tempList: any[] = [];
                if (empObj.status === 'SUCCESS') {
                    empObj.data.forEach(element => {
                        tempList.push({ label: element.fullName, value: element.value, id: element.key, username: element.value });
                    });
                }
                this.employees = tempList;

                this.selectedEmployee = this.employees.find(project => project.value?.toLowerCase() === this.authService.getCurrentUsername()?.toLowerCase());
                this.employeeFullName = this.employees.find(project => project.value?.toLowerCase() === this.authService.getCurrentUsername()?.toLowerCase())?.value;

                setTimeout(() => {
                    if (this.route.snapshot.queryParams.employee) {
                        this.employeeFullName = this.route.snapshot.queryParams.employee;
                    }
                }, 200);
            });
        } else {
            this.loadPageEmployee({ companyId: this.companyId }, true);
            if (this.route.snapshot.queryParams.employee) {
                this.selectedEmployee = { username: this.route.snapshot.queryParams.employee };
            }
        }
        this.search();
        this.fetchUserIdMap();
    }

    fetchUserIdMap(): Observable<void> {
        const companyId = this.authService.getCurrentCompanyId();
        const page = 0;
        const size = 100;  // Adjust as necessary for your data

        // console.log("Fetching user activities with companyId:", companyId);

        return new Observable<void>((observer) => {
            this.authService.getLatestUserActivities(companyId, page, size).subscribe(res => {
                const activities = res.data?.content || [];
                // console.log("User Activities API Response:", activities);

                const userFetches = activities.map(activity => {
                    const username = activity.username?.toLowerCase().trim();
                    const userId = activity.userId;
                    const profilePhoto = activity.profilePhoto;

                    // console.log(`Processing user activity for username: ${username}, userId: ${userId}, profilePhoto: ${profilePhoto}`);

                    if (username && userId) {
                        return this.employeeService.getEmployeeByIdCustom(userId).pipe(
                            map(employeeRes => {
                                const employee = Array.isArray(employeeRes) ? employeeRes[0] : employeeRes;
                                // console.log("Employee data fetched:", employee);

                                const profileImageId = employee.profileImageId || profilePhoto;
                                const profileImageUrl = employee.profile?.fileUrl;

                                this.userIdMap[username] = {
                                    userId,
                                    profileImageId,
                                    profileImageUrl: profileImageUrl || 'assets/images/logo.png'
                                };

                                // console.log(`Updated userIdMap for username ${username}:`, this.userIdMap[username]);
                            })
                        );
                    }
                    return of(null);  // Skip if username/userId is missing
                });

                // Wait until all user fetches complete
                forkJoin(userFetches).subscribe(() => {
                    observer.next();
                    observer.complete();
                });
            }, error => {
                console.error('Error fetching user activities:', error);
                observer.error(error);
            });
        });
    }


    ngAfterViewInit() {
        /*this.notesDetailArray = [];
        this.fetchUserIdMap().subscribe(() => {
            this.loadDataSource();  // Ensure `userIdMap` is populated before loading data
        });*/
    }


    loadAllCompanyList() {
        const pafc = this;
        this.dropDownsService.getAllCompanyList().subscribe((res) => {
            const resObj: any = res;
            this.allCompanyList = [];
            if (resObj.status === 'SUCCESS') {
                resObj.data.forEach(function (company) {
                    pafc.allCompanyList.push({ label: company.value, value: company.key });
                });
            }
        });
    }

    disableCompany(): boolean {
        return !(this.authService.adminRole());
    }

    changeCompany(event) {
        this.companyId = event.value;
        this.loadPageEmployee({ companyId: event.value });
    }

    loadPageEmployee(options, isFirst?) {
        this.employees = [];
        options.status = 1;
        this.employeeService.getEmployeeListByCompanyId(options.companyId, options.status).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                if (resObj.data && resObj.data.length > 0) {
                    resObj.data.forEach((item) => {
                        this.employees.push({ label: `${item.fullName} (${item.value})`, value: item.value, id: item.key, username: item.value });
                    });
                    if (isFirst) {
                        setTimeout(() => {
                            if (this.route.snapshot.queryParams.employee) {
                                this.employeeFullName = this.route.snapshot.queryParams.employee;
                            }
                        }, 200);
                    }
                }
            }
            console.log(this.employees);
        });
        this.platformAdminService.getAdmins(options.companyId || 0).subscribe((e: any) => {
            if (e.status === 'SUCCESS') {
                if (e.data && e.data.length > 0) {
                    e.data.forEach((item) => {
                        this.employees.push({ label: `${item.fullName} (${item.username})`, value: item.username, id: item.id, username: item.username });
                    });
                }
            }
        });
    }

    resetFilter() {
        this.fromDate = null;
        this.toDate = null;
        console.log(this.isEmployeeRole);
        if (!this.isEmployeeRole) {
            this.employeeFullName = null;
        }
        this.textSearch = null;
        this.loadDataSource();
    }

    search() {
        if (this.table) {
            this.table.first = 0;
        }
        this.loadDataSource();
    }

    loadDataSource(event?: any) {
        this.size = event?.rows || 10;
        this.page = (event?.first || 0) / this.size;

        const options: any = {};
        if (event && event.sortField && event.isSortClick) {
            const sortOrder = <any>event['sortOrder'];
            const sortField = event['sortField'];
            options.sortField = sortField;
            options.sortOrder = sortOrder === 'ASC' || sortOrder === 1 ? 'ASC' : 'DESC';
        } else {
            options.sortField = 'createdAt';
            options.sortOrder = 'DESC';
        }

        this.load(options);
    }


    load(optionSort) {
        this.loading = true;
        this.dataSourceSubject.next(null);  // Clear the previous data to prevent caching issues

        const options: any = {
            size: this.size,
            page: this.page,
            sortOrder: optionSort.sortOrder,
            sortField: optionSort.sortField,
            companyId: this.companyId,
            fromDate: null,
            toDate: null
        };
        if (this.fromDate && this.toDate) {
            // options.fromDate = this.fromDate.getTime();
            // options.toDate = this.toDate.getTime();
            options.fromDate = MOMENT(this.fromDate).startOf('day').utc(true);
            options.toDate = MOMENT(this.toDate).endOf('day').utc(true);
        }

        if (this.selectedEmployee) {
            options.username = this.selectedEmployee.username;
        }
        if (this.isEmployeeRole) {
            options.username = this.authService.getCurrentUsername();
        }
        if (this.textSearch) {
            options.textSearch = this.textSearch;
        }

        if (!this.isPlatformAdmin) {
            options.companyId = this.authService.getCurrentCompanyId();
        }
        this.employeeJobReportService.search(options).subscribe((data: any) => {
            this.loading = false;
            const reportList = data.data.content;

            // Map through reports and assign avatars
            const processedReportList = reportList.map(report => {
                const username = report.userName?.toLowerCase().trim();
                const user = this.userIdMap[username];

                if (user && user.profileImageId) {
                    const profileImageId = Number(user.profileImageId);

                    // Assign avatar from cache if available
                    if (user.avatarUrl) {
                        report.avatar = user.avatarUrl;
                    } else {
                        // Fetch avatar if not cached
                        this.documentService.getAvatarByEntityId(profileImageId).subscribe(
                            avatarUrl => {
                                user.avatarUrl = avatarUrl;
                                report.avatar = avatarUrl;
                                this.cdr.detectChanges();  // Ensure avatar updates are shown
                            },
                            () => {
                                report.avatar = 'assets/images/logo.png';  // Set fallback
                            }
                        );
                    }
                } else {
                    report.avatar = 'assets/images/logo.png';  // Fallback if no profileImageId
                }
                return report;
            });

            // Emit processed list with all avatars updated
            this.dataSourceSubject.next({ content: processedReportList, totalElements: data.data.totalElements });
        }, () => {
            this.loading = false;
        });
    }


    isAdmin() {
        return this.authService.adminRole();
    }

    edit(d: EmployeeJobReport) {
        this.router.navigate(['/app/employee/employee-job-report/edit/', d.id]);
    }

    uploadFile(event) {

    }

    onUploadDocument(uploadFilePanel: OverlayPanel) {
        this.uploadFileRef.nativeElement.click();
        uploadFilePanel.hide();
    }

    onViewDocument(uploadFilePanel: OverlayPanel) {
        this.documentsService.getUploadedFile(this.selectedRecord.documentId).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                if (resObj.data.fileType === 'pdf') {
                    window.open(resObj.data.fileUrl);
                } else {
                    this.fileUrl = resObj.data.fileUrl;
                    this.showViewFileDialog = true;
                }
                uploadFilePanel.hide();
            }
        }, error => {
            // error
        });
    }

    onChangeDocument(uploadFilePanel: OverlayPanel) {
        this.uploadFileRef.nativeElement.click();
        uploadFilePanel.hide();
    }

    onRemoveDocument(uploadFilePanel: OverlayPanel) {
        if (this.selectedRecord.documentId) {
            this.selectedRecord.documentId = null;
            uploadFilePanel.hide();
        }
    }

    viewHistoryUpload(uploadFilePanel: OverlayPanel) {
        // this.historyHeader = 'Email Template Upload History';
        // this.historyType = 'upload';
        // this.showHistoryDialog = true;
        // uploadFilePanel.hide();
    }

    sortingClick(selectedSortOption) {
        // console.log(selectedSortOption);
        const options = {
            sortOrder: selectedSortOption,
            sortField: this.selectedField,
            isSortClick: true
        };
        // console.log(options);
        this.setSortOption(this.selectedField, selectedSortOption);
        this.loadDataSource(options);
    }

    setSortOption(field, selectedSortOption?) {
        this.processCols.forEach((item) => {
            if (field === item.field) {
                item.sortOptions = selectedSortOption;
                this.selectedSortOption = selectedSortOption;
            }
        });
    }

    async exportPDF(rowData) {
        const reportDate = new Date(rowData.reportDate);
        const employee = this.employees.find(e => e.username && e.username.toLowerCase() === rowData.userName.toLowerCase());
        if (employee) {
            this.messageService.add({
                severity: 'info',
                summary: this.translatePipe.transform('Exporting PDF'),
                detail: this.translatePipe.transform('Exporting PDF file. This process may take up to a minute to complete, please wait...')
            });

            const companySetting: any = await this.companyService.getCompanySetting(this.companyId || this.authService.getCurrentCompanyId()).toPromise();
            this.companyFileUrl = companySetting?.data?.document?.fileUrl;
            const documentData: any = await this.documentsService.downloadFile(companySetting?.data?.document?.id).toPromise();
            await this.convertBlobToBase64(documentData?.body).then(resFile => this.companyLogo = resFile);

            let options: any = this.historyFilter;
            options.employeeIds.push(employee.id);
            options.fromDate = reportDate;
            options.toDate = reportDate;
            const clockingDataResponse: any = await this.employeeClockingService.search2(options).toPromise();
            if (clockingDataResponse.status === 'SUCCESS') {
                const pdfBodyContent: any = [];
                const clockingData = clockingDataResponse.data.content;
                if (clockingData.length === 0) {
                    pdfBodyContent.push({
                        type: 'START_WORK',
                        time: null,
                        description: 'Started Work: ',
                        notes: []
                    });
                    pdfBodyContent.push({
                        type: 'TAKE_BREAK',
                        time: null,
                        description: 'Started Break: ',
                        notes: []
                    });
                    pdfBodyContent.push({
                        type: 'BACK_FROM_BREAK',
                        time: null,
                        description: 'Ended Break: ',
                        notes: []
                    });
                    pdfBodyContent.push({
                        type: 'END_WORK',
                        time: null,
                        description: 'Ended Work: ',
                        notes: []
                    });
                    pdfBodyContent.push({
                        type: 'TOTAL_BREAK_DURATION',
                        time: null,
                        description: 'Total break duration: ',
                        notes: []
                    });
                    pdfBodyContent.push({
                        type: 'TOTAL_WORK_DURATION',
                        time: null,
                        description: 'Total work duration: ',
                        notes: []
                    });
                } else {
                    clockingData.forEach((data: any) => {
                        // start time
                        pdfBodyContent.push({
                            type: 'START_WORK',
                            time: data.clockInTime,
                            description: 'Started Work: ' + moment(new Date(data.clockInTime)).format('hh:mm A'),
                            notes: []
                        });
                        if (data.employeeClockingLunchHourHistory && data.employeeClockingLunchHourHistory.length > 0) {
                            data.employeeClockingLunchHourHistory.forEach(lunch => {
                                if (lunch.lunchOutTime) {
                                    pdfBodyContent.push({
                                        type: 'TAKE_BREAK',
                                        time: lunch.lunchOutTime,
                                        description: 'Started Break: ' + moment(new Date(lunch.lunchOutTime)).format('hh:mm A'),
                                        notes: []
                                    });
                                } else {
                                    pdfBodyContent.push({
                                        type: 'TAKE_BREAK',
                                        time: null,
                                        description: 'Started Break: ',
                                        notes: []
                                    });
                                }

                                if (lunch.lunchInTime) {
                                    pdfBodyContent.push({
                                        type: 'BACK_FROM_BREAK',
                                        time: lunch.lunchInTime,
                                        description: 'Ended Break: ' + moment(new Date(lunch.lunchInTime)).format('hh:mm A'),
                                        notes: []
                                    });
                                } else {
                                    pdfBodyContent.push({
                                        type: 'BACK_FROM_BREAK',
                                        time: null,
                                        description: 'Ended Break: ',
                                        notes: []
                                    });
                                }
                            });
                            pdfBodyContent.push({
                                type: 'TOTAL_BREAK_DURATION',
                                time: null,
                                description: 'Total break duration: ',
                                notes: []
                            });
                        } else {
                            pdfBodyContent.push({
                                type: 'TAKE_BREAK',
                                time: null,
                                description: 'Started Break: ',
                                notes: []
                            });
                            pdfBodyContent.push({
                                type: 'BACK_FROM_BREAK',
                                time: null,
                                description: 'Ended Break: ',
                                notes: []
                            });
                            pdfBodyContent.push({
                                type: 'TOTAL_BREAK_DURATION',
                                time: null,
                                description: 'Total break duration: ',
                                notes: []
                            });
                        }
                        if (data.missedEndWork) {
                            pdfBodyContent.push({
                                type: 'END_WORK',
                                time: null,
                                description: 'Ended Work: ',
                                notes: []
                            });
                            pdfBodyContent.push({
                                type: 'TOTAL_WORK_DURATION',
                                time: null,
                                description: 'Total work duration: ',
                                notes: []
                            });
                        } else {
                            pdfBodyContent.push({
                                type: 'END_WORK',
                                time: data.clockOutTime,
                                description: 'Ended Work: ' + moment(new Date(data.clockOutTime)).format('hh:mm A'),
                                notes: []
                            });
                            pdfBodyContent.push({
                                type: 'TOTAL_WORK_DURATION',
                                time: null,
                                description: 'Total work duration: ' + data.workHours,
                                notes: []
                            });
                        }
                    });
                }

                const jobNoteResponse: any = await this.employeeJobReportNoteService.getAllEmployeeJobReportNote(rowData.id).toPromise();
                console.log('jobNoteResponse');
                console.log(jobNoteResponse);
                if (jobNoteResponse.data && jobNoteResponse.data.length > 0) {
                    jobNoteResponse.data.forEach(note => {
                        for (let i = pdfBodyContent.length - 1; i >= 0; i--) {
                            const content = pdfBodyContent[i];
                            console.log('content');
                            console.log(content);

                            // if (content && content.time && note.createdAt >= content.time) {
                            if (content) {
                                content.notes.push({
                                    time: moment(new Date(note.createdAt)),
                                    note: note.note,
                                    noteReason: note.noteReason,
                                    timeFormat: moment(new Date(note.createdAt)).format('MM-DD-YYYY hh:mm A'),
                                    //                 title: note.createdByUsr + ' : ' + note.notesTime,
                                    title: note.notesTime,
                                });
                                break;
                            }
                        }
                    });
                }

                // https://login.operrwork.com/#/app/tm/OA%3D%3D?t=2095-Job-Report-empty-time-frame
                for (let i = 0; i < pdfBodyContent.length; i++) {
                    const currentTime = pdfBodyContent[i].time;
                    if (!currentTime) {
                        continue;
                    }
                    const nextTime = this.getNextReportTime(pdfBodyContent, i);
                    if (nextTime) {
                        this.fillMissingTimeFrame(pdfBodyContent[i].notes, currentTime, nextTime);
                    }
                }

                const doc = this.buildPdf(reportDate, pdfBodyContent, rowData.fullName, rowData.id);
                let output = doc.output('bloburl');
                window.open(output.toString(), '_blank');
                let data = <any>{
                    featureName: 'Employee Job Report',
                    fileName: output,
                    fileSize: pdfBodyContent.toString().length,
                    action: 'Export',
                    createdByUsr: this.authService.getCurrentUsername(),
                    companyId: this.authService.getCurrentCompanyId()
                };
                this.exportMonitorService.create(data).subscribe(() => {
                });
            }
        }
    }

    getNextReportTime(listReport: any[], currentIdx: number) {
        const currentReport = listReport[currentIdx];
        if (currentReport.type === 'END_WORK' || !currentReport.time) {
            return null;
        }
        for (let i = currentIdx + 1; i < listReport.length; i++) {
            const next = listReport[i].time;
            if (next) {
                return next;
            }
        }
        return null;
    }

    fillMissingTimeFrame(notes: any[], startTime, endTime) {
        let start = moment(new Date(startTime));
        start = start.set('minute', 0);
        start = start.set('second', 0);
        start = start.set('millisecond', 0);

        let current = start.clone();
        while (current.isBefore(moment(new Date(endTime)))) {
            const next = current.clone().add(59 * 60 * 1000, 'milliseconds');
            const isMissing = !notes.some(note => {
                const t = moment(note.time, 'HH:mm');
                return t.isSameOrAfter(current) && t.isBefore(next);
            });

            /*if (isMissing && start.add(1, 'hour').isSameOrBefore(moment(new Date(endTime)))) {
              notes.push({
                time: current,
                note: 'No Data Added by User.',
                timeFormat: current.format('MM-DD-YYYY hh:mm A') + ' to ' + next.format('hh:mm A'),
                isMissing: true,
                noteReason: '',
                title: '',
              });
            }*/
            current.add(1, 'hour');
        }
    }

    buildPdf(reportDate, pdfBodyContent, fullName, reportId): jsPDF {
        const doc = new jsPDF();
        let baseUrl;
        if (window.location.href.includes('?')) {
            baseUrl = window.location.href.substring(0, window.location.href.indexOf('?'));
        } else {
            baseUrl = window.location.href;
        }
        const jobReportUrl = baseUrl.replace('list', 'edit') + '/' + reportId;
        doc.setFillColor(25, 145, 245);

        if (this.companyLogo) {
            doc.addImage(this.companyLogo, this.companyFileUrl.endsWith('.png') ? 'PNG' : 'JPEG', 89, 5, 35, 35);
        }

        doc.addFont('../../../../assets/fonts/Arial.ttf', 'Arial', 'normal');
        doc.addFont('../../../../assets/fonts/IBMPlexSans-Regular.ttf', 'IBM Plex Sans', 'normal');
        doc.addFont('../../../../assets/fonts/IBMPlexSans-SemiBold.ttf', 'IBM Plex Sans', 'bold');
        doc.setFont('IBM Plex Sans');
        autoTable(doc, {
            margin: 39,
            body: [
                [
                    {
                        content: this.companyInfo?.name,
                        styles: {
                            halign: 'center',
                            fontSize: 20,
                            fontStyle: 'bold'
                        }
                    }
                ],
            ],
            theme: 'plain'
        });

        autoTable(doc, {
            margin: 0,
            body: [
                [
                    {
                        content: 'Job Report',
                        styles: {
                            halign: 'left',
                            valign: 'bottom',
                            fontSize: 20,
                            fontStyle: 'normal'
                        }
                    },
                    {
                        content: 'Exported: ' + moment(new Date()).format('MM/DD/YYYY hh:mm a'),
                        styles: {
                            halign: 'right',
                            valign: 'bottom',
                            fontSize: 11,
                        }
                    }
                ]
            ],
            theme: 'plain'
        });
        doc.setDrawColor(99, 99, 99);
        doc.setFillColor(240, 240, 240);
        doc.roundedRect(13, 72, 185, 23, 1, 1, 'FD');
        autoTable(doc, {
            margin: 0,
            body: [
                [
                    {
                        content: '',
                        styles: {
                            halign: 'left',
                            fontSize: 18,
                            cellWidth: 3,
                            cellPadding: 0
                        }
                    },
                    {
                        content: 'Name:',
                        styles: {
                            halign: 'left',
                            fontSize: 18,
                            cellPadding: 0,
                            fontStyle: 'bold'
                        }
                    },
                    {
                        content: fullName,
                        styles: {
                            halign: 'left',
                            fontSize: 18,
                            cellPadding: 0,
                            fontStyle: 'normal'
                        }
                    }
                ],

                [
                    {
                        content: '',
                        styles: {
                            halign: 'left',
                            fontSize: 18,
                            cellWidth: 3,
                            cellPadding: 0
                        }
                    },
                    {
                        content: 'Report Date:',
                        styles: {
                            halign: 'left',
                            fontSize: 18,
                            cellPadding: 0,
                            fontStyle: 'bold'
                        }
                    },
                    {
                        content: moment(reportDate).format('MM/DD/YYYY'),
                        styles: {
                            halign: 'left',
                            fontSize: 18,
                            cellPadding: 0,
                            fontStyle: 'normal'
                        }
                    }
                ]


            ],
            theme: 'plain'
        });

        const groupedRows = [];
        for (let i = 0; i < pdfBodyContent.length; i += 2) {
            groupedRows.push([
                {
                    content: pdfBodyContent[i]?.description || '',
                    styles: {
                        halign: 'left',
                        fontSize: 14,
                        fontStyle: 'normal',
                        cellPadding: 0
                    }
                },
                {
                    content: pdfBodyContent[i + 1]?.description || '',
                    styles: {
                        halign: 'left',
                        fontSize: 14,
                        fontStyle: 'normal',
                        cellPadding: 0
                    }
                }
            ]);
        }
        doc.addFont('../../../../assets/fonts/IBMPlexSans-Regular.ttf', 'IBM Plex Sans', 'normal');
        doc.addFont('../../../../assets/fonts/IBMPlexSans-SemiBold.ttf', 'IBM Plex Sans', 'bold');
        doc.setFont('IBM Plex Sans');
        autoTable(doc, {
            margin: 0,
            body: groupedRows,
            theme: 'plain'
        });
        doc.addFileToVFS('../../../../assets/fonts/NotoSans-Regular-all.ttf', notoSansTC);
        doc.addFont('../../../../assets/fonts/NotoSans-Regular-all.ttf', 'NotoSans', 'normal');
        // doc.setFont('IBM Plex Sans');
        doc.setFont('NotoSans');

        pdfBodyContent.forEach(row => {
            if (row.notes && row.notes.length > 0) {
                row.notes.forEach(note => {
                    console.log('note');
                    console.log(note);
                    const body = [];
                    const temporaryElement = document.createElement('div');
                    const temporaryElementReason = document.createElement('div');

                    temporaryElement.innerHTML = note.note;
                    temporaryElementReason.innerHTML = note?.noteReason ? 'Reason for missing: ' + note?.noteReason : '';

                    const regex = /<\w+[^>]*>/g;
                    const regex1 = /<\/\w+>/g;
                    let text = temporaryElement.innerHTML;
                    let textReason = temporaryElementReason.innerHTML;

                    text = text.split(regex).join('');
                    text = text.split(regex1).join('\n');
                    textReason = textReason.split(regex).join('');
                    textReason = textReason.split(regex1).join('\n');
                    console.log('text: ', text);
                    const halign = note.isMissing ? 'center' : 'left';
                    body.push(
                        [
                            {
                                content: note.title,
                                styles: {
                                    halign: 'left',
                                    fontStyle: 'bold'
                                },
                                colSpan: 1,
                            },
                            {
                                content: 'Reported: ' + note.timeFormat,
                                styles: {
                                    halign: 'right'
                                },
                                colSpan: 1,
                            }
                        ],
                        [
                            {
                                content: text,
                                styles: {
                                    halign: halign
                                },
                                colSpan: 2
                            }
                        ]
                    );
                    if (textReason) {
                        body.push([
                            {
                                content: textReason,
                                styles: {
                                    halign: halign
                                },
                                colSpan: 2
                            }
                        ]);
                    }
                    autoTable(doc, {
                        margin: 0,
                        includeHiddenHtml: true,
                        styles: {
                            lineWidth: 0.25,
                            lineColor: [99, 99, 99],
                            font: 'NotoSans',
                            fontSize: 10,
                        },
                        bodyStyles: { fillColor: 255 },
                        alternateRowStyles: { fillColor: [245, 245, 245] },
                        willDrawCell: (data) => {
                            if (data.column.dataKey === 0 && data.cell.section === "body") {
                                //                             var img = new Image();
                                //                             img.src = logo_url;
                                const x = data.cell.x;
                                const y = data.cell.y + 10;
                                doc.addImage(this.companyLogo, this.companyFileUrl.endsWith('.png') ? 'PNG' : 'JPEG', x, y, 100, 100);
                                //doc.textWithLink('Click here', x, y, { url: 'https://stackoverflow.com/questions/76404828/insert-hyperlink-in-jspdf' });
                            }
                        },
                        body: body,
                        theme: 'striped'
                    });
                });
            }

        });

        return doc;
    }

    showMap(data: any) {
        this.jobLocation = {
            lat: data.lat,
            lng: data.lng
        };
        if (!this.jobLocation.lat || !this.jobLocation.lng) {
            if (window.navigator.geolocation) {
                window.navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.jobLocation = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };
                    },
                    (error) => {
                        console.log(error.message);
                    }
                );
            } else {
                console.log('Geolocation is not supported by this browser.');
            }
        }
        this.viewMapDialog = true;
    }

    noteDetailsOnMouseEnter(event: Event, jobReportNotes, overlayPanel: OverlayPanel) {
        this.notesDetailArray = [];
        // this.detailsEditor.writeValue(jobReportNotes?.note)
        this.notesDetailArray = jobReportNotes;
        this.showProgressBarForNote = false;

        if (overlayPanel.target === null || overlayPanel.target === undefined) {
            // overlayPanel.show(event);
            overlayPanel.toggle(event);
        }

    }

    addJobReport() {
        this.disableAdd = true;
        this.router.navigate(['/app/employee/employee-job-report/add']);
    }

    changeEmployee(event) {
        if (event && event.value) {
            this.selectedEmployee = this.employees.find(project => project.value?.toLowerCase() === event.value?.toLowerCase());
        }
    }

    async convertBlobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }
}
