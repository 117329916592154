import { ProjectDetailComponent } from './component/project-detail/project-detail.component';
import { BoardProjectComponent } from './component/board-project/board-project.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../authenticate/auth.guard';
import { TaskLayoutComponent } from './component/task-layout/task-layout.component';
import { PermissionGuard } from 'app/authenticate/permission.guard';
import { MENU_ID } from 'app/shared/data/menu-id';
import { RoleLevel } from 'app/layout/component/app-menu/role-level';

export const TaskManagementRoutes: Routes = [
    {
        path: 'app/task-management',
        component: TaskLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: BoardProjectComponent },
            { path: ':id', component: ProjectDetailComponent }
        ]
    },
    {
        path: 'app/tm',
        component: TaskLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                component: BoardProjectComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [], canEdit: [MENU_ID.TASK_MANAGEMENT], roleWithoutPermission: [RoleLevel.ROLE_GUESS] }

            },
            {
                path: ':id',
                component: ProjectDetailComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [], canEdit: [MENU_ID.TASK_MANAGEMENT], roleWithoutPermission: [RoleLevel.ROLE_GUESS] }
            }
        ]
    }
];

export const TaskManagementRoutingModule = RouterModule.forChild(TaskManagementRoutes);
