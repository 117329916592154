import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-employee-job-report-edit',
  templateUrl: './employee-job-report-edit.component.html',
  styleUrls: ['./employee-job-report-edit.component.scss']
})
export class EmployeeJobReportEditComponent implements OnInit {
  employeeJobReportId: Number;
  constructor(
    private router: Router, 
    private route: ActivatedRoute
  ) { 
    this.route.params.subscribe(params => this.employeeJobReportId = +params.id);
  }

  ngOnInit() {
  }

}
