<div class="card add-shadow p-4">
	<h1 class="m-0 ml-2 my-4">{{'Wallet Transactions' | translate}}</h1>
	<p-panel [toggleable]="true">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Transactions' | translate}}</span>
		</p-header>
		<div class="ui-g">
			<div class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin">
				<label>{{ 'companyLabel' | translate }}: </label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-building"></i>
						</button>
					</div>
					<p-dropdown [options]="allCompanyList" optionLabel="value" dataKey="key" placeholder="{{ 'Select One' | translate }}" [(ngModel)]="selectedCompany" (onChange)="changeCompany()" >
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{ 'TransactionType' | translate }}: </label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-folder-tree"></i>
						</button>
					</div>
					<p-dropdown [options]="allTransactionTypes" placeholder="{{ 'Select One' | translate }}" [showClear]="true" [(ngModel)]="selectedTransactionType" >
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{ 'dateRange' | translate }}: </label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-week"></i>
						</button>
					</div>
					<mat-form-field class="w-100">
						<mat-date-range-input [rangePicker]="picker">
							<input
								matStartDate
								matInput
								placeholder="{{ 'StartDate' | translate }}"
								[(ngModel)]="fromDate"
								name="Startdate"
								(focus)="picker.open()"
							/>
							<input
								matEndDate
								matInput
								placeholder="{{ 'EndDate' | translate }}"
								[(ngModel)]="toDate"
								name="Enddate"
								(focus)="picker.open()"
							/>
						</mat-date-range-input>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12" *ngIf="isCompanyAdmin">
				<label>{{ 'user' | translate }}: </label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-user"></i>
						</button>
					</div>
					<p-dropdown [options]="allUsers" placeholder="{{ 'Select One' | translate }}" optionLabel="username" [(ngModel)]="selectedUser" >
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-12 py-4 text-center">
				<button type="button" class="btn btn-danger mx-1" (click)="reset()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
				<button type="button" class="btn btn-primary mx-1" (click)="search()"><i class="fa-solid fa-check mr-2"></i>{{'button.search' | translate}}</button>
			</div>
		</div>
	</p-panel>
	<br>
	<h1 class="m-0 ml-2 mb-4">{{'Browse Transactions' | translate}}</h1>
	<p-panel id="table-ppanel" [toggleable]="true">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-table text-primary mr-2"></i>{{ 'Wallet Transactions' | translate }} ({{ totalRecords }})</span>
		</p-header>
		<p-table #dt (onFilter)="onFilter($event, dt)" [columns]="cols" [value]="transactionList" dataKey="id" [lazy]="true" (onLazyLoad)="loadContentsLazy($event)" [responsive]="true" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5, 10, 20]" [totalRecords]="totalRecords" [resizableColumns]="false" [loading]="loading"
				 [globalFilterFields]="['sourceUsername', 'sourceFullName', 'companyName', 'to', 'transactionType', 'amount', 'fee']">
			
			<ng-template pTemplate="caption">
				<div class="d-flex align-items-center flex-wrap justify-content-between" style="row-gap: 10px">
					<div class="d-flex align-items-center">
						<button type="button" (click)="exportPdf()" class="btn btn-primary mx-1">
							<i class="fa-solid fa-print mr-2"></i> {{'Export PDF' | translate}}
						</button>
					</div>
					<div class="input-group flex-grow-1 mr-2" style="max-width: 400px">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button">
								<i class="fa-solid fa-filter"></i>
							</button>
						</div>
						<input type="text" [(ngModel)]="textSearch" pInputText placeholder="{{'Filter' | translate}}" (input)="dt.filterGlobal($event?.target?.value, 'contains')">
					</div>
				</div>
			</ng-template>

			<ng-template pTemplate="header" let-columns>
				<tr>
					<th class="adjust-padding"
						pResizableColumn
						*ngFor="let col of columns"
						[ngStyle]="{ display: col?.display, width: col.width }">
						<ng-container [ngSwitch]="col.field">
							<span *ngSwitchDefault>
								<div class="d-flex align-items-center justify-content-between w-100">
									<span class="one-liner">{{col.label | translate}}</span>
									<span *ngIf="col.sort">
										<span (click)="col.sort == 'number' ? op2.toggle($event) : op1.toggle($event); selectedField = col.field; setSortOption(col.field, col.sort)">
											<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
										</span>
									</span>
								</div>
							</span>
							<span *ngSwitchCase="'status'">
								<span class="d-flex justify-content-center text-center w-100">
									<i class="fa-solid fa-info text-muted m-auto" pTooltip="{{'Status' | translate}}" pTooltip="left"></i>
								</span>
            				</span>
						</ng-container>
					</th>
				</tr>
			</ng-template>

			<ng-template pTemplate="body" let-rowData let-i="rowIndex">
				<tr
					*ngIf="!loading"
					[pSelectableRow]="rowData"
					[pContextMenuRow]="rowData">
					<td>
						 {{ i + 1 }}
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{ 'label_date' | translate }}: </span>
							<span *ngIf="rowData.createdAt" pTooltip="{{rowData.createdAt | date: 'M/d/yyyy H:mm:ss a'}}">{{rowData.createdAt | date: 'M/d/yyyy h:mm:ss a'}}</span>
							<span *ngIf="!rowData.createdAt" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td *ngIf="isDisplay()">
						<span class="one-liner">
							<span class="mobile-table-label">{{ 'user' | translate }}: </span>
							<span *ngIf="rowData.sourceUsername" pTooltip="{{rowData.sourceUsername}}">{{rowData.sourceUsername}}</span>
							<span *ngIf="!rowData.sourceUsername" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td *ngIf="isDisplay()">
						<span class="one-liner">
							<span class="mobile-table-label">{{ 'Name' | translate }}: </span>
							<span *ngIf="rowData.sourceFullName" pTooltip="{{rowData.sourceFullName}}">{{rowData.sourceFullName}}</span>
							<span *ngIf="!rowData.sourceFullName" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{ 'from' | translate }}: </span>
							<span *ngIf="rowData.companyName" pTooltip="{{rowData.companyName}}">{{rowData.companyName}}</span>
							<span *ngIf="!rowData.companyName" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{ 'to' | translate }}: </span>
							<span *ngIf="rowData.to" pTooltip="{{rowData.to}}">{{rowData.to}}</span>
							<span *ngIf="!rowData.to" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{ 'login_type' | translate }}: </span>
							<span *ngIf="rowData.transactionType" pTooltip="{{rowData.transactionType}}">{{rowData.transactionType}}</span>
							<span *ngIf="!rowData.transactionType" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{ 'Amount' | translate }}: </span>
							<span *ngIf="rowData.amount" pTooltip="${{ rowData.amount }}">${{ rowData.amount}}</span>
							<span *ngIf="!rowData.amount" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{ 'Fee' | translate }}: </span> 
							<span *ngIf="rowData.fee" pTooltip="${{ rowData.fee}}">${{ rowData.fee}}</span>
							<span *ngIf="!rowData.fee" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{ 'Status' | translate }}: </span>
							<i *ngIf="rowData.status === 'SUCCEEDED'" class="fa-solid fa-circle-check text-success" pTooltip="{{rowData.status}}" tooltipPosition="left"></i>
							<i *ngIf="rowData.status === 'FAILED'" class="fa-solid fa-circle-xmark text-danger" pTooltip="{{rowData.status}}" tooltipPosition="left"></i>
							<i *ngIf="rowData.status === 'PENDING'" class="fa-solid fa-circle-info text-primary" pTooltip="{{rowData.status}}" tooltipPosition="left"></i>
						</span>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage" let-columns>
				<tr *ngIf="!loading" class="text-center">
					<td [attr.colspan]="cols.length">{{ 'no data' | translate }}</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="footer" let-columns>
				<tr *ngIf="loading" class="text-center">
					<td [attr.colspan]="cols.length">{{ 'loading' | translate }}</td>
				</tr>
			</ng-template>
		</p-table>
	</p-panel>
</div>
<p-overlayPanel #op1 appendTo="body">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
