
<p-toast position="bottom-right">{{msgs}}</p-toast>
<h1 class="m-0 ml-2 my-4">{{'Punch Tracking' | translate}}</h1>
<p-panel [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Punches' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Clock Action' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user-clock"></i>
					</button>
				</div>
				<p-dropdown [options]="clockActions" [(ngModel)]="selectedTimeClock" placeholder="{{'placeholder.pleaseSelect' | translate}}" (onChange)="timeClockChange($event)">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'employee.name' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user"></i>
					</button>
				</div>
				<p-multiSelect defaultLabel="{{'Select Items' | translate}}" class="w-100" [options]="employeeList" [(ngModel)]="historyFilter.employeeIds" [disabled]="disableEmployee()" id="id_filteredEmployeeList_to_selected_label">
					<ng-template let-option pTemplate="item">
						<span class="one-liner" style="font-size: 14px;max-width: 95%;">{{option.label | translate}}</span>
					</ng-template>
					<ng-template let-option pTemplate="selectedItem">
						<span class="one-liner" style="font-size: 14px;max-width: 95%;">{{option.label | translate}}</span>
					</ng-template>
				</p-multiSelect>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Date Range' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-week"></i>
					</button>
				</div>
				<mat-form-field class="w-100">
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate matInput placeholder="{{'Start' | translate}}" [(ngModel)]="fromDate" name="Startdate" (focus)="picker.open()" [ngModelOptions]="{standalone: true}">
						<input matEndDate matInput placeholder="{{'End' | translate}}" [(ngModel)]="toDate" name="Enddate" (focus)="picker.open()" [ngModelOptions]="{standalone: true}">
					</mat-date-range-input>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12" >
			<label>{{'Status' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-info"></i>
					</button>
				</div>
				<p-dropdown [options]="workStatus" [(ngModel)]="selectedWorkStatus" placeholder="{{'placeholder.pleaseSelect' | translate}}" (onChange)="workStatusChange($event)">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">
							<i *ngIf="item.label === 'Success'" class="fa-solid fa-circle-check text-success mr-1"></i>
							<i *ngIf="item.label === 'Failed'" class="fa-solid fa-circle-xmark text-danger mr-1"></i>
							{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<i *ngIf="item.label === 'Success'" class="fa-solid fa-circle-check text-success mr-1"></i>
						<i *ngIf="item.label === 'Failed'" class="fa-solid fa-circle-xmark text-danger mr-1"></i>
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-12 text-center py-4">
			<button class="btn btn-danger mx-1" type="button" (click)="reset()"><i class="fa-solid fa-xmark mr-2"></i>{{'Reset' | translate}}</button>
			<button class="btn btn-primary mx-1" type="button" (click)="search2()"><i class="fa-solid fa-check mr-2"></i>{{'Search' | translate}}</button>
		</div>
	</div>
</p-panel>
<div class="d-flex align-items-center flex-wrap justify-content-start py-4">   
	<h1 class="m-0 ml-2">{{'Browse Punches' | translate}}</h1>
</div>
<p-panel id="table-ppanel" [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-table text-primary mr-2"></i> {{'Punches' | translate}} ({{dt?._totalRecords || 0 | number}})</span>
	</p-header>
	<p-table #dt (onFilter)="onFilter($event, dt)" [value]="punchHistoryList" [responsive]="true" dataKey="id" [lazy]="false" (onLazyLoad)="loadPunchHistoryLazy($event)" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50]" [totalRecords]="totalRecords"
			 [(contextMenuSelection)]="selectedPunchHistory" [globalFilterFields]="['employee.fullName', 'id', 'accountUsed', 'details', 'locationName', 'pin', 'clockActionName','createdAtStr', 'applicationName']">
		<ng-template pTemplate="caption">
			<div class="d-flex align-items-center justify-content-between" style="row-gap: 10px">
				<span class="d-flex flex-grow-1" id="table-buttons">
					<div class="d-flex">
						<button (click)="exportPdf()" class="btn btn-primary mx-1">
							<span class="text-nowrap"><i *ngIf="exportingPDF" class="fa-solid fa-spinner mr-2 loadingHourglass"></i><i *ngIf="!exportingPDF" class="fa-solid fa-print mr-2"></i> {{'Export PDF' | translate}} </span>
						</button>
					</div>
				</span>
				<div class="input-group flex-grow-1 mr-2" style="max-width: 400px">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button">
							<i class="fa-solid fa-filter"></i>
						</button>
					</div>
					<input type="text" pInputText placeholder="{{'Filter' | translate}}" class="input-search float-right" [(ngModel)]="textSearch" (input)="dt.filterGlobal($event?.target?.value, 'contains')">
				</div>
			</div>
		</ng-template>

		<p-toast position="bottom-right">{{msgs}}</p-toast>
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th>
					<div class="d-flex justify-content-between align-items-center w-100">
						<span class="one-liner" pTooltip="{{'ID' | translate}}">{{'ID' | translate}}</span>
						<span (click)="op2.toggle($event); selectedField = 'employee.id';">
							<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
						</span>
					</div>
				</th>
				<th>
					<div class="d-flex justify-content-between align-items-center w-100">
						<span class="one-liner" pTooltip="{{'FullName' | translate}}">{{'FullName' | translate}}</span>
						<span (click)="op1.toggle($event); selectedField = 'employee.fullName';">
							<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
						</span>
					</div>
				</th>
				<th>
					<div class="d-flex justify-content-between align-items-center w-100">
						<span class="one-liner" pTooltip="{{'Username' | translate}}">{{'Username' | translate}}</span>
						<span (click)="op1.toggle($event); selectedField = 'accountUsed';">
							<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
						</span>
					</div>
				</th>
				<th style="width:80px !important;">
					<div class="d-flex justify-content-between align-items-center w-100">
						<span class="one-liner" pTooltip="{{'PIN/Face Recognition' | translate}}">{{'Method' | translate}}</span>
						<!-- <span (click)="op2.toggle($event); selectedField = 'pin';">
							<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
						</span> -->
					</div>
				</th>
				<th>
					<div class="d-flex justify-content-between align-items-center w-100">
						<span class="one-liner" pTooltip="{{'Details' | translate}}">{{'Details' | translate}}</span>
						<span (click)="op1.toggle($event); selectedField = 'details';">
							<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
						</span>
					</div>
				</th>

				<th>
					<div class="d-flex justify-content-between align-items-center w-100">
						<span class="one-liner" pTooltip="{{'Location' | translate}}" tooltipPosition="left">{{'Location' | translate}}</span>
						<span (click)="op1.toggle($event); selectedField = 'locationName';">
							<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
						</span>
					</div>
				</th>
				<th>
					<div class="d-flex justify-content-between align-items-center w-100">
						<span class="one-liner" pTooltip="{{'Punch' | translate}}">{{'Punch' | translate}}</span>
						<span (click)="op1.toggle($event); selectedField = 'clockActionName';">
							<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
						</span>
					</div>
				</th>
				<th style="width:15% !important;">
					<div class="d-flex justify-content-between align-items-center w-100">
						<span class="one-liner" pTooltip="{{'Timestamp' | translate}}">{{'Timestamp' | translate}}</span>
						<span (click)="op3.toggle($event); selectedField = 'createdAt';">
							<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
						</span>
					</div>
				</th>
				<th [id]="'status'">
					<div class="d-flex justify-content-center align-items-center w-100">
						<i class="fa-solid fa-info text-muted m-auto"  pTooltip="{{'Status' | translate}}" tooltipPosition="left"></i>
					</div>
				</th>
				<th>
					<div class="d-flex justify-content-between align-items-center w-100">
						<span class="one-liner" pTooltip="{{'Confirmation Date' | translate}}" tooltipPosition="left">{{'Confirmed' | translate}}</span>
						<span (click)="op3.toggle($event); selectedField = 'confirmationDate';">
							<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
						</span>
					</div>
				</th>
				<th>
					<div class="d-flex justify-content-between align-items-center w-100">
						<span class="one-liner" pTooltip="{{'Application' | translate}}">{{'Application' | translate}}</span>
						<span (click)="op1.toggle($event); selectedField = 'userClockFrom';">
							<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
						</span>
					</div>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData>
			<tr [pSelectableRow]="rowData" id="id_punch_history_table_body">
				<td [pTooltip]="rowData.id">
					<span class="one-liner">
						<span class="mobile-table-label">{{'ID' | translate}}: </span>
						<span *ngIf="rowData.id" pTooltip="{{rowData.id}}">{{rowData.id}}</span>
						<span *ngIf="!rowData.id" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'FullName' | translate}}: </span>
						<span *ngIf="rowData.employee?.fullName" pTooltip="{{rowData.employee?.fullName}}">{{rowData.employee?.fullName}}</span>
						<span *ngIf="!rowData.employee?.fullName" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Username' | translate}}: </span>
						<span *ngIf="rowData.accountUsed" pTooltip="{{rowData.accountUsed}}">{{rowData.accountUsed}}</span>
						<span *ngIf="!rowData.accountUsed" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Punch Method' | translate}}: </span>
						<img [src]="rowData?.faceDetectionImage" *ngIf="rowData.faceDetection && rowData.faceDetectionImage" (click)="viewFaceImg(rowData)" class="align-self-center profile-picture m-auto cursor-pointer" style="max-height: 22px;max-width: 30px;margin-left: 5px !important;border-radius: 16px;" pTooltip="{{'Facial Recognition' | translate}}" tooltipPosition="left"/>
						<span *ngIf="!rowData.faceDetection && rowData.pin" pTooltip="{{'PIN' | translate}}: {{rowData.pin}}">{{rowData.pin}}</span>
						<span *ngIf="!(rowData.pin || rowData.faceDetectionImage)" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>	
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Details' | translate}}: </span>
						<span *ngIf="rowData.details" pTooltip="{{rowData.details}}">{{rowData.details}}</span>
						<span *ngIf="!rowData.details" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Location' | translate}}: </span>
						<span *ngIf="rowData.locationName" pTooltip="{{rowData.locationName | translate}}" tooltipPosition="left">{{rowData.locationName | translate}}</span>
						<span *ngIf="!rowData.locationName" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Action' | translate}}: </span>
						<span *ngIf="rowData.clockActionName" pTooltip="{{rowData.clockActionName | translate}}">{{rowData.clockActionName | translate}}</span>
						<span *ngIf="!rowData.clockActionName" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Timestamp' | translate}}: </span>
<!--						<span *ngIf="rowData.createdAtStr" pTooltip="{{rowData.createdAtStr}}" tooltipPosition="left">{{rowData.createdAtStr}}</span>-->
<!--						<span *ngIf="!rowData.createdAtStr" class="text-muted">{{'no data' | translate}}</span>-->
						<span *ngIf="rowData.createdAt" pTooltip="{{ rowData.createdAt | date: 'MM/dd/yyyy hh:mm a' }}">{{ rowData.createdAt | date: 'MM/dd/yyyy hh:mm a': 'UTC' }}</span>
						<span *ngIf="!rowData.createdAt" class="text-muted">{{ 'no data' | translate }}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Status' | translate}}: </span>
						<i *ngIf="rowData.status === 'Success'" class="fa-solid fa-circle-check text-success" pTooltip="{{rowData.status}}" tooltipPosition="left"></i>
						<i *ngIf="rowData.status === 'Error'" class="fa-solid fa-circle-xmark text-danger" pTooltip="{{rowData.errorMessage || rowData.status}}" tooltipPosition="left"></i>
					</span>
				</td>

				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Confirmation Date' | translate}}: </span>
						<img [src]="rowData?.faceImage" *ngIf="rowData.faceImage && !rowData.confirmationDate" (click)="viewFaceImgConfirm(rowData)" class="align-self-center profile-picture m-auto cursor-pointer" style="max-height: 22px;max-width: 30px;margin-left: 5px !important;border-radius: 16px;"/>
						<span *ngIf="rowData.confirmationDate" pTooltip="{{rowData.confirmationDate | date: 'MM/dd/yyyy hh:mm a'}}">{{rowData.confirmationDate | date: 'MM/dd/yyyy hh:mm a'}}</span>
						<span *ngIf="!(rowData.faceImage || rowData.confirmationDate)" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>	

				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Application' | translate}}: </span>
						<span *ngIf="rowData.userClockFrom === 'Computer'" pTooltip="{{'Computer (web portal)'}}" tooltipPosition="left">{{'Computer (web portal)'}}</span>
						<span *ngIf="rowData.userClockFrom && rowData.userClockFrom !== 'Computer'" pTooltip="{{rowData.userClockFrom}}" tooltipPosition="left">{{rowData.userClockFrom}}</span>
						<span *ngIf="!rowData.userClockFrom" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading">
				<td [attr.colspan]="11" class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="loading">
				<td [attr.colspan]="11" class="text-center py-5">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
		</ng-template>

	</p-table>
</p-panel>

<p-overlayPanel #op1 appendTo="body" class="p-0">
	<p-listbox [options]="textSortOptionsClient" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptionsClient" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op3 appendTo="body">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>

<p-overlayPanel #op99 appendTo="body" styleClass="custom">
	<div style=" max-width: 300px;">
		<div style="background-color: #5b93ce; color: #fff;" class="d-flex align-items-center px-2 py-1">
			<i style="color: #fff;" class="mr-2 fa-solid fa-info-circle"></i>
			{{reasonHeader}}
		</div>
		<div class="px-2 py-3">
			{{reasonNote}}
		</div>
	</div>	
</p-overlayPanel>

<p-dialog [modal]="true" [(visible)]="showViewFaceImg" [style]="{'width': '90%', 'max-width':'600px'}" [dismissableMask]="true">
	<p-header>
		<span>{{'Punch Confirmation' | translate}} ({{faceData.name}})</span>
	</p-header>
		<div class="d-flex">
			<div style="width: 400px; padding: 5px" [style.width]="(!isEmployee && (!faceData.status || faceData.status === 'Pending')) ? '400px' : '560px'">
				<img [src]="faceData.img" alt="" [style.width]="(!isEmployee && (!faceData.status || faceData.status === 'Pending')) ? '400px' : '560px'">
				<div class="text-center" style="padding: 5px" [style.width]="(!isEmployee && (!faceData.status || faceData.status === 'Pending')) ? '400px' : '560px'">
					<span *ngIf="faceData.date" style="font-size: large;">{{faceData.date | date: 'MM/dd/yyyy HH:mm a'}}</span>
					<span *ngIf="faceData.status && faceData.status !== 'Pending'" class="pl-2" style="font-size: large; font-weight: bold;" [ngClass]="faceData.status == 'Accepted' ? 'text-success' : 'text-danger'">{{faceData.status}}</span>
				</div>
			</div>
			<div *ngIf="!isEmployee && (!faceData.status || faceData.status === 'Pending')" class="d-flex justify-content-center" style="flex-direction: row; align-items: center; flex-wrap: wrap;">
				<div class="d-flex justify-content-center" style="height: 160px; flex-direction: row; align-items: center; flex-wrap: wrap;">
					<button class="btn btn-success" (click)="acceptFaceImg()" [disabled]="confirmingFaceImg">
						<span><i class="fa-solid fa-check mr-2"></i>{{'Accept' | translate}}</span>
					</button>
					<button class="btn btn-danger" (click)="rejectFaceImg()" [disabled]="confirmingFaceImg">
						<span><i class="fa-solid fa-xmark mr-2"></i>{{'Reject' | translate}}</span>
					</button>
				</div>
			</div>
		</div>
</p-dialog>

<p-dialog [modal]="true" id="viewImageModal" [(visible)]="showViewDetectionFaceImg" [style]="{'width': '90%', 'max-width':'600px'}" [dismissableMask]="true">
	<p-header>
		<span><i class="fa-solid fa-users-viewfinder mr-2"></i>{{'Facial Recognition' | translate}} ({{faceData.name}})</span>
	</p-header>
	<img [src]="faceData.img" alt="" class="w-100">
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="showViewDetectionFaceImg = false"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
	</p-footer>
</p-dialog>
