<div class="d-flex align-items-center justify-content-between  py-4">
	<h1 class="m-0 ml-2" *ngIf="mode === 'add' || mode === 'create'">{{'Add Job Report' | translate}}</h1>
	<h1 class="m-0 ml-2" *ngIf="mode === 'edit'">{{'Edit Job Report' | translate}}</h1>
	<span>
		<button type="button" class="btn btn-info round" (click)="enableForm()" *ngIf="mode === 'view'"  pTooltip="{{'Edit' | translate}}" tooltipPosition="left">
			<i class="fa-solid fa-pen-to-square"></i>
		</button>
		<button *ngIf="mode !== 'view'" class="btn btn-danger round" (click)="onCancel()" pTooltip="{{'Cancel' | translate}}" tooltipPosition="left">
			<i class="fa-solid fa-xmark"></i>
		</button>
	</span>
</div>
<form [formGroup]="employeeJobReportForm" #employeeJobReportDataForm [name]="employeeJobReportDataForm">
	<p-panel [toggleable]="true" id="main-panel">
		<p-header class="flex-grow-1 d-flex align-item-center justify-content-between">
			<span><i class="fa-solid fa-contact-card text-primary mr-2"></i>{{'Job Report Details' | translate}}</span>
			<span *ngIf="mode != 'create' && mode != 'add'" class="text-dark c-pointer mr-3" (click)="showHistoryForDetail()"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i>{{'history.title' | translate}}</span>
		</p-header>
		<div class="ui-g">
			<div class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin">
				<label>{{'company.name' | translate}}<span class="required-indicator">*</span>:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-building"></i>
						</button>
					</div>
					<p-dropdown [options]="companies" formControlName="company" [filter]="true" emptyFilterMessage="{{'No results found' | translate}}" placeholder="{{'placeholder.pleaseSelect' | translate}}" [(ngModel)]="companySelected" (onChange)="changeCompany($event)" [disabled]="disableCompany() || disableInput" optionLabel="value">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
				<span *ngIf="!employeeJobReportForm.controls['company'].valid && employeeJobReportForm.controls['company'].dirty && isPlatformAdmin">
					{{'validate.required' | translate:{value: 'company.name' | translate} }}
				</span>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label class="flex-none">{{'User' | translate}}<span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-user"></i>
						</button>
					</div>
					<p-dropdown [options]="employees" formControlName="userName" [disabled]="isEmployeeRole || mode !== 'create'" placeholder="{{'placeholder.pleaseSelect' | translate}}" [filter]="true" emptyFilterMessage="{{'No results found' | translate}}" [(ngModel)]="employeeSelected" (onChange)="changeUser($event)" optionLabel="label">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
				<span  class="text-danger" *ngIf="!employeeJobReportForm.controls['userName'].valid && employeeJobReportForm.controls['userName'].dirty">
					{{'validate.required' | translate:{value: 'User' | translate} }}
				</span>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label class="flex-none">{{'Report Date' | translate}} <span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important" (click)="picker1.open()">
							<i class="fa-solid fa-calendar-plus"></i>
						</button>
					</div>
					<mat-form-field class="w-100">
						<input matInput (click)="picker1.open()" [matDatepicker]="picker1" name="reportDate" [disabled]="readOnly" formControlName="reportDate" [max]="maxDate" utc="true" [(ngModel)]="reportDateNgModel" (ngModelChange)="onDateChange($event)">
						<mat-datepicker #picker1></mat-datepicker>
					</mat-form-field>
				</div>
				<span *ngIf="!employeeJobReportForm.controls['reportDate'].valid && employeeJobReportForm.controls['reportDate'].dirty" class="ui-message ui-messages-error"> {{'This field is required' | translate }}</span>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Attachment' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid"
								[ngClass]="{'text-success fa-circle-check': employeeJobReport?.documentId, 'fa-paperclip': !employeeJobReport?.documentId}">
							</i>		
						</button>
					</div>
					<input type="file" hidden (change)="uploadFile($event)" multiple #upload>
					<div class="form-control" style="height: 40px;font-size: 16px;">
						<span *ngIf="!employeeJobReport?.documentId">{{'Upload File' | translate}}</span>
						<span *ngIf="employeeJobReport?.documentId">{{'File Uploaded' | translate}}</span>
					</div>
					<div class="input-group-append">
						<button class="btn btn-white" id="button-addon2" type="button" (click)="uploadFilePanel.toggle($event)" 
							[disabled]="mode != 'create' && mode != 'edit'" style="cursor:default !important">
							<i class="fa-solid fa-upload text-primary"></i>
						</button>
					</div>
				</div>
				<p-overlayPanel #uploadFilePanel appendTo="body">
					<ng-template pTemplate>
						<ul class="m-0 px-1" style="list-style: none; min-width: 150px;">
							<li style="cursor: pointer;" class="px-2" *ngIf="!employeeJobReport?.documentId">
								<span (click)="onUploadDocument(uploadFilePanel)" class="text-dark d-block my-2"><i class="fa-solid fa-upload text-primary mr-2"></i>{{'upload' | translate}}</span>
							</li>
							<!--<li style="cursor: pointer;" class="px-2" *ngIf="employeeJobReport?.documentId">
								<span (click)="onViewDocument(uploadFilePanel)" class="text-dark d-block my-2"><i class="fa-solid fa-up-right-from-square text-primary mr-2"></i>{{'view' | translate}}</span>
							</li>-->
							<li style="cursor: pointer;" class="px-2"  *ngIf="employeeJobReport?.documentId">
								<span (click)="onChangeDocument(uploadFilePanel)" class="text-dark d-block my-2"><i class="fa-solid fa-recycle text-primary mr-2"></i>{{'change' | translate}}</span>
							</li>
							<li style="cursor: pointer;" class="px-2" *ngIf="mode !== 'add' && mode !== 'create'" (click)="viewHistoryUpload(uploadFilePanel)" >
								<span class="text-nowrap"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i> {{'History' | translate}}</span>
							</li>
							<!--<li style="cursor: pointer;" class="px-2" *ngIf="employeeJobReport?.documentId">
								<span (click)="onRemoveDocument(uploadFilePanel)" class="text-dark d-block my-2"><i class="fa-solid fa-xmark text-danger mr-2"></i>{{'remove' | translate}}</span>
							</li>-->
						</ul>
					</ng-template>
				</p-overlayPanel>

				<div class="upload-process mt-1 d-flex" *ngIf="isUploadingFile">
					<svg xmlns="http://www.w3.org/2000/svg" style="zoom: 26%;width: 51px;height: 50px;rotate: 180deg;" pTooltip="{{'Uploading...' | translate}}">
						<style>
							.line {
								fill: none;
								stroke: #aaa;
								stroke-width: 8;
								stroke-dasharray: 40
							}

							.animated {
								animation: draw .8s ease-in-out;
								animation-iteration-count: infinite;
								stroke-dashoffset: 0
							}

							.line2 {
								animation-delay: .08s
							}

							.line3 {
								animation-delay: .16s
							}

							@keyframes draw {
								0% {
									stroke-dashoffset: 0
								}

								50% {
									stroke-dashoffset: 30
								}

								100% {
									stroke-dashoffset: 0
								}
							}
						</style>
						<path class="line animated" d="M14 10V50z" />
						<path class="line animated line2" d="M30 10V50z" />
						<path class="line animated line3" d="M46 10V50z" />
					</svg>
					Uploading...
				</div>
				<div class="mt-1" *ngIf="employeeJobReport && (employeeJobReport.documentUrls || []).length">

					<div class="d-flex align-items-center flex-wrap flex-grow-1 mt-1" style="gap: 5px">
						<div *ngFor="let file of employeeJobReport.documentUrls; let j = index">
							<div *ngIf="file && file !== ''" class="d-flex align-items-center justify-content-between">
								<div>
									<div *ngIf="isImage(file)">
										<img (click)="onViewImage(file)" [src]="file" height="100px" width="100" style="object-fit: cover; cursor: pointer;">
									</div>
									<div *ngIf="isDoc(file)">
										<i (click)="openInNewTab(file)" class="fa-regular fa-file-word" style="font-size: 105px; cursor: pointer;"></i>
									</div>
									<div *ngIf="isPdf(file)">
										<i (click)="openInNewTab(file)" class="fa-regular fa-file-pdf" style="font-size: 105px; cursor: pointer;"></i>
									</div>
									<div *ngIf="isExcel(file)">
										<i (click)="openInNewTab(file)" class="fa-regular fa-file-excel" style="font-size: 105px; cursor: pointer;"></i>
									</div>
									<div *ngIf="isFile(file)">
										<i (click)="openInNewTab(file)" class="fa-regular fa-file" style="font-size: 105px; cursor: pointer;"></i>
									</div>
									<span class="d-flex justify-content-center">
									<div>
										<i class="fa-solid fa-xmark text-danger mx-1" (click)="removeCommentFile(j); $event.stopPropagation()" pTooltip="Delete" tooltipPosition="left"></i>
									</div>
								</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="ui-g mt-4">
			<div class="ui-g-12 ui-lg-12">
				<label>{{'Concern' | translate}}:</label>
				<textarea maxlength="1500" [rows]="3" id="newNote" type="text" [readonly]="readOnly" placeholder="{{'Maximum 1500 characters' | translate}}" pInputText [(ngModel)]="employeeJobReport.concern" formControlName="concern" style="font-size: 16px"></textarea>
				<span class="pull-right" [ngClass]="employeeJobReport.concern?.length < 1500 ? 'text-muted' : 'text-danger'" style="font-size: 12px">{{employeeJobReport.concern?.length || 0}}/1500</span>
			</div>
			<div class="ui-g-12 ui-lg-12" *ngFor="let reply of  employeeJobReport.replies">
				<div class="justify-content-between" style="background-color: lightgray; border-radius: 3px">
					<span><strong>{{'Reply from ' | translate}} {{reply.userName}}:</strong></span>
					<span>{{reply.createdAt | date : 'MMM dd, yyyy hh:mm a'}}</span>
				</div>
				<div>
					{{reply.reply}}
				</div>
			</div>
		</div>
	</p-panel>

			<div class="ui-g mt-4">
				<div class="ui-g-12">
					<h1 class="m-0 ml-2 mb-4">{{'Job Report Updates' | translate}}</h1>
				</div>
			</div>
			<div class="ui-g align-items-start pb-4" id="report-details" style="row-gap: 20px">
				<div class="ui-sm-12 ui-g-3" id="time-spent">
					<p-panel [toggleable]="true" id="table-ppanel">
						<p-header class="flex-grow-1">
							<span><i class="fa-solid fa-clock text-primary mr-2"></i>{{'Time Spent' | translate}} <span>({{ getRowCount() }} {{'hours' | translate}}) </span></span>
						</p-header>
						<p-table [class.d-none]="mode === 'view' && !loadingTimeSpent" [value]="listHourNews" selectionMode="single" [(selection)]="selectedHours" scrollable="true" dataKey="code" scrollHeight="550px">
							<ng-template pTemplate="header">
								<tr>
									<th><span class="one-liner">{{'Period' | translate}}</span></th>
									<th class="text-center" style="width: 45px"><i class="fa-solid fa-info text-muted m-auto" pTooltip="{{'Status' | translate}}" tooltipPosition="left"></i></th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-product let-i="rowIndex">
								<tr [pSelectableRow]="product" *ngIf="product.status === 'Missed'" (click)="clickHour(product, i)">
									<td><span class="one-liner"><i class="fa-solid fa-pen-to-square text-primary mr-2"></i>{{product.code}}</span></td>
									<td style="width: 45px"><i class="fa-solid fa-circle-xmark text-danger" pTooltip="{{product.status}}" tooltipPosition="left"></i></td>
								</tr>
								<tr [pSelectableRow]="product" *ngIf="product.status === 'In Progress'" (click)="clickHour(product, i)">
									<td><span class="one-liner"><i class="fa-solid fa-pen-to-square text-primary mr-2"></i>{{product.code}}</span></td>
									<td style="width: 45px"><i class="fa-solid fa-circle-info text-primary" pTooltip="{{product.status}}" tooltipPosition="left"></i></td>
								</tr>
								<tr [pSelectableRow]="product" *ngIf="product.status === 'Done'" (click)="clickHour(product, i)">
									<td><span class="one-liner text-muted"><i class="fa-solid fa-up-right-from-square text-primary mr-2"></i>{{product.code}}</span></td>
									<td style="width: 45px" *ngIf="product.noteReason == null"><i class="fa-solid fa-circle-check text-success" pTooltip="{{product.status}}" tooltipPosition="left"></i></td>
									<td style="width: 45px" *ngIf="product.noteReason !== null"><i class="fa-solid fa-circle-check text-warning" pTooltip="{{product.status}}" tooltipPosition="left"></i></td>
								</tr>
								<tr [pSelectableRow]="product" *ngIf="product.status === 'Upcoming'" (click)="clickHour(product, i)">
									<td><span class="one-liner"><i class="fa-solid fa-pen-to-square text-primary mr-2"></i>{{product.code}}</span></td>
									<td style="width: 45px"><i class="fa-solid fa-clock text-secondary" pTooltip="{{product.status}}" tooltipPosition="left"></i></td>
								</tr>
								<tr [pSelectableRow]="product" *ngIf="product.status === ''" (click)="clickHour(product, i)">
									<td><span class="one-liner"><i class="fa-solid fa-pen-to-square text-primary mr-2"></i>{{product.code}}</span></td>
									<td style="width: 45px"><i class="fa-solid fa-triangle-exclamation text-muted" pTooltip="{{'no data' | translate}}" tooltipPosition="left"></i></td>
								</tr>
							</ng-template>
							<ng-template pTemplate="emptymessage" let-columns>
								<tr *ngIf="!loadingTimeSpent">
									<td [attr.colspan]="2" class="text-center py-5">
										<div class="text-muted my-5">{{'no data' | translate}}</div>
									</td>
								</tr>
								<tr *ngIf="loadingTimeSpent">
									<td [attr.colspan]="2" class="text-center py-5">
										<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
									</td>
								</tr>
							</ng-template>
						</p-table>
						<p-footer *ngIf="jrAllowExpand">
							<span *ngIf="!isExpand" (click)="expandTime()" class="d-flex align-items-center c-pointer" style="font-size: 14px;cursor: pointer">
								<i class="fa-solid fa fa-square-plus text-primary mr-2"></i> 
								{{'More Hours' | translate}}
							</span>
							<span *ngIf="isExpand" (click)="collapseTime()" class="d-flex align-items-center c-pointer" style="font-size: 14px;cursor: pointer">
								<i class="fa-solid fa fa-square-minus text-primary mr-2"></i> 
								{{'Less Hours' | translate}}
							</span>
						</p-footer>
					</p-panel>
				</div>
				<div class="ui-g-9 flex-grow-1 px-2" *ngIf="!selectedHourItem">
					<p-panel [toggleable]="true" id="table-ppanel">
						<p-header class="flex-grow-1 d-flex align-items-center justify-content-between">
							<span class="one-liner"><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Progress Update' | translate}}</span>
						</p-header>
						<div class="ql-editor bg-light d-flex align-items-center justify-content-center p-4" id="note_details" [style.height.px]="(getRowCount() * 40) + 42" style="min-height:275px;font-size: 16px !important;max-height:442px">
							<div class="h-100 d-flex align-items-center justify-content-center">
								<div class="text-center">
									<i *ngIf="loadingTimeSpent" class="fa-solid fa-spinner text-muted fa-spin"></i>
									<div *ngIf="loadingTimeSpent" class="text-muted one-liner mt-2" style="font-size: 14px">{{'Loading...' | translate}}</div>
									<i *ngIf="!loadingTimeSpent && listHourNews?.length > 0"  class="fa-solid fa-hand-point-up text-muted fa-2x" id="pointUp"></i>
									<i *ngIf="!loadingTimeSpent && listHourNews?.length > 0" class="fa-solid fa-hand-point-left text-muted fa-2x" id="pointLeft"></i>
									<div *ngIf="!loadingTimeSpent && listHourNews?.length > 0" class="text-muted one-liner mt-4">{{'Select a time span to begin' | translate}}</div>
									<i *ngIf="!loadingTimeSpent && listHourNews?.length === 0" class="fa-solid fa-triangle-exclamation text-muted fa-2x" id="pointLeft"></i>
									<div *ngIf="!loadingTimeSpent && listHourNews?.length === 0" class="text-muted one-liner mt-2">{{'no data' | translate}}</div>
								</div>
							</div>
						</div>
					</p-panel>
				</div>
				<div class="ui-g-9 flex-grow-1 px-2" *ngIf="selectedHourItem">

					<p-panel [toggleable]="true" id="table-ppanel">
						<p-header class="flex-grow-1 d-flex align-items-center justify-content-between">
							<span class="one-liner"><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{titleNoteByHour}}</span>
							<span *ngIf="createdAtNoteByHour"><span class="one-liner text-secondary mr-3" pTooltip="{{createdAtNoteByHour | date: 'M-d-yyyy h:mm a'}}" tooltipPosition="left">{{createdAtNoteByHour | date: 'M-d-yyyy h:mm a'}}</span></span>
						</p-header>
						<div *ngIf="!selectedHourItem.editNotDisabled">
							<p-editor formControlName="details" (drop)="handleDrop($event, 'create')" (dragover)="allowDrop($event)" (click)="onEditorClick($event)" #detailsEditor
								(onTextChange)="textChanged($event)" (onInit)="setEditor($event)"
								(closed)="boldMention(); "
								[(ngModel)]="selectedHourItem.note"
								[modules]="quillConfig"
								[style]="{'height': calculateEditorHeight(), 'max-height': '361px'}"
								[attr.disabled]="readOnly || disableInput ? '' : null"
								placeholder="{{'PleaseEnterMinimum25Characters' | translate}}">
				 			 </p-editor>
						</div>

						<div *ngIf="selectedHourItem.editNotDisabled" [innerHTML]="selectedHourItem.note | safeHtml" class="ql-editor bg-light p-4" id="note_details" [style.height.px]="(getRowCount() * 40) + 42" style="font-size: 16px !important"></div>

						<div class="loading-upload" *ngIf="loadingUpload">
							<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60">
									<style>
											.line {
													fill: none;
													stroke: #aaa;
													stroke-width: 8;
													stroke-dasharray: 40
											}

											.animated {
													animation: draw .8s ease-in-out;
													animation-iteration-count: infinite;
													stroke-dashoffset: 0
											}

											.line2 {
													animation-delay: .08s
											}

											.line3 {
													animation-delay: .16s
											}

											@keyframes draw {
													0% {
															stroke-dashoffset: 0
													}

													50% {
															stroke-dashoffset: 30
													}

													100% {
															stroke-dashoffset: 0
													}
											}
									</style>
									<path class="line animated" d="M14 10V50z" />
									<path class="line animated line2" d="M30 10V50z" />
									<path class="line animated line3" d="M46 10V50z" />
							</svg>
						</div>
						<p-footer *ngIf="!selectedHourItem.editNotDisabled && mode !== 'view'" class="d-flex justify-content-between">
							<div>
								<a style="font-size: 1em;" (click)="openCreateWorkOrderDialog()" href="javascript:void(0);">{{'Add Plan/Work Order' | translate}}</a>
								<br/>
								<span style="font-size: 12px;" class="text-danger" *ngIf="onlyWhitespaceError">{{'OnlySpace' | translate}}</span>
								<span style="font-size: 12px;" class="text-danger" *ngIf="exceededMinLength">{{'PleaseEnterMinimum25Characters' | translate}}</span>
							</div>
							<div>
								<span [ngClass]="exceededMaxLength ? 'text-danger' : 'text-muted'" class="pull-right" style="font-size: 12px; float: right;">{{charactersNumber || 0}}/5000</span>
							</div>
						</p-footer>
					</p-panel>
					<br>
					<p-panel [toggleable]="true" id="table-ppanel" *ngIf="showNoteReason">
						<p-header class="flex-grow-1 d-flex align-items-center justify-content-between">
							<span class="one-liner"><i class="fa-solid fa-triangle-exclamation text-orange mr-2"></i>{{'Reason for Missing Update' | translate}}</span>
							<span *ngIf="createdAtNoteByHour"><span class="one-liner text-secondary mr-3" pTooltip="{{createdAtNoteByHour | date: 'MM-dd-yyyy h:mm a'}}" tooltipPosition="left">{{createdAtNoteByHour | date: 'MM-dd-yyyy h:mm a'}}</span></span>
						</p-header>
						<p-editor *ngIf="!selectedHourItem.editNotDisabled" formControlName="detailsReason" (click)="onEditorClick($event)" #detailsEditorReason (onTextChange)="textChangedReason($event)" (onInit)="setEditorReason($event)"
							maxlength="1500" (closed)="boldMentionReason()" [(ngModel)]="selectedHourItem.noteReason" [attr.disabled]="readOnly || disableInput ? '' : null"
							[style]="{'height': '260px'}" placeholder="{{'Enter a brief explanation at least 25 characters in length' | translate}}" style="font-size: 16px;outline:0px;width: 100%">
						</p-editor>
						<div *ngIf="selectedHourItem.editNotDisabled" [innerHTML]="selectedHourItem.noteReason | safeHtml" class="ql-editor bg-light p-4" id="note_details" [style.height.px]="82" style="font-size: 16px !important"></div>
						<p-footer *ngIf="!selectedHourItem.editNotDisabled && mode !== 'view'" class="d-flex justify-content-between">
							<div>
								<span style="font-size: 12px;" class="text-danger" *ngIf="onlyWhitespaceErrorReason">{{'OnlySpace' | translate}}</span>
								<span style="font-size: 12px;" class="text-danger" *ngIf="exceededMinLengthReason">{{'PleaseEnterMinimum25Characters' | translate}}</span>
							</div>
							<div>
								<span class="pull-right text-muted" style="font-size: 12px; float: right;">{{charactersNumberReason || 0}}/1500</span>
							</div>
						</p-footer>
					</p-panel>
				</div>
				<div class="ui-g-12 text-center pt-4">
					<button type="button" class="btn btn-danger mx-1" (click)="onCancel()" ><i class="fa-solid fa-xmark mr-2"></i>{{'button.cancel' | translate}}</button>
					<button type="button" class="btn btn-primary mx-1" [disabled]="isClicked" (click)="update(employeeJobReportForm)" *ngIf="mode === 'edit'"><i class="fa-solid fa-check mr-2"></i>{{'button.submit' | translate}}</button>
					<button type="button" class="btn btn-info mx-1" (click)="enableForm()" *ngIf="mode === 'view'"><i class="fa-solid fa-pen-to-square mr-2"></i>{{'button.edit' | translate}}</button>
					<button type="button" class="btn btn-primary mx-1" [disabled]="isClicked" (click)="add(employeeJobReportForm)" *ngIf="mode === 'create'"><i class="fa-solid fa-check mr-2"></i>{{'button.submit' | translate}}</button>
				</div>
			</div>
			
			<div *ngIf="allEmployeeJobReportNote && allEmployeeJobReportNote.length > 0" class="ui-g mt-4">
				<div class="ui-g-12">
					<div class="d-flex align-items-center justify-content-between">
						<h3 class="m-0 ml-2">{{'Progress Updates' | translate}}</h3>
					</div>
					<div class="mt-4">
						<div *ngFor="let note of allEmployeeJobReportNote;  let i= index" class="py-2">
							<div class="card p-0">
								<div class="card-header d-flex flex-wrap align-items-center justify-content-between">
									<span class="d-flex align-items-center"><i class="fa-solid fa-user-pen text-primary mr-2"></i>{{note.createdByUsr}} : {{note.notesTime}}</span>
									<span class="d-flex align-items-center"><span class="text-secondary">{{note.createdAt | date: 'MM-dd-yyyy h:mm a'}}</span><i class="fa-solid fa-pen-to-square text-primary ml-2" pTooltip="{{'View/Edit' | translate}}" (click)="openUpdateToModal(note, $event)"></i></span>
								</div>
								<div [innerHTML]="note.note | safeHtml" class="p-4" id="note_details" style="background: #f9f9f9"></div>
								<span *ngIf="note?.noteReason" class="font-weight-bold ml-4">R{{'Reason for late or missing update' | translate}}</span>
								<div *ngIf="note?.noteReason" [innerHTML]="note?.noteReason | safeHtml" class="ql-editor p-4" id="note_details" style="background: #f9f9f9"></div>
							</div>
						</div>
					</div>
				</div>
				<div class="ui-g-12 text-center">
					<a type="button" class="btn btn-purple mx-1" href="#top"><i class="fa-solid fa-chevron-up mr-2"></i>{{'Back to Top' | translate}}</a>
				</div>
			</div>

	</form>

   

<p-dialog header="{{'View Document' | translate}}" [(visible)]="showViewFileDialog" [style]="{width: '50vw'}">
    <img class="w-100" [src]="fileUrl" alt="">
</p-dialog>

<p-dialog baseZIndex="1000" [(visible)]="updateToDoModal" [dismissableMask]="true" showEffect="fade" [modal]="true" (onHide)="cancelUpdateModal()" id="editnote" [style]="{'width':'90%', 'max-width':'1000px'}">
	<p-header>
		<span><i class="fa-solid fa-circle-plus mr-2"></i>{{'Edit Progress Update' | translate}} ({{notesTimeEdit}})</span>
	</p-header>
	<div class="d-flex align-items-start" id="report-details" style="row-gap: 20px">
		<div [class.d-none]="mode === 'view'" class="flex-grow-1">
			<p-editor #updateEditor (drop)="handleDrop($event, 'update')" (dragover)="allowDrop($event)" (click)="onEditorClick($event)" [(ngModel)]="nodeToEdit" [style]="{'height':'259px','word-break': 'break-word'}" (onTextChange)="textEditChanged($event)">
				<p-footer class="d-flex justify-content-between">
					<div>
						<span [class.d-none]="mode === 'view'"  style="font-size: 12px;" class="text-danger" *ngIf="onlyWhitespaceError">{{'OnlySpace' | translate}}</span>
						<span [class.d-none]="mode === 'view'" style="font-size: 12px; color: red;" *ngIf="exceededMinLength && !onlyWhitespaceError">{{'PleaseEnterMinimum25Characters' | translate}}</span>
					</div>
					<div>
						<span [class.d-none]="mode === 'view'" class="pull-right text-muted" style="font-size: 12px; float: right;">{{'characters' | translate}}: {{charactersNumber}}</span>
						<span [class.d-none]="mode === 'view'" class="pull-right" style="font-size: 12px; color: red;" *ngIf="exceededMaxLength">{{'maximum5000' | translate}}</span>
					</div>
				</p-footer>
			</p-editor>
		</div>
		<!-- <div class="pl-2" id="time-spent" style="min-width: 213px">

				<div [class.d-none]="mode === 'view'" #checkboxJob class="flex flex-column px-3 custom-scroll" style="height: 301px; overflow: auto; width: 100%;overflow-y:scroll;">
					<span *ngFor="let hour of selectedUpdateHours" class="d-flex align-items-center w-100 my-3">
						<p-checkbox  [inputId]="hour.code" [(ngModel)]="hour.checked" (onChange)="rowCheckedChangeEdit()" [binary]="true"></p-checkbox>
						<label [for]="hour.code" class="ml-2 one-liner">{{ hour.name }}</label>
					</span>
				</div>
		
		</div> -->
	</div>
	<p-footer class="d-flex align-items-center justify-content-between">
		<button type="button" class="btn btn-danger  mr-2" (click)="cancelUpdateModal()"><i class="fa-solid fa-xmark mr-2"></i> {{'Cancel' | translate}}</button>
		<button type="button" class="btn btn-primary " (click)="updateEditNote()"><i class="fa-solid fa-check mr-2"></i> {{'Save' | translate}}</button>
	</p-footer>
</p-dialog>

<p-dialog header="History" *ngIf="showHistoryDialog" [(visible)]="showHistoryDialog" [responsive]="true" showEffect="fade" [style]="{'width': '100%', 'max-width':'900px'}" [closable]="true" [modal]="true">
	<app-employee-job-report-history appendTo="body" [jobId]="employeeJobReportId" [historyType]="historyType"></app-employee-job-report-history>
	<p-footer>
		<button type="button" pButton (click)="showHistoryDialog = false" label="Close"></button>
	</p-footer>
</p-dialog>

<p-dialog header="Preview" *ngIf="showViewImage" [(visible)]="showViewImage" [responsive]="true" showEffect="fade" [style]="{width: '65vw'}" [closable]="false">
	<img [src]="viewFileUrl" id="rotated" style="object-fit: contain;" *ngIf="viewFileUrl" class="imageShown"/>
	<p-footer>
		<div class="ui-dialog-buttonpane ui-helper-clearfix">
          <span style="margin-right: 40%">
              <span style="cursor: pointer;" placeholder="Rotate image" (click)="rotateImage()"><i class="fa fa-repeat" aria-hidden="true"></i></span>
              <span style="margin-left: 10px; cursor: pointer;" placeholder="Zoom In" (click)="zoomin()"><i class="fa fa-search-plus" aria-hidden="true"></i></span>
              <span style="margin-left: 10px; cursor: pointer;" placeholder="Zoom Out" (click)="zoomout()"><i class="fa fa-search-minus" aria-hidden="true"></i></span>
          </span>
			<button style="cursor: pointer;" type="button" pButton (click)="closeViewFileDialog()" label="Close"></button>
		</div>
	</p-footer>
</p-dialog>

<p-dialog id="createWorkOrderId" header="{{'Create Work Order' | translate}}" *ngIf="showCreateWorkOrderDialog" [(visible)]="showCreateWorkOrderDialog" [responsive]="true"
		  showEffect="fade" [style]="{'width': '85%', 'max-width':'1000px', 'height':'75%', 'max-height':'900px'}" [closable]="true" [modal]="true">
	<app-todo-add subMode="employee_job_report" (closePopup)="closeCreateWorkOrderPopup($event)" (popupData)="createWorkOrderPopupData($event)"></app-todo-add>
</p-dialog>
