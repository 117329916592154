import { MENU_ID } from './menu-id';
export class MenuList {

    public menus = [
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.TRANSACTIONS,
                MENU_ID.TRANSACTION_TYPES,
                // MENU_ID.TRANSACTION_ADD,
                MENU_ID.STATEMENT_BALANCE,
                MENU_ID.DISCOUNTS_WAIVERS,
                MENU_ID.TRANSACTION_GROUP,
                MENU_ID.TAXS,
                MENU_ID.RECEIVED_PAYMENTS,
                MENU_ID.PROJECTS,
                MENU_ID.CREDIT_DEBIT_ACCOUNTS,
                MENU_ID.CONTRACTS_LICENSES,
                MENU_ID.LOANS,
                MENU_ID.FOLLOW_UP_BILLS,
                MENU_ID.PAYMENT_TRACKING,
                MENU_ID.PAYROLL_JOURNAL_REPORT,
                MENU_ID.PAYROLL_JOURNAL_PASSWORD,
                MENU_ID.INSURANCE_POLICIES,
                MENU_ID.FILE_EXAMINATION,
                MENU_ID.GENERAL_INVOICES,
                MENU_ID.INVOICE_TYPE,
                MENU_ID.INVOICE_TRACKING,
                MENU_ID.PUNCH_BILLING,
                MENU_ID.REVENUE_PAYMENTS,
                MENU_ID.SERVICE_REVENUE,
                MENU_ID.GROSS_INCOME,
                MENU_ID.AGING_SUMMARY,
                MENU_ID.AGING_DETAILS,
                MENU_ID.CONTRACTOR_PAYROLL,
                MENU_ID.CONTRACTOR_NEW_PAYROLL,
                MENU_ID.BILLING_LOCATION,
                MENU_ID.SMS_MMS_BILLING
            ],
            'name': 'Accounting',
            'label': 'Accounting',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': MENU_ID.TRANSACTIONS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Transactions',
            'label': 'Transactions',
            'routerLink': '/app/transaction/list',
            'order': 95,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        // {
        //     'id': MENU_ID.TRANSACTION_ADD,
        //     'ctrl': 0,
        //     'type': 'child',
        //     'child': [],
        //     'name': 'Add Transaction',
        //     'label': 'Add Transaction',
        //     'routerLink': '/app/transaction/add',
        //     'order': 1,
        //     'checked': false,
        //     'setting': true,
        //     'isActionsAllowed': true,
        //     'showMenu': true,
        //     'editChecked': false,
        //     'deleteChecked': false,
        // },
        {
            'id': MENU_ID.TRANSACTION_TYPES,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Transaction Types',
            'label': 'Transaction Types',
            'routerLink': '/app/transaction/type/add',
            'order': 82,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true,
        },
        {
            'id': MENU_ID.ACCOUNTING,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Accounting',
            'label': 'Accounting',
            'routerLink': '',
            'order': 94,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true,
        },
        {
            'id': MENU_ID.RECEIVED_PAYMENTS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Received Payments',
            'label': 'Received Payments',
            'routerLink': '/app/payment-info/list',
            'order': 96,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.TAXS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Taxs',
            'label': 'Taxs',
            'routerLink': '/app/tax-detail/list',
            'order': 115,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.PROJECTS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Projects',
            'label': 'Projects',
            'routerLink': '/app/project/add',
            'order': 100,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        }, {
            'id': MENU_ID.CREDIT_DEBIT_ACCOUNTS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Credit & Debit Accounts',
            'label': 'Credit & Debit Accounts',
            'routerLink': '/app/account/list',
            'order': 101,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.CONTRACTS_LICENSES,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Contracts & Licenses',
            'label': 'Contracts & Licenses',
            'routerLink': '/app/generic-compliance/list',
            'order': 102,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.PAYMENT_TRACKING,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Payment Tracking',
            'label': 'Payment Tracking',
            'routerLink': '/app/payment-tracking/list',
            'order': 102,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.LOANS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Loans',
            'label': 'Loans',
            'routerLink': '/app/loan/list',
            'order': 104,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.FOLLOW_UP_BILLS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Follow-up Bills',
            'label': 'Follow-up Bills',
            'routerLink': '/app/employee-bill/list',
            'order': 26,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.INSURANCE_POLICIES,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Insurance',
            'label': 'Insurance',
            'routerLink': '/app/insurance/list',
            'order': 1151,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.FILE_EXAMINATION,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'File Examination',
            'label': 'File Examination',
            'routerLink': '/app/file-examination/list',
            'order': 2003,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.GENERAL_INVOICES,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'General Invoices',
            'label': 'General Invoices',
            'routerLink': '/app/general-invoice/list',
            'order': 2004,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.INVOICE_TRACKING,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Invoice Tracking',
            'label': 'Invoice Tracking',
            'routerLink': '/app/invoice-tracking/list',
            'order': 2005,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.CONTRACTOR_NEW_PAYROLL,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'New Payroll',
            'label': 'New Payroll',
            'routerLink': '/app/contractors/new-payroll',
            'order': 1154,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.CONTRACTOR_PAYROLL,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Payroll',
            'label': 'Payroll',
            'routerLink': '/app/contractors/payroll-list',
            'order': 1155,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.SERVICE_REVENUE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'SERVICE REVENUE',
            'label': 'SERVICE REVENUE',
            'routerLink': '/app/service-revenue/list',
            'order': 1159,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.AR_REPORT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'AR Report',
            'label': 'AR Report',
            'routerLink': '/app/revenue-payment/list',
            'order': 1160,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.GROSS_INCOME,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Gross Income',
            'label': 'Gross Income',
            'routerLink': '/app/gross-income/list',
            'order': 1161,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.AGING_DETAILS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Aging Details',
            'label': 'Aging Details',
            'routerLink': '/app/receivable-aging-details/list',
            'order': 1167,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.AGING_SUMMARY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Aging Summary',
            'label': 'Aging Summary',
            'routerLink': '/app/aging-summary/list',
            'order': 1179,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.STATEMENT_BALANCE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Statement & Balance',
            'label': 'Statement & Balance',
            'routerLink': '/app/transaction/statement-balance/list',
            'order': 1672,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.PAYROLL_JOURNAL_REPORT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Payroll Journal Report',
            'label': '',
            'routerLink': '/app/payroll-journal-report/list',
            'order': 1673,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true,
        },
        {
            'id': MENU_ID.PAYROLL_JOURNAL_PASSWORD,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Payroll Journal Password',
            'label': 'label.admin_setting',
            'routerLink': '/app/payroll-journal-report/list',
            'order': 1674,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.PAYSTUB_PIN_NOT_REQUIRED,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'PayStub PIN Not Required',
            'label': 'label.admin_setting',
            'routerLink': '/app/payroll-journal-report/list',
            'order': 1675,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.INVOICE_DISCOUNT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Invoice Discount',
            'label': '',
            'routerLink': '',
            'order': 1676,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.BILLING_LOCATION,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Billing Location',
            'label': 'Billing Location',
            'routerLink': '/billing-location/list',
            'order': 1186,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.SMS_MMS_BILLING,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'SMS/MMS Billing',
            'label': 'SMS/MMS Billing',
            'routerLink': '/sms-mms-billing/list',
            'order': 4422,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.PUNCH_BILLING,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Punch Billing',
            'label': 'Punch Billing',
            'routerLink': '/app/billings/punch-billing/list',
            'order': 2006,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true,
        },
        {
            'id': MENU_ID.DISCOUNTS_WAIVERS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Discounts & Waivers',
            'label': 'Discounts & Waivers',
            'routerLink': '/app/transaction/discount',
            'order': 1,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.TRANSACTION_GROUP,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Add Group Transaction',
            'label': 'Add Group Transaction',
            'routerLink': '/app/transaction/group',
            'order': 1,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.TAX_TYPES,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Tax Types',
            'label': 'Tax Types',
            'routerLink': '/app/tax-info/type/add',
            'order': 1,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.INVOICE_TYPE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Tax Types',
            'label': 'Tax Types',
            'routerLink': '/app/tax-info/type/add',
            'order': 1,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.REVENUE_PAYMENTS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Tax Types',
            'label': 'Tax Types',
            'routerLink': '/app/revenue-payment/list',
            'order': 1,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.PLATFORM_ADMIN,
                MENU_ID.ADMINISTRATORS,
                MENU_ID.PREFERENCES,
                MENU_ID.GROUPS,
                MENU_ID.PROCESS_MONITOR,
                MENU_ID.MONITOR_DETAILS,
                MENU_ID.LOGIN_ACTIVITY,
                MENU_ID.EMAIL_ACTIVITY,
                MENU_ID.EXPORT_MONITOR,
                MENU_ID.COMPANY_TRACKING,
                MENU_ID.DESK_ACTIVITY,
                // MENU_ID.MONITORING,
                MENU_ID.SUSPICIOUS_ACTIVITIES,
                MENU_ID.EMAIL_SUBSCRIPTION,
                MENU_ID.GUESTS,
                MENU_ID.PLANS,
                MENU_ID.PLAN_PREVIEW,
                MENU_ID.MAINTENANCE
            ],
            'name': 'Admin',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': MENU_ID.PLATFORM_ADMIN,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Platform Admin',
            'label': 'label.platform_admin',
            'routerLink': '/app/admin/platform-admin',
            'order': 2,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.ADMINISTRATORS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Administrators',
            'label': 'label.senior_center_admin',
            'routerLink': '/app/admin/agency-admin',
            'order': 4,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.PREFERENCES,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Preferences',
            'label': 'Preferences',
            'routerLink': '/app/admin/agency/setting',
            'order': 98,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.GROUPS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Groups',
            'label': 'label.groups',
            'routerLink': '/app/admin/groups',
            'order': 41,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.PROCESS_MONITOR,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Process Monitor',
            'label': 'label.process_monitor',
            'routerLink': '/app/admin/process-monitor',
            'order': 49,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.MONITOR_DETAILS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Monitor Details',
            'label': 'Monitor Details',
            'routerLink': '/app/admin/process-monitor/monitor-details/list',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.LOGIN_ACTIVITY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Login Activity',
            'label': 'Login Activity',
            'routerLink': '/app/admin/process-monitor/monitor-details/login-activity',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.COMPANY_TRACKING,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Company Tracking',
            'label': 'Company Tracking',
            'routerLink': '/app/admin/process-monitor/company-tracking',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },

        {
            'id': MENU_ID.DESK_ACTIVITY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Av Board',
            'label': 'label.employee_av_board',
            'routerLink': '/app/admin/employee-av-board',
            'order': 50,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.MONITORING,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Monitoring',
            'label': 'Monitoring',
            'routerLink': '/app/admin/monitoring',
            'order': 1032,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.SUSPICIOUS_ACTIVITIES,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Suspicious activities',
            'label': 'Suspicious activities',
            'routerLink': '/app/admin/monitoring/suspicious-activities',
            'order': 1033,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.EMAIL_SUBSCRIPTION,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Email Subscription',
            'label': 'Email Subscription',
            'routerLink': '/app/admin/email-subscription/list',
            'order': 1039,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.EMPLOYEE_MENU_INACTIVITY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Menu & Inactivity',
            'label': '',
            'routerLink': '',
            'order': 108,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.EMAIL_ACTIVITY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Email Activity',
            'label': 'label.groups',
            'routerLink': '/app/admin/process-monitor/email-activity',
            'order': 43,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.EXPORT_MONITOR,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Export Monitor',
            'label': 'label.groups',
            'routerLink': '/app/admin/process-monitor/export-monitor',
            'order': 1040,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.SMS_VALIDATION,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'SMS Validation',
            'label': 'label.groups',
            'routerLink': '/app/admin/process-monitor/export-monitor',
            'order': 1040,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.PLAN_PREVIEW,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Plan Preview',
            'label': '',
            'routerLink': '/app/plan/preview',
            'order': 1189,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.PLANS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Plans',
            'label': '',
            'routerLink': '/app/plan/list',
            'order': 1190,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.MAINTENANCE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Maintenance',
            'label': '',
            'routerLink': '/app/maintenance/list',
            'order': 1191,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.EMPLOYEES,
                MENU_ID.ADD_EMPLOYEE,
                MENU_ID.INTERN,
                MENU_ID.VOLUNTEER,
                MENU_ID.EMPLOYEE_DOCUMENT,
                MENU_ID.EMPLOYEE_DOCUMENT_HISTORY,
                MENU_ID.EMPLOYEE_ON_DUTY,
                MENU_ID.PUNCH_IN_OUT,
                MENU_ID.PUNCH_HISTORY,
                MENU_ID.PUNCTUALITY,
                MENU_ID.PUNCH_TRACKING,
                MENU_ID.WORK_TIME_CONFIRMATION,
                MENU_ID.EMPLOYEE_TIME_OFF,
                MENU_ID.EMPLOYEE_CALENDAR,
                MENU_ID.EMPLOYEE_PAYMENT,
                MENU_ID.TIME_SHEET,
                MENU_ID.EMPLOYEE_TIME_CORRECTION,
                MENU_ID.PAYSTUB,
                MENU_ID.EXPENSE,
                MENU_ID.EMPLOYEE_EVALUATION,
                MENU_ID.EMPLOYEE_SURVEY,
                MENU_ID.PHONE_DIRECTORY,
                MENU_ID.EMPLOYEE_JOB_REPORT,
                MENU_ID.EMPLOYEE_TIME_HISTORY,
                MENU_ID.EMPLOYEE_SCHEDULER,
                MENU_ID.HIDE_PAY_RATE_OT_RATE,
                MENU_ID.SUMMARY_VIEW,
                MENU_ID.EMPLOYEE_OT,
                MENU_ID.OVERTIME_REQUEST,
                MENU_ID.SSN_VIEW,
                MENU_ID.VIEW_DETAILS_REPORT,
            ],
            'name': 'Employee',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false
        },
        {
            'id': MENU_ID.EMPLOYEES,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Profile',
            'label': 'label.employee_profile',
            'routerLink': '/app/employee',
            'order': 21,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.ADD_EMPLOYEE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Add Employee',
            'label': 'label.employee_add',
            'routerLink': '/app/employee/add',
            'order': 99,
            'checked': false,
            'setting': true,
            'employeeMenu': false,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.EMPLOYEE_NOTE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Note',
            'label': 'Employee Note',
            'routerLink': '/app/employee/note',
            'order': 1,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.EMPLOYEE_DOCUMENT_HISTORY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Document History',
            'label': 'Employee Document History',
            'routerLink': '',
            'order': 1192,
            'checked': false,
            'setting': false,
            'isActionsAllowed': false,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.INTERN,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Intern',
            'label': 'Intern',
            'routerLink': '/app/intern/list',
            'order': 1030,
            'checked': false,
            'setting': true,
            'employeeMenu': false,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.VOLUNTEER,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Volunteer',
            'label': 'Volunteer',
            'routerLink': '/app/volunteer/list',
            'order': 1031,
            'checked': false,
            'setting': true,
            'employeeMenu': false,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.MY_CONTACT_LIST,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'My Contact List',
            'label': 'label.employee_add',
            'routerLink': '/app/employee/my-contact/list',
            'order': 51,
            'checked': false,
            'setting': true,
            'employeeMenu': false,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.PUNCH_IN_OUT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Punch In/Out',
            'label': 'label.punch in/out',
            'routerLink': '/app/employee-clocking/punch',
            'order': 22,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.PUNCTUALITY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Punctuality',
            'label': 'Punctuality',
            'routerLink': '',
            'order': 1690,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.PUNCH_HISTORY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Punch History',
            'label': 'label.punch_history',
            'routerLink': '/app/employee-clocking/punch-history',
            'order': 23,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.EMPLOYEE_TIME_OFF,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Time-Off Requests',
            'label': 'Time-Off Requests',
            'routerLink': '/app/absence/list',
            'order': 25,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.WORK_TIME_CONFIRMATION,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Work Time Confirmation',
            'label': 'Work Time Confirmation',
            'routerLink': '/app/employee-clocking/work-confirmation',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.EMPLOYEE_CALENDAR,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Calendar',
            'label': 'label.employee_calendar',
            'routerLink': '/app/absence/calendar',
            'order': 106,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.PERFORMANCE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Performance',
            'label': 'label.performance',
            'routerLink': '/app/performance/list',
            'order': 44,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.SUSPENSION_RECORDS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Suspension Records',
            'label': 'Suspension Records',
            'routerLink': '/app/performance/suspension-record/list',
            'order': 44,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.EMPLOYEE_EVALUATION,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Performance',
            'label': 'label.employee_performance',
            'routerLink': '/app/performance/employee-performance',
            'order': 107,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        ,
        {
            'id': MENU_ID.EMPLOYEE_SURVEY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Survey',
            'label': 'Employee Survey',
            'routerLink': '/app/performance/employee-survey',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.EMPLOYEE_TIME_CORRECTION,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Time Correction',
            'label': 'label.protest_hour',
            'routerLink': '/app/employee/protest-hour',
            'order': 45,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.TIME_SHEET,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Time Sheet',
            'label': 'label.time_sheet',
            'routerLink': '/app/employee/timesheet',
            'order': 47,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.PAYSTUB,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Paystub',
            'label': 'Paystub',
            'routerLink': '/app/employee/paystub',
            'order': 500,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.PAYMENT_DETAILS_ADD_EMPLOYEE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Payment Details | Add Employee',
            'label': '',
            'routerLink': '',
            'order': 72,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true
        },
        {
            'id': MENU_ID.HIDE_PAY_RATE_OT_RATE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Hide Pay Rate | OT Rate',
            'label': '',
            'routerLink': '',
            'order': 81,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.SUMMARY_VIEW,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Summary View',
            'label': '',
            'routerLink': '',
            'order': 73,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true
        },
        {
            'id': MENU_ID.EMPLOYEE_OT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee OT',
            'label': '',
            'routerLink': '',
            'order': 74,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true
        },

        {
            'id': MENU_ID.OVERTIME_REQUEST,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Overtime Requests',
            'label': '',
            'routerLink': '',
            'order': 55,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true,
        },
        {
            'id': MENU_ID.SSN_VIEW,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'SSN View',
            'label': '',
            'routerLink': '',
            'order': 705,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true,
        },
        // {
        //   'id': MENU_ID.ADD_ABSENCE,
        //   'ctrl': 0,
        //   'type': 'child',
        //   'child': [],
        //   'name': 'Add Absence',
        //   'label': '',
        //   'routerLink': '',
        //   'order': 707,
        //   'checked': false,
        //   'setting': true,
        //   'isActionsAllowed': true,
        //   'editChecked': false,
        //   'deleteChecked': false,
        // },

        {
            'id': MENU_ID.ADD_ABSENCE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Add Absence',
            'label': 'label.add_absence',
            'routerLink': '/app/employee-clocking/punch-history',
            'order': 707,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.VIEW_DETAILS_REPORT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'View Details Report',
            'label': '',
            'routerLink': '/app/chat-box',
            'order': 1100,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.PHONE_DIRECTORY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Phone Directory',
            'label': '',
            'routerLink': '/app/employee/phone-directory',
            'order': 1158,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.PUNCH_TRACKING,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Punch Tracking',
            'label': '',
            'routerLink': '/app/employee-clocking/punch-tracking',
            'order': 1171,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.EMPLOYEE_JOB_REPORT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Job Report',
            'label': '',
            'routerLink': '/app/employee/employee-job-report/list',
            'order': 1028,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.EMPLOYEE_TIME_HISTORY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Time history',
            'label': '',
            'routerLink': '/app/employee/employee-time-history',
            'order': 1035,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.EMPLOYEE_SCHEDULER,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Scheduler',
            'label': '',
            'routerLink': '/app/employee/scheduler',
            'order': 1180,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.EXPENSE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Expense',
            'label': '',
            'routerLink': 'app/employee/expense/list',
            'order': 71,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.EMPLOYEE_ON_DUTY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee On Duty',
            'label': '',
            'routerLink': '/app/employee-onduty',
            'order': 1138,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.COMPLIANCE_REPORTS,
                MENU_ID.PERFORMANCE_REPORTS,
                MENU_ID.REPORT_TEMPLATES,
                MENU_ID.OPERRTEL_REPORTS,
                MENU_ID.API_USAGE_REPORT
            ],
            'name': 'Report',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': MENU_ID.OPERRTEL_REPORTS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Operrtel Reports',
            'label': 'Operrtel Reports',
            'routerLink': null,
            'order': 75,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.REPORT_TEMPLATES,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Report Templates',
            'label': '',
            'routerLink': '/app/reports/report-template/list',
            'order': 97,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.PERFORMANCE_REPORTS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Performance reports',
            'label': 'Performance reports',
            'routerLink': '/app/reports/job-report',
            'order': 69,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.COMPLIANCE_REPORTS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Compliance Reports',
            'label': 'Compliance Reports',
            'routerLink': '/app/reports/compliance-report',
            'order': 76,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.API_USAGE_REPORT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'API Usage Report',
            'label': '',
            'routerLink': '/app/reports/api-usage-report',
            'order': 85,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.CRM,
                MENU_ID.IMPORT_CLIENTS,
                MENU_ID.CLIENT_DASHBOARD,
                MENU_ID.CLIENT_CONTRACT,
                MENU_ID.CLIENT_TRANSFERRED,
                MENU_ID.CRM_DOCUMENTS,
                MENU_ID.CRM_EVENTS,
                MENU_ID.CRM_EVENTS_TYPE,
                MENU_ID.CRM_CALENDAR,
                MENU_ID.MANAGE_PIPELINE,
                MENU_ID.SALES_PIPELINE,
                MENU_ID.TRANSFER_RESHUFFLE,
                MENU_ID.CRM_LABEL_FLAGS,
                MENU_ID.CRM_PRODUCT,
                MENU_ID.CLIENT_USER_ACCOUNT,
                MENU_ID.BILLING_CLIENT_LOG,
                MENU_ID.CAMPAIGN,
                MENU_ID.CRM_LEAD,
                MENU_ID.CAMPAIGN_OPPORTUNITY,
                MENU_ID.CAMPAIGN_MARKETING,
                MENU_ID.DRIP_CAMPAIGN,
                MENU_ID.ACTIVITY_LOG,
                MENU_ID.EMAIL_TEMPLATE,
                MENU_ID.LINKED_ACCOUNTS,
                MENU_ID.EVV_REGISTERED,
                MENU_ID.CALL_NOTES,
                MENU_ID.CALL_RECORDS,
                MENU_ID.CRM_OPERRTEL_CONTACT_LISTS,
                MENU_ID.TIMER_RECORDS
            ],
            'name': 'CRM',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': MENU_ID.CRM,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'CRM',
            'label': 'label.crm',
            'routerLink': '/app/crm',
            'order': 30,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.TRANSFER_RESHUFFLE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Transfer & Reshuffle',
            'label': '',
            'routerLink': '',
            'order': 80,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.CLIENT_TRANSFERRED,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Client Transferred',
            'label': 'label.crm',
            'routerLink': '/app/crm/client/transfer-acceptance/',
            'order': 51,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.EVV_REGISTERED,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'EVV Registered',
            'label': 'label.crm',
            'routerLink': '/app/crm/evv/search',
            'order': 52,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.CRM_EVENTS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Events',
            'label': '',
            'routerLink': '/app/crm/event/list',
            'order': 1003,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.CRM_EVENTS_TYPE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Events Type',
            'label': '',
            'routerLink': '/app/crm/event-type/list',
            'order': 1004,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.CRM_CALENDAR,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Calendar',
            'label': '',
            'routerLink': '/app/crm/event/calendar',
            'order': 1005,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.MANAGE_PIPELINE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Manage Pipeline',
            'label': '',
            'routerLink': '/app/crm/addPipeline',
            'order': 1006,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.SALES_PIPELINE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Sales Pipeline',
            'label': 'Sales Pipeline',
            'routerLink': '/app/crm/pipeline',
            'order': 1008,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.CRM_LABEL_FLAGS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Flags',
            'label': '',
            'routerLink': '/app/crm/flags',
            'order': 1009,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.CRM_PRODUCT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Product',
            'label': 'label.crm',
            'routerLink': '/app/crm/products/list',
            'order': 66,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.CAMPAIGN,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Campaign',
            'label': 'label.crm',
            'routerLink': '/app/crm/campaign/list',
            'order': 1101,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        // {
        //     'id': MENU_ID.LEAD_LIST,
        //     'ctrl': 0,
        //     'type': 'child',
        //     'child': [],
        //     'name': 'Lead List',
        //     'label': 'label.crm',
        //     'routerLink': '/app/crm/campaign/potential/list',
        //     'order': 1132,
        //     'checked': false,
        //     'setting': true,
        //     'isActionsAllowed': true,
        //     'showMenu': true,
        //     'editChecked': false,
        //     'deleteChecked': false,
        // },
        {
            'id': MENU_ID.CRM_LEAD,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Lead',
            'label': 'label.crm',
            'routerLink': '/app/crm/campaign/potential/add',
            'order': 1133,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.CAMPAIGN_MARKETING,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Marketing',
            'label': 'label.crm',
            'routerLink': '/app/crm/campaign/marketing',
            'order': 1134,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.CAMPAIGN_OPPORTUNITY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Opportunity',
            'label': 'label.crm',
            'routerLink': '/app/crm/campaign/opportunity/list',
            'order': 1135,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.ACTIVITY_LOG,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Activity Log',
            'label': 'label.crm',
            'routerLink': '/app/crm/activity-log/list',
            'order': 1136,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.CLIENT_USER_ACCOUNT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Client User Account',
            'label': '',
            'routerLink': '/app/client-account/list',
            'order': 1137,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.BILLING_CLIENT_LOG,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Billing Client Log',
            'label': '',
            'routerLink': '/app/job-follow-up/list',
            'order': 1012,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.IMPORT_CLIENTS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Import Clients',
            'label': '',
            'routerLink': '',
            'order': 109,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.EMAIL_TEMPLATE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Email Template',
            'label': '',
            'routerLink': '/app/crm/email-template/list',
            'order': 1139,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.LINKED_ACCOUNTS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Client Info Account',
            'label': '',
            'routerLink': '/app/client-info-account/list',
            'order': 1153,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.CLIENT_CONTRACT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Client Contract',
            'label': '',
            'routerLink': '/app/crm/client/contract/list',
            'order': 1157,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.LINKED_ACCOUNTS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Linked Accounts',
            'label': 'Linked Accounts',
            'routerLink': '/app/client-info-account/list',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.CALL_NOTES,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Call Notes',
            'label': 'Call Notes',
            'routerLink': '/app/crm/call-note',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.CALL_NOTES,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Call Records',
            'label': 'Call Records',
            'routerLink': '/app/crm/call-record',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.CRM_OPERRTEL_CONTACT_LISTS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Operrtel Contact List',
            'label': 'Operrtel Contact List',
            'routerLink': '/app/crm/operrtel/contacts-list',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.TIMER_RECORDS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Timer Records',
            'label': 'Timer Records',
            'routerLink': '/app/crm/timer-records',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.EMPLOYEE_PAYMENT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Payment',
            'label': 'label.payment',
            'routerLink': '/app/employee/payment',
            'order': 24,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.ELECTORAL_CAMPAIGN,
                MENU_ID.VOTER,
                MENU_ID.WALKER,
                MENU_ID.ZONE_MANAGEMENT,
                MENU_ID.CAMPAGN_CANDIDATE,
                MENU_ID.SCHEDULE_BATCH,
                MENU_ID.FLYER,
                MENU_ID.VISIT_HISTORY,
                MENU_ID.VOTER_IMPORT_HISTORY,
                MENU_ID.CUSTOMIZE_QUESTIONS,
                MENU_ID.FUNDRAISERS,
                MENU_ID.ZONE_PREFERENCES
            ],
            'name': 'VRM',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        
        {
            'id': MENU_ID.ELECTORAL_CAMPAIGN,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Electoral Campaign',
            'label': '',
            'routerLink': '/app/crm/campaign/electoral/list',
            'order': 1169,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.VOTER,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Voter',
            'label': '',
            'routerLink': '/app/ele-campaign/voter/list',
            'order': 1163,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.WALKER,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Walker\'s List',
            'label': '',
            'routerLink': '/app/ele-campaign/walker/list',
            'order': 1164,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.ZONE_MANAGEMENT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Zone Management',
            'label': '',
            'routerLink': '/app/ele-campaign/zone-management/list',
            'order': 1165,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'ctrl': 0,
            'id': MENU_ID.CAMPAGN_CANDIDATE,
            'type': 'child',
            'child': [],
            'name': 'Candidate',
            'label': '',
            'routerLink': '/app/ele-campaign/candidate/list',
            'order': 1166,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.SCHEDULE_BATCH,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Schedule Batch',
            'label': '',
            'routerLink': '/app/ele-campaign/schedule-batch',
            'order': 1168,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.FLYER,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Flyer',
            'label': '',
            'routerLink': '/app/ele-campaign/flyer/list',
            'order': 1170,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.VISIT_HISTORY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Visit History',
            'label': '',
            'routerLink': '/app/ele-campaign/visit-history/list',
            'order': 2341,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.VOTER_IMPORT_HISTORY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Voter Import History',
            'label': '',
            'routerLink': '/app/ele-campaign/voter-upload-history/list',
            'order': 3158,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.CUSTOMIZE_QUESTIONS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Customize Questions',
            'label': '',
            'routerLink': '/app/vrm/customize-questions/list',
            'order': 1172,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.FUNDRAISERS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Fundraisers',
            'label': '',
            'routerLink': '/app/vrm/fundraisers/list',
            'order': 1173,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.ZONE_PREFERENCES,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Zone Preferences',
            'label': '',
            'routerLink': '/app/vrm/fundraisers/list',
            'order': 1692,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.BILLING,
                MENU_ID.ADD_CLIENT_BILLING_DETAILS,
                MENU_ID.INVOICE_TEMPLATE,
                MENU_ID.DISPATCH_BILLING,
                MENU_ID.PARKING_BILLING,
                MENU_ID.PARKING_INVOICES,
                MENU_ID.CLIENTS_BASES,
                MENU_ID.DISPATCH_API_USAGE,
                MENU_ID.PROCESS_PAYMENT,
                MENU_ID.PAYMENT_METHOD,
                MENU_ID.PAYMENT_AVAILABILITY,
                MENU_ID.PAYMENTS,
                MENU_ID.VIEW_PAYMENT_METHOD,
                MENU_ID.FOLLOW_UPS,
                MENU_ID.CLIENT_SELF_PAYMENT,
                MENU_ID.CLIENT_CREDITS,
                MENU_ID.MONTHLY_FEE,
                MENU_ID.INVOICE,
                MENU_ID.OLD_INVOICE,
                MENU_ID.INVOICE_VERIFICATION,
                MENU_ID.INVOICE_SUMMARY,
                MENU_ID.WALLET,
                MENU_ID.WALLET_TRANSACTION
            ],
            'name': 'Payment',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': MENU_ID.MONTHLY_FEE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Monthly Fee',
            'label': 'Monthly Fee',
            'routerLink': '/app/monthly-fee/list',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.PROCESS_PAYMENT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Process Payment',
            'label': 'label.crm',
            'routerLink': '/app/payments',
            'order': 59,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.INVOICE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Invoice',
            'label': 'label.crm',
            'routerLink': '/app/payments/invoice',
            'order': 65,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.BILLING,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Billing',
            'label': 'label.crm',
            'routerLink': '/app/billings/list',
            'order': 67,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.ADD_CLIENT_BILLING_DETAILS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Add Client Billing Details',
            'label': 'label.crm',
            'routerLink': '/app/billings/add',
            'order': 112,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.INVOICE_TEMPLATE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Invoice Template',
            'label': 'label.crm',
            'routerLink': '/app/billings/invoice-template/list',
            'order': 110,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.DISPATCH_BILLING,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Dispatch Billing',
            'label': 'label.crm',
            'routerLink': '/app/billings/dispatch/list',
            'order': 113,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.OLD_INVOICE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Old Invoice',
            'label': 'label.crm',
            'routerLink': '/app/payments/old-invoice',
            'order': 1013,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.CLIENT_CREDITS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Client Credits',
            'label': 'label.crm',
            'routerLink': '/app/payments/available-credit',
            'order': 1016,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.FOLLOW_UPS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Follow-Ups',
            'label': 'label.crm',
            'routerLink': '/app/payments/follow-up-note',
            'order': 1014,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.VIEW_PAYMENT_METHOD,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'View Payment Method',
            'label': 'label.crm',
            'routerLink': '/app/payments/view-payment-method/list',
            'order': 1120,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.VIEW_PAYMENT_METHOD,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Parking Billing',
            'label': 'Parking Billing',
            'routerLink': '/app/billings/parking/list',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.PARKING_INVOICES,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Parking Invoice',
            'label': 'Parking Invoice',
            'routerLink': '/app/billings/parking/invoice',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.CLIENTS_BASES,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Clients & Bases',
            'label': 'Clients & Bases',
            'routerLink': '/app/billings/map-client-base',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.DISPATCH_API_USAGE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'API Usage',
            'label': 'API Usage',
            'routerLink': '/app/billings/dispatch/api-usage',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.PAYMENT_AVAILABILITY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Payment Availability',
            'label': 'Payment Availability',
            'routerLink': '/app/payments/payment-availability',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.PAYMENTS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Payments',
            'label': 'Payments',
            'routerLink': '/app/payments/detail/list',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.WALLET,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Wallet',
            'label': 'wallet',
            'routerLink': '/app/payments/wallet',
            'order': 1666,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.WALLET_TRANSACTION,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Wallet Transaction',
            'label': 'wallet Transaction',
            'routerLink': '/app/payments/wallet-transaction',
            'order': 1667,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.CLIENT_SELF_PAYMENT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Client Self Payment',
            'label': 'Client Self Payment',
            'routerLink': '/app/payments/client-self-payment',
            'order': 1668,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.INVOICE_VERIFICATION,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Invoice Verification',
            'label': 'Invoice Verification',
            'routerLink': '/app/payments/invoice-verification',
            'order': 1678,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.INVOICE_SUMMARY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Invoice Summary',
            'label': 'Invoice Summary',
            'routerLink': '/app/payments/invoice-summary',
            'order': 1679,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.QUOTE,
                MENU_ID.LAND_PROJECT,
                MENU_ID.SERVICE_RATE,
                MENU_ID.SERVICE_PROVIDER
            ],
            'name': 'Survey Land',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': MENU_ID.QUOTE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Quote',
            'label': 'Quote',
            'routerLink': '/app/quote/list',
            'order': 1670,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.LAND_PROJECT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Land Project',
            'label': 'Land Project',
            'routerLink': '/app/lander/list',
            'order': 1671,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.SERVICE_RATE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Service Rate',
            'label': 'Service Rate',
            'routerLink': '/app/service-rate/list',
            'order': 72,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.SERVICE_PROVIDER,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Service Provider',
            'label': 'Service Provider',
            'routerLink': '/app/service-provider/list',
            'order': 72,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.PHONE_PACKAGE,
                MENU_ID.CLIENT_PACKAGE,
                MENU_ID.PHONE_SYSTEM_CONTRACT,
                MENU_ID.PHONE_INVOICE,
                MENU_ID.PHONE_PAYMENT,
                MENU_ID.PHONE_SYSTEM_SEND_SMS,
                MENU_ID.SMS_USAGE,
                MENU_ID.BULK_SMS,
                MENU_ID.AUTO_DIAL,
                MENU_ID.PHONE_SYSTEM_SCHEDULE,
                MENU_ID.PHONE_SYSTEM_TOP_UP,
                MENU_ID.MY_MESSAGE,
                MENU_ID.OPERRTEL_GROUP,
                MENU_ID.DRIP_CAMPAIGN,
                MENU_ID.SHORTEN_URL,
                MENU_ID.OPERRTEL_CONTACT_LISTS
            ],
            'name': 'OperrTel',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': true,
            'deleteChecked': true,
            'disableParent': false,
        },
        {
            'id': MENU_ID.OPERRTEL,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'OperrTel',
            'label': 'label.crm',
            'routerLink': null,
            'order': 57,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.PHONE_PACKAGE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Phone Package',
            'label': 'label.crm',
            'routerLink': '/app/phonesystem/list',
            'order': 58,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.PHONE_SYSTEM_CONTRACT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Contract',
            'label': 'label.crm',
            'routerLink': '/app/phonesystem/contract',
            'order': 68,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.PHONE_INVOICE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Phone Invoice',
            'label': 'label.crm',
            'routerLink': '/app/phonesystem/invoice',
            'order': 63,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.PHONE_PAYMENT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Phone Payment',
            'label': 'label.crm',
            'routerLink': '/app/payment',
            'order': 61,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.PHONE_SYSTEM_SEND_SMS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Send SMS',
            'label': 'label.crm',
            'routerLink': '/app/phonesystem/send-sms',
            'order': 1027,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.BULK_SMS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Bulk SMS',
            'label': 'label.crm',
            'routerLink': '/app/phonesystem/bulk-sms',
            'order': 1182,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.PHONE_SYSTEM_SCHEDULE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Schedule',
            'label': 'label.crm',
            'routerLink': '/app/phonesystem/schedule-list',
            'order': 1183,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.SMS_USAGE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'SMS Usage',
            'label': 'label.crm',
            'routerLink': '/app/phonesystem/sms-monitoring',
            'order': 1036,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.AUTO_DIAL,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Auto Dial',
            'label': 'label.crm',
            'routerLink': '/app/phonesystem/auto-dial',
            'order': 1184,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.PHONE_SYSTEM_TOP_UP,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Top up',
            'label': 'label.crm',
            'routerLink': '/app/phonesystem/top-up',
            'order': 1037,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.MY_MESSAGE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'My Message',
            'label': 'label.crm',
            'routerLink': '/app/phonesystem/my-message',
            'order': 1185,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.OPERRTEL_GROUP,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'OperrTel Group',
            'label': '',
            'routerLink': '/app/ele-campaign/vrm-group/list',
            'order': 2342,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.DRIP_CAMPAIGN,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'DRIP Campaign',
            'label': '',
            'routerLink': '/app/phonesystem/drip-campaign/list',
            'order': 2343,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.CLIENT_PACKAGE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Client Package',
            'label': 'Client Package',
            'routerLink': '/app/phonesystem/package/list',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.SHORTEN_URL,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Shorten URL',
            'label': 'Shorten URL',
            'routerLink': '/app/phonesystem/shorten-url',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.CRM_CONTACTS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Contacts',
            'label': '',
            'routerLink': '/app/crm/contact/list',
            'order': 2344,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.MY_CONTACT_LIST,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'My Contact List',
            'label': 'label.employee_add',
            'routerLink': '/app/employee/my-contact/list',
            'order': 51,
            'checked': false,
            'setting': true,
            'employeeMenu': false,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        // {
        //     'id': null,
        //     'ctrl': 0,
        //     'type': 'parent',
        //     'child': [
        //         MENU_ID.MAP_CLIENT_BASE
        //     ],
        //     'name': 'Billing',
        //     'label': '',
        //     'routerLink': '',
        //     'order': 1,
        //     'checked': false,
        //     'setting': true,
        //     'isActionsAllowed': true,
        //     'showMenu': true,
        //     'editChecked': false,
        //     'deleteChecked': false,
        //     'disableParent': false,
        // },
        {
            'id': MENU_ID.MAP_CLIENT_BASE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Map Client/Base',
            'label': 'label.company',
            'routerLink': '/app/billing/map-client-base',
            'order': 1121,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.COMPANY_PROFILE,
                MENU_ID.COMPANY_SETTING,
                MENU_ID.COMPANY_CONTRACT,
                MENU_ID.PAYROLL_SETTING,
                MENU_ID.OTHER_SETTING,
                MENU_ID.COMPANY_HOLIDAY,
                MENU_ID.DOCUMENT_SETTING,
                MENU_ID.COMPANY_PAYMENT,
                MENU_ID.COMPANY_PAYMENT_METHOD,
                MENU_ID.OPERATIONAL_POLICY,
                MENU_ID.DEPARTMENT,
                MENU_ID.POSITION,
                MENU_ID.COMPANY_CONTENT,
                MENU_ID.CONFERENCE_ROOM,
                MENU_ID.SHARE_HOLDER,
                MENU_ID.NOTIFICATION,
                MENU_ID.PWD_MANAGEMENT,
                MENU_ID.API_SETTING,
                MENU_ID.OPERRTEL_POOL,
                MENU_ID.PACKAGE_SERVICE_PLAN,
                MENU_ID.OPERRSIGN,
                MENU_ID.LOCATION_MANAGEMENT
            ],
            'name': 'Operation',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': MENU_ID.NOTIFICATION,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Notification',
            'label': 'label.crm',
            'routerLink': '/app/notification',
            'order': 1056,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.COMPANY_PROFILE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Company Profile',
            'label': 'label.company',
            'routerLink': '/app/company',
            'order': 5,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        // {
        //   'id': MENU_ID.TASK_MANAGEMENT,
        //   'ctrl': 0,
        //   'type': 'child',
        //   'child': [],
        //   'name': 'Company Settings',
        //   'label': '',
        //   'routerLink': '/app/company/setting',
        //   'order': 91,
        //   'checked': false,
        //   'setting': true,
        //   'isActionsAllowed': false,
        //   'editChecked': false,
        //   'deleteChecked': false,
        //   'disableDeleteChecked': false,
        // },
        {
            'id': MENU_ID.DEPARTMENT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Department',
            'label': 'label.department',
            'routerLink': '/app/department',
            'order': 7,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.POSITION,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Position',
            'label': 'label.position',
            'routerLink': '/app/position',
            'order': 8,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.COMPANY_CONTENT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Content',
            'label': 'label.content',
            'routerLink': '/app/content',
            'order': 9,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.CONFERENCE_ROOM,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Conference Room',
            'label': '',
            'routerLink': '/app/conference-room',
            'order': 1011,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.SHARE_HOLDER,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Share Holder',
            'label': '',
            'routerLink': '/app/share-holder/list',
            'order': 1011,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.PWD_MANAGEMENT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'PWD management',
            'label': '',
            'routerLink': '/app/pwdmng/list',
            'order': 1059,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.DOCUMENT_SETTING,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Document Setting',
            'label': '',
            'routerLink': '/app/company/document-settings/list',
            'order': 1176,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.API_SETTING,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'API Setting',
            'label': '',
            'routerLink': '/app/google-api-setting',
            'order': 86,
            'checked': false,
            'setting': false,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.OPERRTEL_POOL,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'OperrTel Pool',
            'label': '',
            'routerLink': '/app/operrtel-pool/list',
            'order': 1178,
            'checked': false,
            'setting': false,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.PACKAGE_SERVICE_PLAN,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Package Service/Plan',
            'label': '',
            'routerLink': '/app/package-service-plan/list',
            'order': 1181,
            'checked': false,
            'setting': false,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.OPERRSIGN,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'OperrSign',
            'label': '',
            'routerLink': '/app/doc-sign',
            'order': 1177,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.LOCATION_MANAGEMENT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Location Management',
            'label': '',
            'routerLink': '/app/location-management/list',
            'order': 1187,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.COMPANY_HOLIDAY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Holiday',
            'label': 'Holiday',
            'routerLink': '/app/company/holiday',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.COMPANY_DOCUMENT_SETTING,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Document Setting',
            'label': 'Document Setting',
            'routerLink': '/app/company/document-settings/list',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.COMPANY_PAYMENT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Bill & Payments',
            'label': 'Bill & Payments',
            'routerLink': '/app/company/company-payment',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.COMPANY_PAYMENT_METHOD,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Payment Method',
            'label': 'Payment Method',
            'routerLink': '/app/company/company-payment-method',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.OPERATIONAL_POLICY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Operational Policy',
            'label': 'Operational Policy',
            'routerLink': '/app/company/operational-policy',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.COMPANY_SETTING,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Company Setting',
            'label': '',
            'routerLink': '',
            'order': 1687,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.PAYROLL_SETTING,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Payroll Setting',
            'label': '',
            'routerLink': '',
            'order': 1688,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.OTHER_SETTING,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Other Setting',
            'label': '',
            'routerLink': '',
            'order': 1689,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.DASHBOARD,
                MENU_ID.OVERTIME_DASHBOARD,
                MENU_ID.OT_PREVENTION,
                MENU_ID.CLIENT_DASHBOARD,
                MENU_ID.SURVEY_DASHBOARD,
                MENU_ID.CRM_DASHBOARD,
                MENU_ID.CEO_DASHBOARD,
                MENU_ID.VRM_DASHBOARD,
                MENU_ID.OPERRTEL_DASHBOARD,
                MENU_ID.GLOBAL_DASHBOARD,
                MENU_ID.EMPLOYEE_DASHBOARD
            ],
            'name': 'Dashboard',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': MENU_ID.DASHBOARD,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Dashboard',
            'label': 'label.dashboard',
            'routerLink': '/app/dashboard',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.OVERTIME_DASHBOARD,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Overtime',
            'label': 'label.overtime',
            'routerLink': '/app/overtime/list',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.OT_PREVENTION,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'OT Prevention',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true
        },
        {
            'id': MENU_ID.CLIENT_DASHBOARD,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Client Dashboard',
            'label': 'Client Dashboard',
            'routerLink': '/app/client/dasboard',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.SURVEY_DASHBOARD,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Survey Dashboard',
            'label': 'Survey Dashboard',
            'routerLink': '/app/survey/dasboard',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.CRM_DASHBOARD,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'CRM Dashboard',
            'label': 'CRM Dashboard',
            'routerLink': '/app/invoice/dasboard',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.CEO_DASHBOARD,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'CEO Dashboard',
            'label': 'CEO Dashboard',
            'routerLink': '/app/ceo/dashboard',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.VRM_DASHBOARD,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'VRM Dashboard',
            'label': '',
            'routerLink': '/app/voter/dashboard',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.OPERRTEL_DASHBOARD,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Operrtel Dashboard',
            'label': '',
            'routerLink': '/app/operrtel/dashboard',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.GLOBAL_DASHBOARD,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Global Dashboard',
            'label': 'Global Dashboard',
            'routerLink': '/app/resident/dashboard',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': MENU_ID.EMPLOYEE_DASHBOARD,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Dashboard',
            'label': 'Employee Dashboard',
            'routerLink': '/app/employee-dashboard',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.SOFTPHONE,
                MENU_ID.WORK_ORDER,
                MENU_ID.COLORS,
                MENU_ID.LAYOUT_PALETTE,
                MENU_ID.THEMES,
                MENU_ID.EVENT_SCHEDULE,
                MENU_ID.CHAT_BOX,
                MENU_ID.TASK_MANAGEMENT,
                MENU_ID.MANAGER_CALENDAR
            ],
            'name': 'Other',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': MENU_ID.WORK_ORDER,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Work Order',
            'label': 'label.position',
            'routerLink': '/app/workorder',
            'order': 48,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.COLORS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Colors',
            'label': '',
            'routerLink': '',
            'order': 70,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.LAYOUT_PALETTE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Layout Palette',
            'label': '',
            'routerLink': '',
            'order': 701,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.THEMES,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Themes',
            'label': '',
            'routerLink': '',
            'order': 702,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        // {
        //   'id': MENU_ID.COMPANY_ADMIN,
        //   'ctrl': 0,
        //   'type': 'child',
        //   'child': [],
        //   'name': 'Company Admin',
        //   'label': 'label.company_admin',
        //   'routerLink': '/app/admin/company-admin',
        //   'order': 22,
        //   'checked': false,
        //   'setting': true,
        //   'isActionsAllowed': true,
        //   'showMenu': true,
        //   'editChecked': false,
        //   'deleteChecked': false,
        // },
        {
            'id': MENU_ID.EVENT_SCHEDULE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Event Schedule',
            'label': 'label.event_schedule',
            'routerLink': '/app/employee/scheduler/event-schedule',
            'order': 46,
            'checked': false,
            'setting': true,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.CHAT_BOX,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Chat Box',
            'label': 'label.crm',
            'routerLink': '/app/chat-box',
            'order': 64,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.TASK_MANAGEMENT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Task Management',
            'label': 'Task Management',
            'routerLink': '/app/tm',
            'order': 37,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.MANAGER_CALENDAR,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Calendar',
            'label': 'Calendar',
            'routerLink': '/app/mananger-calendar/list',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.VIDEO_CONFERENCES,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Video Conferences',
            'label': '',
            'routerLink': '/app/conference/schedule/upcoming',
            'order': 126,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.JOIN_CONFERENCE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Join Conference',
            'label': '',
            'routerLink': '/app/conference/join',
            'order': 127,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.NOTEBOOK,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Notebook',
            'label': 'Notebook',
            'routerLink': '/app/note',
            'order': 1038,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.PAYSTUB_PDF_PROCESSER,
                MENU_ID.SPREAD_SHEET,
                MENU_ID.JOIN_CONFERENCE,
                MENU_ID.VIDEO_CONFERENCES,
                MENU_ID.SCREEN_RECORDER,
                MENU_ID.NOTEBOOK,
                MENU_ID.LAW_FIRM,
                MENU_ID.OPERR_DRIVE,
                MENU_ID.WORKING_TIME_CALCULATOR,
                MENU_ID.ONBOARDING_REQUIREMENTS
            ],
            'name': 'Tools',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': MENU_ID.SOFTPHONE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Softphone',
            'label': '',
            'routerLink': '',
            'order': 1156,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
        },
        {
            'id': MENU_ID.CRM_DOCUMENTS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Documents',
            'label': 'Documents',
            'routerLink': '/app/crm/document/list',
            'order': 1777,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.WORKING_TIME_CALCULATOR,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Working Time Calculator',
            'label': 'Working Time Calculator',
            'routerLink': '/app/working-time-calculator',
            'order': 1778,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.PAYSTUB_PDF_PROCESSER,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Paystub PDF Processer',
            'label': 'Paystub PDF Processer',
            'routerLink': '/app/paystub-pdf-processer',
            'order': 1779,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.MULTIMEDIA,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Multimedia',
            'label': 'Multimedia',
            'routerLink': '/app/conference',
            'order': 36,
            'checked': false,
            'setting': false,
            'employeeMenu': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.SCREEN_RECORDER,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Screen Recorder',
            'label': 'Screen Recorder',
            'routerLink': '/app/screen-recorder/list',
            'order': 125,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.LAW_FIRM,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Law Firm',
            'label': 'Law Firm',
            'routerLink': '/app/law-firm/list',
            'order': 4461,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.SPREAD_SHEET,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Spread Sheet',
            'label': 'Spread Sheet',
            'routerLink': '/app/spread-sheet/list',
            'order': 1162,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.RECOLLECTION_DETAILS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Recollection Details',
            'label': 'Recollection Details',
            'routerLink': '/app/recollection-details/list',
            'order': 4461,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.ONBOARDING_REQUIREMENTS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Onboarding Requirements',
            'label': 'Onboarding Requirements',
            'routerLink': '/app/onboarding-requirements/list',
            'order': 5038,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.OPERR_DRIVE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Operr Drive',
            'label': 'Operr Drive',
            'routerLink': '/app/document/list',
            'order': 5038,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.IND_CONTRACTORS,
                MENU_ID.CONTRACTOR_CONTRACT,
                MENU_ID.CONTRACTOR_INVOICE,
                MENU_ID.PAYMENT_METHOD,
                MENU_ID.ASSIGNED_TICKET,
                MENU_ID.COMPLETED_TICKETS,
                MENU_ID.TICKET_SUMMARY
            ],
            'name': 'Contractor',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': MENU_ID.IND_CONTRACTORS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Ind Contractors',
            'label': 'label.crm',
            'routerLink': '/app/crm/freelancer/list',
            'order': 56,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.CONTRACTOR_CONTRACT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Contract',
            'label': '',
            'routerLink': '',
            'order': 79,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false
        },
        {
            'id': MENU_ID.CONTRACTOR_INVOICE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Contractor Invoice',
            'label': 'label.crm',
            'routerLink': '/app/contractors/invoice',
            'order': 703,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.PAYMENT_METHOD,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Payment Method',
            'label': 'label.crm',
            'routerLink': '/app/payment-method/list',
            'order': 704,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.ASSIGNED_TICKET,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Assigned Ticket',
            'label': 'label.crm',
            'routerLink': '/app/crm/freelancer/assignedtickets',
            'order': 710,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.COMPLETED_TICKETS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Completed Tickets',
            'label': 'label.crm',
            'routerLink': '/app/crm/freelancer/completedtickets',
            'order': 711,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.TICKET_SUMMARY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Ticket Summary',
            'label': 'label.crm',
            'routerLink': '/app/contractors/ticket-summary',
            'order': 1029,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.EXCURSION,
                MENU_ID.MEMBERSHIP,
                MENU_ID.CONTRIBUTION
            ],
            'name': 'Membership',
            'label': 'label.membership',
            'routerLink': '',
            'order': 42,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': MENU_ID.EXCURSION,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Excursion',
            'label': 'label.excursion',
            'routerLink': '/app/excursion',
            'order': 60,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.GUESTS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Guest',
            'label': 'label.guest',
            'routerLink': '/app/guest',
            'order': 37,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.MEMBERSHIP,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Membership',
            'label': 'label.membership',
            'routerLink': '/app/membership',
            'order': 38,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.CONTRIBUTION,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Contribution',
            'label': 'label.contribution',
            'routerLink': '/app/contribution',
            'order': 39,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.MAIL,
                MENU_ID.DEADLINE_REMINDER,
                MENU_ID.MAIL_DOCUMENT
            ],
            'name': 'Mail',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': MENU_ID.MAIL,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Mail',
            'label': 'Mail',
            'routerLink': '/app/lettermail',
            'order': 999,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.DEADLINE_REMINDER,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Deadline Reminder',
            'label': 'Deadline Reminder',
            'routerLink': '/app/lettermail/reminder/list',
            'order': 998,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.MAIL_DOCUMENT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Document',
            'label': 'Document',
            'routerLink': '/app/lettermail/document/list',
            'order': 997,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.TRAINING_SCHEDULE,
                MENU_ID.TRAINING_TOPIC
            ],
            'name': 'Training/Schedule',
            'label': 'label.membership',
            'routerLink': '',
            'order': 42,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': MENU_ID.TRAINING_SCHEDULE,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Training/Schedule',
            'label': 'Training/Schedule',
            'routerLink': '/app/stock',
            'order': 1001,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': MENU_ID.TRAINING_TOPIC,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Training Topic',
            'label': 'Training/Schedule',
            'routerLink': '/app/training-topic/list',
            'order': 1002,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.ADMIN_BOARD_ACCESS,
                MENU_ID.MEMBERS,
                MENU_ID.TRACKING,
                MENU_ID.CARDS
            ],
            'name': 'Admin Board Access',
            'label': 'Admin Board Access',
            'routerLink': '',
            'order': 45,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': MENU_ID.ADMIN_BOARD_ACCESS,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Admin Board Access',
            'label': 'Admin Board Access',
            'routerLink': '',
            'order': 1015,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': MENU_ID.MEMBERS,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Members',
            'label': 'Members',
            'routerLink': '',
            'order': 1017,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': MENU_ID.TRACKING,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Tracking',
            'label': 'Tracking',
            'routerLink': '',
            'order': 1018,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': MENU_ID.CARDS,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Cards',
            'label': 'Cards ',
            'routerLink': '',
            'order': 1080,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.TOTAL_JOB,
                MENU_ID.GENERAL_REPORT,
                MENU_ID.DETAIL_REPORT,
                MENU_ID.BILLING_CORRECTION,
                MENU_ID.FIDELIS_REPORT,
                MENU_ID.BILLING_UPLOAD_HISTORY,
                MENU_ID.BILLING_CLIENT_STATUS,
                MENU_ID.DISPATCH_TOTAL_JOB
            ],
            'name': 'Billing Report ',
            'label': 'Billing Report ',
            'routerLink': '',
            'order': 46,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': MENU_ID.TOTAL_JOB,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Total Job',
            'label': 'Total Job',
            'routerLink': '/app/job-follow-up/billing-total-job',
            'order': 1019,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': MENU_ID.DISPATCH_TOTAL_JOB,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Dispatch Total Job',
            'label': 'Dispatch Total Job',
            'routerLink': '/app/job-follow-up/dispatch-total-job',
            'order': 1122,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': MENU_ID.GENERAL_REPORT,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'General Report',
            'label': 'General Report',
            'routerLink': '/app/job-follow-up/billing-report',
            'order': 1020,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': MENU_ID.DETAIL_REPORT,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Detail Report',
            'label': 'Detail Report',
            'routerLink': '/app/job-follow-up/billing-details-report',
            'order': 1021,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': MENU_ID.BILLING_CORRECTION,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Correction',
            'label': 'Correction',
            'routerLink': '/app/job-follow-up/billing-correction-report',
            'order': 1022,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': MENU_ID.FIDELIS_REPORT,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Fidelis Report',
            'label': 'Fidelis Report',
            'routerLink': '/app/fidelis-billing-report/list',
            'order': 1023,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': MENU_ID.BILLING_UPLOAD_HISTORY,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Upload History',
            'label': 'Upload History',
            'routerLink': '/app/job-follow-up/billing-upload-history',
            'order': 1024,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': MENU_ID.BILLING_CLIENT_STATUS,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Billing Client Status',
            'label': 'Billing Client Status',
            'routerLink': '/app/job-follow-up/billing-client-status',
            'order': 1034,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.LOG_TOTAL_JOB,
                MENU_ID.BILLING_LOG_DETAIL_REPORT
            ],
            'name': 'LOG',
            'label': 'LOG',
            'routerLink': '',
            'order': 47,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': MENU_ID.LOG_TOTAL_JOB,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'LOG Total Job',
            'label': 'LOG Total Job',
            'routerLink': '/app/job-follow-up/billing-log-total-job',
            'order': 1025,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': MENU_ID.BILLING_LOG_DETAIL_REPORT,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Detail Report',
            'label': 'Detail Report',
            'routerLink': '/app/job-follow-up/billing-log-details-report',
            'order': 1026,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        // {
        //     'id': MENU_ID.GUEST,
        //     'ctrl': 0,
        //     'type': 'child',
        //     'child': '',
        //     'name': 'Plan',
        //     'label': 'Plan',
        //     'routerLink': '/app/plan/list',
        //     'order': 1026,
        //     'checked': false,
        //     'setting': true,
        //     'isActionsAllowed': true,
        //     'editChecked': false,
        //     'deleteChecked': false,
        //     'showMenu': true,
        //     'disableParent': false,
        // },
        {
            'id': MENU_ID.GUESTS,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Plan',
            'label': 'Plan',
            'routerLink': '/app/plan/preview',
            'order': 1026,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.RESIDENTS,
                MENU_ID.SIGN_INS_OUTS_LIST,
                MENU_ID.SIGN_INS_OUTS,
                MENU_ID.RESIDENT_FLAG,
                MENU_ID.ALERT_HISTORY,
                MENU_ID.INCIDENT_REPORT,
                MENU_ID.CURFEW_SCHEDULE,
                MENU_ID.HOURS_48_OVER_AUTHORIZATION,
                MENU_ID.SERVICE_MANAGEMENT,
                MENU_ID.WAIVED_SINGLE_MISSING_SIN_IN_OUT,
                MENU_ID.WAIVED_IN_BULK_MISSING_SIN_IN_OUT
            ],
            'name': 'Resident',
            'label': 'Resident',
            'routerLink': '',
            'order': 1271,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': MENU_ID.RESIDENTS,
            'ctrl': 0,
            'type': 'child',
            'child': '',
            'name': 'Residents',
            'label': 'Residents',
            'routerLink': '/app/resident/list',
            'order': 1272,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': MENU_ID.SIGN_INS_OUTS_LIST,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Sign-Ins & Outs List',
            'label': 'Sign-Ins & Outs List',
            'routerLink': '/app/resident/sign-in-out-list',
            'order': 1273,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': MENU_ID.RESIDENT_FLAG,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Flag',
            'label': 'Flag',
            'routerLink': '/app/resident/flag',
            'order': 1274,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': MENU_ID.ALERT_HISTORY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Alert History',
            'label': 'Alert History',
            'routerLink': '/app/resident/alert-history',
            'order': 1275,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': MENU_ID.SIGN_INS_OUTS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Sign-Ins & Outs',
            'label': 'Sign-Ins & Outs',
            'routerLink': '/app/resident/sign-in-out',
            'order': 1276,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        
        {
            'id': MENU_ID.WAIVED_SINGLE_MISSING_SIN_IN_OUT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Waived single Missing Sin-In/Out',
            'label': 'Waived single Missing Sin-In/Out',
            'routerLink': null,
            'order': 1278,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': MENU_ID.WAIVED_IN_BULK_MISSING_SIN_IN_OUT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Waived In Bulk Missing Sin-In/Out',
            'label': 'Waived In Bulk Missing Sin-In/Out',
            'routerLink': null,
            'order': 1279,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false,
        },
        {
            'id': MENU_ID.REMINDER_ALERT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Reminder Alert',
            'label': 'Reminder Alert',
            'routerLink': '/app/reminder/list',
            'order': 1209,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.HOURS_48_OVER_AUTHORIZATION,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': '48 hours & Over Authorization',
            'label': '48 hours & Over Authorization',
            'routerLink': null,
            'order': 1280,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false
        },
        {
            'id': MENU_ID.NEGLIGENCE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Negligence',
            'label': 'Negligence',
            'routerLink': null,
            'order': 1281,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false
        },
        {
            'id': MENU_ID.INCIDENT_REPORT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Incident Report',
            'label': 'Incident Report',
            'routerLink': null,
            'order': 1206,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false
        },
        {
            'id': MENU_ID.CURFEW_SCHEDULE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Curfew Schedule',
            'label': 'Curfew Schedule',
            'routerLink': null,
            'order': 1207,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false
        },
        {
            'id': MENU_ID.SERVICE_MANAGEMENT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Service Management',
            'label': 'Service Management',
            'routerLink': null,
            'order': 1208,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
            'disableParent': false
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.COMPLIANCE_DOCUMENT,
                MENU_ID.STUFF_COMPLIANCE,
                MENU_ID.EMPLOYEE_DOCUMENT,
                MENU_ID.TIME_RECOLLECTION,
                MENU_ID.TIME_RECOLLECTION_UPLOAD_HISTORY,
                MENU_ID.STUFF_VOUCHER,
                MENU_ID.WAGE_SCHEDULE,
                MENU_ID.EMPLOYEE_VOUCHER,
                MENU_ID.TIP_CREDIT,
                MENU_ID.MONITORING_COMPANY_DOCUMENTS,
                MENU_ID.COMPLIANCE_DASHBOARD,
            ],
            'name': 'Compliance',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': MENU_ID.COMPLIANCE_DOCUMENT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Compliance Document',
            'label': 'Compliance Document',
            'routerLink': '/app/compliance-setting/list',
            'order': 1681,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.STUFF_COMPLIANCE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Stuff Compliance',
            'label': 'Stuff Compliance',
            'routerLink': '/app/employee/compliance/list',
            'order': 1682,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.EMPLOYEE_DOCUMENT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Document',
            'label': 'Employee Document',
            'routerLink': '',
            'order': 1683,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.COMPLIANCE_DASHBOARD,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Compliance Dashboard',
            'label': 'Compliance Dashboard',
            'routerLink': '',
            'order': 1680,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.TIME_RECOLLECTION,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Time Recollection',
            'label': 'Time Recollection',
            'routerLink': '',
            'order': 1685,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.TIME_RECOLLECTION_UPLOAD_HISTORY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Time Recollection Upload History',
            'label': 'Time Recollection Upload History',
            'routerLink': '',
            'order': 1685,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.STUFF_VOUCHER,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Voucher',
            'label': 'Voucher',
            'routerLink': '',
            'order': 1684,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.WAGE_SCHEDULE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Wage Schedule',
            'label': 'Wage Schedule',
            'routerLink': '',
            'order': 1686,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.EMPLOYEE_VOUCHER,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Employee Voucher',
            'label': 'Employee Voucher',
            'routerLink': '/app/compliance-setting/employee-voucher',
            'order': 1691,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.TIP_CREDIT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Tip Credit',
            'label': 'Tip Credit',
            'routerLink': '/app/compliance-setting/tip-credit',
            'order': 1691,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.MONITORING_COMPANY_DOCUMENTS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Monitoring Company Documents',
            'label': 'Monitoring Company Documents',
            'routerLink': '/app/compliance-setting/company-document',
            'order': 4871,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.INVENTORY,
                MENU_ID.INVENTORY_ITEMS,
                MENU_ID.EQUIPMENT_CHECK_OUT,
                MENU_ID.VEHICLES,
                MENU_ID.PURCHASE_REQUESTS,
                MENU_ID.INVENTORY_REPORT,
                MENU_ID.MANAGE_ATTRIBUTES
            ],
            'name': 'Inventory',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
            'disableParent': false,
        },
        {
            'id': MENU_ID.INVENTORY,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Inventory',
            'label': '',
            'routerLink': '/app/stock/list',
            'order': 1202,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
        },
        {
            'id': MENU_ID.INVENTORY_ITEMS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Items',
            'label': '',
            'routerLink': '/app/product/add',
            'order': 1200,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
        },
        {
            'id': MENU_ID.EQUIPMENT_CHECK_OUT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Equipment Check-Out',
            'label': '',
            'routerLink': '/app/equipment',
            'order': 1201,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
        },
        {
            'id': MENU_ID.VEHICLES,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Vehicles',
            'label': '',
            'routerLink': '/app/vehicle/list',
            'order': 1152,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
        }, {
            'id': MENU_ID.PURCHASE_REQUESTS,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Purchase Requests',
            'label': '',
            'routerLink': '/app/purchase-requests',
            'order': 1203,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
        },
        {
            'id': MENU_ID.INVENTORY_REPORT,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Inventory Report',
            'label': '',
            'routerLink': '/app/inventory-report',
            'order': 1204,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
        },
        {
            'id': MENU_ID.MANAGE_ATTRIBUTES,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Manage Attributes',
            'label': '',
            'routerLink': '/app/manage-attributes',
            'order': 1205,
            'checked': false,
            'setting': true,
            'isActionsAllowed': false,
            'editChecked': false,
            'deleteChecked': false,
            'showMenu': true,
        },
        {
            'id': null,
            'ctrl': 0,
            'type': 'parent',
            'child': [
                MENU_ID.MEMBER_PROFILE,
                MENU_ID.MEMBER_SCHEDULE
            ],
            'name': 'Member',
            'label': '',
            'routerLink': '',
            'order': 1,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': true,
            'deleteChecked': true,
            'disableParent': false,
        },
        {
            'id': MENU_ID.MEMBER_PROFILE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Member Profiles',
            'label': 'Member Profiles',
            'routerLink': '/app/member/list',
            'order': 6005,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
        {
            'id': MENU_ID.MEMBER_SCHEDULE,
            'ctrl': 0,
            'type': 'child',
            'child': [],
            'name': 'Schedules',
            'label': 'Schedules',
            'routerLink': '/app/member/schedule',
            'order': 6006,
            'checked': false,
            'setting': true,
            'isActionsAllowed': true,
            'showMenu': true,
            'editChecked': false,
            'deleteChecked': false,
        },
    ];

    public static checkItem(showArray: any, baseArray: any, listArray: any): boolean {
        if (showArray && showArray.length === 0) {
            return false;
        } else {
            let flag = false;
            showArray.forEach(element => {
                if (baseArray.includes(+element)) {
                    // find in current ary
                    if (listArray.find(item => item.id === +element)) {
                        flag = true;
                    }
                }
            });
            return flag;
        }
    }

    public static checkChildItem(menu: any[], listArray): boolean {
        let flag = false;
        menu.forEach(element => {
            if (listArray.find(item => item.id === element)) {
                flag = true;
            }
        });
        return flag;
    }

    public static cleacObject(menus) {
        let order = 0;
        menus.forEach(element => {
            if (element.type === 'parent') {
                order = order + 1;
            } else {
                element.ctrl = order;
            }
        });
    }

    public static menuToDisplay(menuList) {
        const Ids = {
            employeeIds: [7, 8, 42, 62, 77],
            crmIds: [],
            operrTelIds: []
        };
        if (menuList.length > 0) {
            for (let i = 0; i <= menuList.length; i++) {
                if (menuList[i]?.name === 'CRM') {
                    Ids.crmIds.push(...menuList[i].child);
                }
                if (menuList[i]?.name === 'Employee') {
                    Ids.employeeIds.push(...menuList[i].child);
                }
                if (menuList[i]?.name === 'OperrTel') {
                    Ids.operrTelIds.push(...menuList[i].child);
                }
            }
            return Ids;
        }

    }

    sortMenu(items: any[]): any[] {
        const itemMap = new Map<number | null, any>();
        items.forEach(item => itemMap.set(item.id, item));
        const sortedMenu: any[] = [];
        const parents = items.filter(item => item.type === 'parent');
        parents.forEach(parent => {
            sortedMenu.push(parent);
            const children = parent.child
                .map(childId => itemMap.get(childId))
                .filter((child) => !!child)
            sortedMenu.push(...children);
        });
        return sortedMenu;
    }

    public sortedMenu = this.sortMenu(this.menus);
}
