import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'app/shared/service/auth/auth.service';
import { DocumentsService } from 'app/shared/service/documents-upload/documents.service';
import moment from 'moment/moment';
import { saveAs } from 'file-saver';

declare let $: any;

@Component({
  selector: 'app-attachment-section',
  templateUrl: './attachment-section.component.html',
  styleUrls: ['./attachment-section.component.scss']
})
export class AttachmentSectionComponent implements OnInit {
  @Input() imageUrl:any;
  @Output() onDelete = new EventEmitter();
  @Output() onRemoveCover = new EventEmitter();
  @Output() onMakeCover = new EventEmitter();
  @Output() onComment = new EventEmitter();
  previewImage: string;
  @Input() taskBackground: string = '';
  isSuperAdmin: boolean = this.authService.isSuper();
  @Output() onEditLabel = new EventEmitter();

  constructor(private authService: AuthService,
    private documentsService:DocumentsService) { }

  ngOnInit(): void {
  }

  delete() {
    this.onDelete.emit(this.imageUrl);
    this.closeModalImage();
    this.removeCover();
  }

  comment() {
    this.onComment.emit(this.imageUrl);
  }

  closeModalImage() {
    this.previewImage = null
  }

  removeCover() {
    this.onRemoveCover.emit()
  }

  viewImage() {
    if (!this.imageUrl.documentId) {
      this.previewImage = this.imageUrl.content;
    } else {
      this.documentsService.getUploadedFile(this.imageUrl.documentId).subscribe((res: any) => {
        if (res.status === 'SUCCESS') {
          this.previewImage = res.data.fileUrl;
        }
      })
    }
  }

  makeCover() {
    this.onMakeCover.emit(this.imageUrl)
  }

  editImageLabel(){
    this.onEditLabel.emit(this.imageUrl)
  }

  setFocus(field) {
    setTimeout(() => document.getElementById(field).focus(), 1000);
  }

  editLabel = false;
  lostFocus() {
      this.editLabel = true;
  }

  editImageLabelClick() {
      this.editLabel = true;
      setTimeout(() => {
        $('#editAttachInputDirectly').select();
      }, 500);
  }

  download(item) {
    if (item.type === 'IMAGE_URL') {
      this.documentsService.downloadFile(item.documentId).subscribe(res => {
        saveAs(res.body, this.getFileName(item.content));
      });
    } else {
      const a = document.createElement('a');
      a.href = item.content;
      a.target = '_blank';
      a.download = this.getFileName(item.content);
      a.click();
      a.remove();
    }
  }

  getFileName(url) {
      return url.split('/').pop().split('__').pop();
  }
}
