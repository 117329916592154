import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MonitoringDetailsService } from './admin/process-monitor/service/monitoring-details.service';
import { AppRoutingModule } from './app.routes';
import 'rxjs/add/operator/toPromise';
import './rxjs.operators';
import { AngularDraggableModule } from 'angular2-draggable';
import { AdminGuard } from './authenticate/admin.guard';
import { IdleService } from './idle/idle.service';
import { ActionMonitoringService } from './shared/service/action-monitoring.service';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { SignUpModule } from './sign-up/sign-up.module';
import { PrimeNgModule } from './prime-ng/prime-ng.module';
import { SharedModule } from './shared/shared.module';
import { WebcamModule } from 'ngx-webcam';

import { CookieService } from 'ngx-cookie-service';
import { AuthGuard } from './authenticate/auth.guard';
import { AuthService } from './shared/service/auth/auth.service';
import { InterceptHeaderHttps } from './intercept.header.https';
import { WindowRefService } from './shared/service/window-ref/window-ref.service';

import { LayoutModule } from './layout/layout.module';
import { LoginModule } from './login/login.module';
import { AppComponent } from './app.component';
import { CarService } from './demo/service/carservice';
import { CountryService } from './demo/service/countryservice';
import { EventService } from './demo/service/eventservice';
import { NodeService } from './demo/service/nodeservice';
import { BreadcrumbService } from './layout/service/breadcrumb.service';
import { AllMenusService } from './layout/service/all-menus/all-menus.service';
import { AppTopbarComponent } from './layout/component/app-topbar/app-topbar.component';
import { AppFooterComponent } from './layout/component/app-footer/app.footer.component';
import { AppBreadcrumbComponent } from './layout/component/app-breadcrumb/app-breadcrumb.component';
import { AppMenuComponent, AppSubMenuComponent } from './layout/component/app-menu/app-menu.component';
import { AppDashboardComponent } from './layout/component/app-dashboard/app-dashboard.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { PopupService } from './layout/service/popup.service';
import { CallLogService } from './layout/service/calllog/call-log.service';
import { ContactService } from './layout/service/calllog/contact.service';
import { PhoneSettingsService } from './layout/service/calllog/phone-settings.service';

import { CallHandler } from './layout/component/opr-call-handler/opr-call-handler.component';
import { BillingInvoiceService } from './billing/service/billinginvoice.service';
import { BillingNotesService } from './billing/service/billingnotes.service';
import { PaymentDetailsService } from './billing/service/paymentdetails.service';
import { ToDoNotesService } from './todo/service/todonotes.service';
import { BillingHistoryService } from './billing/service/billinghistory.service';
import { EmployeePaymentHistoryService } from './employee/service/employee-paymenthistory.service';
import { InvoiceTemplateService } from './billing/service/invoice-template.service';
import { DndModule } from 'ng2-dnd';
import { MessageService, ToastModule } from 'primeng';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { TranslateCustomerService } from './shared/service/translate-custome.service';
import { TruncateTextPipe } from './shared/pipes/truncate.pipe';
import { DispatchBillingService } from './billing/service/dispatch-billing.service';
import { CRMDashboardModule } from './crm-dashboard/crm-dashboard.module';
import { DashboardService } from './layout/service/dashboard/dashboard.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { SpreadsheetAllModule } from '@syncfusion/ej2-angular-spreadsheet';
import { CountdownModule } from 'ngx-countdown';
import { ArReportModule } from './ar-report/ar-report.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'environments/environment';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AgmCoreModule } from '@agm/core';
import { AgmDrawingModule } from '@agm/drawing';
import { FaceRecognitionModule } from './face-recognition/face-recognition.module';
import { UserAgreementComponent } from './layout/component/user-agreement/user-agreement.component';
import { UserPrivacyComponent } from './layout/component/user-privacy/user-privacy.component';
import { ApiUsageService } from './billing/service/api-usage.service';
import { ProcessMonitorEmailActivityService } from '../app/admin/process-monitor/service/process-monitor-email-activity.service';
import { ExportMonitorService } from '../app/admin/process-monitor/service/export-monitor.service';
import { TransferDataService } from './shared/service/transfer-data.service';
import { ShareDataService } from './shared/service/share-data.service';
import { CommonService } from './shared/service/common.service';
import { EmployeeDashboardComponent } from './employee/component/employee-dashboard/employee-dashboard.component';
import { OneTimePaymentComponent } from './payment/component/one-time-payment/one-time-payment.component';
import { OneTimePaymentThankyouComponent } from './payment/component/one-time-payment/one-time-payment-thankyou/one-time-payment-thankyou.component';
import { PayrollJournalReportPreViewComponent } from './payroll-journal-report/component/payroll-journal-report-preview/payroll-journal-report-preview.component';
import { PayrollSettingModule } from './payroll-setting/payroll-setting.module';
import { OneTimePaymentCheckoutComponent } from './payment/component/one-time-payment/one-time-payment-checkout/one-time-payment-checkout.component';
import { QuotePreviewComponent } from './quote/component/quote-preview/quote-preview.component';
import { TaskManagementModule } from "./task-management/task-management.module";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { AvatarService } from './shared/service/avatar.service';
import { SignaturePadModule } from 'angular2-signaturepad';
import { ClickOutsideModule } from "ng-click-outside";
import { PermissionGuard } from './authenticate/permission.guard';
import { PermissonDeniedComponent } from './permisson-denied/permisson-denied.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const baseComponents = [
  UserAgreementComponent,
  UserPrivacyComponent,
  EmployeeDashboardComponent,
  QuotePreviewComponent,
  PayrollJournalReportPreViewComponent,
  OneTimePaymentComponent,
  OneTimePaymentCheckoutComponent,
  OneTimePaymentThankyouComponent
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    NgIdleKeepaliveModule.forRoot(),
    DndModule.forRoot(),
    PrimeNgModule,
    AngularDraggableModule,
    ToastModule,
    CountdownModule,
    ScrollingModule,
    AgmDrawingModule,
    AgmCoreModule.forRoot({
      apiKey: environment.map_google_key,
      libraries: ['marker', 'places', 'drawing', 'geometry'], // Add the 'drawing' library to load
    }),
    SpreadsheetAllModule,
    WebcamModule,

    SharedModule,
    AppRoutingModule,
    LayoutModule,
    LoginModule,
    ForgotPasswordModule,
    SignUpModule,
    CRMDashboardModule,
    ArReportModule,
    FaceRecognitionModule,
    PayrollSettingModule,
    TaskManagementModule,
    NgxIntlTelInputModule,
    SignaturePadModule,
    ClickOutsideModule
  ],
  declarations: [
    ...baseComponents,
    AppComponent,
    CallHandler,
    AppMenuComponent,
    AppSubMenuComponent,
    AppTopbarComponent,
    AppBreadcrumbComponent,
    AppFooterComponent,
    AppDashboardComponent,
    PermissonDeniedComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptHeaderHttps,
      multi: true
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    IdleService,
    MonitoringDetailsService,
    ActionMonitoringService,
    CarService,
    CountryService,
    EventService,
    NodeService,
    BreadcrumbService,
    AllMenusService,
    CallLogService,
    ContactService,
    PhoneSettingsService,
    AuthGuard,
    PermissionGuard,
    AdminGuard,
    AuthService,
    CookieService,
    WindowRefService,
    PopupService,
    BillingInvoiceService,
    BillingNotesService,
    BillingHistoryService,
    PaymentDetailsService,
    ToDoNotesService,
    EmployeePaymentHistoryService,
    TranslateCustomerService,
    TranslateService,
    TranslatePipe,
    TruncateTextPipe,
    InvoiceTemplateService,
    DispatchBillingService,
    DashboardService,
    NgxImageCompressService,
    ApiUsageService,
    ProcessMonitorEmailActivityService,
    ExportMonitorService,
    TransferDataService,
    ShareDataService,
    CommonService,
    DatePipe,
    MessageService,
    AvatarService
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}

