import { Component, Input, OnInit } from '@angular/core';
import { EmployeeJobReportService } from 'app/employee/service/employee-job-report.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DATE_FORMATS } from 'app/shared/data/config-common';
import { DocumentsService } from 'app/shared/service/documents-upload/documents.service';

@Component({
  selector: 'app-employee-job-report-history',
  templateUrl: './employee-job-report-history.component.html',
  styleUrls: ['./employee-job-report-history.component.scss'],
  providers: [{
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  },
  { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },]
})
export class EmployeeJobReportHistoryComponent implements OnInit {
  fieldsName = [
    { value: 'userName', label: 'User Name' },
    { value: 'reportDate', label: 'Report Date' }
  ];

  displayColumns: any[] = [
    { field: 'updatedDate', label: 'Updated', sortable: false },
    { field: 'userName', label: 'User', sortable: false },
    { field: 'oldValue', label: 'Old Value', sortable: false },
    { field: 'newValue', label: 'New Value', sortable: false },
    { field: 'action', label: 'Action', sortable: false }
  ];
  dataSourceSubject = new BehaviorSubject(null);
  dataSource$ = this.dataSourceSubject.asObservable();
  size: number;
  page: number;
  rangeDates: any = []
  historyFor: any = {};
  selectedField: string;

  //@Output() onHideHistory: EventEmitter<any> = new EventEmitter();
  loading: boolean = false;
  @Input() jobId: number;
  @Input() historyType: any;

  constructor(private employeeJobReport: EmployeeJobReportService, private documentsService: DocumentsService) {
  }

  ngOnInit() {
    if (this.historyType == 'upload') {
      this.fieldsName = [{ value: 'documentId', label: 'Attachment' }];
      this.selectedField = 'documentId';
      this.loadDataSource();
    }
  }

  selectField(event) {
    this.historyFor = this.fieldsName.find(field => event.value === field.value)
  }

  loadDataSource(event?: any) {
    this.dataSourceSubject.next([]);
    this.size = event && event.rows ? event.rows : (this.size ? this.size : 25);
    this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);

    const options: any = {
      size: this.size,
      page: this.page,
      fieldName: this.selectedField,
      jobId: this.jobId
    };

    if (this.rangeDates[0] && this.rangeDates[1]) {
      options.fromDate = this.rangeDates[0];
      options.toDate = this.rangeDates[1];
    }

    if (!this.selectedField) {
      this.dataSourceSubject.next([]);
      return;
    }
    this.employeeJobReport.getHistory(options).subscribe(
      (data: any) => {
        this.dataSourceSubject.next(data.data);
      }
    );
  }

  checkField(value, field) {
    return value;
  }

  public hideHistory() {
    //this.onHideHistory.emit();
  }

  reset() {
    this.rangeDates = [];
    this.selectedField = null;
    this.historyFor = {};
    this.loadDataSource();

  }
  onViewDocument(documentId) {
    this.documentsService.getUploadedFile(documentId).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        window.open(resObj.data.fileUrl);
      }
    }, error => {
      // error
    });
  }

}