export class EmployeeJobReport {
    id: number;
    companyId: number;
    fullName: string;
    userName: string;
    documentId: number | null;
    employeeId: number;
    reportDate: Date;
    note: string;
    createdByUsr: string;
    details: string;
    detailsReason: string;
    lastModifiedBy: string;
    updatedAt: Date;
    lat: number;
    lng: number;
    listNoteByHours: any[];
    concern: string;
    replies: any[];
    documentUrls: string[];
}
