import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DATE_FORMATS } from 'app/shared/data/config-common';
import { AuthService } from 'app/shared/service/auth/auth.service';
import { DropDownsService } from 'app/shared/service/drop-downs/drop-downs.service';
import { CompanyService } from 'app/company/service/company.service';
import { RevenueService } from '../../services/revenue.service';
import { DatePipe } from '@angular/common';
import { TransactionDetailsService } from '../../services/transaction-details.service';
import { ContractorInvoiceService } from '../../../contractor/service/contractor-invoice.service';
import { ExpenseService } from 'app/employee/component/expense/service/expense.service';

@Component({
  selector: 'app-service-revenue',
  templateUrl: './gross-income.component.html',
  styleUrls: ['./gross-income.component.scss'],
  providers: [TransactionDetailsService, ContractorInvoiceService,
    ExpenseService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }]
})
export class GrossIncomeComponent implements OnInit {

  totalIncome: number = 0;
  totalContractorExpenses: number = 0;
  totalExpenses: number = 0;

  constructor(private companyService: CompanyService,
    private authService: AuthService,
    private dropDownsService: DropDownsService,
    private datePipe: DatePipe,
    private messageService: MessageService,
    private transactionDetailsService: TransactionDetailsService,
    private contentService: ContractorInvoiceService,
    private expenseService: ExpenseService,
    private revenueService: RevenueService) {
  }

  ngOnInit(): void {

    this.getTotalPayment();
  }

  getTotalPayment() {
    const options: any = {
      companyId: this.authService.getCurrentCompanyId()
    };
    this.transactionDetailsService.loadLists(options).subscribe(res => {
      const payments = res.data.content;
      payments.forEach(ele => {
        ele.date = new Date(ele.date);
        ele.netCashInOut = ele.netCashInOut ? ele.netCashInOut : 0;
        this.totalIncome += ele.netCashInOut;
      });
    });
    options.oldInvoiceOnly = false;
    options.isAll = true;
    options.toDate = null;
    options.fromDate = null;
    this.contentService.filter(options).subscribe(res => {
      const resObj: any = res.data.content;
      resObj.forEach(ele => {
        if (ele.status === 'Approved') {
          ele.outstandingBalance = ele.outstandingBalance ? ele.outstandingBalance : 0;
          this.totalContractorExpenses += ele.outstandingBalance;
        }
      });
    });
    const optionsExpense: any = {
      companyId: this.authService.getCurrentCompanyId()
    };
    optionsExpense.size = 9999;
    optionsExpense.page = 0;
    this.expenseService.loadExpenses(optionsExpense).subscribe(res => {
      const resObj: any = res.data.content;
      resObj.forEach(ele => {
        ele.amount = ele.amount ? ele.amount : 0;
        this.totalExpenses += ele.amount;
      });
    });
  }

}
