<p-toast position="bottom-right">{{msgs}}</p-toast>
<h1 class="m-0 ml-2 my-4">{{'Revenue Payments' | translate}}</h1>
<p-panel [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i>{{'Find Revenue Payments' | translate}}</span>
	</p-header>
	<div class="ui-g ">
		<div class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin">
			<label>{{'companyLabel' | translate}}: </label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-building"></i>
					</button>
				</div>
				<p-dropdown class="flex-auto dropdown-full-width w-100" [options]="allCompanyList" optionLabel="value" dataKey="key"
							placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedCompany" [filter]="true" [showClear]="true">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'dateRange' | translate}}: </label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-week"></i>
					</button>
				</div>
				<mat-form-field class="w-100">
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate matInput placeholder="{{'StartDate' | translate}}" [(ngModel)]="fromDate" name="Startdate" (focus)="picker.open()">
						<input matEndDate matInput placeholder="{{'EndDate' | translate}}" [(ngModel)]="toDate" name="Enddate" (focus)="picker.open()">
					</mat-date-range-input>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Type' | translate}} </label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-money-bill-transfer"></i>
					</button>
				</div>
				<p-dropdown [options]="transactionType" [style]="{'flex-grow': '1'}" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedTransactionType">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-12 py-4 text-center">
			<button type="button" class="btn btn-danger mx-1" (click)="reset()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
			<button type="button" class="btn btn-primary mx-1" (click)="searchList()"><i class="fa-solid fa-check mr-2"></i>{{'button.search' | translate}}</button>
		</div>
	</div>
</p-panel>

<div class="ui-g" id="table-space">   
	<div class="ui-g-6 ui-sm-12">
		<h1 class="m-0 ml-2 my-4">{{'Browse Invoices' | translate}}</h1>
		<p-panel id="table-ppanel"  [toggleable]="true">
			<p-header class="flex-grow-1">
				<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Invoices' | translate}} ({{totalRecordsInvoice}})</span>
			</p-header>
			<p-table [value]="invoices" dataKey="id" selectionMode="single" [lazy]="true" [paginator]="true" [totalRecords]="totalRecordsInvoice"
					 [rows]="10" [rowsPerPageOptions]="[10, 25, 50]" [columns]="colsInvoice" [responsive]="true" (sortFunction)="customSort($event)"
					 [customSort]="true" (onLazyLoad)="onLazyLoadInvoice($event)" [loading]="loadingInvoice" [lazyLoadOnInit]="false">

				<ng-template pTemplate="caption">
					<div class="d-flex align-items-center justify-content-start flex-wrap">
						<button type="button" (click)="op3.toggle($event);" class="btn btn-primary mx-1">
							<i *ngIf="!exporting" class="fa-solid fa-print mr-2"></i>
							<i *ngIf="exporting" class="fa-solid fa-spinner mr-2 loadingHourglass"></i> {{'Export PDF' | translate}}
						</button>
					</div>
				</ng-template>

				<ng-template pTemplate="header" let-columns>
					<tr>
						<th *ngFor="let col of columns" width="{{col?.width}}">
							<ng-container [ngSwitch]="col.field">
								<span *ngSwitchDefault>
									<div class="d-flex align-items-center justify-content-between w-100">
										<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
										<span *ngIf="!col.sort">
											<p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order" [pSortableColumn]="col.field"></p-sortIcon>
										</span>
										<span *ngIf="col.sort">
											<span (click)="col.sort == 'number'? op4.toggle($event) : op2.toggle($event); selectedField = col.field;setSortOption(col.field, col.sort); sortInvoice = true;">
												<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
											</span>
										</span>
									</div>
								</span>
								<span *ngSwitchCase="'index'">
									<div class="d-flex align-items-center justify-content-between w-100">
										<span class="one-liner">{{col.label | translate}}</span>
									</div>
								</span>
							</ng-container>
						</th>
					</tr>
				</ng-template>

				<ng-template pTemplate="body" let-rowData let-index="rowIndex">
					<tr *ngIf="loading && (index+1 === 1)">
						<td [attr.colspan]="3" class="text-center py-5">
							<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
						</td>
					</tr>
					<tr *ngIf="!loading" [pSelectableRow]="rowData">
						<td>
							{{rowData?.index}}
						</td>
						<td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Invoiced' | translate}}: </span>
								<span *ngIf="rowData.invoiceDate" pTooltip="{{rowData.invoiceDate | date : 'M/d/yyyy h:mm a'}}">{{rowData.invoiceDate | date : 'M/d/yyyy h:mm a'}}</span>
								<span *ngIf="!rowData.invoiceDate" class="text-muted">{{'no data' | translate}}</span>
								
							</span>
						</td>
						<td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Paid Fee' | translate}}: </span>
								<span *ngIf="rowData.paidFee" pTooltip="{{rowData?.paidFee | currency : 'USD':'symbol':'1.2-2'}}">{{rowData?.paidFee | currency : 'USD':'symbol':'1.2-2'}}</span>
								<span *ngIf="!rowData.paidFee" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</td>
					</tr>
				</ng-template>

				<ng-template pTemplate="emptymessage" let-columns>
					<tr *ngIf="loading">
						<td [attr.colspan]="columns.length" class="text-center py-5">
							<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
						</td>
					</tr>
					<tr *ngIf="!loading">
						<td [attr.colspan]="columns.length" class="text-center py-5">
							<div class="text-muted my-5">{{'no data' | translate}}</div>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="footer" let-columns>
					<tr *ngIf="!loading">
						<td colspan="2"><span class="desktop-table-label">{{'Total' | translate}}</span></td>
						<td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Total' | translate}}: </span>{{totalInvoice | currency : 'USD':'symbol':'1.2-2'}}
							</span>
						</td>
					</tr>
				</ng-template>
			</p-table>
		</p-panel>
	</div>
	<div class="ui-g-6 ui-sm-12">
		<h1 class="m-0 ml-2 my-4">{{'Browse Payments' | translate}}</h1>
		<p-panel id="table-ppanel" [toggleable]="true">
			<p-header class="flex-grow-1">
				<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Payments' | translate}} ({{totalRecords}})</span>
			</p-header>
			<p-table [value]="payments" dataKey="id" selectionMode="single" [lazy]="true" id="payments" [paginator]="true"
					 [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 25, 50]" [rows]="10" [columns]="colsPayment" [lazyLoadOnInit]="false"
					 [responsive]="true" (sortFunction)="customSort($event)" [customSort]="true" (onLazyLoad)="loadLazy($event)" [loading]="loading">
				
				<ng-template pTemplate="caption">
					<div class="d-flex align-items-center justify-content-start flex-wrap">
						<button type="button" (click)="op3.toggle($event);" class="btn btn-primary mx-1">
							<i class="fa-solid fa-print mr-2"></i> {{'Export PDF' | translate}}
						</button>
					</div>
				</ng-template>

				<ng-template pTemplate="header" let-columns>
					<tr>
						<th *ngFor="let col of columns; let i = index" width="{{col?.width}}">
							<ng-container [ngSwitch]="col.field">
								<span *ngSwitchDefault>
									<div class="d-flex align-items-center justify-content-between w-100">
										<span class="one-liner">{{col.label | translate}}</span>
										<span *ngIf="!col.sort">
											<p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order" [pSortableColumn]="col.field"></p-sortIcon>
										</span>
										<span *ngIf="col.sort">
											<span (click)="col.sort == 'number'? op4.toggle($event) : op2.toggle($event); selectedField = col.field;setSortOption(col.field, col.sort); sortInvoice = false;">
												<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
											</span>
										</span>
									</div>
								</span>
								<span *ngSwitchCase="'index'">
									<div class="d-flex align-items-center justify-content-between w-100">
										<span class="one-liner">{{col.label | translate}}</span>
									</div>
								</span>
							</ng-container>
						</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-rowData let-i="rowIndex">
					<tr *ngIf="loading && (i+1 === 1)">
						<td [attr.colspan]="3" class="text-center py-5">
							<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
						</td>
					</tr>
					<tr *ngIf="!loading" [pSelectableRow]="rowData">
						<td>
							{{i + 1}}
						</td>
						<td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Payment' | translate}}: </span>
								<span *ngIf="rowData.date" pTooltip="{{rowData.date | date : 'M/d/yyyy h:mm a'}}">{{rowData.date | date : 'M/d/yyyy h:mm a'}}</span>
								<span *ngIf="!rowData.date" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</td>
						<td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Amount' | translate}}: </span>
								<span *ngIf="rowData.netCashInOut" pTooltip="{{rowData?.netCashInOut | currency : 'USD':'symbol':'1.2-2'}}">{{rowData?.netCashInOut | currency : 'USD':'symbol':'1.2-2'}}</span>
								<span *ngIf="!rowData.netCashInOut" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage" let-columns>
					<tr *ngIf="!loading">
						<td [attr.colspan]="columns.length" class="text-center py-5">
							<div class="text-muted my-5">{{'no data' | translate}}</div>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="footer" let-columns>
					<tr *ngIf="!loading">
						<td colspan="2"><span class="desktop-table-label">{{'Total' | translate}}</span></td>
						<td>
							<span class="one-liner">
								<span class="mobile-table-label">{{'Total' | translate}}: </span>{{totalPayment | currency : 'USD':'symbol':'1.2-2'}}
							</span>
						</td>
					</tr>
				</ng-template>
			</p-table>
		</p-panel>
	</div>
</div>
<p-overlayPanel #op1 appendTo="body">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClickPayment(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClickPayment(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op4 appendTo="body">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClickPayment(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op3 [appendTo]="'body'">
	<p-listbox [options]="fileTypes">
		<ng-template let-col pTemplate="item">
			<div (click)="exportPdfAR(col.value); op3.hide();">
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
