import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../authenticate/auth.guard';
import { CrmDashboardComponent } from './component/crm-dashboard/crm-dashboard.component';
import { CrmDashboardLayoutComponent } from './component/crm-dashboard-layout/crm-dashboard-layout.component';
import { PermissionGuard } from 'app/authenticate/permission.guard';
import { MENU_ID } from 'app/shared/data/menu-id';


export const CRMDashBoardRoutes: Routes = [
    {
        path: 'app/crm-dashboard',
        component: CrmDashboardLayoutComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { canView: [MENU_ID.CEO_DASHBOARD], canEdit: [] },
        children: [
            { path: '', pathMatch: 'full', component: CrmDashboardComponent },
        ]
    },
    {
        path: 'app/ceo/dashboard',
        component: CrmDashboardLayoutComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { canView: [MENU_ID.CEO_DASHBOARD], canEdit: [] },
        children: [
            { path: '', pathMatch: 'full', component: CrmDashboardComponent },
        ]
    }
];

export const CRMDashboardRoutingModule = RouterModule.forChild(CRMDashBoardRoutes);
