<form [formGroup]="agencySettingForm" #agencySettingDataForm>
	<div class="d-flex align-items-center justify-content-between py-2">
		<div class="d-flex">
			<h1 class="m-0 ml-2">{{'Company Settings' | translate}}</h1>
			<h1 *ngIf="isPlatformAdmin && company && company.name" class="font-weight-bold m-0 ml-1">({{company.name}} company)</h1>
		</div>
        <button *ngIf="disabledData" class="btn btn-info round" (click)="disabledData = false; isDisabled(!disabledData)" pTooltip="{{'button.edit' | translate}}" tooltipPosition="left"><i class="fa-solid fa-pen-to-square"></i></button>
    </div>
	<hr>
	<h2 class="m-0 ml-2 mt-2 mb-3">{{'Reminder Options' | translate}}</h2>
	<p-panel [toggleable]="true" toggler="header">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Reminders' | translate}}</span>
		</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Employee Reminders' | translate}}</label>
				<div class="d-flex flex-wrap align-items-center pt-2" style="column-gap: 50px; row-gap: 10px">
					<p-checkbox [disabled]="disabledData" id="reminderEmployeeVacation" [formControl]="agencySettingForm.controls['reminderEmployeeVacation']" binary="true" label="{{'form.reminderEmployeeVacation' | translate}}" [(ngModel)]="companySetting.reminderEmployeeVacation"></p-checkbox>
                	<p-checkbox [disabled]="disabledData" id="reminderEmployeeBirthday" [formControl]="agencySettingForm.controls['reminderEmployeeBirthday']" binary="true" label="{{'form.reminderEmployeeBirthday' | translate}}" [(ngModel)]="companySetting.reminderEmployeeBirthday"></p-checkbox>
					<p-checkbox [disabled]="disabledData" id="reminderEmployeeWorkingOvertime" [formControl]="agencySettingForm.controls['reminderEmployeeWorkingOvertime']" binary="true" label="{{'form.reminderEmployeeWorkingOvertime' | translate}}" [(ngModel)]="companySetting.reminderEmployeeWorkingOvertime"></p-checkbox>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Type' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-folder-tree"></i>
						</button>
					</div>
					<p-dropdown [options]="reminderOptions" [disabled]="disabledData" formControlName="reminderMethod" [(ngModel)]="companySetting.reminderMethod"  placeholder="{{'Method' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
				  </p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Period' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-mouse-pointer"></i>
						</button>
					</div>
					<p-dropdown [disabled]="disabledData" [options]="reminderTimePeriodOptions" formControlName="reminderTimePeriod" [(ngModel)]="companySetting.reminderTimePeriod" placeholder="{{'Select One' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Recipients' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-paper-plane"></i>
						</button>
					</div>
					<p-multiSelect [disabled]="true" emptyFilterMessage="{{'noResultsFound'| translate}}"  [defaultLabel]="multiSelectLabel" class="w-100" [options]="dropDownPositions" formControlName="sendTo" [filter]="false" (onChange)="PositionsToDropDownChange($event)" [(ngModel)]="companySetting.sendTo" id="id_Positions_to_selected_label">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate" style="font-size: 14px !important">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
				</div>
			</div>
			<div class="ui-g-3 ui-sm-6">
				<label>{{'Repeat Reminder' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<p-checkbox [disabled]="disabledData" formControlName="reminderNeedRepeat" [(ngModel)]="companySetting.reminderNeedRepeat" binary="true" pTooltip="{{companySetting.reminderNeedRepeat ? ('Disable' | translate) : ('Enable' | translate)}}"></p-checkbox>
						</button>
					</div>
					<p-dropdown [disabled]="disabledData" [options]="reminderRepeatEveryOptions" formControlName="reminderRepeatEvery" [(ngModel)]="companySetting.reminderRepeatEvery" placeholder="{{'Select One' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-3 ui-sm-6">
				<label>{{'End Reminders' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-day"></i>
						</button>
					</div>
					<p-calendar id="reminderRepeatEndOn" [disabled]="disabledData" [showTime]="true" dateFormat="M/d/yyyy" hourFormat="24" formControlName="reminderRepeatEndOn" [minDate]="minDateValue" [(ngModel)]="companySetting.reminderRepeatEndOn"></p-calendar>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Days of Week' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-check"></i>
						</button>
					</div>
					<p-multiSelect [disabled]="disabledData" [options]="daysOfWeek" name="reminderRepeatOn"  [formControl]="agencySettingForm.controls['reminderRepeatOn']" [(ngModel)]="companySetting.reminderRepeatOn" defaultLabel="{{'Select Items' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate" style="font-size: 14px !important">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
				</div>
			</div>
		</div>
	</p-panel>
	<br>
	<p-panel [toggleable]="true" toggler="header">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Job Report Reminders' | translate}}</span>
		</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Days' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-days"></i>
						</button>
					</div>
					<p-multiSelect [disabled]="disabledData" selectedItemsLabel="{{'selectedItemsLabel' | translate}}" class="w-100" [options]="jobReportDayOptions" formControlName="jrDayToSendNotify" [(ngModel)]="companySetting.jrDayToSendNotify" defaultLabel="{{'form.sendTo' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate" style="font-size: 14px !important">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
				</div>
			</div>
			<div class="ui-g-2 ui-sm-12">
				<label>{{'Interval' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-stopwatch"></i>
						</button>
					</div>
					<p-dropdown [disabled]="disabledData" [options]="jobReportEveryOptions" formControlName="jrSendNotifyEvery" [(ngModel)]="companySetting.jrSendNotifyEvery" placeholder="{{'pleaseSelect' | translate}}" (ngModelChange)="companySetting.customizedTimeIntervalHour = null; companySetting.customizedTimeIntervalMinute = null">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-4 ui-sm-12">
				<label class="pl-2">{{'Customized Time Interval' | translate}}</label>
				<div class="input-group w-100 d-flex align-items-center justify-content-between">
					<span class="pr-2">OR</span>
					<input class="mr-2" [attr.disabled]="disabledData ? 'true' : null" type="number" pInputText formControlName="customizedTimeIntervalHour" [(ngModel)]="companySetting.customizedTimeIntervalHour" min="0" (input)="companySetting.jrSendNotifyEvery = null">
					<span class="px-2">Hour</span>
					<input [attr.disabled]="disabledData ? 'true' : null" type="number" pInputText formControlName="customizedTimeIntervalMinute" [(ngModel)]="companySetting.customizedTimeIntervalMinute" min="0" (input)="companySetting.jrSendNotifyEvery = null">
					<span class="px-2">Min</span>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Additional Recipients' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i *ngIf="!fillAllEmployeeEmailLoading" class="fa-solid fa-paper-plane"></i>
							<i *ngIf="fillAllEmployeeEmailLoading" class="fa-solid fa-spinner loadingHourglass" pTooltip="{{'Loading...' | translate}}"></i>
						</button>
					</div>
					<p-autoComplete [disabled]="disabledData" #item [(ngModel)]="companySetting.jrNeedToNotifyOtherPeopleArr" formControlName="jrNeedToNotifyOtherPeopleArr" [suggestions]="suggestionsForBaseEmail" (completeMethod)="filterEmailProviderForTo($event)" [minLength]="1" field="key" value="email" [multiple]="true" class="w-100"></p-autoComplete>
				</div>
			</div>
			<div class="ui-g-3 ui-sm-12">
				<label>{{'Type' | translate}}</label>
				<div class="d-flex align-items-center pt-2" style="column-gap: 50px">
					<p-checkbox [disabled]="disabledData" id="jobReportNotifyEmail" [formControl]="agencySettingForm.controls['jobReportNotifyEmail']" [(ngModel)]="companySetting.jobReportNotifyEmail" binary="true" label="{{'form.email' | translate}}"></p-checkbox>
					<p-checkbox [disabled]="disabledData" id="jobReportNotifySms" [formControl]="agencySettingForm.controls['jobReportNotifySms']"  [(ngModel)]="companySetting.jobReportNotifySms" binary="true" label="{{'form.SMS' | translate}}"></p-checkbox>
				</div>
			</div>
			<div class="ui-g-3 ui-sm-12">
				<label>{{'Allow Job Report time range expand' | translate}}</label>
				<div class="d-flex align-items-center pt-3">
					<p-radioButton [disabled]="disabledData" [(ngModel)]="companySetting.jrAllowExpand" name="jrAllowExpand" formControlName="jrAllowExpand" [value]="true" label="{{'yes' | translate}}" style="margin-right: 30px;"></p-radioButton>
					<p-radioButton [disabled]="disabledData" [(ngModel)]="companySetting.jrAllowExpand" name="jrAllowExpand" formControlName="jrAllowExpand" [value]="false" label="{{'no' | translate}}"></p-radioButton>
				</div>
			</div>

			<div class="ui-g-3 ui-sm-12">
				<label>{{'If missed Job Report (Per Day)' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-stopwatch"></i>
						</button>
					</div>
					<p-dropdown [disabled]="disabledData" [options]="missedJobReportTimesOptions" formControlName="missedJobReportTimes" [(ngModel)]="companySetting.missedJobReportTimes" placeholder="{{'pleaseSelect' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-3 ui-sm-12">
				<label>{{'Action' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-stopwatch"></i>
						</button>
					</div>
					<p-dropdown [disabled]="disabledData" [options]="missedJobReportActionOptions" formControlName="missedJobReportAction" [(ngModel)]="companySetting.missedJobReportAction" placeholder="{{'pleaseSelect' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>

			<div class="ui-g-2 ui-sm-12">
				<label>{{'Send Job Report Every Day At' | translate}} :</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important;">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<!-- <p-calendar dataType="string"  [timeOnly]="true" placeholder="HH:MM AM/PM" 
						[(ngModel)]="companySetting.sendJobReportEveryDayAtStr" hourFormat="12" 
						[disabled]="disabledData" name="sendJobReportEveryDayAt" formControlName="sendJobReportEveryDayAt">
					</p-calendar> -->

                    <p-calendar dataType="string" [timeOnly]="true" placeholder="HH:MM AM/PM"
                                [(ngModel)]="companySetting.sendJobReportEveryDayAtStr" hourFormat="12"
                                [disabled]="disabledData" name="sendJobReportEveryDayAt" formControlName="sendJobReportEveryDayAt">
                    </p-calendar>

<!--					<p-calendar [disabled]="disabledData" dataType="string"  [timeOnly]="true" name="sendJobReportEveryDayAt" -->
<!--						[(ngModel)]="companySetting.sendJobReportEveryDayAtStr" -->
<!--						(ngModelChange)="onChangeSendJobReportEveryDayAt()" formControlName="sendJobReportEveryDayAt"></p-calendar>-->
				</div>
			</div>

			<div class="ui-g-4 ui-sm-12">
				<label>{{'To Email Below' | translate}} (Max 10 Email Addresses)</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-at"></i>
						</button>
					</div>
					<p-chips #sjredeInputChips class="w-100 flex-auto" [style]="{width: '100%'}" [inputStyle]="{'padding-left':'5px'}"
						formControlName="sendJobReportEveryDayEmails" [(ngModel)]="sendJobReportEveryDayEmailList" 
						(onBlur)="getValueChipsSendJobReportEveryDayEmails($event)"
						(onAdd)="validateSjredeBeforeAddEmail($event, sjredeInputChips)" [max]="10"  placeholder="Maximum 10 emails">
					</p-chips>
					<div class="input-group-prepend p-0">
						<button class="btn btn-primary" id="button-addon2" type="button" style="cursor:default !important" 
							pTooltip="{{'Add Email' | translate}}" tooltipPosition="left" (click)="actionAddSjredEmail(sjredeInputChips)">
							<i class="fa-solid fa-plus"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</p-panel>

	<h2 class="m-0 ml-2 my-4">{{'Notifications & Alerts' | translate}}</h2>

	<p-panel [toggleable]="true" toggler="header">
		<p-header class="flex-grow-1 d-flex aign-items-center justify-content-between">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Notification Settings' | translate}}</span>
			<span class="mr-3 text-dark" *ngIf="formMode === 'update'" (click)="showHistory('notify-history');$event.stopPropagation();" style="cursor: pointer !important"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i>{{'history.title' | translate}}</span>
		</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'E-Mail Notifications' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<p-checkbox [disabled]="disabledData" [formControl]="agencySettingForm.controls['employeeMissingPunchNotificationMethod']" name="group1" [(ngModel)]="notificationMethods" value="Email" pTooltip="{{ ((EmailToggle && notificationMethods) || EmailToggle) ? ('Disable' | translate) : ('Enable' | translate) }}" (click)="checkEmail()"></p-checkbox>
						</button>
					</div>

					<p-chips #inputChipsEmployeeMissingPunchNotificationEmail class="w-100 flex-auto" [style]="{width: '100%'}" [inputStyle]="{'padding-left':'5px'}"
						formControlName="employeeMissingPunchNotificationEmail" [(ngModel)]="notificationMethodEmailList" 
						(onBlur)="getValueChipsEmployeeMissingPunchNotificationEmail($event)"
						(onAdd)="validateBeforeAddEmailEmployeeMissingPunchNotificationEmail($event, inputChipsEmployeeMissingPunchNotificationEmail)" 
						[max]="5"  placeholder="Maximum 5 emails">
					</p-chips>
					<div class="input-group-prepend p-0">
						<button class="btn btn-primary" id="button-addon2" type="button" style="cursor:default !important" 
							pTooltip="{{'Add Email' | translate}}" tooltipPosition="left" (click)="actionAddEmailEmployeeMissingPunchNotificationEmail(inputChipsEmployeeMissingPunchNotificationEmail)">
							<i class="fa-solid fa-plus"></i>
						</button>
					</div>

					<!-- <input [attr.disabled]="disabledData" type="email" pInputText [formControl]="agencySettingForm.controls['employeeMissingPunchNotificationEmail']"
						   [(ngModel)]="notificationMethodEmail" [disabled]="!EmailToggle" id="email-input"
						   (ngModelChange)="onEmailChange(agencySettingDataForm)"
						   placeholder="{{ EmailToggle ? 'example@operr.com' : ('Check to enable' | translate) }}"> -->
				</div>
				<!-- <span class="error text-danger" *ngIf="isNValidEmail" style="font-size: 11px">{{'validateEmail' | translate:{value: 'form.emailAddress' | translate} }}</span> -->
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'SMS Notifications' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<p-checkbox [disabled]="disabledData" [formControl]="agencySettingForm.controls['employeeMissingPunchNotificationMethod']" name="group2" [(ngModel)]="notificationMethods" value="SMS" pTooltip="{{ ((SMSToggle && notificationMethods) || SMSToggle) ? ('Disable' | translate) : ('Enable' | translate) }}"  (click)="checkSMS()"></p-checkbox>
						</button>
					</div>
					<p-inputMask [disabled]="disabledData" class="w-100" id="sms-input" mask="(999) 999-9999" formControlName="smsNumber" [disabled]="!SMSToggle" [(ngModel)]="smsNotification" placeholder="(###) ###-####"></p-inputMask>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'sendAt' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-calendar [disabled]="disabledData" dataType="string"  [timeOnly]="true" name="sendAt" [(ngModel)]="companySetting.sendAtStr" (ngModelChange)="onChangeSendAt()" formControlName="sendAt"></p-calendar>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Clock In/Out Conditions' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-triangle-exclamation"></i>
						</button>
					</div>
					<p-dropdown [disabled]="disabledData" [options]="clockInOutConditionOptions" formControlName="clockInOutCondition"  [(ngModel)]="companySetting.clockInOutCondition" placeholder="{{'pleaseSelect' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Client Invoice' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-paper-plane"></i>
						</button>
					</div>
					<p-dropdown [disabled]="disabledData" [options]="clientInvoiceNotificationOptions" formControlName="clientInvoiceNotification"  [(ngModel)]="companySetting.clientInvoiceNotification" placeholder="{{'pleaseSelect' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Already Paid' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" type="button" style="cursor:default !important">
							<i class="fa-solid fa-info-circle"></i>
						</button>
					</div>
					<p-dropdown [disabled]="disabledData" [options]="alreadyPaidOptions" formControlName="alreadyPaid"  [(ngModel)]="companySetting.alreadyPaid" placeholder="{{'pleaseSelect' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
		</div>
	</p-panel>
	<br>
	<p-panel [toggleable]="true" toggler="header">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Operational Alerts' | translate}}</span>
		</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-12">
				<label>{{'Alert Types' | translate}}</label>
				<div class="ui-g-3 ui-sm-6 p-0 py-1" *ngFor="let notifyFor of getNotifyFor; let i = index;">
					<span class="d-flex align-items-center">
						<p-checkbox [disabled]="disabledData" aria-checked="notifyFor.selected" value="true" [id]="'check' + i" name="{{notifyFor.id}}" aria-checked="notifyFor.selected" [ngModelOptions]="{standalone: true}" [ngModel]="notifyFor.selected" (onChange)="getNotifyForCheck($event, notifyFor.id);" binary="true"></p-checkbox>
						<span class="one-liner" pTooltip="{{notifyFor.name | translate}}" tooltipPosition="left" style="font-size: 16px">{{notifyFor.name | translate}}</span>
					</span>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12" *ngIf="checkboxStates['editPunchInOut']">
				<label>{{'Edit Punch in/out' | translate}}: </label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-days"></i>
						</button>
					</div>
					<input [attr.disabled]="disabledData" type="number" pInputText formControlName="sendAlertWhenPunchInOut" [(ngModel)]="companySetting.sendAlertWhenPunchInOut" min="0">
				</div>
				<div *ngIf="agencySettingForm.controls['sendAlertWhenPunchInOut'].invalid && agencySettingForm.controls['sendAlertWhenPunchInOut'].touched" class="text-danger">{{'This field is required' | translate}}</div>
			</div>

			<div class="ui-g-6 ui-sm-12" *ngIf="checkboxStates['protestHour']">
				<label>{{'Quota (Max. Time-Corrections)' | translate}} 	<i sclass="ml-2 fa-solid fa-circle-question text-danger" (mouseenter)="opTimeCorrectionQuota.show($event)" (mouseleave)="opTimeCorrectionQuota.hide()"></i></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-hand"></i>
						</button>
					</div>
					<input [attr.disabled]="disabledData" type="number" pInputText formControlName="timeCorrectionQuota" [(ngModel)]="companySetting.timeCorrectionQuota" min="0">
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12" *ngIf="checkboxStates['protestHour']">
				<label>{{'Quota Period' | translate}}: </label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-week"></i>
						</button>
					</div>
					<p-dropdown [disabled]="disabledData" [options]="perOptions" formControlName="timeCorrectionPer" [(ngModel)]="companySetting.timeCorrectionPer" placeholder="{{'pleaseSelect' | translate}}"></p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12" *ngIf="checkboxStates['employeeEndTime']">
				<label>{{'Employee End-Time' | translate}}: <i class="ml-2 fa-solid fa-circle-question text-danger" (mouseenter)="opEmployeeEndTime.show($event)" (mouseleave)="opEmployeeEndTime.hide()"></i></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-calendar [disabled]="disabledData" dataType="string"  [timeOnly]="true" name="employeeEndTimeRemider" [(ngModel)]="companySetting.employeeEndTimeRemiderStr" (ngModelChange)="onChangeEmployeeEndTimeRemider()" formControlName="employeeEndTimeRemider" placeholder="HH:MM"></p-calendar>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12" *ngIf="checkboxStates['Plan/Assignment']">
				<label>{{'Assignment Alert Time' | translate}}: <i class="ml-2 fa-solid fa-circle-question text-danger" (mouseenter)="op1.show($event)" (mouseleave)="op1.hide()"></i></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-calendar [disabled]="disabledData" dataType="string"  [timeOnly]="true" name="planAssignmentAlertTime" [(ngModel)]="companySetting.planAssignmentAlertTimeStr" (ngModelChange)="onChangePlanAssignmentAlertTime()" formControlName="planAssignmentAlertTime" placeholder="HH:MM"></p-calendar>
				</div>
			</div>
		</div>
		<p-overlayPanel #opEmployeeEndTime appendTo="body" styleClass="custom">
			<div style=" max-width: 300px;">
				<div style="background-color: #5b93ce; color: #fff;" class="d-flex align-items-center px-2 py-1">
					<i style="color: #fff;" class="mr-2 fa-solid fa-info-circle"></i>
						{{'information' | translate}}
				</div>
				<div class="px-2 py-3">
					This field when selected will send email reminder to user base on the frequency added, if add 02:00 (HH:MM) hence system will send email to user every 2hrs
				</div>
			</div>
		</p-overlayPanel>
		<p-overlayPanel #opTimeCorrectionQuota appendTo="body" styleClass="custom">
			<div style=" max-width: 300px;">
				<div style="background-color: #5b93ce; color: #fff;" class="d-flex align-items-center px-2 py-1">
					<i style="color: #fff;" class="mr-2 fa-solid fa-info-circle"></i>
						{{'information' | translate}}
				</div>
				<div class="px-2 py-3">
					Maximum Time correction employee is allowed to submit for a certain period
				</div>
			</div>
		</p-overlayPanel>
		  <p-overlayPanel #op1 appendTo="body" styleClass="custom">
			<div style="max-width: 300px;">
				<div style="background-color: #5b93ce; color: #fff;" class="d-flex align-items-center px-2 py-1">
					<i style="color: #fff;" class="mr-2 fa-solid fa-info-circle"></i>{{'information' | translate}}
				</div>
				<div class="px-2 py-3">
					This field when selected will send email reminder to user base on the frequency added, if add 02:00 (HH:MM) hence system will send email to user every 2hrs from the clock in time of user until enter at least on Plan/Assignment.
				</div>
			</div>
		</p-overlayPanel>
	</p-panel>
	<br>
	<p-panel [toggleable]="true" toggler="header">
		<p-header class="flex-grow-1 d-flex align-items-center justify-content-between">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Punch History Emails' | translate}}</span>
			<span class="text-dark mr-3" *ngIf="formMode === 'update'" (click)="showHistory('punch_history');$event.stopPropagation();" style="cursor: pointer"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i>{{'history.title' | translate}}</span>
		</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'form.financeEmail' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-at"></i>
						</button>
					</div>
					<!-- <input id="suportEmail" formControlName="financeEmail" [(ngModel)]="companySetting.financeEmail" type="text" pInputText/> -->

					<p-chips #inputChips class="w-100 flex-auto" [style]="{width: '100%'}" [inputStyle]="{'padding-left':'5px'}"
						formControlName="financeEmail" [(ngModel)]="financeEmailList" (onBlur)="getValueChips($event)"
						(onAdd)="validateBeforeAddEmail($event, inputChips)" [max]="2"  placeholder="Maximum 2 emails">
					</p-chips>
					<div class="input-group-prepend p-0">
						<button class="btn btn-primary" id="button-addon2" type="button" style="cursor:default !important" 
							pTooltip="{{'Add Email' | translate}}" tooltipPosition="left" (click)="actionAddEmail(inputChips)">
							<i class="fa-solid fa-plus"></i>
						</button>
					</div>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="agencySettingForm.controls['financeEmail'].errors?.required && agencySettingForm.controls['financeEmail'].dirty">{{'validate.required' | translate:{value: 'form.financeEmail' | translate} }}</span>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Recipients' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<p-checkbox [disabled]="disabledData" name="sendActive" [formControl]="agencySettingForm.controls['sendActive']" [(ngModel)]="companySetting.sendActive" binary="true" pTooltip="{{ companySetting.sendActive ? ('Disable' | translate) : ('Enable' | translate) }}" (onChange)="checkedActiveEmail()"></p-checkbox>
						</button>
					</div>
					<p-multiSelect selectedItemsLabel="{{'selectedItemsLabel' | translate}}" class="d-inline-block w-100" 
						[filter]="true" [options]="sendTos" formControlName="emailSendTo" [(ngModel)]="companySetting.emailSendTo" 
						defaultLabel="{{'form.sendTo' | translate}}" [disabled]="!agencySettingForm.get('sendActive').value === true || disabledData"
						(onChange)="sendToDropDownChange($event)">
						<ng-template pTemplate="item" let-item>
							<span class="one-liner" style="font-size: 14px !important;max-width: 90%;">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="agencySettingForm.get('sendActive').value === true && agencySettingForm.controls['emailSendTo'].errors?.required && agencySettingForm.controls['emailSendTo'].dirty">{{'validate.required' | translate:{value: 'form.sendTo' | translate} }}</span>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Scheduled Send' | translate}} <span style="color: red">*</span>:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-day"></i>
						</button>
					</div>
					<p-dropdown defaultLabel="Day of the week" [options]="daysOfWeek" [disabled]="!agencySettingForm.get('sendActive').value === true || disabledData" [(ngModel)]="companySetting.dayOfWeek" formControlName="dayOfWeek" placeholder="{{'pleaseSelect' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="(agencySettingForm.get('sendActive').value === true) && agencySettingForm.controls['dayOfWeek'].errors?.required && agencySettingForm.controls['dayOfWeek'].dirty">{{'validate.thisField' | translate}}</span>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'at' | translate}} <span style="color: red">*</span>:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important;">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-calendar dataType="string"   [timeOnly]="true" placeholder="HH:MM AM/PM" [(ngModel)]="companySetting.atHoursStr" hourFormat="12" [disabled]="!agencySettingForm.get('sendActive').value === true || disabledData" name="atHours" formControlName="atHours"></p-calendar>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="(agencySettingForm.get('sendActive').value === true) && agencySettingForm.controls['atHours'].errors?.required && agencySettingForm.controls['atHours'].dirty">{{'validate.thisField' | translate}}</span>
			</div>
		</div>
	</p-panel>
	<br>
	<!-- <p-panel [toggleable]="true" [collapsed]="true">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Overtime Alerts' | translate}}</span>
		</p-header>
		<div class="ui-g mb-0">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Time Format' | translate}}</label>
				<div class="d-flex flex-wrap align-items-center pt-2" style="column-gap: 50px; row-gap: 10px">
					<p-radioButton [disabled]="disabledData" name="otAlertFormatHour" [(ngModel)]="otHourFormat" formControlName="otAlertFormatHour" value="12" label="{{'12 Hour' | translate}}" inputId="ota_opt1"></p-radioButton>
					<p-radioButton [disabled]="disabledData" name="otAlertFormatHour" [(ngModel)]="otHourFormat" formControlName="otAlertFormatHour" value="24" label="{{'24 Hour' | translate}}" inputId="ota_opt2"></p-radioButton>
				</div>
			</div>
		</div>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'form.overtimeAlert' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<p-checkbox [disabled]="disabledData" name="overtimeAlert" [formControl]="agencySettingForm.controls['overtimeAlert']" (onChange)="onCheckOvertimeAlertToggle($event)"   [(ngModel)]="overtimeAlert" binary="true" pTooltip="{{ agencySettingForm.controls['overtimeAlert'] ? ('Disable' | translate) : ('Enable' | translate) }}"></p-checkbox>
						</button>
					</div>
					<p-calendar [disabled]="disabledData" #calendar [(ngModel)]="otHour" *ngIf="otHourFormat == '12'" [ngModelOptions]="{standalone: true}" placeholder="HH:MM" [timeOnly]="true" [showTime]="true" hourFormat="12" (ngModelChange)="onChangeOtAlert($event)">
						<p-footer class="d-flex py-2 px-1">
							<button class="btn btn-white flex-grow-1 mx-1" (click)="calendar.overlayVisible = false; otHour = ''"><i class="fa-solid fa-xmark text-danger mr-2"></i>{{'Cancel' | translate}}</button>
							<button class="btn btn-white flex-grow-1 mx-1" type="button" (click)="calendar.overlayVisible = false"><i class="fa-solid fa-check text-primary mr-2"></i>{{'Apply' | translate}}</button>
						</p-footer>
					</p-calendar>
					<p-calendar [disabled]="disabledData" #calendar [(ngModel)]="otHour" *ngIf="otHourFormat == '24'" [ngModelOptions]="{standalone: true}" placeholder="HH:MM" [timeOnly]="true" [showTime]="true" hourFormat="24" (ngModelChange)="onChangeOtAlert($event)">
						<p-footer class="d-flex py-2 px-1">
							<button class="btn btn-white flex-grow-1 mx-1" (click)="calendar.overlayVisible = false; otHour = ''"><i class="fa-solid fa-xmark text-danger mr-2"></i>{{'Cancel' | translate}}</button>
							<button class="btn btn-white flex-grow-1 mx-1" type="button" (click)="calendar.overlayVisible = false"><i class="fa-solid fa-check text-primary mr-2"></i>{{'Apply' | translate}}</button>
						</p-footer>
					</p-calendar>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Alert Timing' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-triangle-exclamation"></i>
						</button>
					</div>
					<p-dropdown [disabled]="disabledData" [options]="otAlertType" formControlName="otAlertType"  [(ngModel)]="otAlertTypeMethod" placeholder="{{'pleaseSelect' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Recipients (E-Mail Alerts)' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-paper-plane"></i>
						</button>
					</div>
					<p-multiSelect [disabled]="disabledData" defaultLabel="{{'pleaseSelect' | translate}}" emptyFilterMessage="{{'noResultsFound'| translate}}" selectedItemsLabel="{{'selectedItemsLabel' | translate}}" defaultLabel="{{'Select Items' | translate}}" [options]="otAlertOptions" formControlName="otAlertEmail"  (onChange)="otAlertEmailToDropDownChange($event)" [(ngModel)]="otAlertEmailMethod" id="id_otAlertEmail_to_selected_label">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate" style="font-size: 14px !important">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Recipients (SMS Alerts)' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-paper-plane"></i>
						</button>
					</div>
					<p-multiSelect [disabled]="disabledData" defaultLabel="{{'pleaseSelect' | translate}}" emptyFilterMessage="{{'noResultsFound'| translate}}" selectedItemsLabel="{{'selectedItemsLabel' | translate}}" defaultLabel="{{'Select Items' | translate}}" [options]="otAlertSMSOptions" formControlName="otAlertSMS" (onChange)="otAlertSMSToDropDownChange($event)" [(ngModel)]="otAlertSMSMethod" id="id_otAlertSMS_to_selected_label">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate" style="font-size: 14px !important">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
				</div>
			</div>
		</div>
	</p-panel> -->
	<h2 class="m-0 ml-2 my-4">{{'Security' | translate}}</h2>

	<p-panel [toggleable]="true" toggler="header">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-shield-halved text-primary mr-2"></i>{{'Login' | translate}}</span>
		</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'maxLoginAttemptCountEmployee' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-hand"></i>
						</button>
					</div>
					<input [attr.disabled]="disabledData" id="maxLoginAttemptCountEmployee" min="0" [(ngModel)]="companySetting.maxLoginAttemptCountEmployee" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" formControlName="maxLoginAttemptCountEmployee" type="number" pInputText/>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Maxloginattemptcountadmin' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-hand"></i>
						</button>
					</div>
					<input [attr.disabled]="disabledData" id="maxLoginAttemptCountAdmin" min="0" [(ngModel)]="companySetting.maxLoginAttemptCountAdmin" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" formControlName="maxLoginAttemptCountAdmin" type="number" pInputText/>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'form.Calendarnotification' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-check"></i>
						</button>
					</div>
					<p-dropdown [disabled]="disabledData" [(ngModel)]="companySetting.calendarNotification" name="calendarNotification" formControlName="calendarNotification" [options]="notificationOptions" optionLabel="label" optionValue="value">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-3 ui-sm-12">
				<label>{{'form.Notificationmethod' | translate}}</label>
				<div class="d-flex flex-wrap pt-2" style="column-gap: 30px">
					<span class="one-liner"><p-checkbox [disabled]="disabledData" id="calendarNotificationMethod" [formControl]="agencySettingForm.controls['calendarNotificationMethod']" value="Email" [(ngModel)]="calendarNotificationMethods"></p-checkbox>{{'Email' | translate}}</span>
					<span class="one-liner"><p-checkbox [disabled]="disabledData" id="calendarNotificationMethod"    [(ngModel)]="calendarNotificationMethods" [formControl]="agencySettingForm.controls['calendarNotificationMethod']" value="SMS"></p-checkbox>{{'SMS' | translate}}</span>
				</div>
			  </div>
			  <div class="ui-g-3 ui-sm-12">
				<label>{{'Double Verification' | translate}}</label>
				<div class="d-flex flex-wrap pt-2" style="column-gap: 30px">
					<span class="one-liner"><p-checkbox [disabled]="disabledData" name="is2ndVerificationEmployeeEnable" [(ngModel)]="is2ndVerificationEmployeeEnable" [formControl]="agencySettingForm.controls['is2ndVerificationEmployeeEnable']" binary="true"></p-checkbox>{{'Employee' | translate}}</span>
					<span class="one-liner"><p-checkbox [disabled]="disabledData" name="is2ndVerificationAdminEnable" [(ngModel)]="is2ndVerificationAdminEnable" [formControl]="agencySettingForm.controls['is2ndVerificationAdminEnable']" binary="true"></p-checkbox>{{'Admin' | translate}}</span>
				</div>
			</div>
		</div>
	</p-panel>
	<br>
	<p-panel [toggleable]="true" toggler="header">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'PIN Verification' | translate}}</span>
		</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Max. Input Attempts' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-hand"></i>
						</button>
					</div>
				  	<input [disabled]="disabledData" type="number" min=1 max=9 pInputText [(ngModel)]="companySetting.pinVerificationLimit" (keyup)="preventInputOfPinVerifitcationLimit($event)" formControlName="pinVerificationLimit" id="pinVerificationLimit"/>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Count Inactivity (hrs)' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<p-checkbox [disabled]="disabledData" [(ngModel)]="companySetting.requiredHoursStatus" formControlName="requiredHoursStatus" [binary]="true" pTooltip="{{ companySetting.requiredHoursStatus ? ('Enable' | translate) : ('Disable' | translate) }}"></p-checkbox>
						</button>
					</div>
					<p-calendar [timeOnly]="true" placeholder="HH:MM" hourFormat="24" [disabled]="!companySetting.requiredHoursStatus || disabledData" utc="true" name="requiredHours" [(ngModel)]="companySetting.requiredHours" formControlName="requiredHours"></p-calendar>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'PIN/Facial Recognition' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-check"></i>
						</button>
					</div>
					<p-dropdown [disabled]="disabledData" [(ngModel)]="companySetting.pinFacialRecognition" name="pinFacialRecognition" formControlName="pinFacialRecognition" [options]="pinFacialRecognitionOptions">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Punch Validation' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-check"></i>
						</button>
					</div>
					<p-dropdown [disabled]="disabledData" [(ngModel)]="companySetting.punchValidation" name="punchValidation" formControlName="punchValidation" [options]="punchValidationOptions">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
		</div>
	</p-panel>
	<h2 class="m-0 ml-2 my-4">{{'Other Preferences' | translate}}</h2>

	<p-panel [toggleable]="true" toggler="header">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Support Email' | translate}}</span>
		</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Support Email' | translate}}: <span class="text-red">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-at"></i>
						</button>
					</div>
					<input [disabled]="disabledData" id="suportEmail" formControlName="supportEmail" [(ngModel)]="companySetting.supportEmail" type="text" pInputText/>
				</div>
				<span class="text-red" *ngIf="isEmptySupportEmail">{{'validate.pleaseFill' | translate:{value: 'supportEmail' | translate} }}</span>
				<span class="text-red" *ngIf="isNValidSupportEmail">{{'validate.pleaseEnter' | translate:{value: 'supportEmail' | translate} }}</span>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'supportResponseTime' | translate}} (hh:mm)</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-stopwatch"></i>
						</button>
					</div>
					<p-calendar [disabled]="disabledData" dataType="string"  [timeOnly]="true" placeholder="HH:MM" (ngModelChange)="onChangeSupportResponseTime()" name="supportResponseTime" [(ngModel)]="companySetting.supportResponseTimeStr" formControlName="supportResponseTime"></p-calendar>
				</div>
			</div>
		</div>
	</p-panel>  	
	<br>
	<p-panel [toggleable]="true" toggler="header">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'form.clientPaymentGracePeriod' | translate}}</span>
		</p-header>
		<div class="ui-g">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Enable' | translate}}</label>
				<div class="d-flex align-items-center pt-3">
					<p-radioButton [disabled]="disabledData" [(ngModel)]="companySetting.clientPaymentGracePeriod" name="clientPaymentGracePeriod" formControlName="clientPaymentGracePeriod" [value]="true" label="{{'yes' | translate}}" style="margin-right: 30px;"></p-radioButton>
					<p-radioButton [disabled]="disabledData" [(ngModel)]="companySetting.clientPaymentGracePeriod" name="clientPaymentGracePeriod" formControlName="clientPaymentGracePeriod" [value]="false" label="{{'no' | translate}}"></p-radioButton>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'form.Notificationmethod' | translate}}</label>
				<div class="d-flex flex-wrap align-items-center pt-2" style="column-gap: 50px; row-gap: 10px">
					<p-checkbox [disabled]="disabledData" id="seniorPunchInOutNotificationToEmergencyContactMethod" [formControl]="agencySettingForm.controls['seniorPunchInOutNotificationToEmergencyContactMethod']" [(ngModel)] = "seniorPunchInOutNotificationToEmergencyContactMethods" value="Email" label="{{'form.email' | translate}}"></p-checkbox>
					<p-checkbox [disabled]="disabledData" id="seniorPunchInOutNotificationToEmergencyContactMethod" [formControl]="agencySettingForm.controls['seniorPunchInOutNotificationToEmergencyContactMethod']" [(ngModel)] = "seniorPunchInOutNotificationToEmergencyContactMethods" value="SMS" label="{{'form.SMS' | translate}}"></p-checkbox>
				</div>
			</div>
		</div>
	</p-panel>  
	<br>
	<p-panel [toggleable]="true" toggler="header">
		<p-header class="flex-grow-1 d-flex aign-items-center justify-content-between">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Miscellaneous' | translate}}</span>
			<span class="mr-3 text-dark" *ngIf="formMode === 'update'" (click)="showHistory('misc-history');$event.stopPropagation();" style="cursor: pointer !important"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i>{{'history.title' | translate}}</span>
		</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'form.mobileVisitVerification' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<p-checkbox [disabled]="disabledData" name="mobileVisitVerification" [formControl]="agencySettingForm.controls['mobileVisitVerification']" binary="true" [(ngModel)]="companySetting.mobileVisitVerification" (onChange)="isDisabled($event.checked)"></p-checkbox>
						</button>
					</div>
					<input [attr.disabled]="true" name="toleranceRange" formControlName="toleranceRange" [(ngModel)]="companySetting.toleranceRange" min="0" type="number" pInputText (keydown)="onKeyUp($event)" placeholder="Tolerance Range (ft)"/>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Tracking Update Interval' | translate}} (HH:MM)</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-stopwatch"></i>
						</button>
					</div>
					<p-calendar [disabled]="disabledData" dataType="string"  [timeOnly]="true" (ngModelChange)="onChangeUpdateTrackEvery()" name="updateTrackingEvery" [(ngModel)]="companySetting.updateTrackingEveryStr" formControlName="updateTrackingEvery"></p-calendar>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'form.sendAlert' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-stopwatch"></i>
						</button>
					</div>
					<p-calendar [disabled]="disabledData" dataType="string"  [timeOnly]="true" name="sendAlert" [(ngModel)]="companySetting.sendAlertStr" formControlName="sendAlert"></p-calendar>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Overtime Validation & Prompt' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<p-checkbox id="overtimeValidation" [formControl]="agencySettingForm.controls['overtimeValidation']" (onChange)="onCheckOvertimeToggle($event)" [(ngModel)]="overtimeValidation" binary="true"></p-checkbox>
						</button>
					</div>
					<input [attr.disabled]="disabledData" pInputText [formControl]="agencySettingForm.controls['promptPasswordAt']" min="0" type="number" [(ngModel)]="promptPasswordAt" (keydown)="onKeyUp($event)" placeholder="{{'form.promptPasswordAt' | translate}}"/>
				</div>
			</div>
		</div> 
	</p-panel>
	<br>
	<!-- <p-panel [toggleable]="true" [collapsed]="true">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Main Menu' | translate}}</span>
		</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Employee Menu Items' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-bars"></i>
						</button>
					</div>
					<p-multiSelect [disabled]="disabledData" defaultLabel="{{'Select Items' | translate}}" emptyFilterMessage="{{'noResultsFound'| translate}}" selectedItemsLabel="{{'selectedItemsLabel' | translate}}" class="w-100" [options]="employeeMenus" formControlName="employeeMenuList" (onChange)="employeeMenuToDropDownChange($event)" [(ngModel)]="companySetting.employeeMenuList" id="id_employeeMenu_to_selected_label">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate" style="font-size: 14px !important">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Department Permissions' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-folder-tree"></i>
						</button>
					</div>
					<p-multiSelect [disabled]="disabledData" defaultLabel="{{'Select Items' | translate}}" emptyFilterMessage="{{'noResultsFound'| translate}}" selectedItemsLabel="{{'selectedItemsLabel' | translate}}" [options]="employeeDepartments" formControlName="employeeDepartmentList" (onChange)="employeeDepartmentToDropDownChange($event)" id="id_employeeDepartment_to_selected_label" [(ngModel)]="companySetting.employeeDepartmentList">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate" style="font-size: 14px !important">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'CRMmenulist' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-bars"></i>
						</button>
					</div>
					<p-multiSelect defaultLabel="{{'Select Items' | translate}}" [disabled]="disabledData"
					emptyFilterMessage="{{'noResultsFound'| translate}}"
					selectedItemsLabel="{{'selectedItemsLabel' | translate}}" class="flex-auto"
					[options]="crmMenus" formControlName="crmMenuList" [style]="{'width':'100%'}"
					(onChange)="crmMenuToDropDownChange($event)" [(ngModel)]="companySetting.crmMenuList"
					id="id_crmMenuList_to_selected_label">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate" style="font-size: 14px !important">{{item.label | translate}}</span>
					</ng-template>
				 </p-multiSelect>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Department Permissions' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-folder-tree"></i>
						</button>
					</div>
					<p-multiSelect [disabled]="disabledData" defaultLabel="{{'Select Items' | translate}}" emptyFilterMessage="{{'noResultsFound'| translate}}" selectedItemsLabel="{{'selectedItemsLabel' | translate}}" class="w-100" [options]="crmDepartments" formControlName="crmDepartmentList" (onChange)="crmDepartmentToDropDownChange($event)" id="id_crmDepartment_to_selected_label" [(ngModel)]="companySetting.crmDepartmentList">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate" style="font-size: 14px !important">{{item.label | translate}}</span>
						</ng-template>
				 	</p-multiSelect>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label translate>Othermenulist</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-bars"></i>
						</button>
					</div>
					<p-multiSelect [disabled]="disabledData" defaultLabel="{{'Select Items' | translate}}" emptyFilterMessage="{{'noResultsFound'| translate}}" selectedItemsLabel="{{'selectedItemsLabel' | translate}}" class="w-100" [options]="otherMenus" formControlName="otherMenuList" (onChange)="otherMenuToDropDownChange($event)" [(ngModel)]="companySetting.otherMenuList" id="id_otherMenuList_to_selected_label">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate" style="font-size: 14px !important">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Department Permissions' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-folder-tree"></i>
						</button>
					</div>
					<p-multiSelect [disabled]="disabledData" defaultLabel="{{'Select Items' | translate}}" emptyFilterMessage="{{'noResultsFound'| translate}}" selectedItemsLabel="{{'selectedItemsLabel' | translate}}" [options]="otherDepartments" formControlName="otherDepartmentList" (onChange)="otherDepartmentToDropDownChange($event)" id="id_otherDepartment_to_selected_label" [(ngModel)]="companySetting.otherDepartmentList">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate" style="font-size: 14px !important">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Accounting' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-bars"></i>
						</button>
					</div>
					<p-multiSelect [disabled]="disabledData" defaultLabel="{{'Select Items' | translate}}" emptyFilterMessage="{{'noResultsFound'| translate}}" selectedItemsLabel="{{'selectedItemsLabel' | translate}}" class="w-100" [options]="otherMenus" formControlName="accountMenuList" (onChange)="accountMenuToDropDownChange($event)" [(ngModel)]="companySetting.accountMenuList" id="id_accountMenuList_to_selected_label">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate" style="font-size: 14px !important">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Department Permissions' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-folder-tree"></i>
						</button>
					</div>
					<p-multiSelect [disabled]="disabledData" defaultLabel="{{'Select Items' | translate}}"
					emptyFilterMessage="{{'noResultsFound'| translate}}" selectedItemsLabel="{{'selectedItemsLabel' | translate}}" class="w-100"
					[options]="crmDepartments" formControlName="crmDepartmentList"
					(onChange)="accountDepartmentToDropDownChange($event)" id="id_accountDepartment_to_selected_label" [(ngModel)]="companySetting.accountDepartmentList">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate" style="font-size: 14px !important">{{item.label | translate}}</span>
					</ng-template>
				 </p-multiSelect>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Billing' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-bars"></i>
						</button>
					</div>
					<p-multiSelect [disabled]="disabledData" defaultLabel="{{'Select Items' | translate}}" emptyFilterMessage="{{'noResultsFound'| translate}}" selectedItemsLabel="{{'selectedItemsLabel' | translate}}" class="w-100" [options]="otherMenus" formControlName="billingMenuList" (onChange)="billingMenuToDropDownChange($event)" [(ngModel)]="companySetting.billingMenuList" id="id_billingMenuList_to_selected_label">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate" style="font-size: 14px !important">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Department Permissions' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-folder-tree"></i>
						</button>
					</div>
					<p-multiSelect [disabled]="disabledData" defaultLabel="{{'Select Items' | translate}}" emptyFilterMessage="{{'noResultsFound'| translate}}" selectedItemsLabel="{{'selectedItemsLabel' | translate}}" class="w-100" [options]="bilingMenu" formControlName="billingMenuList"  (onChange)="billingMenuToDropDownChange($event)" [(ngModel)]="companySetting.billingDepartmentList" id="id_billingDepartment_to_selected_label">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate" style="font-size: 14px !important">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Username' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-user-shield"></i>
						</button>
					</div>
					<p-dropdown [disabled]="disabledData" [options]="userNameOption" formControlName="userNameOption" placeholder="{{'pleaseSelect' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>	
					</p-dropdown>
				</div>
			</div>
		</div>
	</p-panel> -->
	<br>
	<form #formRef="ngForm">
		<p-panel [toggleable]="true" id="table-ppanel" [collapsed]="true" toggler="header">
			<p-header class="flex-grow-1">
				<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Networked Devices' | translate}}</span>
			</p-header>
			<div *ngFor="let ip of listIpAddress; let in=index" class="px-3 pb-3 pt-2 d-flex flex-wrap align-items-center border-bottom">
				<div class="flex-grow-1 px-2 ui-sm-12">
					<label>{{'IP Address' | translate}} {{in + 1}} <span class="text-danger">*</span></label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-ethernet"></i>
							</button>
						</div>
						<input [readonly]="disabledData" type="text" placeholder="{{'header.ipNum' | translate}}" [(ngModel)]="listIpAddress[in].locationIp" name="name{{in}}" pInputText #name="ngModel" required/>
					</div>
				</div>
				<div class="ui-sm-12 text-center">
					<button [disabled]="disabledData" (click)="deleteIpAddress(ip.companyId, ip.id)" class="btn btn-white mt-3" pTooltip="{{'Delete' | translate}}" tooltipPosition="left"><i class="fa-solid fa-xmark text-danger"></i></button>
				</div>
				<div class="flex-grow-1 ui-sm-12 px-2">
					<label>{{'Name/Location' | translate}}</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-quote-left"></i>
							</button>
						</div>
						<input [readonly]="disabledData" type="text" placeholder="{{'locationName' | translate}}" [(ngModel)]="listIpAddress[in].locationName" name="name1{{in}}" pInputText #name="ngModel" required/>
					</div>
				</div>
<!--				<div class="ui-sm-12 text-center">-->
<!--					<button [disabled]="disabledData" (click)="deleteIpAddress(ip.companyId, ip.id)" class="btn btn-white mt-3" pTooltip="{{'Delete' | translate}}" tooltipPosition="left"><i class="fa-solid fa-xmark text-danger"></i></button>-->
<!--				</div>-->
			</div>
			<div class="px-3 pb-3 pt-2 d-flex flex-wrap align-items-center border-bottom">
				<div class="flex-grow-1 px-2 ui-sm-12">
					<label>{{'IP Address' | translate}}</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-ethernet"></i>
							</button>
						</div>
						<input [readonly]="disabledData" type="text" placeholder="{{'header.ipNum' | translate}}" [(ngModel)]="locationIp" name="nameNew" pInputText #name="ngModel" required/>
					</div>
				</div>
				<div class="ui-sm-12 text-center">
					<button [disabled]="disabledData" class="btn btn-white mt-3 text-primary" (click)="addMyCurrentIP()" pTooltip="{{'Add My Current IP' | translate}}" tooltipPosition="left">{{'Add My Current IP' | translate}}</button>
				</div>
				<div class="flex-grow-1 px-2 ui-sm-12">
					<label>{{'Name/Location' | translate}}</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-quote-left"></i>
							</button>
						</div>
						<input [readonly]="disabledData" id="input-ip" type="text" placeholder="{{'locationName' | translate}}" [(ngModel)]="locationName" name="nameOffice" pInputText #name="ngModel" required/>
					</div>
				</div>
				<div class="ui-sm-12 text-center">
					<button [disabled]="disabledData" class="btn btn-white mt-3 text-primary" (click)="createIpAddress()" pTooltip="{{'Add IP' | translate}}" tooltipPosition="left">{{'Add IP' | translate}}</button>
				</div>
<!--				<div class="ui-sm-12 text-center">-->
<!--					<button [disabled]="disabledData" class="btn btn-white mt-3" (click)="createIpAddress()" pTooltip="{{'Add' | translate}}" tooltipPosition="left"><i class="fa-solid fa-plus text-primary"></i></button>-->
<!--				</div>-->
			</div>
		</p-panel>
	</form>
	<br>
	<p-panel [toggleable]="true" toggler="header">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Birthday Song' | translate}}</span>
		</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Birthday Song' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<p-checkbox [disabled]="disabledData" binary="true" [(ngModel)]="companySetting.birthdaySongEnable" formControlName="birthdaySongEnable" pTooltip="{{'Enable' | translate}}" tooltipPosition="left"></p-checkbox>
						</button>
					</div>
					<input [attr.disabled]="disabledData" pInputText *ngIf="companySetting.birthdaySongEnable" type="text" placeholder="{{ birthdaySongFile?.fileName || ''}}" readonly>
					<input [attr.disabled]="disabledData" pInputText *ngIf="!companySetting.birthdaySongEnable" type="text" placeholder="{{ birthdaySongFile?.fileName || ''}}" disabled>
					<p-fileUpload [disabled]="disabledData"  [style.display]="'none'" mode="basic" #songfile name="songfile" url="./upload.php" customUpload="true" accept=".mp3,audio/*" (uploadHandler)="uploadSongFile(songfile)" chooseLabel="{{localSongFile?.files?.length ? localSongFile?.files[0].name : 'UploadSong' | translate}}" auto="auto"></p-fileUpload>
					<div class="input-group-append">
						<button [disabled]="disabledData" *ngIf="companySetting.birthdaySongEnable" class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important" pTooltip="{{'Play' | translate}}">
							<i *ngIf="muteVolume" (click)="stopAudio()" class="fa-solid fa-pause"></i>
							<i *ngIf="showVolume" (click)="playAudio(birthdaySongFile?.fileUrl)" class="fa-solid fa-play text-success"></i>
						</button>
						<button class="btn btn-white" (click)="openSongFileSelector()" id="button-addon2"><i class="fa-solid fa-upload text-primary"></i></button>
					</div>
				</div>
			</div>
		</div>
	</p-panel>
	<h1 class="m-0 ml-2 my-4">{{'Resident Management' | translate}}</h1>
	<p-panel [toggleable]="true" toggler="header">
		<p-header class="flex-grow-1 d-flex aign-items-center justify-content-between">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Resident Check-In Config' | translate}}</span>
		</p-header>
		<div class="ui-g">
			<div class="ui-g-6 ui-sm-12 ">
				<label>{{'Print Templates' | translate}}</label>
				<div class="d-flex align-items-center justify-content-start" style="column-gap: 40px; row-gap: 10px">
					<div class="d-flex aign-items-center" style="flex-direction: column; width: 180px;">
						<img src="assets/images/resident_template1.png" style="display: inline-block; width: 100%;" alt="" (click)="residentQrCodeUrl = 'assets/images/resident_template1.png'; showViewResidentQrCodeDialog = true"/>
						<div class="d-flex align-items-center justify-content-center pt-3">
							<p-radioButton [disabled]="disabledData" [value]="1" name="residentQrCodeTemplate" [(ngModel)]="companySetting.residentQrCodeTemplate" formControlName="residentQrCodeTemplate"></p-radioButton>
						</div>
					</div>
					<div class="d-flex aign-items-center" style="flex-direction: column; width: 180px">
						<img src="assets/images/resident_template2.png" style="display: inline-block; width: 100%;" alt="" (click)="residentQrCodeUrl = 'assets/images/resident_template2.png'; showViewResidentQrCodeDialog = true"/>
						<div class="d-flex align-items-center justify-content-center pt-3">
							<p-radioButton [disabled]="disabledData" [value]="2" name="residentQrCodeTemplate" [(ngModel)]="companySetting.residentQrCodeTemplate" formControlName="residentQrCodeTemplate"></p-radioButton>
						</div>
					</div>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12 p-0">
				<div class="ui-g-12 ui-sm-12">
					<label>{{'Unit Price' | translate}}</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-dollar-sign"></i>
							</button>
						</div>
						<input type="text" opr-only-numbers pInputText formControlName="unitPrice"  class="fullWidthInput" placeholder="$0.00" (blur)="formatData('unitPrice')" [(ngModel)]="unitPrice" [attr.disabled]="isPlatform && !disabledData ? null : ''" (focus)="unitPrice === '$0.00' ? unitPrice = '' : ''" [maxLength]="6">
					</div>
				</div>
				<div class="ui-g-12 ui-sm-12">
					<label>{{'Printer Settings' | translate}}</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-print"></i>
							</button>
						</div>
						<p-dropdown [options]="printerDevices" [disabled]="disabledData" formControlName="printerDevice" [(ngModel)]="companySetting.printerDevice"  placeholder="{{'Device' | translate}}">
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>
						</p-dropdown>
						<button type="button" class="btn btn-white" (click)="testPrinter()" [disabled]="!companySetting.printerDevice || disabledData">
							<i class="fa-solid fa-print text-primary mr-2"></i>{{'Test Print' | translate}}
						</button>
					</div>
				</div>
			</div>
		</div>
	</p-panel>
	<h1 class="m-0 ml-2 my-4">{{'OperrTel Service' | translate}}</h1>
	<p-panel [toggleable]="true" toggler="header">
		<p-header class="flex-grow-1 d-flex aign-items-center justify-content-between">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Plan & Price' | translate}}</span>
			<span class="text-dark mr-3" *ngIf="formMode === 'update'" (click)="showHistory('plan');$event.stopPropagation();"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i>{{'history.title' | translate}}</span>
		</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-12 ui-sm-12">
				<label>{{'Current Plan' | translate}}</label>
				<div style="font-size: large" class="py-1">{{companySetting.credits}} Credits, {{companySetting.assistanceHours}}/month</div>		
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Credits' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-wallet"></i>
						</button>
					</div>
					<input name="credits" formControlName="credits"  [attr.disabled]="isPlatform && !disabledData ? null : ''" [(ngModel)]="companySetting.credits" min="0" type="number"  pInputText/>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Auto Call Minutes' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-stopwatch"></i>
						</button>
					</div>
					<input name="autoCallMinutes" formControlName="autoCallMinutes"  [attr.disabled]="isPlatform && !disabledData ? null : ''" [(ngModel)]="companySetting.autoCallMinutes" min="0" type="number"  pInputText/>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Assistance Hours' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<input type="text" pInputText formControlName="assistanceHours"  class="fullWidthInput" placeholder="$0.00" (keypress)="isNumber($event)" [attr.disabled]="isPlatform && !disabledData ? null : ''" (blur)="formatData('smsPrice')" [(ngModel)]="companySetting.assistanceHours" (focus)="companySetting.assistanceHours == '$0.00' ? companySetting.assistanceHours ='' : ''">
				</div>
			</div>
		</div>
	</p-panel>
	<br>
	<p-panel [toggleable]="true" toggler="header">
		<p-header class="flex-grow-1 d-flex aign-items-center justify-content-between">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Call Forwarding' | translate}}</span>
		</p-header>
		<div class="ui-g pb-3">
			<ng-container *ngFor="let followMe of followMeList; index as i">
				<div class="ui-g-6 ui-sm-12">
					<label>Forwarding Phone #{{i+1}}</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-mobile-phone"></i>
							</button>
						</div>
						<p-inputMask [disabled]="disabledData" class="w-100" id="followMe{{i+1}}" mask="(999) 999-9999" formControlName="followMe{{i+1}}" [ngModelOptions]="{standalone: true}" [(ngModel)]="followMe.phone" placeholder="(###) ###-####"></p-inputMask>
					</div>
				</div>
			</ng-container>
		</div>
		<p-footer class="d-flex justify-content-center">
			<button type="button" class="btn btn-white mx-1" (click)="addOperrTelCallForwarding()">
				<i class="fa-solid fa-plus text-primary mr-2"></i>{{'Add Another' | translate}}
			</button>
		</p-footer>
	</p-panel>
	<br>
	<p-panel [toggleable]="true" toggler="header">
		<p-header class="flex-grow-1 d-flex aign-items-center justify-content-between">
				<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'SMS' | translate}}</span>
				<span class="text-dark mr-3" *ngIf="formMode === 'update'" (click)="showHistory('sms');$event.stopPropagation();"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i>{{'history.title' | translate}}</span>
		</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-3 ui-sm-12">
				<label>{{'Available Credits' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-wallet"></i>
						</button>
					</div>
					<input name="smsQuota" formControlName="smsQuota"  [attr.disabled]="isPlatform && !disabledData ? null : '' " [(ngModel)]="companySetting.smsQuota" min="0" type="number"  pInputText (keydown)="onKeyUp($event)" placeholder="{{'Outgoing' | translate}}"/>
				</div>
			</div>
			<div class="ui-g-3 ui-sm-12">
				<label>{{'Reply SMS' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-folder-tree"></i>
						</button>
					</div>
					<p-dropdown [autoDisplayFirst]="false" [options]="replySmsOptions" formControlName="replySms" [(ngModel)]="companySetting.replySms" [disabled]="disabledData" placeholder="{{'pleaseSelect' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Cycle Period' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-week"></i>
						</button>
					</div>
					<p-dropdown [options]="quotaFrequencyOptions" formControlName="smsQuotaFrequency" [(ngModel)]="smsQuotaFrequencyMethod" [disabled]="disabledData" placeholder="{{'pleaseSelect' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Price' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-dollar-sign"></i>
						</button>
					</div>
					<input type="text" pInputText formControlName="smsPrice"  class="fullWidthInput" placeholder="$0.00" (keypress)="isNumber($event)" [attr.disabled]="isPlatform && !disabledData ? null : ''" (blur)="formatData('smsPrice')" [(ngModel)]="smsPrice" (focus)="smsPrice == '$0.00' ? smsPrice ='' : ''">
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'When Quota Reached' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-triangle-exclamation"></i>
						</button>
					</div>
					<p-dropdown [options]="afterQuotaOptions" formControlName="smsAfterQuota" [(ngModel)]="companySetting.smsAfterQuota" [disabled]="!isPlatform || disabledData" placeholder="{{'pleaseSelect' | translate}}" (onChange)="afterQuotaChange('SMS')">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<div class="ui-g-6 ui-sm-12">
					<label>{{'Outgoing Messages' | translate}}</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-hashtag"></i>
							</button>
						</div>
						<input name="smsCount" formControlName="smsCount" [attr.disabled]="isDisabledSMS_MMS || disabledData" [(ngModel)]="companySetting.smsCount" min="0" type="number" pInputText (keydown)="onKeyUp($event)" placeholder="{{'0' | translate}}" />
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>{{'Bulk Message Limit' | translate}}</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-hand"></i>
							</button>
						</div>
						<p-dropdown [options]="bulkMessageLimitOptions" formControlName="smsQuotaFrequency" [(ngModel)]="companySetting.bulkMessageLimit" [disabled]="!isPlatform || disabledData" placeholder="{{'pleaseSelect' | translate}}">
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>
						</p-dropdown>
					</div>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<div class="ui-g-6 ui-sm-12">
					<label>{{'SMS Credits used' | translate}}</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-hashtag"></i>
							</button>
						</div>
						<input name="smsCreditsUsed" formControlName="smsCreditsUsed" [attr.disabled]="true" [(ngModel)]="companySetting.smsCreditsUsed" min="0" type="number" pInputText placeholder="{{'0' | translate}}" />
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>{{'MMS Credits used' | translate}}</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-hashtag"></i>
							</button>
						</div>
						<input name="mmsCreditsUsed" formControlName="mmsCreditsUsed" [attr.disabled]="true" [(ngModel)]="companySetting.mmsCreditsUsed" min="0" type="number" pInputText placeholder="{{'Incoming' | translate}}" />
					</div>
				</div>
			</div>

<!--			<div class="ui-g-6 ui-sm-12">-->
<!--				<label>{{'Usage Cost' | translate}}</label>-->
<!--				<div class="input-group w-100">-->
<!--					<div class="input-group-prepend p-0">-->
<!--						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">-->
<!--							<i class="fa-solid fa-dollar-sign"></i>-->
<!--						</button>-->
<!--					</div>-->
<!--					<input type="text" pInputText formControlName="smsUsageCost"  class="fullWidthInput" placeholder="$0.00" (keypress)="isNumber($event)" disabled (blur)="formatData('smsUsageCost')" [(ngModel)]="smsUsageCost" (focus)="smsPrice == '$0.00' ? smsPrice ='' : ''" >-->
<!--				</div>-->
<!--			</div>-->
	
<!--			<div *ngIf="companySetting.smsAfterQuota === 'ADD_AMOUNT' || companySetting.smsAfterQuota === 'ADD_PERCENT'"  class="ui-g-6 ui-sm-12">-->
<!--				<label>{{'Overage Pricing' | translate}}</label>-->
<!--				<div class="input-group w-100">-->
<!--					<div class="input-group-prepend p-0">-->
<!--						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">-->
<!--							<i *ngIf="companySetting.smsAfterQuota === 'ADD_AMOUNT'"  class="fa-solid fa-dollar-sign"></i>-->
<!--							<i *ngIf="companySetting.smsAfterQuota === 'ADD_PERCENT'"  class="fa-solid fa-percent"></i>-->
<!--							<i *ngIf="companySetting.smsAfterQuota !== 'ADD_AMOUNT' && companySetting.smsAfterQuota !== 'ADD_PERCENT'" class="fa-solid fa-stop text-muted"></i>-->
<!--						</button>-->
<!--					</div>-->
<!--					<input *ngIf="companySetting.smsAfterQuota === 'ADD_AMOUNT'" name="smsAvailableAfterQuota" formControlName="smsAvailableAfterQuota" -->
<!--						[(ngModel)]="companySetting.smsAvailableAfterQuota" min="0" type="number" -->
<!--						pInputText opr-only-numbers  placeholder="{{'Enter Amount' | translate}}"  -->
<!--						 [attr.disabled]="!companySetting.smsAfterQuota || companySetting.smsAfterQuota === 'TEMPORARILY_RESTRICTED' ? '' : null"-->
<!--					/>-->
<!--					<input *ngIf="companySetting.smsAfterQuota === 'ADD_PERCENT'" name="smsAvailableAfterQuota" formControlName="smsAvailableAfterQuota" -->
<!--						[(ngModel)]="companySetting.smsAvailableAfterQuota" min="0" type="number" -->
<!--						pInputText twoDigitDecimaNumber  placeholder="{{'Percentage' | translate}}" -->
<!--						 [attr.disabled]="!companySetting.smsAfterQuota || companySetting.smsAfterQuota === 'TEMPORARILY_RESTRICTED' ? '' : null"-->
<!--					/>-->
<!--				</div>-->
<!--			</div>-->
		</div>
		<p-footer>
			<button class="btn btn-white text-nowrap" (click)="openCreditSetting()" [disabled]="!isSuperAdmin()"><i class="fa-solid fa-gear text-primary mr-2"></i>{{'Settings' | translate}}</button>
		</p-footer>
	</p-panel>
	<br>
	<p-panel [toggleable]="true" toggler="header">
		<p-header class="flex-grow-1 d-flex aign-items-center justify-content-between">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'MMS' | translate}}</span>
			<span class="text-dark mr-3" *ngIf="formMode === 'update'" (click)="showHistory('mms');$event.stopPropagation();"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i>{{'history.title' | translate}}</span>
		</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Quota' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-hashtag"></i>
						</button>
					</div>
					<input name="mmsQuota" formControlName="mmsQuota"  [attr.disabled]="isPlatform && !disabledData ? null : ''" [(ngModel)]="companySetting.mmsQuota" min="0" type="number"  pInputText (keydown)="onKeyUp($event)" placeholder="{{'Outgoing' | translate}}"/>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Cycle Period' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-week"></i>
						</button>
					</div>
					<p-dropdown [options]="quotaFrequencyOptions" formControlName="mmsQuotaFrequency" [(ngModel)]="mmsQuotaFrequencyMethod" [disabled]="!isPlatform || disabledData" placeholder="{{'pleaseSelect' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Price' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-dollar-sign"></i>
						</button>
					</div>
					<input type="text" pInputText formControlName="mmsPrice"  class="fullWidthInput" placeholder="$0.00" (keypress)="isNumber($event)" [attr.disabled]="isPlatform && !disabledData ? null : ''" (blur)="formatData('mmsPrice')" [(ngModel)]="mmsPrice" (focus)="mmsPrice == '$0.00' ? mmsPrice ='' : ''">
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'When Quota Reached' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-triangle-exclamation"></i>
						</button>
					</div>
					<p-dropdown [options]="afterQuotaOptions" formControlName="mmsAfterQuota" [(ngModel)]="companySetting.mmsAfterQuota" [disabled]="!isPlatform || disabledData" placeholder="{{'pleaseSelect' | translate}}" (onChange)="afterQuotaChange('MMS')">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>		
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Outgoing Messages' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-hashtag"></i>
						</button>
					</div>
					<input name="mmsCount" formControlName="mmsCount" [attr.disabled]="isDisabledSMS_MMS || disabledData" [(ngModel)]="companySetting.mmsCount" min="0" type="number" pInputText (keydown)="onKeyUp($event)" placeholder="{{'Incoming' | translate}}" />
				</div>
			</div>
<!--			<div class="ui-g-6 ui-sm-12">-->
<!--				<label>{{'Usage Cost' | translate}}</label>-->
<!--				<div class="input-group w-100">-->
<!--					<div class="input-group-prepend p-0">-->
<!--						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">-->
<!--							<i class="fa-solid fa-dollar-sign"></i>-->
<!--						</button>-->
<!--					</div>-->
<!--					<input type="text" pInputText formControlName="mmsUsageCost"  class="fullWidthInput" placeholder="$0.00" (keypress)="isNumber($event)" disabled (blur)="formatData('mmsUsageCost')" [(ngModel)]="mmsUsageCost" (focus)="mmsPrice == '$0.00' ? mmsPrice ='' : ''" >-->
<!--				</div>-->
<!--			</div>-->
<!--			<div *ngIf="companySetting.mmsAfterQuota === 'ADD_AMOUNT' || companySetting.mmsAfterQuota === 'ADD_PERCENT'"  class="ui-g-6 ui-sm-12">-->
<!--				<label>{{'Overage Pricing' | translate}}</label>-->
<!--				<div class="input-group w-100">-->
<!--					<div class="input-group-prepend p-0">-->
<!--						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">-->
<!--							<i *ngIf="companySetting.mmsAfterQuota === 'ADD_AMOUNT'"  class="fa-solid fa-dollar-sign"></i>-->
<!--							<i *ngIf="companySetting.mmsAfterQuota === 'ADD_PERCENT'"  class="fa-solid fa-percent"></i>-->
<!--							<i *ngIf="companySetting.mmsAfterQuota !== 'ADD_AMOUNT' && companySetting.mmsAfterQuota !== 'ADD_PERCENT'" class="fa-solid fa-stop text-muted"></i>-->
<!--						</button>-->
<!--					</div>-->
<!--					<input *ngIf="companySetting.mmsAfterQuota === 'ADD_AMOUNT'" name="mmsAvailableAfterQuota" formControlName="mmsAvailableAfterQuota" -->
<!--						[(ngModel)]="companySetting.mmsAvailableAfterQuota" min="0" type="number" -->
<!--						pInputText opr-only-numbers placeholder="{{'Enter Amount' | translate}}" -->
<!--						 [attr.disabled]="!companySetting.mmsAfterQuota || companySetting.mmsAfterQuota === 'TEMPORARILY_RESTRICTED' ? '' : null"-->
<!--					/>-->
<!--					<input *ngIf="companySetting.mmsAfterQuota === 'ADD_PERCENT'" name="mmsAvailableAfterQuota" formControlName="mmsAvailableAfterQuota" -->
<!--						[(ngModel)]="companySetting.mmsAvailableAfterQuota" min="0" type="number" -->
<!--						pInputText twoDigitDecimaNumber placeholder="{{'Percentage' | translate}}" -->
<!--						 [attr.disabled]="!companySetting.mmsAfterQuota || companySetting.mmsAfterQuota === 'TEMPORARILY_RESTRICTED' ? '' : null"-->
<!--					/>-->
<!--				</div>-->
<!--			</div>-->
		</div>
	</p-panel>
  	<div class="ui-g">
		<div class="ui-g-12 text-center pt-4">
			<button (click)="onCancel()" class="btn btn-danger mx-1"><i class="fa-solid fa-xmark mr-2"></i>{{'button.cancel' | translate}}</button>
			<button *ngIf="!disabledData" (click)="onSaveNew(agencySettingDataForm)" class="btn btn-primary mx-1" [disabled]="isSaving"><i class="fa-solid fa-check mr-2"></i>{{'button.submit' | translate}}</button>
			<button *ngIf="disabledData" (click)="disabledData = false; isDisabled(!disabledData)" class="btn btn-info mx-1" [disabled]="isSaving"><i class="fa-solid fa-pen-to-square mr-2"></i>{{'button.edit' | translate}}</button>
		</div>
	</div>
</form>

<p-dialog *ngIf="historyShow" [(visible)]="historyShow" [modal]="true" [style]="{ 'width':'90%', 'max-width':'900px', 'max-height':'85vh'}" appendTo="body">
	<p-header>
		<span><i class="fa-solid fa-clock-rotate-left mr-2"></i>{{'Edit History' | translate}}: {{historyHeader | translate}}</span>
	</p-header>
	<app-company-setting-history (onHideHistory)="hideHistory()" [companySettingId]="companySetting.id" [historyType]="historyType"></app-company-setting-history>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="historyShow = false">
			<span class="text-nowrap"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
		</button>
	</p-footer>
</p-dialog>

<p-overlayPanel #op99 appendTo="body" styleClass="custom">
	<div style=" max-width: 300px;">
		<div style="color: #fff;" class="d-flex align-items-center px-2 py-1 task-management-top-bar">
			<i style="color: #fff;" class="mr-2 fa-solid fa-info-circle"></i>
			{{'information' | translate}}
		</div>
		<div class="px-2 py-3">
			{{'informationTextNotifyToPeople' | translate}}
		</div>
	</div>
</p-overlayPanel>

<p-confirmDialog [style]="{width: '50vw' }"></p-confirmDialog>

<p-dialog modal="modal" [(visible)]="creditSettingDialog" [dismissableMask]="true" [modal]="true" [style]="{'width':'80%', 'max-width':'400px'}">
	<p-header>
		<span><i class="fa-solid fa-gear mr-2"></i>{{'SMS / MMS Credit Setup' | translate}}</span>
	</p-header>
	<div class="ui-g p-3">
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Dollar Value' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-dollar"></i>
					</button>
				</div>
				<input [disabled]="disabledData" twoDigitDecimaNumber type="text" pInputText [(ngModel)]="creditDollarValue" placeholder="0">
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Credit Value' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-hashtag"></i>
					</button>
				</div>
				<input [disabled]="disabledData" opr-only-numbers type="text" pInputText [(ngModel)]="creditValue" placeholder="0">
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'SMS/Credit' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-hashtag"></i>
					</button>
				</div>
				<input [disabled]="disabledData" opr-only-numbers type="text" pInputText [(ngModel)]="creditSmsValue" placeholder="0">
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'MMS/Credit' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-hashtag"></i>
					</button>
				</div>
				<input [disabled]="disabledData" opr-only-numbers type="text" pInputText [(ngModel)]="creditMmsValue" placeholder="0">
			</div>
		</div>
	</div>
	<p-footer class="d-flex justify-content-between">
		<button (click)="creditSettingDialog = false" class="btn btn-danger mx-1"><i class="fa-solid fa-xmark mr-2"></i>{{'button.cancel' | translate}}</button>
		<div>
			<button (click)="showHistory('creditSetup')" class="btn btn-while text-muted mx-1"><i class="fa-solid fa-clock-rotate-left mr-2"></i>{{'History' | translate}}</button>
			<button (click)="submitCreditSetting()" class="btn btn-primary mx-1"><i class="fa-solid fa-check mr-2"></i>{{'button.submit' | translate}}</button>
		</div>

	</p-footer>
</p-dialog>

<p-dialog [modal]="true" [(visible)]="showViewResidentQrCodeDialog" [style]="{'width': '90%', 'max-width':'600px'}">
	<p-header>
		<span><i class="fa-solid fa-signature mr-2"></i>{{'View Signature' | translate}}</span>
	</p-header>
  	<img class="w-100" [src]="residentQrCodeUrl" alt="">
	<p-footer>
		<button class="btn btn-primary" (click)="showViewResidentQrCodeDialog = false"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
	</p-footer>
</p-dialog>
<p-toast position="bottom-right">{{msgs}}</p-toast>
