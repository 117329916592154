import { AuthService } from './../../../shared/service/auth/auth.service';
import { TaskManagementService } from './../../service/task-management.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng';
import { BreadcrumbService } from 'app/layout/service/breadcrumb.service';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import { CommonCode } from '../../model/type-code.enum';
import { TranslatePipe } from "@ngx-translate/core";
import { AgencyAdminService } from '../../../admin/agency-admin/service/agency-admin.service';
import { EmployeeService } from '../../../employee/service/employee.service';
import { MENU_ID } from 'app/shared/data/menu-id';
import {ShareDataService} from '../../../shared/service/share-data.service';

@Component({
  selector: 'app-board-project',
  templateUrl: './board-project.component.html',
  styleUrls: ['./board-project.component.scss']
})
export class BoardProjectComponent implements OnInit {
  projects: any[] = [];
  guestProjects: any[] = [];
  allProject: any[] = [];
  loading = false;
  showDialogAddProject = false;
  showConfirmDeleteDialog = false;
  showDialogUpdateProject = false;
  showDialogUpdateBackground = false;
  errorMessage = '';
  newProjectName = '';
  selectedProject: any = {};
  companyId: any = 0;
  isSuperAdmin: boolean = this.authService.isSuper();
  timeSpent = new Date();
  displaySettings = false;
  displayMembers = false;
  displayAllCards = false;
  workspace: any = [];
  colors = ['#DAF7A6',
    '#FFC300',
    '#FF5733',
    '#C70039',
    '#900C3F',
    '#581845'];
  selectedMember: any = [];
  searchText: string;
  searchList: any = [];
  showConfirmDelete = false;
  ids = [];
  guestWorkspace: any = [];
  displayGuestList = false;
  memberList: any;
  selectedBoard: any;
  isCompanyAdmin = this.authService.isCompanyAd();
  @ViewChild('op5', { static: true }) op5: any;
  isEmployee = false;
  guestProjectsList: any[];
  projectsAll: any[];
  allProjectList: any;
  isContractor = false;
  isGuest = false;
  allMemberList: any;
  allAdmins: any;
  allEmployees: any;
  memberType: string;
  totalMembers: number = 0;
  showMemberList: boolean = false;
  @ViewChild('op3', { static: true }) op3: any;
  memberCols = [
    { label: '#', field: '#' },
    { label: 'Username', field: 'username', sortOptions: '', sort: 'text' },
    { label: 'Full Name', field: 'fullName', sortOptions: '', sort: 'text' },
    { label: 'Status', field: 'status', sortOptions: '', sort: 'number' },
  ]
  dashboardKey: string = '';

  constructor(private messageService: MessageService,
    private router: Router,
    private taskManagementService: TaskManagementService,
    private breadcrumbService: BreadcrumbService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private translatePipe: TranslatePipe,
    private monitoringDetailsService: MonitoringDetailsService,
    private agencyAdminService: AgencyAdminService,
    private employeeService: EmployeeService,
    private shareDataService: ShareDataService) { }

  ngOnInit(): void {
    this.companyId = this.authService.getCurrentCompanyId() ? this.authService.getCurrentCompanyId() : 0;
    this.breadcrumbService.setItems([{ label: 'Task Management', id: 'task_management', routerLink: '/app/tm' }]);
    this.activatedRoute.queryParams.subscribe(res => {
      if (res['invite-code']) {
        this.acceptInvitation(res['invite-code']);
      } else {
        this.getAllProjectList();
        // this.getAllProject();
      }
    });
    this.isEmployee = this.authService.isEmployeeRole();
    this.isContractor = this.authService.isContractorRole();
    this.isGuest = this.authService.isGuestRole();

    this.getAllAdminsInCompany();
    this.getAllEmployeesInCompany();

    this.shareDataService.shareData$.subscribe(key => {
      this.dashboardKey = key;
    });
  }

  setCardsBreadcrumb() {
    this.breadcrumbService.setItems([
      { label: 'Task Management', id: 'task_management', routerLink: '/app/tm' },
      { label: 'Cards', id: 'task_management_cards' }
    ]);
  }
  setTrackingBreadcrumb() {
    this.breadcrumbService.setItems([
      { label: 'Task Management', id: 'task_management', routerLink: '/app/tm' },
      { label: 'Activity', id: 'task_management_cards' }
    ]);
  }
  setMembersBreadcrumb() {
    this.breadcrumbService.setItems([
      { label: 'Task Management', id: 'task_management', routerLink: '/app/tm' },
      { label: 'Members', id: 'task_management_cards' }
    ]);
  }
  setBoardsBreadcrumb() {
    this.breadcrumbService.setItems([
      { label: 'Task Management', id: 'task_management', routerLink: '/app/tm' },
      { label: 'Boards', id: 'task_management_cards' }
    ]);
  }
  showAddProjectModal() {
    this.showDialogAddProject = true;
    this.timeSpent = new Date();
  }

  addProject() {
    if (!this.newProjectName.trim()) {
      return;
    }
    this.showDialogAddProject = false;
    this.loading = true;
    this.taskManagementService.addProject(this.newProjectName.trim(), this.companyId).subscribe((res: any) => {
      this.loading = false;
      this.projectsAll.push(res.data);
      this.allProjectList.push(res.data);
      this.messageService.add({
        severity: 'success',
        summary: this.translatePipe.transform('Added'),
        detail: this.translatePipe.transform('Board added successfully')
      });
      this.newProjectName = '';
      this.monitoringDetailsService.monitorAction(
        'New Board Added',
        this.timeSpent,
        {
          created_new_board_by: this.authService.getCurrentLoggedInName(),
          board_name: res.data.name,
          type: CommonCode.ADD_BOARD.toString()
        },
        'complete',
        'Task management',
        res.data.id
      );
    }, error => {
      this.messageService.add({
        severity: 'error',
        summary: this.translatePipe.transform('Error'),
        detail: error.error.message
      });
      this.loading = false;
    });
  }

  delete(event, project) {
    event.stopPropagation();
    let isDeletable = false;
    if (project.buckets) {
      project.buckets.forEach(bucket => {
        if (bucket.tasks.length) { isDeletable = true; }
      });
    }
    if (isDeletable) {
      this.messageService.add({
        severity: 'info',
        summary: this.translatePipe.transform('Information'),
        detail: this.translatePipe.transform('Board cannot be delete unless all card has been removed')
      });
      return;
    }
    this.showConfirmDeleteDialog = true;
    this.selectedProject = project;
    this.timeSpent = new Date();
  }
  confirmDelete() {
    this.loading = true;
    this.showConfirmDeleteDialog = false;
    this.taskManagementService.deleteProject(this.selectedProject.id).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.loading = false;
        const idx = this.projects.findIndex(x => x.id === this.selectedProject.id);
        this.projectsAll.splice(idx, 1);
        this.allProjectList.splice(idx, 1);
        this.showConfirmDeleteDialog = false;
        this.messageService.add({
          severity: 'success',
          summary: this.translatePipe.transform('Deleted'),
          detail: this.translatePipe.transform('Board deleted successfully')
        });
        this.monitoringDetailsService.monitorAction(
          'Deleted Board',
          this.timeSpent,
          {
            deleted_board_by: this.authService.getCurrentLoggedInName(),
            board_name: this.selectedProject.name
          },
          'complete',
          'Task management',
          this.selectedProject.id
        );
        this.router.navigate['app/tm'];
      } else {
        this.loading = false;
        this.messageService.add({
          severity: 'info',
          summary: this.translatePipe.transform('Information'),
          detail: this.translatePipe.transform('Board cannot be delete unless all card has been removed')
        });
      }

    }, err => {
      this.loading = false;
      // this.messageService.add({ severity: 'failure', summary: 'Error', detail: 'Cannot delete board' });
    });

  }
  edit(event, project) {
    event.stopPropagation();
    this.selectedProject = { ...project };
    this.showDialogUpdateProject = true;
    this.timeSpent = new Date();
  }
  confirmUpdate() {
    this.showDialogUpdateProject = false;
    this.loading = true;
    this.taskManagementService.updateProject(
      {
        name: this.selectedProject.name,
        background: this.selectedProject.background,
        backgroundType: 'IMAGE',
        projectId: this.selectedProject.id
      }
    ).subscribe((res: any) => {
      this.messageService.add({
        severity: 'info',
        summary: this.translatePipe.transform('Updated'),
        detail: this.translatePipe.transform('Board updated successfully')
      });
      this.showDialogUpdateProject = false;
      const idx = this.projects.findIndex(x => x.id === this.selectedProject.id);
      this.projectsAll[idx] = res.data;
      this.allProjectList[idx] = res.data;
      this.loading = false;
      this.monitoringDetailsService.monitorAction(
        'Updated Board',
        this.timeSpent,
        {
          updated_board_by: this.authService.getCurrentLoggedInName(),
          board_name: this.selectedProject.name,
          type: CommonCode.UPDATE_BOARD.toString(),
        },
        'complete',
        'Task management',
        this.selectedProject.id
      );
    }, err => { this.loading = false; this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: err.error.message }); });

  }
  gotoProjectDetail(p) {
    this.router.navigate(['/app/tm', this.taskManagementService.encodeId(p.id)]);
  }
  getAllProject() {
    // this.loading = true;
    this.taskManagementService.getAllProject().subscribe((res: any) => {
      // this.loading = false;
      // this.projects = res.data.content;
      this.allProject = res.data.content;
      this.allProject = this.allProject.filter(p =>
        p.userId === this.authService.getCurrentLoggedInId() || (p.acceptedUsers && p.acceptedUsers.length > 0 && p.acceptedUsers.find(ac => ac.userName === this.authService.getCurrentUsername())));
      this.projects = this.allProject.filter(p =>
        p.userId === this.authService.getCurrentLoggedInId() && p.userType && p.userType.toLowerCase() === this.taskManagementService.getUserType().toLowerCase());
      this.guestProjects = this.allProject.filter(p => p.userId !== this.authService.getCurrentLoggedInId());
      // if (this.authService.isCompanyAd() || this.authService.isSubCompanyAd()) {
      //   this.guestProjects = this.allProject.filter(p => p.userId !== this.authService.getCurrentLoggedInId());
      // } else {
      //   this.guestProjects = this.allProject.filter(p =>
      //     p.userId !== this.authService.getCurrentLoggedInId() && p.userType.toLowerCase() !== this.taskManagementService.getUserType().toLowerCase());
      // }
    }, err => this.loading = false);
    // this.taskManagementService.getAllProjectByGuest().subscribe((res: any) => {
    //   this.loading = false;
    //   this.guestProjects = res.data.content;
    //   this.allProject.push(...res.data.content);
    // }, err => this.loading = false);
  }
  acceptInvitation(token) {
    this.taskManagementService.acceptInvitation(token).subscribe((res: any) => {
      // this.getAllProject();
      this.taskManagementService.saveFirebaseNotification(`/task-management/${res.data.projectId}/assigned-memeber-update`, {
        projectId: res.data.projectId,
        updatedByUser: this.authService.getUserInfo().username,
        lastUpdateTime: new Date().getTime(),
        trackingSession: new Date().getTime()
      }).subscribe();
      this.router.navigate(['/app/tm', this.taskManagementService.encodeId(res.data.projectId)]);
    }, err => {
      this.getAllProjectList();
      // this.getAllProject();
    });
  }
  openBackgroundPicker(event, p) {
    event.stopPropagation();
    this.selectedProject = p;
    this.selectedProject.background = p.background || '';
  }
  onPickBackgroundImage(event) {
    this.selectedProject.background = event.background;
    this.selectedProject.backgroundType = event.type;
    this.taskManagementService.updateProjectBackground({
      background: event.background,
      backgroundType: 'IMAGE',
      name: this.selectedProject.name
    }, this.selectedProject.id).subscribe(res => {
      this.monitoringDetailsService.monitorAction(
        'Updated Background Board',
        new Date(),
        {
          updated_board_by: this.authService.getCurrentLoggedInName(),
          bg: 'Change background',
          type: CommonCode.UPDATE_BG_BOARD.toString()
        },
        'complete',
        'Task management',
        Number(this.selectedProject.id)
      );
    });
  }

  showBoards() {
    this.displaySettings = false;
    this.displayMembers = false;
    this.displayAllCards = false;
  }

  memberWorkspace(type?: string) {
    this.memberList = [];
    this.workspace = [];
    this.displayMembers = true;
    this.displaySettings = false;
    this.displayAllCards = false;
    const requestType = 0;
    this.taskManagementService.getUserDetails(requestType).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.workspace = res.data;
        this.workspace = this.workspace.filter(e => e.userDetail.id !== this.authService.getCurrentLoggedInId() &&
          e.userDetail.userType !== this.taskManagementService.getUserType());
        this.workspace.forEach(user => {
          user.userDetail.displayName = user.userDetail.name.match(/\b(\w)/g).join('').slice(0, 2);
          user.userDetail.color = this.colors[user.userDetail.id % this.colors.length];
        });
        this.memberList = this.workspace;
        this.searchList = this.workspace;
      }
    });

    this.displayGuest('member');
    this.guestCount();
  }

  showAllCard() {
    this.displayAllCards = true;
    this.displayMembers = false;
    this.displaySettings = false;
  }

  guestCount(val?) {
    this.memberList = [];
    this.guestWorkspace = [];
    const requestType = 1;
    this.taskManagementService.getUserDetails(requestType).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.guestWorkspace = res.data;
        this.guestWorkspace.forEach(user => {
          user.userDetail.displayName = user.userDetail.name.match(/\b(\w)/g).join('').slice(0, 2);
          user.userDetail.color = this.colors[user.userDetail.id % this.colors.length];
        });
        if (val) {
          this.displayGuest();
        }
      }
    });
  }

  selectMember(board) {
    this.selectedMember = board.boardDetails;
  }

  search() {
    this.memberList = this.searchList.filter((val) => {
      return val.userDetail.name.toLowerCase().search(this.searchText.toLowerCase()) > -1;
    });
  }

  inviteMember() {
    this.messageService.add({
      severity: 'info',
      summary: this.translatePipe.transform('Success'),
      detail: this.translatePipe.transform(`Under Development`)
    });
  }

  remove(data?) {
    this.ids = [];
    if (this.displayGuestList) {
      if (data) {
        this.ids.push(data.boardUserAssignedId);
      } else {
        this.selectedMember.forEach(e => {
          this.ids.push(e.boardUserAssignedId);
        });
      }
    } else {
      if (data) {
        this.ids.push(data.myBoardUserAssignedId);
      } else {
        this.selectedMember.forEach(e => {
          this.ids.push(e.myBoardUserAssignedId);
        });
      }
    }
    this.showConfirmDelete = true;
  }

  deletedFromBoard() {
    this.taskManagementService.removeUser(this.ids).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.messageService.add({
          severity: 'success',
          summary: this.translatePipe.transform('Deleted'),
          detail: this.translatePipe.transform('User deleted successfully')
        });
        if (this.displayGuestList) {
          this.guestCount();
        } else {
          this.memberWorkspace();
        }
        this.showConfirmDelete = false;
      }
    });
  }

  displayGuest(type?: string) {
    if (type) {
      this.displayGuestList = false;
      this.memberList = this.workspace;
      this.searchList = this.workspace;
      return;
    }
    this.displayGuestList = true;
    this.memberList = this.guestWorkspace;
    this.searchList = this.guestWorkspace;
  }

  changeMemberRole(role: number) {
    this.op5.hide();
    if (!this.isCompanyAdmin) {
      return this.messageService.add({
        severity: 'info',
        summary: this.translatePipe.transform('Information'),
        detail: this.translatePipe.transform(`You do not have permission! Only Company Admin can change role`)
      });
    }
    const payLoad = {
      projectUserAssignmentId: this.selectedBoard.boardUserAssignedId ? this.selectedBoard.boardUserAssignedId : this.selectedBoard.myBoardUserAssignedId,
      role: role
    };
    this.taskManagementService.changeUserRole(payLoad).subscribe((res: any) => {
      if (this.displayGuestList) {
        this.guestCount(true);
      } else {
        this.memberWorkspace();
      }
      if (res.status === 'SUCCESS') {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `Permission changed for the member`
        });
      }
    });
  }

  getAllProjectList() {
    this.loading = true;
    this.taskManagementService.getNewProjectList().subscribe((res: any) => {
      // this.projects = res.data.content;
      this.allProjectList = res.data;
      this.projectsAll = this.allProjectList.filter(p =>
        p.userId === this.authService.getCurrentLoggedInId() && p.userType && p.userType.toLowerCase() === this.taskManagementService.getUserType().toLowerCase());
      this.guestProjectsList = this.allProjectList.filter(p => p.userId !== this.authService.getCurrentLoggedInId());
      this.loading = false;
      this.getAllProject();
    }, err => this.loading = false);
  }

  isDisplay() {
    if (!this.isEmployee && !this.isContractor && !this.isGuest) { return true; }
    return false;
  }

  isDisplayCars() {
    const isCheckPermiss = this.authService.hasViewPermission(MENU_ID.ADMIN_BOARD_ACCESS);
    if (isCheckPermiss) {
      return true;
    } else {
      return false;
    }
  }

  removeSearchText() {
    this.searchText = '';
    this.search();
  }

  getAllAdminsInCompany() {
    this.agencyAdminService.getAllAdmin(0, 9999, this.authService.getCurrentCompanyId()).subscribe(res => {
      if (res.status === 'SUCCESS') {
        this.allAdmins = res.data?.content;
      }
    });
  }

  getAllEmployeesInCompany() {
    const option: any = {
      status: 1,
      companyId: this.authService.getCurrentCompanyId()
    };

    this.employeeService.getAllByCompanyIdWithoutPage(option).subscribe(res => {
      const resObj: any = res;

      if (resObj.status === 'SUCCESS') {
        this.allEmployees = resObj.data;
      }
    });
  }

  showMember(type) {
    this.allMemberList = [];
    this.memberType = type;

    if (type === '3') {
      this.allMemberList = (this.allEmployees || []).map(i => {
        return {
          username: i.username,
          fullName: i.fullName,
          status: i.status === 1 ? 'Active' : 'Inactive'
        }
      });
    } else if (type === '1') {
      this.allMemberList = (this.allAdmins || []).filter(i => i.role?.level === 3).map(i => {
        return {
          username: i.username,
          fullName: i.fullName,
          status: i.status === 1 ? 'Active' : 'Inactive'
        }
      });
    } else if (type === '2') {
      this.allMemberList = (this.allAdmins || []).filter(i => i.role?.level === 4).map(i => {
        return {
          username: i.username,
          fullName: i.fullName,
          status: i.status === 1 ? 'Active' : 'Inactive'
        }
      });
    }
    this.showMemberList = true;
    this.op3.hide();
  }
}
