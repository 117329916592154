import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../authenticate/auth.guard';
import { OntimeLateComponent } from './component/ontime-late/ontime-late.component';
import { PunchInOutHistoryComponent } from './component/punch-in-out-history/punch-in-out-history.component';
import { PunchInOutComponent } from './component/punch-in-out/punch-in-out.component';
import { EmployeeClockingLayoutComponent } from './component/employee-clocking-layout/employee-clocking-layout.component';
import { SummaryViewComponent } from './component/summary-view/summary-view.component';
import { EmployeeOTComponent } from "./component/employee-ot/employee-ot.component";
import { PunchInOutTrackingComponent } from './component/punch-in-out-tracking/punch-in-out-tracking.component';
import { NgModule } from '@angular/core';
import { ProtestHourListAllComponent } from "../employee/component/protest-hour/protest-hour-list-all/protest-hour-list-all.component";
import { EmployeePunchConfirmationComponent } from './component/employee-punch-confirmation/employee-punch-confirmation.component';
import { PermissionGuard } from 'app/authenticate/permission.guard';
import { MENU_ID } from 'app/shared/data/menu-id';
import { RoleLevel } from 'app/layout/component/app-menu/role-level';
import { EmployeeJobReportComponent } from 'app/employee/employee-job-report/component/employee-job-report/employee-job-report.component';
import { EmployeeJobReportAddComponent } from 'app/employee/employee-job-report/component/employee-job-report-add/employee-job-report-add.component';
import { EmployeeJobReportEditComponent } from 'app/employee/employee-job-report/component/employee-job-report-edit/employee-job-report-edit.component';
import { EmployeeJobReportHistoryComponent } from 'app/employee/employee-job-report/component/employee-job-report-history/employee-job-report-history.component';


export const routes: Routes = [
  {
    path: '',
    component: EmployeeClockingLayoutComponent,
    children: [
      {
        path: 'punch',
        component: PunchInOutComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { canView: [MENU_ID.PUNCH_IN_OUT], canEdit: [], roleWithoutPermission: [RoleLevel.ROLE_FRONT_DESK] },
      },
      {
        path: 'punch-history',
        component: PunchInOutHistoryComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { canView: [MENU_ID.PUNCH_HISTORY], canEdit: [] },
      },
      {
        path: 'ontime-late',
        component: OntimeLateComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { canView: [MENU_ID.PUNCH_HISTORY], canEdit: [] },
      },
      {
        path: 'summary-view',
        component: SummaryViewComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { canView: [MENU_ID.SUMMARY_VIEW], canEdit: [] },
      },
      {
        path: 'employee-ot',
        component: EmployeeOTComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { canView: [MENU_ID.EMPLOYEE_OT], canEdit: [] },
      },
      {
        path: 'punch-tracking',
        component: PunchInOutTrackingComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { canView: [MENU_ID.PUNCH_TRACKING], canEdit: [] },
      },
      {
        path: 'work-confirmation',
        component: EmployeePunchConfirmationComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { canView: [MENU_ID.WORK_TIME_CONFIRMATION], canEdit: [] },

      },
      {
        path: 'protest-hour',
        component: ProtestHourListAllComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { canView: [MENU_ID.EMPLOYEE_TIME_CORRECTION], canEdit: [] }
      },
      {
        path: 'job-report',
        component: EmployeeJobReportComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { canView: [MENU_ID.EMPLOYEE_JOB_REPORT], canEdit: [] }
      },
      {
        path: 'job-report/add',
        component: EmployeeJobReportAddComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { canView: [], canEdit: [MENU_ID.EMPLOYEE_JOB_REPORT] }
      },
      {
        path: 'job-report/edit/:id',
        component: EmployeeJobReportEditComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { canView: [], canEdit: [MENU_ID.EMPLOYEE_JOB_REPORT] }
      },
      {
        path: 'job-report/history',
        component: EmployeeJobReportHistoryComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: { canView: [MENU_ID.EMPLOYEE_JOB_REPORT], canEdit: [] }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmployeeClockingRoutingModule { }

