import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './component/layout/layout.component';
import { AppDashboardComponent } from './component/app-dashboard/app-dashboard.component';
import { AuthGuard } from '../authenticate/auth.guard';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { AppEmailComponent } from './component/app-email/app-email.component';
import { NoteLayoutComponent } from '../note/component/note-layout/note-layout.component'
import { FaqLayoutComponent } from 'app/faq/component/faq-layout/faq-layout.component';
import { EmailListComponent } from './component/app-email-list/app-email-list.component';
import { EmailLayoutComponent } from './component/app-email-layout/email-layout.component';
import { PermissionGuard } from 'app/authenticate/permission.guard';
import { MENU_ID } from 'app/shared/data/menu-id';
import { RoleLevel } from './component/app-menu/role-level';

export const LayoutRoutes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        children: [
            {
                path: '',
                component: DashboardComponent
            },
            {
                path: 'app/dashboard',
                component: DashboardComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: {
                    canView: [MENU_ID.DASHBOARD], canEdit: [],
                    roleWithoutPermission: [
                        RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN,
                        RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                        RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN,
                        RoleLevel.ROLE_CEO_ADMIN
                    ]
                }
            },
            {
                path: 'app/note',
                component: NoteLayoutComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.NOTEBOOK], canEdit: [] }
            },
            {
                path: 'app/faq',
                component: FaqLayoutComponent,
                canActivate: [AuthGuard]
            }
        ]
    }, {
        path: 'app/email',
        component: EmailLayoutComponent,
        children: [
            {
                path: '',
                component: AppEmailComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.MAIL], canEdit: [] }
            },
            {
                path: 'list',
                component: EmailListComponent,
                canActivate: [AuthGuard, PermissionGuard],
                data: { canView: [MENU_ID.MAIL], canEdit: [] }
            },
        ]
    }
];

export const LayoutRoutingModule = RouterModule.forChild(LayoutRoutes);
