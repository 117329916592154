import { QuoteDetail } from "./quote-detail.model";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from "moment-timezone";
import { formatNumber } from "@angular/common";
import { notoSansTC } from './noto-sans-tc';

export class Quote {
    id: number;
    companyId: number;
    clientId: number;
    quoteNumber: number;
    quoteDate: Date;
    quoteName: string;
    validUntil: Date;
    referenceNo: string;
    deposit: string;
    surveyType: string;
    discount: string;
    discountType: string;
    borough: string;
    town: string;
    district: string;
    address: string;
    county: string;
    neighborhood: string;
    zipcode: string;
    city: string;
    state: string;
    section: string;
    block: string;
    lot: string;
    quoteDetails: QuoteDetail[];
    createdByUsr: string;
    lastModifiedBy: string;
    updatedAt: Date;
}

export function buildSurveyLandPdfFile(quoteDetails, companyName, clientEmail, clientPhone, ignoreCheckingInvoice?): jsPDF {
    if (!quoteDetails.quoteNumber) {
        quoteDetails.quoteNumber = Math.floor(100000 + Math.random() * 900000);
      }
    changeDiscountType(quoteDetails.quoteDetails, quoteDetails.discountType, quoteDetails.discount);
    const doc = new jsPDF();
    autoTable(doc, {
      body: [
        [
          {
            content: companyName,
            styles: {
              halign: 'center',
              fontSize: 20
            }
          }
        ],
      ],
      theme: 'plain'
    });

    if (clientPhone || clientEmail) {
      autoTable(doc, {
        body: [
          [
            {
              content: (clientEmail ? ('Email: ' + clientEmail) : '') + ( clientPhone ? '\nTel: ' + clientPhone : ''),
              styles: {
                halign: 'center'
              }
            }
          ],
        ],
        theme: 'plain'
      });
    }

    doc.addFileToVFS('../../../../assets/fonts/NotoSans-Regular-all.ttf', notoSansTC);
    doc.addFont('../../../../assets/fonts/NotoSans-Regular-all.ttf', 'NotoSans', 'normal');
    doc.setFont('NotoSans');

    autoTable(doc, {
      body: [
        [
          {
            content: 'Quote Name: '  + quoteDetails.quoteName
              + '\nQuote Number: ' + quoteDetails.quoteNumber
              + '\nQuote Date: ' + moment(quoteDetails.quoteDate).format('MM/DD/YYYY')
              + '\nQuote Date: ' + moment(quoteDetails.validUntil).format('MM/DD/YYYY'),
            styles: {
                halign: 'left',
                font: 'NotoSans',
            }
          },
          {
            content: 'Address: ' + quoteDetails?.address
              + '\nCity/Town: ' + quoteDetails?.city
              + '\nState: : ' + quoteDetails?.state
              + '\nZip: ' + quoteDetails?.zipcode
              + '\nCountry: ' + quoteDetails?.county,
            styles: {
                halign: 'right',
                font: 'NotoSans',
            }
          }
        ],
      ],
      theme: 'plain'
    });

    for (let index = 0; index < quoteDetails.quoteDetails.length; index++) {
      const quoteDetail = quoteDetails.quoteDetails[index];
      if (!quoteDetail.invoiceNumber && ignoreCheckingInvoice) {
        continue;
      }
      let bodyContent = [];
      for (let i = 0; i < quoteDetail.quoteSurveys.length; i++) {
        const quoteSurvey = quoteDetail.quoteSurveys[i];
        const totalLineAmount = quoteSurvey?.quantity * quoteSurvey?.price
        const quoteSurveyArr = [quoteSurvey?.typeSurvey, convertToPlain(quoteSurvey?.description), quoteSurvey?.quantity, quoteSurvey?.price, totalLineAmount];
        bodyContent.push(quoteSurveyArr);
      }
      const totalAmountStr = 'Total Amount: ' + formatNumber(quoteDetail.totalAmount, 'en-US', '1.2-2');
      const subtotalStr = '$' + formatNumber(quoteDetail.subTotal, 'en-US', '1.2-2');
      const discountStr = '$' + formatNumber(quoteDetail.discount, 'en-US', '1.2-2');
      const totalStr = '$' + formatNumber(quoteDetail.totalAmount, 'en-US', '1.2-2');
      if (ignoreCheckingInvoice) {
        autoTable(doc, {
            body: [
              [
                {
                  content: totalAmountStr,
                  styles: {
                    halign: 'left',
                    fontSize: 14
                  }
                },
                {
                  content: 'Invoice Number #' + quoteDetail.invoiceNumber,
                  styles: {
                    halign: 'right',
                    fontSize: 14
                  }
                }
              ],
            ],
            theme: 'plain'
          });
      } else {
        autoTable(doc, {
            body: [
              [
                {
                  content: totalAmountStr,
                  styles: {
                    halign: 'left',
                    fontSize: 14
                  }
                }
              ],
            ],
            theme: 'plain'
        });
        let baseUrl;
        if (window.location.href.includes('?')) {
            baseUrl = window.location.href.substring(0, window.location.href.indexOf('?'));
        } else {
            baseUrl = window.location.href;
        }
        const approveUrl = baseUrl + '?quote=' + index;
        const rejectUrl = baseUrl + '?reject=' + index;
        if (index === 0) {
            if (quoteDetail.invoiceNumber) {
              const payUrl = baseUrl + '?invoiceNumber=' + quoteDetail.invoiceNumber;
              doc.setFillColor(30, 192, 44);
              doc.roundedRect(174, 81, 20, 10, 2, 2, 'FD');
              doc.textWithLink('Pay', 178, 88, { url: payUrl });
            } else {
              if (quoteDetails.status === 'Approved') {
                doc.setTextColor(127, 127, 127);
                doc.setFillColor(182, 220, 182);
                doc.roundedRect(146, 81, 25, 10, 2, 2, 'FD');
                doc.text('Approve', 148, 88);
  
                doc.setFillColor(255, 75, 75);
                doc.roundedRect(174, 81, 20, 10, 2, 2, 'FD');
                doc.text('Reject', 176, 88);
              } else {
                doc.setFillColor(30, 192, 44);
                doc.roundedRect(146, 81, 25, 10, 2, 2, 'FD');
                doc.textWithLink('Approve', 148, 88, { url: approveUrl });
  
                doc.setFillColor(255, 75, 75);
                doc.roundedRect(174, 81, 20, 10, 2, 2, 'FD');
                doc.textWithLink('Reject', 176, 88, { url: rejectUrl });
              }

            }
        } else {
            if (quoteDetail.invoiceNumber) {
              const payUrl = baseUrl + '?invoiceNumber=' + quoteDetail.invoiceNumber;
              doc.setFillColor(30, 192, 44);
              doc.roundedRect(174, 13, 20, 10, 2, 2, 'FD');
              doc.textWithLink('Pay', 178, 20, { url: payUrl });
            } else {
              if (quoteDetails.status === 'Approved') {
                doc.setTextColor(127, 127, 127);
                doc.setFillColor(182, 220, 182);
                doc.roundedRect(146, 13, 25, 10, 2, 2, 'FD');
                doc.text('Approve', 148, 20);

                doc.setFillColor(255, 75, 75);
                doc.roundedRect(174, 13, 20, 10, 2, 2, 'FD');
                doc.text('Reject', 176, 20);
              } else {
                doc.setFillColor(30, 192, 44);
                doc.roundedRect(146, 13, 25, 10, 2, 2, 'FD');
                doc.textWithLink('Approve', 148, 20, { url: approveUrl });
                
                doc.setFillColor(255, 75, 75);
                doc.roundedRect(174, 13, 20, 10, 2, 2, 'FD');
                doc.textWithLink('Reject', 176, 20, { url: rejectUrl });
              }  
            }
        }
      }
      
      autoTable(doc, {
        head: [['Type Of Survey', 'Description', 'Quantity', 'Unit Cost', 'Line Total']],
        body: bodyContent,
        theme: 'striped',
        headStyles: {
          fillColor: '#343a40'
        }
      });

      autoTable(doc, {
        body: [
          [
            {
              content: 'Subtotal:',
              styles: {
                halign: 'right'
              }
            },
            {
              content: subtotalStr,
              styles: {
                halign: 'right'
              }
            },
          ],
          [
            {
              content: 'Discount:',
              styles: {
                halign: 'right'
              }
            },
            {
              content: discountStr,
              styles: {
                halign: 'right'
              }
            },
          ],
          [
            {
              content: 'Paid to Date:',
              styles: {
                halign: 'right'
              }
            },
            {
              content: '$0.00',
              styles: {
                halign: 'right'
              }
            },
          ],
          [
            {
              content: 'Total:',
              styles: {
                halign: 'right'
              }
            },
            {
              content: totalStr,
              styles: {
                halign: 'right'
              }
            },
          ],
        ],
        theme: 'plain'
      });
      if (index !== quoteDetails.quoteDetails.length - 1) {
        doc.addPage();
      }
    }
    return doc;
  }

function changeDiscountType(quoteDetailsList: any, discountType: any, discount: any) {
    for (let index = 0; index < quoteDetailsList.length; index++) {
        for (let i = 0; i < quoteDetailsList[index].quoteSurveys.length; i++) {
            calculateTotalAmount(index, i, quoteDetailsList, discountType, discount);
        }
    }
}

function calculateTotalAmount(index: number, rowIndex: number, quoteDetailsList, discountType: any, discount: any) {
    const rowData = quoteDetailsList[index].quoteSurveys[rowIndex];
    quoteDetailsList[index].quoteSurveys[rowIndex].totalAmount = rowData.price * rowData.quantity;
    calculateTotalForQuote(index, quoteDetailsList, discountType, discount);
}

function calculateTotalForQuote(index: number, quoteDetailsList: any, discountType: any, discount: any) {
    quoteDetailsList[index].subTotal = 0;
    quoteDetailsList[index].discount = 0;
    quoteDetailsList[index].totalAmount = 0;
    quoteDetailsList[index].quoteSurveys.forEach(rowData => {
        quoteDetailsList[index].subTotal += rowData.totalAmount;
    });
    if (discountType === 'percent') {
        quoteDetailsList[index].discount = quoteDetailsList[index].subTotal * Number(discount) / 100;
    } else if (discountType === 'amount') {
        quoteDetailsList[index].discount = Number(discount);
    }
    quoteDetailsList[index].totalAmount = quoteDetailsList[index].subTotal - quoteDetailsList[index].discount;
}

function convertToPlain(html) {
  if (html) {
      
    // Create a new div element
    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element 
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }
  return '';
}
