import { Component, OnDestroy, OnInit } from '@angular/core';
import { MENU_ID } from 'app/shared/data/menu-id';
import { CustomTabMenu } from 'app/shared/model/custom-tab-menu';
import { AuthService } from 'app/shared/service/auth/auth.service';

@Component({
  selector: 'app-transaction-type-layout',
  templateUrl: './ar-report-layout.component.html',
  styleUrls: ['./ar-report-layout.component.css']
})
export class ArReportLayoutComponent implements OnInit, OnDestroy {

  customTabMenu: CustomTabMenu = {
    breadcrumb: 'Accounting',
    items: [
      {
        label: 'Accounts Receivable',
        id: 'ar_report',
        routerLink: ['/', 'app', 'ar-report', 'list'],
        routerLinkUrl: '/app/ar-report/list',
        visible: this.authService.hasViewPermission(MENU_ID.AR_REPORT),
      },
      {
        label: 'Revenue Payments',
        id: 'revenue_payment',
        routerLink: ['/', 'app', 'revenue-payment', 'list'],
        routerLinkUrl: '/app/revenue-payment/list',
        visible: this.authService.hasViewPermission(MENU_ID.REVENUE_PAYMENTS),
        breadcrumb: ['Revenue Payments']
      },
      {
        label: 'Service Revenue',
        id: 'service_revenue',
        routerLink: ['/', 'app', 'service-revenue', 'list'],
        routerLinkUrl: '/app/service-revenue/list',
        visible: this.authService.hasViewPermission(MENU_ID.SERVICE_REVENUE),
        breadcrumb: ['Service Revenue']
      },
      {
        label: 'Gross Income',
        id: 'gross-income',
        routerLink: ['/', 'app', 'gross-income', 'list'],
        routerLinkUrl: '/app/gross-income/list',
        visible: this.authService.hasViewPermission(MENU_ID.GROSS_INCOME),
        breadcrumb: ['Gross Income']
      },
      {
        label: 'Aging Summary',
        id: 'aging-summary',
        routerLink: ['/', 'app', 'aging-summary', 'list'],
        routerLinkUrl: '/app/aging-summary/list',
        visible: this.authService.hasViewPermission(MENU_ID.AGING_SUMMARY),
        breadcrumb: ['Aging Summary']
      },
      {
        label: 'Aging Details',
        id: 'aging-details',
        routerLink: ['/', 'app', 'receivable-aging-details', 'list'],
        routerLinkUrl: '/app/receivable-aging-details/list',
        visible: this.authService.hasViewPermission(MENU_ID.AGING_DETAILS),
        breadcrumb: ['Aging Details']
      }
    ]
  }

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

}
