<h1 class="m-0 ml-2 my-4">{{'Job Reports' | translate}}</h1>
<p-panel [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i>{{'Find Job Reports' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin">
			<label>{{'Company' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-building"></i>
					</button>
				</div>
				<p-dropdown [filter]="true" emptyFilterMessage="{{'No results found' | translate}}" [options]="allCompanyList" [(ngModel)]="companyId"  placeholder="{{'Select One' | translate}} *" [disabled]="disableCompany()" (onChange)="changeCompany($event)">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Date Range' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-week"></i>
					</button>
				</div>
				<mat-form-field class="w-100">
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate matInput placeholder="{{'Date Range' | translate}}" [(ngModel)]="fromDate" name="Startdate" (focus)="picker.open()">
						<input matEndDate matInput placeholder="{{'Date Range' | translate}}" [(ngModel)]="toDate" name="Enddate" (focus)="picker.open()">
					</mat-date-range-input>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'employeeName' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user"></i>
					</button>
				</div>
				<p-dropdown placeholder="{{'Select One' | translate}}" [options]="employees" [(ngModel)]="employeeFullName" [filter]="true" 
					emptyFilterMessage="{{'No results found' | translate}}" [autoDisplayFirst]="false" [disabled]="isEmployeeRole"
					(onChange)="changeEmployee($event)">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-12 d-flex justify-content-center pt-4 pb-4">
			<button (click)="resetFilter()" class="btn btn-danger mx-1"><i class="fa-solid fa-xmark mr-2"></i> {{'Reset' | translate}}</button>
			<button (click)="search()" class="btn btn-primary mx-1"><i class="fa-solid fa-check mr-2"></i> {{'Search' | translate}}</button>
		</div>
	</div>
</p-panel>
<div class="d-flex flex-wrap align-items-center justify-content-between py-4">
	<h1 class="m-0 ml-2">{{'Browse Reports' | translate}}</h1>
	<button appNoDblClick class="btn btn-primary" [disabled]="disableAdd" (click)="addJobReport()" type="button" id="add-new-button" pTooltip="{{'Add' | translate}}" tooltipPosition="left"><i class="fa-solid fa-plus"></i></button>
</div>
<p-panel id="table-ppanel" [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-table text-primary mr-2"></i> {{'Job Reports' | translate}} ({{(dataSource$|async)?.totalElements}})</span>
	</p-header>
	<p-table #dt [value]="(dataSource$|async)?.content" [rows]="10" [totalRecords]="(dataSource$|async)?.totalElements" [lazyLoadOnInit]="false"
			 [lazy]="true" [paginator]="true" [rowsPerPageOptions]="[5, 10, 25, 100]" [columns]="processCols"
			 [responsive]="false" (onPage)="loadDataSource($event)">
		<ng-template pTemplate="caption">
			<div class="d-flex justify-content-end w-100">
				<div class="input-group w-100 mr-2" style="max-width: 450px">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-filter"></i>
						</button>
					</div>
					<input type="text" size="35" [(ngModel)]="textSearch" (input)="loadDataSource($event)" placeholder="{{'Filter' | translate}}" class="form-control" style="height: 40px !important">
				</div>
			</div>
		</ng-template>
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th>#</th>
				<th *ngFor="let col of columns" [ngStyle]="{'min-width':col.width > 0 ? col.width + 'px' : '' }" pResizableColumn>
					<ng-container [ngSwitch]="col.field">
						<span *ngSwitchDefault>
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op12.toggle($event) : op11.toggle($event); selectedField = col.field; setSortOption(col.field, col.sortOptions)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>
						<span *ngSwitchCase="'documentId'">
							<i class="fa-solid fa-upload text-muted m-auto" pTooltip="{{'File Attachments' | translate}}"></i>
						</span>
						<span *ngSwitchCase="'jobReportNotes'">
							<span class="d-flex align-items-center justify-content-center w-100">
								<i class="fa-solid fa-bars-progress text-muted m-auto" pTooltip="{{'Progress Update' | translate}}"></i>
							</span>
						</span>
						<span *ngSwitchCase="'actions'">
							<i class="fa-solid fa-bars text-muted m-auto" pTooltip="{{'Actions' | translate}}" tooltipPosition="left"></i>
						</span>
						<span *ngSwitchCase="'location'">
							<i class="fa-solid fa-location-dot mx-1 text-muted" pTooltip="{{'Map' | translate}}" tooltipPosition="left"></i>
						</span>
					</ng-container>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData let-i="rowIndex">
			<tr [pSelectableRow]="rowData">
				<td>{{i+1}}</td>
				<td *ngFor="let col of processCols">
					<ng-container [ngSwitch]="col.field">
						<span *ngSwitchCase="'fullName'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Full Name' | translate}}: </span>
								<span *ngIf="rowData.fullName" pTooltip="{{ rowData[col.field] }}"><img *ngIf="rowData.avatar" [src]="rowData.avatar" class="avatar border mr-2" alt="Avatar" style="width: 23px; height: 23px; border-radius: 50%;" />{{ rowData[col.field] }}</span>
								<span *ngIf="!rowData.fullName" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'reportDate'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Added' | translate}}: </span>
								<span *ngIf="rowData.reportDate" pTooltip="{{ rowData[col.field] | date : 'MM/dd/yyyy hh:mm a'}}">{{ rowData[col.field] | date : 'MM/dd/yyyy hh:mm a'}}</span>
								<span *ngIf="!rowData.reportDate" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'documentId'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Attachment' | translate}}: </span>
								<i *ngIf="rowData?.documentId" (click)="uploadFilePanel.toggle($event); selectedRecord = rowData;" class="fa-solid fa-paperclip text-success" style="cursor: pointer;" pTooltip="{{'Attachments' | translate}}"></i>
								<i *ngIf="!rowData?.documentId" class="fa-solid fa-paperclip text-muted" style="cursor: pointer;" pTooltip="{{'no data' | translate}}"></i>
							</span>
						</span>
						<span *ngSwitchCase="'createdAt'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Created' | translate}}: </span>
								<span *ngIf="rowData.createdAt" pTooltip="{{ rowData[col.field] | date : 'MM/dd/yyyy hh:mm a'}}">{{ rowData[col.field] | date : 'MM/dd/yyyy hh:mm a'}}</span>
								<span *ngIf="!rowData.createdAt" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'updatedAt'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Updated' | translate}}: </span>
								<span *ngIf="rowData.updatedAt" pTooltip="{{ rowData[col.field] | date : 'MM/dd/yyyy hh:mm a' }}">{{ rowData[col.field] | date : 'MM/dd/yyyy hh:mm a' }}</span>
								<span *ngIf="!rowData.updatedAt" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'reportSynthetic'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Finished #/Total #' | translate}}: </span>
								<span *ngIf="rowData.needTotal && rowData.finishedTotal" pTooltip="{{ rowData.finishedTotal }}/{{ rowData.needTotal }}">{{ rowData.finishedTotal }}/{{ rowData.needTotal }}</span>
								<span *ngIf="!rowData.needTotal || !rowData.finishedTotal" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'location'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Map' | translate}}: </span>
								<i class="fa-solid fa-location-dot mx-1 text-primary" (click)="showMap(rowData)" pTooltip="{{'Map' | translate}}" tooltipPosition="left"></i>
							</span>
						</span>
						<span *ngSwitchCase="'jobReportNotes'">
							<span class="d-flex align-items-center w-100">
								<span class="mobile-table-label">{{'Progress Update' | translate}}: </span>
								<span *ngIf="rowData.jobReportNotes" class="cursor-pointer">
									<i class="fa-solid fa-list-check text-primary" (mouseenter)="noteDetailsOnMouseEnter($event, rowData.jobReportNotes, overlayPanel)" (mouseleave)="!noteDetailsOnMouseEnter($event, rowData.jobReportNotes, overlayPanel)" pTooltip="{{'Progress Updates' | translate}}"></i>
								</span>
								<span *ngIf="!rowData.jobReportNotes" class="cursor-pointer">
									<i class="fa-solid fa-list-check text-muted" pTooltip="No Data"></i>
								</span>
							</span>
						</span>
						<span *ngSwitchCase="'actions'">
							<span class="d-flex align-items-center">
								<span class="mobile-table-label">{{'Actions' | translate}}: </span>
								<i *ngIf="isAdmin() || isEmployeeRole" class="fa-solid fa-pen-to-square text-primary c-pointer mx-1" (click)="edit(rowData)" pTooltip="{{'View/Edit' | translate}}" tooltipPosition="left"></i>
								<i *ngIf="isAdmin() || isEmployeeRole" class="fa-solid fa-file-pdf text-bright c-pointer mx-1" (click)="exportPDF(rowData)" pTooltip="{{'Export PDF' | translate}}" tooltipPosition="left"></i>
								<i *ngIf="!isAdmin() && !isEmployeeRole" class="fa-solid fa-pen-to-square text-muted mx-1" pTooltip="{{'Disabled' | translate}}" tooltipPosition="left"></i>
								<i *ngIf="!isAdmin() && !isEmployeeRole" class="fa-solid fa-file-pdf text-muted mx-1" pTooltip="{{'Disabled' | translate}}" tooltipPosition="left"></i>
							</span>
						</span>
					</ng-container>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading">
				<td [attr.colspan]="10" class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="loading">
				<td [attr.colspan]="10" class="text-center py-5">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>
<p-overlayPanel #uploadFilePanel appendTo="body">
	<ng-template pTemplate>
		<ul class="m-0 px-1" style="list-style: none; min-width: 150px;">
			<li style="cursor: pointer;" *ngIf="selectedRecord?.documentId" >
				<span (click)="onViewDocument(uploadFilePanel)" class="text-nowrap"><i class="fa-solid fa-eye text-primary mr-2"></i>{{'view' | translate}} </span>
			</li>
		</ul>
	</ng-template>
</p-overlayPanel>
<p-dialog [(visible)]="showViewFileDialog" [modal]="true" [style]="{width: '50vw'}">
	<p-header>
		<span><i class="fa-solid fa-image mr-2"></i>{{'View Attachment' | translate}}</span>
	</p-header>
    <img class="w-100" [src]="fileUrl" alt="">
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="showViewFileDialog = false">
			<i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}
		</button>
	</p-footer>
</p-dialog>
<p-overlayPanel #op11 appendTo="body" class="p-0">
	<p-listbox [options]="textSortOptionsClient" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op12 appendTo="body">
	<p-listbox [options]="numberSortOptionsClient" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>

<p-dialog [(visible)]="viewMapDialog" class="modal_in" id="locationModal" [modal]="true" [style]="{'width': '90%', 'max-width':'900px', 'max-height': '85vh'}">
	<p-header>
		<span><i class="fa-solid fa-location-dot mr-2"></i>{{'Location' | translate}}</span>
	</p-header>
	<agm-map  *ngIf="jobLocation" style="width: 100%; height: 60vh" [latitude]="jobLocation.lat" [longitude]="jobLocation.lng" [zoom]="12">
		<agm-marker [latitude]="jobLocation.lat" [longitude]="jobLocation.lng"></agm-marker>
	</agm-map>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="viewMapDialog = false"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
	</p-footer>
</p-dialog>

<p-overlayPanel #overlayPanel [style]="{'width':'90%','max-width': '700px', 'min-height': '125px','max-height':'550px', 'overflow':'auto', 'padding': '0px'}" [appendTo]="'body'">
    <div class="spinner-overlay-note" *ngIf="showProgressBarForNote">
        <p-progressSpinner class="center-spin"></p-progressSpinner>
    </div>
    <div *ngFor="let item of notesDetailArray" class="py-2">
        <p-panel id="table-ppanel">
            <p-header>
                <span><i class="fa-solid fa-circle-info text-primary mr-2"></i>{{'Progress Updates' | translate}}</span>
                <span class="pull-right text-muted">{{item.notesTime}}</span>
            </p-header>
			<p-editor [id]="item.id"
				[(ngModel)]="item.note"
				[readonly]="true" [style]="{'width': '600px', 'min-height': '125px', 'padding': '0px', 'border':'0px'}">
				<p-header> </p-header>
		 	 </p-editor>
        </p-panel>
    </div>
</p-overlayPanel>
