import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { MENU_ID } from 'app/shared/data/menu-id';
import { CustomTabMenu } from 'app/shared/model/custom-tab-menu';
@Component({
    selector: 'app-company-layout',
    templateUrl: './company-layout.component.html',
    styleUrls: ['./company-layout.component.css']
})
export class CompanyLayoutComponent implements OnInit {

    customTabMenu: CustomTabMenu = {
        breadcrumb: 'Company',
        items: [
            {
                label: this.authService.isSuper() || this.authService.isSubSuper() ? 'Company List' : 'Company Profile',
                id: 'company_list',
                routerLink: ['list'],
                routerLinkUrl: '/app/company/list',
                visible: this.authService.hasViewPermission(MENU_ID.COMPANY_PROFILE),
                breadcrumb: [this.authService.isSuper() || this.authService.isSubSuper() ? 'Company List' : 'Company Profile']
            },
            {
                label: 'Add Company',
                id: 'add_company',
                routerLink: ['add'],
                routerLinkUrl: '/app/company/add',
                visible: this.authService.hasEditPermission(MENU_ID.COMPANY_PROFILE) && this.authService.isPlatformAdmin(),
                breadcrumb: ['Add Company']
            },
            {
                label: 'Edit Company',
                id: 'edit_company',
                routerLink: ['edit'],
                routerLinkUrl: '/app/company/edit',
                visible: this.authService.hasEditPermission(MENU_ID.COMPANY_PROFILE) && this.authService.isPlatformAdmin(),
                breadcrumb: ['Edit Company'],
                hideAsDefault: true
            },
            {
                label: 'Company Details',
                id: 'company_details',
                routerLink: ['view'],
                routerLinkUrl: '/app/company/view',
                visible: this.authService.hasViewPermission(MENU_ID.COMPANY_PROFILE),
                breadcrumb: ['Company Details'],
                hideAsDefault: true
            },
            {
                label: 'Company Setting',
                id: 'company_setting',
                routerLink: ['setting/view'],
                routerLinkUrl: '/app/company/setting/view',
                visible: this.authService.hasViewPermission(MENU_ID.COMPANY_SETTING),
                breadcrumb: ['Company Setting'],
                hideAsDefault: true
            },
            {
                label: 'Payroll Setting',
                id: 'payroll_setting',
                routerLink: ['payroll'],
                routerLinkUrl: '/app/company/payroll',
                visible: this.authService.hasViewPermission(MENU_ID.PAYROLL_SETTING),
                breadcrumb: ['Payroll Setting'],
                hideAsDefault: true
            },
            {
                label: 'View Payroll Setting',
                id: 'view_payroll_setting',
                routerLink: ['payroll/view'],
                routerLinkUrl: '/app/company/payroll/view',
                visible: this.authService.hasViewPermission(MENU_ID.PAYROLL_SETTING),
                breadcrumb: ['View Payroll Setting'],
                hideAsDefault: true
            },
            {
                label: 'Add Holiday',
                id: 'add_holiday',
                routerLink: ['payroll/holiday/add'],
                routerLinkUrl: '/app/company/payroll/holiday/add',
                visible: this.authService.hasEditPermission(MENU_ID.PAYROLL_SETTING),
                breadcrumb: ['Add Holiday'],
                hideAsDefault: true
            },
            {
                label: 'Edit Holiday',
                id: 'edit_holiday',
                routerLink: ['payroll/holiday/edit'],
                routerLinkUrl: '/app/company/payroll/holiday/edit',
                visible: this.authService.hasEditPermission(MENU_ID.PAYROLL_SETTING),
                breadcrumb: ['Edit Holiday'],
                hideAsDefault: true
            },
            {
                label: 'Other Setting',
                id: 'other_setting',
                routerLink: ['other/view'],
                routerLinkUrl: '/app/company/other/view',
                visible: this.authService.hasViewPermission(MENU_ID.OTHER_SETTING),
                breadcrumb: ['Other Setting'],
                hideAsDefault: true
            },
            {
                label: 'Edit Other Setting',
                id: 'edit_other_setting',
                routerLink: ['other/edit'],
                routerLinkUrl: '/app/company/other/edit',
                visible: this.authService.hasEditPermission(MENU_ID.OTHER_SETTING),
                breadcrumb: ['Edit Other Setting'],
                hideAsDefault: true
            },
            {
                label: 'Contract',
                id: 'company_contract',
                routerLink: ['contract'],
                routerLinkUrl: '/app/company/contract',
                visible: this.authService.hasViewPermission(MENU_ID.COMPANY_PROFILE),
                breadcrumb: ['Contract'],
                hideAsDefault: true
            },


            {
                label: 'Holiday',
                id: 'holiday_manage',
                routerLink: ['holiday'],
                routerLinkUrl: '/app/company/holiday',
                visible: this.authService.hasViewPermission(MENU_ID.COMPANY_HOLIDAY),
                breadcrumb: ['Holiday']
            },
            {
                label: 'Document Setting',
                id: 'document_setting',
                routerLink: ['document-settings/list'],
                routerLinkUrl: '/app/company/document-settings',
                visible: this.authService.hasViewPermission(MENU_ID.DOCUMENT_SETTING),
                breadcrumb: ['Document Setting']
            },
            {
                label: 'Bill & Payments',
                id: 'company_payment',
                routerLink: ['company-payment'],
                routerLinkUrl: '/app/company/company-payment',
                visible: this.authService.hasViewPermission(MENU_ID.COMPANY_PAYMENT),
                breadcrumb: ['Company Payment']
            },
            {
                label: 'Add Payment Method',
                id: 'company_payment_method',
                routerLink: ['company-payment-method'],
                routerLinkUrl: '/app/company/company-payment-method',
                visible: this.authService.hasEditPermission(MENU_ID.COMPANY_PAYMENT_METHOD),
                breadcrumb: ['Add Payment Method']
            },
            {
                label: 'Operational Policy',
                id: 'operation_policy',
                routerLink: ['operational-policy'],
                routerLinkUrl: '/app/company/operational-policy',
                visible: this.authService.hasEditPermission(MENU_ID.OPERATIONAL_POLICY),
                breadcrumb: ['Operational Policy']
            },
        ]
    }

    constructor(private authService: AuthService) {
    }


    ngOnInit() {
    }

    ngOnDestroy() {
    }
}
