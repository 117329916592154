import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LazyLoadEvent, MenuItem, Message, MessageService } from 'primeng/api';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Table } from 'primeng/table';
import { DepartmentService } from '../../../department/service/department.service';
import { Employee } from '../../../employee/model/employee.model';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { EmployeeClockingService } from '../../service/employee-clocking.service';
import { EmployeeClocking } from '../../model/employee-clocking.model';
import { WindowRefService } from '../../../shared/service/window-ref/window-ref.service';
import * as MOMENT from 'moment';
import * as moment from 'moment';
import { PayrollSettingService } from '../../../payroll-setting/service/payroll-setting.service';
import { CompanyService } from '../../../company/service/company.service';
import { AgencyService } from '../../../agency/service';
import { Company } from '../../../company/model/company.model';
import { Agency } from '../../../agency/model';
import { first } from 'rxjs/operators';
import * as _ from 'lodash';
import { NoteHistoryService } from '../../../crm/service/note-history.service';
import { Constants } from '../../../shared/model/constants';
import { EditedClockingBeyondSettings } from '../../model/edited.clocking-beyond-settings.model';
import { EmployeeService } from "../../../employee/service/employee.service";
import { PayrollSetting } from '../../../payroll-setting/model/payroll-setting.model';
import { bool } from 'aws-sdk/clients/signer';
import {DropDownsService} from "../../../shared/service/drop-downs/drop-downs.service";
import {TranslatePipe} from '@ngx-translate/core';
import {EmployeeClockingServiceV2} from '../../service/v2/employee.clocking.v2.service';
import {FileUtility} from '../../../shared/utility/file.utility';
import {SignaturePad} from 'angular2-signaturepad/signature-pad';
import {DocumentsServicev2} from '../../../shared/service/documents-upload/document.v2.service';
import {MonitoringDetailsService} from '../../../admin/process-monitor/service/monitoring-details.service';
import {UtilsService} from '../../../shared/service/utils.service';

declare let $: any;

@Component({
    selector: 'app-employee-punch-confirmation',
    templateUrl: './employee-punch-confirmation.component.html',
    styleUrls: ['./employee-punch-confirmation.component.scss'],
    providers: [EmployeeClockingService, WindowRefService, PayrollSettingService, MessageService]
})
export class EmployeePunchConfirmationComponent implements OnInit {
    totalRecords: Number = 0;
    msgs: Message[] = [];
    loading = false;
    employeeList: Array<Employee> = [];
    filteredEmployeeList: Array<Employee> = [];
    @ViewChild('dt', {static: true}) table: Table;
    companies = [];

    selectedCompany: any = 0;
    selectApplication: any;
    selectPeriod: any;
    selectEmployee: any;
    showViewFileDialog = false;
    fileUrl: any;
    optionsApplication = [
        { label: 'Web Client', value: 'Web' },
        { label: 'iOS Mobile', value: 'IOS Mobile'},
        { label: 'Android Mobile', value: 'Android Mobile'},
    ];
    periodList = [];
    textSortOptions = [
        {name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z'},
        {name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a'}
      ];
      numberSortOptions = [
        {name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9'},
        {name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1'}
      ];
      dateSortOptions = [
        {name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9'},
        {name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1'}
      ];
      statusSortOptions = [
        {name: 'Confirmed First', value: 'ASC', img: 'circle-check text-success'},
        {name: 'Pending First', value: 'DESC', img: 'circle-info text-primary'}
    ];
    selectedSortOption = '';

    isPlatformAdmin: boolean = false;
    isEmployee: boolean = false;
    adminData;
    selectedField;
    fromDate;
    toDate;
    totalPunchRecords;
    employeeIds = [];

    punchHistoryList = [];
    @ViewChild('signaturePadConfirm') signaturePadConfirm: SignaturePad;
    showSignaturePadConfirm = true;
    signaturePadUrl;
    isLicensorSignConfirm = null;
    signaturePadConfirmConfig: Object = {
        'minWidth': 1,
        'canvasWidth': 400,
        'canvasHeight': 225,
        'canvasBorder': 'none',
        'penColor': '#4a87f3'
    };
    divWidth;
    punchFromDateDialog;
    punchToDateDialog;
    timeSpent = new Date();
    showPunchDialog: boolean = false;
    confirmWeeklyHoursCorrect = false;
    dataPunchDialog: any;

    employeeConfirmation: any = [];
    employeeConfirmationExport: any = [];
    invalidDates: MOMENT.Moment[] = [MOMENT().add(2, 'days'), MOMENT().add(3, 'days'), MOMENT().add(5, 'days')];

    companyWeekEnd = 0;
    payrollSetting: any;

    showViewsHistoryDialog: any;
    viewsHistoryList: any = [];
    employeeNameHistory: any;
    periodHistory: any;
    totalViewsHistoryRecords;
    isInvalidDate = (m: MOMENT.Moment) => {
        return this.invalidDates.some(d => d.isSame(m, 'day'));
    }
    constructor(private router: Router, private route: ActivatedRoute,
        private employeeService: EmployeeService,
        private employeeClockingServiceV2: EmployeeClockingServiceV2,
        private documentServicev2: DocumentsServicev2,
        private employeeClockingService: EmployeeClockingService,
        private payrollSettingService: PayrollSettingService,
        private noteHistoryService: NoteHistoryService,
        private windowRef: WindowRefService,
        private companyService: CompanyService,
        private messageService: MessageService,
        private agencyService: AgencyService,
        private datePipe: DatePipe,
        private dropdownService: DropDownsService,
        private departmentService: DepartmentService,
        private monitoringDetailsService: MonitoringDetailsService,
        private utilsService: UtilsService,
        private authService: AuthService,
                private translatePipe: TranslatePipe) {
            this.divWidth = window.innerWidth * 30 / 100;
            this.signaturePadConfirmConfig = {
                'minWidth': 1,
                'canvasWidth': this.divWidth,
                'canvasHeight': 225,
                'canvasBorder': 'none',
                'penColor': '#4a87f3'
            };
    }

    ngOnInit() {
        this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        this.adminData = JSON.parse(localStorage.getItem('_globals'));
        this.isEmployee = this.authService.isEmployeeRole();
        this.selectedCompany = this.authService.getCurrentCompany() ? this.authService.getCurrentCompany().id : null;

        // this.getAllEmployees();
        this.dropdownService.getAllCompanyList().subscribe(
        res => {
            const responseObject = res;
            const tempCompanyList = [];
            if (responseObject.status === 'SUCCESS') {
                responseObject.data.forEach(element => {
                    tempCompanyList.push({ label: element.value, value: element.key });
                });
                this.companies = tempCompanyList;
            }
        });
        this.loadPayrollSetting();
        this.loadPage();
        this.loadEmployeeList(this.selectedCompany);
        this.getAllPeriod(this.selectedCompany ? this.selectedCompany : 0, 0);

    }
    onSearch() {
        this.msgs = [];
        this.loadPage();
    }

    onReset() {
        this.selectedCompany = null;
        this.selectEmployee = null;
        this.selectedCompany = null;
        this.selectApplication = null;
        this.selectPeriod = null;
        this.fromDate = null;
        this.toDate = null;
        this.loadPage();
    }

    loadPayrollSetting() {
        this.payrollSettingService.getbyId(this.authService.getCurrentCompanyId()).subscribe(res1 => {
            const res1Obj: any = res1;
            this.payrollSetting = res1Obj.data;
            this.companyWeekEnd =  this.utilsService.convertWeekDaysToNumber(this.payrollSetting.payrollWeekEnding);
        });
    }

    async loadPage() {
        this.loading = true;
        const  options = {
            companyId: null,
            employeeId: null,
            fromDate: null,
            toDate: null,
            application: null,
            size : 9999,
            page : 0,
            period: null
        };
        if (!this.isPlatformAdmin) {
            options.companyId = this.authService.getCurrentCompanyId();
        }
        if (this.isEmployee) {
            options.employeeId =  this.authService.getCurrentLoggedInId();
        }

        if (this.fromDate && this.toDate) {
            options.fromDate = this.fromDate;
            options.toDate = this.toDate;
        }
        if (this.selectedCompany) {
            options.companyId = this.selectedCompany.value;
        }
        if (this.selectApplication) {
            options.application = this.selectApplication.value;
        }
        if (this.selectEmployee) {
            options.employeeId = this.selectEmployee.value;
        }

        if (this.selectPeriod) {
            options.period = this.selectPeriod.value;
        }
        const resObj: any = await this.employeeClockingServiceV2.searchPunchConfirmation(options).toPromise();
        if (resObj.status === 'SUCCESS') {
            this.loading = false;
            this.employeeConfirmation = resObj.data.content;
            this.employeeConfirmation.forEach(item => {
               item.statusStr = item.status == 1? this.translatePipe.transform('Confirmed') : this.translatePipe.transform('Pending');
            });
            this.totalRecords = resObj.data.content.length;
            this.employeeConfirmationExport = resObj.data.content;
        }
    }

    search(event) {
        this.loading = true;
        this.table.first = 0;
        this.loadPage();
    }

    getAllPeriod(companyId, employeeId) {
        this.periodList = [];
        this.employeeClockingServiceV2.getAllPeriod(companyId, employeeId).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.periodList = res.data.map((item: any) => this.mapToDropdown(item, item));
            }
        });
    }

    getAllEmployees() {
        this.employeeClockingService.getEmployeeList().subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.employeeList = resObj.data;
            }
        });
    }

    mapToDropdown(label, val) {
        return {
            label: label,
            value: val
        };
    }

    getAllEmployeesByCompany() {
        this.loadEmployeeList(this.selectedCompany);
    }

    sortingClick(selectedSortOption) {
        this.sortF = this.selectedField;
        const options = {
            field: this.selectedField,
            order: selectedSortOption === 'ASC' ? 1 : -1,
            mode: 'single',
            data: this.employeeConfirmation
        };

        // this.setSortOption(this.selectedField, selectedSortOption);
        this.customSort(options);
    }
    sortF;
    customSort(event) {
        if (this.sortF.length > 0) {
            if (event.data) {
                event.data.sort((data1, data2) => {
                    const value1 = data1[event.field];
                    const value2 = data2[event.field];
                    let result = null;

                    if (value1 == null && value2 != null) {
                        result = -1;
                    } else if (value1 != null && value2 == null) {
                        result = 1;
                    } else if (value1 == null && value2 == null) {
                        result = 0;
                    } else if (event.field != 'viewConfirm' && typeof value1 === 'string' && typeof value2 === 'string') {
                        result = value1.localeCompare(value2);
                    } else {
                        result = (+value1 < +value2) ? -1 : (+value1 > +value2) ? 1 : 0;
                    }
                    return (event.order * result);
                });
            }
            this.sortF = '';
        }
    }

    loadEmployeeList(company) {
        this.employeeList = [];
        const searchOption = <any>{};
        if (company != null) {
            searchOption.companyId = company;
        } else {
            searchOption.companyId = this.authService.getCurrentCompanyId();
        }
        searchOption.status = 1;
        this.employeeService.getAllsDropdown(searchOption).subscribe(resEmp => {
            const empObj: any = resEmp;
            const tempList: any[] = [];
            if (empObj.status === 'SUCCESS') {
                empObj.data.forEach(element => {
                    tempList.push({ label: element.fullName, value: element.key });
                });
                tempList.sort((a, b) => a.label.localeCompare(b.label));
            }
            this.employeeList = tempList;
        });
    }
    viewSignatureDialog(rowData) {
        this.showViewFileDialog = true;
        this.fileUrl = rowData.signatureUrl;
    }

    exportingPDF;
    exportPdf() {
        if (this.employeeConfirmationExport && this.employeeConfirmationExport.length) {
            this.exportingPDF = true;
            const data: any = {
                contentList: this.employeeConfirmationExport.map(value => {
                    return {
                        id: value.id,
                        fullName: value.fullName,
                        createdAtStr: this.datePipe.transform(value.createdAt, 'MM/dd/yyyy hh:mm a'),
                        period: value.period,
                        viewConfirm: value.viewConfirm,
                        signatureUrl: value.signatureUrl,
                        application: value.application,
                        status: value.statusStr
                    };
                })
            };
            data.companyId = this.authService.getCurrentCompanyId();
            data.companyName = this.companies.find(item => item.value === this.authService.getCurrentCompanyId()).label;
            data.printedBy = this.authService.getCurrentUsername();
            data.reportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy EEE hh:mm a');
            this.employeeClockingServiceV2.exportPunchConfirm(data).subscribe(res => {
                if (res.data) {
                    const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl, '_blank');
                } else {
                    this.messageService.add({severity: 'error', summary: 'Problem exporting', detail: 'Problem exporting pdf list'});
                }
                this.exportingPDF = false;
            }, error => {
                this.exportingPDF = false;
            });
        }
    }

    onFilter(event) {
        this.employeeConfirmationExport = event.filteredValue;
    }

    async exportPdfDetail(rowData) {
        let punchHistoryListExport = [];
        let date = [];
        rowData.isExporting = true;
        if (rowData.period) {
            date = rowData.period.split(' - ');
            const startDate =  new Date(date[0]);
            const endDate = new Date(date[1]);
            const options = {
                employeeIds: [rowData.employeeId],
                fromDate: moment(startDate).format('YYYY-MM-DD'),
                toDate: moment(endDate).format('YYYY-MM-DD'),
                sortField: 'createdAt',
                sortOrder: 'ASC',
                companyId: rowData.companyId,
                isForOT: false
            };
            const start = new Date(startDate);
            const end = new Date(endDate);
            const isWeekEndDate = end.getDay() === this.companyWeekEnd;
            const isInWeek = end.getDay()  - start.getDay() === -1;
            options.isForOT = isWeekEndDate && isInWeek;

            const resObj: any = await this.employeeClockingServiceV2.search2(options).toPromise();
            if (resObj.status === 'SUCCESS') {
                punchHistoryListExport = _.compact(resObj.data.content);
            }
        }
        const data: any = {
            contentList: punchHistoryListExport.map(value => {
                let checkIn = '';
                let lunchOut = '';
                let lunchIn = '';
                let totalLunchTime = '';
                let checkOut = '';
                const total = value.spreadHour && value.spreadHour > 0 ? '+ 01:00' : value.workHours ? value.workHours : '--:--';
                if (!this.isExceptionPunchInOut(value.locationName)) {
                    checkIn = !value.missedStartWork && value.clockInTime == null ? '--:--' : value.missedStartWork ? 'Missing' : this.datePipe.transform(value.clockInTime, 'h:mm a');
                    lunchOut = !value.missedLunchOutTime && value.lunchOutTime == null ? '--:--' : value.missedLunchOutTime ? 'Missing' : this.datePipe.transform(value.lunchOutTime, 'h:mm a');
                    lunchIn = !value.missedLunchInTime && value.lunchInTime == null ? '--:--' : value.missedLunchInTime ? 'Missing' : this.datePipe.transform(value.lunchInTime, 'h:mm a');
                    checkOut = !value.missedEndWork && value.clockOutTime == null ? '--:--' : value.missedEndWork ? 'Missing' : this.datePipe.transform(value.clockOutTime, 'h:mm a');
                    totalLunchTime = this.calCulateTotalLunchTothhmm(value);
                } else {
                    checkIn = value.locationName;
                    lunchOut =  value.locationName;
                    lunchIn =  value.locationName;
                    totalLunchTime =  value.locationName;
                    checkOut =  value.locationName;
                }

                return {
                    id: value.id,
                    fullName: value.fullName,
                    date: this.datePipe.transform(value.punchDate, 'MM/dd/yyyy'),
                    day: this.datePipe.transform(value.punchDate, 'EEE'),
                    checkIn: checkIn,
                    lunchOut: lunchOut,
                    lunchIn: lunchIn,
                    checkOut: checkOut,
                    totalLunchTime: totalLunchTime,
                    total: total,
                    overTime: value.overTimeStr
                };
            })
        };
        data.detailList = [
            {
                createdAtStr: this.datePipe.transform(rowData.createdAt, 'MM/dd/yyyy hh:mm a'),
                viewConfirm: rowData.viewConfirm,
                signatureUrl: (rowData.signatureUrl || '').replace('https://livestore.operrwork.com', 'http://192.168.120.175'),
                application: rowData.application,
                status: rowData.statusStr
            }
        ];
        data.from = date[0];
        data.to = date[1];
        data.signatureUrl = (rowData.signatureUrl || '').replace('https://livestore.operrwork.com', 'http://192.168.120.175');
        data.totalHours = this.getTotalHour(punchHistoryListExport);
        data.totalOvertime = this.getTotalOverrtime(punchHistoryListExport);
        data.companyId = this.authService.getCurrentCompanyId();
        data.companyName = this.companies.find(item => item.value === rowData.companyId).label;
        data.printedBy = this.authService.getCurrentUsername();
        data.reportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy EEE hh:mm a');
        data.periodDate = rowData.period;
        data.employeeName = rowData.fullName;
        this.employeeClockingServiceV2.exportPunchConfirmDetail(data).subscribe(res => {
            if (res.data) {
                const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
                const blobUrl = URL.createObjectURL(blob);
                window.open(blobUrl, '_blank');
            } else {
                this.messageService.add({severity: 'error', summary: 'Problem exporting', detail: 'Problem exporting pdf list'});
            }
            this.exportingPDF = false;
            rowData.isExporting = false;
        }, error => {
            this.exportingPDF = false;
            rowData.isExporting = false;
        });
    }

    clearLicensorSign() {
        this.showSignaturePadConfirm = true;
        this.isLicensorSignConfirm = null;
        // this.contract.licensorSignature = null;
        if (this.signaturePadConfirm) {
            this.signaturePadConfirm.clear();
        }
    }
    drawStartSignaturePad() {
    }
    drawCompleteSignaturePad() {
    }

    redirectToPunchHistory() {
    }

    isExceptionPunchInOut(location: any) {
        return location === 'Holiday' || location === 'OFF' || location === 'VACATION' || location === 'SICK' || location === 'OTHER';
    }

    getEndWorkLabel(data) {
        if (data.createdAt) {
            const cteateDate = moment(data.createdAt).format('YYYY-MM-DD');
            const currentDate = moment(new Date()).format('YYYY-MM-DD');
            return (cteateDate === currentDate) ? '--:--' : 'Missing';
        } else {
            return 'Missing';
        }
    }

    calCulateTotalLunchTothhmm(rowData) {
        if (!(rowData && rowData.employeeClockingLunchHourHistory)) {
            return '';
        }
        let totalMinutes = 0;
        const clockings = rowData.employeeClockingLunchHourHistory;
        for (const clocking of clockings) {
            if (clocking.lunchTime) { totalMinutes += clocking.lunchTime; }
        }
        const hours = Math.floor(totalMinutes / 60);
        const minute = totalMinutes % 60;
        return _.padStart(hours, 1, '0') + ':' + _.padStart(minute, 2, '0');
    }

    getToolTip(locationName, text) {
        if (locationName === 'Holiday') {
            return 'Holiday: ' + text;
        } else {
            if (text && text !== null && text !== 'null') {
                return ' by ' + text;
            }
            return '';
        }
    }

    closePunchDialog() {
        this.showPunchDialog = false;
        this.monitoringDetailsService.monitorAction(
            `Weekly hours popup closed`,
            this.timeSpent,
            {weekly_hours_popup_close_by : this.authService.getCurrentLoggedInName()},
            'complete',
            'Weekly hours popup closed',
            0
        );
    }
    getTotalOverrtime(punchHistoryList: Array<any>) {
        let totalHours = 0;
        _.forEach(punchHistoryList, (x) => {
            totalHours += x.overTime;
        });
        return this.hhmm(Math.round(totalHours));
    }

    getTotalHour(punchHistoryList: Array<any>) {
        let totalHours = 0;
        let totalOvertime = 0;
        _.forEach(punchHistoryList, (x) => {
            totalHours += x.totalWorkTime;
            totalOvertime += x.overTime;
        });
        const result = totalHours / 60000 - totalOvertime;
        return this.hhmm(Math.round(result));
    }

    hhmm(minutes: number): string {
        const hours = Math.floor(minutes ? minutes / 60 : 0);
        const minute = minutes ? minutes % 60 : 0;
        if (!Number.isInteger(hours) || !Number.isInteger(minute)) {
            return minutes + '';
        }
        return _.padStart(hours, 1, '0') + ':' + _.padStart(minute, 2, '0');
    }

    dateToShowInTwelveHoursFormat(date) {
        if (date) {
            return moment(date).format('hh:mm A');
        } else {
            return '';
        }
    }

    async showPopupPunch(rowData) {
        this.showSignaturePadConfirm = true;
        this.signaturePadUrl = '';
        this.dataPunchDialog = rowData;
        if (rowData.period) {
            const date = rowData.period.split(' - ');
            const startDate =  new Date(date[0]);
            const endDate = new Date(date[1]);
            this.punchFromDateDialog = date[0];
            this.punchToDateDialog = date[1];
            const options = {
                employeeIds: [rowData.employeeId],
                fromDate: moment(startDate).format('YYYY-MM-DD'),
                toDate: moment(endDate).format('YYYY-MM-DD'),
                sortField: 'createdAt',
                sortOrder: 'ASC',
                companyId: rowData.companyId,
                isForOT: false
            };
            const start = new Date(startDate);
            const end = new Date(endDate);
            const isWeekEndDate = end.getDay() === this.companyWeekEnd;
            const isInWeek = end.getDay()  - start.getDay() === -1;
            options.isForOT = isWeekEndDate && isInWeek;

            const resObj: any = await this.employeeClockingServiceV2.search2(options).toPromise();
            if (resObj.status === 'SUCCESS') {
                this.punchHistoryList = _.compact(resObj.data.content);
            }
            this.showPunchDialog = true;
            if (rowData.status === '1') {
                this.showSignaturePadConfirm = false;
                this.signaturePadUrl = rowData.signatureUrl;
            }
        }
    }

    private dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        const byteString = atob(dataURI.split(',')[1]);
        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], {
            type: mimeString
        });
    }


    submitPunchConfirmation() {
        console.log('submitPunchConfirmation signaturePadConfirm.isEmpty: ', this.signaturePadConfirm.isEmpty())
        if (this.showSignaturePadConfirm) {
            if (this.signaturePadConfirm.isEmpty()) {
                this.messageService.add({
                    severity: 'error',
                    summary: this.translatePipe.transform('Invalid data'),
                    detail: this.translatePipe.transform('Licensee/Licensor Signatures are required!')
                });
                return;
            }
            const signaturePadRaw = this.dataURItoBlob(this.signaturePadConfirm.toDataURL());
            const signaturePadFile = new File([signaturePadRaw], 'signaturePadConfirm.png');
            this.documentServicev2.uploadFile(signaturePadFile, 'recollection_signaturePadFile', this.authService.getCurrentCompanyId(), 'Company') // upload licensor sign
                .subscribe(resSignature => {
                    const resObj: any = resSignature;
                    const payLoad = {
                        employeeClockingIds: this.punchHistoryList.map(i => i.id),
                        signature: resObj.data.id,
                        signatureUrl: resObj.data.fileUrl,
                        fullName: this.dataPunchDialog.fullName,
                        companyId: this.dataPunchDialog.companyId,
                        employeeId: this.dataPunchDialog.employeeId,
                        period : this.dataPunchDialog.period,
                        application: this.dataPunchDialog.application,
                    };
                    this.employeeClockingServiceV2.confirmPunch(payLoad).subscribe(res => {
                        if (res.status === 'SUCCESS') {
                            this.messageService.add({
                                severity: 'info',
                                summary: this.translatePipe.transform('Save'),
                                detail: this.translatePipe.transform('Punch confirmation success')
                            });
                            this.monitoringDetailsService.monitorAction(
                                `Weekly hours popup confirmed`,
                                this.timeSpent,
                                { weekly_hours_popup_confirmed_by: this.authService.getCurrentLoggedInName()},
                                'complete',
                                ' Weekly hours popup confirmed',
                                0
                            );
                        } else {
                            this.messageService.add({
                                severity: 'error',
                                summary: this.translatePipe.transform('Save'),
                                detail: this.translatePipe.transform('Punch confirmation error')
                            });
                        }
                        this.showPunchDialog = false;
                        this.onSearch();
                    }, () => {
                        this.showPunchDialog = false;
                        this.messageService.add({
                            severity: 'error',
                            summary: this.translatePipe.transform('Error'),
                            detail: this.translatePipe.transform('Punch confirmation error')
                        });
                    });
                });
        }
    }

    showViewsHistoryPopup(rowData) {
        this.showViewsHistoryDialog = true;
        this.viewsHistoryList = rowData.employeePunchConfirmationHistories;
        this.periodHistory = rowData.period;
        this.employeeNameHistory = rowData.fullName;
        this.totalViewsHistoryRecords = this.viewsHistoryList.length;
    }

    closeViewHistoryDialog() {
        this.showViewsHistoryDialog = false;
        this.viewsHistoryList = [];
        this.periodHistory = null;
        this.employeeNameHistory = null;
    }
}
