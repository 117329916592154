export class ClockingComplianceRequest {
    public employeeIds?: number[];
    public department?: number;
    public fromDate?: number;
    public toDate?: number;
    // public agencyId?: number;
    public companyId?: number;
    public page: number;
    public size: number;
    public sortOrder: string;
    public sortField: string;
    public timeZone: String;
    public startDate?: Date;
    public endDate?: Date;
}

export class DailyComplianceReport {

    public date: Date;
    public expectedToday: number;
    public expected: number;
    public absentCount: number;
    public lateCount: number;
    public onTimeCount: number;
    public totalEmployee: number;
    public missedclockCount: number;
    public withoutLunchCount: number;
    public schduledTimeInMinutes: number;
    public sceduledTimeHHMM?: string;
    public regularTimeinMinutes: number;
    public regularTimeHHMM: string;
    public weekDay: number;
    public timeWorkDay: number;
    public isHoliday: boolean;
    public schduledTimeByDateInMinutes?: number;

}
export class ClockingComplianceReport {

    public daywiseCompliance: DailyComplianceReport[] = [];
    public totalExpected: number;
    public totalAbsent: number;
    public totalLate: number;
    public totalOntime: number;
    public totalMissed: number;
    public totalEmployee?: number;
    public totalEmployeeWithoutLunch: number;
    public totalScheduled: number;
    public totalWorkTime: number;
    public numberOfDays: number;
    public totalScheduledHoursHHMM?: string;
    public totalWorkHourHHMM?: string;
    totalWorkTimeHHMM: string;

}
