import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Company } from '../../../company/model/company.model';
import { Agency, AgencyHoliday } from '../../../agency/model';
import { Subject } from 'rxjs/Subject';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Constants } from '../../../shared/model/constants';
import * as _ from 'lodash';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HolidayHistoryService } from '../../service/holiday-history.service';
import { CompanyServicev2 } from 'app/company/service/company.v2.service';
import * as MOMENT from 'moment';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { OperationalPolicyService } from "../../service/operational-policy.service";
import { Table } from "primeng/table";

@Component({
  selector: 'app-operational-policy',
  templateUrl: './operational-policy.component.html',
  styleUrls: ['./operational-policy.component.scss'],
  providers: [
    HolidayHistoryService,
    // {
    //   provide: DateAdapter,
    //   useClass: MomentDateAdapter,
    //   deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    // },
    // { provide: MAT_DATE_FORMATS, useValue: DATE_MONTH_FORMATS }
  ]
})
export class OperationalPolicyComponent implements OnInit, OnDestroy {

  @ViewChild('f') myNgForm;

  loading = true;
  size: number = 9999;
  page: number;
  companySelects: any;
  companyName: any;
  agencyName: any;
  agencyId: number;
  companyId: any;
  agencies: any[] = [];
  agency: Agency;
  searchText: any;
  company: Company;
  agencySelected: any;
  historyShow = false;
  searchStatus: any[] = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' }
  ];

  optionEvery: any[] = [];
  selectedStatus: any;
  selectedSortOption = '';
  selectedField = '';
  textSortOptions = [
    { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
    { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
  ];
  numberSortOptions = [
    { name: 'Sort 0 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 0', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  dateSortOptions = [
    { name: 'Sort 0 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 0', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  cols: any[] = [
    { field: 'id', label: 'cols.id', sortOptions: '', sort: 'number' },
    { field: 'name', label: 'Policy Name', sortOptions: '', sort: 'text' },
    { field: 'createdAt', label: 'Created', sortOptions: 'createdAt', sort: 'number' },
    { field: 'createdByUsr', label: 'Creator', sortOptions: '', sort: 'text' },
    { field: 'status', label: 'cols.status', sortOptions: '', sort: 'text' },
    { field: 'actions', label: 'cols.action' }
  ];
  holidayDate: Date;
  companyList: any[];
  agencyList: Agency[];
  edittingHoliday: any;
  formMode: string;

  private destroyed$ = new Subject();
  private dataSourceSubject = new BehaviorSubject(null);
  dataSource$ = this.dataSourceSubject.asObservable();
  holidayList = [];

  searchForm: UntypedFormGroup;
  holidayForm: UntypedFormGroup;
  isPlatformAdmin = false;
  hide: boolean;
  sortField: string;
  sortOrder: any;
  msgs: any[];
  addHolidayModal = false;
  displayDialog: boolean = false;
  currentHoliday: AgencyHoliday;

  checkValidateName: boolean = false;
  @ViewChild('table', { static: true }) table: Table;
  constructor(private router: Router,
    private formBuilder: UntypedFormBuilder,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private messageService: MessageService,
    private companyService: CompanyServicev2,
    private operationalPolicyService: OperationalPolicyService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private translatePipe: TranslatePipe) {
    this.translate.use(this.translate.currentLang ? this.translate.currentLang : 'en');
  }

  ngOnInit() {

    this.initHolidayForm();
    this.initSearchForm();
    this.initCompanyDropdown();

    this.hide = this.authService.isCompanyAd() || this.authService.isSubCompanyAd();
    this.company = this.authService.getCurrentCompany() ? this.authService.getCurrentCompany() : null;
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    if (!this.isPlatformAdmin)
      this.companyId = this.authService.getCurrentCompany();

    for (let i = 1; i <= 31; i++) {
      this.optionEvery.push({ label: i, value: i });
    }
    this.loadAllHolidayList();
  }

  initCompanyDropdown() {
    this.companyService.getDropdown({ page: 0, size: 9999 }).subscribe((res: any) => {
      this.companyList = [];
      res.data.forEach(element => {
        this.companyList.push({ label: element.value, value: element.key });
      });
      this.companySelects = this.authService.getCurrentCompany();
      // if (!this.isPlatformAdmin) { this.companySelects = this.authService.getCurrentCompany(); }
      //this.loadAllHolidayList();
    });
  }

  changeCompany(event: any) {
    this.companyId = event.value;
    this.companySelects = this.companyId;
    this.loadAllHolidayList();

  }

  search(event?: any) {
    this.page = 0;
    setTimeout(() => {
      const wordSearch = event.target.value;

      if (wordSearch === event.target.value) {
        this.loadAllHolidayList();
      }
    }, 1000);

  }

  private initSearchForm() {
    this.searchForm = this.formBuilder.group({
      company: [null, Validators.required],
    });
    this.searchForm.patchValue({ company: this.authService.getCurrentCompany() });
  }

  getHistory() {
    this.historyShow = true;
  }
  private initHolidayForm(holiday?: any) {
    if (holiday && holiday.id) {
      this.formMode = Constants.FORM_MODE.UPDATE;
    } else {
      this.formMode = Constants.FORM_MODE.CREATE;
      this.edittingHoliday = {
        status: 'Active'
      };
      holiday = <any>{
        status: 'Active'
      };
    }
    this.holidayForm = this.formBuilder.group({
      name: [holiday.name, Validators.required],
      status: [holiday.status, Validators.required]
    });
  }

  ngOnDestroy() {
    this.dataSourceSubject.next(null);
    this.dataSourceSubject.complete();
    this.destroyed$.next();
    this.destroyed$.complete();

  }

  sortingClick(selectedSortOption: string) {
    this.selectedSortOption = selectedSortOption;
    this.page = 0; // Reset to the first page
    this.loadAllHolidayList();
  }



  setSortOption(field, selectedSortOption?) {
    this.cols.forEach(item => {
      if (field === item.field) {
        item.sortOptions = selectedSortOption;
        this.selectedSortOption = selectedSortOption;
      }
    });
  }

  loadAllHolidayList(event?: any) {
    // if (!this.isPlatformAdmin) {
    //   this.companyId = this.authService.getCurrentCompany();
    // }
    this.holidayList = [];
    this.dataSourceSubject.next(null);
    this.loading = true;
    this.page = 0;
    let options = <any>{};
    options.size = event && event.rows ? event.rows : (this.size ? this.size : 99999);
    options.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
    options.sortField = this.selectedField ? this.selectedField : 'createdAt';
    options.sortOrder = this.selectedSortOption ? this.selectedSortOption : 'DESC';
    options.key = this.searchText ? this.searchText : null;
    ///options.companyId = this.companyId;
    this.operationalPolicyService.search(options).subscribe(
      (data: any) => {
        this.loading = false;
        this.dataSourceSubject.next(data.data);
        this.holidayList = data.data.content;
        this.holidayList.forEach(item => {
          if (item.createdAt) {
            item.createdAtStr = this.datePipe.transform(item.createdAt, 'MM/dd/yyyy hh:mm a');
          }
        });
      },
      error => {
        console.error('Error fetching data:', error);
        this.loading = false;
      }
    );
  }

  hasError(fieldName: string, errorCode: string): boolean {
    return this.holidayForm.controls[fieldName] &&
      (this.holidayForm.controls[fieldName].dirty || this.holidayForm.controls[fieldName].touched) &&
      this.holidayForm.controls[fieldName].hasError(errorCode);
  }

  submitting;
  async onSave(event: Event) {
    if (this.submitting) return;
    this.submitting = true;
    const holidayRaw: any = this.holidayForm.getRawValue();
    // if (!this.isPlatformAdmin) {
    //     holidayRaw.companyId = this.authService.getCurrentCompany();
    // }
    // if (!holidayRaw.companyId === undefined) {
    //   this.messageService.add({ severity: 'error',
    //            summary: this.translatePipe.transform('error'), detail: this.translatePipe.transform('Please select company') });
    //   return;
    // }
    if (this.formMode === Constants.FORM_MODE.UPDATE) {
      this.msgs = [];
      const historyName = this.edittingHoliday.holidayName;
      const historyDate = this.edittingHoliday.holidayDate;
      _.assign(this.edittingHoliday, holidayRaw);
      this.edittingHoliday.lastModifiedBy = this.authService.getCurrentUsername();
      await this.operationalPolicyService.updateRecord(this.edittingHoliday).subscribe((response: any) => {
        const resObj: any = response;
        if (resObj.status === 'SUCCESS') {
          this.messageService.add({
            severity: 'info',
            summary: this.translatePipe.transform('Updated'), detail: this.translatePipe.transform('Policy Updated.')
          });
          setTimeout(() => {
            // this.resetCompanyAgency();
            this.loadAllHolidayList();
            this.initHolidayForm();
            this.myNgForm.resetForm();
            this.submitting = false;
          }, 1000);
        } else if (resObj.status === 'COMPANY_HOLIDAY_NAME_EXIT') {
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('error'), detail: this.translatePipe.transform('COMPANY_HOLIDAY_NAME_EXIT') });
          this.initHolidayForm();
          this.loadAllHolidayList();
          this.myNgForm.resetForm();
          this.submitting = false;
        } else {
          this.edittingHoliday.holidayName = historyName;
          this.edittingHoliday.holidayDate = historyDate;
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('error'), detail: this.translatePipe.transform('COMPANY_HOLIDAY_NAME_UNIQUE') });
          this.initHolidayForm();
          this.loadAllHolidayList();
          this.myNgForm.resetForm();
          this.submitting = false;
        }
      }, (error: any) => {
        this.submitting = false;
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('error'), detail: this.translatePipe.transform('ErrorUpdating') });
      });
    } else {
      this.msgs = [];

      // holidayRaw.agency = { id: this.agency.id };
      // holidayRaw.companyId = this.companySelects;
      holidayRaw.createdByUsr = this.authService.getCurrentUsername();
      await this.operationalPolicyService.createRecord(holidayRaw).subscribe((response: any) => {
        const resObj: any = response;
        if (resObj.status === 'SUCCESS') {
          this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('Created'), detail: this.translatePipe.transform('Policy Added.') });
          setTimeout(() => {
            this.resetCompanyAgency();
            this.loadAllHolidayList();
            this.initHolidayForm();
            this.myNgForm.resetForm();
            this.submitting = false;
          }, 1000);
        } else if (resObj.status === 'COMPANY_HOLIDAY_NAME_EXIT') {
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('error'), detail: this.translatePipe.transform('COMPANY_HOLIDAY_NAME_EXIT') });
          setTimeout(() => {
            this.loadAllHolidayList();
            this.initHolidayForm();
            this.myNgForm.resetForm();
            this.submitting = false;
          }, 1000);
        } else {
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('error'), detail: this.translatePipe.transform('COMPANY_HOLIDAY_NAME_UNIQUE') });
          setTimeout(() => {
            this.loadAllHolidayList();
            this.initHolidayForm();
            this.myNgForm.resetForm();
            this.submitting = false;
          }, 1000);
        }
      }, (error: any) => {
        this.submitting = false;
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('error'), detail: this.translatePipe.transform('COMPANY_HOLIDAY_NAME_EXIT') });
      });

    }
  }

  onCancel(event: Event) {
    this.initHolidayForm();

    if (this.isPlatformAdmin) {
      this.resetCompanyAgency();
      this.myNgForm.resetForm();
    }

    this.searchText = '';
    setTimeout(() => {
      this.loadAllHolidayList();
    }, 1000);
    this.table.reset();
  }

  onDeleteHoliday(event: Event, holiday: AgencyHoliday) {
    this.currentHoliday = holiday; // set the current holiday
    this.displayDialog = true;
    this.msgs = [];

  }


  onEditHoliday(event: Event, holiday: any) {
    this.formMode = 'update';

    this.edittingHoliday = _.cloneDeep(holiday);
    if (this.isPlatformAdmin) {
      this.companySelects = holiday.companyId;
      this.companyName = this.companySelects.name;
      this.companyId = holiday.companyId;
      this.holidayDate = this.setDate(holiday.holidayDate);
    }
    this.initHolidayForm(holiday);
  }

  setDate(holidayDate: any) {
    if (holidayDate === undefined) {
      return new Date();
    }
    const date = MOMENT.utc(holidayDate).format('MM-DD-YYYY');
    return new Date(date);
  }

  resetCompanyAgency() {
    this.companySelects = this.companyId;
    // this.companyId = null;
    this.agency = null;
  }

  addHoliday() {
    this.addHolidayModal = true;
  }
  closeHoliday() {
    this.addHolidayModal = false;
  }


  onConfirm() {
    this.operationalPolicyService.deleteRecord(this.currentHoliday.id).subscribe((res: any) => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        // this.resetCompanyAgency();
        this.loadAllHolidayList();
        this.myNgForm.resetForm();
        this.messageService.add({
          severity: 'info',
          summary: this.translatePipe.transform('Deleted'), detail: this.translatePipe.transform('Policy deleted successfully')
        });
      } else {
        this.loadAllHolidayList();
        this.myNgForm.resetForm();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.translatePipe.transform(resObj.message) });
      }
    }, error => {
      const resObj: any = error;
      // this.resetCompanyAgency();
      this.loadAllHolidayList();
      this.myNgForm.resetForm();
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform(error), detail: this.translatePipe.transform(resObj.message) });
    });
    this.closeDialog();
  }

  onReject() {
    // Your rejection logic here...
    this.closeDialog();
  }

  closeDialog() {
    this.displayDialog = false;
  }

  // exportPdf() {
  //     let searchCriteria = _.cloneDeep(this.holidaySearchCriteria);
  //     searchCriteria.page = 0;
  //     searchCriteria.size = 9999;
  //     this.companyHolidayService.searchHoliday(searchCriteria).subscribe(
  //         (data: any) => {
  //         let planList = data.data.content;
  //         if (planList && planList.length) {
  //             const data: any = {
  //                 contentList: planList.map(value => {
  //                     return {
  //                         id: value.id,
  //                         holidayName: value.holidayName,
  //                         holidayDate: this.datePipe.transform(this.setDate(value.holidayDate), 'yyyy MMMM d'),
  //                         addedDate: this.datePipe.transform(value.createdAt, 'MM/dd/yyyy hh:mm a'),
  //                         status: value.status,
  //                     };
  //                 })
  //             };
  //             data.companyId = this.authService.getCurrentCompany();
  //             data.username = this.authService.getCurrentUsername();
  //             data.reportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy EEE hh:mm a');
  //             this.companyHolidayService.exportPdf(data).subscribe(resExport => {
  //                 if (resExport.data) {
  //                     const blob = FileUtility.b64toBlob(resExport.data.body, 'application/pdf');
  //                     const blobUrl = URL.createObjectURL(blob);
  //                     window.open(blobUrl, '_blank');
  //                 } else {
  //                     this.messageService.add({
  //                         severity: 'error',
  //                         summary: this.translatePipe.transform('Error'),
  //                         detail: this.translatePipe.transform('Export pdf fail')
  //                     });
  //                 }
  //             }, () => {
  //                 this.messageService.add({
  //                     severity: 'error',
  //                     summary: this.translatePipe.transform('Error'),
  //                     detail: this.translatePipe.transform('Export pdf fail')
  //                 });
  //             });
  //         }
  //     }, () => {
  //         this.messageService.add({
  //             severity: 'error',
  //             summary: this.translatePipe.transform('Error'),
  //             detail: this.translatePipe.transform('Export pdf fail')
  //         });
  //     });
  // }
  validateName() {
    const check = this.holidayList.find(item => item.name === this.edittingHoliday.name && item.id != this.edittingHoliday.id);
    this.checkValidateName = check ? true : false;
  }
}
