<p-toast position="bottom-right" class="concern"></p-toast>
<p-tabMenu [model]="tabMenuItems" [activeItem]="activeItem">
  <ng-template pTemplate="item" let-item let-i="index">
    <div *ngIf="item.visible" (click)="onItemClick($event, item)">
      <span class="text-nowrap"><i *ngIf="item.iconBeforeClass" [ngClass]="item.iconBeforeClass"></i> {{item.label | translate}} <i *ngIf="item.iconClass" [ngClass]="item.iconClass"></i></span>
    </div>
  </ng-template>
</p-tabMenu>
<div class="ui-g" *ngIf="customTabMenu && !customTabMenu.disabledRouterOutlet">
  <div class="ui-g-12 p-0">
    <div class="card add-shadow p-4 m-0">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
