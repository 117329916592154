<h1 class="m-0 ml-2 my-4">{{'Service Revenue' | translate}}</h1>
<p-panel [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i>{{'Find Revenue Data' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin">
			<label>{{'companyLabel' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-building"></i>
					</button>
				</div>
				<p-dropdown [options]="allCompanyList" [filter]="true" optionLabel="value" dataKey="key" [ngModelOptions]="{'standalone':true}" placeholder="{{'Select One' | translate}}" [(ngModel)]="selectedCompany" (onChange)="changeCompany()" >
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Account' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-folder-open"></i>
					</button>
				</div>
		  		<p-dropdown [options]="accounts" [style]="{'flex-grow': '1'}" placeholder="{{'Select One' | translate}}" [ngModelOptions]="{'standalone':true}" [(ngModel)]="selectedAccount">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Client' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-briefcase"></i>
					</button>
				</div>
				<p-dropdown [options]="allClientList" optionLabel="value" dataKey="value" filter="true" [ngModelOptions]="{'standalone':true}" [(ngModel)]="selectedClient" [style]="{'flex-grow': '1'}" placeholder="{{'Select One' | translate}}">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'dateRange' | translate}}: </label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-week"></i>
					</button>
				</div>
				<mat-form-field class="w-100">
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate matInput [(ngModel)]="fromDate" name="Startdate" (focus)="picker.open()">
						<input matEndDate matInput [(ngModel)]="toDate" name="Enddate" (focus)="picker.open()">
					</mat-date-range-input>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
		</div>
		<div class="ui-g-12 py-4 text-center">
			<button type="button" class="btn btn-danger mx-1" (click)="reset()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
			<button type="button" class="btn btn-primary mx-1" (click)="search()"><i class="fa-solid fa-check mr-2"></i>{{'button.search' | translate}}</button>
		</div>
	</div>
</p-panel>
<div class="d-flex flex-wrap align-items-center justify-content-between py-4" style="row-gap: 10px">
	<h1 class="m-0 ml-2 mr-4">{{'Browse Revenue' | translate}}</h1>
	<div class="d-flex flex-wrap justify-content-between justify-content-sm-start align-items-center pl-2" style="column-gap: 40px;">
		<div class="flex-nowrap"><span class="text-nowrap" style="font-weight: 500;"><i class="fa-solid fa-file-invoice-dollar text-danger mr-2"></i>{{'Invoice A/R' | translate}}: </span><span>{{totalInvoice | currency : 'USD':'symbol':'1.2-2'}}</span></div>
		<div class="flex-nowrap"><span class="text-nowrap" style="font-weight: 500;"><i class="fa-solid fa-sack-dollar text-primary mr-2"></i>{{'Revenue' | translate}}: </span><span>{{totalServiceFee | currency : 'USD':'symbol':'1.2-2'}}</span></div>
		<div class="flex-nowrap"><span class="text-nowrap" style="font-weight: 500;"><i class="fa-solid fa-hand-holding-dollar text-success mr-2"></i>{{'Payments' | translate}}: </span> <span>{{totalPayment | currency : 'USD':'symbol':'1.2-2'}}</span></div>
	</div>
</div>
<p-panel id="table-ppanel" [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Service Revenue' | translate}}  ({{totalRecords | number }})</span>
	</p-header>
	<p-table #dt class="para-table-text" selectionMode="single" [columns]="cols" [value]="insuranceList" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10, 25, 50]" [resizableColumns]="true" dataKey="id" (onLazyLoad)="loadRevenueLazy($event)" [totalRecords]="totalRecords" [lazy]="false" [responsive]="true">

		<ng-template pTemplate="caption">
			<div class="d-flex align-items-center justify-content-between">
				<div class="d-flex align-items-center">
					<button type="button" (click)="exportPdf();" class="btn btn-primary mx-1">
						<i class="fa-solid fa-print mr-2"></i> {{'Export PDF' | translate}}
					</button>
				</div>
				<div class="input-group w-100 mr-2" style="max-width: 450px">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" type="button" style="cursor:default !important">
							<i class="fa-solid fa-filter"></i>
						</button>
					</div>
					<input pInputText type="text" size="35" [(ngModel)]="filterText" (input)="customFilter($event)" placeholder="{{'Filter' | translate}}">
				</div>
			</div>
		</ng-template>

		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngFor="let col of columns" width="{{col?.width}}">
					<ng-container [ngSwitch]="col.field">
						<span *ngSwitchDefault>
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field;setSortOption(col.field, col.sort)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>
	
						<span *ngSwitchCase="'invoiceDate'">
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op3.toggle($event) : op1.toggle($event); selectedField = col.field;setSortOption(col.field, col.sort)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>

						<span *ngSwitchCase="'confirmedByUsr'">
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op2.toggle($event) : op4.toggle($event); selectedField = col.field;setSortOption(col.field, col.sort)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>

						<span *ngSwitchCase="'approvedByUsr'">
							<div class="d-flex align-items-center justify-content-between w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op2.toggle($event) : op5.toggle($event); selectedField = col.field;setSortOption(col.field, col.sort)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</div>
						</span>
					</ng-container>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData let-i="rowIndex">
			<tr *ngIf="!loading" [pSelectableRow]="rowData">
				<td>
					{{i+1}}
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Invoiced' | translate}}: </span>
						<span *ngIf="rowData.invoiceDate" pTooltip="{{rowData?.invoiceDate | date:'MM/dd/yyyy hh:mm a'}}">{{rowData?.invoiceDate | date:'MM/dd/yyyy hh:mm a'}}</span>
						<span *ngIf="!rowData.invoiceDate" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Type' | translate}}: </span>
						<span *ngIf="rowData.transactionType" pTooltip="{{ rowData.transactionType }}">{{ rowData.transactionType }}</span>
						<span *ngIf="!rowData.transactionType" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Client' | translate}}: </span>
						<span *ngIf="rowData.clientName" pTooltip="{{ rowData.clientName }}">{{ rowData.clientName }}</span>
						<span *ngIf="!rowData.clientName" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Account' | translate}}: </span>
						<span *ngIf="rowData.account" pTooltip="{{ rowData.account }}">{{ rowData.account }}</span>
						<span *ngIf="!rowData.account" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>           
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Debit' | translate}}: </span>
						<span *ngIf="rowData.debitAmount" pTooltip="{{rowData?.debitAmount | currency : 'USD':'symbol':'1.2-2'}}">{{rowData?.debitAmount | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!rowData.debitAmount" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Credit' | translate}}: </span>
						<span *ngIf="rowData.creditAmount" pTooltip="{{rowData?.creditAmount | currency : 'USD':'symbol':'1.2-2'}}">{{rowData?.creditAmount | currency : 'USD':'symbol':'1.2-2'}}</span>
						<span *ngIf="!rowData.creditAmount" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td pEditableColumn>
					<span class="one-liner" style="width: unset !important;max-height: 40px !important">
						<p-cellEditor class="w-100">
							<ng-template pTemplate="input">
								<span class="d-flex align-items-center">
									<span class="mobile-table-label text-nowrap">{{'Memo' | translate}}: </span>
									<input pInputText type="text" [(ngModel)]="rowData.memo" (keydown.enter)="updateMemo(rowData)" (blur)="updateMemo(rowData)" class="flex-grow-1 editableCell">   
								</span>
							</ng-template>
							<ng-template pTemplate="output">
								<span class="d-flex text-nowrap">
									<span class="mobile-table-label">{{'Memo' | translate}}: </span>
									<span *ngIf="rowData.memo" class="one-liner flex-grow-1">{{rowData.memo}}</span>
									<span *ngIf="!rowData.memo" class="one-liner text-muted flex-grow-1">{{'no data' | translate}}</span>
									<i class="fa-solid fa-pen-to-square text-muted float-right ml-2" pTooltip="{{'Edit' | translate}}" tooltipPosition="left"></i>
								</span>
							</ng-template>
						</p-cellEditor>
					</span>
				</td>
				<td *ngIf="rowData?.confirmedByUsr">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Confirmed' | translate}}: </span><i class="fa-solid fa-circle-check text-success mr-1"></i>
						<span pTooltip="{{rowData?.confirmedByUsr}}">{{rowData?.confirmedByUsr}}</span>
					</span>
				</td>
				<td *ngIf="!rowData?.confirmedByUsr">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Confirmation' | translate}}: </span><i class="fa-solid fa-circle-check text-primary mr-1"></i><span (click)="approveByUsr(rowData, 1)" class="text-dark">{{'Confirm' | translate}}</span>
					</span>
				</td>
				<td *ngIf="rowData?.approvedByUsr">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Approved' | translate}}: </span><i class="fa-solid fa-circle-check text-success mr-1"></i>
						<span *ngIf="rowData.approvedByUsr" pTooltip="{{rowData?.approvedByUsr}}">{{rowData?.approvedByUsr}}</span>
					</span>
				</td>
				<td *ngIf="!rowData?.approvedByUsr">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Approval' | translate}}: </span><i class="fa-solid fa-circle-check text-primary mr-1"></i><span [routerLink]="[]" (click)="approveByUsr(rowData, 2)" class="text-dark">{{'Approve' | translate}}</span>
					</span>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading">
				<td [attr.colspan]="cols?.length" class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="loading">
				<td [attr.colspan]="cols?.length" class="text-center py-5">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>


<p-overlayPanel #op1 appendTo="body">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op3 appendTo="body">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op4 appendTo="body">
	<p-listbox [options]="confirmSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op5 appendTo="body">
	<p-listbox [options]="approveSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
