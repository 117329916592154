<div class="card add-shadow p-4 mb-0">

	<div class="d-flex align-items-center justify-content-between pt-4 pb-2 px-4"  style="row-gap: 10px;padding: 15px !important">
		<h1 class="m-0 ml-2" style="font-size: 30px !important">{{'Dashboard' | translate}}</h1>
		<button class="btn btn-white btn-sm mx-1" (click)="triggerLogOut()" pTooltip="{{'Log Out' | translate}}" tooltipPosition="left" style="border-radius:25px"><i class="fa-solid fa-power-off text-danger"></i></button>
	</div>

	<div class="ui-g">	
		<div class="ui-g-4 ui-sm-12" style="padding: 15px !important">
			<div class="card add-shadow text-left p-0">
				<div class="card-header">
					<h5 class="mb-0 text-truncate"><i class="fa-solid fa-clock text-primary mr-2"></i>{{'Punches' | translate}}</h5>
				</div>
				<div *ngIf="!loadingLateness && !loadingPunchStatisticNew" class="p-3" style="font-size: 16px">
					<div class="py-1" (click)="openOutForAbsentDialog()" style="cursor:pointer">
						<i class="fa-solid fa-calendar-xmark text-danger mr-2"></i>
						<span><b>{{'Absent' | translate}}</b> </span>
						<span class="pull-right"><span class="badge" [ngClass]="employeeDisplayClockingAbsent ? 'badge-danger' : 'badge-muted'">{{employeeDisplayClockingAbsent ? employeeDisplayClockingAbsent.length : 0}}</span><i class="fa-solid fa-up-right-from-square ml-2" [ngClass]="employeeDisplayClockingAbsent ? 'text-primary' : 'text-muted'"></i></span>
					</div>
					<div class="py-1 border-top" (click)="openLateness()" style="cursor:pointer">
						<i class="fa-solid fa-clock text-orange mr-2"></i>
						<span><b>{{'Late' | translate}}</b> </span>
						<span class="pull-right"><span class="badge" [ngClass]="totalLateness > 0 ? 'badge-orange' : 'badge-muted'">{{(totalLateness | number) || 0}}</span><i class="fa-solid fa-up-right-from-square ml-2" [ngClass]="totalLateness > 0 ? 'text-primary' : 'text-muted'"></i></span>
					</div>
					<div class="py-1 border-top" (click)="openOutForLunchDialog()" style="cursor:pointer">
						<i class="fa-solid fa-utensils text-primary mr-2"></i>
						<span><b>{{'Break' | translate}}</b> </span>
						<span class="pull-right"><span class="badge" [ngClass]="totalOutForLunch > 0 ? 'badge-primary' : 'badge-muted'">{{(totalOutForLunch | number) || 0}}</span><i class="fa-solid fa-up-right-from-square ml-2" [ngClass]="totalOutForLunch > 0 ? 'text-primary' : 'text-muted'"></i></span>
					</div>
					<div class="py-1 border-top" (click)="openNotLunchDialog()" style="cursor:pointer">
						<i class="fa-solid fa-triangle-exclamation text-danger mr-2"></i>
						<span><b>{{'Missed Break' | translate}}</b> </span>
						<span class="pull-right"><span class="badge" [ngClass]="totalNotLunch > 0 ? 'badge-danger' : 'badge-muted'">{{(totalNotLunch | number) || 0}}</span><i class="fa-solid fa-up-right-from-square ml-2" [ngClass]="totalNotLunch > 0 ? 'text-primary' : 'text-muted'"></i></span>
					</div>
					<div class="py-1 border-top" (click)="openOutForClockInDialog()" style="cursor:pointer">
						<i class="fa-solid fa-circle-check text-success mr-2"></i>
						<span><b>{{'Working' | translate}}</b> </span>
						<span class="pull-right"><span class="badge" [ngClass]="totalClockedIn > 0 ? 'badge-success' : 'badge-muted'">{{(totalClockedIn | number) || 0}}</span><i class="fa-solid fa-up-right-from-square ml-2" [ngClass]="totalClockedIn > 0 ? 'text-primary' : 'text-muted'"></i></span>
					</div>
				</div>
				<div *ngIf="loadingLateness || loadingPunchStatisticNew" class="d-flex align-items-center justify-content-center" style="font-size:16px;height: 196px">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</div>
				<div class="card-footer">
					<button type="button" class="btn btn-white btn-sm mr-2">
					  <span class="text-nowrap"><i class="fa-solid fa-refresh text-primary"></i></span>
					</button>
				</div>
			</div>
		</div>
		<div class="ui-g-4 ui-sm-12" style="padding: 15px !important">
			<div class="card add-shadow text-left p-0">
				<div class="card-header">
					<h5 class="mb-0 text-truncate"><i class="fa-solid fa-database text-primary mr-2"></i>{{'Other Metrics' | translate}}</h5>
				</div>
				<div *ngIf="!loadingLateness && !loadingPunchStatisticNew"  class="p-3" style="font-size: 16px">
					<div class="py-1" (click)="openOutForEmployeeOnlineDialog()" style="cursor:pointer">
						<i class="fa-solid fa-computer text-primary mr-2"></i>
						<span><b>{{'dashboard.currentUser' | translate}}</b> </span>
						<span class="pull-right"><span class="badge" [ngClass]="numOfCurrentLoginUser > 0 ? 'badge-primary' : 'badge-muted'">{{numOfCurrentLoginUser || 0}}</span><i class="fa-solid fa-up-right-from-square ml-2" [ngClass]="numOfCurrentLoginUser > 0 ? 'text-primary' : 'text-muted'"></i></span>
					</div>

					<div *ngIf="!isCEOAdmin() && !isEmployee" class="py-1 border-top" [routerLink]="['/app/employee/list']" [queryParams]="{ 'filter': 'expected' }" style="cursor:pointer">
						<i class="fa-solid fa-users text-info mr-2"></i>
						<span><b>{{'Employees Expected' | translate}}</b> </span>
						<span class="pull-right"><span class="badge" [ngClass]="expectedClockedIn > 0 ? 'badge-info' : 'badge-muted'"><span>{{expectedClockedIn || 0}}</span></span><i class="fa-solid fa-chevron-right ml-2" [ngClass]="expectedClockedIn > 0 ? 'text-primary' : 'text-muted'"></i></span>
					</div>
					<div *ngIf="!isCEOAdmin() && isEmployee" class="py-1 border-top" style="cursor:default">
						<i class="fa-solid fa-users text-info mr-2"></i>
						<span><b>{{'Employees Expected' | translate}}</b> </span>
						<span class="pull-right"><span class="badge" [ngClass]="expectedClockedIn > 0 ? 'badge-info' : 'badge-muted'"><span>{{expectedClockedIn || 0}}</span></span><i class="fa-solid fa-chevron-right ml-2" [ngClass]="expectedClockedIn > 0 ? 'text-primary' : 'text-muted'"></i></span>
					</div>

					<div class="py-1 border-top" (click)="gotoPage('task_management', 'MY_BOARDS')" style="cursor:pointer">
						<i class="fa-solid fa-blackboard text-purple mr-2"></i>
						<span><b>{{'My Task Boards' | translate}}</b> </span>
						<span class="pull-right"><span class="badge" [ngClass]="myTaskNumber > 0 ? 'badge-purple' : 'badge-muted'">{{myTaskNumber || 0}}</span><i class="fa-solid fa-chevron-right ml-2" [ngClass]="myTaskNumber > 0 ? 'text-primary' : 'text-muted'"></i></span>
					</div>

					<div class="py-1 border-top" (click)="gotoPage('task_management', 'GUEST_BOARDS')" style="cursor:pointer">
						<i class="fa-solid fa-chalkboard-teacher text-primary mr-2"></i>
						<span><b>{{'Guest Boards' | translate}}</b> </span>
						<span class="pull-right"><span class="badge" [ngClass]="guestTaskNumber > 0 ? 'badge-primary' : 'badge-muted'">{{guestTaskNumber || 0}}</span><i class="fa-solid fa-chevron-right ml-2" [ngClass]="guestTaskNumber > 0 ? 'text-primary' : 'text-muted'"></i></span>
					</div>

					<div *ngIf="!isCEOAdmin()" class="py-1 border-top" (click)="gotoPage('task_assignment')" style="cursor:pointer">
						<i class="fa-solid fa-clipboard-list text-primary mr-2" style="width: 20px"></i>
						<span><b>{{'Work Orders' | translate}}</b> </span>
						<span class="pull-right"><span class="badge" [ngClass]="totalAssignments > 0 ? 'badge-primary' : 'badge-muted'">{{totalAssignments || 0}}</span><i class="fa-solid fa-chevron-right ml-2" [ngClass]="totalAssignments > 0 ? 'text-primary' : 'text-muted'"></i></span>
					</div>

					<div *ngIf="isCEOAdmin()" class="py-1 border-top" style="cursor:default">
						<i class="fa-solid fa-dollar-sign text-success mr-2" style="width: 20px"></i>
						<span><b>{{'Payments' | translate}}</b> </span>
						<span class="pull-right"><span class="badge" [ngClass]="totalPayment > 0 ? 'badge-success' : 'badge-muted'">{{totalPayment || 0}}</span><i class="fa-solid fa-up-right-from-square ml-2" [ngClass]="totalPayment > 0 ? 'text-muted' : 'text-muted'"></i></span>
					</div>
					<div *ngIf="isCEOAdmin() && !isEmployee" [routerLink]="['/app/crm/client/list']" class="py-1 border-top" style="cursor:pointer">
						<i class="fa-solid fa-birefcase text-primary mr-2" style="width: 20px"></i>
						<span><b>{{'Clients' | translate}}</b> </span>
						<span class="pull-right"><span class="badge" [ngClass]="clientsCount > 0 ? 'badge-primary' : 'badge-muted'">{{clientsCount || 0}}</span><i class="fa-solid fa-chevron-right ml-2" [ngClass]="clientsCount > 0 ? 'text-muted' : 'text-muted'"></i></span>
					</div>
					<div *ngIf="isCEOAdmin() && isEmployee" class="py-1 border-top" style="cursor:default">
						<i class="fa-solid fa-birefcase text-primary mr-2" style="width: 20px"></i>
						<span><b>{{'Clients' | translate}}</b> </span>
						<span class="pull-right"><span class="badge" [ngClass]="clientsCount > 0 ? 'badge-primary' : 'badge-muted'">{{clientsCount || 0}}</span><i class="fa-solid fa-up-right-from-square ml-2" [ngClass]="clientsCount > 0 ? 'text-muted' : 'text-muted'"></i></span>
					</div>
				</div>
				<div *ngIf="loadingLateness || loadingPunchStatisticNew" class="d-flex align-items-center justify-content-center" style="font-size:16px;height: 196px">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</div>
				<div class="card-footer">
					<button type="button" class="btn btn-white btn-sm mr-2">
					  <span class="text-nowrap"><i class="fa-solid fa-refresh text-primary"></i></span>
					</button>
				</div>
			</div>
		</div>
		<div class="ui-g-4 ui-sm-12" style="padding: 15px !important">
			<div class="card add-shadow text-left p-0">
				<div class="card-header">
					<div class="d-flex align-items-center justify-content-between">
						<h5 class="mb-0 text-truncate"><span class="badge m-0 mr-2" [ngClass]="concernList?.length > 0 ? 'badge-orange' : 'badge-muted'">{{(concernList?.length | number) || 0}}</span>{{'Concerns' | translate}} </h5>
					</div>
				</div>
				<div *ngIf="!loadingConcerns && concernList.length > 0" class="p-0 bg-light" style="font-size: 16px;height: 195px;">
					<ng-container *ngIf="!loadingConcerns && concernList.length > 0">
						<ng-container *ngFor="let rowData of paginatedConcerns">
							<div class="d-flex align-items-center justify-content-between bg-white px-3 py-2 border-bottom" (click)="loadConcern2(rowData)" id="concernsItem" style="cursor:pointer">
								<span class="d-flex align-items-center justify-content-start text-truncate">
									<i class="fa-solid fa-circle-exclamation text-orange mr-2" style="font-size: 22px"></i>
									<div class="flex-grow-1 text-truncate" style="line-height: 1.15">
										<div class="text-muted text-nowrap text-truncate ml-2" style="font-size: 12px">{{rowData.scheduleTo || 'Untitled'}}</div>
										<div class="m-0 ml-2 text-truncate">{{ rowData.concern }}</div>
									</div>
								</span>
								<div class="text-muted text-nowrap ml-2" style="font-size: 10px">{{rowData.createdAt | date: 'M/d/yyy h:mm a'}}</div>
							</div>
						</ng-container>
					</ng-container>
				</div>
				<div *ngIf="!loadingConcerns && concernList.length === 0" class="p-0 bg-light d-flex align-items-center justify-content-center" style="font-size: 16px;height: 195px;">
					<span class="text-muted text-truncate">{{'No concerns' | translate}}</span>
				</div>
				<div *ngIf="loadingConcerns" class="d-flex bg-light align-items-center justify-content-center" style="font-size:16px;height: 196px">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</div>
				<div class="card-footer d-flex justify-content-between">
					<button (click)="concernPrevPage()" [disabled]="concernCurrentPage === 0" type="button" class="btn btn-white btn-sm mx-1">
						<span class="text-nowrap"><i class="fa-solid fa-chevron-left text-primary mr-2"></i>{{'Prev' | translate}}</span>
					</button>
					<button (click)="concernNextPage()" [disabled]="(concernCurrentPage + 1) * concernPageSize >= concernList.length" type="button" class="btn btn-white btn-sm mx-1">
						<span class="text-nowrap">{{'Next' | translate}}<i class="fa-solid fa-chevron-right text-primary ml-2"></i></span>
					</button>
				</div>
			</div>
		</div>
		<div class="ui-xl-4 ui-md-6 ui-sm-12" style="padding: 15px !important">
			<div class="card add-shadow text-left p-0">
				<div class="card-header">
					<div class="d-flex align-items-center justify-content-between">
						<h5 class="mb-0 text-truncate"><i class="fa-solid fa-chart-pie text-primary mr-2"></i>{{'Staff Compliance' | translate}} </h5>
					</div>
				</div>
				<div class="chartStyle" [ngStyle]="{'max-height': isCEOAdmin() ? '327px' : '327px'}">

					<div class="d-flex align-items-center flex-wrap p-2 border-bottom" style="row-gap: 10px">
						<!--
						<button class="btn btn-white text-nowrap mx-1">
							<span>
								<span class="badge badge-primary mr-2">{{statisticEmployee.count || 0}}</span>{{'Employees' | translate}}
								<span style="font-size: 10px" class="text-info text-center" *ngIf="statisticEmployee.increase > statisticEmployee.decrease">
									<span class="increase">
										<i class="fa-solid fa-caret-up mr-1"></i>{{employeePercent(true)}}%
									</span>
								</span>
								<span style="font-size: 10px" class="text-center" *ngIf="statisticEmployee.increase < statisticEmployee.decrease">
									<span class="decrease">
										<i class="fa-solid fa-caret-down mr-1"></i>{{employeePercent()}}%
									</span>
								</span>
							</span>
						</button>
						-->
						<button class="btn btn-white btn-sm text-nowrap mx-1" (click)="complianceScores = true">
							<span>
								<span class="badge badge-success mr-2">{{statisticEmployeeCompliance.count || 0}}</span>{{'Compliant' | translate}}
								<span style="font-size: 10px" class="text-info text-center" *ngIf="statisticEmployeeCompliance.increase < statisticEmployeeCompliance.decrease">
									<span class="increase">
										<i class="fa-solid fa-caret-up mr-1"></i>{{employeeNonCompliancePercent(true)}}%
									</span>
								</span>
								<span style="font-size: 10px" class="text-center" *ngIf="statisticEmployeeCompliance.increase > statisticEmployeeCompliance.decrease">
									<span class="decrease">
										<i class="fa-solid fa-caret-down mr-1"></i>{{employeeNonCompliancePercent()}}%
									</span>
								</span>
							</span>
							<i class="fa-solid fa-chevron-down text-muted ml-2"></i>
						</button>
						<button class="btn btn-white btn-sm text-nowrap mx-1" (click)="complianceScores = false">
							<span>
								<span class="badge badge-danger mr-2">{{statisticEmployee.count ? (statisticEmployee.count - statisticEmployeeCompliance.count) : 0}}</span>{{'Not Compliant' | translate}}
								<span style="font-size: 10px" class="text-info text-center" *ngIf="statisticEmployeeCompliance.increase > statisticEmployeeCompliance.decrease">
									<span class="increase">
										<i class="fa-solid fa-caret-up mr-1"></i>{{employeeCompliancePercent(true)}}%
									</span> 
								</span>
								<span style="font-size: 10px" class="text-center" *ngIf="statisticEmployeeCompliance.increase < statisticEmployeeCompliance.decrease">
									<span class="decrease">
										<i class="fa-solid fa-caret-down mr-1"></i>{{employeeCompliancePercent()}}%
									</span>
								</span>
							</span>
							<i class="fa-solid fa-chevron-down text-muted ml-2"></i>
						</button>
					</div>

					<div *ngIf="complianceScores" class="d-flex align-items-center justify-content-center flex-wrap p-2">
						<p-chart type="doughnut" [height]="265" [width]="330" [data]="complianceData" [options]="complianceOptions" *ngIf="showChart"></p-chart>
						<div style="font-size: 11px" class="text-info text-center" *ngIf="statisticEmployeeCompliance.increase > statisticEmployeeCompliance.decrease">
							<div class="increase">
								<i class="fa-solid fa-caret-up mr-1"></i>{{increaseCompianceScore(true)}}
							</div>
						</div>
						<div style="font-size: 11px" class="text-center" *ngIf="statisticEmployeeCompliance.increase < statisticEmployeeCompliance.decrease">
							<div class="decrease">
								<i class="fa-solid fa-caret-down mr-1"></i>{{increaseCompianceScore()}}
							</div>
						</div>
					</div>
					<div *ngIf="!complianceScores" class="d-flex align-items-start justify-content-center" id="complianceChartContainer">
						<div>
							<div class="w-100 text-center my-3" style="font-size: 18px">Compliance Issues <span class="font-weight-bold">{{totalDocument || 0}}</span></div>
							<p-chart type="doughnut" height="195" [data]="mandatoryData" [options]="mandatoryOptions"></p-chart>
						</div>
	
						<div class="d-flex flex-grow-1 flex-column px-4 border-left" id="chartKey">
							<div class="w-100 my-3" style="font-size: 18px">Chart Items</div>
							<div *ngFor="let com of compliances" class="d-flex align-items-center justify-content-between">
								<div class="d-flex flex-grow-1 align-items-center" id="chatKeyItemText">
									<span [ngStyle]="{'background': (com.color || '#f9f9f9'),  height: '10px', width: '10px', 'border-radius':'5px'}" class="mr-1"></span>
									<span class="font-weight-bold">{{com.count || 0}}</span>
									<div class="text-truncate ml-1">{{com.name}}</div>
								</div>
								<div style="font-size: 11px" class="text-info text-center" *ngIf="com.increase > com.decrease">
									<div class="increase">
										<i class="fa-solid fa-caret-up mr-1"></i>{{increaseCompianceDocument(com, true)}}%
									</div>
								</div>
								<div style="font-size: 11px" class="text-center" *ngIf="com.increase < com.decrease">
									<div class="decrease">
										<i class="fa-solid fa-caret-down mr-1"></i>{{increaseCompianceDocument(com)}}%
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<button type="button" class="btn btn-white btn-sm mr-2" (click)="complianceScores = true">
						<span class="text-nowrap">
							<i *ngIf="!complianceScores" class="fa-solid fa-list text-primary mr-2"></i>
							<i *ngIf="complianceScores" class="fa-solid fa-circle-check text-success mr-2"></i>
							Overview
							<i *ngIf="!complianceScores" class="fa-solid fa-up-right-from-square text-primary ml-2"></i>
						</span>
					  </button>
					<button type="button" class="btn btn-white btn-sm mr-2" (click)="complianceScores = false">
						<span class="text-nowrap">
							<i *ngIf="complianceScores" class="fa-solid fa-triangle-exclamation text-primary mr-2"></i>
							<i *ngIf="!complianceScores" class="fa-solid fa-circle-check text-success mr-2"></i>
							Issues
							<i *ngIf="complianceScores" class="fa-solid fa-up-right-from-square text-primary ml-2"></i>
						</span>
					</button>
				</div>
			</div>
		</div>
		<div class="ui-xl-4 ui-md-6 ui-sm-12" style="padding: 15px !important">
			<div class="card add-shadow text-left p-0">
				<div class="card-header">
					<div class="d-flex align-items-center justify-content-between">
						<h5 class="mb-0 text-truncate"><i class="fa-solid fa-magnifying-glass-location text-primary mr-2"></i>{{'User Activity' | translate}} </h5>
					</div>
				</div>
				<div class="p-0 bg-light userActivitySection" style="font-size: 16px;">
					<ng-container *ngIf="userActivities?.length > 0 && !loadingLateness">
						<ng-container *ngFor="let activity of userActivities">
							<div (click)="onTopbarMenuProfileClick(activity)" class="d-flex align-items-center justify-content-between bg-white px-3 py-2 border-bottom" id="concernsItem" style="cursor:pointer">
								<span class="d-flex align-items-center justify-content-start text-truncate">
									<img *ngIf="activity.avatar" class="avatar" src="{{activity?.avatar}}"/>
									<i *ngIf="!activity.avatar"  class="fa-solid fa-circle-user text-muted mr-2" style="width:40px;font-size: 40px"></i>
									<div class="flex-grow-1 text-truncate" style="line-height: 1.2">
										<div class="text-muted text-nowrap text-truncate ml-2" style="font-size: 12px">{{activity.username || 'Untitled'}}</div>
										<div class="m-0 ml-2 text-truncate">{{activity.loginStatus | translate}}</div>
									</div>						
								</span>
								<div class="text-muted text-nowrap ml-2" style="font-size: 12px">{{getTimeDifference(activity?.createdAt)}}<i class="fa-solid fa-circle-info text-primary ml-2" pTooltip="{{activity.createdAt | date: 'M/d/yyy h:mm a'}}" tooltipPosition="left"></i></div>
							</div>						
						</ng-container>
					</ng-container>
					<div *ngIf="(!userActivities || userActivities.length === 0) && !loadingLateness" class="d-flex align-items-center justify-content-center bg-light py-5" style="font-size: 16px;min-height: 354px">
						<div class="text-muted my-5">{{'No reports found' | translate}}</div>
					  </div>
					  <div *ngIf="loadingLateness" class="d-flex align-items-center justify-content-center bg-light py-5" style="font-size: 16px;min-height: 354px">
						<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
					  </div>
				</div>
				<div class="card-footer d-flex justify-content-between">
					<button type="button" (click)="getData('less')" [disabled]="(userActivities && userActivities.length <= 10) || isEmployee" class="btn btn-white btn-sm"><i class="fa-solid fa-chevron-up text-danger mr-2"></i>{{'dashboard.user_activity_view_less' | translate}}</button>
					<button type="button" (click)="getData('more')" [disabled]="(userActivities && disableViewMore) || isEmployee" class="btn btn-white btn-sm"><i class="fa-solid fa-chevron-down text-primary mr-2"></i>{{'dashboard.user_activity_view_more' | translate}}</button>
				</div>
			</div>
		</div>
		<div class="ui-xl-4 ui-md-6 ui-sm-12" style="padding: 15px !important">
			<div class="card add-shadow text-left p-0">
				<div class="card-header">
					<div class="d-flex align-items-center justify-content-between">
						<h5 class="mb-0 text-truncate"><i class="fa-solid fa-chart-line text-primary mr-2"></i>{{'Overtime Usage' | translate}} </h5>
					</div>
				</div>
				<div class="chartStyle p-4" [ngStyle]="{'min-height': isCEOAdmin() ? '327px' : '327px'}">
					<p-chart *ngIf="tabShowChart == 1" type="line" height="279" [data]="chartData" responsive="true" [options]="chartOptions" (onDataSelect)="selectData($event)"></p-chart>
				</div>
				<div class="card-footer">
					<button type="button" class="btn btn-white btn-sm mr-2">
					  <span class="text-nowrap"><i class="fa-solid fa-refresh text-primary"></i></span>
					</button>
				</div>
			</div>
		</div>
		<div class="ui-xl-4 ui-md-6 ui-sm-12" style="padding: 15px !important">
			<div class="card add-shadow text-left p-0">
				<div class="card-header">
					<div class="d-flex align-items-center justify-content-between">
						<h5 (click)="gotoJobReport()" class="mb-0 text-truncate">
							<!--<span class="badge mr-2" [ngClass]="jobReports.length > 0 ? 'badge-primary' : 'badge-danger'">{{jobReports.length || 0 | number}}</span>-->
							{{'Latest Top 10 Job Reports' | translate}} </h5>
					</div>
				</div>
				<div *ngIf="(jobReports && (jobReports.length > 0)) && !loadingJobReports" class="p-0 bg-light" id="jobReportsList" style="font-size: 16px;">
					<ng-container *ngFor="let report of jobReports">
						<div class="d-flex align-items-center justify-content-between bg-white px-3 py-2 border-bottom" (click)="gotoDetailJobReport(report.id)" id="concernsItem" style="cursor:pointer">
							<span class="d-flex align-items-center flex-grow-1 justify-content-start text-truncate">
								<img *ngIf="report.avatar" class="avatar mr-2" src="{{report?.avatar}}"/>
								<div class="flex-grow-1 text-truncate" style="line-height: 1.2">
									<div class="text-muted text-nowrap" style="font-size: 12px" (click)="gotoJobReport(report?.userName);$event.stopPropagation()">{{report.fullName || 'no data'}}</div>
									<div class="m-0 text-truncate" id="jobReportItemText" [innerHTML]="report.latestNote" style="max-height: 1.15em"></div>
								</div>						
							</span>
							<div class="text-muted text-nowrap ml-2" style="font-size: 12px">{{report.time}}<i class="fa-solid fa-circle-info text-primary ml-2" pTooltip="{{report.createdAt | date: 'M/d/yyy h:mm a'}}" tooltipPosition="left"></i></div>
						</div>						
					</ng-container>
				</div>
				<div *ngIf="(!jobReports || (jobReports.length === 0)) && !loadingJobReports" class="d-flex align-items-center justify-content-center bg-light py-5" style="font-size: 16px;min-height: 326px">
					<div class="text-muted my-5">{{'No reports found' | translate}}</div>
				</div>
				<div *ngIf="loadingJobReports" class="d-flex align-items-center justify-content-center bg-light" style="height: 326px">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</div>
				<div class="card-footer">
					<button class="btn btn-white btn-sm mx-1" (click)="openJobReportSummaryDialog('miss')">
						<span class="text-nowrap">
							<span class="badge mr-1" [ngClass]="totalMissJobReport > 0 ? 'badge-orange' : 'badge-muted'">{{totalMissJobReport}}</span> {{'Missed' | translate}} 
						</span>
					</button>
					<button class="btn btn-white btn-sm mx-1" (click)="openJobReportSummaryDialog('limit')">
						<span class="text-nowrap">
							<span class="badge mr-1" [ngClass]="totalMissJobReport > 0 ? 'badge-danger' : 'badge-muted'">{{totalMissThanLimit}}</span> {{'Over Limit' | translate}}
						</span>
					</button>
					<button type="button" class="btn btn-white btn-sm mx-1" (click)="gotoJobReport()">
						<span class="text-nowrap">
							<i class="fa-solid fa-link text-primary mr-2"></i>View All<i class="fa-solid fa-chevron-right text-muted ml-2"></i>
						</span>
					</button>
				</div>
			</div>
		</div>
		<div *ngIf="!isCEOAdmin()"  class="ui-xl-4 ui-md-6 ui-sm-12" style="padding: 15px !important">
			<div class="card add-shadow text-left p-0">
				<div class="card-header">
					<div class="d-flex align-items-center justify-content-between">
						<h5 class="mb-0 text-truncate"><i class="fa-solid fa-ethernet text-primary mr-2"></i>{{'dashboard.daily_connectivity' | translate}} </h5>
					</div>
				</div>
				<div class="d-flex justify-content-center align-items-center p-4"  style="min-height:383px">
					<canvas id="connectivityCanvas"></canvas>
				</div>
			</div>
		</div>
		<div *ngIf="!isCEOAdmin()"  class="ui-xl-4 ui-md-6 ui-sm-12" style="padding: 15px !important">


			<div class="card add-shadow text-left p-0 mb-0">
				<div class="card-header">
					<div class="d-flex align-items-center justify-content-between">
						<h5 class="mb-0 text-truncate"><span class="badge mr-2" [ngClass]="holidays?.length + anniversaries?.length + birthdays?.length + absencesAprroved?.length + (isDelayedArrival ? 1 : 0) === 0 ? 'badge-muted' : 'badge-primary'">{{ holidays.length + anniversaries?.length + birthdays?.length + absencesAprroved?.length + (isDelayedArrival ? 1 : 0) }}</span>{{'Announcements' | translate}} </h5>
					</div>
				</div>
				<div class="p-0 bg-light" style="height:382px;min-height:382px;max-height:382px;">			
					<ng-container *ngIf="holidays?.length + anniversaries?.length + birthdays?.length + absencesAprroved?.length + (isDelayedArrival ? 1 : 0) > 0" >
						<div *ngFor="let rowData of holidays" class="bg-white d-flex align-items-center border-bottom p-3" style="height:60px;">
							<span class="d-flex align-items-center text-truncate"><i class="fa-solid fa-calendar-xmark text-primary mr-1"></i><b>{{'Holiday Alert' | translate}}: </b></span><span>{{rowData?.holidayName}} - {{rowData.holidayDate | date : 'MMMM dd'}}</span>
						</div>
						<div *ngFor="let ann of anniversaries; let index = index" class="bg-white border-bottom d-flex align-items-center p-3" style="height:60px;">
							<ng-container *ngIf="employeeId == ann.id; else anCoworker">
								<span class="d-flex align-items-center text-truncate"><i class="fa-solid fa-thumbs-up text-info fa-2x mr-2"></i>{{ 'CONGRATS_MESSAGE' | translate:{year: ann.year} }}</span>
							</ng-container>
							<ng-template #anCoworker>
								<span class="d-flex align-items-center text-truncate"><i class="fa-solid fa-thumbs-up text-info fa-2x mr-2"></i><span [innerHTML]="'CONGRATS_MESSAGE2' | translate:{name: ann.fullName, title: ann.title, year: ann.year}"></span></span>
							</ng-template>
						</div>
						<div *ngFor="let bd of birthdays; let idx = index" class="bg-white d-flex align-items-center border-bottom p-3" style="height:60px;">
							<ng-container *ngIf="employeeId == bd.id; else birthdayCoworker">
								<span class="d-flex align-items-center text-truncate"><i class="fa-solid fa-birthday-cake text-purple fa-2x mr-2"></i><b>{{bd.fullName}}</b> - {{'Happy Birthday To YOU!' | translate}}</span>
							</ng-container>
							<ng-template #birthdayCoworker>
								<span class="d-flex align-items-center text-truncate"><i class="fa-solid fa-birthday-cake text-purple fa-2x mr-2"></i><span [innerHTML]="'BIRTHDAY_MESSAGE' | translate:{name: bd.fullName}"></span></span>
							</ng-template>
						</div>
						<div *ngFor="let message of absencesAprroved; let indx = index" class="bg-white d-flex align-items-center border-bottom text-truncate p-3" style="height:60px;"> 
							<span class="d-flex align-items-center text-truncate"><i class="fa-solid fa-calendar-xmark text-orange fa-2x mr-2"></i><span [innerHTML]="message"></span></span>
						</div>
						<div *ngIf="isDelayedArrival" class="bg-white d-flex align-items-center border-bottom p-3" style="height:60px;"> 
							<span class="d-flex align-items-center"><i class="fa-solid fa-triangle-exclamation fa-2x text-danger mr-2"></i><span [innerHTML]="'DELAYED_ARRIVAL_MESSAGE' | translate:{name: fullName, time: delayedTimeStr, startWork: startWork}"></span></span>
						</div>	
					</ng-container>
					<div class="h-100 d-flex flex-column justify-content-center align-items-center ng-star-inserted" *ngIf="holidays?.length + anniversaries?.length + birthdays?.length + absencesAprroved?.length + (isDelayedArrival ? 1 : 0) === 0" style="font-size: 16px;height: 186px;overflow-y: auto;overflow-x: hidden">
						<i class="fa-solid fa-circle-check fa-3x text-muted"></i>
						<div class="text-muted mt-2">{{ 'No Announcements' | translate }}</div>
					</div>
				</div>
			</div>
			<!--
			<div class="card add-shadow text-left p-0">
				<div class="card-header">
					<div class="d-flex align-items-center justify-content-between">
						<h5 class="mb-0 text-truncate">
							<span class="badge mr-2" [ngClass]="(holidays.length + anniversaries?.length + birthdays?.length + absencesAprroved?.length + (isDelayedArrival ? 1 : 0)) === 0 ? 'badge-muted' : 'badge-primary'">{{ holidays.length + anniversaries?.length + birthdays?.length + absencesAprroved?.length + (isDelayedArrival ? 1 : 0) }}</span>
							{{'Announcements' | translate}} 
						</h5>
					</div>
				</div>
				<div *ngIf="holidays.length || ((anniversaries || birthdays || absencesAprroved || isDelayedArrival).length > 0)" class="p-0" style="min-height: 300px">
					<p-table *ngIf="holidays.length" [value]="holidays" [paginator]="false">
						<ng-template pTemplate="header" let-columns>
							<tr>
								<th colspan="1">
									<span class="d-flex justify-content-between align-items-center w-100">
										<span class="one-liner" pTooltip="{{'Holiday' | translate}}" tooltipPosition="left">{{'Holiday' | translate}}</span>
									</span>
								</th>
								<th colspan="1">
									<span class="d-flex justify-content-between align-items-center w-100">
										<span class="one-liner" pTooltip="{{'Date' | translate}}" tooltipPosition="left">{{'Date' | translate}}</span>
									</span>
								</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
							<tr>
								<td>
									<span class="one-liner">
										<span class="mobile-table-label">{{'Holiday' | translate}}: </span>
										<span *ngIf="rowData.holidayName" pTooltip="{{rowData?.holidayName}}">{{rowData?.holidayName}}</span>
										<span *ngIf="!rowData.holidayName" class="text-muted">{{'no data' | translate}}</span>
									</span>
								</td>
								<td>
									<span class="one-liner">
										<span class="mobile-table-label">{{'Date' | translate}}: </span>
										<span *ngIf="rowData.holidayDate" pTooltip="{{rowData.holidayDate | date : 'MMMM dd' | translate}}">{{rowData.holidayDate | date : 'MMMM dd' | translate}}</span>
										<span *ngIf="!rowData.holidayDate" class="text-muted">{{'no data' | translate}}</span>
									</span>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage" let-columns>
							<tr *ngIf="(!holidays || isBeforeToday(rowData.holidayDate)) && !holidaysLoading">
								<td [attr.colspan]="4" class="text-center py-5">
									<div class="text-muted my-5">{{'no data' | translate}}</div>
								</td>
							</tr>
							<tr *ngIf="requestsLoading">
								<td [attr.colspan]="4" class="text-center py-5">
									<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
								</td>
							</tr>
						</ng-template>
					</p-table>
					<div *ngIf="(anniversaries || birthdays || absencesAprroved || isDelayedArrival).length > 0" class="p-4" style="height: -webkit-fill-available;overflow-y: auto;overflow-x: hidden;">
						<div *ngFor="let ann of anniversaries; let index = index">
							<ng-container *ngIf="employeeId == ann.id; else anCoworker">
								<span><i class="fa-solid fa-thumbs-up text-info mr-1"></i>{{ 'CONGRATS_MESSAGE' | translate:{year: ann.year} }}</span>
							</ng-container>
							<ng-template #anCoworker>
								<span><i class="fa-solid fa-thumbs-up text-info mr-1"></i><span [innerHTML]="'CONGRATS_MESSAGE2' | translate:{name: ann.fullName, title: ann.title, year: ann.year}"></span></span>
							</ng-template>
						</div>
						<div *ngFor="let bd of birthdays; let idx = index">
							<ng-container *ngIf="employeeId == bd.id; else birthdayCoworker">
								<span><i class="fa-solid fa-birthday-cake text-purple mr-1"></i>{{'Happy Birthday To YOU!' | translate}}</span>
							</ng-container>
							<ng-template #birthdayCoworker>
								<span><i class="fa-solid fa-birthday-cake text-purple mr-1"></i>{{ 'BIRTHDAY_MESSAGE' | translate:{name: bd.fullName} }}</span>
							</ng-template>
						</div>
						<div *ngFor="let message of absencesAprroved; let indx = index">
							<span><i class="fa-solid fa-circle-check text-success mr-1"></i>{{message}}</span>
						</div>
						<div *ngIf="isDelayedArrival">
							<span><i class="fa-solid fa-triangle-exclamation text-warning mr-1"></i><span [innerHTML]="'DELAYED_ARRIVAL_MESSAGE' | translate:{name: fullName, time: delayedTimeStr}"></span></span>
						</div>
					</div>
				</div>
				<div *ngIf="(holidays.length + anniversaries?.length + birthdays?.length + absencesAprroved?.length + (isDelayedArrival ? 1 : 0)) === 0" class="d-flex align-items-center justify-content-center bg-light py-5" style="font-size: 16px;min-height: 300px">
					<div class="text-muted my-5">{{'Nothing to announce' | translate}}</div>
				</div>
			</div>
			-->
		</div>

		<div class="ui-xl-4 ui-md-6 ui-sm-12" *ngIf="isCEOAdmin()" id="invoicesPanel">
			<p-panel [toggleable]="true" id="table-ppanel">
				<p-header class="flex-grow-1">
					<span><i class="fa-solid fa-file-invoice text-primary mr-2"></i>{{'Invoices' | translate}} ({{invoices?.length}})</span>
				</p-header>
				<p-table #dt [value]="invoices" dataKey="id" selectionMode="single" [scrollable]="true" scrollHeight="240px" [style]="{'margin-bottom':'20px'}">
					<ng-template pTemplate="header">
						<tr>
							<th>
								<span class="one-liner">{{'Client' | translate}}</span>
							</th>
							<th>
								<span class="one-liner">{{'Invoice' | translate}}</span>
							</th>
							<th>
								<span class="d-flex align-items-center justify-content-start w-100">
									<span class="one-liner">{{'Amount' | translate}}</span>
								</span>
							</th>
							<th>
								<span class="one-liner">{{'Due' | translate}}</span>
							</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-rowData>
						<tr [pSelectableRow]="rowData">

							<td>{{rowData.clientName}}</td>
							<td>
								{{rowData.invoiceNumber}}
							</td>
							<td *ngIf="rowData.color =='orange'" style="background-color: orange">
								<div class="text-white">
									${{rowData.feeTxt}}
								</div>

							</td>
							<td *ngIf="rowData.color =='red'" style="background-color: red">
								<div class="text-white">
									${{rowData.feeTxt}}
								</div>
							</td>
							<td *ngIf="rowData.color =='green'" style="background-color: green">
								<div class="text-white">
									${{rowData.feeTxt}}
								</div>
							</td>
							<td>{{rowData.dueDate | date: 'MM/dd/yyyy'}}</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage" let-columns>
						<tr *ngIf="!loading">
							<td [attr.colspan]="4" class="text-center py-5">
								<div class="text-muted my-5">{{'no data' | translate}}</div>
							</td>
						</tr>
						<tr *ngIf="loading">
							<td [attr.colspan]="4" class="text-center py-5">
								<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
							</td>
						</tr>
					</ng-template>
				</p-table>
			</p-panel>
		</div>
		<div class="ui-g-6 ui-sm-12" *ngIf="isCEOAdmin()">
			<p-panel [toggleable]="true">
				<p-header class="flex-grow-1">
					<span><i class="fa-solid fa-wallet text-primary mr-2"></i>{{'Payments' | translate}}</span>
				</p-header>
				<div class="total-payment">
					<p-chart type="pie" [data]="dataPie"></p-chart>
				</div>
				<p-footer>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-calendar-week"></i>
							</button>
						</div>
						<p-dropdown [options]="types" [(ngModel)]="selectedType" (onChange)="getAllData()" placeholder="{{'Select One' | translate}}">
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>
						</p-dropdown>
					</div>
				</p-footer>
			</p-panel>
		</div>
		<div class="ui-g-12 ui-sm-12" *ngIf="isCEOAdmin()" id="assignmentPanel">
			<p-panel [toggleable]="true">
				<p-header class="flex-grow-1">
					<span><i class="fa-solid fa-clipboard text-primary mr-2"></i>{{'Plan/Assignment' | translate}}</span>
				</p-header>
				<div class="plan-assigment">
					<p-chart type="horizontalBar" [data]="dataPlanAssignment" [options]="horizontalOptions"></p-chart>
				</div>
			</p-panel>
		</div>
	</div>
</div>


<p-dialog *ngIf="isPopupOutForLunch" [(visible)]="isPopupOutForLunch" [modal]="true" [responsive]="true" id="absentEmployeeseModal" [style]="{'width':'90%', 'max-width':'500px','max-height':'80vh'}" [dismissableMask]="true">
	<p-header>
		<span>
			<i *ngIf="modalHeaderTitle.startsWith('Absent')" class="fa-solid fa-calendar-xmark mr-2"></i>

			<div *ngIf="modalHeaderTitle.startsWith('Break') && total > 0" class="badge badge-info mr-2">{{total}}</div>
			<i *ngIf="modalHeaderTitle.startsWith('Break') && (!total || total === 0)" class="fa-solid fa-utensils mr-2"></i>
			
			<div *ngIf="modalHeaderTitle.startsWith('Working') && total > 0" class="badge badge-success mr-2">{{total}}</div>
			<i *ngIf="modalHeaderTitle.startsWith('Working') && (!total || total === 0)" class="fa-solid fa-house-circle-check mr-2"></i>

			<div *ngIf="modalHeaderTitle.startsWith('Missed Break') && total > 0" class="badge badge-danger mr-2">{{total}}</div>
			<i *ngIf="modalHeaderTitle.startsWith('Missed Break') && (!total || total === 0)" class="fa-solid fa-triangle-exclamation mr-2"></i>
			{{modalHeaderTitle | translate}} {{popupOutHeader}}
		</span>
	</p-header>
	<div *ngIf="(popupIndex === 3)" class="d-flex justify-content-between border-bottom w-100 px-1 py-2">
		<span class="d-flex">
			<button class="btn mx-1" [ngClass]="userTable ? 'btn-primary' : 'btn-white'" (click)="leaveTable = false;userTable = true;textSearch = ''" style="border-radius: 25px"><span class="badge mr-1" [ngClass]="userTable ? 'badge-danger' : 'badge-danger'">{{filteredEmployeeDisplayData.length || 0}}</span>{{'Absent' | translate}}</button>
			<button class="btn mx-1" [ngClass]="leaveTable ? 'btn-primary' : 'btn-white'" (click)="leaveTable = true;userTable = false;textSearch = ''" style="border-radius: 25px"><span class="badge mr-1" [ngClass]="leaveTable ? 'badge-light' : 'badge-primary'">{{employeeAbsentDisplayData.length || 0}}</span>{{'Time-Off Requests' | translate}}</button>	
		</span>
		<button *ngIf="userTable || leaveTable" class="btn mx-1" [ngClass]="absentFilter ? 'btn-primary' : 'btn-white'" (click)="this.absentFilter = !this.absentFilter" style="border-radius: 25px"><i class="fa-solid fa-magnifying-glass" [ngClass]="absentFilter ? '' : 'text-primary'"></i></button>
	</div>
	
	<ng-container *ngIf="(popupIndex === 3) && userTable">
		<div *ngIf="absentFilter" class="p-2 border-bottom">
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-magnifying-glass"></i>
					</button>
				</div>
				<input type="text" size="35" pInputText [(ngModel)]="textSearch" (input)="onSearchChange($event?.target?.value)" placeholder="{{'Search by Name' | translate}}" style="height: 40px !important">
			</div>
		</div>
		<div *ngFor="let rowData of filteredEmployeeDisplayData; let i = index" (click)="gotoEmployeePage(rowData?.employeeId)" class="d-flex align-items-center justify-content-start p-2 border-bottom bg-white" id="concernsItem" style="cursor:pointer">
			<img *ngIf="rowData.avatar" class="avatar mr-2 border" src="{{rowData.avatar}}" />
			<div style="line-height: 1" class="py-1 text-truncate">
				<h5 *ngIf="rowData.fullName" class="m-0 text-nowrap">{{rowData.fullName}}</h5>
				<h5 *ngIf="!rowData.fullName" class="m-0 text-nowrap text-muted">{{'no data' | translate}}</h5>
			</div>
			<span class="align-items-center flex-grow-1 text-right">
			  	<i class="fa-solid fa-chevron-right text-muted c-pointer" style="cursor: pointer;"></i>
			</span>
		</div>
		<div *ngIf="filteredEmployeeDisplayData.length === 0" class="text-center py-5 bg-light">
			<i *ngIf="!loadingTable" class="fa-solid fa-circle-exclamation text-muted fa-3x mt-5"></i>
			<div class="text-muted mb-5">{{loadingTable ? ('Loading...' | translate) : ('no data' | translate)}}</div>
		</div>
	</ng-container>

	<ng-container *ngIf="(popupIndex === 3) && leaveTable" >
		<div *ngIf="absentFilter" class="p-2 border-bottom">
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-magnifying-glass"></i>
					</button>
				</div>
				<input type="text" size="35" pInputText [(ngModel)]="textSearch" (input)="onSearchChangeTimeOff($event?.target?.value)" placeholder="{{'Search by Name' | translate}}" style="height: 40px !important">
			</div>
		</div>
		<div *ngFor="let rowData of filteredEmployeeDisplayData2; let i = index"  (click)="gotoEmployeePage(rowData?.employeeId)" class="d-flex align-items-center justify-content-start p-2 border-bottom bg-white" id="concernsItem" style="cursor:pointer">
			<img class="avatar mr-2 border" src="{{rowData.avatar}}" />
			<div style="line-height: 1" class="py-1 text-truncate" style="cursor:pointer">
				<h5  class="m-0 text-nowrap">{{rowData.fullName || 'Untitled'}}</h5>
			</div>
			<span class=" align-items-center flex-grow-1 text-right">
				<i class="fa-solid fa-chevron-right text-muted c-pointer" style="cursor: pointer;"></i>
			</span>
		</div>
		<div *ngIf="filteredEmployeeDisplayData2.length === 0" class="text-center py-5 bg-light">
			<i *ngIf="!loadingTable" class="fa-solid fa-circle-exclamation text-muted fa-3x mt-5"></i>
			<div class="text-muted mb-5">{{loadingTable ? ('Loading...' | translate) : ('no data' | translate)}}</div>
		</div>
	</ng-container>

	<div *ngIf="popupIndex != 3">
		<div *ngFor="let rowData of employeeDisplayData; let i = index" class="d-flex align-items-center justify-content-between p-2 border-bottom bg-white" id="concernsItem">
			<div class="d-flex align-items-center" (click)="gotoEmployeePage(rowData?.employeeId)" >
				<img class="avatar mr-2 border" src="{{rowData.avatar}}"/>
				<div class="d-flex flex-column justify-column-start">				
					<h5 style="cursor: pointer" class="m-0 text-nowrap text-truncate">{{rowData.fullName}}</h5>
					<div class="d-flex" style="column-gap: 10px">
						<span *ngIf="rowData.timeIn"><b>{{'In' | translate}}: </b><span [ngClass]="!rowData.timeIn ? 'text-muted' :''">{{rowData.timeIn || 'no data'}}</span></span>
						<span *ngIf="rowData.timeOut"><b>{{'Out' | translate}}: </b><span [ngClass]="!rowData.timeOut ? 'text-muted' :''">{{rowData.timeOut || 'no data'}}</span></span>
					</div>
				</div>
			</div>
			<div class="text-right">
				<span class="align-items-center" *ngIf="rowData?.suspendStatus !== 'SUSPEND'">
					<i class="fa-solid fa-lock text-primary c-pointer" (click)="showModalSuspend(rowData?.fullName, rowData?.employeeId)" pTooltip="{{'Suspend' | translate}}" tooltipPosition="left" style="cursor: pointer;font-size: 22px"></i>
				</span>
				<span class="align-items-center" *ngIf="rowData?.suspendStatus === 'SUSPEND'">
					<i class="fa-solid fa-lock loop-color c-pointer" (click)="showModalUnSuspend(rowData?.fullName, rowData?.employeeId)" pTooltip="{{'Unsuspend' | translate}}" tooltipPosition="left" style="cursor: pointer;font-size: 22px"></i>
				</span>
			</div>
		</div>
		<div *ngIf="employeeDisplayData.length === 0" class="text-center py-5 my-5">
			<i *ngIf="!loadingTable" class="fa-solid fa-circle-exclamation text-muted fa-3x mt-5"></i>
			<div class="text-muted mb-5">{{loadingTable ? ('Loading...' | translate) : ('no data' | translate)}}</div>
		</div>
	</div>
	
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="closeDialog()"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
	</p-footer>
</p-dialog>

<p-dialog *ngIf="isShowUserOnline" [(visible)]="isShowUserOnline" [modal]="true" [responsive]="true" id="usersOnlineModal" [style]="{'width':'90%', 'max-width':'500px','max-height':'80vh'}">
	<p-header>
		<span>
			<i *ngIf="numOfCurrentLoginUser === 0 || !numOfCurrentLoginUser" class="fa-solid fa-user-shield mr-2"></i>
			<span *ngIf="numOfCurrentLoginUser > 0" class="badge badge-success mr-2">{{numOfCurrentLoginUser}}</span>
			{{popupUserOnlineHeader}}
		</span>
	</p-header>
	<div *ngFor="let rowData of employeeOnlineData; let i = index" (click)="gotoUserPageOrEmployeePage(rowData)" class="d-flex align-items-center justify-content-start p-2 bg-white border-bottom" id="concernsItem" style="cursor:pointer">

		<img *ngIf="rowData.avatar" class="avatar mr-3" [src]="rowData.avatar"/>
		<div style="line-height: 1" class="pb-2">
			<span class="text-muted" style="font-size: 12px">{{rowData.userName}}</span>
			<h5  class="m-0 text-nowrap text-truncate">{{rowData.fullName}}</h5>
		</div>
		<span class="d-flex justify-content-end align-items-center flex-grow-1">
			<span class="badge badge-orange">{{rowData.type}}</span>
			<i class="fa-solid fa-chevron-right text-muted c-pointer ml-2" style="font-size: 22px;opacity:0.5"></i>
		</span>
	</div>
	
	<div *ngIf="employeeOnlineData.length === 0" class="text-center py-5 my-5">
		<i *ngIf="!loadingTable" class="fa-solid fa-circle-exclamation text-muted fa-3x mt-5"></i>
		<div class="text-muted mb-5">{{loadingTable ? ('Loading...' | translate) : ('no data' | translate)}}</div>
	</div>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="isShowUserOnline = false"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
	</p-footer>
</p-dialog>

<p-dialog *ngIf="showConcern" [(visible)]="showConcern" [modal]="true" [responsive]="true" id="showConcernModal" [style]="{'width':'90%', 'max-width':'600px','max-height':'85vh'}">
	<p-header>
		<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Concern Details' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-12">
			<label>{{'Original Concern' | translate}}</label>
			<div class="my-2" style="font-size: 18px" [innerHTML]="selectedReport.details"></div>
			<hr>
		</div>
		<div *ngIf="selectedReport.replies?.length > 0" class="ui-g-12">
			<label>{{'Replies' | translate}}</label>
		</div>
		<div *ngIf="selectedReport.replies?.length > 0" class="ui-g-12 ui-lg-12 ">
			<div class="btn btn-light my-2 w-100 justify-content-between border-bottom" *ngFor="let reply of  selectedReport.replies">
				<span class="d-flex justify-content-between align-items-center">
					<strong>{{reply.userName}}: </strong><span class="text-muted" style="float: right; font-size: 12px;">{{reply.createdAt | date : 'MMM dd, yyyy hh:mm a'}}</span>
				</span>
				<div class="py-2 text-left" style="font-size: 16px">{{reply.reply}}</div>
			</div>
		</div>
	</div>
	<p-footer class="d-flex">
		<div class="input-group w-100">
			<div class="input-group-prepend p-0">
				<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
					<i class="fa-solid fa-reply"></i>
				</button>
			</div>
			<input type="text" pInputText type="text" id="childClientPassword" name="reply" [(ngModel)]="newReply" placeholder="{{'Enter text' | translate}}" class="w-100" maxlength="500" class="form-control">
			<div class="input-group-append">
				<button style="width: 40px" (click)="addReply()" [disabled]="!newReply && isReplying" class="btn btn-primary" type="button">
					<i class="fa-solid fa-paper-plane mr-2" pTooltip="{{'Send' | translate}}"></i>
				</button>
			</div>
		</div>
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="isPopupJobReportSummary" [responsive]="true" [dismissableMask]="true" [modal]="true" [style]="{'width':'90%', 'max-width':'500px', 'max-height':'80vh'}" id="jobReportsTableModal">
	<p-header>
		<span *ngIf="popupIndexSummary === 1">
			<i class="fa-solid fa-circle-exclamation mr-2"></i> {{'Missed Job Reports' | translate}} ({{popupJobReportSummaryData.length || 0 | number}})
		</span>
		<span *ngIf="popupIndexSummary === 2">
			<i class="fa-solid fa-circle-exclamation mr-2"></i> {{'Missed Too Many' | translate}} ({{popupJobReportSummaryData.length || 0 | number}})
		</span>
	</p-header>


	<div *ngFor="let rowData of popupJobReportSummaryData" class="d-flex align-items-center justify-content-between p-2 border-bottom bg-white px-3" style="cursor:pointer">
		<div class="d-flex align-items-center">
			<!-- User Icon -->
			<img class="avatar mr-2 border" src="{{rowData.avatar}}" />
		
			<!-- User Information -->
			<div class="py-1 text-truncate" style="line-height: 1">
			<h5 class="m-0 text-nowrap">{{rowData.name || 'Untitled'}}</h5>
			</div>
		</div>
	  
		<!-- Lock Icon Based on Suspension Status -->
		<span class="d-flex align-items-center" *ngIf="rowData?.suspendStatus !== 'SUSPEND' && beyondLimitationDecision == 2">
			<span class="badge" [ngClass]="popupIndexSummary === 1 ? 'badge-orange' : 'badge-danger'" pTooltip="{{'Times Missed' | translate}}" tooltipPosition="left" style="font-size: 14px">{{rowData.missedReport || '0'}}</span>
		 	 <i class="fa-solid fa-lock text-primary c-pointer ml-2" (click)="showModalSuspend(rowData?.name, rowData?.employeeId)" pTooltip="{{'Suspend' | translate}}" tooltipPosition="left" style="cursor: pointer;font-size: 20px"></i>
		</span>
		<span class="d-flex align-items-center" *ngIf="rowData?.suspendStatus === 'SUSPEND' && beyondLimitationDecision == 2">
			<span class="badge" [ngClass]="popupIndexSummary === 2 ? 'badge-danger' : 'badge-orange'" pTooltip="{{'Times Missed' | translate}}" tooltipPosition="left" style="font-size: 14px">{{rowData.missedReport || '0'}}</span>
		  	<i class="fa-solid fa-lock loop-color c-pointer ml-2" (click)="showModalUnSuspend(rowData?.fullName, rowData?.employeeId)" pTooltip="{{'Unsuspend' | translate}}" tooltipPosition="left" style="cursor: pointer; font-size: 20px"></i>
		</span>
	  </div>
	  
	  <!-- Empty and Loading State -->
	  <div *ngIf="popupJobReportSummaryData.length === 0 && !loadingTable" class="text-center py-5">
		<div class="text-muted my-5">{{'no data' | translate}}</div>
	  </div>
	  <div *ngIf="loadingTable" class="text-center py-5">
		<div class="text-muted my-5">
		  <i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}
		</div>
	  </div>
	

	<p-footer class="d-flex justify-content-between">
		<span>
			<button *ngIf="popupIndexSummary === 2" class="btn btn-white" (click)="changeTypeJobReport('miss');"><i  class="fa-solid fa-up-right-from-square text-primary mr-2"></i>{{'View Missed' | translate}}</button>
			<button *ngIf="popupIndexSummary === 1" class="btn btn-white" (click)="changeTypeJobReport('limit');"><i class="fa-solid fa-up-right-from-square text-primary mr-2"></i>{{'View Over Limit' | translate}}</button>
		</span>
		<button class="btn btn-primary" (click)="isPopupJobReportSummary = false;"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
	</p-footer>
</p-dialog>


<p-dialog [(visible)]="isPopupLateness" [responsive]="true" [dismissableMask]="true" [modal]="true" [style]="{'width':'90%', 'max-width':'500px', 'max-height':'80vh'}" id="lateNessTable">
	<p-header>
		<span *ngIf="popupLatenessData.length > 0" class="badge badge-orange mr-2">{{popupLatenessData.length || 0 | number}}</span>
		<i *ngIf="popupLatenessData.length === 0 || !popupLatenessData" class="fa-solid fa-triangle-exclamation mr-2"></i> {{'Late' | translate}}
	</p-header>
	<div *ngFor="let rowData of popupLatenessData; let i = index" class="d-flex align-items-center justify-content-start p-2 border-bottom">
		<div class="d-flex justify-content-center justify-content-between w-100">
			<div class="d-flex justify-content-center">
				<img class="avatar mr-2 border" src="{{rowData.avatar}}" />
				<ng-container *ngFor="let col of popupLatenessCols">
					<h5 *ngIf="rowData[col.field] && col.field == 'fullName'" style="cursor: pointer" class="m-0 text-nowrap text-truncate py-1" (click)="gotoEmployeePage(rowData?.employeeId)" pTooltip="{{rowData[col.field]}}">{{rowData[col.field]}}</h5>
					<div *ngIf="!rowData[col.field]" class="text-muted d-flex flex-column justify-content-center aling-items-center py-5">
						{{'no data' | translate}}
					</div>
				</ng-container>
			</div>
			<div class="font-weight-bold pr-5">{{rowData.comeIn}}</div>
		</div>

		<div *ngIf="beyondLimitationDecision == 2" class="text-right">
			<span class="align-items-center" *ngIf="rowData?.suspendStatus !== 'SUSPEND'">
				<i class="fa-solid fa-lock text-primary c-pointer" (click)="showModalSuspend(rowData?.fullName, rowData?.employeeId)" pTooltip="{{'Suspend' | translate}}" tooltipPosition="left" style="cursor: pointer;font-size: 22px"></i>
			</span>
			<span class="align-items-center" *ngIf="rowData?.suspendStatus === 'SUSPEND'">
				<i class="fa-solid fa-lock text-danger c-pointer" (click)="showModalUnSuspend(rowData?.fullName, rowData?.employeeId)" pTooltip="{{'Unsuspend' | translate}}" tooltipPosition="left" style="cursor: pointer;font-size: 22px"></i>
			</span>
		</div>
	</div>
	
	<div *ngIf="popupLatenessData.length === 0" class="text-center py-5 my-5">
		<i *ngIf="!loadingTable" class="fa-solid fa-circle-exclamation text-muted fa-3x mt-5"></i>
		<div class="text-muted mb-5">{{loadingTable ? ('Loading...' | translate) : ('no data' | translate)}}</div>
	</div>
	
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="isPopupLateness = false"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
	</p-footer>
</p-dialog>


<p-dialog [(visible)]="isShowPopupSuspend" [responsive]="true" [dismissableMask]="true" [modal]="true" [style]="{'width':'90%', 'max-width':'500px', 'max-height':'80vh'}" id="suspendModal">
	<p-header>
		<i class="fa-solid fa-lock mr-2"></i> {{'Suspend' | translate}}: {{dataSelectedSuspend}}
	</p-header>
	<div class="ui-g pb-3">
		<div class="ui-g-12 ui-sm-12">
			<label>{{'Start Date' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-day"></i>
					</button>
				</div>
				<mat-form-field class="w-100">
					<input matInput [ngxMatDatetimePicker]="picker2" name="startDateSuspend" [(ngModel)]="suspendUser.suspendAt" (focus)="picker2.open()" [max]="maxDate">
					<ngx-mat-datetime-picker #picker2 [enableMeridian]="true" [showSeconds]="true"></ngx-mat-datetime-picker>
				</mat-form-field>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Duration' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-hashtag"></i>
					</button>
				</div>
				<input type="text" (keypress)="numberOnly($event)" maxlength="6" pInputText [(ngModel)]="suspendUser.time" id="time" name="time" placeholder="0" class="w-100">
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Units' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-stopwatch"></i>
					</button>
				</div>
				<p-dropdown [(ngModel)]="suspendUser.unit" [options]="unitList" optionLabel="label" placeholder="{{'placeholder.pleaseSelect' | translate}}">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
	</div>
	<p-footer class="d-flex justify-content-between">
		<button class="btn btn-danger" (click)="isShowPopupSuspend = false"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button class="btn btn-primary" (click)="submitSuspendUser(2)"><i class="fa-solid fa-check mr-2"></i>{{'Suspend' | translate}}</button>
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="isShowPopupUnSuspend" class="modal_in" [modal]='true' [style]="{'width':'90%', 'max-width':'450px'}">
	<p-header>
		<span><i class="fa-solid fa-circle-exclamation mr-2"></i>{{'Un-Suspend User' | translate}}</span>
	</p-header>
    <div class="ui-g">
		<div class="ui-g-12">
			<span style="font-size:16px">{{messageConfirmUnsuspendUser}}</span>
		</div>
    </div>
    <p-footer class="d-flex justify-content-between">
		<button type="button" (click)="isShowPopupUnSuspend = false" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>   
          <button type="button" (click)="submitUnsuspendUser()" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
    </p-footer>
</p-dialog>
