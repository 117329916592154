<ng-container *ngIf="viewBoardError">
    <div class="row justify-content-md-center align-self-center" style="display: inline;">
        <div class="offset-md-4" style="margin-top: 100px">
            <img src="../../../assets/images/cannotbereached.png" style="width: 100px; height: auto; margin-bottom: 30px;"><br>
            <h3 translate>This Card can't be reached</h3>
            <h5 translate style="color:red">Card was deleted by {{cardDeletedHistory.createdByUsr}} at {{cardDeletedHistory.createdAt | date:'MM/dd/yyyy HH:mm'}}</h5>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!viewBoardError">
    <app-task-management-top-bar  [showBackground]="true" [boards]="allProject" [currentBoardId]="projectId" (onAdd)="showDialogAddColumn=true"
                                [showMenu]="true"
                                [isAdmin]="isSuperAdmin || isBoardAdmin() || isSubSuper"
                                [activityData]="activityData"
                                [viewMore]="viewMore"
                                [isLoadingActivity]="isLoadingActivity"
                                (loadActivityEvent)="showActivity($event)"
                                (onShowTaskUrl)="toggleTaskUrl($event)"
                                [childTemplate]="tplMemberHeader"
                                [labels]="allLabel"
                                [allowUnhide]="isSuperAdmin || isBoardAdmin() || isSubSuper"
                                (onSearch)="onSearchTaskFromSidebar($event)"
                                (onUnhide)="onUnhideBuckets()"
                                [members]="allMember"
                                [loadProject]="loadingProject"
                                  [hasHiddenList]="hiddenList"
                                  (onCheckHiddenList)="hasHiddenList()"
                                  (onOpenBoardNotice)="openBoardNotice()"
        (onOpenBackground)="opBackground.toggle($event)">
    </app-task-management-top-bar>
    <div class="board-management-content" [ngStyle]="{'background-image': 'url(' + background + ')'}">
        <div class="spinner-overlay" *ngIf="loading">
            <p-progressSpinner class="center-spin"></p-progressSpinner>
        </div>
        <ng-template #tplMemberHeader>
            <div class="d-flex align-items-center justify-content-between flex-grow-1">
            <div *ngFor="let member of allMember;let i=index;" class="list-member-add">
                <ng-container *ngIf="i < 5">
                    <app-card-member (click)="selectedMemberCard = member; checkRemoveCard($event, member)"
                                     [member]="member" [allMember]="allMember" [boardAdmin]="boardAdmin && boardAdmin.id === member.id">
                    </app-card-member>
                </ng-container>
            </div>
            <div class="d-flex align-items-center justify-content-end flex-grow-1">
                <span class="d-flex flex-wrap align-center" style="row-gap: 5px">
                    <!--<div *ngFor="let member of allMember;let i=index;" class="list-member-add">-->
                        <!--<ng-container *ngIf="i < 5">-->
                            <!--<app-card-member (click)="selectedMemberCard = member; checkRemoveCard($event, member)"-->
                                <!--[member]="member" [allMember]="allMember" [boardAdmin]="boardAdmin && boardAdmin.id === member.id">-->
                            <!--</app-card-member>-->
                        <!--</ng-container>-->
                    <!--</div>-->
                    <span  class="badge bg-secondary mx-1" style="max-height: 20px" *ngIf="allMember.length > 5" (click)="opAllMember.toggle($event)">+{{ allMember.length - 5 }} {{'More' | translate}}</span>
                    <p-dropdown [options]="allMemberOption" dataKey="value" placeholder="{{'Select Member' | translate}}" [filter]="true" [showClear]="true" (onChange)="selectMember($event.value)">
                        <ng-template pTemplate="item" let-item>
                            <span class="text-truncate">{{item.label | translate}}</span>
                        </ng-template>
                        <ng-template let-item pTemplate="selectedItem">
                            <span class="one-liner">{{item.label | translate}}</span>
                        </ng-template>
                    </p-dropdown>
                    <p-dropdown [options]="priorityTypeOption" dataKey="value" placeholder="{{'Select Priority' | translate}}" [showClear]="true" (onChange)="selectPriorityTypeOption($event.value)" [(ngModel)]="selectPriority">
                        <ng-template pTemplate="item" let-item>
                            <span class="text-truncate">{{item.label | translate}}</span>
                        </ng-template>
                        <ng-template let-item pTemplate="selectedItem">
                            <span class="one-liner">{{item.label | translate}}</span>
                        </ng-template>
                    </p-dropdown>
                </span>
                <button class="btn btn-white mx-1" (click)="openInviteModal()">
                    <span class="text-nowrap"><i class="fa-solid fa-user-plus text-primary mr-2"></i>{{'Invite' | translate}}</span>
                </button>
            </div>
            </div>
        </ng-template>
        <section class="columns noselect" dragula="COLUMNS" [(dragulaModel)]="board" id="boardSection">
            <ng-container *ngFor="let column of board; let columnIndex = index;">
                <div class="column" [hidden]="column.hide">
                    <span class="material-icons sort-col" (click)="sortCol.toggle($event); selecteColumn(columnIndex, column)">more_vert</span>
                    <i class="material-icons delete-column-icon" (click)="openDeleteColumnDialog(column)"
                        title="Delete this list" *ngIf="!isSuperAdmin">delete</i>
                    <span class="total-tik">Tot : {{column?.tasksView?.length}}</span>
                    <app-edit-column-title [column]="column" (columnEvent)="onEditColumnTitleEvent($event)" (hideEvent)="onHideColumnEvent(column)"
                    [board]="currenBoard" [allMember]="allMember">
                    </app-edit-column-title>
                    <div class="cards-dummy">
                        <div *ngFor="let d of [1,2,3]"></div>0
                    </div>
                    <!-- <cdk-virtual-scroll-viewport itemSize="20" #cdkVirtualScrollViewport [style.height]="getViewportHeight(cdkVirtualScrollViewport, column.id)"> -->
                    <div #cardsCnt class="cards" dragula="CARDS" [(dragulaModel)]="column.tasksView" [attr.data-id]="column.id"
                        infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="50" (scrolled)="onScrollDown(columnIndex)" [scrollWindow]="false">
                        <ng-container *ngFor="let item of column.tasksView;">
                            <div class="card" *ngIf="!item.status" [hidden]="item?.hidden || item?.hideFromSearch" style="margin-bottom: 10px !important;"
                                 [class.selectedItem]="column.multiSelect && item.selected" class="bucket-{{column.id}}">
                                <mat-checkbox class="multi-select-checkbox" [color]="'primary'" *ngIf="column.multiSelect" [(ngModel)]="item.selected"
                                              binary="true" style="position: absolute; top: -4px; right: 2px; z-index: 10000" (click)="checkBoxSelect(item)"></mat-checkbox>
                                <div class="card-action-icon" (click)="$event.stopPropagation();" [ngClass]="{'card-action-icon2': !item?.background}">
                                    <i class="material-icons edit-card-icon" *ngIf="!(cardName === item?.name)"
                                        (click)="editNameCard(item, $event, column)" title="Open this note" [ngClass]="{'disabledIcon': isSuperAdmin}">edit</i>
                                    <i class="material-icons update-card-icon" *ngIf="cardName === item?.name"
                                        [ngClass]="{'disable-event': selectedCard.name.length === 0}" (click)="nameChanged=true; updateCard(true)"
                                        title="Update this note">done</i>
                                    <i class="material-icons close-card-icon" *ngIf="cardName === item?.name"
                                        (click)="cancelEditCardName($event)" title="Close change this note">close</i>
                                    <i class="material-icons delete-card-icon" *ngIf="!(cardName === item?.name)"
                                        (click)="openDeleteCardDialog(item, $event, column)" title="Delete this note"
                                    [ngClass]="{'disabledIcon': isSuperAdmin}" >delete</i>

                                </div>
                                <img (click)="openEditCardDialog(item, column,columnIndex)" *ngIf="item?.background" style="width: 100%;margin-bottom: 5px;max-height: 420px;" [src]="item?.background" />
                                <div class="task-label" style="cursor:pointer;">
                                    <div *ngFor="let label of item?.labels || []" [style.background]="label.color"
                                    (click)="selectLabel(label.id)">{{label.name}}</div>
                                </div>
                                <div *ngIf="!item?.background" class="row" style="display: inline-block; height: 20px !important;">
                                    <img *ngIf="item?.ticketType && item?.ticketType === 'EPIC'" (click)="selectTicketType(item?.ticketType)"
                                         style="width: 18px !important; position: absolute; right: 10px; top: 10px; padding: 0 !important; height: 20px !important;" src="assets/images/ticket_type_epic.jpg">
                                    <img *ngIf="item?.ticketType && item?.ticketType === 'STORY'"  (click)="selectTicketType(item?.ticketType)"
                                         style="width: 18px !important; position: absolute; right: 10px; top: 10px; padding: 0 !important; height: 20px !important;" src="assets/images/ticket_type_story.jpg">
                                    <img *ngIf="item?.ticketType && item?.ticketType === 'BUG'"  (click)="selectTicketType(item?.ticketType)"
                                         style="width: 18px !important; position: absolute; right: 10px; top: 10px; padding: 0 !important; height: 20px !important;" src="assets/images/ticket_type_bug.jpg">
                                </div>
                                <div style="cursor:pointer;">
                                    <div *ngIf="item?.background" class="row" style="display: inline-block; height: 20px !important;">
                                        <img *ngIf="item?.ticketType && item?.ticketType === 'EPIC'" (click)="selectTicketType(item?.ticketType)"
                                             style="width: 18px !important; position: absolute; right: 13px; padding: 0 !important; height: 20px !important;" src="assets/images/ticket_type_epic.jpg">
                                        <img *ngIf="item?.ticketType && item?.ticketType === 'STORY'"  (click)="selectTicketType(item?.ticketType)"
                                             style="width: 18px !important; position: absolute; right: 13px; padding: 0 !important; height: 20px !important;" src="assets/images/ticket_type_story.jpg">
                                        <img *ngIf="item?.ticketType && item?.ticketType === 'BUG'"  (click)="selectTicketType(item?.ticketType)"
                                             style="width: 18px !important; position: absolute; right: 13px; padding: 0 !important; height: 20px !important;" src="assets/images/ticket_type_bug.jpg">
                                    </div>
                                    <span class="task-name" *ngIf="!(cardName === item?.name)">
                                        <span class="tooltiptext" (click)="openEditCardDialog(item, column,columnIndex)" [ngClass]="{'tooltip2': item?.background}">{{item?.name}}</span>
                                        <span class="two-liner m-0" (click)="openEditCardDialog(item, column,columnIndex)">{{item?.name}}</span>
                                    </span>
                                    <span *ngIf="cardName === item?.name" (click)="$event.stopPropagation();">
                                        <input type="text" class="form-control" pInputText [(ngModel)]="selectedCard.name"
                                            style="font-size: 14px; padding-right: 40px;" />
                                    </span>
                                </div>
                                <div (click)="openEditCardDialog(item, column,columnIndex)" class="label-form-action pt-2">
                                    <!-- <div class="task-deadline"
                                        [ngStyle]="{color: item?.statusDeadline && item?.statusDeadline === 'upcoming' ? '#FFCC00' : item?.statusDeadline === 'expired' ? '#FF0000' : 'inherit'}">
                                        {{item?.deadline | date:'short'}}
                                    </div> -->
                                    <div class="task-deadline">
                                        <span *ngIf="item?.startDate" > SD: {{item?.startDate | date:'short'}} </span> <br>
                                        <span *ngIf="item?.statusDeadline" [ngStyle]="{color: item?.statusDeadline && item?.statusDeadline === 'upcoming' ? '#FFCC00' : item?.statusDeadline === 'expired' ? '#FF0000' : 'inherit'}">
                                        FD: {{item?.deadline | date:'short'}}</span>
                                    </div>
                                    <!-- <div class="d-flex" style="margin-top: -2px;" *ngIf="item?.commentsCount">
                                        <i class="material-icons">chat_bubble_outline</i> {{item?.commentsCount}}
                                    </div> -->
                                </div>
                                <div class="flex-start pt-1" style="margin-top: 5px; color: grey;">
                                    <strong> #{{item?.internalId}}</strong>
                                    <span class="pl-3">
                                        <i *ngIf="item?.projectId" (click)="copyToClipboard(item)" class="fa-solid fa-link text-primary c-pointer"
                                           pTooltip="{{'Copy URL' | translate}}">
                                        </i>
                                    </span>
                                    <span *ngIf="item?.attachmentCount" class="pl-3"><i class="fa-solid fa-paperclip" style="font-size: 12px;"></i>  {{item?.attachmentCount}}</span>
                                    <span *ngIf="item?.commentsCount" class="pl-3"><i class="fa-regular fa-comment" style="font-size: 12px;"></i>  {{item?.commentsCount}}</span>
                                    <div style="display: inline-flex; position: absolute; right: 0; padding-right: 15px;">
                                        <div *ngIf="item?.priorityType === 'HIGH'" class="exclamation-mark-icon" (click)="selectPriorityType(item?.priorityType)">!!!</div>
                                        <div *ngIf="item?.priorityType === 'MEDIUM'" style="background: darkorange;" class="exclamation-mark-icon" (click)="selectPriorityType(item?.priorityType)">!!</div>
                                        <div *ngIf="item?.priorityType === 'LOW'" style="background: #00b700" class="exclamation-mark-icon" (click)="selectPriorityType(item?.priorityType)">!</div>
                                    </div>
                                </div>
                                <div class="pt-1 d-flex flex-end" style="cursor:pointer; flex-wrap: wrap" (click)="selectBucket(item)">
                                    <div *ngFor="let member of item?.assignedUsers" class="ml-3px mt-5px"
                                         (click)="selectMember(member.id);op3.toggle($event);selectedMemberCard=member;">
                                        <app-card-member [member]="member" [allMember]="allMember" [boardAdmin]="boardAdmin && boardAdmin.id === member.id"></app-card-member>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="stacked-icons" *ngIf="column?.tasksView?.length > 0 && showScrollAction(columnIndex)">
                        <i class="fas fa-caret-up" (click)="scrollToTop(columnIndex)" title="Move to up"></i>
                        <i class="fas fa-caret-down" (click)="scrollToBottom(columnIndex)" title="Move to bottom"></i>
                    </div>
                    <!-- </cdk-virtual-scroll-viewport> -->

                    <!-- Add new card -->
                    <app-add-card [selectedColumn]="column" [selectedCardsCnt]="cardsCnt" [(addCardText)]="addCardText" (cardEvent)="onAddCardEvent($event, column.name, column.tasks)">
                    </app-add-card>
                </div>

            </ng-container>
            <div class="column add-new-column" [ngClass]="{'disabledIcon': isSuperAdmin}" (click)="openAddColumnModal()">
                Add New List
            </div>
        </section>

        <p-dialog header="Add New List" [dismissableMask]="true" showEffect="true" height="500px" width="550px"
            [modal]="true" [(visible)]="showDialogAddColumn" (keydown.enter)="addColumn()">
            <div class="note-popup-content text-center">
                <div class="ui-g-12">
                    <input type="text" maxlength="250" [(ngModel)]="newColumnName" pInputText placeholder="List Name" autofocus>
                </div>
                <div class="ui-g-12 notes-action">
                    <p-button label="Save" (onClick)="addColumn()"
                        [disabled]="!newColumnName || newColumnName.trim() === ''">
                    </p-button>
                    <p-button label="Cancel" (onClick)="showDialogAddColumn=false"></p-button>
                </div>
            </div>
        </p-dialog>

        <p-dialog header="Confirmation" [(visible)]="showConfirmDeleteDialog" class="modal_in" [modal]='true'>
            <div class="ui-g" style="width:380px;padding:8px;">
                Are you want to delete the bucket?
            </div>
            <p-footer>
                <div class="ui-g">
                    <div class="ui-g-12" align="right">
                        <button type="button" pButton label="Yes" (click)="confirmDeleteColumn()"></button>
                        <button type="button" pButton label="No" (click)="showConfirmDeleteDialog=false"></button>
                    </div>
                </div>
            </p-footer>
        </p-dialog>


        <p-dialog header="Confirmation" [(visible)]="showConfirmDeleteCardDialog" class="modal_in" [modal]='true'>
            <div class="ui-g" style="width:380px;padding:8px;">
                Are you want to delete this card?
            </div>
            <p-footer>
                <div class="ui-g">
                    <div class="ui-g-12" align="right">
                        <button type="button" pButton label="Yes" (click)="confirmDeleteCard()"></button>
                        <button type="button" pButton label="No" (click)="showConfirmDeleteCardDialog=false"></button>
                    </div>
                </div>
            </p-footer>
        </p-dialog>


        <p-dialog (onHide)="closeModalTask()" [dismissableMask]="true" class="card-management-modal"  [class]="'custom-scroll'" showEffect="true" [modal]="true" [(visible)]="showDialogUpdateCard" [style]="{'width':'100%', 'max-width':'900px', 'max-height':'85vh'}">
            <p-header>
				<span><i class="fa-solid fa-info mr-2"></i><span [textContent]="selectedCard.name" (click)="opChangeNameCard.toggle($event)" (input)="changeCardname($event)"></span></span>
            </p-header>
            <div class="task-cover-image" *ngIf="selectedCard.background"
                style="background-image: url('{{selectedCard.background}}')">
            </div>
            <div class="card-popup-content p-4" appFileDrag (files)="filesDropped($event)">
                <div class="upload-drop-content" id="upload-drop-content">
                    Drop file to upload...
                </div>
                <div class="ui-g-12" *ngIf="selectedCard.labels && selectedCard.labels.length > 0">
                    <div>LABELS 111:</div>
                    <div class="label-full" *ngFor="let label of selectedCard.labels || []" 
                        (click)="opLabel.toggle($event);showLabelForm=false" [style.background]="label.color">
                        {{label.name}}</div>
                </div>
                <div class="ui-g-12">
                    <div class="ui-g-8 ticket-content-left">
                        <div style="padding: 0.5em;">in list
                            <span (click)="opMove.toggle($event)" class="cursor-pointer"
                                style="text-decoration: underline;">{{selectedBucket}}</span>
                        </div>
                        <div (click)="checkOnClickImage($event)">
                            <div style="padding: 0.5em; display: flex; align-items: center;flex-wrap: wrap">
                                <p style="margin-right: 15px; margin-bottom: 0;">MEMBERS</p>
                                <div *ngFor="let member of selectedCard.assignedUsers" (click)="op3.toggle($event);selectedMemberCard=member" class="exclude-outside">
                                    <app-card-member [member]="member" [allMember]="allMember" [boardAdmin]="boardAdmin && boardAdmin.id === member.id"></app-card-member>
                                </div>
                                <div #panel1 [ngClass]="{'disabledIcon': isSuperAdmin}" class="add-member-icon exclude-outside" (click)="op2.toggle($event, panel1)">+</div>
                                <div *ngIf="showButtonRefresh" style="margin-left: 300px;" class="refresh-icon exclude-outside">
                                    <a href="javascript:void(0)" (click)="refreshProjectDetail()"> <i class="fa-solid fa-rotate"></i> </a>
                                </div>
                            </div>
                            <div (click)="openTextArea($event)" class="exclude-outside cursor-pointer" [ngStyle]="{'display': !isShowTextArea ? 'block' : 'none'}">
                                <p *ngIf="!selectedCard.content || selectedCard.content === ' '" class="cursor-pointer">
                                    <a class="description-fake-text-area">Add description ...</a>
                                </p>
                                <div *ngIf="selectedCard.content" class="task-detail-content ql-editor cursor-pointer"
                                    [innerHTML]="selectedCard.content | trustHtml">
                                </div>
                            </div>
                            <p-editor [hidden]="!isShowTextArea" (onInit)="onEditorInit($event)" #descriptionEditor id="descriptionEditor"
                                [(ngModel)]="draffContent[selectedCard.id]" (clickOutside)="closeTextArea()" [exclude]="'.exclude-outside, p-overlayPanel'"
                                [excludeBeforeClick]="true" (onTextChange)="onTextChange($event, 'paste')" (keydown)="typingText()"
                                [mentionConfig]="mentionConfig" [readonly]="showFocusOnDescription || (selectedCard.lockTicketContent && !allowLockTicketContent)" (paste)="pasteEvent = true"
                                (closed)="boldMentionDescription()" (click)="focusOnComment($event)" [disabled]="selectedCard.lockTicketContent && !allowLockTicketContent">
                                <p-header>
                                    <span class="ql-formats">
                                        <select class="ql-header">
                                        <option value="1">Heading</option>
                                        <option value="2">Subheading</option>
                                        <option selected>Normal</option>
                                        </select>
                                        <select class="ql-font">
                                        <option selected>Sans Serif</option>
                                        <option value="serif">Serif</option>
                                        <option value="monospace">Monospace</option>
                                        </select>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-bold" aria-label="Bold"></button>
                                        <button class="ql-italic" aria-label="Italic"></button>
                                        <button class="ql-underline" aria-label="Underline"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <select class="ql-color" ></select>
                                        <select class="ql-background" ></select>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-list" value="ordered" aria-label="Ordered List"></button>
                                        <button class="ql-list" value="bullet" aria-label="Unordered List"></button>
                                        <select class="ql-align">
                                        <option selected></option>
                                        <option value="center"></option>
                                        <option value="right"></option>
                                        <option value="justify"></option>
                                        </select>
                                    </span>
                                </p-header>
                            </p-editor>
                            <div class="ui-g-12 text-right" style="margin-top: 10px" *ngIf="allowLockTicketContent">
                                <p-checkbox [(ngModel)]="selectedCard.lockTicketContent" [ngModelOptions]="{standalone: true}" binary="true"
                                            (onChange)="onLockTicketContent($event)" label="{{'Lock Ticket Content' | translate}}"></p-checkbox>
                                <i class="fa fa-info-circle text-primary" tooltipPosition="bottom" pTooltip="{{'Only you, board admin and board sub-admin could edit this ticket.' | translate}}"></i>
                            </div>
                            <div class="ui-g-12 text-right" style="margin-top: 10px" *ngIf="!allowLockTicketContent && selectedCard.lockTicketContent">
                                <i class="fa fa-lock"></i>
                            </div>
                            <div class="ui-g-12 text-left" style="margin-top: 35px" *ngIf="isShowTextArea">
                                <button pButton label="Save" class="green-btn"
                                    (click)="updateCard(true); monitorAddTaskDescription(selectedCard);isShowTextArea = false;"
                                    style="margin-right: 10px;border-radius: 5px;"></button>
                                <p-button label="Cancel" (onClick)="draffContent[selectedCard.id] = selectedCard.content;closeTextArea();isShowTextArea = false;"></p-button>
                            </div>
                        </div>
                        <div>
                            <div class="ui-g-12" *ngIf="allCommentFile.length">
                            <hr>
                            <h6><i class="material-icons">attachment</i> Attachments</h6>
                            <ng-container *ngFor="let imageUrl of allCommentFile">
                                <app-attachment-section [imageUrl]="imageUrl" (onDelete)="deleteComment($event)"
                                    (onRemoveCover)="removeCover($event)" (onMakeCover)="makeCover($event)"
                                    [taskBackground]="selectedCard.background" (onComment)="replyComment($event, 'attachment')"
                                    (onEditLabel)="updateComment($event, 'imageLabel')"></app-attachment-section>
                            </ng-container>
                            </div>
                            <div class="ui-g-12" *ngFor="let checks of checkListData; let i = index; trackBy: trackByIdentify">
                                <div class="ui-g-12">
                                    <p class="ui-g-1">
                                        <p-checkbox [(ngModel)]="checks.checked" binary="true" [ngModelOptions]="{standalone: true}" (click)="updateCheckListItem(checks)"></p-checkbox>
                                    </p>
                                <div class="ui-g-5 pb-0" id="showItemListItem_{{i}}"  (click)="checkListNameShow(i, checks)">
                                <h5 style="margin-bottom: 0;"> {{checks?.name}}</h5>
                                </div>
                                <div class="ui-g-6 text-right d-flex" id="showItemListItem1_{{i}}" >

                                    <div style="display: flex; align-items: center;">
                                        <p class="mr-1 mb-0">Assigned To</p>
                                        <div *ngIf="checks.assignedUser" (click)="selectedCheckList = checks; op5.toggle($event);" class="exclude-outside">
                                            <app-card-member [member]="checks.assignedUser" [allMember]="allMember" [boardAdmin]="boardAdmin && checks.assignedUser && boardAdmin.id === checks.assignedUser.id"></app-card-member>
                                        </div>
                                        <div *ngIf="!checks.assignedUser" [ngClass]="{'disabledIcon': isSuperAdmin}" class="add-member-icon exclude-outside mr-1" (click)="selectedCheckList = checks; op4.toggle($event)">+</div>
                                    </div>

                                    <!-- <span *ngIf="!checks.selectedHide" class="option" (click)="hideSelectedItem(checks, true)">Hide Checked Item</span>
                                    <span *ngIf="checks.selectedHide" class="option" (click)="hideSelectedItem(checks, false)">Show Checked Item</span> -->
                                    <span class="option" (click)="deleteCheckList.toggle($event); selectedCheckList=checks">Delete</span>
                                </div>
                                <div class="ui-g-11" id="ItemListItem_{{i}}" style="display: none;" >
                                    <input type="text" pInputText [(ngModel)]="updateCheckListName" autofocus> 
                                    <button class="mt-1 p-0" pButton label="Save" (click)="saveItem(i, j, checks, 'li')"></button>
                                    <i class="material-icons p-2" style="font: 22px; color:red;" (click)="hideInputList(i, checks)"> close </i>
                                </div>
                            </div>
                            <div class="ui-g-12 p-0">
                                <div class="ui-g-1">
                                <p style="margin-top:-5px">{{checks.percentage | number: '1.0-0'}}%</p>
                                </div>
                                <div class="ui-g-11">
                                    <p-progressBar [value]="checks.percentage" [style]="{'height': '8px'}"></p-progressBar>
                                </div>
                            </div>
                            <div class="ui-g-12">
                            <div class="ui-g-12 p-0" id="ico" *ngFor="let ite of checks?.taskCheckListItems; let j = index">
                                <div class="ui-g-1">
                                    <p>
                                        <p-checkbox [(ngModel)]="ite.selectItem" binary="true" [ngModelOptions]="{standalone: true}" 
                                        (onChange)="selectChecbox($event, ite, checks)" ></p-checkbox>
                                    </p>
                                </div>
                                <div class="ui-g-11">
                                    
                                    <div class="d-flex">
                                        <span id="temInputItem_{{i}}{{j}}" (click)="showInputItem(i, j, ite)"> {{ite?.name}}</span>
                                        <span class="iconn">
                                        <i class="material-icons p-2" style="position: relative; top: -12px; font: 22px; color:red" (click)="deleteItem(j, checks)"> close </i>
                                        </span>
                                    </div>

                                    <p id="showItemInputItem_{{i}}{{j}}" style="display: none;" >
                                        <input type="text" pInputText [(ngModel)]="listItemNameUp" autofocus> 
                                        <button class="mt-1 p-0" pButton label="Save" (click)="saveItem(i,j, ite)"></button>
                                        <i class="material-icons p-2" style="font: 22px;color:red" (click)="hideInputItem(i, j, ite)"> close </i>
                                    </p>
                                </div>
                            </div>
                                <div class="ui-g-11">
                                    <input id="showItemInput_{{i}}" type="text" pInputText placeholder="Checklist" [(ngModel)]="listItemName" autofocus
                                    style="display: none;" (keydown)="Space($event)" #myTextBox="ngModel"> 
                                    <span style="display: block;">
                                    <span class="option" id="item_{{i}}" (click)="showInput(i)">Add an item</span></span>
                                    <span  class="mt-2" style="display: none;" id="item2_{{i}}">
                                        <div *ngIf="!myTextBox.value" style="color:red;">
                                            Please enter valid input !
                                        </div> 
                                    <button [disabled]="!myTextBox.value"  pButton label="Add" (click)="addChecklistItem(checks)"></button> &nbsp; 
                                    <i class="material-icons" style="font: 22px; color:red;" (click)="hideInput(i, checks)"> close </i></span>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="tabs">
                            <div class="tab active" id="comment" (click)="tabSelect('comment')">Comments</div>
                            <div class="tab" id="activity" (click)="tabSelect('activity')">Activity</div>
                        </div>
                        <div class="content active" id="comment.active">
                            <div class="comment-box">
                                <p-editor #commentEditor class="exclude-outside" placeholder="New comment here...." [(ngModel)]="newComment"
                                          [mentionConfig]="mentionConfig" (closed)="boldMention()"
                                          [readonly]="isSuperAdmin || showFocuOnComment"
                                          (onTextChange)="onTextChange($event)"
                                          (click)="focusOnEditor($event)"
                                          (onInit)="onCommentEditorInit($event)">
                                    <p-header>
                                                <span class="ql-formats">
                                                    <button class="ql-bold" aria-label="Bold"></button>
                                                    <button class="ql-italic" aria-label="Italic"></button>
                                                    <button class="ql-underline" aria-label="Underline"></button>
                                                </span>
                                    </p-header>
                                </p-editor>
                                <div class="comment-control" [hidden]="newComment == null || newComment.length === 0">
                                    <div class="comment-box-left">
                                        <p-button class="exclude-outside" (click)="addComment(newComment,'TEXT')" label="Save"
                                                  [disabled]="commentClicked">
                                        </p-button>
                                    </div>
                                    <div class="comment-box-right" style="display: none;">
                                        <label #attachmentLabel for="attachment"><i class="material-icons">attachment</i>
                                        </label>
                                        <input type="file" accept="image/*" id="attachment" style="display: none;"
                                               multiple (change)="onSelectFile($event)" />
                                    </div>
                                </div>
                            </div>
                            <div class="loading-upload" *ngIf="loadingUpload">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60">
                                    <style>
                                        .line {
                                            fill: none;
                                            stroke: #aaa;
                                            stroke-width: 8;
                                            stroke-dasharray: 40
                                        }

                                        .animated {
                                            animation: draw .8s ease-in-out;
                                            animation-iteration-count: infinite;
                                            stroke-dashoffset: 0
                                        }

                                        .line2 {
                                            animation-delay: .08s
                                        }

                                        .line3 {
                                            animation-delay: .16s
                                        }

                                        @keyframes draw {
                                            0% {
                                                stroke-dashoffset: 0
                                            }

                                            50% {
                                                stroke-dashoffset: 30
                                            }

                                            100% {
                                                stroke-dashoffset: 0
                                            }
                                        }
                                    </style>
                                    <path class="line animated" d="M14 10V50z" />
                                    <path class="line animated line2" d="M30 10V50z" />
                                    <path class="line animated line3" d="M46 10V50z" />
                                </svg>
                            </div>
                            <ng-container *ngFor="let comment of getAllComments(); trackBy: trackByComment">
                                <app-comment-item class="exclude-outside" [taskBackground]="selectedCard.background" [isShowLog]="true"
                                                  [comment]="comment" (onUpdate)="updateComment($event)" (onDelete)="deleteComment($event)"
                                                  (onReply)="replyComment($event)" (onMakeCover)="makeCover($event)" (onRemoveCover)="removeCover()"
                                                  [mentionEmployees]="mentionEmployees">
                                </app-comment-item>
                            </ng-container>
                        </div>
                        <div class="content" id="activity.active">
                            <ng-container *ngFor="let activity of getAllActivities(); trackBy: trackByActivity">
                                <app-comment-item class="exclude-outside" [taskBackground]="selectedCard.background" [isShowLog]="true" [comment]="activity">
                                </app-comment-item>
                            </ng-container>
                            <div>
                                <div class="comment-header" *ngIf="selectedCard && selectedCard.info">
                                    <app-card-member [member]="selectedCard.info"></app-card-member>
                                    <div class="member-name">{{selectedCard.createdBy}}</div>
                                    <div class="comment-time">{{selectedCard.createdAt | date:'short'}}</div>
                                </div>
                                <div class="comment-content">made this card</div>
                            </div>
                        </div>
                    </div>
                    <div class="ui-g-4 ticket-content-right">
                        <div class="add-to-cart exclude-outside">
                            <div class="item-label-group">
                                <div class="item-label">
                                    <div class="title-label d-flex align-items-center justify-content-between" [ngClass]="{'disabledIcon': isSuperAdmin}" (click)="opTicketType.toggle($event)">
                                        <a>
                                            <mat-icon> border_all</mat-icon><span>Ticket Type:
                                                <ng-container [ngSwitch]="selectedCard.ticketType">
                                                    <span *ngSwitchCase="TICKET_TYPE.STORY">Story</span>
                                                    <span *ngSwitchCase="TICKET_TYPE.EPIC">Epic</span>
                                                    <span *ngSwitchCase="TICKET_TYPE.BUG">Bug</span>
                                                </ng-container>
                                            </span>
        
                                            <img *ngIf="selectedCard.ticketType === 'EPIC'" style="width: 10%; margin-left: 80px;" src="assets/images/ticket_type_epic.jpg">
                                            <img *ngIf="selectedCard.ticketType === 'STORY'" style="width: 10%; margin-left: 78px" src="assets/images/ticket_type_story.jpg">
                                            <img *ngIf="selectedCard.ticketType === 'BUG'" style="width: 10%; margin-left: 80px" src="assets/images/ticket_type_bug.jpg">
                                        </a>
                                        <span style="float: right"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                                <div class="item-label" *ngIf="isAdmin || isBoardAdmin()">
                                    <div class="title-label d-flex align-items-center justify-content-between" [ngClass]="{'disabledIcon': isSuperAdmin}" (click)="opVisibilityMode.toggle($event)">
                                        <a>
                                            <mat-icon>person_outline</mat-icon><span>View mode:
                                                <ng-container [ngSwitch]="selectedCard.viewMode">
                                                    <span *ngSwitchCase="VISIBILITY_MODE.EVERYONE">everyone</span>
                                                    <span *ngSwitchCase="VISIBILITY_MODE.ASSIGN_ONLY">assign only</span>
                                                    <span *ngSwitchCase="VISIBILITY_MODE.PERSONALIZE">personalize</span>
                                                </ng-container>
                                            </span>
                                        </a>
                                        <span style="float: right"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>

                            <!--<p style="font-size: 18px;">ADD TO CARD</p>-->
                            <div class="box-label" [ngClass]="{'disabledIcon': isSuperAdmin}" >
                                <div class="item-label-group">
                                    <div class="item-label">
                                        <div class="title-label">
                                            <a (click)="openToolBox('member')">
                                                <mat-icon>person_outline</mat-icon><span>Member</span>
                                            </a>
                                        </div>
                                        <div class="tool-box" *ngIf="isShowToolBox && selectLabelCard === 'member'">
                                            <div class="add-member d-flex-wrap align-items-center">
                                                <div *ngFor="let member of selectedCard.assignedUsers"
                                                    (click)="op3.toggle($event);selectedMemberCard=member">
                                                    <app-card-member [member]="member" [allMember]="allMember" [boardAdmin]="boardAdmin && boardAdmin.id === member.id">
                                                    </app-card-member>
                                                </div>
                                                <div #panel2 class="add-member-icon" (click)="op2.toggle($event, panel2)">+</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item-label">
                                        <div class="title-label d-flex align-items-center justify-content-between" [ngClass]="{'disabledIcon': isSuperAdmin}" (click)="opPriorityType.toggle($event)">
                                            <a>
                                                <mat-icon> content_paste</mat-icon><span>Priority Type:
                                                <ng-container [ngSwitch]="selectedCard.priorityType">
                                                    <span *ngSwitchCase="PRIORITY_TYPE.HIGH">Story</span>
                                                    <span *ngSwitchCase="PRIORITY_TYPE.MEDIUM">Epic</span>
                                                    <span *ngSwitchCase="PRIORITY_TYPE.LOW">Bug</span>
                                                </ng-container>
                                            </span>
                                                <div *ngIf="selectedCard.priorityType === 'HIGH'" class="exclamation-mark-icon">!!!</div>
                                                <div *ngIf="selectedCard.priorityType === 'MEDIUM'" style="background: darkorange;" class="exclamation-mark-icon">!!</div>
                                                <div *ngIf="selectedCard.priorityType === 'LOW'" style="background: #00b700" class="exclamation-mark-icon">!</div>
                                            </a>
                                            <span style="float: right"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                                        </div>
                                    </div>
                                    <div class="item-label">
                                        <div class="title-label">
                                            <a  (click)="opLabel.toggle($event);showLabelForm=false">
                                                <mat-icon>label</mat-icon><span>Labels</span>
                                            </a>
                                        </div>
                                        <div class="tool-box" *ngIf="isShowToolBox && selectLabelCard === 'label'">
                                            <div class="developing-box">
                                                <p>Under Development</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="item-label-group">
                                    <div class="item-label">
                                        <div class="title-label">
                                            <a (click)="checkList.toggle($event)">
                                                <mat-icon>task_alt</mat-icon><span>To-Do</span>
                                            </a>
                                        </div>
                                        <!-- <div class="tool-box" *ngIf="isShowToolBox && selectLabelCard === 'checklist'">
                                            <div class="developing-box">
                                                <p>Under Development</p>
                                            </div>
                                        </div> -->
                                    </div>

                                    <div class="item-label">
                                        <div class="title-label">
                                            <a   (click)="attachmentLabel.click()">
                                                <mat-icon >attachment</mat-icon><span>Attachment</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="item-label-group">
                                    <div class="item-label">
                                        <div class="title-label">
                                            <a  (click)="openToolBox('date')">
                                                <mat-icon>schedule</mat-icon><span>Ticket Start Date</span>
                                            </a>
                                        </div>
                                        <div class="tool-box" *ngIf="isShowToolBox && selectLabelCard === 'date'">
                                            <div class="set-date d-flex">
                                                <mat-form-field>
                                                    <input matInput [ngxMatDatetimePicker]="picker2" readonly
                                                        [(ngModel)]="selectedCard.startDate" name="licenseeDate"
                                                           [max]="selectedCard.deadline"
                                                        (focus)="picker2.open()" (dateChange)="updateCard(true)">
                                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                                    <ngx-mat-datetime-picker #picker2 [enableMeridian]="true"
                                                        [showSeconds]="true">
                                                    </ngx-mat-datetime-picker>
                                                </mat-form-field>
                                                <a class="btn" [hidden]="!selectedCard.startDate" (click)="deleteStartDate()"><mat-icon>close</mat-icon></a>
                                            </div>
                                        </div>
                                    </div>
        
                                    <div class="item-label">
                                        <div class="title-label">
                                            <a  (click)="openToolBox('date')">
                                                <mat-icon>schedule</mat-icon><span>Finish Date</span>
                                            </a>
                                        </div>
                                        <div class="tool-box" *ngIf="isShowToolBox && selectLabelCard === 'date'">
                                            <div class="set-date d-flex">
                                                <mat-form-field>
                                                    <input matInput [ngxMatDatetimePicker]="picker1" readonly
                                                        [(ngModel)]="selectedCard.deadline" name="licenseeDate"
                                                           [min]="selectedCard.startDate"
                                                        (focus)="picker1.open()" (dateChange)="updateCard(true)">
                                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                                    <ngx-mat-datetime-picker #picker1 [enableMeridian]="true"
                                                        [showSeconds]="true">
                                                    </ngx-mat-datetime-picker>
                                                </mat-form-field>
                                                <a class="btn" [hidden]="!selectedCard.deadline" (click)="deleteDueDate()"><mat-icon>close</mat-icon></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="item-label-group">
                                    <div class="item-label">
                                        <div class="title-label">
                                            <a  (click)="opMove.toggle($event)">
                                                <mat-icon>arrow_right_alt</mat-icon><span>Move</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="item-label">
                                        <div class="title-label">
                                            <a  (click)="opDuplicate.toggle($event)">
                                                <mat-icon>file_copy</mat-icon><span>Duplicate</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="item-label-group">
                                    <div class="item-label">
                                        <div class="title-label d-flex" (click)="subscribe()">
                                            <a>
                                                <mat-icon>visibility</mat-icon><span>Watch</span>
                                            </a>
                                            <div class="d-flex align-center" *ngIf="isSubscribe">
                                                <i class="material-icons task-watch-icon">done</i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item-label">
                                        <div class="title-label">
                                            <a  (click)="openEstimationTaskDialog()">
                                                <mat-icon>domain</mat-icon><span>Estimation Task</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="item-label-group">
                                    <div class="item-label"></div>
                                    <div class="item-label confirm-btn" style="margin-bottom: unset !important;">
                                        <div class="title-label mr-1"
                                            *ngIf="statusAfter !== 'Approved' && statusAfter !== 'Close'"
                                            [class]="{'title-label-confirm': !isDisableConfirm,
                                            'disabled': statusAfter === 'Confirm' ||
                                            statusAfter === 'Confirmed' || statusAfter === 'Approved' || confirmSpinner['confirm']}">
                                            <a (click)="onConfirm()" [class]="{'disabled': isDisableConfirm}">
                                                <i *ngIf="confirmSpinner['confirm']" class="fa-solid fa-spinner mr-2 loadingHourglass"></i><span>Confirm</span>
                                            </a>
                                        </div>
                                        <div class="title-label"
                                            *ngIf="statusAfter !== 'Approved' && statusAfter !== 'Close'"
                                            [class]="{'title-label-confirm': !isDisableConfirm,
                                            'disabled': statusAfter === 'Approved' || confirmSpinner['approved']}">
                                            <a (click)="onApproved()" [class]="{'disabled': isDisableConfirm}">
                                                <i *ngIf="confirmSpinner['approved']" class="fa-solid fa-spinner mr-2 loadingHourglass"></i><span>Approved</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="item-label confirm-btn">
                                        <div class="title-label mr-1"
                                            *ngIf="statusAfter === 'Approved'"
                                            [class]="{'title-label-tack-back': !isDisableConfirm, 'disabled': !isAdminBoard || confirmSpinner['tackback']}">
                                            <a (click)="onTakeBack()">
                                                <i *ngIf="confirmSpinner['tackback']" class="fa-solid fa-spinner mr-2 loadingHourglass"></i><span>Take Back</span>
                                            </a>
                                        </div>
                                        <div class="title-label"
                                            *ngIf="statusAfter == 'Approved' || statusAfter == 'Close'"
                                            [class]="{'title-label-confirm': !isDisableConfirm,
                                            'disabled': statusAfter === 'Close' || confirmSpinner['complete']}">
                                            <a [class]="{'disabled': isDisableConfirm}"
                                                (click)="onCompleteTicket()">
                                                <i *ngIf="confirmSpinner['complete']" class="fa-solid fa-spinner mr-2 loadingHourglass"></i><span>{{statusAfter === 'Close' ? 'Completed' : 'Complete'}}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="item-label">
                            <div class="title-label" pTooltip="{{selectedCard.createdByName}}">
                                <a style="word-wrap: break-word; display:block; width: 100%">
                                    <span>Created By: {{selectedCard.createdBy}}
                                        {{selectedCard.createdAt | date: 'MM/dd/yyyy hh:mm a'}}</span>
                                </a>
                            </div>
                        </div>
<!--                        <div class="item-label">-->
<!--                            <div class="title-label">-->
<!--                                <a style="padding: 10px 10px;">-->
<!--                                    <span>Date Created: {{selectedCard.createdAt | date: 'MM/dd/yyyy hh:mm a'}}</span>-->
<!--                                </a>-->
<!--                            </div>-->
<!--                        </div>-->
                        <div class="item-label">
                            <div class="title-label" pTooltip="{{selectedCard.lastModifiedByName}}">
                                <a style="padding: 10px 10px;word-wrap: break-word; display:block; width: 100%">
                                    <span>Last Edited By: {{selectedCard.lastModifiedBy}}
                                        {{ selectedCard.updatedAt | date: 'MM/dd/yyyy hh:mm a'}}</span>
                                </a>
                            </div>
                        </div>
                    </div> 
                </div>

            </div>
        </p-dialog>
        
    </div>
</ng-container>
<p-overlayPanel #op2 (onShow)="hidePanelMember(); setFocus('input1')">
    <input type="text" id="input1" class="form-control" autofocus pInputText #seachMember placeholder="Search by member name" [(ngModel)]="searchMemberName" [ngModelOptions]="{standalone: true}" />
    <div class="member-card-panel">
        <ng-container *ngFor="let member of allMember">
            <div class="list-member-add" *ngIf="member.name.toLowerCase().includes(seachMember.value.toLowerCase()) || member.email.toLowerCase().includes(seachMember.value.toLowerCase())"
                (click)="addMemberToTask(member); op2.hide()">
                <app-card-member [member]="member" [allMember]="allMember" [boardAdmin]="boardAdmin && boardAdmin.id === member.id"></app-card-member>
                <span>{{member.name}} - {{member.email}}</span>
            </div>
        </ng-container>
    </div>
</p-overlayPanel>
<p-overlayPanel #op3>
    <div style="cursor: pointer;" [ngClass]="{'disabledIcon': isSuperAdmin}" (click)="unassign()">
        Remove from card
    </div>
</p-overlayPanel>

<p-overlayPanel #op4 (onHide)="hidePanelMember()">
    <input type="text" id="input2" class="form-control" pInputText #seachMember2 placeholder="Search by member name" [(ngModel)]="searchMemberName2" [ngModelOptions]="{standalone: true}"
    (click)="setFocus('input2')" />
    <div class="member-card-panel">
        <div *ngFor="let member of allMember" class="list-member-add" (click)="addMemberToCheckListItem(member); op4.hide()">
            <ng-container *ngIf="member.name.toLowerCase().includes(seachMember2.value.toLowerCase()) || member.email.toLowerCase().includes(seachMember2.value.toLowerCase())">
                <app-card-member [member]="member" [allMember]="allMember" [boardAdmin]="boardAdmin && boardAdmin.id === member.id"></app-card-member>
                <span>{{member.name}} - {{member.email}}</span>
            </ng-container>
        </div>
    </div>
</p-overlayPanel>

<p-overlayPanel #op5>
    <div style="cursor: pointer;" [ngClass]="{'disabledIcon': isSuperAdmin}" (click)="unassignChecklist()">
        Remove from checklist
    </div>
</p-overlayPanel>

<p-dialog header="Invite" [dismissableMask]="true" showEffect="true" height="900px" width="550px" [modal]="true"
    [(visible)]="showInvitePopup" class="share-popup">
    <div class="note-popup-share-content text-center">
        <div class="ui-g-12">
            <div class="ui-g-2">
                <span class="ui-float-label">
                    User:
                </span>
            </div>
            <div class="ui-g-10">
                <span class="ui-float-label">
                    <div class="ui-fluid">
                        <p-multiSelect class="d-inline-block w-100" defaultLabel="{{'pleaseSelect' | translate}}"
                            [options]="allEmployee" [(ngModel)]="selectUserToInvite" [style]="{'width': '500px'}"
                            id="allEmployee_invite" optionLabel="name" appendTo="body">
                        </p-multiSelect>
                    </div>
                </span>
            </div>
        </div>
        <div class="ui-g-12" style="cursor: pointer" >
            <div class="ui-g-3">
                <a href="javascript:void(0)" (click)="getShareLink()">Invite with link</a>
            </div>
        </div>
        <p-progressSpinner class="center-spin" *ngIf="loadingInviteLink"></p-progressSpinner>
        <div class="ui-g-12" *ngIf="inviteLink">
            <div class="ui-g-9">
                <input type="text" class="form-control" pInputText #inviteLinkEl [(ngModel)]="inviteLink"
                    style="font-size: 14px; padding-right: 30px;" />
            </div>
            <div class="ui-g-1">
                <p-button label="{{copyBtnContent}}" (click)="copyLink(inviteLinkEl)" style="margin-right: 10px;">
                </p-button>
            </div>
        </div>
        <div>
            <div *ngIf="boardAdmin" style="text-align: left;">
                BOARD ADMIN
            </div>
            <div class="d-flex-wrap member-list-panel">
                <div class="list-member-add">
                    <div *ngIf="boardAdmin?.name?.toLowerCase().includes(searchTermMember.toLowerCase())">
                        <app-card-member [member]="boardAdmin" [allMember]="allMember" [boardAdmin]="true"></app-card-member>
                    </div>
                </div>
            </div>
            <div *ngIf="invitedUsers.length>0" style="text-align: left;">
                INVITED
            </div>
            <div class="d-flex-wrap">
                <div *ngFor="let member of invitedUsers" class="list-member-add">
                    <app-card-member [member]="member" [allMember]="allMember" [boardAdmin]="boardAdmin && boardAdmin.id === member.id"></app-card-member>
                </div>
            </div>
            <div style="text-align: left;">
                GUESTS
            </div>
            <div class="d-flex-wrap">
                <div *ngFor="let member of allMember" class="list-member-add">
                    <app-card-member [member]="member" [allMember]="allMember" [boardAdmin]="boardAdmin && boardAdmin.id === member.id" *ngIf="!boardAdmin || member?.id !== boardAdmin.id"></app-card-member>
                </div>
            </div>
        </div>
        <div class="ui-g-12 task-invite-action">
            <p-button label="Submit" (click)="submitInvite()"></p-button>
            <p-button label="Cancel" (click)="showInvitePopup=false"></p-button>
        </div>
    </div>
</p-dialog>


<p-overlayPanel #opLabel (onHide)="hidePanelLabel()">
    <div class="loading-label" *ngIf="loadingLabel">
        <p-progressSpinner class="center-spin"></p-progressSpinner>
    </div>
    <div *ngIf="!showLabelForm" style="width: 304px">
        <div class="">
            <input pInputText type="text" placeholder="Search labels…" aria-placeholder="Search labels…" value="" (keyup)="findLabels($event.target.value)" [(ngModel)]="searchLabelName" [ngModelOptions]="{standalone: true}">
        </div>
        <div>Labels: </div>
        <ul class="panel-label-list" *ngIf="!searchLabelName">
            <li class="" *ngFor="let label of allLabel">
                <label class="label-li">
                    <span>
                        <p-checkbox name="label" binary="true" [(ngModel)]="label.labelChecked" [inputId]="label.id"
                         [ngModelOptions]="{standalone: true}" (click)="toogleAssignLabel(label)"></p-checkbox>
                    </span>
                    <span class="label-project">
                        <div class="label-div">
                            <div class="label-select" [style.background]="label.color">
                                {{label.name}} 
<!--                                <i class="material-icons selected" *ngIf="label.checked">done</i>-->
                            </div>
                            <button class="label-edit"><i class="material-icons edit-btn" (click)="openEditLabel(label)">edit</i></button>
                        </div>
                    </span>
                </label>
            </li>
        </ul>
        <ul class="panel-label-list" *ngIf="searchLabelName && filteredLabel.length > 0">
            <li class="" *ngFor="let label of filteredLabel">
                <label class="label-li">
                    <span>
                        <p-checkbox name="label" binary="true" [(ngModel)]="label.labelChecked" [ngModelOptions]="{standalone: true}" (click)="toogleAssignLabel(label)"></p-checkbox>
                    </span>
                    <span class="label-project">
                        <div class="label-div">
                            <div class="label-select" [style.background]="label.color">
                                {{label.name}} 
<!--                                <i class="material-icons selected" *ngIf="label.checked">done</i>-->
                            </div>
                            <button class="label-edit"><i class="material-icons edit-btn" (click)="openEditLabel(label)">edit</i></button>
                        </div>
                    </span>
                </label>
            </li>
        </ul>
        <ul class="panel-label-list" *ngIf="searchLabelName && filteredLabel.length === 0">No data</ul>
        <div class="btn-add-label" (click)="openAddNewLabel()">
            Create a new label
        </div>
    </div>
    <div *ngIf="showLabelForm">
        <input type="text" class="form-control" [(ngModel)]="selectedLabel.name"
            style="font-size: 14px; padding-right: 30px;" placeholder="label name"
               [ngStyle]="isCheckLabelName ? { 'border-bottom':'1px solid red'} : { 'border': 'none' }"
               (change)="onChangeLabel()"
               (ngModelChange)="onChangeLabel()"
           id="input2" (click)="setFocus('input2')"/>
        <div class="ui-g-12 custom-error-message left-padding-0 p-0">
            <span class="ui-message ui-messages-error"
                  *ngIf="isCheckLabelName">Name Required
            </span>
        </div>
        <div>Select a color</div>
        <div class="d-flex-wrap">
            <p-colorPicker [(ngModel)]="selectedLabel.color"></p-colorPicker>
            <!-- <div class="new-label-color-item" *ngFor="let labelColor of listLabelColor" [style.background]="labelColor"
                (click)="selectedLabel.color=labelColor">
                <i class="material-icons selected" *ngIf="labelColor===selectedLabel.color">done</i>
            </div> -->
        </div>
        <div class="label-form-action pt-3">
            <ng-container *ngIf="!selectedLabel.id">
                <p-button label="Cancel" (click)="showLabelForm=false"></p-button>
                <p-button label="Create" (click)="createLabel()"></p-button>
            </ng-container>
            <ng-container *ngIf="selectedLabel.id">
                <p-button label="Delete" (click)="deleteLabel()"></p-button>
                <p-button label="Update" (click)="updateLabel()"></p-button>
            </ng-container>
        </div>
    </div>

</p-overlayPanel>
<p-overlayPanel #opAllMember>
    <input type="text" class="form-control" style="font-size: 14px; padding-right: 30px;" placeholder="Search member"
        [(ngModel)]="searchTermMember" />
    <div *ngIf="boardAdmin">
        BOARD ADMIN
    </div>
    <div class="d-flex-wrap member-list-panel">
        <div class="list-member-add">
            <div *ngIf="boardAdmin?.name?.toLowerCase().includes(searchTermMember.toLowerCase())">
                <app-card-member [member]="boardAdmin" [allMember]="allMember" [boardAdmin]="true"></app-card-member>
            </div>
        </div>
    </div>
    <div *ngIf="invitedUsers.length>0">
        INVITED
    </div>
    <div class="d-flex-wrap member-list-panel">
        <div *ngFor="let member of invitedUsers" class="list-member-add">
            <div *ngIf="member?.name?.toLowerCase().includes(searchTermMember.toLowerCase())" (click)="checkRemoveCard($event,member, 'popup')">
                <app-card-member [member]="member" [allMember]="allMember" [boardAdmin]="boardAdmin && boardAdmin.id === member.id"></app-card-member>
            </div>
        </div>
    </div>
    <div>
        GUESTS
    </div>
    <div class="d-flex-wrap member-list-panel">
        <div *ngFor="let member of allMember" class="list-member-add">
            <div *ngIf="member?.name?.toLowerCase().includes(searchTermMember.toLowerCase()) && (!boardAdmin || member?.id !== boardAdmin.id)" (click)="checkRemoveCard($event,member,'popup')">
                <app-card-member [member]="member" [allMember]="allMember" [boardAdmin]="boardAdmin && boardAdmin.id === member.id"></app-card-member>
            </div>
        </div>
    </div>
</p-overlayPanel>
<p-overlayPanel #opMove>
    <div class="move-panel">
        <ng-container *ngIf="this.board.length>1">
            <div class="header">Move card</div>
            <div class="suggest">
                <span>SUGGESTED</span>
                <span class="bucket-name"
                    (click)="moveTask(moveCardInfo,opMove, selectedBucket,true )">{{moveCardInfo.suggested?.name}}</span>
            </div>
            <span style="margin-top: 10px;">SELECT DESTINATION</span>
            <div>
                <div>Board</div>
                <p-dropdown (onChange)="changeTargetBoard(moveCardInfo.targetBoard)" [options]="allProject" dataKey="id"
                    [(ngModel)]="moveCardInfo.targetBoard" optionLabel="name" [style]="{'width':'70.9%'}">
                </p-dropdown>
            </div>
            <div class="select-destination">
                <div>
                    <span>List</span>
                    <p-dropdown (onChange)="changeTargetBucket(moveCardInfo.targetBucket)"
                        [options]="moveCardInfo.listBucket" dataKey="id" [(ngModel)]="moveCardInfo.targetBucket"
                        optionLabel="name" [style]="{'width':'70.9%'}">
                    </p-dropdown>
                </div>
                <div>
                    <span>Position</span>
                    <p-dropdown [options]="moveCardInfo.positionList" [(ngModel)]="moveCardInfo.targetPosition">
                    </p-dropdown>
                </div>
            </div>
            <div class="action">
                <p-button label="Move" (onClick)="moveTask(moveCardInfo,opMove, selectedBucket)"></p-button>
            </div>
        </ng-container>
        <ng-container *ngIf="this.board.length<2">
            <div>Move function only work with 2 columns or more</div>
        </ng-container>
    </div>
</p-overlayPanel>
<p-overlayPanel #opBackground>
    <app-background-panel [background]="background" (onPick)="onPickBackgroundImage($event)"></app-background-panel>
</p-overlayPanel>
<p-overlayPanel #opRemoveCard>
    <div (click)="removeFromBoard()">Remove from board</div>
</p-overlayPanel>
<p-overlayPanel #opLeaveBoard>
    <div (click)="removeFromBoard()">Leave the board</div>
</p-overlayPanel>
<p-overlayPanel #adminAccess>
    <div *ngIf="isBoardAdmin() || isSuperAdmin || isCompanyAdmin"> <div style="text-align: center;"> {{selectedMemberCard.name}}'s Change Permissions </div>
        <hr>
        <div (click)="changeMemberRole(1, 'ADMIN')" style="cursor: pointer;" 
        [ngClass]="{'change-style': selectedMemberCard?.role === 'ADMIN'}" *ngIf="isSuperAdmin || isCompanyAdmin">
            <h6> Admin </h6>
            <span>- All access </span> <br>
        </div>
        <hr *ngIf="isSuperAdmin">
        <div (click)="changeMemberRole(2, 'SUB_ADMIN')" style="cursor: pointer;" 
        [ngClass]="{'change-style': selectedMemberCard?.role === 'SUB_ADMIN'}">
            <h6> SubAdmin </h6>
            <span>- Invite Member & Remove Member </span> <br>
            <span>- Can Move Bucket & Edit </span> <br>
            <span>- Cannot add other member as SubAdmin </span>
        </div>
        <hr>
        <div (click)="changeMemberRole(0, 'MEMBER')" style="cursor: pointer;"
        [ngClass]="{'change-style': selectedMemberCard?.role === 'MEMBER'}">
            <h6> Normal </h6>
            <span>- Can add, view and edit card </span><br>
            <span>- Can delete card they created </span>
        </div>
        <hr>
    </div>
    <div (click)="removeFromBoard()">Remove from board</div>
</p-overlayPanel>
<p-overlayPanel #opVisibilityMode>
    <div class="panel-visibility-mode">
        <div class="panel-visibility-item mt-3">
            <p-radioButton [(ngModel)]="selectedCard.viewMode" name="VISIBILITY_MODE"
                [value]="VISIBILITY_MODE.ASSIGN_ONLY" label="Assigned Only"></p-radioButton>
        </div>
        <div class="panel-visibility-item d-flex align-center">
            <p-radioButton [(ngModel)]="selectedCard.viewMode" name="VISIBILITY_MODE" [value]="VISIBILITY_MODE.EVERYONE"
                label="Every One"></p-radioButton>
        </div>
        <div class="panel-visibility-item d-flex align-center">
            <p-radioButton [(ngModel)]="selectedCard.viewMode" name="VISIBILITY_MODE"
                [value]="VISIBILITY_MODE.PERSONALIZE" label="Personalize"></p-radioButton>
        </div>
        <div [hidden]="selectedCard.viewMode!=VISIBILITY_MODE.PERSONALIZE">
            <p-multiSelect class="d-inline-block w-100 mb-1" defaultLabel="{{'pleaseSelect' | translate}}"
                [options]="allEmployeeUser" optionValue="id" optionLabel="name" optionValue="id"
                [(ngModel)]="selectedCard.accessibleUsers" [style]="{'width': '100%'}">
            </p-multiSelect>
        </div>
    </div>
    <p-button class="mt-1" label="Save" (onClick)="opVisibilityMode.hide(); updateCard(true)">
    </p-button>
</p-overlayPanel>

<p-overlayPanel #opTicketType>
    <div class="panel-visibility-mode">
        <div class="panel-visibility-item mt-3 d-flex align-center">
            <p-radioButton [(ngModel)]="selectedCard.ticketType" name="TICKET_TYPE"
                [value]="TICKET_TYPE.STORY" label="Story"></p-radioButton>
                <img style="width: 10%; margin-left: 78px" src="assets/images/ticket_type_story.jpg">
            </div>
            <div class="panel-visibility-item d-flex align-center">
                <p-radioButton [(ngModel)]="selectedCard.ticketType" name="TICKET_TYPE" [value]="TICKET_TYPE.EPIC"
                label="Epic"></p-radioButton>
            <img style="width: 10%; margin-left: 80px;" src="assets/images/ticket_type_epic.jpg">
        </div>
        <div class="panel-visibility-item d-flex align-center">
            <p-radioButton [(ngModel)]="selectedCard.ticketType" name="TICKET_TYPE"
                [value]="TICKET_TYPE.BUG" label="Bug"></p-radioButton>
            <img style="width: 10%; margin-left: 80px" src="assets/images/ticket_type_bug.jpg">
        </div>
        <div class="panel-visibility-item d-flex align-center">
            <p-radioButton [(ngModel)]="selectedCard.ticketType" name="TICKET_TYPE"
                [value]="TICKET_TYPE.NONE" label="None"></p-radioButton>
        </div>

    </div>
    <p-button class="mt-1" label="Save" (onClick)="opTicketType.hide(); updateCard(true)">
    </p-button>
</p-overlayPanel>

<div class="modal-image-preview exclude-outside" style="z-index: 1050;" *ngIf="previewImage">
    <div class="preview-content">
        <img [src]="previewImage" />
        <div class="close" (click)="previewImage=''">X</div>
    </div>
</div>
<p-overlayPanel #opDuplicate>
    <div class="move-panel">
            <div class="header">Copy card</div>
            <div class="mt-10">Title<span style="color: red;">*</span> </div>
            <textarea [(ngModel)]="moveCardInfotitle" id="id_note_text" pInputTextarea 
                id="input3" (click)="setFocus('input3')" maxlength="100">
            </textarea>
            <ng-container *ngIf="allCommentFile.length!=0">
                <div class="mt-10">Keep...</div>
                <div class="mt-10" >
                    <p-checkbox [(ngModel)]="moveCardInfo.includeAttachment" binary="true" label="Attachment ({{allCommentFile?.length}})"></p-checkbox>
                </div>
            </ng-container>
            <div class="mt-10">Copy to...</div>
            <div class="mt-10">
                <div>Board</div>
                <p-dropdown (onChange)="changeTargetBoard(moveCardInfo.targetBoard)" [options]="allProject" dataKey="id"
                    [(ngModel)]="moveCardInfo.targetBoard" optionLabel="name" [style]="{'width':'70.9%'}">
                </p-dropdown>
            </div>
            <div class="select-destination">
                <div>
                    <span>List</span>
                    <p-dropdown (onChange)="changeTargetBucket(moveCardInfo.targetBucket)"
                        [options]="moveCardInfo.listBucket" dataKey="id" [(ngModel)]="moveCardInfo.targetBucket"
                        optionLabel="name" [style]="{'width':'70.9%'}">
                    </p-dropdown>
                </div>
                <div>
                    <span>Position</span>
                    <p-dropdown [options]="moveCardInfo.positionList" [(ngModel)]="moveCardInfo.targetPosition">
                    </p-dropdown>
                </div>
            </div>
            <div class="action">
                <p-button label="Create Card" (onClick)="duplicateCard(moveCardInfo,opDuplicate)" [disabled]="!moveCardInfotitle"></p-button>
            </div>
    </div>
</p-overlayPanel>

<p-overlayPanel #opChangeNameCard (onHide)="cancelEditCardNameTg()">
    <div class="ui-g-12">
        <input type="text" [(ngModel)]="cardNameUpdate" pInputText placeholder="Card Name" autofocus>
    </div>
    <div class="ui-g-12 notes-action">
        <p-button label="Save" (click)="changeCardName(); opChangeNameCard.hide()"
                  [disabled]="!selectedCard.name || selectedCard.name.trim() === ''"></p-button>
        <p-button label="Cancel" (onClick)="opChangeNameCard.hide();cancelEditCardNameTg();"></p-button>
    </div>
</p-overlayPanel>

<p-dialog header="Estimation Task" [(visible)]="showEstTaskDialog" class="est-task-popup" showEffect="true"
    [modal]="true" >
    <app-freelancer-assign-task [freelancerId]="freelancerId" [taskId]="this.selectedCard.id"
                            [modeType]="modeType"
                            (onCloseDialog)="onCloseEstTask($event)"
                            (onExisted)="onExistedEstTask($event)"
                            [changeStatusAssignTicket]="changeStatus"
                            (onConfirm)="onConfirmTask($event)"
                            (onDelete)="onDeleteEstTask($event)"
                            (currentStatus)="onCurrentStatus($event)"
                            [userDetails]="memeberForEmail"
                            [boardAdminId]="currenBoard?.userId"
                            [boardCreatedBy]="currenBoard?.createdByUsr"
                            [boardName]="currenBoard?.name"
                            [internalId]="selectedCard?.internalId"
    ></app-freelancer-assign-task>
</p-dialog>

<p-dialog [showHeader]="true" [modal]="true" [closable]="true" [(visible)]="showConfirmTicketDialog"
    [dismissableMask]="true" showEffect="fade" width="800">
    <p-header style="font-size: 15.5px;">
        Confirm Ticket
    </p-header>
    <div class="ui-g-12 ui-lg-12">
        <span class="flex-none">Do you really want to confirm ticket</span>
    </div>
    <div class="button-container-dialog text-center" style="padding: 3%;">
        <button pButton class="blue-btn" label="Yes" (click)="confirmTicket(1)"></button>
        <button pButton label="No" (click)="confirmTicket(2)"></button>
    </div>
</p-dialog>

<p-dialog [showHeader]="true" [modal]="true" [closable]="true" [(visible)]="showApproveTicketDialog"
    [dismissableMask]="true" showEffect="fade" width="800">
    <p-header style="font-size: 15.5px;">
        Approve Ticket
    </p-header>
    <div class="ui-g-12 ui-lg-12">
        <span class="flex-none">Do you really want to approve ticket</span>
    </div>
    <div class="button-container-dialog text-center" style="padding: 3%;">
        <button pButton class="blue-btn" label="Yes" (click)="approveTicket(1)"></button>
        <button pButton label="No" (click)="approveTicket(2)"></button>
    </div>
</p-dialog>

<p-dialog [showHeader]="true" [modal]="true" [closable]="true" [(visible)]="showTakebackTicketDialog"
    [dismissableMask]="true" showEffect="fade" width="800">
    <p-header style="font-size: 15.5px;">
        Take Back Ticket
    </p-header>
    <div class="ui-g-12 ui-lg-12">
        <span class="flex-none">Do you really want to take back ticket</span>
    </div>
    <div class="button-container-dialog text-center" style="padding: 3%;">
        <button pButton class="blue-btn" label="Yes" (click)="takebackTicket(1)"></button>
        <button pButton label="No" (click)="takebackTicket(2)"></button>
    </div>
</p-dialog>

<p-dialog [showHeader]="true" [modal]="true" [closable]="true" [(visible)]="showRevertConfirmedTicketDialog"
    [dismissableMask]="true" showEffect="fade" width="800">
    <p-header style="font-size: 15.5px;">
        Take Back Ticket
    </p-header>
    <div class="ui-g-12 ui-lg-12">
        <span class="flex-none">Are you sure you want to take back the ticket's confirmation?</span>
    </div>
    <div class="button-container-dialog text-center" style="padding: 3%;">
        <button pButton class="blue-btn" label="Yes" (click)="revertConfirmedTicket(1)"></button>
        <button pButton label="No" (click)="revertConfirmedTicket(2)"></button>
    </div>
</p-dialog>

<p-dialog [showHeader]="true" [modal]="true" [closable]="true" [(visible)]="showRevertCompletedTicketDialog"
    [dismissableMask]="true" showEffect="fade" width="800">
    <p-header style="font-size: 15.5px;">
        Take Back Ticket
    </p-header>
    <div class="ui-g-12 ui-lg-12">
        <span class="flex-none">Are you sure you want to mark this ticket as incomplete?</span>
    </div>
    <div class="button-container-dialog text-center" style="padding: 3%;">
        <button pButton class="blue-btn" label="Yes" (click)="revertCompletedTicket(1)"></button>
        <button pButton label="No" (click)="revertCompletedTicket(2)"></button>
    </div>
</p-dialog>


<p-dialog [showHeader]="true" [modal]="true" [closable]="true" [(visible)]="showCompletedTicketDialog"
    [dismissableMask]="true" showEffect="fade" width="800">
    <p-header style="font-size: 15.5px;">
        {{'header.confirmation' | translate}}
    </p-header>
    <div class="ui-g-12 ui-lg-12">
        <span class="flex-none">{{'closeConfirmedTask' | translate}}</span>
    </div>
    <div class="button-container-dialog text-center" style="padding: 3%;">
        <button pButton class="blue-btn" label="Yes" (click)="completeTicket(1)"></button>
        <button pButton label="No" (click)="completeTicket(2)"></button>
    </div>
</p-dialog>


<p-overlayPanel #sortCol appendTo="body">
    <div>
        <p class="text-center" style="margin-bottom: 10px;">
            <span class="material-icons" style="float: left;"> chevron_left </span>
            Sort list
            <span class="material-icons" style="float: right;" (click)="sortCol.hide()"> clear </span>
        </p>
    </div>
    <hr>
    <div class="sort-list">
        <p (click)="sortCards('dateAsc')" [ngClass]="{'highLight': sortType === 'dateAsc'}" >Date Created (Newest First)</p>
        <p (click)="sortCards('dateDesc')" [ngClass]="{'highLight': sortType === 'dateDesc'}">Date Created (Oldest First)</p>
        <p (click)="sortCards('AtoZ')" [ngClass]="{'highLight': sortType === 'AtoZ'}">Card Name (A to Z)</p>
        <p (click)="sortCards('ZtoA')" [ngClass]="{'highLight': sortType === 'ZtoA'}">Card Name (Z to A)</p>
        <p (click)="sortCards('SelectCards')" [ngClass]="{'highLight': (sortType === 'SelectCards' && selectedColumnToSort.multiSelect)}">Select Cards</p>
        <p *ngIf="isSuperAdmin || isBoardAdmin() || isSubSuper" (click)="sortCards('HideList')" [ngClass]="{'highLight': sortType === 'HideList'}">Hide List</p>
    </div>
</p-overlayPanel>

<p-overlayPanel #checkList appendTo="body" [style]="{width: '320px'}">
    <div class="ui-g">
    <div class="ui-g-12 text-center" style="border-bottom: 1px solid rgb(230, 230, 230);padding: 0;">
        <div class="ui-g-11">
            Add Checklist
        </div>
        <div class="ui-g-1">
            <i class="material-icons" (click)="checkList.hide()">close</i>
        </div>
    </div>
    <div class="ui-g-12 ">
        <p>Title</p>
        <input type="text" pInputText placeholder="Checklist" [(ngModel)]="checkListName" 
        (keydown)="Space($event)" autofocus #myTextBox="ngModel">
        <div *ngIf="!myTextBox.value" style="color:red;">
            Please enter valid input !
        </div> 
        <button [disabled]="!myTextBox.value" pButton label="Add" class="mt-3" (click)="saveCheckList();checkList.hide()"></button>
    </div>
</div>
</p-overlayPanel>
<p-overlayPanel #deleteCheckList appendTo="body" [style]="{width: '250px'}">
    <div class="ui-g-12">
        Are you sure you want to delete this checklist?
    </div>
    <div class="ui-g-12 text-center">
        <button pButton label="Yes" (click)="delChecklist();deleteCheckList.hide()"></button>
        <span class="option" (click)="deleteCheckList.hide()">No</span>
    </div>
</p-overlayPanel>
<p-overlayPanel #opPriorityType>
    <div class="panel-visibility-mode">
        <div class="panel-visibility-item mt-3 d-flex align-center">
            <p-radioButton [(ngModel)]="selectedCard.priorityType" name="PRIORITY_TYPE" [value]="PRIORITY_TYPE.HIGH" label="High"></p-radioButton>
            <div class="exclamation-mark-icon">!!!</div>
        </div>
        <div class="panel-visibility-item d-flex align-center">
            <p-radioButton [(ngModel)]="selectedCard.priorityType" name="PRIORITY_TYPE" [value]="PRIORITY_TYPE.MEDIUM" label="Medium"></p-radioButton>
            <div class="exclamation-mark-icon" style="background: darkorange; margin-left: 60px">!!</div>
        </div>
        <div class="panel-visibility-item d-flex align-center">
            <p-radioButton [(ngModel)]="selectedCard.priorityType" name="PRIORITY_TYPE" [value]="PRIORITY_TYPE.LOW" label="Low"></p-radioButton>
            <div class="exclamation-mark-icon" style="background: #00b700">!</div>
        </div>
        <div class="panel-visibility-item d-flex align-center">
            <p-radioButton [(ngModel)]="selectedCard.priorityType" name="PRIORITY_TYPE" [value]="PRIORITY_TYPE.NONE" label="None"></p-radioButton>
        </div>
    </div>
    <p-button class="mt-1" label="Save" (onClick)="opPriorityType.hide(); updateCard(true)"></p-button>
</p-overlayPanel>
<p-dialog [showHeader]="true" [modal]="true" [closable]="true" [(visible)]="showBoardNotice" [dismissableMask]="true" showEffect="fade" [style]="{'width':'40%'}">
    <p-header style="font-size: 15.5px;">
        {{'Board Notice' | translate}}
    </p-header>
    <div class="ui-g-12 ui-lg-12">
        <textarea pInputTextarea [(ngModel)]="currenBoard.boardNotice" rows="20" maxlength="5000" style="font-size: 16px; resize: none;"
                  [ngModelOptions]="{standalone: true}" [readOnly]="!allowEditBoardNotice"></textarea>
    </div>
    <p-footer class="d-flex justify-content-center">
        <button *ngIf="allowEditBoardNotice" class="btn btn-primary mr-2" (click)="saveBoardNotice()"><i class="fa-solid fa-check mr-2"></i>{{'button.save' | translate}}</button>
        <button class="btn btn-danger ml-2" (click)="showBoardNotice = false"><i class="fa-solid fa-xmark mr-2"></i>{{'close' | translate}}</button>
    </p-footer>
</p-dialog>
