import { Injectable } from '@angular/core';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { Observable } from 'rxjs';
import { Page } from '../../shared/model/page.model';
import { TransactionDetail } from '../model/transaction-detail.model';
import { environment } from '../../../environments/environment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { map } from 'rxjs/operators';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({ providedIn: 'root' })
export class TransactionDetailsService extends AbstractService<TransactionDetail> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.OPERATOR, '');
  }
  filter(options: any): Observable<Page<TransactionDetail>> {
    const reqUrl = `${this.baseUrl}${Constants.URI.TRANSACTION_DETAIL.ALL}`;
    return this._http.post<Page<TransactionDetail>>(this.baseUrl, options, { params: { page: options.page, size: options.size, sort: options.sort } }).pipe(map(res => res));
  }

  loadLists(options: any) {
    // return this._http.post<Page<TransactionDetail>>(`http://localhost:8007/api/v2/operation/transaction-detail/search`, options).pipe(map(res => res));
    return this._http.post<Page<TransactionDetail>>(`${environment.v2_server_ip}/operation-service/api/v2/operation/transaction-detail/search`, options).pipe(map(res => res));
  }

  deleteTransaction(id: Number) {
    return this._http.delete(`${environment.v2_server_ip}/operation-service/api/v2/operation/transaction-detail/${id}`).pipe(map(data => data));
  }

  loadPaymentInfo(options: any) {
    //return this._http.post(`http://localhost:8007/api/v2/operation/transaction-detail/balance`, options).pipe(map(res => res));
    return this._http.post(`${environment.v2_server_ip}/operation-service/api/v2/operation/transaction-detail/balance`, options).pipe(map(res => res));
  }

  loadBalanceInfo(options: any) {

    //return this._http.post('http://localhost:8007/api/v2/operation/transaction-detail/balance', options).pipe(map(res => res));
    return this._http.post(environment.v2_server_ip + '/operation-service/api/v2/operation/transaction-detail/balance', options).pipe(map(res => res));
  }

  exportAsExcelFile(json: any[], excelFileName: string, fileExtension?: any): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const wscols = [ {wch:12},{wch:15}, {wch:10},{wch:10},{wch:10},{wch:15}];
  
    worksheet['!cols'] = wscols;
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

    const excelBuffer: any = XLSX.write(workbook, { bookType: fileExtension, type: 'array' });
    if (fileExtension) {
      this.saveAsExcelFile(excelBuffer, excelFileName, fileExtension);
    } else {
      this.saveAsExcelFile(excelBuffer, excelFileName, '.xlsx');
    }
  }

  saveAsExcelFile(buffer: any, fileName: string, fileExtension?: string): void {
    let downloadFileExtension: string = EXCEL_EXTENSION;
    if (fileExtension) {
      downloadFileExtension = fileExtension;
    }
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    // FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + '.' + fileExtension);
  }

  generatePdfFile(options: any) {
    // return this._http.post<any>(`http://localhost:8007/api/v2/operation/transaction-detail/generatePdf`, options).pipe(map(res => res));
    return this._http.post<any>(`${environment.v2_server_ip}/operation-service/api/v2/operation/transaction-detail/generatePdf`, options).pipe(map(res => res));
  }

  findAllCreatedByContractorInvoiceByCompanyId(companyId: number): Observable<any> {
    return this._http.get(`${environment.v2_server_ip}/operation-service/api/v2/operation/transaction-detail/all-created-by/${companyId}`)
      .pipe(map(resp => resp));
  }
  
  updateTransactionDetail(entity, id: Number){
    return this._http.put(`${environment.v2_server_ip}/operation-service/api/v2/operation/transaction-detail`, entity).pipe(map(res => res));
  }

  getViewGraph(options: any): Observable<any> {
    // return this._http.post<any>(`http://localhost:8007/api/v2/operation/transaction-detail/get-view-graph`, options).pipe(map(resp => resp));
    return this._http.post<any>(`${environment.v2_server_ip}/operation-service/api/v2/operation/transaction-detail/get-view-graph`, options).pipe(map(res => res));
  }

  updateTransactionConfirm(entity) {
    // return this._http.put<any>(`http://localhost:8007/api/v2/operation/transaction-detail/confirm`, entity).pipe(map(resp => resp));
    return this._http.put(`${environment.v2_server_ip}/operation-service/api/v2/operation/transaction-detail/confirm`, entity).pipe(map(res => res));
  }

  getAmountAvailableBillingAccount(billingAccountId: number): Observable<any> {
    return this._http.get(`${environment.v2_server_ip}/operation-service/api/v2/operation/transaction-detail/amount-available-billing-account/${billingAccountId}`)
    .pipe(map(resp => resp));
  }

  getByInvoiceId(options: any) {
    return this._http.post<Page<TransactionDetail>>(`${environment.v2_server_ip}/operation-service/api/v2/operation/transaction-detail/get-by-invoice-id`, options).pipe(map(res => res));
    // return this._http.post<Page<TransactionDetail>>(`http://localhost:8007/api/v2/operation/transaction-detail/get-by-invoice-id`, options).pipe(map(res => res));
  }
}
