import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../shared/service/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService , private router: Router) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (localStorage.getItem('_pastDueInvoice') === 'yes' 
        && !(state.url === '/app/payments/detail' || state.url === '/app/payments/method' 
            || state.url === '/app/payments/detail/list' || state.url === '/app/payments/invoice' || state.url === '/app/client/dasboard')) {
        return false;
      }
      if (this.authService.authenticateUser()) {
        return true;
      }

      // Invalid User - redirect to login
      const parseURI = encodeURIComponent(state.url);
      this.router.navigate(['login'], { queryParams: { 'redirectURL': parseURI}, queryParamsHandling: 'merge' });
      return false;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authService.authenticateUser()) {
            return true;
        }
        this.router.navigate(['/', 'login']);
        return false;
    }
}
