import {Component, OnInit, ViewChild} from '@angular/core';
import {MessageService, OverlayPanel} from 'primeng';
import {Message} from 'aws-sdk/clients/cloudwatch';
import {AuthService} from '../../../shared/service/auth/auth.service';
import {CompanyService} from '../../../company/service/company.service';
import {DropDownsService} from '../../../shared/service/drop-downs/drop-downs.service';
import {PaymentInvoiceService} from '../../../payment/service/payment-invoice.service';
import {NavigationEnd, Router} from '@angular/router';
import {MonitoringDetailsService} from '../../../admin/process-monitor/service/monitoring-details.service';
import moment from 'moment';
import {FileUtility} from '../../../shared/utility/file.utility';
import * as _ from 'lodash';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {FollowUpBillingInvoice} from '../../../payment/model/follow-up-billing-invoice.model';
import {FollowUpBillingInvoiceService} from '../../../payment/service/follow-up-billing.service';
import {TranslatePipe} from '@ngx-translate/core';
import {BrowserNotification} from '../../../shared/model/browser-notification.model';
import {PaymentInvoice} from '../../../payment/model/payment-invoice.model';
import {BrowserNotificationService} from '../../../shared/service/browser-notification/browser-notification.service';
import {OperatorService} from '../../../employee/service/v2/operator.v2.service';
import { ExportMonitorService } from 'app/admin/process-monitor/service/export-monitor.service';
import { MENU_ID } from 'app/shared/data/menu-id';


@Component({
  selector: 'app-aging-summary',
  templateUrl: './aging-summary.component.html',
  styleUrls: ['./aging-summary.component.scss']
})
export class AgingSummaryComponent implements OnInit {

  @ViewChild('op3', { static: true }) op3: OverlayPanel;
  @ViewChild('notesEditor', { static: true }) followUpNoteEditor: any;

  isPlatformAdmin = false;
  isClientRole = false;

  allCompanyList: any[] = [];
  selectedCompany: any;
  allClientList: any[] = [];
  clientList: any[] = [];
  selectedClient: any;
  fromDate: Date;
  toDate: Date;
  loading = false;
  size: number;
  page: number;
  rows = 10;
  sortField: string;
  sortOrder: number = 0;
  totalRecords = 0;
  selectedStatuses;
  selectedToShow: String = 'ALL';
  statusTypes: any[] = [
    { label: 'Pending', value: 'Pending' },
    { label: 'Unpaid', value: 'Unpaid' },
    { label: 'Partially Paid', value: 'Partial Paid' },
    { label: 'Paid', value: 'Paid' }
  ];
  showSelectedList: any[] = [
    { label: 'All', value: 'ALL' },
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Non-Zero', value: 'NONZERO' }
  ];
  exportOptions = [
    { name: 'View Details', value: 'view' },
    { name: 'Download Invoice', value: 'download' },
  ];
  cols = [
    { field: 'clientName', label: 'Client', sortOptions: '', sort: 'text' },
    { field: 'invoiceNumber', label: 'Current', sortOptions: '', sort: 'text' },
    { field: 'section1', label: '1-30', sortOptions: '', sort: 'number' },
    { field: 'section2', label: '31-60', sortOptions: '', sort: 'number' },
    { field: 'section3', label: '61-90', sortOptions: '', sort: 'number' },
    { field: 'section4', label: '91-120', sortOptions: '', sort: 'number' },
    { field: 'section5', label: '120+', sortOptions: '', sort: 'number' },
    { field: 'total', label: 'Total', sortOptions: '', sort: 'number' },
    { field: 'notes', label: 'FollowUps', sortOptions: '' },
  ];

  invoiceRef: any = {};
  exportAs: any;
  timespent = new Date();
  msgs: Message[] = [];
  companyInfo;

  textSortOptions = [
    { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
    { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
  ];
  numberSortOptions = [
    { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];

  selectedSortOption = '';
  selectedField = '';
  rowGroupMetadata: any;
  agingSummaries: any = [];
  lastRow: any = {};
  showLastRow: boolean = false;
  agingDefault = {
    id: '',
    clientName: '',
    invoiceNumber: '',
    section1: 0,
    section2: 0,
    section3: 0,
    section4: 0,
    section5: 0,
    total: 0,
    balance: 0,
    pdfLink: '',
    clientId: '',
    amount: 0,
    detail: []
  }

  employeeList: any = [];
  isOverTime: boolean = false;
  isOwnerNote: boolean = false;
  isOpenCollectionNote: boolean = false;
  paymentInvoiceData: any = {};
  isNew: any;
  followUpDate: any;
  headerNote: any;
  selectedCollectionPresentative: any;
  collectionTypeSelected: any;
  selectedContact: any;
  followUpNote: any;
  followUpCount = 0;
  charactersLength = 0;
  MAX_LENGTH = 500;
  assignedToEmployeeList: any = [];
  assignedToEmployeeMentionList: any = [];
  paymentInvoiceList: PaymentInvoice[] = [];
  clientId: any;

  collectionTypeOptions = [
    { label: 'Follow-up', value: 'Follow-up' },
    { label: 'Payment Refusal', value: 'Payment Refusal' },
    { label: 'Delay Payment Requested', value: 'Delay Payment Requested' }
  ];
  contacts = [
    { label: 'Phone', value: 'Phone Call' },
    { label: 'SMS', value: 'Text Message' },
    { label: 'Email', value: 'Email' },
    { label: 'In-Person', value: 'In Person Contact' }
  ];
  showDetailPopup: boolean = false
  agingDetails: any = {
    name: '',
    data: '',
  }

  routerEventsSubscribe;

  constructor(private authService: AuthService,
              private companyService: CompanyService,
              private dropDownsService: DropDownsService,
              private paymentInvoiceService: PaymentInvoiceService,
              private router: Router,
              private exportMonitorService: ExportMonitorService,
              private messageService: MessageService,
              private monitoringDetailsService: MonitoringDetailsService,
              private followUpBillingInvoiceService: FollowUpBillingInvoiceService,
              private translatePipe: TranslatePipe,
              private browserNotificationService: BrowserNotificationService,
              private operatorService: OperatorService) {
    this.routerEventsSubscribe = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('app/aging-summary/list')) {
          this.monitoringDetailsService.monitorAction(
              `Navigated to Aging Summary`,
              this.timespent,
              {
                navigated_to_accounts_aging_summary_by: this.authService.getCurrentLoggedInName()
              },
              'complete',
              `Navigated to Aging Summary`,
              0
          );
        }
      }
    });
  }

  ngOnInit(): void {
    this.isClientRole = this.authService.isClientRole();
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();

    if (this.isPlatformAdmin) {
      this.loadAllCompanies();
    } else {
      this.selectedCompany = this.authService.getCurrentCompanyId();
      this.companyService.getCompanyByCompanyId(this.selectedCompany).subscribe(res => {
        this.companyInfo = res.data;
      });
    }
    this.loadEmployeeDropDown();
    this.loadAllClient();
    this.search();
  }

  ngOnDestroy() {
    if (this.routerEventsSubscribe) {
      this.routerEventsSubscribe.unsubscribe();
    }
  }
  
  loadAllCompanies() {
    this.dropDownsService.getAllCompanyList().subscribe((res) => {
      const resObj: any = res;
      this.allCompanyList = [];
      if (resObj.status === 'SUCCESS') {
        this.allCompanyList = _.sortBy(resObj.data, 'value');
      }
    });
  }

  loadAllClient() {
    let companyId;
    if (this.isPlatformAdmin)
      companyId = this.selectedCompany;
    else
      companyId = this.authService.getCurrentCompanyId();

    const options: any = {
      size: 99999,
      page: 0,
      moduleName: 'billing',
      companyId: companyId || 0,
      // status: 1,
    };

    this.dropDownsService.getAllClientList(options).subscribe((res) => {
      this.clientList = [];
      this.allClientList = [];
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        const data = resObj.data.sort((a, b) => a.value.localeCompare(b.value));
        this.allClientList = data.map(c => ({ value: c.key, label: c.value, email: c.email }));
        this.clientList = data;
      }
    });
  }

  changeCompany() {
    if (this.selectedCompany) {
      this.loadAllClient();
    }
  }

  changeClient() {
    this.search();
  }

  search(event?: any) {
    this.monitoringDetailsService.monitorAction(
        `Searched Accounts Receivable Aging Details`,
        this.timespent,
        {
          search_to_accounts_aging_summary_by: this.authService.getCurrentLoggedInName()
        },
        'complete',
        `Searched Accounts Receivable Aging Details`,
        0
    );
    const options = this.buildOptions(event, 0, 99999);
    this.loadPage(options);
  }

  buildOptions(event?: any, page?, size?) {
    this.size = event ? event.rows : this.size ? this.size : 10;
    this.page = event && event.first >= 0 && event.rows > 0 ? event.first / event.rows : 0;

    const options = <any>{
      page: page ? page : this.page,
      size: size ? size : this.size
    };

    this.sortField = this.sortField ? this.sortField : event && event.sortField ? event.sortField : 'id';
    this.sortOrder = this.sortOrder ? this.sortOrder : event && event.sortOrder ? event.sortOrder : 0;

    if (this.sortField === 'id') {
      this.sortOrder = 0;
    }

    options.sortField = this.sortField;
    options.sortOrder = this.sortOrder === 1 ? 'ASC' : 'DESC';

    if (this.isPlatformAdmin) {
      options.companyId = this.selectedCompany;
    } else {
      options.companyId = this.authService.getCurrentCompany();
    }

    if (this.selectedClient != null) {
      options.clientId = this.selectedClient.key;
    }

    if (this.fromDate) {
      options.fromDate = moment(this.fromDate).utc(true).startOf('day').toDate();
      options.startDate = options.fromDate;
    }

    if (this.toDate) {
      options.toDate = moment(this.toDate).utc(true).endOf('day').toDate();
      options.endDate = options.toDate;
    }

    if (this.selectedStatuses && this.selectedStatuses.length > 0 && this.selectedStatuses.length !== this.statusTypes.length) {
      options.listStatus = this.selectedStatuses;
    } else {
      options.listStatus = null;
    }

    options.oldInvoiceOnly = false;
    options.ignoreInvoiceDetails = true;

    return options;
  }

  loadPage(options) {
    this.loading = true;
    this.paymentInvoiceService.filter(options).subscribe(res => {
      this.loading = false;
      this.agingSummaries = [];
      this.paymentInvoiceList = [];
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {

        const data = resObj.data.content;

        this.lastRow = Object.assign({}, this.agingDefault);
        this.lastRow.clientName = 'Total';

        for (const invoice of data) {
          if (this.checkInvoice(invoice) && invoice.status !== 'Paid') {
            let newAging: boolean = false;
            let aging: any = this.agingSummaries.find(a => a.clientId === invoice.clientId);
            if (!aging) {
              aging = Object.assign({}, this.agingDefault);
              aging.clientId = invoice.clientId;
              newAging = true;
            }

            const company = this.allCompanyList.find(com => com.value === invoice.companyId);
            if (company) {
              invoice.companyName = company.label;
            }

            const client = this.allClientList.find(com => com.value === invoice.clientId);
            if (client) {
              aging.clientName = client.label;
              invoice.clientName = client.label;
            }

            const invoiceDate = new Date(invoice.invoiceDate);
            invoiceDate.setMinutes(invoiceDate.getMinutes() + invoiceDate.getTimezoneOffset());
            invoice.invoiceDate = invoiceDate;
            this.paymentInvoiceList.push(Object.assign({}, invoice));

            const diff = moment().diff(moment(invoice.invoiceDate), 'd');

            if (diff >= 0) {
              aging.total += (invoice.balance || 0);
              this.lastRow.total += (invoice.balance || 0);

              if (!aging.detail)
                aging.detail = []

              if (diff < 1) {
                aging.invoiceNumber = invoice.invoiceNumber;
                aging.pdfLink = invoice.pdfLink;
                aging.id = invoice.id;
                aging.amount = (invoice.balance || 0);
                aging.balance = (invoice.balance || 0);
              } else if (diff <= 30) {
                aging.section1 += (invoice.balance || 0);
                this.lastRow.section1 += (invoice.balance || 0);

                invoice.sectionType = '1'
                aging.detail.push(invoice)
              } else if (diff <= 60) {
                aging.section2 += (invoice.balance || 0);
                this.lastRow.section2 += (invoice.balance || 0);

                invoice.sectionType = '2'
                aging.detail.push(invoice)
              } else if (diff <= 90) {
                aging.section3 += (invoice.balance || 0);
                this.lastRow.section3 += (invoice.balance || 0);

                invoice.sectionType = '3'
                aging.detail.push(invoice)
              } else if (diff <= 120) {
                aging.section4 += (invoice.balance || 0);
                this.lastRow.section4 += (invoice.balance || 0);

                invoice.sectionType = '4'
                aging.detail.push(invoice)
              } else {
                aging.section5 += (invoice.balance || 0);
                this.lastRow.section5 += (invoice.balance || 0);

                invoice.sectionType = '5'
                aging.detail.push(invoice)
              }
            }

            if (newAging) {
              const length = invoice.followUpBillingInvoices?.filter(f => f.type === 'AGING_SUMMARY')?.length || 0;
              if (length > 0) {
                aging.followUpNoteDate = invoice.followUpBillingInvoices[length - 1].createdAt;
                aging.numOfFollowUpNote = length;
              }

              this.agingSummaries.push(aging);
            }
          }
        }

        this.totalRecords = this.agingSummaries.length || 0;
        this.showLastRow = (this.lastRow.section1 > 0 || this.lastRow.section2 > 0 || this.lastRow.section3 > 0 || this.lastRow.section4 > 0 || this.lastRow.section5 > 0 || this.lastRow.total > 0);
      } else {
        this.agingSummaries = [];
        this.totalRecords = 0;
        this.paymentInvoiceList = [];
      }
    }, () => {
      this.loading = false;
      this.agingSummaries = [];
      this.totalRecords = 0;
      this.paymentInvoiceList = [];
    });
  }

  countNote(data) {
    this.paymentInvoiceList.filter(d => {
      if (d.clientId === data.clientId && d.followUpBillingInvoices && d.followUpBillingInvoices.length > 0) {
        data.numOfFollowUpNote = d.followUpBillingInvoices.filter(n => n.type === 'AGING_SUMMARY')?.length || 0;
        data.followUpNoteDate = data.numOfFollowUpNote > 0 ? d.followUpBillingInvoices[length - 1].createdAt : null;
      }
    });
  }

  clickInvoiceRef(event: any, rowData) {
    this.op3.toggle(event);
    this.invoiceRef = rowData;
  }

  invoiceRefAction(type: string) {
    switch (type) {
      case 'view': this.exportPdf(this.invoiceRef);
        break;
      case 'download': this.downloadInvoiceRefPdf();
        break;
    }
  }

  exportPdf(invoice) {
    this.op3.hide();
    if (invoice.pdfLink) {
      window.open(invoice.pdfLink, '_blank');
    } else {
      this.paymentInvoiceService.exportPdf(invoice.id).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
            let data = <any>{
                featureName: 'Aging Summary',
                fileName: blobUrl,
                fileSize: blob.size,
                action: 'Export',
                createdByUsr: this.authService.getCurrentUsername(),
                companyId: this.authService.getCurrentCompanyId()
            };
            this.exportMonitorService.create(data).subscribe(()=>{
            });
        }
      });
    }
  }

  downloadInvoiceRefPdf() {
    this.op3.hide();
    this.paymentInvoiceService.exportPdfWithName(this.invoiceRef.id).subscribe(res => {
      if (res.data) {
        const blob = FileUtility.b64toBlob(res.data.blob.body, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.target = '_blank';
        let fileName = 'Dummy.pdf';
        if (res.data.fileName && res.data.fileName.length > 0) {
          fileName = res.data.fileName;
        }
        a.download = fileName;
        a.click();
        const args = {
          ticket_invoice_download_by: this.authService.getCurrentLoggedInName(),
          Ticket_id: this.invoiceRef.invoiceId,
        };
      }
    });
  }

  sortDataSelected(selectedSortOption) {
    this.sortField = this.selectedField;
    this.sortOrder = selectedSortOption === 'ASC' ? 1 : 0;
    this.search();
  }

  getCurrentDateString() {
    return moment().format('LL');
  }

  reset() {
    if (this.isPlatformAdmin) {
      this.selectedCompany = null;
    }
    this.selectedClient = null;
    this.selectedStatuses = null;
    this.fromDate = null;
    this.toDate = null;
    this.sortField = null;
    this.sortOrder = null;
    this.page = 0;
    this.size = 99999;
    this.selectedToShow = 'ALL';
    this.search();
  }

  private checkInvoice(invoice) {
    if (this.selectedToShow !== 'ALL') {
      if (this.selectedToShow === 'NONZERO') {
        return (invoice.totalFee > 0);
      } else {
        return invoice.totalFee > 0 && invoice.status !== 'Paid';
      }
    }
    return true;
  }

  exportTable() {
    const doc = this.buildPdf();
    let output = doc.output('bloburl');
    window.open(output.toString(), '_blank');
  }

  buildPdf(): jsPDF {
    const pdfBody: any = this.getPdfBody();
    const doc = new jsPDF();
    autoTable(doc, {
      body: [
        [
          {
            content: "Aging Summary",
            styles: {
              halign: 'center',
              fontSize: 20,
              fontStyle: 'bold'
            }
          }
        ],
        [
          {
            content: 'As of ' + moment().format('LL'),
            styles: {
              halign: 'center',
              fontSize: 11,
            }
          }
        ]
      ],
      theme: 'plain'
    });

    autoTable(doc, {
      margin: 0,
      head: [
        [
          {
            content: '',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 35
            }
          },
          {
            content: 'Current',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 25
            }
          },
          {
            content: '1 - 30',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 20
            }
          },
          {
            content: '31 - 60',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 20
            }
          },
          {
            content: '61 - 90',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 20
            }
          },
          {
            content: '91 - 120',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 20
            }
          },
          {
            content: '120+',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 20
            }
          },
          {
            content: 'Total',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 25
            }
          }
        ],
      ],
      theme: 'striped',
      columnStyles: {
        0: {cellWidth: 35},
        1: {cellWidth: 25},
        2: {cellWidth: 20},
        3: {cellWidth: 20},
        4: {cellWidth: 20},
        5: {cellWidth: 20},
        6: {cellWidth: 20},
        7: {cellWidth: 25},
      },
      styles: {
        lineWidth: 0.5,
      },
      bodyStyles: {
        fillColor: 255
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245]
      },
      body: pdfBody,
    });
    return doc;
  }

  getPdfBody() {
    const cloneList = this.agingSummaries.map(x => Object.assign({}, x));
    const cloneLastRow = Object.assign({}, this.lastRow);
    cloneLastRow.amount = -1;
    cloneList.push(cloneLastRow);

    return cloneList.map(row => {
      if (row.clientName === 'Total' && row.amount === -1) {
        return [
          {
            content: row.clientName,
            colSpan: 2,
            styles: {
              fontStyle: 'bold',
              halign: 'left'
            }
          },
          {
            content: '$' + (row.section1 || 0).toFixed(2),
            styles: {
              fontStyle: 'bold',
              halign: 'left'
            }
          },
          {
            content: '$' + (row.section2 || 0).toFixed(2),
            styles: {
              fontStyle: 'bold',
              halign: 'left'
            }
          },
          {
            content: '$' + (row.section3 || 0).toFixed(2),
            styles: {
              fontStyle: 'bold',
              halign: 'left'
            }
          },
          {
            content: '$' + (row.section4 || 0).toFixed(2),
            styles: {
              fontStyle: 'bold',
              halign: 'left'
            }
          },
          {
            content: '$' + (row.section5 || 0).toFixed(2),
            styles: {
              fontStyle: 'bold',
              halign: 'left'
            }
          },
          {
            content: '$' + (row.total || 0).toFixed(2),
            styles: {
              fontStyle: 'bold',
              halign: 'left'
            }
          }
        ]
      }

      return [
        {
          content: row.clientName,
          styles: {
            halign: 'left'
          }
        },
        {
          content: '$' + (row.amount || 0).toFixed(2),
          styles: {
            halign: 'left'
          }
        },
        {
          content: '$' + (row.section1 || 0).toFixed(2),
          styles: {
            halign: 'left'
          }
        },
        {
          content: '$' + (row.section2 || 0).toFixed(2),
          styles: {
            halign: 'left'
          }
        },
        {
          content: '$' + (row.section3 || 0).toFixed(2),
          styles: {
            halign: 'left'
          }
        },
        {
          content: '$' + (row.section4 || 0).toFixed(2),
          styles: {
            halign: 'left'
          }
        },
        {
          content: '$' + (row.section5 || 0).toFixed(2),
          styles: {
            halign: 'left'
          }
        },
        {
          content: '$' + (row.total || 0).toFixed(2),
          styles: {
            halign: 'left'
          }
        }
      ]
    });
  }

  formatDate(date: any) {
    if (date) {
      return moment.utc(moment(date).format('MM/DD/YYYY')).local().format('MM/DD/YYYY');
    }
    return null;
  }

  isEnableFollowUps() {
    return this.authService.hasEditPermission(MENU_ID.FOLLOW_UPS);
  }

  redirectToFollowUptUrl(rowData: any) {
    if (!this.isEnableFollowUps()) {
      return;
    }
    localStorage.removeItem('follow-up-note-number');
    localStorage.setItem('follow-up-note-number', rowData.invoiceNumber);
    this.router.navigate(['/app/payments/follow-up-note']);
  }

  openDialog(data: any, isNew?: any) {
    if (!this.isEnableFollowUps()) {
      return;
    }

    this.isOverTime = false;
    this.isOwnerNote = true;
    this.isNew = isNew;
    this.clientId = data.clientId;
    this.followUpDate = new Date();
    this.headerNote = isNew ? 'Collection Note' : 'Update Notes';

    const dataTmp = this.paymentInvoiceList.filter(p => p.clientId === data.clientId);
    if (!isNew) {
      if (dataTmp) {
        const agingNote = dataTmp.filter(d => d.followUpBillingInvoices.filter(n => n.type === 'AGING_SUMMARY')?.length > 0);
        if (agingNote) {
          this.paymentInvoiceData = agingNote[0];
          const length = this.paymentInvoiceData.followUpBillingInvoices.length;
          this.selectedCollectionPresentative = this.paymentInvoiceData.followUpBillingInvoices[length - 1].collectionRepresentative;
          this.selectedContact = this.paymentInvoiceData.followUpBillingInvoices[length - 1].methodContact;
          this.followUpNote = this.paymentInvoiceData.followUpBillingInvoices[length - 1].note;
          this.followUpDate = this.paymentInvoiceData.followUpBillingInvoices[length - 1].createdAt;
          this.collectionTypeSelected = this.paymentInvoiceData.followUpBillingInvoices[length - 1].collectionType;
          this.isOverTime = this.isOver24hrs(this.paymentInvoiceData.followUpBillingInvoices[length - 1].createdAt);
          this.isOwnerNote = this.authService.getCurrentLoggedInName() === this.paymentInvoiceData.followUpBillingInvoices[length - 1].createdByUsr;
          this.followUpCount = this.paymentInvoiceData.extendDueDateCount ? this.paymentInvoiceData.extendDueDateCount : 0;
        }
      }
    } else {
      this.selectedContact = '';
      this.followUpNote = '';
      this.followUpCount = 0;
      this.collectionTypeSelected = '';
      this.paymentInvoiceData = dataTmp ? dataTmp[0] : {};
      if (this.authService.isEmployeeRole()) {
        this.selectedCollectionPresentative = this.authService.getCurrentUsername();
      } else {
        this.selectedCollectionPresentative = null;
      }
    }

    this.charactersLength = this.followUpNote ? this.followUpNote.replace(/<\/?[^>]+(>|$)/g, '').length : 0;
    this.isOpenCollectionNote = true;
    this.timespent = new Date();
  }

  closeDialog() {
    this.clientId = '';
    this.isOpenCollectionNote = false;
    if (this.headerNote && this.headerNote === 'Update Notes') {
      this.monitoringDetailsService.monitorAction(
          'Aging Summary Follow Up Note Viewed',
          this.timespent,
          {
            aging_summary_followup_note_viewed_by: this.authService.getCurrentLoggedInName()
          },
          'complete',
          'Aging Summary Follow Up Note Viewed',
          0
      );
    }
  }

  saveAllAsync() {
    this.saveAll().then(res => {
      console.log(res);
      this.isOpenCollectionNote = false;
    })
  }

  async saveAll() {
    const invoices = this.paymentInvoiceList.filter(p => p.clientId === this.clientId);
    if (invoices) {
      for (const iv of invoices) {
        await this.saveCollectionNote(iv);
      }
    }
  }

  async saveCollectionNote(data) {
    const followUpBilling = new FollowUpBillingInvoice();
    followUpBilling.collectionRepresentative = this.selectedCollectionPresentative;
    followUpBilling.methodContact = this.selectedContact;
    followUpBilling.note = this.followUpNote;
    followUpBilling.invoiceId = data.id;
    followUpBilling.invoiceNumber = data.invoiceNumber;
    followUpBilling.clientName = data.clientName;
    followUpBilling.companyId = data.companyId;
    followUpBilling.collectionType = this.collectionTypeSelected;
    followUpBilling.invoiceDueDate = new Date(data.dueDate);
    followUpBilling.type = 'AGING_SUMMARY';

    this.msgs = [];
    if (this.followUpNoteEditor.getLength() < 25) {
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('Please add at least 25 characters')});
      return;
    }
    if (this.followUpNoteEditor.getLength() > this.MAX_LENGTH) {
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('You have exceeded the maximum text limit')});
      return;
    }

    if (data.followUpBillingInvoices && data.followUpBillingInvoices.length > 0 && !this.isNew) {
      const length = data.followUpBillingInvoices.length;
      followUpBilling.id = data.followUpBillingInvoices[length - 1].id;
      followUpBilling.createdAt = data.followUpBillingInvoices[length - 1].createdAt;
      followUpBilling.createdByUsr = data.followUpBillingInvoices[length - 1].createdByUsr;
      followUpBilling.lastModifiedBy = this.authService.getCurrentLoggedInName();

      await this.followUpBillingInvoiceService.update(followUpBilling, data.followUpBillingInvoices[length - 1].id).subscribe(res => {
        const resObj: any = res;
        this.isOpenCollectionNote = false;
        if (resObj.status === 'SUCCESS') {
          this.messageService.add({
            severity: 'info', summary: this.translatePipe.transform('Update Follow Up Note'),
            detail: this.translatePipe.transform('Follow Up Note has been updated successfully!')});

          this.paymentInvoiceList.forEach(e => {
            if (e.id === data.id) {
              e.followUpBillingInvoices[length - 1] = resObj.data;
            }
          });
          this.notifyMentionEmployee(this.followUpNote, resObj.data.id, followUpBilling);
        } else {
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: resObj.message });
        }
        this.monitoringDetailsService.monitorAction(
            'Aging Summary Follow Up Note Updated',
            this.timespent,
            {
              aging_summary_followup_note_updated_by: this.authService.getCurrentLoggedInName(),
              follow_up_note_id: resObj.data.id
            },
            'complete',
            'Aging Summary Follow Up Note Updated',
            0
        );

      }, (error) => this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: error.message }));
    } else {
      followUpBilling.createdByUsr = this.authService.getCurrentLoggedInName();

      await this.followUpBillingInvoiceService.create(followUpBilling).subscribe(res => {
        const resObj: any = res;
        this.isOpenCollectionNote = false;
        if (resObj.status === 'SUCCESS') {

          this.paymentInvoiceList.forEach(e => {
            if (e.id === data.id) {
              e.followUpBillingInvoices.push(resObj.data);

              this.agingSummaries.filter(a => a.clientId === e.clientId).map(b => {
                b.followUpNoteDate = e.followUpBillingInvoices[length - 1].createdAt;
                b.numOfFollowUpNote = (b.numOfFollowUpNote || 0) + 1;
              });
            }
          });

          this.notifyMentionEmployee(this.followUpNote, resObj.data.id, followUpBilling);
          this.messageService.add({
            severity: 'info', summary: this.translatePipe.transform('Add Follow Up Note'),
            detail: this.translatePipe.transform('Follow Up Note has been created successfully!')});
        } else {
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: resObj.message });
        }
        this.monitoringDetailsService.monitorAction(
            'Aging Summary Follow Up Note Added',
            this.timespent,
            {
              aging_summary_followup_note_added_by: this.authService.getCurrentLoggedInName(),
              follow_up_note_id: resObj.data.id
            },
            'complete',
            'Aging Summary Follow Up Note Added',
            0
        );
      }, (error) => this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: error.message }));
    }
  }

  noteChange() {
    this.msgs = [];
    if (this.followUpNoteEditor.getLength() < 25) {
      this.charactersLength = this.followUpNoteEditor.getLength() - 1;
      return;
    } else {
      this.charactersLength = this.followUpNoteEditor.getLength() - 1;
    }
    if (this.followUpNoteEditor.getLength() > this.MAX_LENGTH) {
      this.followUpNote.deleteText(this.MAX_LENGTH - 1, this.followUpNoteEditor.getLength());
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('Error'), detail: this.translatePipe.transform('You have exceeded the maximum text limit')});
    }
    this.charactersLength = this.followUpNoteEditor.getLength() - 1;
  }

  setEditor(event: any) {
    event.editor.root.focus();
    this.followUpNoteEditor = event.editor;
  }

  boldMention() {
    setTimeout(() => {
      const regexTag = /(@)([@.a-zA-Z0-9_-]*)(<\/p>)/g;
      const regexTag2 = /(@)([@.a-zA-Z0-9_-]*)( +)/g;
      this.followUpNote = this.followUpNote.replace(regexTag, '<strong>$1$2</strong>&nbsp;$3');
      this.followUpNote = this.followUpNote.replace(regexTag2, '<strong>$1$2</strong>$3');
      setTimeout(() => {
        this.followUpNoteEditor.getQuill().setSelection(this.followUpNoteEditor.value.length);
      }, 100);

    }, 500);
  }

  isOver24hrs(createdDate) {
    const currentTime = moment().tz('America/New_York');
    return currentTime.diff(createdDate, 'days') > 0;
  }

  stripHtml(html) {
    const tmp = document.createElement('tmp');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  async notifyMentionEmployee(details: string, followNoteId: number, followUpBilling: any) {
    const employeeList = details.match(/(@)([@.a-zA-Z0-9_-]*)/g);
    if (!employeeList || employeeList.length < 1) return
    for (const employee of employeeList) {
      const key = this.employeeList.find(e => '@' + e.value === employee)?.key;
      if (key != null) {
        const notification: BrowserNotification = new BrowserNotification();
        notification.entity = 'employee';
        const regex = new RegExp(employee + '(.{0,30})');
        notification.content = this.authService.getCurrentUsername() + ' mentioned you: ' + this.stripHtml(details).match(regex)[0] + '...';
        notification.logo = 'https://livestore.operrwork.com/operrwork/2021_2_11__2_10_35__1__2021_1_23__13_42_4__1__m6qxrYbF_400x400.jpg';
        notification.url = document.location.origin + '/#/app/payments/follow-up-note/' + followNoteId;
        notification.status = 'New';
        notification.entityId = key;
        await this.browserNotificationService.save(notification).toPromise();
        // add the bell notification
        const bellNotification: any = {};
        bellNotification.sourceId = followNoteId;
        bellNotification.targetTableName = 'employee';
        bellNotification.targetRoleId = 7;
        bellNotification.targetId = key;
        bellNotification.message = details;
        bellNotification.type = 'follow_up_note';
        bellNotification.tableName = 'follow_up_billing';
        bellNotification.projectId = this.authService.getCurrentCompanyId();
        bellNotification.createdByUsr = this.authService.getCurrentLoggedInName();
        bellNotification.urlLink = '/app/payments/follow-up-note/' + followNoteId;
        await this.operatorService.addBellNotification(bellNotification).toPromise();
        // send email
        const emailData: any = {};
        emailData.employeeId = key;
        emailData.methodContact = followUpBilling.methodContact;
        emailData.collectionRepresentation = followUpBilling.collectionRepresentative;
        emailData.clientName = followUpBilling.clientName;
        emailData.invoiceNumber = followUpBilling.invoiceNumber;
        emailData.note = details.replace(/<[^>]*>/g, '');
        await this.followUpBillingInvoiceService.sendFollowUpTagEmail(emailData).toPromise();
      }
    }
  }

  async loadEmployeeDropDown() {
    this.employeeList = [];
    this.assignedToEmployeeList = [];
    this.assignedToEmployeeMentionList = [];
    const options: any = {
      companyId: this.authService.getCurrentCompanyId(),
      status: 1
    };

    const employee = await this.dropDownsService.getAllEmployeeList(options).toPromise();
    if (employee.data.length > 0) {
      this.employeeList = employee.data;
      employee.data.forEach((item) => {
        const nm = item.value;
        this.assignedToEmployeeList.push({
          label: nm,
          value: nm
        });
      });
      this.assignedToEmployeeMentionList = Object.assign({}, this.assignedToEmployeeList);
    }
  }

  openDetail(rowData, section) {
    switch (section) {
      case '1':
        this.agingDetails.name = '1 - 30'
        break;
      case '2':
        this.agingDetails.name = '31 - 60'
        break;
      case '3':
        this.agingDetails.name = '61 - 90'
        break;
      case '4':
        this.agingDetails.name = '91 - 120'
        break;
      case '5':
        this.agingDetails.name = '120+'
        break;
      default:
        this.agingDetails.name = ''
        break;
    }
    
    this.agingDetails.data = (rowData.detail || []).filter(i => i.sectionType === section && i.clientId === rowData.clientId)
    this.showDetailPopup = true
  }
}
