
import { environment, environmentLocal } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../../shared/model/constants';
import { Injectable } from '@angular/core';
import { EmployeeClockingV2, EmployeePunchRequest, EmployeePunchResponse, EmployeeClockingSearchCriteria } from '../../model/v2/employee.clocking';
import { EmployeeClockingSearch } from '../../../shared/model/search/employee.clocking.search';
import { AbstractServiceV2 } from '../../../shared/service/abstract.v2.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseResponse } from '../../../shared/model/PageResponse/base.response';
import { AnyCnameRecord, AnyPtrRecord } from 'dns';
import { EmployeeSummaryDetail } from '../../../overrtime-dashboard/model/employee-summary-detail';
import * as MOMENT from 'moment';
import { EmployeeDetailExport } from 'app/employee-clocking/model/employee-detail-export.model';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class EmployeeClockingTrackingServiceV2 extends AbstractServiceV2<EmployeeClockingV2, EmployeeClockingSearch> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.CLOCKING_TRACKING, '');
  }


  verifyNextStep(employeeId: any, params:any): Observable<BaseResponse<EmployeePunchResponse>> {
    const reqUrl = `${this.baseUrlV2}/${Constants.URI.EMPLOYEECLOCKING.DO_NEXT_STEP}/${employeeId}`;
    return this._http.get<any>(reqUrl, {params:params}).pipe(map(resp => resp));
  }

  search(options: any): Observable<EmployeePunchResponse> {
    options.toDate = MOMENT(options.toDate).toISOString(true);
    options.fromDate = MOMENT(options.fromDate).toISOString(true);
    const reqUrl = `${this.baseUrlV2}/search`;
    // const reqUrl = `http://localhost:8005/api/v2/clocking/tracking/search`;
    return this._http.post<any>(reqUrl, options).pipe(map(resp => resp));
  }

  getSummaryDetail(options: any): Observable<BaseResponse<EmployeeSummaryDetail>> {
    // options.toDate = MOMENT(options.toDate).toISOString(true);
    // options.fromDate = MOMENT(options.fromDate).toISOString(true);
    const timeZonefromDate = (options.fromDate.getTimezoneOffset() / 60) * -1;
    options.fromDate.setTime(options.fromDate.getTime() + (timeZonefromDate * 60) * 60 * 1000);
    options.fromDate.toISOString();
    const timeZonetoDate = (options.toDate.getTimezoneOffset() / 60) * -1;
    options.toDate.setTime(options.toDate.getTime() + (timeZonetoDate * 60) * 60 * 1000);
    options.toDate.toISOString();
    const reqUrl = `${this.baseUrlV2}/summary-detail`;
    return this._http.post<BaseResponse<EmployeeSummaryDetail>>(reqUrl, options).pipe(map(res => res));
  }

  searchLastClocking(options: any): Observable<EmployeePunchResponse> {
      const reqUrl = `${this.baseUrlV2}/last-clocking`;
      // const reqUrl = `http://localhost:8005/api/v2/clocking/tracking/search`;
      return this._http.post<any>(reqUrl, options).pipe(map(resp => resp));
  }

  getOTPrevent(options: any): Observable<any> {
    const param = { page: options.page, size: options.size };
    const reqUrl = `${this.baseUrlV2}/prevention`;
    return this._http.post<any>(reqUrl, options).pipe(map(resp => resp));
  }

  getLunchTime(employeeId: Number): Observable<any> {
    const reqUrl = `${this.baseUrlV2}/lunch-hour/${employeeId}`;
    return this._http.get<any>(reqUrl).pipe(map(resp => resp));
  }

  exportEmployee(params: any): Observable<any> {
    const reqUrl = `${this.baseUrlV2}/prevention/export-employee`;
    return this._http.post<any>(reqUrl, params).pipe(map(resp => resp));
  }

  exportManager(params: any): Observable<any> {
    const reqUrl = `${this.baseUrlV2}/prevention/export-manager`;
    return this._http.post<any>(reqUrl, params).pipe(map(resp => resp));
  }

  exportPDF(params: any): Observable<any> {
    const reqUrl = `${this.baseUrlV2}/prevention/export-pdf`;
    return this._http.post<any>(reqUrl, params).pipe(map(resp => resp));
  }

  punchHistoryExportPDF(params: any): Observable<any> {
    const reqUrl = `${this.baseUrlV2}/punch-history/export`;
    return this._http.post<any>(reqUrl, params).pipe(map(resp => resp));
  }

  dashBoardExportPDF(params: EmployeeDetailExport): Observable<any> {
    const reqUrl = `${this.baseUrlV2}/summary-detail/export-pdf`;
    return this._http.post<any>(reqUrl, params).pipe(map(resp => resp));
  }

  dashBoardExportExcel(options: any): Observable<Blob> {
    options.toDate = MOMENT(options.toDate).toISOString(true);
    options.fromDate = MOMENT(options.fromDate).toISOString(true);
    const params = {
        page: options.page,
        size: options.size
    };
    if (options.sortField != null) {
        params['sortField'] = options.sortField;
    }
    if (options.sortOrder != null) {
        params['sortOrder'] = options.sortOrder === 1 ? 'ASC' : 'DESC';
    }
    const reqUrl = `${this.baseUrlV2}/summary-detail/export-excel`;
    return this._http.post(reqUrl, options, { params: params, responseType: 'blob'} ).map(res => res);
  }

  employeeTimiSheet(options: any): Observable<any> {
    options.toDate = moment(options.toDate);
    options.fromDate = moment(options.fromDate);
    const reqUrl = `${this.baseUrlV2}/timesheet/search`;
    return this._http.post(reqUrl, options).pipe(map(resp => resp));
  }

  sendFileToMail(start, end, timeSheetPDFModel): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}/timesheet/export-pdf`, timeSheetPDFModel).pipe(map(resp => resp));
  }

  getOverTimeIncrease(id: any) {
    return this._http.get<any>(this.baseUrlV2 + '/ot-increase/' + id).pipe(map(resp => resp));
  }

  sendPunchHistoryDataToEmployee(params: any): Observable<any> {
    const reqUrl = `${this.baseUrlV2}/punch-history/send-employee`;
    return this._http.post<any>(reqUrl, params).pipe(map(resp => resp));
  }

  sendPunchHistoryDataToFinance(params: any): Observable<any> {
    const reqUrl = `${this.baseUrlV2}/punch-history/send-finance`;
    return this._http.post<any>(reqUrl, params).pipe(map(resp => resp));
  }

  getNumOfClocking(employeeId: any): Observable<any>  {
    const reqUrl = `${this.baseUrlV2}/${Constants.URI.EMPLOYEECLOCKING.COUNT}/${employeeId}`;
    return this._http.get<any>(reqUrl).pipe(map(resp => resp));
  }

  updateTimeCorrection(options: any): Observable<EmployeePunchResponse> {
    const reqUrl = `${this.baseUrlV2}/time-correction`;
    return this._http.put<any>(reqUrl, options).pipe(map(resp => resp));
  }

  updateHolidayManually(options: any): Observable<EmployeePunchResponse> {
    const reqUrl = `${this.baseUrlV2}/holiday-manual`;
    return this._http.post<any>(reqUrl, options).pipe(map(resp => resp));
  }

  sendLastWeekDataToEmployee(params: any): Observable<any> {
    const reqUrl = `${this.baseUrlV2}/punch-history/last-week-report`;
    return this._http.post<any>(reqUrl, params).pipe(map(resp => resp));
  }

  checkEmployeeOnline(options: any): Observable<any> {
    const reqUrl = `${this.baseUrlV2}/check-online`;
    return this._http.post<any>(reqUrl, options).pipe(map(resp => resp));
  }

  exportPdf(options: any): Observable<any> {
    options.toDate = MOMENT(options.toDate).toISOString(true);
    options.fromDate = MOMENT(options.fromDate).toISOString(true);
    const reqUrl = `${this.baseUrlV2}/pdf`;
    // const reqUrl = `http://localhost:8005/api/v2/clocking/tracking/pdf`;
    return this._http.post<any>(reqUrl, options).pipe(map(resp => resp));
  }

  createClockingTracking(entity: any): Observable<any> {
    //return this._http.post<any>('http://localhost:8005/api/v2/clocking/tracking', entity).pipe(map(res => res));
    return this._http.post<any>(this.baseUrlV2, entity).pipe(map(res => res));
  }
}
