import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AbstractService } from '../../shared/service/abstract.service';
import { AuthService } from '../../shared/service/auth/auth.service';
import { DropdownDto, MetadataDto, MetadataFilterDto, MetadataTableDto, SimpleEmployeeDto } from '../model/metadata.dto';
import { MetadataModel } from '../model/metadata.model';
import { ReportTemplate } from '../model/report-template';
import { Observable } from 'rxjs';
import MetadataObject from '../../../assets/metadata-objects.json';
import { log } from 'util';
import * as moment from 'moment';
import { environment, environmentLocal } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ReportTemplateService extends AbstractService<any> {

    constructor(private http: HttpClient,
        private authService: AuthService) {
        // super(http, 'report-template', '');
        super(http, 'reporting/api/report-template', '');
    }

    getMetadata(): Observable<MetadataModel> {
        const params = {
            companyId: this.authService.getCurrentCompany() ? '' + this.authService.getCurrentCompany() : '0',
        };
        return this._http.get<MetadataDto>(`${this.baseUrlV2}metadata`, { params: params }).pipe(
            map((value: MetadataDto) => ({
                objects: value.objects && value.objects.length > 0 ? value.objects.map((obj: MetadataTableDto) => ({
                    label: obj.title,
                    value: obj.name,
                    filters: obj.filters.map((filter: MetadataFilterDto) => ({
                        label: filter.title,
                        content: filter.info,
                        value: filter.name,
                        type: filter.type,
                    })),
                    columns: obj.columns.map((column: DropdownDto) => ({
                        label: column.title,
                        content: column.info,
                        value: column.name,
                    })),
                })) : MetadataObject.map((obj: MetadataTableDto) => ({
                    label: obj.title,
                    value: obj.name,
                    filters: obj.filters.map((filter: MetadataFilterDto) => ({
                        label: filter.title,
                        content: filter.info,
                        value: filter.name,
                        type: filter.type,
                        freequency: filter.scheduleType ? true : false,
                        scheduleType: filter.scheduleType ? filter.scheduleType : []
                    })),
                    columns: obj.columns.map((column: DropdownDto) => ({
                        label: column.title,
                        value: column.name,
                        content: column.info
                    })),
                })),
                employees: value.employees ? value.employees.map((emp: SimpleEmployeeDto) => ({
                    label: emp.fullName,
                    value: {
                        id: emp.id,
                        type: emp.roleId === 7 ? 0 : 1,
                        phone: emp.phone,
                        email: emp.email,
                        name: emp.fullName,
                        username: emp.username
                    },
                })) : [],
                employeesSendTo: value.employees ? value.employees.map((emp: SimpleEmployeeDto) => ({
                    label: emp.fullName,
                    value: {
                        id: emp.id,
                        type: emp.roleId === 7 ? 0 : 1,
                    },
                })) : [],
                admins: value.admins ? value.admins.map((adm: any) => ({
                    label: adm.firstName + ' ' + adm.lastName,
                    value: {
                        id: adm.id,
                        type: adm.roleId === 7 ? 0 : 1,
                        phone: adm.phone,
                        email: adm.email,
                        name: adm.firstName + ' ' + adm.lastName,
                        username: adm.username
                    },
                })) : [],
                adminsSendTo: value.admins ? value.admins.map((adm: any) => ({
                    label: adm.firstName + ' ' + adm.lastName,
                    value: {
                        id: adm.id,
                        type: adm.roleId === 7 ? 0 : 1,
                    },
                })) : [],
            }))
        );
    }

    createReportTemplate(data: any): Observable<any> {
        return this._http.post(this.baseUrlV2, data);
    }

    findReportTemplateById(id: number): Observable<any> {
        return this._http.get(`${this.baseUrlV2}${id}`);
    }

    listReportTemplate(options: any): Observable<any> {
        const params = options ? Object.keys(options).map(e => `${e}=${options[e]}`).join('&') : '';
        const httpParams = new HttpParams({
            fromString: params
        });
        return this._http.get(`${this.baseUrlV2}search`, { params: httpParams });
    }

    loadSendToUsers(): Observable<any> {
        const params = {
            companyId: this.authService.getCurrentCompany() ? '' + this.authService.getCurrentCompany() : '0',
        };
        return this._http.get(`${this.baseUrl}employees`, { params: params });
    }

    download(params: any): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'text/plain',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
        }
        );
        return this._http.get(`${this.baseUrlV2}pdf`, { headers, params });
    }

    getPdfExpense(uuid: string, reportType: string): Observable<any> {
        const params = {
            uuid: '' + uuid,
            reportTemplate: reportType
        };
        return this._http.get(`${this.baseUrlV2}pdfExpense`, { params: params });
    }

    getReportData(params) {
        return this._http.get(`${this.baseUrlV2}getTemplateData`, { params: params });
    }

    exportPdf(data): Observable<any> {
        return this._http.post(`${this.baseUrlV2}exportPdf`, data).pipe(map(resp => resp));
    }

    updateStatus(data: any): Observable<any> {
        return this._http.post(`${this.baseUrlV2}update-status`, data);
    }
}
