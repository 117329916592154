import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs';
import { FreelancerTicket } from '../model/freelancer-ticket.model';
import { Message } from "primeng/api";

@Injectable({ providedIn: 'root' })
export class CrmCommService {

    private announcedSource = new Subject();
    private confirmedSource = new Subject();
    private messageSubject = new Subject<Message>();
    message$ = this.messageSubject.asObservable();
    private editTicketSource = new BehaviorSubject<FreelancerTicket>(new FreelancerTicket());

    announced$ = this.announcedSource.asObservable();
    confirmed = this.confirmedSource.asObservable();
    eTicket = this.editTicketSource.asObservable();

    private currentEmployees: any;

    executeCmd(cmd: string, data?: any) {
        this.announcedSource.next({ cmd: cmd, data: data });
    }

    changeMessage(message: FreelancerTicket) {
        this.editTicketSource.next(message);
    }

    setEmployeeList(param) {
        this.currentEmployees = param;
    }

    getEmployeeList(): any {
        return this.currentEmployees;
    }
}
