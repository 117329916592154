<div class="spinner-overlay" *ngIf="loadingSpinner">
    <p-progressSpinner class="center-spin"></p-progressSpinner>
</div>
<p-toast position="bottom-right">{{msgs}}</p-toast>
<div class="card add-shadow p-4">
	<h1 class="m-0 ml-2 my-4">{{'Old Invoices' | translate}}</h1>
	<p-panel [toggleable]="true">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Old Invoices' | translate}}</span>
		</p-header>
        <div class="ui-g">
            <div class="ui-g-12">
                <div class="ui-g-6 ui-sm-12" *ngIf="!disableCompany || disableFields">
                    <label>{{'companyLabel' | translate}}</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-building"></i>
							</button>
						</div>
						<p-dropdown [filter]="true" [options]="allCompanyList" [disabled]="disableCompany || disableFields"  placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedCompany" (onChange)="loadAllClient()">
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>
						</p-dropdown>
					</div>
                </div>
                <div class="ui-g-6 ui-sm-12">
                    <label>{{'Invoice' | translate}}</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-file-invoice-dollar"></i>
							</button>
						</div>
                    	<input type="text" pInputText placeholder="{{'Search' | translate}}" class="w-100" [(ngModel)]="invoiceNumber">
					</div>
                </div>
                <div class="ui-g-6 ui-sm-12">
                    <label>{{'Client' | translate}}</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-briefcase"></i>
							</button>
						</div>
						<p-dropdown class="w-100" [options]="allClientList" [filter]="true"
							[(ngModel)]="selectedClient" [showClear]="true" (onChange)="getAllAddedProductsByClientId($event)" 
							[disabled]="disableFields" placeholder="{{'pleaseSelect' | translate}}">
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>
						</p-dropdown>
					</div>
                </div>
                <div class="ui-g-6 ui-sm-12">
                    <label>{{'Date Range' | translate}}</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-calendar-week"></i>
							</button>
						</div>
						<mat-form-field class="w-100">
							<mat-date-range-input [rangePicker]="picker">
								<input matStartDate matInput placeholder="{{'Start date' | translate}}" [(ngModel)]="fromDate" (focus)="picker.open()">
								<input matEndDate matInput placeholder="{{'End date' | translate}}" [(ngModel)]="toDate" (focus)="picker.open()">
							</mat-date-range-input>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-date-range-picker #picker></mat-date-range-picker>
						</mat-form-field>
					</div>
                </div>
				<div class="ui-g-6 ui-sm-12">
					<label>{{'Inactive Reason' | translate}}</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" type="button" style="cursor:default !important">
								<i class="fa-solid fa-briefcase"></i>
							</button>
						</div>
						<p-dropdown class="w-100" [options]="inactiveReasonDropDown" [filter]="true" [(ngModel)]="inactiveReason"
									[showClear]="true" [disabled]="disableFields" placeholder="{{'pleaseSelect' | translate}}">
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>
						</p-dropdown>
					</div>
				</div>
				<div class="ui-g-12 py-4 text-center">
					<button type="button" class="btn btn-danger mx-1" (click)="resetFilters()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
					<button type="button" class="btn btn-primary mx-1" (click)="search()"><i class="fa-solid fa-check mr-2"></i>{{'button.search' | translate}}</button>
				</div>
            </div>
        </div>
    </p-panel>
	<div class="d-flex flex-wrap justify-content-start align-items-center py-4 pl-2">
		<h1 class="m-0">{{'Browse Invoices' | translate}}</h1>
	</div>
	<p-panel id="table-ppanel" [toggleable]="true">
        <p-header class="flex-grow-1">
            <span><i class="fa-solid fa-table text-primary mr-2"></i> {{'Old Invoices' | translate}} ({{totalRecords}})</span>
        </p-header>
    	<p-contextMenu #cm [model]="items"></p-contextMenu>
    	<p-table #dt [value]="contentList" dataKey="id" selectionMode="single" [rows]="10" [paginator]="true" [lazy]="false"
			[rowsPerPageOptions]="[10,25,50]" (onLazyLoad)="search($event)" [totalRecords]="totalRecords" [contextMenu]="cm" 
			[responsive]="true"  (onPage)="paginate($event)" (sortFunction)="customSort($event)" [customSort]="true"
			[showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
        
			<ng-template pTemplate="caption">
				<div class="d-flex align-items-center justify-content-between flex-wrap" style="row-gap: 10px">
					<div class="d-flex">
						<button type="button"  (click)="exportTablePdf()" class="btn btn-primary mx-1">
							<span class="text-nowrap"><i class="fa-solid fa-print mr-2"></i> {{'Export PDF' | translate}}</span>
						</button>
					</div>
					<div class="input-group w-100 mr-2" style="max-width: 450px">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-filter"></i>
							</button>
						</div>
						<!-- <input [(ngModel)]="searchText" (ngModelChange)="searchInput($event)" placeholder="{{'Filter' | translate}}" class="form-control" style="height: 40px !important"> -->
						<input type="text" pInputText placeholder="{{'Filter' | translate}}" class="input-search float-right" [(ngModel)]="searchText" (keyup)="searchInput()">
					</div>
				</div>
			</ng-template>

			<ng-template pTemplate="header" let-columns>
				<tr>
					<th *ngFor="let col of cols">
						<div class="d-flex align-items-center justify-content-between w-100">
							<span class="one-liner" pTooltip="{{col?.label | translate}}" tooltipPosition="left">{{col?.label | translate}}</span>
							<span *ngIf="col.sort && (col?.label === 'Date')">
								<span (click)="col.sort == 'number'? op3.toggle($event) : op1.toggle($event); selectedField = col.field; setSortOption(col.field, col.sortOptions)">
									<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
								</span>
							</span>
							<span *ngIf="col.sort && (col?.label !== 'Date')">
								<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field; setSortOption(col.field, col.sortOptions)">
									<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
								</span>
							</span>
						</div>
					</th>
				</tr>
			</ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData">
                 <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Invoice' | translate}}: </span>
						<span *ngIf="rowData.invoiceNumber" pTooltip="{{rowData.invoiceNumber}}"><a href="javascript:void(0);" class="text-primary" (click)="exportPdf(rowData)">{{rowData.invoiceNumber}}</a></span>
						<span *ngIf="!rowData.invoiceNumber" class="text-muted">{{'no data' | translate}}</span>
					</span>
                </td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Dated' | translate}}: </span>
                	    <!-- <span *ngIf="rowData.invoiceDate" pTooltip="{{rowData.invoiceDate | date: 'MM/dd/yyyy'}}">{{rowData.invoiceDate | date: 'MM/dd/yyyy'}}</span> -->
                	    <span *ngIf="rowData.invoiceDate" pTooltip="{{rowData.invoiceDate}}">{{rowData.invoiceDate}}</span>
						<span *ngIf="!rowData.invoiceDate" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Client/Base' | translate}}: </span>
						<span *ngIf="rowData.clientName" pTooltip="{{rowData.clientName}}">{{rowData.clientName}}</span>
						<span *ngIf="!rowData.clientName" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
                <td *ngIf="isPlatformAdmin">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Company' | translate}}: </span>
						<span *ngIf="rowData.companyName" pTooltip="{{rowData.companyName}}">{{rowData.companyName}}</span>
						<span *ngIf="!rowData.companyName" class="text-muted">{{'no data' | translate}}</span>			
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Amount' | translate}}: </span>
						<!-- <span *ngIf="rowData.totalAmount" pTooltip="${{rowData.totalAmount | number : '1.2-2'}}">${{rowData.totalAmount | number : '1.2-2'}}</span> -->
						<span *ngIf="rowData.totalAmountTxt1" pTooltip="${{rowData.totalAmountTxt1}}">{{rowData.totalAmountTxt1}}</span>
						<!-- <span *ngIf="!rowData.totalAmount" class="text-muted">$0.00</span> -->
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Service Fee' | translate}}: </span>
						<!-- <span *ngIf="rowData.feeTxt" pTooltip="${{rowData.feeTxt | number : '1.2-2'}}">${{rowData.feeTxt | number : '1.2-2'}}</span> -->
						<span *ngIf="rowData.feeTxt1" pTooltip="${{rowData.feeTxt1}}">{{rowData.feeTxt1}}</span>
						<span *ngIf="!rowData.feeTxt1" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Paid' | translate}}:</span>
						<!-- <span *ngIf="rowData.paidFeeTxt" pTooltip="${{rowData.paidFeeTxt | number : '1.2-2'}}">${{rowData.paidFeeTxt | number : '1.2-2'}}</span> -->
						<span *ngIf="rowData.paidFeeTxt1" pTooltip="${{rowData.paidFeeTxt1}}">${{rowData.paidFeeTxt1}}</span>
						<span *ngIf="!rowData.paidFeeTxt1" class="text-muted">{{'no data' | translate}}</span>
				</span></td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Balance' | translate}}: </span>
						<!-- <span *ngIf="rowData.balanceTxt" pTooltip="${{rowData.balanceTxt | number : '1.2-2'}}">${{rowData.balanceTxt | number : '1.2-2'}}</span> -->
						<span *ngIf="rowData.balanceTxt1" pTooltip="${{rowData.balanceTxt1}}">{{rowData.balanceTxt1}}</span>
						<span *ngIf="!rowData.balanceTxt1" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Archiver' | translate}}: </span>
						<span *ngIf="rowData.inactivedBy" pTooltip="{{rowData.inactivedBy}}">{{rowData.inactivedBy}}</span>
						<span *ngIf="!rowData.inactivedBy" class="text-muted">{{'no data' | translate}}</span>		
                    </span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Archived On' | translate}}: </span>
						<!-- <span *ngIf="rowData.inactivedDate" pTooltip="{{rowData.inactivedDate | date: 'MM/dd/yyyy HH:mm:ss'}}">{{rowData.inactivedDate | date: 'MM/dd/yyyy HH:mm:ss'}}</span> -->
						<span *ngIf="rowData.inactivedDate" pTooltip="{{rowData.inactivedDate}}">{{rowData.inactivedDate}}</span>
						<span *ngIf="!rowData.inactivedDate" class="text-muted">{{'no data' | translate}}</span>
            		</span>
				</td>
                <td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Inactive Reason' | translate}}: </span>
						<span *ngIf="rowData.inactiveReason" pTooltip="{{rowData.inactiveReason}}">{{rowData.inactiveReason}}</span>
						<span *ngIf="!rowData.inactiveReason" class="text-muted">{{'no data' | translate}}</span>
            		</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Prev Stage' | translate}}: </span>
						<span *ngIf="rowData.prevStage" pTooltip="{{rowData.prevStage}}">{{rowData.prevStage}}</span>
						<span *ngIf="!rowData.prevStage" class="text-muted">{{'no data' | translate}}</span>
            		</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Actions' | translate}}: </span> 	
						<i class="fa-solid fa-plus text-primary c-pointer mx-1" (click)="openDialog(rowData, $event, true)" pTooltip="{{'Add Collections Note' | translate}}" tooltipPosition="left"></i>       
						<span class="mx-1">
							<i *ngIf="rowData.followUpNoteDate" class="fa-solid fa-note-sticky text-warning c-pointer" (click)="openDialog(rowData, $event, false)" pTooltip="{{'Follow-Ups' | translate}} {{rowData.followUpNoteDate | date: 'MM/dd/yyyy'}}"  tooltipPosition="left">		
								<span *ngIf="rowData.numOfFollowUpNote > 0" class="translate-middle badge rounded-pill bg-danger" style="font-size: 7px">
									{{rowData.numOfFollowUpNote}}
								</span>
							</i>
							<i *ngIf="rowData.followUpNoteDate" class="fa-solid fa-inbox text-purple c-pointer mx-1" (click)="redirectToFollowUptUrl(rowData)" pTooltip="{{'View Follow-Ups' | translate}}" tooltipPosition="left"></i>
						</span>							
					</span>
				</td>
            </tr>
        </ng-template>

		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading">
				<td [attr.colspan]="isPlatformAdmin ? 12 : 11"  class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="loading">
				<td [attr.colspan]="isPlatformAdmin ? 12 : 11" class="text-center py-5">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
		</ng-template>

        <ng-template *ngIf="!loading" pTemplate="footer" let-columns>
            <tr *ngIf="contentList.length > 0">
                <td align="right"><b>{{'Total' | translate}}:</b></td>
                <td></td>
                <td></td>
                <td *ngIf="isPlatformAdmin"></td>
                <td>{{sumTotalAmountTxt}}</td>
                <td>{{sumFeeTxt}}</td>
                <td>{{sumPaidFeeTxt}}</td>
                <td>{{sumOutStandingBalanceTxt}}</td>
                <td></td>
                <td></td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>
</div>
<p-dialog header="Invoice Details" styleClass="w-50" [modal]="true" [responsive]="true" [autoZIndex]="true" [(visible)]="detailEnable" [contentStyle]="{'max-height':'400px'}">
	<div class="ui-g invoice-details">
		<div id="contentToConvert" style="padding:50px;">
			<div style="text-align: center;">
				<img src="../../../../assets/punch-images/operr_logo.jpg">
			</div>
		</div>
		<div class="ui-g-12">
			<p style="text-align: center;">
				<b>{{'INVOICE' | translate}}</b>
			</p>
		</div>
		<div class="ui-g-12">
			<div class="ui-g-6">
				{{'Invoice Number' | translate}}: {{invoiceDetail.invoiceNumber}}
			</div>
		</div>
		<div style="text-align: right">
			{{'Date' | translate}}: {{invoiceDetail.invoiceDate | date: 'dd/MM/yyyy'}}
		</div>
		<div class="ui-g-12">
			<div class="ui-g-8 pb-0 ui-g-offset-2">
				<p-table [value]="details">
					<ng-template pTemplate="header">
						<tr>
							<th><b>{{'Charge Item' | translate}}</b></th>
							<th><b>{{'Amount' | translate}}</b></th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-detail>
						<tr>
							<td>{{detail?.name}}</td>
							<td>
								<div style="text-align: right">
									{{detail?.value?.toFixed(2)}}
								</div>
							</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="footer" let-columns>
						<tr>
							<td>
								<b>{{'Total' | translate}}:</b>
							</td>
							<td>
								<div style="text-align: right">
									${{invoiceDetail?.totalAmount?.toFixed(2)}}
								</div>
							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</div>
		<div class="ui-g-12">
			<b>{{'Please make to payment' | translate}}</b>
		</div>
		<div class="ui-g-12" style="text-align: center;">
			<button pButton type="button" label="{{'Ok' | translate}}" (click)="cancelDialog()"></button>
		</div>
	</div>
</p-dialog>

<p-overlayPanel #op1 appendTo="body">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption, $event)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption, $event)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op3 appendTo="body">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption, $event)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>

<p-dialog [(visible)]="isOpenCollectionNote" [responsive]="true" baseZIndex="99999" [modal]="true" [dismissableMask]="true" [style]="{'width':'100%', 'max-width':'900px'}">
	<p-header>
		<span><i class="fa-solid fa-circle-plus mr-2"></i>{{headerNote}}: {{followUpDate | date: 'MM/dd/yyyy hh:mm'}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Client' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-briefcase"></i>
					</button>
				</div>
				<input *ngIf="paymentInvoiceData.clientName" pInputText [(ngModel)]="paymentInvoiceData.clientName" type="text" disabled>
				<div *ngIf="!paymentInvoiceData.clientName" class="form-control"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'no data' | translate}}</span></div>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Invoice #' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-hashtag"></i>
					</button>
				</div>
				<input *ngIf="paymentInvoiceData.invoiceNumber" pInputText [(ngModel)]="paymentInvoiceData.invoiceNumber" type="text" disabled>
				<div *ngIf="!paymentInvoiceData.invoiceNumber" class="form-control"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'no data' | translate}}</span></div>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Invoice Dated' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-days"></i>
					</button>
				</div>
				<input *ngIf="paymentInvoiceData.invoiceDate"  pInputText type="text" [value]="paymentInvoiceData.invoiceDate | date: 'MM/dd/yyyy HH:mm'" disabled>
				<div *ngIf="!paymentInvoiceData.invoiceDate" class="form-control"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'no data' | translate}}</span></div>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Due Date' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-days"></i>
					</button>
				</div>
				<p-calendar [style]="{'flex-grow': '1'}" [inputStyle]="{'width':'100%'}" [showIcon]="false" placeholder="Date" [(ngModel)]="followUpInvoiceDueDate"
							[disabled]="collectionTypeSelected !== 'Delay Payment Requested' || followUpCount > 2 || disabledExtendDueDate || isOverTime || !isOwnerNote"></p-calendar>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Balance' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i *ngIf="paymentInvoiceData.balanceTxt > 0" class="fa-solid fa-dollar-sign"></i>
						<i *ngIf="paymentInvoiceData.balanceTxt <= 0" class="fa-solid fa-circle-check text-success"></i>
					</button>
				</div>
				<input *ngIf="paymentInvoiceData.balanceTxt" pInputText [(ngModel)]="paymentInvoiceData.balanceTxt" type="text" disabled>
				<div *ngIf="!paymentInvoiceData.balanceTxt" class="form-control"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'no data' | translate}}</span></div>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Invoice Status' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-info"></i>
					</button>
				</div>
				<input *ngIf="paymentInvoiceData.status" pInputText [(ngModel)]="paymentInvoiceData.status" type="text" disabled>
				<div *ngIf="!paymentInvoiceData.status" class="form-control"><span class="one-liner"><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{'no data' | translate}}</span></div>
			</div>
		</div>

		<div class="ui-g-6 ui-sm-12">
			<label>{{'Collection Agent' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-user-tie"></i>
					</button>
				</div>
				<p-dropdown class="w-100" [options]="assignedToEmployeeList" [filter]="true" [(ngModel)]="selectedCollectionPresentative"
							[showClear]="true" [style]="{'flex-grow': '1'}" placeholder="{{'pleaseSelect' | translate}}" [disabled]="isOverTime || !isOwnerNote"></p-dropdown>
			</div>
		</div>

		<div class="ui-g-6 ui-sm-12">
			<label>{{'Collection Type' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-folder-tree"></i>
					</button>
				</div>
				<p-dropdown [options]="collectionTypeOptions" [(ngModel)]="collectionTypeSelected" [style]="{'flex-grow': '1'}"  placeholder="Choose method" [disabled]="isOverTime || !isOwnerNote"></p-dropdown>
			</div>
		</div>

		<div class="ui-g-6 ui-sm-12">
			<label>{{'Contact Method' | translate}}:<span class="text-danger">*</span></label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-message"></i>
					</button>
				</div>
				<p-dropdown [options]="contacts" [(ngModel)]="selectedContact" [style]="{'flex-grow': '1'}" placeholder="Choose method" [disabled]="isOverTime || !isOwnerNote"></p-dropdown>
			</div>
		</div>

		<div class="ui-g-6 ui-sm-12">
			<label>{{'Count' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-hashtag"></i>
					</button>
				</div>
				<input type="text" pInputText [(ngModel)]="followUpCount" disabled/>
			</div>
		</div>
		
		<div class="ui-g-12 ui-sm-12">
			<label>{{'Note' | translate}}:<span class="text-danger">*</span> </label>
			<p-editor #notesEditor
				(onTextChange)="textChanged1($event)"
				(onInit)="setEditor($event)"
				(closed)="boldMention()"
				[mentionConfig]="{triggerChar:'@',maxItems:10,labelKey:'value'}"
				[mention]="assignedToEmployeeList"
				(opened)="customerPopup($event)"
				[(ngModel)]="followUpNote"
				[style]="{'height':'170px'}"
				[readonly]="isOverTime || !isOwnerNote">
				<p-header>
					<span class="ql-formats">
						<button class="ql-bold" aria-label="Bold"></button>
						<button class="ql-italic" aria-label="Italic"></button>
						<button class="ql-underline" aria-label="Underline"></button>
						<button class="ql-strike" aria-label="Strike"></button>
					</span>
					<span class="ql-formats">
						<select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)">
							<option value="rgb(0, 0, 0)" label="rgb(0, 0, 0)"></option>
							<option value="rgb(104 104 104)" label="rgb(104 104 104)"></option>
							<option value="rgb(222 58 58)" label="rgb(222 58 58)"></option>
							<option value="rgb(255 99 0)" label="rgb(255 99 0)"></option>
							<option value="rgb(90 171 29)" label="rgb(90 171 29)"></option>
							<option value="rgb(0 123 255)" label="rgb(0 123 255)"></option>
						</select>
						<span class="ql-format-separator"></span>
					</span>
					<span class="ql-formats">
						<button class="ql-list" title="List"></button>
						<button class="ql-bullet" title="Bullet"></button>         
					</span> 
				</p-header>
			</p-editor>
			<span *ngIf="500 - charactersLength > 0" class="text-muted pull-right" style="font-size: 12px">{{500 - charactersLength}} {{'characters left' | translate}}</span>
			<span *ngIf="500 - charactersLength <= 0" class="text-danger pull-right" style="font-size: 12px">{{500 - charactersLength}} {{'characters left' | translate}}</span>
		</div>
	</div>
	<p-footer class="d-flex align-items-center justify-content-between">
		<button class="btn btn-danger" (click)="closeDialog()"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button class="btn btn-primary" [disabled]="followUpNote === '' || selectedCollectionPresentative === '' || selectedContact === '' || !followUpInvoiceDueDate || isOverTime || !isOwnerNote || isSaveCollectionNote"
				(click)="saveCollectionNote()"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</button>
	</p-footer>
</p-dialog>

