import { Injectable } from '@angular/core';
import { AbstractService } from '../../../shared/service/abstract.service';
import { MonitorDetails } from '../model/monitor-details.model';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../../shared/model/constants';
import { Page } from '../../../shared/model/page.model';
import { Observable } from 'rxjs';
import { NGDropdownModel } from '../../../payroll-setting/utility/model.utility';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { UtilsService } from '../../../shared/service/utils.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MonitoringDetailsService extends AbstractService<MonitorDetails> {

  visitedPageData: any = {
    pageVisitStartTime: '',
    pageVisitEndTime: '',
    pageTitle: '',
    arguments: {},
  };

  constructor(private http: HttpClient, private authService:AuthService, private utilsService:UtilsService, private router:Router, private cookieService:CookieService) {
      super(http, Constants.ROUTES.MONITOR_DETAILS, '');
  }

  getProcessListByPage(params: any): Observable<Page<MonitorDetails>> {
      return this.http.post<Page<MonitorDetails>>(this.baseUrlV2 + Constants.URI.PROCESS_MONITOR.LISTALLBYPAGE, params).pipe(map(resp => resp));
  }

  getPageWithFilter(options?: any): Observable<Page<MonitorDetails>> {
      const optionBody: any = {
        companyId: options.companyId,
        action: options.action,
        duration: options.duration,
        startedBy: options.startedBy,
        fromDate: options.fromDate,
        toDate: options.toDate,
        size: options.size,
        page: options.page,
        sortField: options.sortField,
        sortOrder: options.sortOrder,
        projectId: options.projectId,
        process: options.process
      };
      return this._http.post<Page<MonitorDetails>>(this.baseUrlV2 + 'search', optionBody).pipe(map(resp => resp));
  }

  getActionsDropdown(): Observable<NGDropdownModel[]> {
    return this.http.get<NGDropdownModel[]>(this.baseUrl + Constants.URI.MONITOR_DETAILS.ACTIONS).pipe(map(resp => resp));
  }


  getObjectsDropdown(paramData?:any): Observable<NGDropdownModel[]> {
        return this.http.post<NGDropdownModel[]>(this.baseUrlV2 + 'all-object-name',
            paramData).pipe(map(resp => resp));
  }

  getUserListDropdown(paramData?:any): Observable<NGDropdownModel[]> {
    return this.http.get<NGDropdownModel[]>(this.baseUrl + Constants.URI.MONITOR_DETAILS.USERLIST,{params:paramData}).pipe(map(resp => resp));
  }
  generateMonitorDetailsPdfFile(options?: any,offset?:number): Observable<any> {
    const optionParams: any = {
      page: options.page,
      size: options.size,
      sort: options.sort
    };
    console.log(new Date());

    const optionBody: any = {
      agencyId: options.agencyId,
      companyId: options.companyId,
      action: options.action,
      duration: options.duration,
      startedBy: options.startedBy,
      fromDate: options.fromDate,
      toDate: options.toDate
    };
    const params = this.createParams(optionParams);
  
      return this.http.post<any>(this.baseUrlV2 + 'pdf', options ).pipe(map(resp => resp));
  }

    exportPdf(data?: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}exportPdf`, data).pipe(map(resp => resp));
    }

  monitorNavigationProcess(processName,args,statusVal,actionVal,startTime?,isAfterLogin?,isLogout?,isAction?){
    const monitorDetails = new MonitorDetails();
    if(startTime){
      monitorDetails.requestStartTime = new Date(startTime);
      monitorDetails.processStartTime = new Date(startTime);
    } else{
      monitorDetails.requestStartTime = new Date();
      monitorDetails.processStartTime = new Date();
    }
    monitorDetails.endTime = new Date();
    monitorDetails.process = processName;
    monitorDetails.companyId = this.authService.getCurrentCompanyId();
    monitorDetails.startedById = this.authService.getCurrentLoggedInId();
    monitorDetails.startedByName = this.authService.getCurrentLoggedInName();
    monitorDetails.arguments = args;
    monitorDetails.status = statusVal;
    monitorDetails.action = actionVal;
    monitorDetails.duration = monitorDetails.endTime.getTime() - monitorDetails.processStartTime.getTime();
    monitorDetails.duration = Math.round(monitorDetails.duration / 1000 ) * 1000;
    monitorDetails.userName = this.authService.getUserInfo().username;
    monitorDetails.userId = this.authService.getUserInfo().adminId;
    monitorDetails.userRole = this.authService.getRoleLevel().toString();
    if(!isAfterLogin && !isAction){
      this.utilsService.visitedPageData.pageUrl = '';
    }
    this.create(monitorDetails).subscribe((data) => {
        console.log(data);
        this.utilsService.visitedPageData.pageVisitStartTime = new Date();
        if(isLogout){
          this.utilsService.visitedPageData.pageTitle = '';
          this.utilsService.visitedPageData.pageUrl = '';
          this.utilsService.isLogoutTriggerCompleted = false;
          this.triggerLogout();
        }
    }, error1 => console.log(error1));
    if(isAfterLogin){
      this.authService.setUserLoginTime(0);
    }
  }

  triggerLogout() {
    this.authService.logout().subscribe((res: any) => {
        if (res.status === 200 ) {
            this.authService.logUserActivity();
            this.cookieService.deleteAll();
            localStorage.clear();
            this.authService.authenticateUser();
            this.authService.setAuthObservable();
            this.router.navigate(['/login']);
        }        
    },error => {
        this.cookieService.deleteAll();
        localStorage.clear();
        this.authService.authenticateUser();
        this.authService.setAuthObservable();
        this.router.navigate(['/login']);
    });
  }

    monitorAction(action: string, startTime: Date, args: { [key: string]: string }, status: string, process: string, projectId?: number, callback?: Function): void {
      const monitorDetails = new MonitorDetails();
        monitorDetails.requestStartTime = startTime;
        monitorDetails.processStartTime = startTime;
        monitorDetails.endTime = new Date();
        monitorDetails.process = process;
        monitorDetails.companyId = this.authService.getCurrentCompanyId() ? this.authService.getCurrentCompanyId() : 0;
        monitorDetails.startedById = this.authService.getCurrentLoggedInId();
        monitorDetails.startedByName = this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName() ? this.authService.getCurrentLoggedInName() : 'Guest';
        monitorDetails.userName = this.authService.getCurrentUsername() ? this.authService.getCurrentUsername() : 'Guest';
        monitorDetails.userId = this.authService.getCurrentLoggedInId();
        monitorDetails.arguments = args;
        monitorDetails.status = status;
        monitorDetails.action = action;
        monitorDetails.projectId = projectId;
        const duration = monitorDetails.endTime.getTime() - monitorDetails.processStartTime.getTime();
        monitorDetails.duration = Math.round(duration / 1000) * 1000;
        console.log('monitorDetails', monitorDetails);
        if (callback) {
          this.create(monitorDetails).subscribe((res) => callback(res), error => console.log(error));
        } else {
          this.create(monitorDetails).subscribe(() => {
          }, error => console.log(error));
        }

    }
    createAll(entity: MonitorDetails[]): Observable<MonitorDetails> {
        return this._http.post<MonitorDetails>(`${this.baseUrlV2}saveMultipleRows`, entity).pipe(map(res => res));
    }
  getActionListDropdown(paramData?:any): Observable<NGDropdownModel[]> {
    return this.http.post<NGDropdownModel[]>(this.baseUrlV2 + 'all-action',
      paramData).pipe(map(resp => resp));
  }

    exportPdfActivityLog(data?: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}activity-log/pdf`, data).pipe(map(resp => resp));
    }
}
