import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RoleLevel } from '../../component/app-menu/role-level';
import { Constants } from '../../../shared/model/constants';
import { environment } from '../../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { PlatformAdmin } from '../../../admin/platform-admin/model/platform-admin.model';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { MENU_ID } from 'app/shared/data/menu-id';

@Injectable({ providedIn: 'root' })
export class AllMenusService {
  platformAdmin: PlatformAdmin;
  themeConfig = {
    layout: '',
    theme: ''
  };
  hasBranch = true;
  private apiUrl = Constants;

  constructor(private http: HttpClient, private cookieService: CookieService,
    private authService: AuthService) {
  }

  getAllMenus() {

    let employeeProfileURL = '';
    let labelEmployeeProfile = '';
    if (this.authService.getRoleLevel() === 7) {
      employeeProfileURL = '/app/employee/view/' + this.authService.getCurrentLoggedInId();
      labelEmployeeProfile = 'employee.employeeProfile';
    } else {
      employeeProfileURL = '/app/employee/list';
      labelEmployeeProfile = 'All Employees';
    }
    this.hasBranch = this.authService.isCurrentCompanyHasBranch();
    let allMenus: any;
    let allMenus2: any = [
      {
        label: 'Dashboards', icon: 'dashboard', id: 'dashboard',
        items: [
          {
            label: 'Dashboard', routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/dashboard'],
            menuId: MENU_ID.DASHBOARD,
            id: 'admin_dashboard',
            icon: 'space_dashboard'
          },
          {
            label: 'Overtime', icon: 'running_with_errors', 
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : this.authService.hasViewPermission(MENU_ID.OVERTIME_DASHBOARD) ? ['/app/overtime/list'] : null,
            menuId: this.getMenuId([MENU_ID.OVERTIME_DASHBOARD, MENU_ID.OT_PREVENTION]),
            uid: 90024,
            items: [
              {
                label: 'Prevention', routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/overtime/prevention'],
                menuId: MENU_ID.OT_PREVENTION,
                id: 'prevention',
                icon: 'lock_clock',
              }
            ],
          },
          {
            label: 'Client Dashboard', routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/client/dasboard'],
            id: 'client_dasboard',
            icon: 'pie_chart',
            menuId: MENU_ID.CLIENT_DASHBOARD,
          },

          {
            label: 'Survey Dashboard', routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/survey/dasboard'],
            id: 'survey_dasboard',
            icon: 'ballot',
            menuId: MENU_ID.SURVEY_DASHBOARD,
          },

          {
            label: 'CRM Dashboard', routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/invoice/dasboard'],
            menuId: MENU_ID.CRM_DASHBOARD,
            id: 'crm_dashboard',
            icon: 'equalizer',
          },
          {
            label: 'CEO Dashboard', routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/ceo/dashboard'],
            menuId: MENU_ID.CEO_DASHBOARD,
            id: 'ceo_dashboard',
            icon: 'diversity_3',
          },
          {
            label: 'VRM Dashboard', routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/voter/dashboard'],
            id: 'vrm_dasboard',
            icon: 'how_to_vote',
            menuId: MENU_ID.VRM_DASHBOARD,
          },
          {
            label: 'Operrtel Dashboard', routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/operrtel/dashboard'],
            id: 'oppertel_dasboard',
            icon: 'ring_volume',
            menuId: MENU_ID.OPERRTEL_DASHBOARD,
          },
          {
            label: 'Global Dashboard', routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/resident/dashboard'],
            id: 'global_dasboard',
            icon: 'diversity_3',
            menuId: MENU_ID.GLOBAL_DASHBOARD,
          }
        ],
        uid: 9000
      },
      {
        label: 'Employee Punch', id: 'menu_employee_punch',
        routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee-clocking/punch'],
        menuId: this.getMenuId([MENU_ID.PUNCH_IN_OUT, MENU_ID.PUNCH_HISTORY, MENU_ID.SUMMARY_VIEW, MENU_ID.EMPLOYEE_OT]),
        icon: 'pattern',
        uid: 8999
      },
      {
        label: 'Platform Color', icon: 'palette', id: 'color',
        items: [
          {
            label: 'Default Blue', icon: 'brush', command: (event) => {
              this.changeLayout('moody');
              this.changeTheme('bluegrey');
            },
            menuId: MENU_ID.LAYOUT_PALETTE
          },
          {
            label: 'Red', icon: 'brush', command: (event) => {
              this.changeLayout('red');
              this.changeTheme('redd');
            },
            menuId: MENU_ID.LAYOUT_PALETTE

          },
          {
            label: 'Purple', icon: 'brush', command: (event) => {
              this.changeLayout('cool');
              this.changeTheme('cool');
            },
            menuId: MENU_ID.LAYOUT_PALETTE

          },
          {
            label: 'Green', icon: 'brush', command: (event) => {
              this.changeLayout('coolgreen');
              this.changeTheme('coolgreen');
            },
            menuId: MENU_ID.LAYOUT_PALETTE

          },
          {
            label: 'Orange', icon: 'brush', command: (event) => {
              this.changeLayout('orange');
              this.changeTheme('orange');
            },
            menuId: MENU_ID.LAYOUT_PALETTE

          },
          {
            label: 'Teal', icon: 'brush', command: (event) => {
              this.changeLayout('teal');
              this.changeTheme('teal');
            },
            menuId: MENU_ID.LAYOUT_PALETTE

          },
          {
            label: 'Black', icon: 'brush', command: (event) => {
              this.changeLayout('black');
              this.changeTheme('black');
            },
            menuId: MENU_ID.LAYOUT_PALETTE

          },
          {
            label: 'Night Mode', icon: 'dark_mode', command: (event) => {
              this.changeLayout('night');
              this.changeTheme('night');
            },
            menuId: MENU_ID.LAYOUT_PALETTE

          },
        ],
        menuId: MENU_ID.COLORS,
        uid: 9001
      },

      {
        label: 'operation', icon: 'build', id: 'menu_operation',
        items: [
          {
            label: 'Companies', id: 'company_profile',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/company/list'],
            icon: 'business',
            menuId: this.getMenuId([MENU_ID.COMPANY_PROFILE, MENU_ID.COMPANY_HOLIDAY, MENU_ID.DOCUMENT_SETTING, MENU_ID.COMPANY_PAYMENT, MENU_ID.COMPANY_PAYMENT_METHOD, MENU_ID.OPERATIONAL_POLICY]),
          },
          {
            label: 'Departments', id: 'menu_department_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/department/list'],
            menuId: MENU_ID.DEPARTMENT,
            icon: 'sort'
          },
          {
            label: 'Positions', id: 'menu_position_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/position/add'],
            menuId: MENU_ID.POSITION,
            icon: 'work'
          },
          {
            label: 'Content', id: 'menu_content_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/content/list'],
            menuId: MENU_ID.COMPANY_CONTENT,
            icon: 'description',

          },
          {
            label: 'Conference Rooms', id: 'menu_conference_room',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/conference-room/list'],
            menuId: MENU_ID.CONFERENCE_ROOM,
            icon: 'record_voice_over',

          },
          {
            label: 'Shareholders', id: 'menu_share_holder_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/share-holder/list'],
            icon: 'group',
            menuId: MENU_ID.SHARE_HOLDER,
          },
          {
            label: 'Notifications', id: 'menu_notification_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/notification/list'],
            icon: 'notifications_active',
            menuId: MENU_ID.NOTIFICATION,
          },

          {
            label: 'Manage PWDs', id: 'menu_pwdmng_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/pwdmng/list'],
            icon: 'lock',
            menuId: MENU_ID.PWD_MANAGEMENT,
          },
          {
            label: 'Document Settings', id: 'menu_document_settings',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/company/document-settings/list'],
            icon: '-1',
            menuId: MENU_ID.DOCUMENT_SETTING
          },
          {
            label: 'API Settings', id: 'menu_google_api_setting',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/google-api-setting'],
            menuId: MENU_ID.API_SETTING,
            icon: 'code'
          },
          {
            label: 'OperrTel Pools', id: 'menu_operrtel_pool_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/operrtel-pool/list'],
            icon: 'workspaces',
            menuId: MENU_ID.OPERRTEL_POOL,
          },
          {
            label: 'Service Plans', id: 'menu_package_service_plan_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/package-service-plan/list'],
            icon: 'shopping_cart',
            menuId: MENU_ID.PACKAGE_SERVICE_PLAN,
          },
          {
            label: 'OperrSign', id: 'menu_doc_sign',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/doc-sign'],
            menuId: MENU_ID.OPERRSIGN,
            icon: 'history_edu'
          },
          {
            label: 'Location Management', id: 'menu_location_management',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/location-management/list'],
            menuId: MENU_ID.LOCATION_MANAGEMENT,
            icon: 'location_on',
            items: [
              {
                label: 'Apt/Room Inspection', id: 'menu_apt_inspection',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/location-management/inspection'],
                menuId: MENU_ID.LOCATION_MANAGEMENT,
                icon: 'code'
              },
              {
                label: 'Unit Overview', id: 'menu_unit_view',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/location-management/apt-room-view'],
                menuId: MENU_ID.LOCATION_MANAGEMENT,
                icon: 'workspaces'
              }
            ]
          }
        ],
        uid: 9002
      },
      {
        label: 'Mail', icon: 'email', id: 'menu_email',
        routerLink: localStorage.getItem('_disableAll') === '1' ? null : this.authService.hasViewPermission(MENU_ID.MAIL) ? ['/app/lettermail/list'] : null,
        menuId: this.getMenuId([MENU_ID.MAIL, MENU_ID.DEADLINE_REMINDER, MENU_ID.MAIL_DOCUMENT]),
        items: [
          {
            label: 'Deadline Reminder', id: 'menu_deadline_reminder',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/lettermail/reminder/list'],
            menuId: MENU_ID.DEADLINE_REMINDER
          },
          {
            label: 'File', id: 'menu_document',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/lettermail/document/list'],
            menuId: MENU_ID.MAIL_DOCUMENT
          }
        ],
        uid: 9003
      },
      {
        label: 'Survey Land', icon: 'architecture', id: 'menu_survey_land',
        uid: 9016,
        items: [
          {
            label: 'Quote', id: 'menu_quote',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/quote/list'],
            menuId: MENU_ID.QUOTE,
          }, {
            label: 'Land Project', id: 'menu_land_project',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/lander/list'],
            menuId: MENU_ID.LAND_PROJECT,
          },
          {
            label: 'Service Rate', id: 'menu_service_rate',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/service-rate/list'],
            menuId: MENU_ID.SERVICE_RATE,
          },
          {
            label: 'Service Provider', id: 'menu_service_provider',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/service-provider/list'],
            menuId: MENU_ID.SERVICE_PROVIDER,
          }
        ]
      },
      {
        label: 'Compliance', icon: 'assured_workload', id: 'compliance_setting-menu',
        uid: 9018,
        items: [
          {
            label: 'Document Settings', id: 'menu_compliance_doc', icon: 'edit_document',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/compliance-setting/list'],
            menuId: MENU_ID.COMPLIANCE_DOCUMENT,
          },
          {
            label: 'Staff Compliance', id: 'menu_stuff_compliance', icon: 'rule',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/compliance-setting/stuff-compliance'],
            menuId: MENU_ID.STUFF_COMPLIANCE,
          },
          {
            label: 'Time Recollection', id: 'menu_time_recollection', icon: 'history_toggle_off',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/compliance-setting/time-recollection'],
            menuId: MENU_ID.TIME_RECOLLECTION,
          },
          {
            label: 'Re-Collection Uploads', id: 'menu_time_recollection_upload', icon: 'upload_file',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/compliance-setting/time-recollection-upload-history'],
            menuId: MENU_ID.TIME_RECOLLECTION_UPLOAD_HISTORY,
          },
          {
            label: 'Voucher', id: 'stuff-voucher', icon: 'request_quote',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/compliance-setting/stuff-voucher'],
            menuId: MENU_ID.STUFF_VOUCHER,
          },
          {
            label: 'Wage Schedule', id: 'wage-schedule', icon: 'price_change',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/compliance-setting/wage-schedule'],
            menuId: MENU_ID.WAGE_SCHEDULE,
          },
          {
            label: 'Employee Voucher', id: 'employee-voucher', icon: 'receipt_long',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/compliance-setting/employee-voucher'],
            menuId: MENU_ID.EMPLOYEE_VOUCHER,
          },
          {
            label: 'Tip Credit', id: 'tip-credit', icon: 'savings',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/compliance-setting/tip-credit'],
            menuId: MENU_ID.TIP_CREDIT,
          },
          {
            label: 'Monitoring Company Documents', id: 'company-document', icon: 'edit_document',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/compliance-setting/company-document'],
            menuId: MENU_ID.MONITORING_COMPANY_DOCUMENTS,
          },
        ]
      },
      {
        label: 'Training', icon: 'school', id: 'menu_trainning',
        routerLink: localStorage.getItem('_disableAll') === '1' ? null : this.authService.hasViewPermission(MENU_ID.TRAINING_SCHEDULE) ? ['/app/in-service/list'] : null,
        menuId: this.getMenuId([MENU_ID.TRAINING_SCHEDULE, MENU_ID.TRAINING_TOPIC]),
        items: [
          {
            label: 'Topics', icon: 'bookmark', id: 'topic',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/training-topic/list'],
            menuId: MENU_ID.TRAINING_TOPIC
          }
        ],
        uid: 9003
      },

      {
        label: 'employeeName', icon: 'account_circle', id: 'menu_employee_list',
        items: [
          {
            label: 'Manage Employees',
            icon: 'settings',
            id: 'menu_employee',
            items: [
              {
                label: labelEmployeeProfile, id: 'menu_employee_profile', icon: 'group',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : [employeeProfileURL],
                menuId: MENU_ID.EMPLOYEES
              },
              {
                label: 'Add Employee', id: 'menu_employee_add', icon: 'person_add',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee/add'],
                menuId: MENU_ID.ADD_EMPLOYEE
              },
              {
                label: 'Interns', id: 'menu_intern',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/intern/list'],
                menuId: MENU_ID.INTERN
              },
              {
                label: 'Volunteers', id: 'menu_volunteer',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/volunteer/list'],
                menuId: MENU_ID.VOLUNTEER
              }
            ],
            uid: 90025
          },
          {
            label: 'Employee On Duty', id: 'menu_employee_on_duty',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee-onduty'],
            menuId: MENU_ID.EMPLOYEE_ON_DUTY,
            icon: 'record_voice_over',

          },
          {
            label: 'Punch Clock', id: 'menu_employee_punch', icon: 'apps',
            items: [
              {
                label: 'Punch Clock', id: 'menu_employee_punch_clock',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee-clocking/punch'],
                menuId: MENU_ID.PUNCH_IN_OUT,
                icon: 'apps'
              },
              {
                label: 'History', id: 'punch_history',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee-clocking/punch-history'],
                menuId: MENU_ID.PUNCH_HISTORY,
                icon: 'history'
              },
              {
                label: 'Punctuality', id: 'menu_on_time_late',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee-clocking/ontime-late'],
                menuId: MENU_ID.PUNCTUALITY,
                icon: 'timer'
              },
            ],
            uid: 90026
          },
          {
            label: 'Time-Off Requests', icon: 'free_cancellation',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/absence/list'],
            items: [
              {
                label: 'Add Request', id: 'menu_add_time_off_request', icon: 'event_busy',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/absence/add'],
                menuId: this.authService.getLoggedInUserEditIdList().includes(MENU_ID.EMPLOYEE_TIME_OFF) ? MENU_ID.EMPLOYEE_TIME_OFF : -1
              },
              {
                label: 'Calendar', id: 'menu_employee_calendar', icon: 'calendar_month',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/absence/calendar'],
                menuId: MENU_ID.EMPLOYEE_CALENDAR
              }
            ],
            uid: 90029
          },
          {
            label: 'Payments', icon: 'monetization_on',
            items: [
              {
                label: 'Employee Payment', id: 'menu_employee_payment',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee/payment'],
                menuId: MENU_ID.EMPLOYEE_PAYMENT,
                icon: 'monetization_on'
              },
              {
                label: 'Timesheets', id: 'menu_timesheet', icon: 'task',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee/timesheet'],
                menuId: MENU_ID.TIME_SHEET
              },
              {
                label: 'Time Corrections', id: 'menu_time_correction', icon: 'edit_calendar',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee/protest-hour'],
                menuId: MENU_ID.EMPLOYEE_TIME_CORRECTION
              },
              {
                label: 'Paystubs', id: 'menu_employee_paystub', icon: 'receipt_long',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee/paystub'],
                menuId: MENU_ID.PAYSTUB
              },
              {
                label: 'Expenses', id: 'menu_expense', icon: 'point_of_sale',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee/expense/list'],
                menuId: MENU_ID.EXPENSE
              }
            ],
            uid: 90027
          },
          {
            label: 'Event Schedule', icon: 'date_range',
            items: [
              {
                label: 'All Events', id: 'menu_event_schedule',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee/scheduler/event-schedule'],
                menuId: MENU_ID.EVENT_SCHEDULE,
              },
            ],
            uid: 90078
          },
          {
            label: 'Performance', icon: 'query_stats',
            items: [
              {
                label: 'Employee Evaluation', id: 'menu_employee_performance', icon: 'star_half',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/performance/employee-performance'],
                menuId: MENU_ID.EMPLOYEE_EVALUATION,
              },
              {
                label: 'Evaluation Results', id: 'menu_performance', icon: 'align_horizontal_left',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/performance/evaluation-results'],
                menuId: MENU_ID.EMPLOYEE_EVALUATION,
              },
              {
                label: 'Evaluation Criteria', id: 'menu_performance_configuration', icon: 'tune',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/performance/list'],
                menuId: MENU_ID.EMPLOYEE_EVALUATION,
              },
              {
                label: 'Suspension Records', id: 'menu_employee_suspension',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/performance/suspension-record/list'],
                menuId: MENU_ID.SUSPENSION_RECORDS
              },
            ],
            uid: 90029
          },
          // {
          //   label: 'Employee Authorization', id: 'menu_employee_auth',
          //   routerLink: localStorage.getItem('_disableAll') === '1' ? null :
          //     ['/app/employee/employmentAuthorization/' + this.authService.getCurrentLoggedInId()],
          //   menuId: MENU_ID.677
          // },
          {
            label: 'View Details Report', id: 'employee_detail_report',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee/detail-report'],
            menuId: MENU_ID.VIEW_DETAILS_REPORT
          },
          // {
          //   label: 'Employee Setting', id: 'employee_setting',
          //   routerLink: localStorage.getItem('_disableAll') === '1' ? null :
          //     ['/app/employee/setting'],
          //   menuId: MENU_ID.678
          // },
          {
            label: 'Phone Directory', id: 'phone_directory', icon: 'contact_phone',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null :
              ['/app/employee/phone-directory'],
            menuId: MENU_ID.PHONE_DIRECTORY
          },
          {
            label: 'Job Report', id: 'employee_job_report', icon: 'assignment_add',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null :
              ['/app/employee/employee-job-report/list'],
            menuId: MENU_ID.EMPLOYEE_JOB_REPORT
          },
          {
            label: 'Time History', id: 'employee_time_history', icon: 'work_history',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null :
              ['/app/employee/employee-time-history'],
            menuId: MENU_ID.EMPLOYEE_TIME_HISTORY
          },
          {
            label: 'Scheduler', icon: 'date_range', id: 'menu_scheduler',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee/scheduler'],
            menuId: MENU_ID.EMPLOYEE_SCHEDULER
          }
        ],
        uid: 9004
      },
      {
        label: 'Work Order', icon: 'assignment', id: 'menu_plan_assignment',
        routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/workorder/list'],
        menuId: MENU_ID.WORK_ORDER,
        uid: 9005
      },
      {
        label: 'Administrative', icon: 'security', id: 'menu_administrative',
        items: [
          {
            label: 'Platform Admins', id: 'menu_platform_admin',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/admin/platform-admin/list'],
            menuId: MENU_ID.PLATFORM_ADMIN,
            icon: 'verified_user'
          },
          {
            label: 'Administrators', id: 'menu_admin',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/admin/agency-admin/list'],
            menuId: this.getMenuId([MENU_ID.ADMINISTRATORS, MENU_ID.PREFERENCES]),
            icon: 'vpn_key'
          },
          {
            label: 'Groups', id: 'menu_groups',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/admin/groups/list'],
            menuId: MENU_ID.GROUPS,
            icon: 'group'
          },
          {
            label: 'Process Monitor', id: 'menu_process_monitor',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/admin/process-monitor/list'],
            menuId: this.getMenuId([MENU_ID.PROCESS_MONITOR, MENU_ID.MONITOR_DETAILS, MENU_ID.LOGIN_ACTIVITY, MENU_ID.EMAIL_ACTIVITY]),
            icon: 'pageview',
            items: [
              {
                label: 'Monitor Details', id: 'menu_monitor_details',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/admin/process-monitor/monitor-details/list'],
                menuId: MENU_ID.MONITOR_DETAILS,
                icon: 'memory'
              },
              {
                label: 'Login Activity', id: 'menu_login_activity',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/admin/process-monitor/monitor-details/login-activity'],
                menuId: MENU_ID.LOGIN_ACTIVITY,
                icon: 'admin_panel_settings'
              },
              {
                label: 'Email Activity', id: 'menu_email_activity',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/admin/process-monitor/email-activity'],
                menuId: MENU_ID.EMAIL_ACTIVITY,
                icon: 'send_and_archive'
              },
              {
                label: 'Export Monitor', id: 'export_monitor',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/admin/process-monitor/export-monitor'],
                menuId: MENU_ID.EXPORT_MONITOR,
                icon: 'admin_panel_settings'
              },
            ],
          },
          {
            label: 'Desk Activity', id: 'menu_employee_av_board',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/admin/employee-av-board'],
            menuId: MENU_ID.DESK_ACTIVITY,
            icon: 'mouse'
          },
          {
            label: 'Monitoring', id: 'menu_monitoring',
            items: [
              {
                label: 'Suspicious Activity', id: 'menu_suspicious_activities',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/admin/monitoring/suspicious-activities'],
                menuId: MENU_ID.SUSPICIOUS_ACTIVITIES,
                icon: 'error'
              },
            ],
            // menuId: this.getMenuId([MENU_ID.MONITORING, MENU_ID.SUSPICIOUS_ACTIVITIES]),
            icon: 'fingerprint'
          },
          {
            label: 'Email Subscriptions', id: 'menu_email_subscription',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/admin/email-subscription/list'],
            menuId: MENU_ID.EMAIL_SUBSCRIPTION,
            icon: 'mark_email_read'
          },
          {
            label: 'Guests', id: 'menu_guest',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/guest/list'],
            menuId: MENU_ID.GUESTS,
            icon: 'supervised_user_circle'
          },
          {
            label: 'Plan Preview', id: 'menu_plan',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/plan/preview'],
            menuId: MENU_ID.PLAN_PREVIEW,
            icon: 'workspaces'
          },
          {
            label: 'Maintenance', id: 'menu_maintenance',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/maintenance/list'],
            menuId: MENU_ID.MAINTENANCE,
            icon: 'construction'
          },
        ],
        uid: 9006
      },
      {
        label: 'Calendar', id: 'menu_clendar', icon: 'event',
        routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/mananger-calendar/list'],
        menuId: MENU_ID.MANAGER_CALENDAR,
        uid: 9007
      },
      {
        label: 'CRMfull', id: 'menu_crm',
        icon: 'contacts',
        items: [
          {
            label: 'Clients', id: 'menu_crm_list', icon: 'business_center',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/client/list'],
            menuId: MENU_ID.CRM
          },
          {
            label: 'Client Contracts', id: 'menu_client_contract_list', icon: 'find_in_page',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/client/contract/list'],
            menuId: MENU_ID.CLIENT_CONTRACT
          },
          // {
          //   label: 'Client Documents', id: 'menu_clientdocument', icon: 'folder_zip',
          //   routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/document/list'],
          //   menuId: MENU_ID.54
          // },
          {
            label: 'Client Transfers', id: 'menu_client_transferred', icon: 'transfer_within_a_station',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/client/transfer-acceptance/'],
            menuId: MENU_ID.CLIENT_TRANSFERRED
          },
          {
            label: 'Products', id: 'menu_product', icon: 'sell',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/products/list'],
            menuId: MENU_ID.CRM_PRODUCT
          },
          {
            label: 'Client User Accounts', id: 'menu_client_account', icon: 'switch_account',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/client-account/list'],
            menuId: MENU_ID.CLIENT_USER_ACCOUNT
          },
          {
            label: 'Billing Client Log', id: 'menu_job_follow_up', icon: 'pending_actions',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/job-follow-up/list'],
            menuId: (this.authService.hasViewPermission(1012)) ? 1012 : -1
          },
          {
            label: 'Campaigns', id: 'menu_crm_campaign', icon: 'campaign',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/campaign/list'],
            menuId: MENU_ID.CAMPAIGN
          },
          {
            label: 'Activity Log', id: 'activity_log', icon: 'troubleshoot',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/activity-log/list'],
            menuId: MENU_ID.ACTIVITY_LOG
          },
          {
            label: 'Leads', id: 'lead_list', icon: 'campaign',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/potential/list'],
            menuId: MENU_ID.CRM_LEAD
          },
          {
            label: 'Email Templates', id: 'email_template', icon: 'post_add',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/email-template/list'],
            menuId: MENU_ID.EMAIL_TEMPLATE
          },
          {
            label: 'Linked Accounts', id: 'menu_client_info', icon: 'link',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/client-info-account/list'],
            menuId: MENU_ID.LINKED_ACCOUNTS
          },
          {
            label: 'EVV', id: 'menu_evv_registered', icon: 'add_location_alt',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/evv/search'],
            menuId: MENU_ID.EVV_REGISTERED
          },
          {
            label: 'Call Note', id: 'call_note', icon: 'assignment',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/call-note'],
            menuId: MENU_ID.CALL_NOTES
          },
          {
            label: 'Call Record', id: 'call_record', icon: 'record_voice_over',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/call-record'],
            menuId: MENU_ID.CALL_RECORDS
          },
          {
            label: 'Operrtel Contact List', id: 'operrtel_contact_list', icon: 'contacts',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/operrtel/contacts-list'],
            menuId: MENU_ID.CRM_OPERRTEL_CONTACT_LISTS
          },
          {
            label: 'Timer Records', id: 'timer_records_list', icon: 'timer',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/timer-records'],
            menuId: MENU_ID.TIMER_RECORDS
          }
        ],
        uid: 9008
      },
      {
        label: 'VRMfull', id: 'menu_vrm',
        routerLink: localStorage.getItem('_disableAll') === '1' ? null : this.authService.hasViewPermission(MENU_ID.VRM_DASHBOARD) ? ['/app/voter/dashboard'] : null,
        items: [
          {
            label: 'Electoral Campaign', id: 'menu_crm_campaign_vrm',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/campaign/electoral/list'],
            icon: 'record_voice_over',
            menuId: MENU_ID.ELECTORAL_CAMPAIGN
          },
          {
            label: 'Voter List', id: 'menu_voter_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/ele-campaign/voter/list'],
            icon: 'receipt_long',
            menuId: MENU_ID.VOTER
          },
          {
            label: 'Walker\'s List', id: 'menu_walker_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/ele-campaign/walker/list'],
            icon: 'directions_walk',
            menuId: MENU_ID.WALKER
          },
          {
            label: 'Zone Management', id: 'menu_zone_management',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/ele-campaign/zone-management/list'],
            icon: 'location_on',
            menuId: MENU_ID.ZONE_MANAGEMENT
          },
          {
            label: 'Candidates', id: 'menu_candidate_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/ele-campaign/candidate/list'],
            icon: 'groups',
            menuId: MENU_ID.CAMPAGN_CANDIDATE
          },
          {
            label: 'Schedule Batch', id: 'menu_schedule_batch',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/ele-campaign/schedule-batch'],
            icon: 'event_note',
            menuId: MENU_ID.SCHEDULE_BATCH
          },
          {
            label: 'Flyer List', id: 'menu_flyer_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/ele-campaign/flyer/list'],
            icon: 'markunread_mailbox',
            menuId: MENU_ID.FLYER
          },
          {
            label: 'Customize Questions', id: 'menu_customize_questions',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/vrm/customize-questions/list'],
            icon: 'help_center',
            menuId: MENU_ID.CUSTOMIZE_QUESTIONS
          },
          {
            label: 'Visit History', id: 'menu_visit_history',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/ele-campaign/visit-history/list'],
            icon: 'history',
            menuId: MENU_ID.VISIT_HISTORY,
          },
          {
            label: 'Voter Import History', id: 'menu_voter_upload_history',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/ele-campaign/voter-upload-history/list'],
            icon: 'upload',
            menuId: MENU_ID.VOTER_IMPORT_HISTORY,
          },
          {
            label: 'Fundraisers', id: 'menu_fundraisers',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/vrm/fundraisers/list'],
            icon: 'help_center',
            menuId: MENU_ID.FUNDRAISERS
          },
        ],
        uid: 9008
      },
      {
        label: 'Ind. Contractors', icon: 'business_center', id: 'menu_contractor',
        items: [
          {
            label: 'Contractors', id: 'menu_contractor_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/freelancer/list'],
            icon: 'face',
            menuId: MENU_ID.IND_CONTRACTORS,

          },
          {
            label: 'Assigned Tickets', id: 'menu_assigned_ticket',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/freelancer/assignedtickets'],
            icon: 'assignment',
            menuId: MENU_ID.ASSIGNED_TICKET,

          },
          {
            label: 'Completed Tickets', id: 'menu_completed_ticket',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/freelancer/completedtickets'],
            icon: 'assignment_turned_in',
            menuId: MENU_ID.COMPLETED_TICKETS,

          },
          {
            label: 'Ticket Summary', id: 'ticket_summary',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/contractors/ticket-summary'],
            icon: 'list_alt',
            menuId: MENU_ID.TICKET_SUMMARY,
          },
          {
            label: 'Payment Methods', id: 'menu_payment_method',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payment-method/list'],
            menuId: MENU_ID.PAYMENT_METHOD,
            icon: 'credit_card',
          },
          {
            label: 'Invoices', id: 'menu_contractor_invoie',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/contractors/invoice'],
            icon: 'description',
            menuId: MENU_ID.CONTRACTOR_INVOICE,

          },
        ],
        uid: 90023
      },
      {
        label: 'OperrTel', icon: 'ring_volume', id: 'menu_operrtel',
        items: [
          {
            label: 'Contract', id: 'menu_phone_contract', icon: 'policy',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/contract'],
            menuId: MENU_ID.PHONE_SYSTEM_CONTRACT,
          },
          {
            label: 'Phone Packages', id: 'menu_phone_package',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/list'],
            menuId: MENU_ID.PHONE_PACKAGE,
            icon: 'phonelink_setup'
          },
          {
            label: 'Invoices', id: 'menu_phone_invoice',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/invoice'],
            icon: 'request_quote',
            menuId: MENU_ID.PHONE_INVOICE,
          },
          {
            label: 'Payments', id: 'menu_phone_payment', icon: 'mobile_friendly',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payments/detail/list'],
            menuId: MENU_ID.PHONE_PAYMENT
          },

          {
            label: 'Send SMS', id: 'menu_send_sms', icon: 'sms',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/send-sms'],
            queryParams: { section: 'sendSms' },
            menuId: MENU_ID.PHONE_SYSTEM_SEND_SMS,
          },
          {
            label: 'SMS Usage', id: 'menu_sms_monitoring', icon: 'troubleshoot',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/sms-monitoring'],
            menuId: MENU_ID.SMS_USAGE,
          },
          {
            label: 'Auto-Dialer', id: 'menu_auto_dial', icon: 'dialpad',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/auto-dial'],
            menuId: MENU_ID.AUTO_DIAL,
          },
          {
            label: 'Bulk SMS', id: 'menu_bulk_sms', icon: 'sms',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/bulk-sms'],
            queryParams: { section: 'sendBulkSms' },
            menuId: MENU_ID.BULK_SMS,
          },
          {
            label: 'Scheduler', id: 'menu_schedule_list', icon: 'calendar_month',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/schedule-list'],
            menuId: MENU_ID.PHONE_SYSTEM_SCHEDULE,
          },
          {
            label: 'Top Up', id: 'menu_top_up', icon: 'mobile_friendly',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/top-up'],
            menuId: MENU_ID.PHONE_SYSTEM_TOP_UP,
          },
          {
            label: 'My Messages', id: 'menu_my_message', icon: 'textsms',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/my-message'],
            menuId: MENU_ID.MY_MESSAGE
          },
          {
            label: 'OperrTel Groups', id: 'menu_vrm_group',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/ele-campaign/vrm-group/list'],
            icon: 'groups',
            menuId: MENU_ID.OPERRTEL_GROUP,
          },
          {
            label: 'Contacts', icon: 'contact_emergency', id: 'menu_my_contact',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/operrtel/contacts-list'],
            uid: 90025,
            menuId: MENU_ID.OPERRTEL_CONTACT_LISTS,
          },
          {
            label: 'DRIP Campaign', id: 'menu_drip_campaign',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/drip-campaign/list'],
            icon: 'groups',
            menuId: MENU_ID.DRIP_CAMPAIGN,
          },
          {
            label: 'Shorten URLs', id: 'shorten_url',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/shorten-url'],
            icon: 'content_cut',
            menuId: MENU_ID.SHORTEN_URL,
          },
        ],
        uid: 9009
      },
      {
        label: 'Member', id: 'member', icon: 'health_and_safety',
        items: [
          {
            label: 'Member Profiles', id: 'member_profile', icon: 'account_circle',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/member/list'],
            menuId: MENU_ID.MEMBER_PROFILE,
          },
          {
            label: 'Schedules', id: 'member_schedule', icon: 'calendar_month',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/member/schedule/list'],
            menuId: MENU_ID.MEMBER_SCHEDULE,
          },
        ],
        uid: 9009
      },
      {
        label: 'Payments', icon: 'payment', id: 'menu_payment',
        items: [
          {
            label: 'Billing', id: 'menu_billing',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/billings/list'],
            menuId: this.getMenuId([MENU_ID.BILLING, MENU_ID.ADD_CLIENT_BILLING_DETAILS, MENU_ID.INVOICE_TEMPLATE, MENU_ID.DISPATCH_BILLING, MENU_ID.PARKING_BILLING, MENU_ID.PARKING_INVOICES, MENU_ID.CLIENTS_BASES, MENU_ID.DISPATCH_API_USAGE]),
            icon: 'paid'
          },
          {
            label: 'Process Payments', id: 'menu_process_payment',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payments/detail'],
            menuId: this.getMenuId([MENU_ID.PROCESS_PAYMENT, MENU_ID.PAYMENT_METHOD, MENU_ID.PAYMENT_AVAILABILITY, MENU_ID.PAYMENTS, MENU_ID.PHONE_PAYMENT, MENU_ID.VIEW_PAYMENT_METHOD, MENU_ID.FOLLOW_UPS, MENU_ID.CLIENT_SELF_PAYMENT]),
            icon: 'credit_score'
          },
          {
            label: 'Invoices', id: 'menu_invoice',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payments/invoice'],
            icon: 'request_quote',
            menuId: this.getMenuId([MENU_ID.INVOICE, MENU_ID.OLD_INVOICE, MENU_ID.INVOICE_VERIFICATION, MENU_ID.INVOICE_SUMMARY]),
          },
          {
            label: 'Invoice Templates', id: 'menu_invoice_template',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : this.isPlatFormAdmin() ? ['/app/billings/invoice-template/list'] : ['/app/billings/invoice-template/add'],
            icon: 'post_add',
            menuId: MENU_ID.INVOICE_TEMPLATE,
          },
          {
            label: 'Fees', id: 'fees_invoice_template', icon: 'price_check',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : this.isPlatFormAdmin() ? ['/app/monthly-fee/list'] : ['/app/monthly-fee/list'],
            menuId: MENU_ID.MONTHLY_FEE,
          },
          {
            label: 'Wallet', id: 'wallet', icon: 'account_balance_wallet',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payments/wallet'],
            menuId: MENU_ID.WALLET,
          },
          {
            label: 'Wallet Transactions', id: 'wallet_transaction', icon: 'receipt_long',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payments/wallet-transaction'],
            menuId: MENU_ID.WALLET_TRANSACTION,
          },
          {
            label: 'Invoice Verification', id: 'invoice_verification', icon: 'verified',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payments/invoice-verification'],
            menuId: MENU_ID.INVOICE_VERIFICATION,
          },
          {
            label: 'Invoice Summary', id: 'invoice_summary', icon: 'verified',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payments/invoice-summary'],
            menuId: MENU_ID.INVOICE_SUMMARY,
          }
        ],
        uid: 9010
      },
      {
        label: 'Billing Reports', icon: 'assessment', id: 'billing_report',
        items: [
          {
            label: 'MAS', id: 'mas_report',
            items: [
              {
                label: 'Total Job', id: 'total_job',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/job-follow-up/billing-total-job'],
                menuId: MENU_ID.TOTAL_JOB
              },
              {
                label: 'General Report', id: 'general_report',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/job-follow-up/billing-report'],
                menuId: MENU_ID.GENERAL_REPORT
              },
              {
                label: 'Details Report', id: 'details_report',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/job-follow-up/billing-details-report'],
                menuId: MENU_ID.DETAIL_REPORT
              },
              {
                label: 'Correction', id: 'correction',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/job-follow-up/billing-correction-report'],
                menuId: MENU_ID.BILLING_CORRECTION
              },
              {
                label: 'Upload History', id: 'upload_history',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/job-follow-up/billing-upload-history'],
                menuId: MENU_ID.BILLING_UPLOAD_HISTORY
              },
              {
                label: 'Billing Client Status', id: 'billing_client_status',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/job-follow-up/billing-client-status'],
                menuId: MENU_ID.BILLING_CLIENT_STATUS
              }
            ],
            uid: 9021
          },

          // changes
          {
            label: 'Fidelis Report', id: 'fidelis_report',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/fidelis-billing-report/list'],
            menuId: MENU_ID.FIDELIS_REPORT
          },
          {
            label: 'LOG', id: 'log_report',
            items: [{
              label: 'Log Total Job', id: 'log_total_job',
              routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/job-follow-up/billing-log-total-job'],
              menuId: MENU_ID.LOG_TOTAL_JOB
            },
            {
              label: 'Log Detail Report', id: 'log_details_report',
              routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/job-follow-up/billing-log-details-report'],
              menuId: MENU_ID.BILLING_LOG_DETAIL_REPORT
            }
            ],
            uid: 9022
          },
          {
            label: 'Dispatch Total Job', id: 'dispatch_total_job',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/job-follow-up/dispatch-total-job'],
            menuId: MENU_ID.DISPATCH_TOTAL_JOB
          },
        ],
        uid: 9020
      },
      {
        label: 'Accounting', icon: 'savings', id: 'menu_accounting',
        items: [
          {
            label: 'Transactions', id: 'menu_transaction',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/transaction/list'],
            icon: 'sync_alt',
            menuId: this.getMenuId([MENU_ID.TRANSACTIONS, MENU_ID.TRANSACTION_TYPES, MENU_ID.STATEMENT_BALANCE, MENU_ID.DISCOUNTS_WAIVERS, MENU_ID.TRANSACTION_GROUP])
          },
          {
            label: 'Taxes', id: 'menu_tax_info',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/tax-info/list'],
            icon: 'calculate',
            menuId: this.getMenuId([MENU_ID.TAXS, MENU_ID.TAX_TYPES])
          },
          {
            label: 'Received Payments', id: 'menu_transaction_details',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payment-info'],
            icon: 'price_check',
            menuId: MENU_ID.RECEIVED_PAYMENTS
          },
          {
            label: 'Projects', id: 'menu_project', icon: 'account_tree',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/project/add'],
            menuId: MENU_ID.PROJECTS
          },
          {
            label: 'Credit & Debit', id: 'menu_account',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/account/list'],
            icon: 'credit_card',
            menuId: MENU_ID.CREDIT_DEBIT_ACCOUNTS,
          },
          {
            label: 'Contracts & Licenses', id: 'menu_generic_compliance', icon: 'policy',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/generic-compliance/list'],
            menuId: MENU_ID.CONTRACTS_LICENSES
          },
          {
            label: 'Loans', id: 'menu_loan', icon: 'real_estate_agent',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/loan/list'],
            menuId: MENU_ID.LOANS
          },
          {
            label: 'Follow-up Bills', id: 'menu_bill', icon: 'forward_to_inbox',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee-bill'],
            menuId: MENU_ID.FOLLOW_UP_BILLS
          },
          {
            label: 'Payment Tracking', id: 'menu_payment_tracking', icon: 'find_in_page',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payment-tracking/list'],
            menuId: MENU_ID.PAYMENT_TRACKING
          },
          {
            label: 'Payroll Journal', id: 'menu_payroll_journal_report', icon: 'history_edu',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payroll-journal-report/list'],
            menuId: MENU_ID.PAYROLL_JOURNAL_REPORT
          },
          {
            label: 'Insurance', id: 'menu_insurance', icon: 'health_and_safety',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/insurance/list'],
            menuId: MENU_ID.INSURANCE_POLICIES
          },
          {
            label: 'File Examination', id: 'menu_file_examination', icon: 'rule_folder',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/file-examination/list'],
            menuId: MENU_ID.FILE_EXAMINATION
          },
          {
            label: 'General Invoices', id: 'menu_general_invoice', icon: 'request_quote',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/general-invoice/list'],
            menuId: this.getMenuId([MENU_ID.GENERAL_INVOICES, MENU_ID.INVOICE_TYPE])
          },
          {
            label: 'Invoice Tracking', id: 'menu_invoice_tracking', icon: 'account_tree',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/invoice-tracking/list'],
            menuId: MENU_ID.INVOICE_TRACKING
          },
          {
            label: 'Punch Billing', id: 'menu_punch_billing', icon: 'request_quote',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/billings/punch-billing/list'],
            menuId: MENU_ID.PUNCH_BILLING
          },
          {
            label: 'Accounts Receivable', id: 'ar_report', icon: 'insights',
            items: [
              {
                label: 'Revenue Payment', id: 'revenue_payment', icon: 'monetization_on',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/revenue-payment/list'],
                menuId: MENU_ID.REVENUE_PAYMENTS
              },
              {
                label: 'Service Revenue', id: 'service_revenue', icon: 'query_stats',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/service-revenue/list'],
                menuId: MENU_ID.SERVICE_REVENUE
              },
              {
                label: 'Gross Income', id: 'gross_income', icon: 'payments',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/gross-income/list'],
                menuId: MENU_ID.GROSS_INCOME
              },
              {
                label: 'Aging Details', id: 'accounts_receivable_aging_details', icon: 'manage_search',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/receivable-aging-details/list'],
                menuId: MENU_ID.AGING_DETAILS
              },
              {
                label: 'Aging Summary', id: 'aging_summary', icon: 'summarize',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/aging-summary/list'],
                menuId: MENU_ID.AGING_SUMMARY
              },
            ],
          },
          {
            label: 'Payroll', id: 'menu_contractor_payroll',
            icon: 'attach_money',
            items: [
              {
                label: 'Payroll', id: 'menu_list_payroll',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/contractors/payroll-list'],
                icon: 'price_check',
                menuId: MENU_ID.CONTRACTOR_PAYROLL,
              },
              {
                label: 'New Payroll', id: 'menu_new_payroll',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/contractors/new-payroll'],
                icon: 'add_circle',
                menuId: MENU_ID.CONTRACTOR_NEW_PAYROLL,
              }
            ]
          },
          {
            label: 'Billing Location', id: 'billing_location', icon: 'summarize',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/billing-location/list'],
            menuId: MENU_ID.BILLING_LOCATION
          },
          {
            label: 'SMS/MMS Billing', id: 'sms_mms_billing',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/sms-mms-billing/list'],
            menuId: MENU_ID.SMS_MMS_BILLING
          }
        ],
        uid: 9014
      },
      {
        label: 'Reports', icon: 'assessment', id: 'menu_report',
        items: [
          {
            label: 'Compliance Report', id: 'menu_compliance_report',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/reports'],
            menuId: MENU_ID.COMPLIANCE_REPORTS,
            icon: 'how_to_reg'
          },
          {
            label: 'Performance report', id: 'menu_job_report',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/reports/job-report'],
            menuId: MENU_ID.PERFORMANCE_REPORTS,
            icon: 'assignment_add'
          },
          {
            label: 'Report Templates', id: 'menu_report_template',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/reports/report-template/list'],
            menuId: MENU_ID.REPORT_TEMPLATES,
            icon: ''
          },
          {
            label: 'OperrTel Report', id: 'menu_operrtel_report',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/reports/operrtel-report'],
            menuId: MENU_ID.OPERRTEL,
            icon: ''
          },
          {
            label: 'API Usage Report', id: 'menu_api_usage_report', icon: 'integration_instructions',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/reports/api-usage-report'],
            menuId: MENU_ID.API_USAGE_REPORT,
          }
        ],
        menuId: MENU_ID.OPERRTEL_REPORTS,
        uid: 9011
      },
      {
        label: 'Resident', id: 'menu_resident', icon: 'real_estate_agent',
        routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/resident/list'],
        items: [
          {
            label: 'Sign-In/Out List', id: 'resident_sign_in_sign_out', icon: 'qr_code_scanner',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/resident/sign-in-out-list'],
            menuId: MENU_ID.SIGN_INS_OUTS_LIST
          },
          {
            label: 'Flag', id: 'resident_flag', icon: 'flag',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/resident/flag'],
            menuId: MENU_ID.RESIDENT_FLAG
          },
          {
            label: 'Alert History', id: 'alert_history', icon: 'history',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/resident/alert-history'],
            menuId: MENU_ID.ALERT_HISTORY
          },
          {
            label: 'Incident Report', id: 'incident-report', icon: 'emergency_home',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/resident/incident-report/list'],
            menuId: MENU_ID.INCIDENT_REPORT
          },
          {
            label: 'Curfew Schedule', id: 'curfew-schedule', icon: 'lock_clock',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/resident/curfew-schedule'],
            menuId: MENU_ID.CURFEW_SCHEDULE
          },
          {
            label: 'Resident Services', id: 'service-management', icon: 'roofing',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/resident/resident-services/list'],
            menuId: MENU_ID.SERVICE_MANAGEMENT
          },
        ],
        uid: 9017
      },
      {
        label: 'Reminder Alert', id: 'alarm_on',
        routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/reminder/list'],
        items: [],
        menuId: MENU_ID.REMINDER_ALERT,
        uid: 9017
      },
      {
        label: 'Task Management', icon: 'fact_check', id: 'menu_task_management',
        routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/tm'],
        items: [],
        menuId: MENU_ID.TASK_MANAGEMENT,
        uid: 9015
      },

      {
        label: 'Tools', icon: 'construction', id: 'support_tool',
        items: [
          {
            label: 'Paystub PDF Processer', id: 'menu_paystub_pdf_processer',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/paystub-pdf-processer'],
            menuId: MENU_ID.PAYSTUB_PDF_PROCESSER,
            icon: 'grid_on'
          },
          {
            label: 'Spreadsheets', icon: 'grid_on', id: 'spread_sheet',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/spread-sheet/list'],
            items: [],
            uid: 9030,
            menuId: MENU_ID.SPREAD_SHEET
          },
          {
            label: 'Multimedia', icon: 'videocam', id: 'conference_menu',
            items: [
              {
                label: 'Join Conference',
                id: 'menu_join_a_meeting',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/conference/join'],
                menuId: MENU_ID.JOIN_CONFERENCE,
                icon: 'camera_indoor'
              },
              {
                label: 'Video Conferences',
                id: 'menu_meeting_schedule',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/conference/schedule'],
                menuId: MENU_ID.VIDEO_CONFERENCES,
                icon: 'video_chat'
              },
              {
                label: 'Screen Recordings', id: 'menu_screen_recorder', icon: 'video_settings',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/screen-recorder/list'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
                RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: MENU_ID.SCREEN_RECORDER
              }
            ],
            uid: 9013
          },
          {
            label: 'Notebook', icon: 'auto_stories', id: 'menu_note_page_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/note'],
            items: [],
            menuId: MENU_ID.NOTEBOOK,
            uid: 9015
          },
          {
            label: 'Law Firms', icon: 'balance', id: 'menu_law_firm_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/law-firm/list'],
            items: [],
            menuId: MENU_ID.LAW_FIRM,
            uid: 9015
          },
          {
            label: 'Operr Drive', id: 'menu_document',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/document'],
            menuId: MENU_ID.OPERR_DRIVE,
            icon: 'history_edu'
          },
          {
            label: 'Working Time Calculator', id: 'menu_working_time_calculator',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/working-time-calculator'],
            menuId: MENU_ID.WORKING_TIME_CALCULATOR,
            icon: 'calculate'
          },
          {
            label: 'Recollection Details', icon: 'balance', id: 'menu_recollection_details_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/recollection-details/list'],
            items: [],
            menuId: MENU_ID.RECOLLECTION_DETAILS,
            uid: 9015
          },
          {
            label: 'Onboarding Requirements', icon: 'balance', id: 'menu_onboarding_requirements_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/onboarding-requirements/list'],
            items: [],
            menuId: MENU_ID.ONBOARDING_REQUIREMENTS,
            uid: 9015
          },
        ],
        uid: 9016
      },
      {
        label: 'Inventory', icon: 'inventory', id: 'menu_inventory',
        items: [
          {
            label: 'Inventory List', icon: 'local_offer', id: 'menu_inventory_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/stock/list'],
            items: [],
            uid: 9030,
            menuId: MENU_ID.INVENTORY,
          },
          {
            label: 'Items', icon: 'grid_on', id: 'menu_items',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/product/add'],
            items: [],
            uid: 9030,
            menuId: MENU_ID.INVENTORY_ITEMS
          },
          {
            label: 'Equipment Check-Out', icon: 'cloud_sync', id: 'menu_equipment_check_out',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/equipment/list'],
            items: [],
            uid: 9030,
            menuId: MENU_ID.EQUIPMENT_CHECK_OUT
          },
          {
            label: 'Vehicles', icon: 'car_rental', id: 'menu_vehicles',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/vehicle/list'],
            items: [],
            uid: 9030,
            menuId: MENU_ID.VEHICLES
          },
          {
            label: 'Purchase Requests', icon: 'production_quantity_limits', id: 'menu_purchase_requests',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/stock/purchase-list'],
            items: [],
            uid: 9030,
            menuId: MENU_ID.PURCHASE_REQUESTS
          },
          {
            label: 'Inventory Report', icon: 'description', id: 'menu_inventory_report',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/stock/stock-used/list'],
            items: [],
            uid: 9030,
            menuId: MENU_ID.INVENTORY_REPORT
          },
          {
            label: 'Manage Attributes', icon: 'edit_attributes', id: 'menu_manage_attributes',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/stock/manage-attribute'],
            items: [],
            uid: 9030,
            menuId: MENU_ID.MANAGE_ATTRIBUTES
          },
        ],
        uid: 9030
      }
    ];
    const newMenus = [
      { label: 'Billing Report', routerLink: ['/app/job-follow-up/billing-report'], menuId: 1012, hideOnMenuBar: true },
      { label: 'View Payment', routerLink: ['/app/payments/view-payment-method/list'], menuId: 704, hideOnMenuBar: true },
      { label: 'Follow-up List', routerLink: ['/app/payments/follow-up-note'], menuId: 704, hideOnMenuBar: true },
      { label: 'AR Report', routerLink: ['/app/revenue-payment/list'], menuId: 1160, hideOnMenuBar: true },
    ];

    allMenus2 = allMenus2.concat(newMenus);
    allMenus = allMenus2;
    // if (this.authService.isAgencyAd() || this.authService.isSubAgencyAd()) {
    //   allMenus.forEach((ele: any) => {
    //     if (ele.label === 'Operation') {
    //       ele.items = ele.items.filter((item) => {
    //         return item.menuId !== 6;
    //       });
    //     }
    //   });
    // }
    // if (this.isPlatFormAdmin()) {
    //   allMenus = allMenus.filter((ele: any) => {
    //     return ele.uid !== 9111 || ele.id !== 'employee_detail_report';
    //   });

    //   allMenus.forEach((ele: any) => {
    //     if (ele.label === 'employeeName') {
    //       ele.items = ele.items.filter((item) => {
    //         return item.menuId !== 1100;
    //       });
    //     }
    //   });
    // }
    // if (!this.authService.isSuper() && !this.authService.isSubSuper()
    //   && !this.authService.isCompanyAd() && !this.authService.isSubCompanyAd()) {
    //   allMenus.forEach((ele: any) => {
    //     if (ele.label === 'Operation') {
    //       ele.items = ele.items.filter((item) => {
    //         return item.uid !== 90021;
    //       });
    //     } else {
    //       if (ele.label === 'employeeName') {
    //         ele.items = ele.items.filter((item) => {
    //           return item.menuId !== 1100;
    //         });
    //       }
    //     }
    //   });
    // }

    // if (!this.isPlatFormAdmin()) {
    //   allMenus.forEach((ele: any) => {
    //     if (ele.label === 'Administrative') {
    //       ele.items = ele.items.filter((item) => {
    //         return item.id !== 'menu_maintenance';
    //       });
    //     } else if (ele.label === 'Tools') {
    //       ele.items = ele.items.filter((item) => {
    //         return item.id !== 'menu_recollection_details_list';
    //       });
    //     }
    //   });
    // }


    // if (this.authService.isClientRole()) {

    //   allMenus = allMenus.filter((ele: any) => {
    //     return ele.uid !== 9002 && ele.uid !== 9004 && ele.uid !== 9000;
    //   });
    // }

    // if (this.authService.isEmployeeRole()) {
    //   allMenus = allMenus.filter((ele: any) => {
    //     return ele.uid !== 9000 && ele.uid !== 9006;
    //   });
    //   allMenus.forEach(e => {
    //     if (e['items']) {
    //       e['items'] = e['items'].filter((ele: any) => {
    //         return ele.menuId !== 24;
    //       });
    //     }
    //   });
    //   allMenus = allMenus.filter((ele: any) => {
    //     if (ele.uid === 9004) {
    //       ele.items = ele.items.filter((item) => {
    //         if (item.uid === 90027) {
    //           item.items = item.items.filter((item2) => {
    //             return item2.id !== 'menu_employee_payment';
    //           });
    //         }
    //         return item;
    //       });
    //     }
    //     return ele;
    //   });

    //   allMenus = allMenus.filter((ele: any) => {
    //     if (ele.uid === 9002) {
    //       ele.items = ele.items.filter((item2) => {
    //         return item2.id !== 'menu_conference_room';
    //       });
    //     }

    //     if (ele.uid === 9020) {
    //       ele.items = ele.items.filter((item) => {
    //         if (item.uid === 9021) {
    //           item.items = item.items.filter((itm) => {
    //             return itm.id !== 1023;
    //           });
    //         }
    //         return item;
    //       });
    //     }
    //     return ele;
    //   });
    // }

    if (this.authService.isCompanyAdminRole()) {
      allMenus.forEach(e => {
        if (e['items']) {
          e['items'] = e['items'].filter((ele: any) => {
            return ele.menuId !== 2 && ele.menuId !== 1100;
          });
        }
      });
    }

    if (localStorage.getItem('_user') === 'freelancer') {
      if (this.authService.getContractorType() === 1 || this.authService.getContractorType() === 0) {
        allMenus = allMenus.filter((ele: any) => {
          if (ele.id === 'menu_contractor') {
            ele.items = ele.items.filter((item) => {
              return item.id === 'menu_completed_ticket' || item.id === 'menu_assigned_ticket'
                || item.id === 'menu_payment_method' || item.id === 'menu_contractor_list' || item.id === 'ticket_summary' || item.id === 'menu_employee_punch';
            });
          }
          if (ele.id === 'color') {
            return ele.items;
          }
          return ele;
        });
      }

    }

    if (localStorage.getItem('_user') === 'guess') {
      allMenus = allMenus.filter((ele: any) => {
        return ele.uid === 90023 || ele.uid === 9015 || ele.uid === 9005 || ele.uid === 9013;
      });
    }

    if (this.authService.isChildClientRole()) {
      allMenus = allMenus.filter((ele: any) => {
        if (ele.uid === 9000) {
          ele.items = ele.items.filter((item2) => {
            return item2.id !== 'client_dasboard';
          });
        }
        return ele.id !== 'client_dasboard';
      });
    }
    const userLoginInfo = this.authService.getUserInfo();
    if (this.authService.isContractorRole() && !userLoginInfo.groupId) {
      return [];
    }
    return allMenus;
  }

  getThemeConfig() {
    // console.log("GET ADMIN THEME");
    if (localStorage.getItem('_userId')) {
      const adminId = localStorage.getItem('_userId');
      const loginType = this.authService.getLoginUserType();
      const reqUrl = `${environment.v2_server_ip}/${this.apiUrl.URI.THEME.GETADMINTHEMECONFIG}/${adminId}/login-type/${loginType}`;
      this.http.get(reqUrl).subscribe(res => {
        const resObj: any = res;
        if (resObj.data) {
          const parsedData = JSON.parse(resObj.data.config);
          // console.log(parsedData);
          this.changeTheme(parsedData.theme, 'exits');
          this.changeLayout(parsedData.layout, 'exits');
        }
      });
    }
  }

  changeTheme(theme, type?) {
    if (theme !== '') {
      this.themeConfig.theme = theme;
      const themeLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('theme-css');
      if (themeLink) {
        themeLink.href = 'assets/theme/theme-' + theme + '.css';
      }
      if (!type) {
        this.saveThemeConfig();
      } else {
        // console.log(type);
      }
    }

  }

  changeLayout(theme, type?) {
    if (theme !== '') {
      this.themeConfig.layout = theme;
      const layoutLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('layout-css');
      if (layoutLink) {
        layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';
      }
      if (!type) {
        this.saveThemeConfig();
      } else {
        // console.log(type);
      }
    }
  }

  // save theme config to database
  saveThemeConfig() {
    if (localStorage.getItem('_globals')) {
      if (localStorage.getItem('_config')) {
        const loggedIn = JSON.parse(localStorage.getItem('_globals'));
        const loginType = this.authService.getLoginUserType();
        const reqUrl = `${environment.v2_server_ip}/${this.apiUrl.URI.THEME.PUTADMINTHEMECONFIG}`;
        const config = {
          'adminId': loggedIn.adminId,
          'loginType': loginType,
          'config': JSON.stringify(this.themeConfig)
        };
        return this.http.post(reqUrl, config).subscribe(res => {
        });
      }
    }
  }

  isPlatFormAdmin() {
    if (this.authService.isSuper() || this.authService.isSubSuper() || this.authService.isCEOAdmin()) {
      return true;
    }
  }


  getMenuId(menuIds: Number[]) {
    for (let menuId of this.authService.getLoggedInUserMenuIdList()) {
      if (menuIds.includes(menuId)) {
        return menuId;
      }
    }
    return -1;
  }
}
