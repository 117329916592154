import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MENU_ID } from 'app/shared/data/menu-id';
import { MessageService } from 'primeng';
import { AuthService } from '../shared/service/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class PermissionGuard implements CanActivate {
  constructor(private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    private translateService: TranslateService) {

  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const roleWithoutPermission: Number[] = route.data['roleWithoutPermission'] || [];
    if (roleWithoutPermission.length > 0 && roleWithoutPermission.includes(this.authService.getRoleLevel())) {
      return true;
    }
    const canView: Number[] = route.data['canView'] || [];
    const canEdit: Number[] = route.data['canEdit'] || [];
    const role: Number[] = route.data['role'] || [];
    if (canView.length === 0 && canEdit.length === 0 && role.length === 0) {
      return false;
    }
    const userPermissionsView: Number[] = this.authService.getLoggedInUserMenuIdList();
    const userPermissionsEdit: Number[] = this.authService.getLoggedInUserEditIdList();

    const hasViewPermission = canView.length && canView.find(permission => permission === MENU_ID.ANONYMOUS || userPermissionsView.includes(permission));
    const hasEditPermission = canEdit.length > 0 && canEdit.find(permission => permission === MENU_ID.ANONYMOUS || userPermissionsEdit.includes(permission));
    if ((role.length === 0 || (role.length > 0 && role.find(r => r === this.authService.getRoleLevel()))) 
      && (hasViewPermission || hasEditPermission)) {
      return true;
    } else {
      if (this.router.url === '/' || this.router.url === '' || this.router.url.indexOf('/login') >= 0) {
        this.router.navigate(['/permisson-denied']);
      } else {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('Permission Denied'),
          detail: this.translateService.instant('Sorry, you do not have permission to access this page.')
        });
      }
      return false;
    }
  }
}
