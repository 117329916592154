<div class="spinner-overlay" *ngIf="exportPdfLoading">
	<p-progressSpinner class="center-spin"></p-progressSpinner>
</div>
<p-toast position="bottom-right">{{msgs}}</p-toast>
<div class="card add-shadow p-4">
	<h1 class="m-0 ml-2 my-4">{{'Invoice Verification' | translate}}</h1>
	<p-panel [toggleable]="true">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Invoices' | translate}}</span>
		</p-header>
		<div class="ui-g">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Client/Base' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-briefcase"></i>
						</button>
					</div>
					<p-dropdown [ngModelOptions]="{'standalone':true}" [options]="allClientList" [filter]="true" [(ngModel)]="selectedClient" [showClear]="true" [disabled]="disableFields" placeholder="{{'pleaseSelect' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{ 'dateRange' | translate }}: </label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-week"></i>
						</button>
					</div>
					<mat-form-field class="w-100">
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate matInput placeholder="{{ 'StartDate' | translate }}" [(ngModel)]="fromDate" name="Startdate" (focus)="picker.open()" />
							<input matEndDate matInput placeholder="{{ 'EndDate' | translate }}" [(ngModel)]="toDate" name="Enddate" (focus)="picker.open()" />
						</mat-date-range-input>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Verified By' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-user"></i>
						</button>
					</div>
					<p-dropdown [options]="verifyByOptions" [ngModelOptions]="{'standalone':true}"  (ngModelChange)="searchVerifyBy($event)" optionLabel="label" id="id" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedVerifyBy">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Status' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-info"></i>
						</button>
					</div>
					<p-dropdown [options]="statusTypes" [(ngModel)]="selectedStatus" [showClear]="true" placeholder="{{'pleaseSelect' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Billing Type' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" type="button" style="cursor:default !important">
							<i class="fa-solid fa-cash-register"></i>
						</button>
					</div>
					<p-dropdown filter="true" [options]="typeList" [showClear]="true"  [(ngModel)]="selectedType" placeholder="{{'pleaseSelect' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-12 py-4 text-center">
				<button type="button" class="btn btn-danger mx-1" (click)="reset()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
				<button type="button" class="btn btn-primary mx-1" (click)="search()"><i class="fa-solid fa-check mr-2"></i>{{'button.search' | translate}}</button>
				<button type="button" class="btn btn-primary mx-1" (click)="op5.toggle($event)"><i class="fa-solid fa-file-export mr-2"></i>{{'Export' | translate}}</button>
			</div>
		</div>
	</p-panel>
	<div class="d-flex align-items-center justify-content-start my-4">
		<h1 class="m-0 ml-2">{{'Browse Invoices' | translate}}</h1>
	</div>
	<p-panel id="table-ppanel" [toggleable]="true">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Invoice Verifications' | translate}} ({{totalRecords || 0 | number}})</span>
		</p-header>
		<p-contextMenu #cm [model]="items"></p-contextMenu>
		<p-table #dt (onFilter)="onFilter($event, dt)" [value]="contentList" class="w-100" [resizableColumns]="true" [rows]="10" [lazy]="true" (onLazyLoad)="search($event)" [paginator]="true" [totalRecords]="totalRecords" (sortFunction)="customSort($event)" [rowsPerPageOptions]="[10,25,50]" [responsive]="true" [columns]="cols"
				 [globalFilterFields]="['verification1st', 'verification2st', 'status', 'clientName', 'invoiceType', 'invoiceNumber',
				  'fee', 'apiUsageCost', 'feeStr', 'apiUsageCostStr', 'totalServiceAndUsageStr', 'invoiceNumberStr']">
			<ng-template pTemplate="caption">
				<div class="d-flex align-items-center flex-wrap justify-content-between" style="row-gap: 10px">
					<div class="d-flex">
						<button *ngIf="!showCheckbox" type="button" (click)="assignInBulkClicked()" class="btn btn-info mx-1" [disabled]="showCheckbox">
							<span class="text-nowrap"><i class="fa-solid fa-square-check mr-2"></i> {{'Bulk Verification' | translate}}</span>
						</button>
						<button *ngIf="showCheckbox" type="button" (click)="cancelAssignInBulk()" class="btn btn-danger mx-1">
							<span class="text-nowrap"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel Bulk Verification' | translate}}</span>
						</button>
						<button type="button" (click)="emailInvoice()" class="btn btn-primary mx-1" [disabled]="disableEmailSmsInvoice">
							<span class="text-nowrap"><i class="fa-solid fa-envelope mr-2"></i> {{'Email Invoice' | translate}}</span>
						</button>
						<button type="button" (click)="showConfirmationPopupSmsInvoice = true" class="btn btn-primary mx-1" [disabled]="disableEmailSmsInvoice">
							<span class="text-nowrap"><i class="fa-solid fa-envelope mr-2"></i> {{'SMS Invoice' | translate}}</span>
						</button>
					</div>
					<div class="input-group flex-grow-1 mr-2" style="max-width: 400px">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button">
								<i class="fa-solid fa-filter"></i>
							</button>
						</div>
						<input type="text" [(ngModel)]="textSearch" pInputText placeholder="{{'Filter' | translate}}" (input)="dt.filterGlobal($event?.target?.value, 'contains')">
					</div>
				</div>
			</ng-template>

			<ng-template pTemplate="header" let-columns>
				<tr>
					<th>#</th>
					<th id="thEmailInvoice">
						<div style="padding-left: 41px;padding-right: 41px;">
							<p-checkbox value="true" [(ngModel)]="emailSmsAll" (ngModelChange)="bulkEmailSms($event, true)" binary="true"></p-checkbox>
						</div>
						<span>Email/SMS</span>
					</th>
					<th id="bulkAssignColumn" *ngIf="showCheckbox">
<!--						<i class="fa-solid fa-square-check text-muted m-auto" style="font-size: 16px" pTooltip="{{'Assign (Bulk)' | translate}}" tooltipPosition="left"></i>-->
						<div style="padding-left: 41px;padding-right: 41px;">
							<p-checkbox value="true" [(ngModel)]="bulkAll" (ngModelChange)="bulkVerifications($event, true)" binary="true"></p-checkbox>
						</div>						
						<span>Bulk Verification</span>
					</th>
					<th *ngFor="let col of cols">
						<div class="d-flex align-items-center justify-content-between w-100">
							<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
							<span *ngIf="col.sort">
								<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.dbField; setSortOption(col.field, col.sortOptions)">
									<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
								</span>
							</span>
						</div>
					</th>
				</tr>
			</ng-template>

			<ng-template pTemplate="body" let-rowData let-i="rowIndex">
				<tr *ngIf="loading && (i+1 === 1)">
					<td [attr.colspan]="11" class="text-center py-5">
						<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
					</td>
				</tr>
				<tr *ngIf="!loading" [pSelectableRow]="rowData" [pContextMenuRow]="rowData">
					<td>
						<span class="d-flex">
							{{i + 1}}
						</span>
					</td>
					<td class="tdEmailInvoice">
						<p-checkbox [disabled]="(!rowData.verification1st && !rowData.verification2st) || rowData.status === 'Paid'" value="true" [(ngModel)]="rowData.checked" binary="true" pTooltip="{{'Email/SMS Invoice' | translate}}" (ngModelChange)="bulkEmailSms(rowData, false)"></p-checkbox>
					</td>
					<td *ngIf="showCheckbox" class="tdBulkAssignColumn">
						<p-checkbox [disabled]="(rowData.verification1st && rowData.verification2st) || (rowData.verification1st == authService.getCurrentUsername()) || (rowData.verification2st == authService.getCurrentUsername())" value="true" [(ngModel)]="rowData.verificationChecked"
									binary="true" (ngModelChange)="bulkVerifications(rowData, false)"></p-checkbox>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Created' | translate}}: </span>
							<span *ngIf="rowData.createdAt" pTooltip="{{rowData?.createdAt | date: 'MM/dd/yyyy h:mm a'}}" tooltipPosition="left">{{rowData?.createdAt | date: 'MM/dd/yyyy h:mm a'}}</span>
							<span *ngIf="!rowData.createdAt" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Client Name' | translate}}: </span>
							<span *ngIf="getClientName(rowData)" pTooltip="{{getClientName(rowData)}}">{{getClientName(rowData)}}</span>
							<span *ngIf="!getClientName(rowData)" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Invoice Type' | translate}}: </span>
							<span *ngIf="rowData.invoice?.invoiceType" pTooltip="{{rowData.invoice?.invoiceType}}" tooltipPosition="left">{{rowData.invoice?.invoiceType}}</span>
							<span *ngIf="!rowData.invoice?.invoiceType" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Invoice #' | translate}}: </span>
							<span *ngIf="rowData.invoice?.invoiceNumber" class="text-primary c-pointer" (click)="clickInvoiceRef($event, rowData);" pTooltip="{{rowData.invoice?.invoiceNumber}}" style="cursor: pointer">#{{rowData.invoice?.invoiceNumber}}</span>
							<span *ngIf="!rowData.invoice?.invoiceNumber" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Service Fee' | translate}}: </span>
							<span *ngIf="rowData.invoice?.fee" pTooltip="{{rowData.invoice?.fee | currency:'USD'}}"
								tooltipPosition="left">{{rowData.invoice?.fee | currency:'USD'}}</span>
							<span *ngIf="!rowData.invoice?.fee" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'API Usage Fee' | translate}}: </span>
							<span *ngIf="rowData.invoice?.apiUsageCost" pTooltip="{{rowData.invoice?.apiUsageCost | currency:'USD'}}"
								tooltipPosition="left">{{rowData.invoice?.apiUsageCost | currency:'USD'}}</span>
							<span *ngIf="!rowData.invoice?.apiUsageCost" class="text-muted">0.00</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Total' | translate}}: </span>
							<span *ngIf="rowData.totalServiceAndUsage" pTooltip="{{rowData.totalServiceAndUsage | currency:'USD'}}"
								  tooltipPosition="left">{{rowData.totalServiceAndUsage | currency:'USD'}}</span>
							<span *ngIf="!rowData.totalServiceAndUsage" class="text-muted">0.00</span>
						</span>
					</td>
					<td>
						<span *ngIf="rowData.verification1st" class="one-liner">
							<span class="mobile-table-label">{{'1st Verification' | translate}}: </span>{{rowData.verification1st}}
						</span>
						<span *ngIf="!rowData.verification1st && rowData.verification2st != authService.getCurrentUsername()" class="one-liner">
							<span class="mobile-table-label">{{'1st Verification' | translate}}: </span><a href="javascript:void(0);"
								(click)="openConfirmationPopup('1', rowData.id)">
								<i class="fa-solid fa-check-circle mr-2"></i>{{'confirm' | translate}}</a>
						</span>
					</td>
					<td>
						<span *ngIf="rowData.verification2st" class="one-liner">
							<span class="mobile-table-label">{{'2st Verification' | translate}}: </span>{{rowData.verification2st}}
						</span>
						<span *ngIf="!rowData.verification2st && rowData.verification1st != authService.getCurrentUsername()" class="one-liner">
							<span class="mobile-table-label">{{'2st Verification' | translate}}: </span><a href="javascript:void(0);"
								(click)="openConfirmationPopup('2', rowData.id)">
								<i class="fa-solid fa-check-circle mr-2"></i>{{'confirm' | translate}}</a>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Email Sent' | translate}}: </span>
							<span *ngIf="rowData.emailSent" pTooltip="{{rowData.emailSent}}" tooltipPosition="left">{{rowData.emailSent}}</span>
							<span *ngIf="!rowData.emailSent" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="mobile-table-label">{{'Status' | translate}}: </span>
							<span *ngIf="rowData.status" pTooltip="{{rowData.status}}" tooltipPosition="left">{{rowData.status}}</span>
							<span *ngIf="!rowData.status" class="text-muted">{{'no data' | translate}}</span>
						</span>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="footer">
				<tr *ngIf="!loading">
					<td [attr.colspan]="!showCheckbox ? 6 : 7">
						<span class="one-liner">
							<span class="font-weight-bold">{{'Total' | translate}}: </span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="font-weight-bold">{{totalServiceFees | currency : 'USD':'symbol':'1.2-2'}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="font-weight-bold">{{totalAPIUsageFees | currency : 'USD':'symbol':'1.2-2'}}</span>
						</span>
					</td>
					<td>
						<span class="one-liner">
							<span class="font-weight-bold">{{sumServiceFeesAndAPIUsageFees | currency : 'USD':'symbol':'1.2-2'}}</span>
						</span>
					</td>
					<td colspan="2"></td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage" let-columns>
				<tr *ngIf="!loading">
					<td [attr.colspan]="cols.length" class="text-center py-5">
						<div class="text-muted my-5">{{'no data' | translate}}</div>
					</td>
				</tr>
				<tr *ngIf="loading">
					<td [attr.colspan]="cols.length" class="text-center py-5">
						<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</p-panel>
</div>
<p-overlayPanel #op1 appendTo="body" [style]="{'padding':'0px'}" style="padding: 0px !important">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption, $event)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption, $event)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op3 appendTo="body">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption, $event)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>


<p-overlayPanel #op4 appendTo="body">
	<p-listbox [options]="exportOptions" [style]="{'width':'150px'}" [(ngModel)]="exportAs" (click)="invoiceRefAction(exportAs)">
			<ng-template let-col pTemplate="item">
					<div>
							<span>{{col.name}}</span>
					</div>
			</ng-template>
	</p-listbox>
</p-overlayPanel>


<p-dialog header="{{'Confirmation' | translate}}" [(visible)]="showConfirmationPopup" class="modal_in" [modal]='true'>
	<div class="ui-g" style="width:380px;padding:8px;">
		{{'You agree this invoice, was received?' | translate}}
	</div>
	<p-footer>
		<div class="ui-g">
			<div class="ui-g-12" align="right">
				<button type="button" pButton label="{{'yes' | translate}}" (click)="confirmInvoiceVerification()"></button>
				<button type="button" pButton label="{{'no' | translate}}" (click)="openConfirmationPopup()"></button>
			</div>
		</div>
	</p-footer>
</p-dialog>

<p-dialog header="{{'Confirmation' | translate}}" [(visible)]="showConfirmationPopupEmailInvoice" class="modal_in"
	[modal]='true'>
	<div class="ui-g" style="width:380px;padding:8px;">
		{{'Do you agree to email selected invoices?' | translate}}
	</div>
	<p-footer>
		<div class="ui-g">
			<div class="ui-g-12" align="right">
				<button type="button" pButton label="{{'yes' | translate}}" (click)="accept()"
					[disabled]="disableButton"></button>
				<button type="button" pButton label="{{'no' | translate}}"
					(click)="closePopupConfirmationEmailInvoice()"></button>
			</div>
		</div>
	</p-footer>
</p-dialog>

<p-dialog header="{{'Confirmation' | translate}}" [(visible)]="showConfirmationPopupSmsInvoice" class="modal_in"
	[modal]='true'>
	<div class="ui-g" style="width:380px;padding:8px;">
		{{'Do you agree to sms selected invoices?' | translate}}
	</div>
	<p-footer>
		<div class="ui-g">
			<div class="ui-g-12" align="right">
				<button type="button" pButton label="{{'yes' | translate}}" (click)="acceptSms()"
					[disabled]="sendingSms"></button>
				<button type="button" pButton label="{{'no' | translate}}"
					(click)="closePopupConfirmationSmsInvoice()"></button>
			</div>
		</div>
	</p-footer>
</p-dialog>

<p-overlayPanel #op3 appendTo="body">
	<p-listbox [options]="exportOptions" [style]="{'width':'150px'}" [(ngModel)]="exportAs"
		(click)="invoiceRefAction(exportAs)">
		<ng-template let-col pTemplate="item">
			<div>
				<span>{{col.name}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>

<p-overlayPanel #op5 [appendTo]="'body'">
	<p-listbox [options]="fileTypes">
		<ng-template let-col pTemplate="item">
			<div (click)="exportClientList(col.value)">
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>

	</p-listbox>
</p-overlayPanel>

<p-dialog [(visible)]="showInactiveInvoice" class="modal_in" [modal]='true'>
	<p-header>
		<span><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Confirmation' | translate}}</span>
	</p-header>
	<div class="ui-g p-4">
		{{'Are you sure you want to void this invoice?' | translate}}
	</div>
	<p-footer class="d-flex justify-content-between">
		<button type="button" class="btn btn-danger" (click)="showInactiveInvoice=false"><i
				class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button type="button" class="btn btn-primary" (click)="inactiveInvoiceAction()"><i
				class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
	</p-footer>
</p-dialog>
