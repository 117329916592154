import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import { ContractorInvoiceService } from 'app/contractor/service/contractor-invoice.service';
import { FreelancerService } from 'app/crm/service/freelancer.service';
import { PaymentProfileService } from 'app/payment/service/payment-profile.service';
import { WalletTransactionService } from 'app/payment/service/wallet-transaction.service';
import { PayrollSettingService } from 'app/payroll-setting/service/payroll-setting.service';
import { AuthService } from 'app/shared/service/auth/auth.service';
import { TransactionService } from 'app/transaction/service/transaction.service';
import { MessageService } from 'primeng';
import { forkJoin, Subscription } from 'rxjs';
import { TranslatePipe } from '@ngx-translate/core';
import { PaymentCardDefaultService } from 'app/payment/service/payment-card-default.service';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
  providers: [ContractorInvoiceService, TransactionService]
})
export class WalletComponent implements OnInit {
  showConfirmDialog: boolean = false;
  userBankAccounts = [];
  contractorAccounts: any;
  defaultBankAccount;
  userAccountList: any = [];
  accountDropdownList;
  transactionHistories;
  accountBalance: any = {};
  seletectedContractor: any;
  transfer: any = {
    amount: '',
    fromAccount: '',
    toAccount: ''
  };
  transferFeeCashOut;

  paymentTypeList = [
    { label: 'Invoice', value: 'invoice' },
    { label: 'Contract', value: 'contract' },
    { label: 'bonus', value: 'bonus' },
    { label: 'Other', value: 'other' }
  ];
  paymentTypeSelected;

  payForList;
  payForSelected;

  companyId;
  unlinkAccountDialog;
  loading = false;
  isDisabled: boolean = false;
  timeSpent = new Date();
  private _routerSub = Subscription.EMPTY;
  userName: any;

  customerBank: any;
  customerCard: any;
  defaultMethod: any;
  selectedMethod: any;
  balanceUpdateObservable: Subscription;

  constructor(
    private authService: AuthService,
    private paymentProfileService: PaymentProfileService,
    private messageService: MessageService,
    private freelancerService: FreelancerService,
    private payrollSettingService: PayrollSettingService,
    private contractorInvoiceService: ContractorInvoiceService,
    private walletTransactionService: WalletTransactionService,
    private monitoringDetailsService: MonitoringDetailsService,
    private translatePipe: TranslatePipe,
    private router: Router,
    private transactionService: TransactionService,
    private paymentCardDefaultService: PaymentCardDefaultService
  ) {
    this.companyId = this.authService.getCurrentCompanyId() ? this.authService.getCurrentCompanyId() : null;
    this._routerSub = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url !== '/app/payments/wallet') {
          this.monitoringDetailsService.monitorAction(
            `Navigated to Wallet `,
            this.timeSpent,
            {
              navigated_to_wallet_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
            },
            'complete',
            `Navigated to Wallet `,
            0
          );
        }
      }
    })
  }

  ngOnInit(): void {
    this.userName = this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName();
    this.payrollSettingService.getbyId(this.companyId).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.transferFeeCashOut = res.data.transferFeeCashOut;
      }
    });
    if (this.authService.adminRole()) {
      const options: any = {};
      options.page = 0;
      options.size = 99999;
      options.status = 1;
      options.companyId = this.companyId;


      forkJoin([this.paymentProfileService.listAccounts({ user_type: 'CONTRACTOR' }), this.freelancerService.filter(options)])
        .subscribe((res: any[]) => {
          this.contractorAccounts = [];
          const contractors = res[0].data;
          const freelancers = res[1].data.content;
          freelancers.forEach(freelancer => {
            const contractor = contractors.find(account => freelancer.id.toString() === account.appUserId);
            if (contractor) {
              contractor.name = freelancer.fullName;
              contractor.contractItems = freelancer.contractItems;
              this.contractorAccounts.push(contractor);
            } else {
              this.contractorAccounts.push({
                name: freelancer.fullName,
                contractItems: freelancer.contractItems
              });
            }
          });
          console.log(this.contractorAccounts);

        });
    }
    this.listBankAccountExternal();
    this.loadPaymentMethod();
    if (this.balanceUpdateObservable) {
      this.balanceUpdateObservable.unsubscribe();
    }
    this.balanceUpdateObservable = this.paymentProfileService.balanceUpdateObservable.debounceTime(1000).subscribe(e => {
      if (e) {
          this.loadBalance();
      }
    });
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this._routerSub.unsubscribe();
    }, 1000)
    if (this.balanceUpdateObservable) {
      this.balanceUpdateObservable.unsubscribe();
    }
  }

  listBankAccountExternal() {
    this.paymentProfileService.listBankAccountExternal(
      this.authService.getUserInfo().adminId,
      this.authService.getPaymentUserType()
    ).subscribe((extRes: any) => {
      if (extRes.status === 200 && extRes.data && extRes.data.length > 0) {
        extRes.data.forEach((data) => {
          let paymentMethod = data;
          if (paymentMethod) {
            paymentMethod = {
              ...paymentMethod,
              status: data.status,
              bankId: data.id,
            };
            this.userBankAccounts.push(paymentMethod);
          }
        });
        if (this.userBankAccounts.length > 0) {
          this.defaultBankAccount = this.userBankAccounts.find(u => u.defaultPayment);
          if (!this.defaultBankAccount) {
            this.defaultBankAccount = this.userBankAccounts[0];
          }
          this.userAccountList.push({ value: '', label: 'OperrWork' });
          this.userAccountList.push({ value: this.defaultBankAccount.customerBankToken, label: this.defaultBankAccount.accountNumber });
          this.accountDropdownList = [...this.userAccountList];
          this.paymentProfileService.linkBankAccountStatus(this.defaultBankAccount.accountId).subscribe((accountVerificationstatusRes: any) => {
            if (accountVerificationstatusRes.status === 200) {
              this.defaultBankAccount.linkedAccount = accountVerificationstatusRes.data;
              this.loadTransactionHistories();
              this.loadBalance();
            }
          });
        }
      }
    });
  }

  openModal() {
    if (this.transferFeeCashOut && this.transfer.amount && this.transfer.amount != '') {
      this.transfer.fee = +this.transfer.amount * this.transferFeeCashOut / 100;
      this.transfer.net = +this.transfer.amount - this.transfer.fee;
    }
    this.showConfirmDialog = true;
    const args = {
      review_transfer_clicked_by: this.userName
    }
    const action = 'Review transfer Clicked'
    this.monitorDetails(args, action)
  }

  accept() {
    this.isDisabled = true;
    if ((!this.transfer.fromAccount && !this.transfer.toAccount) || !this.transfer.amount || +this.transfer.amount <= 0) {
      this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: this.translatePipe.transform('Invalid request') });
      this.showConfirmDialog = false;
      this.isDisabled = false;
      return;
    }
    const options: any = {
      app_user_id: this.authService.getUserInfo().adminId,
      amount: this.transfer.amount && this.transfer.amount != '' ? +this.transfer.amount : 0,
      source: this.defaultBankAccount.accountId,
      destination: this.defaultBankAccount.customerBankToken,
      user_type: this.authService.getPaymentUserType(),
      created_by_user: this.authService.getUserInfo().username
    };
    const toAccount = this.accountDropdownList.find(a => a.value === this.transfer.toAccount);
    const fromAccount = this.accountDropdownList.find(a => a.value === this.transfer.fromAccount);
    if (this.seletectedContractor) {
      options.memo = 'Transfered to account ' + (toAccount ? toAccount.label : '');
      options.destination = this.seletectedContractor.id;
      if (this.paymentTypeSelected === 'invoice' && this.payForSelected) {
        const metadata = {
          createdByUsr: this.authService.getCurrentUsername(),
          paymentMethod: 'WALLET_TRANSFER',
          companyId: this.companyId,
          invoiceNumber: this.payForSelected.invoiceNumber,
          invoiceId: this.payForSelected.id,
          invoiceUserType: 'CONTRACTOR',
          invoiceContractorId: this.seletectedContractor.appUserId,
          amount: this.transfer.amount,
          cashIn: 0,
          cashKept: 0,
          cashOut: this.transfer.amount,
          netCashInOut: - this.transfer.amount
        }
        options.metadata = metadata;
      }
      this.paymentProfileService.chargeThenTransfer(options).subscribe((res: any) => {
        if (res.status === 200) {
          this.isDisabled = false;
          this.walletTransactionService.sync(res.data.transactionPaymentId).subscribe();
          const transactionStatus = res.data.status === 1 || res.data.status === 7 ? 1 : res.data.status === 3 ? 0 : 2;
          this.saveContractorInvoiceTransaction(this.payForSelected.id, this.transfer.amount, transactionStatus, res.data.transactionPaymentId);
          this.loadBalance();
          this.paymentProfileService.notifyBalanceWalletChange();
          this.loadTransactionHistories();
          this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('Success'), detail: this.translatePipe.transform('Successfully') });
          this.monitorTransfer('SUCCESS')
        } else {
          this.isDisabled = false;
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: res.data });
          this.monitorTransfer()
        }
        this.transfer = {
          amount: 0,
          fromAccount: '',
          toAccount: ''
        };
        this.showConfirmDialog = false;
      }, err => {
        this.isDisabled = false;
        this.monitorTransfer()
      });
    }
    else if (this.transfer.toAccount === '') {
      options.memo = 'Credited to account ' + (fromAccount ? fromAccount.label : '');
      options.metadata = {
        destination: this.defaultBankAccount.accountId
      }
      this.paymentProfileService.topUpAmount(options).subscribe((res: any) => {
        if (res.status === 200) {
          this.isDisabled = false;
          this.walletTransactionService.sync(res.data.transactionPaymentId).subscribe();
          this.loadBalance();
          this.paymentProfileService.notifyBalanceWalletChange();
          this.loadTransactionHistories();
          this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('Success'), detail: this.translatePipe.transform('Successfully') });
          this.monitorTransfer('SUCCESS');
        } else {
          this.isDisabled = false;
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: res.data });
          this.monitorTransfer();
        }
        this.transfer = {
          amount: 0,
          fromAccount: '',
          toAccount: ''
        };
        this.showConfirmDialog = false;
      }, err => {
        this.isDisabled = false;
        this.monitorTransfer()
      });
    } else {
      options.memo = 'Withdrawal from account' + (toAccount ? toAccount.label : '');
      options.fee = this.transfer.fee ? this.transfer.fee : 0;
      this.paymentProfileService.payoutAmount(options).subscribe((res: any) => {
        if (res.status === 200) {
          this.isDisabled = false;
          this.walletTransactionService.sync(res.data.transactionPaymentId).subscribe();
          this.loadBalance();
          this.paymentProfileService.notifyBalanceWalletChange();
          this.loadTransactionHistories();
          this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('Success'), detail: this.translatePipe.transform('Successfully') });
          this.monitorTransfer('SUCCESS')
        } else {
          this.isDisabled = false;
          this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: res.data });
          this.monitorTransfer()
        }
        this.transfer = {
          amount: 0,
          fromAccount: '',
          toAccount: ''
        };
        this.showConfirmDialog = false;
      }, err => {
        this.isDisabled = false;
        this.monitorTransfer()
      });
    }


  }

  saveContractorInvoiceTransaction(invoiceId, amount, status, transactionId) {
    const details: any = [];
    const payload: any = {
      companyId: this.authService.getCurrentCompanyId(),
      createdByUsr: this.authService.getCurrentUsername(),
      expenseCurrency: 'USD',
      status: status,
      appliedTransaction: true,
      paymentTransactionId: transactionId
    };

    const transactionDetail: any = {};
    transactionDetail.contractorInvoiceId = invoiceId,
      transactionDetail.status = status;
    transactionDetail.date = new Date();
    transactionDetail.cashIn = 0;
    transactionDetail.cashKept = 0;
    transactionDetail.cashOut = amount;
    transactionDetail.paymentMethod = 'WALLET_TRANSFER';
    transactionDetail.createdByUsr = this.authService.getCurrentUsername();
    transactionDetail.paymentTransactionId = transactionId;
    // tslint:disable-next-line:max-line-length
    transactionDetail.netCashInOut = (parseFloat(transactionDetail.cashIn) + parseFloat(transactionDetail.cashKept)) - parseFloat(transactionDetail.cashOut);
    transactionDetail.netCashInOut = transactionDetail.netCashInOut.toFixed(2);
    details.push(transactionDetail);
    payload.transactionDetails = details;
    this.transactionService.createTransaction(payload).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.contractorInvoiceService.reCalculatePaidStatus(invoiceId).subscribe();
        this.loadContractInvoices(this.seletectedContractor.appUserId);
        this.seletectedContractor = null;
        this.paymentTypeSelected = null;
      }
    });
  }

  reject() {
    this.showConfirmDialog = false;
  }

  getToAccount() {
    if (this.transfer.toAccount === '') {
      return 'OperrWork';
    }
    return this.defaultBankAccount.accountNumber;
  }

  linkAccount() {
    const date = new Date()
    const args = { link_account_processed_by: this.userName }
    const action = 'Link Account Process Started'
    this.monitorDetails(args, action, date)
    this.loading = true;
    const linkBankPayload = {
      appUserId: this.authService.getUserInfo().adminId,
      userType: this.authService.getPaymentUserType(),
      bankId: this.defaultBankAccount ? this.defaultBankAccount.id : null,
      refreshUrl: location.href,
      returnUrl: location.href,
      firstName: this.authService.getUserInfo().firstName,
      lastName: this.authService.getUserInfo().lastName,
      email: this.authService.getUserInfo().email,
      phoneNumber: this.authService.getUserInfo().phone ? this.authService.getUserInfo().phone : '0123456789',
    }
    this.paymentProfileService.linkBankAccount(linkBankPayload).subscribe((res: any) => {
      this.loading = false;
      if (res.status === 200) {
        window.open(res.data, '_blank');
      } else {
        this.messageService.add({ severity: 'error', summary: this.translatePipe.transform('ERROR'), detail: res.data });
      }
    }, () => this.loading = false)
  }

  loadBalance() {
    this.paymentProfileService.getAccountBalance({
      app_user_id: this.authService.getUserInfo().adminId,
      user_type: this.authService.getPaymentUserType()
    }).subscribe((balanceRes: any) => {
      if (balanceRes.status === 200) {
        this.accountBalance = balanceRes.data;
      }
    });
  }

  loadTransactionHistories(page?, size?) {
    this.transactionHistories = [];
    this.paymentProfileService.getTransactionList({
      register_id: this.defaultBankAccount.accountId,
      page: page ? page : 1,
      size: size ? size : 10
    }).subscribe((res: any) => {
      if (res.status === 200 && res.data && res.data.content) {
        res.data.content.forEach((t: any) => {
          if (t.paymentType === 'TOP_UPS' || t.paymentType === 'PAY_OUTS' || t.paymentType === 'TRANSFER') {
            this.transactionHistories.push({
              title: t.memo,
              date: t.createdAt,
              amount: t.amount,
              fee: t.fee,
              failureMessage: t.failureMessage,
              type: (t.paymentType === 'PAY_IN' || t.paymentType === 'TOP_UPS') || (t.paymentType === 'TRANSFER' && t.transferType === 'PAY_IN') ? "credit" : "debit",
              statusTxt: t.status === 1 || t.status === 6 || t.status === 7 ? 'SUCCEEDED' : (t.status === 3 ? 'PENDING' : (t.status === 4 ? 'CANCELED' : 'FAILED'))
            });
          }
        });
      }
    })
  }

  isAdmin() {
    return this.authService.adminRole();
  }

  seletectedContractorChange() {
    if (this.seletectedContractor) {
      const dropdown = this.contractorAccounts.map(u => {
        return { value: u.id, label: u.externalBanks && u.externalBanks.length > 0 ? (u.name + '\'s Wallet') : null };
      });

      this.accountDropdownList = [...dropdown];
      this.accountDropdownList.push({ value: this.defaultBankAccount.id, label: 'OperrWork' });
      this.transfer.fromAccount = this.defaultBankAccount.id;
      this.transfer.toAccount = this.seletectedContractor.id;
    } else {
      this.transfer.amount = null;
      this.transfer.fromAccount = null;
      this.transfer.toAccount = null;
      this.paymentTypeSelected = null;
      this.payForList = null;
      this.payForSelected = null;
      this.accountDropdownList = [...this.userAccountList];
    }
  }

  paymentTypeChange() {
    if (this.seletectedContractor) {
      this.payForList = null;
      this.payForSelected = null;
      if (this.paymentTypeSelected === 'invoice') {
        this.loadContractInvoices(this.seletectedContractor.appUserId);
      } else if (this.paymentTypeSelected === 'contract') {
        if (this.seletectedContractor.contractItems) {
          this.payForList = [...this.seletectedContractor.contractItems];
          this.payForList.forEach(p => p.name = p.contractName);
        } else {
          this.payForList = null;
        }
      }
    } else {
      this.transfer.fromAccount = null;
      this.transfer.toAccount = null;
      this.paymentTypeSelected = null;
      this.payForList = null;
      this.payForSelected = null;
      this.accountDropdownList = [...this.userAccountList];
    }
  }

  loadContractInvoices(contractId) {
    const option = {
      companyId: this.companyId,
      freelancerId: Number(contractId),
      oldInvoiceOnly: false,
      page: 0,
      size: 99999
    }
    this.contractorInvoiceService.filter(option).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.payForList = res.data.content;
        this.payForList.forEach(p => p.name = p.invoiceNumber);
      }
    }, () => this.payForList = null);
  }

  payForSelectedChange() {
    if (this.seletectedContractor && this.paymentTypeSelected === 'invoice' && this.payForSelected) {
      this.transfer.amount = this.payForSelected.outstandingBalance;
    }
  }

  unlinkAccount() {
    this.loading = true;
    this.unlinkAccountDialog = true;
  }

  acceptUnlinkAccount() {
    const params = {
      account_id: this.defaultBankAccount.accountId
    };
    this.paymentProfileService.unlinkAccount(params).subscribe(() => {
      this.loading = false;
      this.unlinkAccountDialog = false;
      this.messageService.add({ severity: 'success', summary: this.translatePipe.transform('Success'), detail: this.translatePipe.transform('Successfully') });
      this.defaultBankAccount = null;
      this.userBankAccounts = [];
      this.userAccountList = [];
      this.transactionHistories = [];
      this.accountBalance = {};
      this.listBankAccountExternal();
      const args = { account_unlinked_by: this.userName }
      const action = 'Account Unlinked'
      this.monitorDetails(args, action, new Date())
    }, () => this.loading = false)
  }

  monitorDetails(args, action, d?) {
    this.monitoringDetailsService.monitorAction(
      action,
      d ? d : new Date(),
      args,
      "complete",
      action,
      0
    );
  }

  monitorTransfer(type?) {
    let args; let action;
    if (type) {
      args = { fund_transferred_by: this.userName }
      action = 'Transferred Successfully'
    } else {
      args = { fund_transfer_failed_by: this.userName }
      action = 'Transfer Failed'
    }
    this.monitorDetails(args, action, new Date())
  }

  decimalOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    const charCodes = charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46;
    if (/(\d\.|\.)\d{2}/.test(event.target.value) || charCodes || (event.target.value.includes('.') && charCode === 46)) {
      return false;
    }
    return true;
  }

  loadPaymentMethod() {
    forkJoin([
      this.paymentProfileService.listBankAccount(this.authService.getUserInfo().adminId, this.authService.getPaymentUserType()),
      this.paymentProfileService.getUserCardList(this.authService.getUserInfo().adminId, this.authService.getPaymentUserType()),
      this.paymentCardDefaultService.getDefaultCard({
        roleId: this.authService.getRoleLevel(),
        userId: this.authService.getCurrentLoggedInId(),
        companyId: this.authService.getCurrentCompanyId()
      })
    ]).subscribe((res: any[]) => {
      let customerBanks = [];
      if (res[0] && res[0].length > 0) {
        res[0].forEach((data: any) => {
          let paymentMethod = data;
          if (paymentMethod) {
            paymentMethod = {
              ...paymentMethod,
              status: data.status,
              bankId: data.id,
            }
            customerBanks.push(paymentMethod);
          }
        });
      }
      let customerCards = res[1];
      this.defaultMethod = res[2];
      this.customerCard = (customerCards instanceof Array) && customerCards.find( (c: any) => c && c.id === this.defaultMethod.cardId);
      this.customerBank = customerBanks.find(c => c.id === this.defaultMethod.bankId);
      if (!this.customerCard) {
        this.customerCard = customerCards[0];
      } else {
        this.selectedMethod = this.customerCard;
      }
      if (!this.customerBank) {
        this.customerBank = customerBanks[0];
      } else {
        this.selectedMethod = this.customerBank;
      }
      if (this.customerBank) {
        this.customerBank.lastFourNumbers = this.customerBank.lastFourNumbers.replace('xxxx', '');
      }
    });
  }

  addPaymentMethod() {
    this.router.navigate(['/app/payments/detail']);
  }
}
