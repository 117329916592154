import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { MENU_ID } from 'app/shared/data/menu-id';
import { CustomTabMenu } from 'app/shared/model/custom-tab-menu';

@Component({
  selector: 'app-email-layout',
  templateUrl: './email-layout.component.html',
  styleUrls: ['./email-layout.component.css']
})
export class EmailLayoutComponent implements OnInit {

  customTabMenu: CustomTabMenu = {
    breadcrumb: 'Email',
    items: [
      {
        label: 'Email List',
        id: 'list_email',
        routerLink: ['list'],
        routerLinkUrl: '/app/email/list',
        visible: this.authService.hasViewPermission(MENU_ID.MAIL),
        breadcrumb: ['Email List']
      },
      {
        label: 'Compose',
        id: 'compose',
        routerLink: ['compose'],
        routerLinkUrl: '/app/email/compose',
        visible: this.authService.hasViewPermission(MENU_ID.MAIL),
        breadcrumb: ['Compose']
      }
    ]
  }

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
  }
  ngOnDestroy() {
  }

}
