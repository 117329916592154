import { Component, OnInit } from '@angular/core';
import { LoginService } from '../service/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../shared/service/auth/auth.service';
import { EmployeeService } from '../../employee/service/employee.service';
import { AgencyService } from '../../agency/service';
import * as MOMENT from 'moment';
import { CookieService } from 'ngx-cookie-service';
import * as Crypto from 'crypto-js';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { TaskManagementService } from 'app/task-management/service/task-management.service';
import moment from 'moment';
import { CompanySettingServiceV2 } from '../../company/service/company-setting.v2.service';
import { PaymentInvoiceService } from '../../payment/service/payment-invoice.service';
import { MENU_ID } from 'app/shared/data/menu-id';
import { RoleLevel } from 'app/layout/component/app-menu/role-level';
// import {NgxFaviconService} from "ngx-favicon";
declare const google: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [LoginService, TranslatePipe, TranslateService, TaskManagementService]
})
export class LoginComponent implements OnInit {

  userTypes: Array<object> = [
    { label: 'Admin', value: 1 },
    { label: 'Employee', value: 2 },
    { label: 'Contractor', value: 3 },
    { label: 'Client', value: 4 },
    { label: 'Guest', value: 5 },
    { label: 'Client Sub-Account', value: 6 }];

  signInFormData: any = {
    username: String,
    password: String,
    userValue: Number,
    rememberMe: Boolean
  };
  resObj: any;
  message: string;
  showPwd = false;
  redirectURL = '';
  storeLoginInfo: any;
  attemptMsg = '';
  disableButton = false;
  showRegisterChildAccountDialog = false;
  myAddress: any;
  key = '253D3FB468A0E24677C28A624BE0F939';
  disableLoginType = false;
  fromDispatch = false;
  invoiceNumber = undefined;
  constructor(private loginService: LoginService, private employeeService: EmployeeService,
    private agencyService: AgencyService, private router: Router,
    private authService: AuthService,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private taskManagementService: TaskManagementService,
    private translateService: TranslateService,
    private companySettingService: CompanySettingServiceV2,
    private paymentInvoiceService: PaymentInvoiceService,
    // private ngxFaviconService: NgxFaviconService<any>
  ) {
    const params = this.route.snapshot.queryParams;
    if (params['username'] && params['password']) {
      this.signInFormData.username = params['username'];
      this.signInFormData.password = params['password'];
      this.fromDispatch = true;
      this.invoiceNumber = params['invoiceNumber'];
      this.loginUser(undefined);
    }
  }

  ngOnInit() {
    this.translateService.use('en');
    this.translateService.use(this.translateService.currentLang ? this.translateService.currentLang : 'en');
    this.signInFormData.username = '';
    this.signInFormData.password = '';
    this.signInFormData.userValue = 0;
    this.signInFormData.rememberMe = false;
    const loginData = this.GetCookieValue('_rememberme');
    if (loginData !== null) {
      // Decrypt
      const bytes = Crypto.AES.decrypt(loginData, this.key);
      this.signInFormData = JSON.parse(bytes.toString(Crypto.enc.Utf8));
    }
    const isBackFromSignUp = localStorage.getItem('_register_guest');
    if (isBackFromSignUp === 'true') {
      this.signInFormData.userValue = 5;
    }

    localStorage.removeItem('_showConfirmationPunchInOut')
    if (this.authService.authenticateUser()) {
      if (localStorage.getItem('_user') === 'freelancer') {
        this.router.navigate(['/app/crm/freelancer/edit', localStorage.getItem('_userId')]);
      } else if (localStorage.getItem('_user') === 'client') {
        // this.router.navigate(['/app/client/dasboard']);
        this.checkInvoicePayment()
      } else if (localStorage.getItem('_user') === 'child_client') {
        this.router.navigate(['/app/job-follow-up/billing-total-job']);
      } else if (localStorage.getItem('_user') === 'guess') {
        this.router.navigate(['/app/tm']);
      } else {

        this.setFaviconComany();
        this.checkForUserBirthday();
        if (this.authService.adminRole()) {
          this.router.navigate(['app/dashboard']);
        } else if (this.authService.getRoleLevel() === 7) {
          this.router.navigate(['app/employee-dashboard']);
        } else {
          this.router.navigate(['/app']);
        }
      }
    } else {
      // this.cookieService.deleteAll();
      localStorage.clear();
      this.authService.authenticateUser();
      this.authService.setAuthObservable();
    }
    const params = this.route.snapshot.queryParams;
    if (params['isFromComm']) {
      this.signInFormData.userValue = 1;
      this.disableLoginType = true;
    }
  }

  ngAfterViewInit() {
    this.getLocation();
  }

  GetCookieValue(name) {
    const found = document.cookie.split(';').filter(c => c.trim().split('=')[0] === name);
    return found.length > 0 ? found[0].split('=')[1] : null;
  }

  loginUser(e: any) {
    const date = new Date();
    date.setTime(date.getTime() + (30 * 1000));
    const expires = '; expires=' + date['toGMTString']();
    // e.preventDefault();
    this.message = '';
    this.attemptMsg = '';
    if (this.signInFormData.username && this.signInFormData.password) {
      this.loginService.login(this.signInFormData.username, this.signInFormData.password, this.myAddress, this.fromDispatch).subscribe(res => {
        const resObj: any = res;
        if (resObj.status === 'INVALID_PASSWORD' && resObj.count >= 0) {
          switch (resObj.count) {
            case 0: this.attemptMsg = 'This account is locked! Contact support.';
              this.disableButton = true; break;
            case 1: this.attemptMsg = 'Login info still wrong. 1 attempt left.'; break;
            default: this.attemptMsg = `Login info wrong. ${resObj.count} login attempts left.`;
          }
          return;
        }
        if (resObj.status === 'BLOCK') {
          this.attemptMsg = 'This account is locked! Contact support.';
          this.disableButton = true;
          return;
        }
        if (resObj.status === 'TERMINATED') {
          this.attemptMsg = resObj.message;
          this.disableButton = true;
          return;
        }
        this.authService.setAuthObservable();
        const params = this.route.snapshot.queryParams;
        if (params['redirectURL']) {
          this.redirectURL = params['redirectURL'];
        }
        localStorage.setItem('_invoiceNumber', this.invoiceNumber);
        if (this.redirectURL !== '') {
          let parserURI = decodeURIComponent(this.redirectURL);
          parserURI = decodeURIComponent(parserURI);
          const indexOfQuery = parserURI.indexOf('?');
          const indexOfQueryTM = parserURI.indexOf('/app/tm');
          if (indexOfQuery >= 0 && indexOfQueryTM >= 0) {
            const URLId = parserURI.substring(0, indexOfQuery).replace('/app/tm/', '');
            const projectId = this.taskManagementService.decodeId(URLId);
            const cardName = parserURI.substring(indexOfQuery + 3, parserURI.length);
            this.router.navigate(['/app/tm', this.taskManagementService.encodeId(projectId)], { queryParams: { 't': cardName } });
          } else {
            this.router.navigate([parserURI]);
          }

        } else if (res === '') {
          if (this.storeLoginInfo) {
            this.signInFormData.password = this.signInFormData.password;
          }
          localStorage.removeItem('_register_guest');
          // Encrypt
          const signInFormData = Crypto.AES.encrypt(JSON.stringify(this.signInFormData), this.key).toString();
          document.cookie = '_rememberme=' + signInFormData;
          if (this.authService.getUserInfo().defaultDashboard) {
            const defaultDashboard = this.authService.getUserInfo().defaultDashboard;
            if (defaultDashboard === 'global_dashboard' && this.authService.hasViewPermission(MENU_ID.GLOBAL_DASHBOARD)) {
              this.router.navigate(['/app/resident/dashboard']);
              return;
            }
            if (defaultDashboard === 'dashboard' && this.authService.hasViewPermission(MENU_ID.DASHBOARD)) {
              this.router.navigate(['/app/dashboard']);
              return;
            }
            if (defaultDashboard === 'overtime' && this.authService.hasViewPermission(MENU_ID.OVERTIME_DASHBOARD)) {
              this.router.navigate(['/app/overtime/list']);
              return;
            }
            if (defaultDashboard === 'client_dashboard' && this.authService.hasViewPermission(MENU_ID.CLIENT_DASHBOARD)) {
              this.checkInvoicePayment()
              return;
            }
            if (defaultDashboard === 'crm_dashboard' && this.authService.hasViewPermission(MENU_ID.CRM_DASHBOARD)) {
              this.router.navigate(['/app/invoice/dashboard']);
              return;
            }
            if (defaultDashboard === 'ceo_dashboard' && this.authService.hasViewPermission(MENU_ID.CEO_DASHBOARD)) {
              this.router.navigate(['/app/ceo/dashboard']);
              return;
            }
            if (defaultDashboard === 'vrm_dashboard' && this.authService.hasViewPermission(MENU_ID.VRM_DASHBOARD)) {
              this.router.navigate(['/app/voter/dashboard']);
              return;
            }
            if (defaultDashboard === 'oppertel_dashboard' && this.authService.hasViewPermission(MENU_ID.OPERRTEL_DASHBOARD)) {
              this.router.navigate(['/app/operrtel/dashboard']);
              return;
            }
          }
          if (localStorage.getItem('_user') === 'freelancer') {
            this.router.navigate(['/app/crm/freelancer/edit', localStorage.getItem('_userId')]);
          } else if (localStorage.getItem('_user') === 'client') {
            localStorage.setItem('login', 'true');
            this.router.navigate(['/app/client/dasboard']);
            this.checkInvoicePayment()
          } else if (localStorage.getItem('_user') === 'child_client') {
            this.router.navigate(['/app/job-follow-up/billing-total-job']);
          } else if (localStorage.getItem('_user') === 'guess') {
            this.router.navigate(['/app/tm']);
          } else if (this.authService.getRoleLevel() === RoleLevel.ROLE_FRONT_DESK) {
            this.router.navigate(['/app/employee-clocking/punch']);
          } else {
            // this.router.navigate(['/app/dashboard']);
            this.setFaviconComany();
            this.checkForUserBirthday();
            const userLoginInfo = this.authService.getUserInfo();
            const accountType = userLoginInfo?.accountType;
            if (accountType === 'Merchant') {
              this.router.navigate(['app/operrtel/dashboard']);
              return;
            }
            if (this.authService.adminRole()) {
              this.router.navigate(['app/dashboard']);
            } else if (this.authService.getRoleLevel() === RoleLevel.ROLE_EMPLOYEE) {
              this.router.navigate(['app/employee-dashboard']);
            }
          }
        } else {
          this.message = res;
        }

      }, error => {
        console.log(error);
        this.message = 'Your login credentials could not be verified';
      });
    } else {
      this.message = 'Please enter values for all the fields';
    }

  }

  forgotPassword() {
    if (this.disableButton) { return; }
    this.router.navigate(['/', 'forgot-password']);
  }

  signUp() {
    this.router.navigate(['/', 'sign-up']);
  }

  private setFaviconComany() {
    if (this.authService.getCurrentCompany()
      && this.authService.getCurrentCompany().companySetting
      && this.authService.getCurrentCompany().companySetting.document) {
      const logoUrl = this.authService.getCurrentCompany().companySetting.document.fileUrl;
      document.getElementById('favicon').setAttribute('href', logoUrl);
      // this.ngxFaviconService.setFaviconByUrl( logoUrl);
    }
  }
  private checkForUserBirthday() {
    this.employeeService.get(this.authService.getCurrentLoggedInId()).subscribe(res => {
      const resObj: any = res;
      const today: any = MOMENT().set('year', 2000);
      if (resObj.status === 'SUCCESS' && resObj.data && today.isSame(MOMENT(resObj.data.dateOfBirth).set('year', 2000), 'day')) {
        this.agencyService.getAgencySetting(resObj.data.agency.id)
          .subscribe((response: any) => {
            if (response.data && response.data.birthdaySongEnable && response.data.birthdaySong) {
              const audio = new Audio();
              audio.src = response.data.birthdaySong.fileUrl;
              audio.load();
              audio.play();
              setInterval(function () {
                if (audio.currentTime > 15) {
                  audio.pause();
                }
              }, 1000);

            }
          });
      }
    });
  }

  showPassword() {
    this.showPwd = !this.showPwd;
  }

  signUpChildAccount() {
    this.showRegisterChildAccountDialog = true;
  }

  RememberMeToggle(evt) {
    this.storeLoginInfo = evt;
  }

  latitude: number;
  longitude: number;
  address: string;
  getLocation() {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.loginService.getAddress(this.latitude, this.longitude).subscribe(
            (response) => {
              this.myAddress = response.display_name;
            },
            (error) => {
            }
          );
        },
        (error) => {
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  }

  checkInvoicePayment() {
    const options = {
      clientId: this.authService.getCurrentLoggedInId(),
      companyId: this.authService.getCurrentCompanyId(),
      oldInvoiceOnly: false,
      ignoreInvoiceDetails: true,
      sortField: 'id',
      sortOrder: 'DESC',
      listStatus: ['Unpaid', 'Partial Paid'],
      page: 0,
      size: 10
    }

    this.paymentInvoiceService.invoiceSummarySearch(options).subscribe((invoiceReponse: any) => {
      if (invoiceReponse.status === 'SUCCESS') {

        localStorage.removeItem('_pastDueInvoice');
        const unpaidInvoices = this.getUnpaidInvoices(invoiceReponse.data.content);

        if (unpaidInvoices && unpaidInvoices.length > 0) {

          const unpaidInvoice = unpaidInvoices[0];
          const dueDate = moment(unpaidInvoice.dueDate).startOf('d');
          const now = moment().startOf('d');
          const diff = dueDate.diff(now, 'd', true);

          if (diff < -1) {
            // this.router.navigate(['/app/payments/detail'])
            // this.router.navigateByUrl('/app/payments/detail?invoiceNumber=' + unpaidInvoice.invoiceNumber, { replaceUrl: true })
          } else if (diff === -1) {
            const startInvoiceCountDown = this.cookieService.get('_startInvoiceCountDown');
            if (startInvoiceCountDown) {

              const jsonObj = JSON.parse(startInvoiceCountDown);
              if (jsonObj && jsonObj.clientId === this.authService.getUserInfo().adminId && jsonObj.invoiceId === unpaidInvoice.id) {

                const countDownDiff = moment().diff(moment(jsonObj.startInvoiceCountDown, 'MM/DD/YYYY HH:mm:ss'), 'seconds', true);
                if (countDownDiff <= 0) {

                  this.companySettingService.getCompanySettingByCompanyId(this.authService.getUserInfo().companyId).subscribe(com => {
                    if (com.status === 'SUCCESS' && com.data && com.data.clientPaymentGracePeriod) {
                      // this.router.navigate(['/app/payments/detail'])
                      // this.router.navigateByUrl('/app/payments/detail?invoiceNumber=' + unpaidInvoice.invoiceNumber, { replaceUrl: true })
                    }
                  })
                }
              }
            }
            this.router.navigate(['/app/client/dasboard'])
          } else {
            this.router.navigate(['/app/client/dasboard'])
          }
        }
      }
    });
  }

  getUnpaidInvoices(invoices: any[]) {
    if (!invoices || invoices.length <= 0) {
      return null;
    }

    // invoices = invoices.sort((a, b) => a.id - b.id);
    return invoices.filter(i => i.status === 'Unpaid' || i.status === 'Partial Paid');
  }
}
